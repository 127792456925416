import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

import "./FinalisationProcessDataTable.scss";

const actions = {
  editItem: "Edit",
  downloadFile: "Download",
  deleteItem: "Delete"
};

const FinalisationProcessDataTable = props => {
  const {
    config,
    items,
    onDownloadFile,
    onEditItem,
    onDeleteItem,
    isHostUser
  } = props;
  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const { findItem, createColumn, createColumnForDropdownMenu } =
    useDataTable(items);

  const handleAction = useCallback(
    (action, itemId) => {
      const item = findItem(itemId);
      if (action !== actions.downloadFile) {
        setRowDisplay({ rowId: -1, display: false });
      }
      if (action === actions.editItem) {
        onEditItem(item);
      } else if (action === actions.downloadFile) {
        const documentId = itemId; // itemId in this scenario is the documentId
        onDownloadFile(documentId);
      } else if (action === actions.deleteItem) {
        onDeleteItem(item);
      }
    },
    [findItem, onDownloadFile, onEditItem, onDeleteItem]
  );

  const singleActionHandler = useCallback(
    action =>
      ({ cell }) => {
        handleAction(action, cell.value);
      },
    [handleAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const clientId = cell.row.original.id;
      const action = menuItem.name;
      handleAction(action, clientId);
    },
    [handleAction]
  );

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const columns = useMemo(() => {
    const makeColumn = fieldNameConfig => {
      switch (fieldNameConfig.type) {
        case "text": {
          return createColumn({
            Header: fieldNameConfig.label,
            accessor: fieldNameConfig.key,
            className: "text",
            width: 80
          });
        }
        case "date": {
          return createColumn({
            Header: fieldNameConfig.label,
            accessor: fieldNameConfig.key,
            className: "date",
            width: 80,
            Cell: ({ cell }) =>
              dateFormatter(cell.value, locale, shortFormat) ?? "Not available"
          });
        }
        case "document": {
          return createColumn({
            Header: fieldNameConfig.label,
            accessor: fieldNameConfig.key,
            className: "document",
            width: 100,
            Cell: ({ cell }) => {
              const displayName = cell.value?.pathName || cell.value?.name;
              return (
                <div className="download-file">
                  <div className="download-file__label">{displayName}</div>
                  {displayName && cellVisibilityHandler(cell.row.id) && (
                    <i
                      title={`Download ${
                        fieldNameConfig.formLabel ?? fieldNameConfig.label
                      }`}
                      className="download-file__icon clickable material-icons"
                      onClick={() =>
                        singleActionHandler(actions.downloadFile)({ cell })
                      }
                    >
                      download
                    </i>
                  )}
                </div>
              );
            }
          });
        }
        case "file": {
          return createColumn({
            Header: "",
            accessor: fieldNameConfig.key,
            className: "file",
            width: 50,
            Cell: ({ cell }) => {
              return (
                <div>
                  {cell.value && cellVisibilityHandler(cell.row.id) ? (
                    <i
                      title={`Download ${fieldNameConfig.label}`}
                      className="clickable material-icons"
                      onClick={() =>
                        singleActionHandler(actions.downloadFile)({ cell })
                      }
                    >
                      download
                    </i>
                  ) : (
                    <></>
                  )}
                </div>
              );
            }
          });
        }
        default:
      }
    };

    const columns = config.fieldNames.map(makeColumn);

    return [
      ...columns,
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        displayCell: cellVisibilityHandler
      })
    ];
  }, [
    config,
    singleActionHandler,
    dropdownMenuActionHandler,
    cellVisibilityHandler,
    createColumn,
    createColumnForDropdownMenu,
    locale,
    shortFormat
  ]);

  const data = useMemo(
    () =>
      items.map(item => {
        const getActionsMenu = () => {
          if (isHostUser) {
            return [{ name: actions.editItem }, { name: actions.deleteItem }];
          }
          return [];
        };

        return {
          id: item.id,
          name: item.name,
          signOffDate: item.signOffDate,
          signOffDocument: item.signOffDocument ?? "",
          lodgementDate: item.lodgementDate,
          lodgementReceipt: item.lodgementReceipt ?? "",
          actionsMenu: getActionsMenu()
        };
      }),
    [isHostUser, items]
  );

  return (
    <DataTable
      className="finalisation-process-table"
      columns={columns}
      data={data}
      onMouseEnterRow={rowId => setRowDisplay({ rowId, display: true })}
      onMouseLeaveRow={rowId => setRowDisplay({ rowId, display: false })}
    ></DataTable>
  );
};

FinalisationProcessDataTable.defaultProps = {};

FinalisationProcessDataTable.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    fieldNames: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf(["text", "date", "document", "file"])
      })
    )
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      signOffDate: PropTypes.any,
      signOffDocument: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      }),
      lodgementDate: PropTypes.any
    })
  ).isRequired,
  onDownloadFile: PropTypes.func,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  isHostUser: PropTypes.bool
};

export default FinalisationProcessDataTable;
