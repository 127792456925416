import React, { useMemo } from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Inline, Stack } from "@fermions";

import { Icon, IconColor, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import OTTooltip from "../OTTooltip";
import "./Attachment.scss";

export enum AttachmentType {
  WEBSHEET = "websheet",
  DOCUMENT = "document"
}

interface AttachmentProps {
  type: AttachmentType;
  onClick: () => void;
  file: { name: string };
  action?: (file: { name: string }) => void;
  disabled?: boolean;
}

export const Attachment = ({
  type,
  onClick,
  file,
  action,
  disabled
}: AttachmentProps) => {
  const { showTooltip, elementProps } = useTooltip();
  const label = file.name ?? "";

  const attachmentTypeIcon = useMemo(() => {
    let icon = { name: "view_module", fillStyle: IconFillStyle.OUTLINED };
    if (type === AttachmentType.DOCUMENT) {
      icon = { name: "description", fillStyle: IconFillStyle.OUTLINED };
    }

    return (
      <Icon
        {...icon}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        fillStyle={IconFillStyle.OUTLINED}
        className="attachment__type-icon"
        color={IconColor.INHERIT}
      />
    );
  }, [type]);

  const attachmentActionIcon = useMemo(() => {
    let icon = { name: "open_in_new", fillStyle: IconFillStyle.OUTLINED };
    if (type === AttachmentType.DOCUMENT) {
      icon = { name: "download", fillStyle: IconFillStyle.FILLED };
    }

    return (
      <Icon
        {...icon}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        className="attachment__action-icon"
        color={IconColor.INHERIT}
      />
    );
  }, [type]);

  return (
    <Stack className="attachment__container" gap="000">
      <Inline
        className={[
          "attachment",
          returnStringIfTrue(disabled, "attachment--disabled")
        ]}
        alignment="center"
        {...(!disabled ? { onClick } : {})}
      >
        {attachmentTypeIcon}
        <div className="attachment__label" {...elementProps}>
          {label}
        </div>
        {attachmentActionIcon}
        {showTooltip && <OTTooltip visible={showTooltip} content={label} />}
      </Inline>
      {action && action(file)}
    </Stack>
  );
};
