import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ModalForm from "@components/molecules/ModalForm/ModalForm";

function AddInsightsBoard(props) {
  const { handleCancel, handleSubmit, errorMessage } = props;
  const { t } = useTranslation();

  const yupSchema = useMemo(() => {
    return yup.object({
      name: yup.string().required(t("common:insights.board.form.name.error"))
    });
  }, [t]);

  return (
    <ModalForm
      submitLabel={t("common:ui.forms.next.label")}
      cancelLabel={t("common:ui.forms.cancel.label")}
      title={t("common:insights.board.create.label")}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      yupSchema={yupSchema}
    >
      {errorMessage && <InlineAlert type="error" message={t(errorMessage)} />}
      <Form.TextField
        label={t("common:insights.board.form.name.label")}
        name="name"
        required={true}
        maxLength={50}
      />
    </ModalForm>
  );
}

export default AddInsightsBoard;
