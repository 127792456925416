export const externalLinkValidationConstants = {
  VALIDATE_EXTERNAL_LINK_OBJECT_REQUEST:
    "VALIDATE_EXTERNAL_LINK_OBJECT_REQUEST",
  VALIDATE_EXTERNAL_LINK_OBJECT_SUCCESS:
    "VALIDATE_EXTERNAL_LINK_OBJECT_SUCCESS",
  VALIDATE_EXTERNAL_LINK_OBJECT_FAILURE:
    "VALIDATE_EXTERNAL_LINK_OBJECT_FAILURE",

  RESET_EXTERNAL_LINK_OBJECT_STORE: "RESET_EXTERNAL_LINK_OBJECT_STORE",
  CLEAR_ERROR: "CLEAR_EXTERNAL_LINK_VALIDATION_ERROR"
};
