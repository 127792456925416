import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@atoms/Button";

import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./MessageModal.scss";

const MessageModal = props => {
  const { message, title, onClickClose } = props;
  const { t } = useTranslation();
  const renderMessage = () => {
    return (
      <div className="message-modal__content">
        <span className="message-modal__message">{message}</span>
      </div>
    );
  };
  return (
    <ModalTemplate
      boxClassName="message-modal"
      title={title}
      onClose={onClickClose}
      content={renderMessage()}
      footer={
        <>
          <Button
            label={t(`common:ui.modals.close.label`)}
            onClick={onClickClose}
          />
        </>
      }
    />
  );
};

MessageModal.propTypes = {
  boxClassName: PropTypes.string,
  title: PropTypes.string.isRequired || PropTypes.element,
  message: PropTypes.string.isRequired,
  onClickClose: PropTypes.func
};

export default MessageModal;
