import React from "react";

import { useTranslation } from "react-i18next";

import { Box, Inline } from "@fermions";

import "./NameValuePair.scss";

interface KeyValueProps {
  name?: string;
  value?: Node;
  action?: Element;
  nameWidth?: string;
  className?: string;
}

export const NameValuePair = ({
  name,
  value,
  action,
  nameWidth,
  className
}: KeyValueProps) => {
  const { t } = useTranslation();

  return (
    <Inline className={["name-value", className]} gap="075">
      <Box
        className={"name-value__name"}
        alignment="left"
        style={{ width: nameWidth }}
      >
        {t(name)}:
      </Box>
      <Box className={"name-value__value"} alignment="left" width="fill">
        {value ?? "-"}
      </Box>
      {action && <Box className={"name-value__action"}>{action}</Box>}
    </Inline>
  );
};
