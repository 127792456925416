import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  AITPermissionConfig,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import { Box } from "@fermions";

import Form from "@atoms/Form/Form.jsx";
import { Icon, IconColor, IconFillStyle } from "@atoms/Icon";

import MatrixTemplate from "@components/templates/MatrixTemplate/MatrixTemplate.tsx";

interface Permission extends AITPermissionConfig {
  selection: string[];
}

type Props = {
  permissions: Permission[];
  availableToOptions: string[];
  crudFormMode: CrudFormMode;
  onChange: (
    actionKey: string,
    availableTo: string
  ) => (value: boolean) => void;
};

function RequestPermissionsMatrix(props: Readonly<Props>) {
  const {
    permissions,
    availableToOptions,
    onChange,
    crudFormMode = CrudFormMode.READ
  } = props;
  const { t } = useTranslation();
  const rowHeaders = useMemo(
    () => permissions?.map(p => t(p.key)),
    [permissions, t]
  );
  const renderIcon = useCallback(
    (
      hasOption: boolean,
      isSelected: boolean,
      permissionKey: string,
      availableTo: string,
      disabled: boolean = false
    ) => {
      if (!hasOption) {
        return <></>;
      }
      if (crudFormMode === CrudFormMode.READ) {
        if (isSelected) {
          return (
            <Icon
              name="check_circle"
              fillStyle={IconFillStyle.FILLED}
              color={IconColor.SECONDARY}
            />
          );
        }
        return (
          <Icon
            name="cancel"
            fillStyle={IconFillStyle.OUTLINED}
            color={IconColor.MUTED}
          />
        );
      }

      return (
        <Form.Checkbox
          value={isSelected}
          name={`permissions.${permissionKey}.${availableTo}`}
          onChange={onChange?.(permissionKey, availableTo)}
          defaultValue={isSelected}
          disabled={disabled}
        />
      );
    },
    [crudFormMode, onChange]
  );

  const getPermissionRows = useCallback(
    ({ className, cellWidth }) => {
      const width = cellWidth;
      const colData = availableToOptions;
      const rowData = rowHeaders;
      return rowData.map(permissionKey => {
        const permission = permissions.find(p => p.key === permissionKey);
        const selectedAsSet = new Set(permission?.selection);
        return (
          <>
            {colData.map(availableToKey => {
              const isAll = selectedAsSet.has("ALL");
              const isView = permissionKey === "VIEW";
              const isSelected =
                selectedAsSet.has(availableToKey) || isAll || isView;
              const hasOption = (
                permission?.availableTo ?? availableToOptions
              ).includes(availableToKey);
              return (
                <td
                  key={`${permissionKey}_${availableToKey}`}
                  className={className}
                  style={{ width }}
                >
                  <Box width="fill" alignment="center">
                    {renderIcon(
                      hasOption,
                      isSelected,
                      permissionKey,
                      availableToKey,
                      isView
                    )}
                  </Box>
                </td>
              );
            })}
          </>
        );
      });
    },
    [availableToOptions, rowHeaders, permissions, renderIcon]
  );

  return (
    <MatrixTemplate
      getRows={getPermissionRows}
      rowHeaders={rowHeaders.map(h =>
        t("admin:ui.manageRequestTypes.permissions.row", { context: h })
      )}
      columnHeaders={availableToOptions.map(h =>
        t("admin:ui.manageRequestTypes.permissions.column", { context: h })
      )}
      header={t("admin:ui.manageRequestTypes.permissions.title")}
    />
  );
}

export default RequestPermissionsMatrix;
