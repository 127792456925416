import React, { useEffect, useRef } from "react";

import { useUIConfig } from "@shared/hooks/useUIConfig";

import UIConfigContext from "@app/helpers/UIConfigContext";

const UIConfigProvider = props => {
  const { uiConfig, fetchUIConfig } = useUIConfig();
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      fetchUIConfig();
    }
  }, [fetchUIConfig]);

  return (
    <UIConfigContext.Provider value={uiConfig}>
      {props.children}
    </UIConfigContext.Provider>
  );
};

export default UIConfigProvider;
