import { useCallback, useEffect, useState } from "react";

import { useThrottledState } from "@shared/hooks/useThrottledState";

const INACTIVITY_TIMER_MS = 1000 * 60 * 5;
const REFRESH_FREQUENCY_MS = 1000 * 10;

export function useHasUserInteraction() {
  const [userLastActive, setUserLastActive] = useThrottledState(
    new Date(),
    REFRESH_FREQUENCY_MS
  );
  const [isUserActive, setIsUserActive] = useState(true);

  const markUserStillActive = useCallback(() => {
    setUserLastActive(new Date());
    setIsUserActive(true);
  }, [setUserLastActive]);

  useEffect(() => {
    let canHandle = true;

    const inactiveTimerId = setTimeout(() => {
      if (canHandle) {
        setIsUserActive(false);
      }
    }, INACTIVITY_TIMER_MS);

    return () => {
      canHandle = false;
      clearTimeout(inactiveTimerId);
    };
  }, [userLastActive]);

  return {
    userLastActive,
    isUserActive,
    markUserStillActive
  };
}
