import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageFinalPackageDocumentsAction } from "@shared/actions";

export function useVisualisation() {
  const dispatch = useDispatch();
  const manageFinalPackageDocuments = useSelector(
    state => state.manageFinalPackageDocuments
  );
  const [visualisationDocuments, setVisualisationDocuments] = useState([]);
  const [loadingVisualisations, setLoadingVisualisations] = useState(false);
  const [uploadingVisualisation, setUploadingVisualisation] = useState(false);
  const [workbookDocument, setWorkbookDocument] = useState(null);
  const [updateVisualisationSuccess, setUpdateVisualisationSuccess] =
    useState(false);
  const [visualisationError, setVisualisationError] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setVisualisationDocuments([]);
    setWorkbookDocument(null);
    if (manageFinalPackageDocuments.visualisation?.documents) {
      const documents = manageFinalPackageDocuments.visualisation.documents;
      const newDocuments = {
        left: null,
        mid: null,
        right: null
      };
      if (documents.length) {
        documents.forEach(visualisationDocument => {
          const key = visualisationDocument?.name.split("/")[0];
          const name = visualisationDocument?.name.split("/")[1];

          if (key == "workbook") {
            setWorkbookDocument({
              id: visualisationDocument?.id,
              name: name || "",
              documentRevisionId: visualisationDocument?.revisionId
            });
          } else {
            newDocuments[key] = {
              id: visualisationDocument?.id,
              name: name || "",
              image: visualisationDocument?.image
            };
          }
        });
        setVisualisationDocuments(newDocuments);
      }
    }
  }, [manageFinalPackageDocuments]);

  useEffect(() => {
    setLoadingVisualisations(manageFinalPackageDocuments.visualisation.loading);
  }, [manageFinalPackageDocuments.visualisation.loading]);

  useEffect(() => {
    setUploadingVisualisation(
      manageFinalPackageDocuments.visualisation.uploading
    );
  }, [manageFinalPackageDocuments.visualisation.uploading]);

  useEffect(() => {
    setVisualisationError(manageFinalPackageDocuments.error);
  }, [manageFinalPackageDocuments.error]);

  useEffect(() => {
    setUpdateVisualisationSuccess(
      manageFinalPackageDocuments.visualisation.updateSuccess
    );
  }, [manageFinalPackageDocuments.visualisation.updateSuccess]);

  useEffect(() => {
    setRefresh(manageFinalPackageDocuments.visualisation.refresh);
  }, [manageFinalPackageDocuments.visualisation.refresh]);

  const fetchVisualisationFolder = useCallback(
    projectId => {
      if (projectId) {
        dispatch(
          manageFinalPackageDocumentsAction.getVisualisationFiles({
            projectId
          })
        );
      }
    },
    [dispatch]
  );

  const fetchVisualisationFiles = useCallback(() => {}, []);

  const uploadVisualisationFile = useCallback(
    ({ projectId, file, folderId, positionNum }) => {
      if (projectId) {
        dispatch(
          manageFinalPackageDocumentsAction.uploadVisualisationFile({
            file,
            projectId,
            folderId,
            positionNum
          })
        );
      }
    },
    [dispatch]
  );

  const updateVisualisationTitle = useCallback(
    ({ name, documentId, projectId }) => {
      if (projectId) {
        dispatch(
          manageFinalPackageDocumentsAction.updateVisualisationTitle({
            name,
            documentId,
            projectId
          })
        );
      }
    },
    [dispatch]
  );

  const deleteVisualisationFile = useCallback(
    ({ document, projectId }) => {
      if (projectId) {
        dispatch(
          manageFinalPackageDocumentsAction.deleteVisualisationFile({
            projectId,
            document
          })
        );
      }
    },
    [dispatch]
  );

  const resetVisualisationError = useCallback(() => {
    dispatch(manageFinalPackageDocumentsAction.resetVisualisationError());
  }, [dispatch]);

  const resetVisualisationFolder = useCallback(() => {
    setVisualisationDocuments([]);
    setWorkbookDocument(null);
    dispatch(manageFinalPackageDocumentsAction.resetVisualisationFolder());
  }, [dispatch]);

  return {
    workbookDocument,
    visualisationDocuments,
    loadingVisualisations,
    uploadingVisualisation,
    updateVisualisationSuccess,
    visualisationError,
    refresh,
    uploadVisualisationFile,
    updateVisualisationTitle,
    deleteVisualisationFile,
    fetchVisualisationFolder,
    fetchVisualisationFiles,
    resetVisualisationFolder,
    resetVisualisationError
  };
}
