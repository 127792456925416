import { Permission } from ".";

export type Resource = {
  id: number;
  name: string;
  permissions: Required<Permission>[];
};

export type ResourcePermission = {
  section: string;
  resources: Resource[];
};

export const enum ResourceName {
  ROLES = "Roles",
  ENTITY_RESTRICTION = "EntityRestriction",
  HOST_USERS = "HostUsers",
  CLIENTS = "Clients",
  CLIENT_USERS = "ClientUsers",
  AUDIT_REPORT = "AuditReport",
  MANAGE_NEWS_AND_IMAGE = "ManageNewsAndImage",
  DATA_EXTRACTION = "DataExtraction",
  ENGAGEMENT_TYPE = "Engagement Type",
  ENGAGEMENTS = "Engagements",
  ENGAGEMENT_MEMBERS = "EngagementMembers",
  PROJECTS_WITH_MEMBERSHIP = "ProjectsWithMembership",
  PROJECTS_WITHOUT_MEMBERSHIP = "ProjectsWithoutMembership",
  PROJECT_MEMBERS = "ProjectMembers",
  MILESTONES = "Milestones",
  MILESTONE_COMMENTS = "MilestoneComments",
  RISKS = "Risks",
  INTERACTIVE_REPORT = "InteractiveReport",
  REQUESTS = "Requests",
  REQUEST_TYPES = "RequestTypes",
  PROJECT_DOCUMENTS = "ProjectDocuments",
  FINAL_PACKAGE = "FinalPackage",
  LABELS = "Labels",
  PERMANENT_FILES = "PermanentFiles",
  INSIGHTS = "Insights",
  ONETEAM_AI = "OneTeamAI"
}

export const enum RoleMode {
  VIEW = "VIEW",
  EDIT = "EDIT",
  CREATE = "CREATE"
}
