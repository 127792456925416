import React from "react";

import { ActionDropdown } from "@molecules/ActionDropdown";

import "./FileItem.scss";

const FileItem = props => {
  const {
    itemName,
    itemId,
    onMenuItemClick,
    onFileClick,
    menuItems,
    menuVisibility,
    quickAccessMenuItems = []
  } = props;
  return (
    <div
      className={`file-item ${onFileClick ? "file-item--clickable" : ""}`}
      onClick={() => onFileClick?.(itemId)}
    >
      <i className="material-icons-outlined file-item__icon">description</i>
      <div className="file-item__name">{itemName}</div>
      <div className="file-item__menu">
        {quickAccessMenuItems.map(menuItem => (
          <i
            title={menuItem.name}
            key={menuItem.name}
            className="material-icons file-item__menu-item-quick-access"
            onClick={() => onMenuItemClick(menuItem)}
          >
            {menuItem.iconName}
          </i>
        ))}
        {menuVisibility && (
          <ActionDropdown
            positionRelative={true}
            menuItems={menuItems}
            onMenuItemClick={onMenuItemClick}
          />
        )}
      </div>
    </div>
  );
};

export default FileItem;
