import React from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants";

import FileAttachmentItem from "../FileAttachmentItem/FileAttachmentItem";
import "./FileAttachmentList.scss";

const documentStatus = systemConstants.project.document.status;
const attachedFileStates = systemConstants.addFiles.attachedFile.state;

const FileAttachmentList = props => {
  const canDownloadAttachment = attachment => {
    if (!props.onFileDownloadClicked) {
      return false;
    }

    const invalidStatuses = [documentStatus.deleted, documentStatus.archived];
    if (props.checkStatus) {
      return !invalidStatuses.includes(attachment.status);
    }
    return true;
  };

  const handleDownloadClick = attachment => {
    return () => {
      if (canDownloadAttachment(attachment)) {
        props.onFileDownloadClicked(attachment);
      }
    };
  };

  const MaybeClickableWrapper = ({ children, disabled, onClick }) => {
    if (!disabled) {
      return (
        <a className="file-attachment-list__container" onClick={onClick}>
          {children}
        </a>
      );
    }
    return <span>{children}</span>;
  };

  const renderAttachment = incomingAttachment => {
    const attachment = (() => {
      if (incomingAttachment instanceof File) {
        return {
          ...incomingAttachment,
          name: incomingAttachment.name,
          state: attachedFileStates?.attached
        };
      }
      return {
        ...incomingAttachment,
        state: attachedFileStates?.attached
      };
    })();

    return (
      <MaybeClickableWrapper
        key={attachment.id ?? attachment.name}
        disabled={!canDownloadAttachment(attachment)}
      >
        <FileAttachmentItem
          file={attachment}
          disabled={!canDownloadAttachment(attachment)}
          showIconOnlyOnHover={props.showIconOnlyOnHover}
          onClick={handleDownloadClick}
          action={props.action}
          itemType={props.itemType}
        />
      </MaybeClickableWrapper>
    );
  };

  if (!props.attachments.length) {
    return <></>;
  }

  return (
    <div
      className={`file-attachment-list file-attachment-list--columns-${props.columnMode}`}
    >
      {props.attachments
        .filter(attachment => !attachment.isDeleted)
        .map(attachment => {
          return renderAttachment(attachment);
        })}
    </div>
  );
};

FileAttachmentList.defaultProps = {
  columnMode: "single",
  showIconOnlyOnHover: false,
  checkStatus: true
};

FileAttachmentList.propTypes = {
  columnMode: PropTypes.oneOf(["single", "flex"]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ),
  showIconOnlyOnHover: PropTypes.bool,
  onFileDownloadClicked: PropTypes.func,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  checkStatus: PropTypes.bool,
  itemType: PropTypes.oneOf(["document", "websheet"])
};

export default FileAttachmentList;
