import { manageDocumentUploadsConstants } from "../constants";
import { manageDocumentUploadsService } from "../services";

export const manageDocumentUploadsActions = {
  uploadDocument,
  clearUploadedDocument,
  updateUploadProgress,
  resetUploadingDocuments
};

function uploadDocument({
  file,
  projectId,
  clientId,
  documentType,
  properties
}) {
  if (projectId && clientId) {
    throw new Error("cannot have both project and client");
  }

  return dispatch => {
    dispatch(request());

    manageDocumentUploadsService
      .uploadDocument({
        dispatch,
        file,
        projectId,
        clientId,
        documentType,
        properties
      })
      .then(
        documentObject => {
          dispatch(success(documentObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageDocumentUploadsConstants.UPLOAD_DOCUMENT_REQUEST,
      metadata: { projectId, clientId, name: file.name, uploadProgress: 0 }
    };
  }
  function success(documentObject) {
    return {
      type: manageDocumentUploadsConstants.UPLOAD_DOCUMENT_SUCCESS,
      metadata: {
        projectId,
        clientId,
        name: file.name,
        documentPathId: documentObject.documentPathId
      }
    };
  }
  function failure(error) {
    return {
      type: manageDocumentUploadsConstants.UPLOAD_DOCUMENT_FAILURE,
      metadata: {
        projectId,
        clientId,
        name: file.name,
        error: error.message
      }
    };
  }
}

function updateUploadProgress({
  projectId,
  clientId,
  name,
  uploadProgress,
  reqSource
}) {
  if (projectId && clientId) {
    throw new Error("cannot have both project and client");
  }
  return {
    type: manageDocumentUploadsConstants.UPDATE_UPLOAD_PROGRESS,
    metadata: { projectId, clientId, name, uploadProgress, reqSource }
  };
}

/**
 *
 * @param {{ projectId?: string, clientId?:string, name:string }}
 * @return {{type:string, metadata:object}}
 */
function clearUploadedDocument({ projectId, clientId, name }) {
  if (projectId && clientId) {
    throw new Error("cannot have both project and client");
  }
  return {
    type: manageDocumentUploadsConstants.CLEAR_UPLOADED_DOCUMENT,
    metadata: { projectId, clientId, name }
  };
}

function resetUploadingDocuments() {
  return {
    type: manageDocumentUploadsConstants.RESET_UPLOADING_DOCUMENTS
  };
}
