import { StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface InsightsBoardSlice {
  currentEngagementTypeChanged: boolean;
  setCurrentEngagementTypeChanged: (
    engagementTypeChanged:
      | InsightsBoardSlice["currentEngagementTypeChanged"]
      | void
  ) => void;
  drilldown: {
    rowId: string;
    columnId: string;
    insightsCardId: string;
    data: { [key: string]: string };
  };
  setDrilldown: (drillDown: InsightsBoardSlice["drilldown"]) => void;
  clearDrilldown: () => void;
}

const createInsightsBoardSlice: StateCreator<InsightsBoardSlice> = set => ({
  currentEngagementTypeChanged: false,
  setCurrentEngagementTypeChanged: engagementTypeChanged =>
    set(() => ({
      currentEngagementTypeChanged: engagementTypeChanged ?? true
    })),
  drilldown: {
    rowId: "",
    columnId: "",
    insightsCardId: "",
    data: {}
  },
  setDrilldown: drilldown =>
    set(() => ({
      drilldown
    })),
  clearDrilldown: () =>
    set(() => ({
      drilldown: {
        rowId: "",
        columnId: "",
        insightsCardId: "",
        data: {}
      }
    }))
});

export const useInsightsBoardStore = create<InsightsBoardSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createInsightsBoardSlice(...a)
      }),
      {
        name: "ot-app-store"
      }
    ),
    { name: "ot-app-store", enabled: process.env.NODE_ENV === "development" }
  )
);
