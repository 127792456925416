import React from "react";

import PropTypes from "prop-types";

import "./CommentButton.scss";

const CommentButton = ({ id, title, icon, state, onClick }) => {
  return (
    <div onClick={e => onClick(e, id)} className="comment-icon">
      <i
        title={title}
        className={`material-symbols-${
          state === "outlined" ? "outlined" : "filled"
        }
             comment-icon-${state}`}
      >
        {icon}
      </i>
    </div>
  );
};

CommentButton.defaultProps = {
  title: "Add Comment",
  icon: "chat_bubble",
  state: "outlined"
};

CommentButton.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  state: PropTypes.oneOf(["active", "inactive", "outlined", "filled"]),
  onClick: PropTypes.func.isRequired
};

export default CommentButton;
