export function DataFormater(number) {
  const absoluteValue = Math.abs(number);
  const isNegative = number < 0;
  let result;
  if (absoluteValue >= 1000000000) {
    result = Math.round((absoluteValue / 1000000000) * 10) / 10 + "B";
  } else if (absoluteValue >= 1000000) {
    result = Math.round((absoluteValue / 1000000) * 10) / 10 + "M";
  } else if (absoluteValue >= 1000) {
    result = Math.round((absoluteValue / 1000) * 10) / 10 + "K";
  } else {
    result = absoluteValue.toString();
  }
  return isNegative ? `- ${result}` : result;
}

export const parseWaterfallData = ({ keys, values }) => {
  const parseDataHelper = ({ keys, curr, prev, index }) => {
    // 0=waterfall data items, 1=line date items
    const waterfallDataKeys = keys[0];
    const lineDataKeys = keys[1];
    let prevAbs = 0;
    if (index !== values.length - 1 && index !== 0) {
      const prevBase = prev[`${waterfallDataKeys}_base`];
      prevAbs = prevBase + prev[waterfallDataKeys];
    }
    curr[`${waterfallDataKeys}_base`] = prevAbs;
    if (lineDataKeys) {
      if (index !== values.length - 1 && index !== 0) {
        curr[lineDataKeys] += prev[lineDataKeys];
      }
    }
    return curr;
  };
  const newValues = [];
  values.forEach((value, index) => {
    const newValue = parseDataHelper({
      keys,
      curr: value,
      prev: index > 0 ? values[index - 1] : {},
      index
    });
    newValues.push(newValue);
  });
  return { keys, values: newValues };
};

const isKeyForValue = key => key !== "label" && !key.includes("_base");

export const filterData = (
  data,
  { displayZero = false, showTopValues = null } = {}
) => {
  let relevantData = [...data];
  if (showTopValues) {
    // we need index so we can cross-reference back into the original list
    const workingSet = data.map((d, idx) => {
      const [, value] = Object.entries(d).find(([key]) => isKeyForValue(key));
      return { idx, value };
    });
    const topValues = workingSet
      .sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
      .slice(0, showTopValues);
    const indexSet = new Set(topValues.map(c => c.idx));
    relevantData = relevantData.filter((_, idx) => indexSet.has(idx));
  }

  if (displayZero) {
    return relevantData;
  }

  const result = relevantData.filter(
    d =>
      !Object.entries(d).some(
        ([key, value]) => isKeyForValue(key) && value === 0
      )
  );

  return result;
};

export const getAdaptiveHeight = ({
  baseHeight,
  increaseFactor,
  compensationFactor,
  barNums,
  chartWidth,
  maxHeight
}) =>
  Math.min(
    maxHeight,
    baseHeight +
      (increaseFactor * (1 + barNums / compensationFactor)) / chartWidth
  );

export function getChartValues(chart, tableData, id, drilldownFilter) {
  const chartWithValues = {
    ...chart,
    id
  };
  const axisLabel = chart.axis.tableColumn;
  const chartValueLabels = chart.chartValues.reduce(
    (a, c) => ({ ...a, [c.tableColumn]: [] }),
    {}
  );
  let columnIndex = -1;

  let rowsToRender = tableData.rows;
  if (drilldownFilter?.label) {
    const cellIndex = tableData.columns.findIndex(
      c => c.name === drilldownFilter.filterColumn
    );
    if (cellIndex !== -1) {
      const applyRowFilters = row =>
        row?.cells[cellIndex]?.value === drilldownFilter?.label;
      rowsToRender = tableData.rows?.filter(applyRowFilters);
    }
  }

  tableData.columns.forEach((c, i) => {
    if (c.name === axisLabel) {
      columnIndex = i;
    } else if (chartValueLabels[c.name]) {
      chartValueLabels[c.name] = rowsToRender.reduce((acc, row) => {
        const value = row.cells[i].value;
        if (!Number.isNaN(value)) {
          acc.push(value);
        }
        return acc;
      }, []);
    }
  });

  if (columnIndex === -1) {
    return null;
  }

  const axisValues = rowsToRender.map(r => r.cells[columnIndex].value);
  chartWithValues.axis.label = axisLabel;
  chartWithValues.axis.values = axisValues;
  chartWithValues.chartValues = Object.keys(chartValueLabels).map(
    chartValueLabel => ({
      label: chartValueLabel,
      values: chartValueLabels[chartValueLabel]
    })
  );
  return chartWithValues;
}

/**
 * Returns an object with the chart colors, axis colors, and legend text color
 * @param {"waterfall"|"bar"|"pie"} type
 * @param {*} chartVariables
 * @returns {Object} {chartColors: Array, axisColors: Object, legendTextColor: String}
 */
export const getChartColors = (type, chartVariables) => {
  const chartColors = [];
  const axisColors = {};
  let legendTextColor = "";
  Object.entries(chartVariables).forEach(([colorVariableName, value]) => {
    if (colorVariableName.startsWith(`${type}Chart`)) {
      if (colorVariableName.endsWith("AxisLabel")) {
        axisColors.label = value;
      } else if (colorVariableName.endsWith("AxisStroke")) {
        axisColors.stroke = value;
      } else if (colorVariableName.endsWith("HoverOverlay")) {
        axisColors.hoverOverlay = value;
      } else if (colorVariableName.endsWith(`LegendText`)) {
        legendTextColor = value;
      } else {
        chartColors.push(value);
      }
    }
  });
  return {
    chartColors,
    axisColors,
    legendTextColor
  };
};

export const formatHoverText = (value, type, infoText) => {
  const additionalInfo = infoText ? ` (${infoText})` : "";
  if (type === "number") {
    return (
      new Intl.NumberFormat("en-AU", {
        style: "decimal",
        currency: "AUD"
      }).format(value) + additionalInfo
    );
  } else if (type === "currency") {
    return (
      new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD"
      }).format(value) + additionalInfo
    );
  } else if (type === "percentage") {
    const percentFormat = new Intl.NumberFormat("en-AU", {
      maximumFractionDigits: 2,
      style: "decimal"
    });
    const formattedValue = percentFormat.format(value) + "%";
    return formattedValue + additionalInfo;
  }
};

const getInterval = ({ isDrilldown, barSize, chartWidth, barNums }) => {
  if (isDrilldown) {
    return chartWidth < 460 ? 1 : 0;
  } else {
    const intervalRatio = barSize / (chartWidth / barNums);
    return intervalRatio < 1 ? 0 : Math.round(intervalRatio);
  }
};

export const getBarAndWaterfallChartFactors = ({
  chartWidth,
  barNums,
  isDrilldown
}) => {
  const rawBarSize = Math.floor((chartWidth - 12) / barNums);
  const minBarSize = 60;
  const maxBarSize = 100;
  const barSize = Math.max(minBarSize, Math.min(rawBarSize, maxBarSize));
  const interval = getInterval({ isDrilldown, barSize, chartWidth, barNums });
  const tickWidth = Math.max(minBarSize, (rawBarSize - 5) * (1 + interval));
  return {
    tickWidth,
    interval,
    barSize
  };
};
