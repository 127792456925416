import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const insightsService = createApi({
  reducerPath: "insightsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Menus", "Insights", "InsightsBoard"],
  endpoints: builder => ({
    getInsightsMenu: builder.query({
      query: () => "/api/insightsBoard/menu",
      providesTags: ["Menus"]
    }),
    getAllInsightsBoard: builder.query({
      query: () => "/api/insightsBoard",
      providesTags: ["InsightsBoard"]
    }),
    getInsightsBoard: builder.query({
      query: id => `/api/insightsBoard/${id}`
    }),
    getInsights: builder.query({
      query: ({ insightsBoardId, engagementTypeId }) =>
        `/api/insightsBoard/${insightsBoardId}/insights?engagementTypeId=${engagementTypeId}`
    }),
    getInsightsDrilldown: builder.query({
      query: ({ insightsBoardId, type, engagementTypeId, params }) => ({
        url: `/api/insightsBoard/${insightsBoardId}/insights/drilldown`,
        method: "POST",
        body: { type, params: { ...params, engagementTypeId } }
      })
    }),
    addInsightsBoard: builder.mutation({
      query: insightsBoard => ({
        url: `/api/insightsBoard/`,
        method: "POST",
        body: insightsBoard
      }),
      invalidatesTags: ["InsightsBoard"]
    }),
    updateInsightsBoard: builder.mutation({
      query: insightsBoard => ({
        url: `/api/insightsBoard/${insightsBoard.id}`,
        method: "PUT",
        body: insightsBoard
      }),
      invalidatesTags: ["InsightsBoard"]
    })
  })
});

export const {
  useGetAllInsightsBoardQuery,
  useGetInsightsMenuQuery,
  useGetInsightsBoardQuery,
  useGetInsightsQuery,
  useLazyGetInsightsDrilldownQuery,
  useAddInsightsBoardMutation,
  useUpdateInsightsBoardMutation,
  util: insightsServiceUtil
} = insightsService;
