import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./List.scss";

const List = ({ list, className }) => {
  return (
    <ul data-testid="ot-list" className={`ot-list ${className || ""}`}>
      {list.map(data => (
        <li key={data["id"]}>
          {data["linkTo"] ? (
            <Link to={`${data["linkTo"]}`}>{data["name"]}</Link>
          ) : (
            <>{data["name"]}</>
          )}
        </li>
      ))}
    </ul>
  );
};

List.defaultProps = {
  list: []
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      linkTo: PropTypes.string
    })
  ),
  className: PropTypes.string
};

export default List;
