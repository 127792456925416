import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/index";

import { useAuthUser, useGetProjectByIdQuery } from "@shared/hooks";

export function useCheckProjLevelSettingsUploadDocument(
  project,
  onChangeCurrentProject,
  projectId
) {
  const navigate = useNavigate();
  const { user } = useAuthUser();

  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || project?.id === projectId }
    );

  useEffect(() => {
    if (projectToSelect && project?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, project, onChangeCurrentProject]);

  useEffect(() => {
    if (isInvalidProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [isInvalidProject, navigate]);

  useEffect(() => {
    if (!user.isHostUser) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [navigate, user]);

  return {};
}
