import { manageProjectDocumentsConstants } from "../constants";

const initialState = {
  loading: false,
  documentsLoading: false,
  isDocumentLoaded: false,
  foldersAndDocuments: { folders: [], documents: [] },
  requestFoldersAndDocuments: { folders: [], documents: [] },
  updatedDocument: false,
  uploadedDocuments: [],
  newUploadedDocuments: [],
  documentRevisions: [],
  deletedDocument: false,
  movedDocument: false,
  selectedProjectDocument: false,
  documentsBySearchString: [],
  archivedDocuments: [],
  updatedDocumentWithNewRevsion: false,
  updateDocumentSuccess: false,
  revertedDocument: false,
  taggedDocuments: [],
  tags: [],
  fetchingProjectTags: false,
  fetchedProjectTags: false,
  addedProjectTag: false,
  addingProjectTag: false,
  addProjectTagError: false,
  updatedProjectTag: false,
  deletedProjectTag: false,
  deletedProjectTagError: false,
  error: ""
};

export function manageProjectDocuments(state = initialState, action = {}) {
  switch (action.type) {
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_REQUEST:
      return {
        ...state,
        updatedDocumentWithNewRevsion: null,
        updateDocumentSuccess: false,
        loading: true
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDocumentSuccess: true,
        updatedDocumentWithNewRevsion: action.updatedObject.document,
        foldersAndDocuments: {
          folders:
            action.updatedObject.updatedProjectFoldersAndDocuments.folders,
          documents:
            action.updatedObject.updatedProjectFoldersAndDocuments.documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_FAILURE:
      return {
        ...state,
        loading: false,
        updatedDocumentWithNewRevsion: null,
        updateDocumentSuccess: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedDocuments: action.documents,
        error: ""
      };
    case manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        archivedDocuments: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS:
      return {
        ...state,
        loading: false,
        documentsBySearchString: action.documents,
        error: ""
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_FAILURE:
      return {
        ...state,
        loading: false,
        documentsBySearchString: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        documentRevisions: action.revisions,
        error: ""
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        documentRevisions: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        movedDocument: action.movedDocumentObject.document,
        foldersAndDocuments: {
          folders:
            action.movedDocumentObject.updatedProjectFoldersAndDocuments
              .folders,
          documents:
            action.movedDocumentObject.updatedProjectFoldersAndDocuments
              .documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        movedDocument: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        updateDocumentSuccess: false,
        deletedDocument: null
      };
    case manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDocumentSuccess: true,
        deletedDocument: action.deletedDocumentObject.deletedDocument,
        foldersAndDocuments: {
          folders:
            action.deletedDocumentObject.updatedProjectFoldersAndDocuments
              .folders,
          documents:
            action.deletedDocumentObject.updatedProjectFoldersAndDocuments
              .documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        updateDocumentSuccess: false,
        deletedDocument: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST:
      return {
        ...state,
        documentsLoading: true
      };
    case manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        isDocumentLoaded: true,
        foldersAndDocuments: {
          folders: action.projectFoldersAndDocuments.folders,
          documents: action.projectFoldersAndDocuments.documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        isDocumentLoaded: false,
        foldersAndDocuments: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requestFoldersAndDocuments: {
          folders: action.projectFoldersAndDocuments.folders,
          documents: action.projectFoldersAndDocuments.documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        requestFoldersAndDocuments: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedDocument: action.updatedObject.document,
        foldersAndDocuments: {
          folders:
            action.updatedObject.updatedProjectFoldersAndDocuments.folders,
          documents:
            action.updatedObject.updatedProjectFoldersAndDocuments.documents
        },
        error: ""
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_FAILURE:
      return {
        ...state,
        loading: false,
        updatedDocument: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadedDocuments: [
          ...state.uploadedDocuments,
          action.uploadedObject.document
        ],
        error: ""
      };
    case manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.UPLOAD_NEW_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectDocumentsConstants.UPLOAD_NEW_PROJECT_DOCUMENT_SUCCESS:
      const updatedUploadedDocuments = [
        ...state.newUploadedDocuments,
        action.uploadedObject.document
      ];
      return {
        ...state,
        loading: false,
        newUploadedDocuments: updatedUploadedDocuments,
        error: ""
      };
    case manageProjectDocumentsConstants.UPLOAD_NEW_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageProjectDocumentsConstants.RESET_UPDATED_DOCUMENT:
      return {
        ...state,
        updatedDocument: false
      };
    case manageProjectDocumentsConstants.RESET_UPLOADED_DOCUMENT:
      return {
        ...state,
        uploadedDocuments: []
      };
    case manageProjectDocumentsConstants.RESET_NEW_UPLOADED_DOCUMENT:
      return {
        ...state,
        newUploadedDocuments: []
      };
    case manageProjectDocumentsConstants.RESET_DOCUMENT_REVISIONS:
      return {
        ...state,
        documentRevisions: []
      };
    case manageProjectDocumentsConstants.RESET_RESTORED_DOCUMENT:
      return {
        ...state,
        restoredDocument: false
      };
    case manageProjectDocumentsConstants.RESET_DELETED_DOCUMENT:
      return {
        ...state,
        deletedDocument: false
      };
    case manageProjectDocumentsConstants.RESET_MOVED_DOCUMENT:
      return {
        ...state,
        movedDocument: false
      };
    case manageProjectDocumentsConstants.SET_SELECTED_PROJECT_DOCUMENT:
      return {
        ...state,
        selectedProjectDocument: action.document
      };
    case manageProjectDocumentsConstants.RESET_SELECTED_PROJECT_DOCUMENT:
      return {
        ...state,
        selectedProjectDocument: false
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_REQUEST: {
      return {
        ...state,
        loading: true,
        taggedDocuments: [],
        error: ""
      };
    }
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        taggedDocuments: action.taggedDocuments || []
      };
    case manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.GET_ALL_TAGS_REQUEST:
      return {
        ...state,
        tags: null,
        fetchingProjectTags: true,
        fetchedProjectTags: false
      };

    case manageProjectDocumentsConstants.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags,
        fetchingProjectTags: false,
        fetchedProjectTags: true
      };

    case manageProjectDocumentsConstants.GET_ALL_TAGS_FAILURE:
      return {
        ...state,
        tags: null,
        fetchingProjectTags: false,
        fetchedProjectTags: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.RESET:
      return initialState;
    case manageProjectDocumentsConstants.RESET_ALL_TAGS:
      return {
        ...state,
        tags: [],
        fetchingProjectTags: false,
        fetchedProjectTags: false,
        error: ""
      };
    case manageProjectDocumentsConstants.RESTORE_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        revertedDocument: true,
        error: ""
      };
    case manageProjectDocumentsConstants.RESTORE_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        revertedDocument: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.RESET_REVERTED_DOCUMENT:
      return {
        ...state,
        revertedDocument: false
      };
    case manageProjectDocumentsConstants.ADD_PROJECT_TAG_REQUEST:
      return {
        ...state,
        addedProjectTag: false,
        addingProjectTag: true
      };
    case manageProjectDocumentsConstants.ADD_PROJECT_TAG_FAILURE:
      return {
        ...state,
        addedProjectTag: false,
        addingProjectTag: false,
        addProjectTagError: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.ADD_PROJECT_TAG_SUCCESS:
      return {
        ...state,
        addingProjectTag: false,
        addedProjectTag: action.tag
      };
    case manageProjectDocumentsConstants.RESET_ADD_PROJECT_TAG:
      return {
        ...state,
        addingProjectTag: false,
        addedProjectTag: false,
        addProjectTagError: false
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_TAG_FAILURE:
      return {
        ...state,
        updatedProjectTag: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectDocumentsConstants.UPDATE_PROJECT_TAG_SUCCESS:
      return {
        ...state,
        updatedProjectTag: action.tag
      };
    case manageProjectDocumentsConstants.DELETE_PROJECT_TAG_FAILURE:
      return {
        ...state,
        deletedProjectTag: false,
        deletedProjectTagError: action.error
      };
    case manageProjectDocumentsConstants.DELETE_PROJECT_TAG_SUCCESS:
      return {
        ...state,
        deletedProjectTag: action.deletedProjectTag
      };
    case manageProjectDocumentsConstants.RESET_DELETE_PROJECT_TAG:
      return { ...state, deletedProjectTag: false, deletedProjectTagError: "" };
    default:
      return state;
  }
}
