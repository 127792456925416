export const manageClientTagsConstants = {
  GET_CLIENT_TAGS_REQUEST: "GET_CLIENT_TAGS_REQUEST",
  GET_CLIENT_TAGS_SUCCESS: "GET_CLIENT_TAGS_SUCCESS",
  GET_CLIENT_TAGS_FAILURE: "GET_CLIENT_TAGS_FAILURE",

  ADD_CLIENT_TAGS_REQUEST: "ADD_CLIENT_TAGS_REQUEST",
  ADD_CLIENT_TAGS_SUCCESS: "ADD_CLIENT_TAGS_SUCCESS",
  ADD_CLIENT_TAGS_FAILURE: "ADD_CLIENT_TAGS_FAILURE",

  UPDATE_CLIENT_TAGS_REQUEST: "UPDATE_CLIENT_TAGS_REQUEST",
  UPDATE_CLIENT_TAGS_SUCCESS: "UPDATE_CLIENT_TAGS_SUCCESS",
  UPDATE_CLIENT_TAGS_FAILURE: "UPDATE_CLIENT_TAGS_FAILURE",

  DELETE_CLIENT_TAGS_REQUEST: "DELETE_CLIENT_TAGS_REQUEST",
  DELETE_CLIENT_TAGS_SUCCESS: "DELETE_CLIENT_TAGS_SUCCESS",
  DELETE_CLIENT_TAGS_FAILURE: "DELETE_CLIENT_TAGS_FAILURE",

  RESET: "RESET_CLIENT_TAGS"
};
