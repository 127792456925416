import React, { useEffect, useRef, useState } from "react";

import { Box } from "@fermions";

import { Icon } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import {
  DropdownItemType,
  Positioning
} from "@components/molecules/DropdownList/DropdownHelper";

import "./InteractiveReportFilterDropdown.scss";

interface InteractiveReportFilterDropdownProps {
  items: DropdownItemType[];
  onChange: (values: DropdownItemType[]) => void;
}

export const InteractiveReportFilterDropdown = ({
  items,
  onChange
}: InteractiveReportFilterDropdownProps) => {
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState<DropdownItemType[]>([]);

  useEffect(() => {
    setCheckedItems(items.filter(i => !i.hidden));
  }, [items]);

  const toggleColumnDropdown = () => {
    setIsColumnFilterVisible(prev => !prev);
  };

  const updateVisibleColumns = (visibleColumns: DropdownItemType[]) => {
    items.forEach((item: DropdownItemType, index: number) => {
      const isVisible = visibleColumns.some(
        visibleColumn => visibleColumn.name === item.name
      );
      items[index].hidden = !isVisible;
      items[index].hiddenByFilter = !isVisible; // used as hint to let others know this is hidden by a filter
    });
    onChange(items);
  };

  const toggleRef = useRef();

  return (
    <Box className="ot-interactive-report-dropdown-filter" alignment="center">
      <Box
        className="filter-icon"
        onClick={toggleColumnDropdown}
        ref={toggleRef}
        alignment="center"
      >
        <Icon name="visibility" />
      </Box>
      <DropdownList
        items={items}
        parentRef={toggleRef}
        isMultiSelect
        selectedValues={checkedItems}
        setSelectedValues={updateVisibleColumns}
        isOpen={isColumnFilterVisible}
        showSearch={false}
        positioning={Positioning.BOTTOM_LEFT}
      />
    </Box>
  );
};
