import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers";

import "./Toggle.scss";

// TODO: remove - replace with ToggleInput from @molecules/inputs

const Toggle = ({
  label,
  value,
  onChange,
  disabled = false,
  showIndicatorLabels = true,
  labelPosition = "right"
}) => {
  const indicatorLabelClass = on =>
    on == value
      ? "toggle__indicator-label toggle__indicator-label--bold"
      : "toggle__indicator-label";

  const { t } = useTranslation();

  const disabledClass = disabled ? "toggle--disabled" : null;

  const renderLabel = () => (
    <span className={`toggle__label toggle__label--${labelPosition}`}>
      {label}
    </span>
  );

  return (
    <label className={classNames(["toggle", disabledClass])}>
      {labelPosition === "left" && renderLabel()}

      {showIndicatorLabels && (
        <span className={indicatorLabelClass(false)}>
          {t("common:ui.toggle.off")}
        </span>
      )}
      <input
        aria-label="On/Off"
        type="checkbox"
        className="toggle__input"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="toggle__track">
        <span className="toggle__indicator"></span>
      </span>
      {showIndicatorLabels && (
        <span className={`${indicatorLabelClass(true)}`}>
          {t("common:ui.toggle.on")}
        </span>
      )}

      {labelPosition === "right" && renderLabel()}
    </label>
  );
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showIndicatorLabels: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["left", "right"])
};
export default Toggle;
