import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import "./SubNav.scss";

const SubNav = props => {
  const { setSubNavActive } = props;
  const [isMenuActive, setMenuActive] = useState(false);
  useEffect(() => {
    const activeItem = props.menuItems.find(item => {
      return props.currentPath === item.path;
    });
    setMenuActive(!!activeItem);
  }, [props.currentPath, props.menuItems]);

  useEffect(() => {
    props.setSubNavActive?.(isMenuActive);
    // Added in the below line to ensure that sub nav active is triggered on mount
    // Putting it outside a useEffect triggers unnecessarily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubNavActive?.(isMenuActive);
  }, [setSubNavActive, isMenuActive]);

  return (
    <div className="sub-nav">
      <a
        className={`sub-nav__btn
        ${isMenuActive ? "sub-nav__btn--active" : ""}
        `}
        data-testid="sub-nav-label"
      >
        <span className="sub-nav__label">{props.label}</span>
      </a>
      <div
        style={{ left: props.subNavLeftPosition }}
        id={`${isMenuActive ? "sub-nav-active" : ""}`}
        className={`sub-nav__content
        ${isMenuActive ? "sub-nav__content--active" : ""} ${
          props.navigationStyle === "OT1" ? "sub-nav__content--OT1" : ""
        }`}
        data-testid="sub-nav-content"
      >
        {props.menuItems.map(item => {
          if (props.handleNavigate) {
            return (
              <button
                key={item.name}
                onClick={() => {
                  props.handleNavigate(item.path, { state: props.state });
                }}
                data-testid="testNavItem"
                className={`sub-nav__content__link ${
                  props.currentPath === item.path
                    ? "sub-nav__content__link--active"
                    : ""
                }`}
              >
                <span>{item.name}</span>
              </button>
            );
          } else {
            // Keeping Link to handle pages with using old page templates
            return (
              <Link
                key={item.name}
                className={`sub-nav__content__link ${
                  props.currentPath === item.path
                    ? "sub-nav__content__link--active"
                    : ""
                }`}
                to={item.path}
              >
                {item.name}
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SubNav;
