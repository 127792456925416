import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import { useUpdateQuery } from "@shared/hooks/useUpdateQuery";

import Popup from "@shared-components/popup/Popup";

import { ButtonVariant } from "@atoms/Button";
import { Text } from "@atoms/Text";

import Form from "@components/atoms/Form/Form";

import ModalForm from "../../molecules/ModalForm/ModalForm";

export const RejectActionModalForm = props => {
  const { visibility, handleSubmit, handleClose, query, document, title } =
    props;
  const { updateQuery } = useUpdateQuery();
  const files = useMemo(
    () => [
      {
        isDeleted: false,
        isNew: false,
        id: document?.id,
        name: document?.name,
        projectId: query?.projectId
      }
    ],
    [document?.id, document?.name, query?.projectId]
  );

  const yupSchema = yup.object({
    reason: yup.string().required("Please enter reason")
  });

  const onUpdate = useCallback(
    data => {
      updateQuery(
        {
          ...query,
          reassign: true,
          status: systemConstants.project.queries.status.closed,
          assignedTo: query.requestedBy,
          properties: {
            flag: systemConstants.project.queries.flags.rejected,
            reason: data.reason
          },
          files
        },
        handleSubmit
      );
    },
    [files, handleSubmit, query, updateQuery]
  );

  return (
    <Popup
      visibility={visibility}
      handleOutsideClick={handleClose}
      width="50rem"
    >
      <ModalForm
        yupSchema={yupSchema}
        boxClassName=""
        title={`Reject ${title}`}
        handleCancel={handleClose}
        handleSubmit={onUpdate}
        submitButtonVariant={ButtonVariant.DANGER}
        submitLabel={`Reject ${title}`}
      >
        <Text text={query?.description || ""} />
        <Form.TextArea name="reason" label="Reason" maxLength={500} />
      </ModalForm>
    </Popup>
  );
};

RejectActionModalForm.propTypes = {
  visibility: PropTypes.bool,
  query: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  document: PropTypes.object,
  title: PropTypes.string
};

export default RejectActionModalForm;
