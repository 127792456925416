import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import { useAddClientMutation, useToasts } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";

import { Button, ButtonVariant } from "@atoms/Button";

import { ClientForm } from "@organisms/ClientForm";

import FormPageTemplate from "@components/templates/FormPageTemplate";

export const AddClient = () => {
  const { t } = useTranslation();
  const goBack = useGoBack();

  const navigate = useNavigate();
  const { showSuccess } = useToasts();

  const [addClient, { isSuccess, isLoading: isSubmitting, error }] =
    useAddClientMutation();

  const [clientLogoModal, setClientLogoModal] = useState(<></>);
  const [logo, setLogo] = useState();

  const handleAddClient = useCallback(
    data => {
      const client = {
        name: data.name?.trim(),
        acn: data.acn?.trim(),
        abn: data.abn?.trim(),
        logo
      };

      addClient({ client })
        .unwrap()
        .then(addedClient => {
          showSuccess({
            key: "common:ui.client.added.message",
            actions: (
              <Button
                label={t("admin:ui.navigation.manageUsers.label", {
                  context: "CLIENT"
                })}
                variant={ButtonVariant.TEXT}
                onClick={() => {
                  navigate(`/admin/clients/${addedClient?.id}/users`);
                }}
              />
            )
          });
          goBack();
        });
    },
    [addClient, goBack, logo, navigate, showSuccess, t]
  );

  const yupSchema = yup.object({
    name: yup.string().required(t("common:ui.client.field.nameError"))
  });

  const formContents = useMemo(
    () => (
      <ClientForm
        setClientLogoModal={setClientLogoModal}
        logo={logo}
        setLogo={setLogo}
      />
    ),
    [logo]
  );

  const renderAddClientActions = isValid => {
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={goBack}
        />
        <Button
          disabled={!isValid}
          label={t("common:ui.addClient.submitLabel")}
          type="submit"
        />
      </>
    );
  };

  return (
    <>
      <FormPageTemplate
        title={t("common:ui.addClient.title")}
        sticky
        form={{
          contents: formContents,
          yupSchema,
          handleSubmit: handleAddClient,
          handleCancel: goBack,
          disabled: isSubmitting || isSuccess,
          renderPageActions: renderAddClientActions
        }}
        renderRawForm={true}
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        other={{
          error: error ? getErrorMessage(error, t) : ""
        }}
      />
      {clientLogoModal}
    </>
  );
};
