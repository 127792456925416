import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { classNames } from "@app/helpers/componentHelpers";

import { Box, Inline, Stack } from "@fermions";

import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import Avatar from "@components/atoms/Avatar";

import FileAttachmentList from "../FileAttachmentList/FileAttachmentList";
import "./ConversationMessage.scss";

const ConversationMessage = props => {
  const { locale, options } = useLocaleDate();
  const { t } = useTranslation();

  const renderMessageTypePill = useCallback(() => {
    if (!props.type || props.type === "MESSAGE") {
      return <></>;
    }

    if (props.type === "APPROVE") {
      return (
        <Stack alignment="right">
          <Pill
            label={t("requests:requests.configured.flags_APPROVED")}
            variant={PillVariant.SUCCESS}
            fillStyle={PillFillStyle.FILLED}
            iconName="check_circle"
          />
        </Stack>
      );
    }

    if (props.type === "REJECT") {
      return (
        <Stack alignment="right">
          <Pill
            label={t("requests:requests.configured.flags_REJECTED")}
            variant={PillVariant.ERROR}
            fillStyle={PillFillStyle.FILLED}
            iconName="cancel"
          />
        </Stack>
      );
    }
  }, [props.type, t]);
  return (
    <div
      className={`conversation-message conversation-message--${props.align}`}
    >
      <Box className="conversation-message__profile">
        <Avatar user={props.user} width="32px" height="32px" />
      </Box>
      <Stack className="conversation-message__contents">
        <Box className="conversation-message__contents-name">
          {props.user.name}
        </Box>
        {
          <Stack className="conversation-message__contents-body" gap="050">
            {
              <Inline
                className={classNames([
                  "conversation-message__contents-message",
                  `conversation-message__contents-message--${props.align}`
                ])}
                gap="100"
                alignment="left"
              >
                {renderMessageTypePill()}
                {props.message}
              </Inline>
            }
            {props.attachments.length > 0 && (
              <FileAttachmentList
                attachments={props.attachments}
                onFileDownloadClicked={props.onFileDownloadClicked}
              />
            )}
            {props.messageFooter && (
              <Box
                className={classNames([
                  "conversation-message__contents-message",
                  `conversation-message__contents-message--${props.align}`
                ])}
              >
                {props.messageFooter}
              </Box>
            )}
          </Stack>
        }
        <div className="conversation-message__contents-date">
          {dateFormatter(props.updatedAt, locale, options.longFormat)}
        </div>
        <div className="conversation-message__contents-bonus"></div>
      </Stack>
    </div>
  );
};

ConversationMessage.defaultProps = {
  align: "left"
};

ConversationMessage.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  message: PropTypes.string,
  messageFooter: PropTypes.element,
  updatedAt: PropTypes.string.isRequired,
  onFileDownloadClicked: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default ConversationMessage;
