import React, { useEffect } from "react";

import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import { useAuthUser, useStaticAssets } from "@shared/hooks";
import {
  useLazyGetFooterTemplateQuery,
  useLazyGetServerBuildQuery
} from "@shared/services/footerService";

import clientBuild from "@app/build.json";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  const { isUrlAvailable, getUrl } = useStaticAssets();
  const { user } = useAuthUser();
  const [getFooterQuery, { data: template }] = useLazyGetFooterTemplateQuery();
  const [getServerQuery, { data: serverBuild }] = useLazyGetServerBuildQuery();

  useEffect(getServerQuery, [getServerQuery]);

  useEffect(() => {
    if (isUrlAvailable) {
      getFooterQuery(
        {
          url: getUrl(
            user?.isHostUser && user?.isLoggedIn
              ? "hostFooter.handlebars"
              : "footer.handlebars"
          )
        },
        true //prefer cache
      );
    }
  }, [
    user?.isHostUser,
    user?.isLoggedIn,
    getFooterQuery,
    isUrlAvailable,
    getUrl
  ]);

  useEffect(() => {
    if (isUrlAvailable && template) {
      const footerData = {
        logoImagePath: getUrl("footerLogo.png"),
        clientBuildVersion: clientBuild.version,
        serverBuildVersion: serverBuild?.version,
        schemaVersion: serverBuild?.["oneteam-schema"]
      };
      const elements = document.querySelectorAll("[data-i18n]");

      elements.forEach(e => {
        e.innerHTML = DOMPurify.sanitize(t(e.dataset.i18n, footerData), {
          ADD_ATTR: ["target"]
        });
      });
    }
  }, [t, isUrlAvailable, template, getUrl, serverBuild?.version]);

  return (
    <div
      className="footer--parent"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(template, {
          ADD_ATTR: ["target"]
        })
      }}
    ></div>
  );
};

export default Footer;
