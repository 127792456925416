import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageQueryResponsesActions } from "@shared/actions";

export function useAddQueryResponse() {
  const dispatch = useDispatch();
  const manageQueryResponses = useSelector(state => state.manageQueryResponses);
  const [isAdded, setIsAdded] = useState();
  const [queryResponseError, setQueryResponseError] = useState(null);

  useEffect(() => {
    setIsAdded(manageQueryResponses.isAdded);
  }, [manageQueryResponses.isAdded]);

  useEffect(() => {
    setQueryResponseError(manageQueryResponses.error);
  }, [manageQueryResponses.error]);

  const resetQuery = useCallback(() => {
    dispatch(manageQueryResponsesActions.reset());
  }, [dispatch]);

  const addResponse = useCallback(
    responseData => {
      dispatch(manageQueryResponsesActions.addQueryResponse(responseData));
    },
    [dispatch]
  );

  const clearError = useCallback(() => {
    dispatch(manageQueryResponsesActions.clearError());
  }, [dispatch]);

  return {
    isAdded,
    queryResponseError,
    resetQuery,
    addResponse,
    clearError
  };
}
