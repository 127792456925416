import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { ProjectTemplateType } from "@shared/services/projectTemplateService";

import ions from "@ions";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Inline, Stack } from "@fermions";

import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";
import { Pill, PillSize } from "@atoms/Pill";

import "./ProjectTemplate.scss";

interface ProjectTemplateProps {
  template?: ProjectTemplateType;
  isSelected: boolean;
  isBlank?: boolean;
  onClick: () => void;
}

const ProjectTemplate = ({
  template,
  onClick,
  isBlank,
  isSelected
}: ProjectTemplateProps) => {
  const { t } = useTranslation();

  const bottomContent = useMemo(() => {
    if (isBlank) {
      return (
        <Icon className="project-template__icon" name="add" size={IconSize.L} />
      );
    }

    const templateInfo = (title?: string, value?: number, icon?: string) => (
      <Inline className="project-template__info" gap="050" alignment="left">
        <Icon
          className="project-template__info-icon"
          name={icon ?? ""}
          fillStyle={IconFillStyle.OUTLINED}
          size={IconSize.XS}
        />
        <Box>
          {title}: {value}
        </Box>
      </Inline>
    );

    return (
      <Stack gap="150">
        {template?.queryCount
          ? templateInfo(
              t("requests:requests.longNamePlural"),
              template?.queryCount,
              "pending_actions"
            )
          : templateInfo(
              t("common:ui.projectTemplate.fields.document.label"),
              template?.documentCount,
              "draft"
            )}
        {templateInfo(
          t("common:ui.projectTemplate.fields.milestone.label"),
          template?.milestoneCount,
          "flag"
        )}
        {templateInfo(
          t("common:ui.projectTemplate.fields.duration.label"),
          template?.duration,
          "calendar_today"
        )}
      </Stack>
    );
  }, [
    isBlank,
    t,
    template?.documentCount,
    template?.duration,
    template?.milestoneCount,
    template?.queryCount
  ]);

  const projectTemplateIons = isSelected
    ? ions.components?.molecules?.project_template_panel?.active
    : ions.components?.molecules?.project_template_panel;

  return (
    <Stack
      className={[
        "project-template",
        returnStringIfTrue(isSelected, "selected")
      ]}
      onClick={onClick}
    >
      {/* need to add it lated. would be done in seperate ticket */}
      <Stack className="project-template__title" width="100" gap="100">
        {isBlank ? (
          t("common:ui.projectTemplate.card.blankProject.label")
        ) : (
          <>
            {template?.name}
            <Pill
              label={template?.engagementType?.name ?? ""}
              variant={projectTemplateIons?.pill_variant}
              fillStyle={projectTemplateIons?.pill_fill_style}
              size={PillSize.SMALL}
            />
          </>
        )}
      </Stack>
      {bottomContent}
    </Stack>
  );
};

export default ProjectTemplate;
