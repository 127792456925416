import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse } from "./";

const baseURL = systemConstants.serverURL;

export const eventsService = {
  track
};

function track(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data)
  };
  return fetch(`${baseURL}/api/events`, requestOptions)
    .then()
    .catch(handleErrorResponse);
}
