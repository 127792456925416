import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import { keyBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { utilities } from "@shared/helpers";
import replaySavedClean from "@shared/helpers/websheetWizard/suggestions/replaySavedClean";
import websheetWizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";

import WizardWebsheet from "../../WizardWebsheet";

const ReplayWizard = React.forwardRef((props, fwdRef) => {
  const {
    loading,
    data: { savedState, websheet: data },
    templateHeaders,
    onSheetLoading,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged
  } = props;
  const { t } = useTranslation();
  const hotTableComponent = useRef(null);
  const [currentSheetName, setCurrentSheetName] = useState(null);
  const [localData, setLocalData] = useState(structuredClone(data));
  const [errorMessage, setErrorMessage] = useState(null);

  const [preservedSheetNames, setPreservedSheetNames] = useState([]); // sheetNames
  const [rowsToDelete, setRowsToDelete] = useState({}); // sheetName: []
  const [columnsToPreserve, setColumnsToPreserve] = useState({}); // sheetName: []
  const replaySuggestions = useRef([]); // [{ sheetName, sheetIndex }]
  const sheetDownloadStates = useRef([]);
  const savedStateName = useRef(null);

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const onCurrentSheetNameChanged = useCallback(
    sheetName => {
      setCurrentSheetName(sheetName);
      parentOnCurrentSheetNameChanged?.(sheetName);
    },
    [parentOnCurrentSheetNameChanged]
  );

  function updateSheetDownloadStatus(websheetData = []) {
    sheetDownloadStates.current = websheetData.map(s => ({
      sheetName: s.sheet,
      downloaded: s.downloaded
    }));
  }

  useEffect(() => {
    if (!data?.length || !savedState) {
      return;
    }

    function updateSheetsInLocalData(sheetsToUpdate) {
      const copies = structuredClone(sheetsToUpdate);
      const sheetsByName = keyBy(copies, "sheet");
      let updatedLocalData;

      setLocalData(prev => {
        updatedLocalData = prev.map(sheetData => {
          const updatedSheet = sheetsByName[sheetData.sheet];
          return updatedSheet ?? sheetData;
        });
        return updatedLocalData;
      });
      updateSheetDownloadStatus(updatedLocalData);
    }

    // TRUE if ANY sheet that was suggested to be replayed has been downloaded
    function didSuggestedSheetsUpdate(incomingWebsheetData, sheetsWithIndex) {
      return sheetsWithIndex.some(
        ({ sheetIndex }) =>
          !sheetDownloadStates.current?.[sheetIndex]?.downloaded &&
          incomingWebsheetData[sheetIndex].downloaded
      );
    }

    // This block deals with dynamic loading
    // - when a replay-applied sheet loads in, we need to trigger the replay again so it operates on all the data
    // - when other sheets load in, we just splice them in and don't bother replaying
    if (
      savedStateName.current === savedState?.name &&
      replaySuggestions.current?.length
    ) {
      const needsToTriggerReplay = didSuggestedSheetsUpdate(
        data,
        replaySuggestions.current
      );
      if (!needsToTriggerReplay) {
        const sheetsThatUpdated = data.filter(
          (sheet, idx) =>
            !sheetDownloadStates?.current?.[idx]?.downloaded && sheet.downloaded
        );
        if (sheetsThatUpdated?.length) {
          updateSheetsInLocalData(sheetsThatUpdated);
        }
        return;
      }
    }

    savedStateName.current = savedState?.name;

    const newLocalData = structuredClone(data);
    updateSheetDownloadStatus(newLocalData);
    try {
      setErrorMessage(null);
      const cleaningTemplate = savedState.properties;
      const replayCommands =
        replaySavedClean.convertCleaningTemplateToReplayCommands(
          cleaningTemplate
        );
      const suggestions = replaySavedClean.execute(
        newLocalData,
        replayCommands,
        templateHeaders
      );
      const { websheet, sheetsToPreserve } = suggestions;
      const newState = sheetsToPreserve.reduce(
        (acc, sheetToPreserve) => {
          const sheet = websheet.find(
            s => s.sheet === sheetToPreserve.sheetName
          );
          if (!sheet) {
            throw utilities.translatableError(
              "common:ui.websheet.actions.cleaningWizard.step.replay.error.cannotFindSheet"
            );
          }

          replaySavedClean.addHeadersToSheet(
            websheet,
            sheet.sheet,
            sheetToPreserve.columnHeaders
          );

          let newColumnsToPreserve = sheetToPreserve.columnHeaders
            ?.filter(ch => !ch.isBlankSelection)
            ?.map(ch => ch.columnIndex);

          // Preserve new blank columns that were not in the original websheet
          const newBlankColumnsToPreserve =
            sheetToPreserve.columnHeaders
              ?.filter(ch => ch.isBlankSelection)
              ?.map((_, index) => sheet.columns.length + index) || [];

          newColumnsToPreserve.push(...newBlankColumnsToPreserve);
          acc.columnsToPreserve[sheetToPreserve.sheetName] =
            newColumnsToPreserve;
          acc.rowsToDelete[sheetToPreserve.sheetName] =
            sheetToPreserve.rowsToRemove.map(idx => idx);
          acc.preservedSheetNames.push(sheetToPreserve.sheetName);
          acc.replaySuggestion.push({
            sheetName: sheetToPreserve.sheetName,
            sheetIndex: sheetToPreserve.sheetIndex
          });
          if (!sheet.downloaded) {
            acc.sheetNamesToDownload.push(sheetToPreserve.sheetName);
          }
          return acc;
        },
        {
          columnsToPreserve: {},
          rowsToDelete: {},
          preservedSheetNames: [],
          replaySuggestion: [],
          sheetNamesToDownload: []
        }
      );

      setColumnsToPreserve(newState.columnsToPreserve);
      setRowsToDelete(newState.rowsToDelete);
      setPreservedSheetNames(newState.preservedSheetNames);
      setLocalData(websheet);

      replaySuggestions.current = newState.replaySuggestion;

      // There's a bit of magic happening here because of dynamic loading
      // Replay needs the required sheets to be downloaded.
      // So after each sheet is processed we move onto the next one - triggering it to be downloaded
      // Once all sheets are downloaded we can replay the whole thing and then preselect the first sheet
      // Basically if there's many sheets this will 'loop' through them one-by-one until they're all downloaded
      const sheetNamesToDownload = newState.sheetNamesToDownload;
      if (sheetNamesToDownload.length) {
        onCurrentSheetNameChanged(sheetNamesToDownload[0]);
      } else {
        onCurrentSheetNameChanged(sheetsToPreserve[0].sheetName);
      }
    } catch (e) {
      onSheetLoading(false);
      if (e.isTranslatable) {
        setErrorMessage(e.i18nKey);
      } else {
        setErrorMessage(
          "common:ui.websheet.actions.cleaningWizard.step.replay.error.cannotReplay"
        );
      }
    }
  }, [
    data,
    onCurrentSheetNameChanged,
    savedState,
    templateHeaders,
    onSheetLoading
  ]);

  const isViewingSheetToPreserve = useMemo(() => {
    return preservedSheetNames.includes(currentSheetName);
  }, [currentSheetName, preservedSheetNames]);

  const currentRowsToDelete = useMemo(() => {
    return rowsToDelete[currentSheetName] || [];
  }, [currentSheetName, rowsToDelete]);

  const currentColumnsToPreserve = useMemo(() => {
    return columnsToPreserve[currentSheetName] || [];
  }, [currentSheetName, columnsToPreserve]);

  const getClassNamesForRemovedRowsOrColumns = useCallback(
    (hotRowIdx, colIdx) => {
      const dataRowIdx = hotRowIdx + 1;
      const isToBeRemoved =
        !isViewingSheetToPreserve ||
        currentRowsToDelete.includes(dataRowIdx) ||
        !currentColumnsToPreserve.includes(colIdx);
      return isToBeRemoved ? "deleted-cell" : "";
    },
    [currentColumnsToPreserve, isViewingSheetToPreserve, currentRowsToDelete]
  );

  const isChecked = useCallback(
    rowIdx => isViewingSheetToPreserve && currentRowsToDelete.includes(rowIdx),
    [isViewingSheetToPreserve, currentRowsToDelete]
  );

  const inputClickHandler = useCallback((row, input, sheetName) => {
    const currentRow = row;
    if (input.checked) {
      setRowsToDelete(prev => ({
        ...prev,
        [sheetName]: [...prev[sheetName], currentRow]
      }));
    } else {
      setRowsToDelete(prev => ({
        ...prev,
        [sheetName]: prev[sheetName].filter(r => r !== currentRow)
      }));
    }
  }, []);

  const afterGetRowHeader = useCallback(
    (hotRowIdx, TH) => {
      TH.classList.add("wizard-websheet__row__header");
      TH.innerHTML = "";

      const dataRowIdx = hotRowIdx + 1;
      const sheetData = localData.find(s => s.sheet === currentSheetName);
      if (dataRowIdx > sheetData.data.length - 1) {
        return;
      }

      const rowHeader = websheetWizardUtilities.makeSelectableRowHeader({
        type: "checkbox",
        name: `row-${dataRowIdx}`,
        value: dataRowIdx,
        isChecked: isChecked(dataRowIdx),
        isDisabled: !isViewingSheetToPreserve
      });
      TH.appendChild(rowHeader);

      if (isViewingSheetToPreserve) {
        const handler = e =>
          inputClickHandler(dataRowIdx, e.target, currentSheetName);
        rowHeader.addEventListener("click", handler);
      }
      // NB: we don't need to removeEventListener because HOT removes it from the DOM when not needed
    },
    [
      isChecked,
      isViewingSheetToPreserve,
      localData,
      inputClickHandler,
      currentSheetName
    ]
  );

  const canSubmit = useCallback(() => {
    return !errorMessage;
  }, [errorMessage]);

  const getDataForSubmission = useCallback(() => {
    if (!hotTableComponent?.current?.hotInstance || !canSubmit()) {
      return {};
    }

    // would need to loop through all sheets and splice them all then aggregate -_-"
    const result = preservedSheetNames.reduce((acc, sheetName) => {
      const intermediate = websheetWizardUtilities.spliceWebsheet(localData, {
        sheetName: sheetName,
        rowsToRemove: rowsToDelete[sheetName],
        columnsToPreserve: columnsToPreserve[sheetName]
      });

      if (!acc) {
        return intermediate;
      }

      const existing = acc ?? intermediate;

      const newSheetData =
        websheetWizardUtilities.alignColumnsAndAppendRowsToSheet(
          existing[0],
          intermediate[0],
          { removeIncomingHeaders: true }
        );
      return [newSheetData];
    }, null);

    return {
      websheet: result,
      savedState: {}
    };
  }, [
    canSubmit,
    columnsToPreserve,
    localData,
    rowsToDelete,
    preservedSheetNames
  ]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit
    }),
    [getDataForSubmission, canSubmit]
  );

  if (errorMessage) {
    return (
      <div className="wizard-error">
        <InlineAlert message={t(errorMessage)} type={"error"}></InlineAlert>
      </div>
    );
  }

  return (
    <WizardWebsheet
      loading={loading}
      currentSheetName={currentSheetName}
      data={localData}
      firstRowHeader={true}
      rowHeaders={true}
      afterGetRowHeader={afterGetRowHeader}
      hotTableComponent={setHotTableComponent}
      additionalClassName={getClassNamesForRemovedRowsOrColumns}
      onSheetLoading={onSheetLoading}
      onCurrentSheetNameChanged={onCurrentSheetNameChanged}
    />
  );
});

ReplayWizard.propTypes = {
  data: PropTypes.shape({
    websheet: PropTypes.array,
    savedState: PropTypes.object
  }).isRequired,
  onSheetLoading: PropTypes.func,
  templateHeaders: PropTypes.array,
  onCurrentSheetNameChanged: PropTypes.func
};
ReplayWizard.displayName = "ReplayWizard";

export default ReplayWizard;
