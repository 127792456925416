import React, { useCallback, useMemo, useState } from "react";

import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";

export function useModalContent() {
  const [contentModals, setContentModals] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [contentKey, setContentKey] = useState("");
  const [modalError, setModalError] = useState("");

  const registerModal = useCallback((contentKey, content) => {
    setContentModals(prevState => ({ ...prevState, [contentKey]: content }));
  }, []);

  const unregisterModal = useCallback(
    contentKey => setContentModals(prevState => delete prevState[contentKey]),
    []
  );

  const getModalContent = useCallback(() => {
    if (modalOpen && contentKey in contentModals) {
      return contentModals[contentKey]?.();
    } else {
      return <></>;
    }
  }, [contentKey, contentModals, modalOpen]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setContentKey("");
    setModalError("");
  }, []);

  const handleOpenModal = useCallback((contentKey, errorMessage) => {
    setModalOpen(true);
    setContentKey(contentKey);
    setModalError(errorMessage);
  }, []);

  const projectAccessModal = useMemo(
    () => () => (
      <ProjectAccessModal
        visibility={true}
        handleClose={handleCloseModal}
        message={modalError}
      />
    ),
    [modalError, handleCloseModal]
  );

  return {
    handleOpenModal,
    handleCloseModal,
    registerModal,
    unregisterModal,
    getModalContent,
    modalOpen,
    projectAccessModal
  };
}
