import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useFeatures } from "@shared/hooks";
import {
  useGetPermissionsQuery,
  useGetRoleByIdQuery
} from "@shared/services/roleService";

import { getErrorMessage } from "@app/helpers/error";

import UpdateRoleForm from "./UpdateRoleForm/UpdateRoleForm";

const UpdateUserRole = () => {
  const { data: permissions, error: permissionsError } =
    useGetPermissionsQuery();
  const { roleId = "" } = useParams();
  const {
    data: role,
    error: roleError,
    isLoading: isRoleLoading
  } = useGetRoleByIdQuery(roleId, {});
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const error = useMemo(() => {
    const error = permissionsError ?? roleError;
    if (!error) {
      return "";
    }
    return getErrorMessage(error, t);
  }, [permissionsError, roleError, t]);

  useEffect(() => {
    if (
      !roleId ||
      (!isFeaturesLoading && !isEnabled(systemConstants.features.roles))
    ) {
      navigate(routeConstants.notFound);
    }
  }, [isFeaturesLoading, navigate, isEnabled, roleId]);

  useEffect(() => {
    //prevent editing for system users
    if (!isRoleLoading && !role?.createdBy) {
      navigate(routeConstants.notFound);
    }
  }, [role, isRoleLoading, navigate]);

  return (
    <UpdateRoleForm
      resourcesPermissions={permissions}
      role={role}
      error={error}
      isLoading={isRoleLoading}
    />
  );
};

export default UpdateUserRole;
