import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { systemConstants } from "@shared/constants/systemConstants";

import { manageDocumentUploadsActions } from "../actions";

const attachedFileStates = systemConstants.addFiles.attachedFile.state;

export function useUploadProjectDocuments() {
  const manageDocumentUploads = useSelector(
    state => state.manageDocumentUploads
  );
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesWithState, setUploadFilesWithState] = useState([]);
  const [projectId, setProjectId] = useState();
  const [documentType, setDocumentType] = useState("");
  const [uploadsCompleted, setUploadsCompleted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadsFailed, setUploadsFailed] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const dispatch = useDispatch();

  const queueUploads = useCallback(files => {
    const uploadList = [];
    const updatedFiles = { ...files };
    if (updatedFiles && Object.keys(updatedFiles).length > 0)
      Object.keys(updatedFiles).forEach(key => {
        const file = updatedFiles[key];
        if (file.state === attachedFileStates.uploadFailed) {
          return;
        }
        if (file.isNew && file.state === attachedFileStates.selected) {
          uploadList.push(key);
        } else if (!file.isNew && file.state === attachedFileStates.selected) {
          file.state = attachedFileStates.uploaded;
        }
      });
    setUploadFiles(updatedFiles);
    setUploadFilesWithState(updatedFiles);
    setUploadQueue(uploadList);
  }, []);

  const clearFileUpload = useCallback(
    fileName => {
      if (fileName && projectId) {
        dispatch(
          manageDocumentUploadsActions.clearUploadedDocument({
            name: fileName,
            projectId: projectId
          })
        );
      }
    },
    [dispatch, projectId]
  );

  const clearUploads = useCallback(() => {
    if (projectId && uploadFiles && Object.keys(uploadFiles).length > 0)
      Object.keys(uploadFiles).forEach(key => {
        const file = uploadFiles[key];
        if (file && file.name) {
          if (file.reqSource && file.state === attachedFileStates.uploading) {
            file.reqSource.cancel("");
          }
          clearFileUpload(file.name, projectId);
        }
      });
  }, [projectId, uploadFiles, clearFileUpload]);

  const handleUploadFinished = useCallback(() => {
    setUploadsCompleted(true);
    setIsUploading(false);
    clearUploads();
    setUploadFiles([]);
  }, [clearUploads]);

  const handleUploadFailed = useCallback(
    (filename, error) => {
      uploadFiles[filename].state = attachedFileStates.uploadFailed;
      uploadFiles[filename].uploadError = error;
      setIsUploading(false);
      setUploadsCompleted(false);
      setUploadsFailed(true);
      setUploadError(error);
      clearUploads();
      setUploadFiles([]);
    },
    [clearUploads, uploadFiles]
  );

  const fileUploaded = useCallback(
    (fileName, pathId) => {
      uploadFiles[fileName].filePathId = pathId;
      uploadFiles[fileName].state = attachedFileStates.uploaded;
      clearFileUpload(fileName);
      const updatedUploadQueue = uploadQueue.filter(key => key !== fileName);
      setUploadQueue(updatedUploadQueue);
    },
    [clearFileUpload, uploadFiles, uploadQueue]
  );

  useEffect(() => {
    return () => {
      clearUploads();
    };
  }, [clearUploads]);

  useEffect(() => {
    if (projectId && uploadFiles && isUploading) {
      if (
        uploadQueue?.length > 0 &&
        uploadFiles[uploadQueue[0]]?.state === attachedFileStates.selected
      ) {
        const uploadFile = uploadFiles[uploadQueue[0]];
        dispatch(
          manageDocumentUploadsActions.uploadDocument({
            file: uploadFile,
            projectId: projectId,
            documentType
          })
        );
        uploadFile.state = attachedFileStates.uploading;
      } else if (!uploadQueue || uploadQueue.length === 0) {
        handleUploadFinished();
      }
    }
  }, [
    dispatch,
    documentType,
    handleUploadFinished,
    isUploading,
    projectId,
    uploadFiles,
    uploadQueue
  ]);

  useEffect(() => {
    if (
      isUploading &&
      projectId &&
      uploadQueue.length > 0 &&
      uploadFiles[uploadQueue[0]].state === attachedFileStates.uploading &&
      manageDocumentUploads.uploadingDocuments?.length
    ) {
      const currentFileKey = uploadQueue[0];
      const uploadingFile = manageDocumentUploads.uploadingDocuments.find(
        file => {
          return file.projectId === projectId && file.name === currentFileKey;
        }
      );

      if (uploadingFile) {
        if (uploadingFile.reqSource) {
          uploadFiles[currentFileKey].reqSource = uploadingFile.reqSource;
        }
        if (uploadingFile.uploadProgress) {
          uploadFiles[currentFileKey].uploadProgress =
            uploadingFile.uploadProgress;
          setUploadFilesWithState({ ...uploadFiles });
        }

        if (uploadingFile.documentPathId) {
          fileUploaded(currentFileKey, uploadingFile.documentPathId);
          setUploadFilesWithState({ ...uploadFiles });
        }

        if (uploadingFile.error) {
          handleUploadFailed(currentFileKey, uploadingFile.error);
          setUploadFilesWithState({ ...uploadFiles });
        }
      }
    }
  }, [
    fileUploaded,
    handleUploadFailed,
    isUploading,
    manageDocumentUploads.uploadingDocuments,
    projectId,
    uploadFiles,
    uploadQueue
  ]);

  const uploadProjectDocuments = useCallback(
    (files, documentsProjectId, documentType = "") => {
      if (isUploading) return;
      if (!files || Object.keys(files)?.length === 0 || !documentsProjectId)
        return;
      setProjectId(documentsProjectId);
      setDocumentType(documentType);
      queueUploads(files);
      setUploadError(false);
      setUploadsFailed(false);
      setUploadsCompleted(false);
      setIsUploading(true);
    },
    [isUploading, queueUploads]
  );

  const getUploadFileState = useCallback(
    filename => {
      if (uploadFilesWithState?.length > 0 && uploadFilesWithState[filename]) {
        const fileStatus = {
          name: filename,
          state: uploadFilesWithState[filename].state,
          filePathId: uploadFilesWithState[filename].filePathId,
          uploadProgress: uploadFilesWithState[filename].uploadProgress,
          uploadError: uploadFilesWithState[filename].uploadError
        };
        return fileStatus;
      }
      return null;
    },
    [uploadFilesWithState]
  );

  const clearUploadsCompleted = useCallback(() => {
    setUploadsCompleted(false);
  }, []);

  return {
    uploadProjectDocuments,
    isUploading,
    uploadsFailed,
    uploadsCompleted,
    uploadError,
    getUploadFileState,
    clearUploadsCompleted
  };
}
