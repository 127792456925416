export const manageExcelDocumentConstants = {
  DOWNLOAD_EXCEL_DOCUMENT_REQUEST: "DOWNLOAD_EXCEL_DOCUMENT_REQUEST",
  DOWNLOAD_EXCEL_DOCUMENT_SUCCESS: "DOWNLOAD_EXCEL_DOCUMENT_SUCCESS",
  DOWNLOAD_EXCEL_DOCUMENT_FAILURE: "DOWNLOAD_EXCEL_DOCUMENT_FAILURE",
  RESET_DOWNLOAD_EXCEL_DOCUMENT_DOWNLOAD: "RESET_DOWNLOAD_EXCEL_DOCUMENT",

  ACQUIRE_LOCK_EXCEL_DOCUMENT_REQUEST: "ACQUIRE_LOCK_EXCEL_DOCUMENT_REQUEST",
  ACQUIRE_LOCK_EXCEL_DOCUMENT_SUCCESS: "ACQUIRE_LOCK_EXCEL_DOCUMENT_SUCCESS",
  ACQUIRE_LOCK_EXCEL_DOCUMENT_FAILURE: "ACQUIRE_LOCK_EXCEL_DOCUMENT_FAILURE",

  REFRESH_LOCK_EXCEL_DOCUMENT_REQUEST: "REFRESH_LOCK_EXCEL_DOCUMENT_REQUEST",
  REFRESH_LOCK_EXCEL_DOCUMENT_SUCCESS: "REFRESH_LOCK_EXCEL_DOCUMENT_SUCCESS",
  REFRESH_LOCK_EXCEL_DOCUMENT_FAILURE: "REFRESH_LOCK_EXCEL_DOCUMENT_FAILURE",

  RELEASE_LOCK_EXCEL_DOCUMENT_REQUEST: "RELEASE_LOCK_EXCEL_DOCUMENT_REQUEST",
  RELEASE_LOCK_EXCEL_DOCUMENT_SUCCESS: "RELEASE_LOCK_EXCEL_DOCUMENT_SUCCESS",
  RELEASE_LOCK_EXCEL_DOCUMENT_FAILURE: "RELEASE_LOCK_EXCEL_DOCUMENT_FAILURE",

  UPDATE_EXCEL_DOCUMENT_REQUEST: "UPDATE_EXCEL_DOCUMENT_REQUEST",
  UPDATE_EXCEL_DOCUMENT_SUCCESS: "UPDATE_EXCEL_DOCUMENT_SUCCESS",
  UPDATE_EXCEL_DOCUMENT_FAILURE: "UPDATE_EXCEL_DOCUMENT_FAILURE"
};
