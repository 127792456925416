import React from "react";

import PropTypes from "prop-types";

import "./HorizontalGalleryLayout.scss";

// Use this to layout the children in a horizontal gallery style within the parent's context
// If there isn't enough space, the Gallery becomes Overflow with horizontal scrolling
// Items are default centered if they don't fill the entire horizontal space
// Positioning and styles of children are untouched
const HorizontalGalleryLayout = props => {
  return (
    <div
      data-testid="testHorizontalGalleryLayout"
      className={`ot-horizontal-gallery ot-horizontal-gallery--justify-${props.justify}`}
    >
      <div className="ot-horizontal-gallery__list">{props.children}</div>
    </div>
  );
};

HorizontalGalleryLayout.defaultProps = {
  justify: "center"
};

HorizontalGalleryLayout.propTypes = {
  justify: PropTypes.oneOf(["center", "left", "right"])
};

export default HorizontalGalleryLayout;
