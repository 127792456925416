import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const jsonEditorService = createApi({
  reducerPath: "jsonEditorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  endpoints: builder => ({
    getSchema: builder.query({
      query: ({ id }) => `/api/jsonEditor/schema/${id}`
    })
  })
});

export const { useGetSchemaQuery, useGetJSONQuery, useGetFileIndexQuery } =
  jsonEditorService;
