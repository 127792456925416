import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";
import { manageProjectDocumentsService } from "@shared/services";

export function useAddProjectTags() {
  const dispatch = useDispatch();
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const [addingTags, setAddingTags] = useState(false);
  const [errorAddingTags, setErrorAddingTags] = useState(false);
  const [projectTagsAdded, setProjectTagsAdded] = useState(false);

  const cancelReq = useRef();

  const addProjectTag = useCallback(
    ({ projectId, tag }) => {
      setProjectTagsAdded(false);
      setAddingTags(true);
      dispatch(manageProjectDocumentsActions.resetAddProjectTag());
      dispatch(
        manageProjectDocumentsActions.createProjectTag({ id: projectId }, tag)
      );
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(manageProjectDocumentsActions.resetAddProjectTag());
    setProjectTagsAdded(false);
    setAddingTags(false);
    setErrorAddingTags(false);
  }, [dispatch]);

  const addProjectTags = useCallback(({ projectId, tags }) => {
    setProjectTagsAdded(false);
    setAddingTags(true);
    manageProjectDocumentsService
      .createProjectTags({ id: projectId }, tags)
      .then(() => {
        if (!cancelReq.current) {
          setAddingTags(false);
          setProjectTagsAdded(true);
        }
      })
      .catch(error => {
        if (!cancelReq.current) setErrorAddingTags(error?.message);
      });
  }, []);

  useEffect(() => {
    cancelReq.current = false;
    return () => {
      cancelReq.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(manageProjectDocumentsActions.resetAddProjectTag());
    };
  }, [dispatch]);

  useEffect(() => {
    if (manageProjectDocuments.addedProjectTag?.id) {
      setProjectTagsAdded(true);
      setAddingTags(false);
    }
  }, [manageProjectDocuments.addedProjectTag?.id]);

  useEffect(() => {
    setErrorAddingTags(manageProjectDocuments.addProjectTagError);
  }, [manageProjectDocuments.addProjectTagError]);

  return {
    addProjectTags,
    addProjectTag,
    addingProjectTags: addingTags,
    errorAddingProjectTag: errorAddingTags,
    projectTagsAdded,
    resetAddProjectAdds: reset
  };
}
