// evaluates the condition to see if it's satisfied
// NB: currently operators are hardcoded
// future: maybe a good idea to have a custom comparator for each questionType because they each have different ways of comparing
import { memoize } from "lodash";

/**
 * @typedef {Object} ConditionalLogicNode
 * @property {string} [value] - the value to compare against (must be present for some operators)
 * @property {string} operator - one of "equals", "notEquals", "contains", "greaterThan", "lessThan", "answered", "unAnswered"
 */

/**
 * @param {object} params
 * @param {ConditionalLogicNode} params.conditionalLogicNode - the node condition to evaluate
 * @param {*} params.answer - the answer to compare against
 * @param {*} [params.question]
 * @returns
 */
function isConditionSatisfied({ conditionalLogicNode, question, answer }) {
  const { value } = conditionalLogicNode;
  const typedAnswer = question?.type === "date" ? new Date(answer) : answer;
  const typedValue = question?.type === "date" ? new Date(value) : value;

  switch (conditionalLogicNode.operator) {
    case "equals": {
      return String(typedAnswer) === String(typedValue);
    }
    case "notEquals": {
      return String(typedAnswer) !== String(typedValue);
    }
    case "contains": {
      return String(typedAnswer).includes(String(typedValue));
    }
    case "greaterThan": {
      return Number(typedAnswer) > Number(typedValue);
    }
    case "lessThan": {
      return Number(typedAnswer) < Number(typedValue);
    }
    case "answered": {
      return !!typedAnswer || typedAnswer === "";
    }
    case "unAnswered": {
      return !typedAnswer && typedAnswer !== "";
    }
    default:
      return false;
  }
}

/**
 * @typedef {Object} QuestionState
 * @property {boolean} isVisible
 * @property {boolean} isMandatory
 */

/**
 * @typedef {Object<string,QuestionState>} ResultQuestionState
 */

/**
 * Given all the satisfiedRoots, returns the resulting state of each affected question
 * The resulting state checks against the dependency chain so only roots that have all their dependencies satisfied will cause a change in state
 * e.g. If we have a dependency chain A > B > C > D
 * If B fails its dependency condition (FALSE); then C and D will also be FALSE regardless of their value
 * If D is TRUE, then it can be assumed that the whole chain has fulfilled their conditions
 * @param {*} options
 * @returns {ResultQuestionState}
 */
function getResultingQuestionStates({
  satisfiedRoots,
  conditionalLogicForest
}) {
  const actionsToTrigger = satisfiedRoots.flatMap(
    logicNode => logicNode.actions
  );
  const affectedQuestions = actionsToTrigger.flatMap(action => {
    switch (action.type) {
      case "category": {
        const { id: categoryId } = action;
        return conditionalLogicForest
          .getQuestionsInCategory(categoryId)
          .map(q => ({
            questionId: q.questionId,
            isVisible: action.show,
            isMandatory: action.isMandatory
          }));
      }
      case "subCategory": {
        const { id: subCategoryId } = action;
        return conditionalLogicForest
          .getQuestionsInSubcategory(subCategoryId)
          .map(q => ({
            questionId: q.questionId,
            isVisible: action.show,
            isMandatory: action.isMandatory
          }));
      }
      case "question": {
        const { id: questionId } = action;
        return [
          {
            questionId,
            isVisible: action.show,
            isMandatory: action.isMandatory
          }
        ];
      }
      default:
        throw new Error(`unknown type ${action.type}`);
    }
  });

  const initialStateForQuestions = conditionalLogicForest
    .getAllQuestions()
    .filter(q => q.showByDefault || q.isMandatory)
    .map(q => ({
      questionId: q.questionId,
      isVisible: q.showByDefault,
      isMandatory: q.isMandatory
    }));

  const result = [...initialStateForQuestions, ...affectedQuestions].reduce(
    (acc, curr) => {
      const questionId = curr.questionId;
      const { isVisible, isMandatory } = acc[questionId] ?? {};
      return {
        ...acc,
        [questionId]: {
          isVisible: isVisible || curr.isVisible,
          isMandatory: isMandatory || curr.isMandatory
        }
      };
    },
    {}
  );

  return result;
}

// now need to iterate and filter out questions that are not visible based on the dependency chain
function getDependencySatisfiedPredicate({
  questionStates,
  conditionalLogicForest
}) {
  /**
   * @type {Object<number,boolean>} questionId: boolean
   */
  const memo = {};
  /**
   * @type {Set<number>} nodeIds that have been visited
   */
  const visitedNodes = new Set();

  const isDependencySatisfied = questionId => {
    if (typeof memo[questionId] === "boolean") {
      return memo[questionId];
    }

    const isVisible = questionStates[questionId]?.isVisible;
    const isDependantRootSatisfied = () => {
      const rootNodes =
        conditionalLogicForest.getRootNodesWhichImpactQuestionId(questionId);

      // Filter out nodes which have been visited otherwise we will get stuck in a loop
      const rootNodesToProcess = rootNodes.filter(n => !visitedNodes.has(n.id));

      const result =
        !rootNodesToProcess.length ||
        rootNodesToProcess.some(rootNode => {
          visitedNodes.add(rootNode.id);
          const nodes = conditionalLogicForest.getNodesByRootId(rootNode.id);
          return nodes.every(n => isDependencySatisfied(n.questionId));
        });
      return result;
    };
    const satisfied = isVisible || isDependantRootSatisfied();

    memo[questionId] = satisfied;
    return memo[questionId];
  };

  return isDependencySatisfied;
}

/**
 * Filters out any questions that are not visible along the dependency chain assuming the current state of the questions
 * @param {object} options
 * @param {object} options.questionStates
 * @param {import("./conditionalLogicForest").ConditionalLogicForest} options.conditionalLogicForest
 * @returns {ResultQuestionState} with any questions that are not visible along the dependency chain removed
 */
function filterStateByDependencies({ questionStates, conditionalLogicForest }) {
  const predicate = getDependencySatisfiedPredicate({
    questionStates,
    conditionalLogicForest
  });

  const result = Object.entries(questionStates)
    .filter(([questionId]) => {
      return predicate(+questionId);
    })
    .reduce((acc, [questionId, state]) => {
      return {
        ...acc,
        [questionId]: state
      };
    }, {});

  return result;
}

function satisfiedRootPredicate({
  conditionalLogicForest,
  answerByQuestionId
}) {
  const isNodeSatisfied = memoize(nodeId => {
    const node = conditionalLogicForest.getNodeById(nodeId);
    const question = conditionalLogicForest.getQuestionById(node.questionId);
    const answers = answerByQuestionId[node.questionId] ?? [];
    const satisfied = answers.some(answer =>
      isConditionSatisfied({
        conditionalLogicNode: node,
        question,
        answer: answer
      })
    );
    return satisfied;
  });

  function isRootConditionSatisfied({ rootId }) {
    // {nodeId: boolean}. T/F if this node upwards to the root has all conditions satisfied. undefined if not yet computed
    const memo = {};
    function isPathSatisfied(node) {
      if (memo[node.id] !== undefined) {
        return memo[node.id];
      }

      if (node.logicType === "root") {
        return isNodeSatisfied(node.id);
      }

      const isSatisfied = isNodeSatisfied(node.id);
      const parentNode = conditionalLogicForest.getNodeById(node.parentId);
      if (node.logicType === "and") {
        memo[node.id] = isSatisfied && isPathSatisfied(parentNode);
        return memo[node.id];
      } else if (node.logicType === "or") {
        memo[node.id] = isSatisfied || isPathSatisfied(parentNode);
        return memo[node.id];
      }

      throw new Error(`unexpected operator ${node.operator}`);
    }

    const leafNodes = conditionalLogicForest.getLeafNodesByRootId(rootId);

    return leafNodes.every(isPathSatisfied);
  }

  return rootNode =>
    isRootConditionSatisfied({
      rootId: rootNode.id
    });
}

/**
 * @param {Object} options
 * @param {import("./conditionalLogicForest").ConditionalLogicForest} options.conditionalLogicForest
 * @param {Object} options.answerByQuestionId
 * @returns
 */
function getSatisfiedRoots({ conditionalLogicForest, answerByQuestionId }) {
  const predicate = satisfiedRootPredicate({
    conditionalLogicForest,
    answerByQuestionId
  });

  const logicRootNodes = conditionalLogicForest.getRootNodes();
  const satisfiedRoots = logicRootNodes.filter(predicate);

  return satisfiedRoots;
}

/**
 * @param {Object} params
 * @param {import("./conditionalLogicForest").ConditionalLogicForest} params.conditionalLogicForest
 * @param {Object} params.answerByQuestionId
 * @returns {ResultQuestionState}
 */
export function runWithConditionalForest({
  conditionalLogicForest,
  answerByQuestionId
}) {
  const satisfiedRoots = getSatisfiedRoots({
    conditionalLogicForest,
    answerByQuestionId
  });

  const questionStates = getResultingQuestionStates({
    conditionalLogicForest,
    satisfiedRoots
  });

  const finalState = filterStateByDependencies({
    conditionalLogicForest,
    questionStates
  });

  return finalState;
}

export const forTest = {
  filterStateByDependencies,
  isConditionSatisfied
};
