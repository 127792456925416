// Get parent of element, either by specified parent ID or default to .parentElement
function getParentElement(element, parentId = null) {
  if (parentId) {
    return document.getElementById(parentId) || element?.parentElement;
  } else {
    return element?.parentElement;
  }
}

/**
 * Gets the scroll position of the element based on the parent
 * @param {HTMLElement} element
 * @param {string} parentId
 * @returns {Object} { top: Number, left: Number}
 */
export function getScrollPosition(element, parentId = null) {
  const positionProperties = ["left", "top"];

  // Element
  const elementPosition = element?.getBoundingClientRect();

  if (!elementPosition) {
    return positionProperties.reduce((acc, p) => {
      acc[p] = 0;
      return acc;
    }, {});
  }

  // Parent
  const immediateParentElement = getParentElement(element, parentId);
  const immediateParentPosition =
    immediateParentElement?.getBoundingClientRect();

  return positionProperties.reduce((acc, property) => {
    let parentElement = immediateParentElement;
    let parentPosition = immediateParentPosition;

    // position is same as parent so we look as our grandparent
    if (elementPosition[property] === parentPosition?.[property]) {
      parentElement = parentElement?.parentElement;
      parentPosition = parentElement?.getBoundingClientRect();
    }

    if (elementPosition[property] !== parentPosition?.[property]) {
      acc[property] =
        elementPosition[property] - (parentPosition?.[property] || 0);
    } else {
      acc[property] = 0;
    }
    return acc;
  }, {});
}
export function getStyleForDropdown(dropdownRef, parentId = null) {
  const scrollPosition = getScrollPosition(dropdownRef, parentId);
  const dropdownWidth = dropdownRef?.getBoundingClientRect()?.width || 0;

  const style = {
    top: `${scrollPosition.top}px`,
    left: scrollPosition.left !== 0 && `${scrollPosition.left}px`,
    width: `${dropdownWidth}px`
  };

  return style;
}

export function handleFreezeScrollPosition(freeze = true) {
  if (freeze) {
    const topPosition = window.scrollY || 0;
    if (topPosition !== 0) {
      document.body.style.top = `-${topPosition}px`;
    }
    document.body.style.position = "fixed";
  } else {
    const styleTop = document.body.style.top || 0;
    document.body.style.position = "";
    document.body.style.top = "";
    if (styleTop != 0) {
      window.scrollTo(0, parseInt(styleTop || "0") * -1);
    }
  }
}

export function getHeightOfElement(elementId) {
  const element = document.getElementById(elementId);
  return element?.getBoundingClientRect()?.height ?? 0;
}

export function getTopPositionOfElement(elementId) {
  const element = document.getElementById(elementId);
  return element?.getBoundingClientRect()?.y || 0;
}

export function getHeightOfPageHeaderPx() {
  return getHeightOfElement("page-template__header") || 0;
}

/**
 * Given a list of classnames strips out empties and non-strings, returning a joined string of class names
 * @param {string[]} list - List of strings
 * @returns {string} - Returns a string of class names with empties and non-strings filtered out
 */
export function classNames(list) {
  return (list ?? [])
    .filter(c => c)
    .filter(c => typeof c === "string")
    .map(c => c.trim())
    .join(" ");
}
export const returnStringIfTrue = (boolean, string) => (boolean ? string : "");
