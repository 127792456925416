import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { chartConstants } from "@app/constants";
import {
  DataFormater,
  filterData,
  formatHoverText,
  getBarAndWaterfallChartFactors
} from "@app/helpers/chart";

import "./BarChartTable.scss";

function CustomizedTick(params) {
  const {
    x,
    y,
    payload: { value },
    visibleTicksCount,
    dependencies: { isDrilldown, tickWidth, chartWidth, className, axisColors }
  } = params;
  const minWidth = 59;
  const getLabelWidth = () => {
    if (!isDrilldown) {
      return tickWidth - 8;
    }
    if (visibleTicksCount === 1) {
      return chartWidth / 1.5;
    }
    return chartWidth / visibleTicksCount / 1.2;
  };
  return (
    tickWidth > minWidth && (
      <foreignObject
        width={getLabelWidth()}
        x={x - 0.5 * getLabelWidth()}
        y={y}
        style={{ color: axisColors.stroke }}
        className={`bar-chart__tick ${className}`}
      >
        {value}
      </foreignObject>
    )
  );
}

const CustomTooltip = props => {
  const { active = false, payload = [], dataType, translate } = props;
  const { t } = useTranslation();
  if (!active || !payload?.length) {
    return null;
  }
  return (
    <div className="custom-tooltip">
      <p className="label label--xaxis">{payload[0].payload?.label}</p>
      {payload.map((p, i) => {
        const value = formatHoverText(p.value, dataType);
        const label = translate ? t(p.name) : p.name;
        return (
          <p
            style={{ color: p.fill }}
            key={p.name + i}
            className="label"
          >{`${label} : ${value}`}</p>
        );
      })}
    </div>
  );
};

const BarChartTable = props => {
  const {
    data,
    colors,
    className,
    axis,
    displayZero,
    showTopValues,
    fixedHeight,
    isDrilldown,
    dataType,
    legend,
    renderEmptyPlaceHolder
  } = props;
  const [tickWidth, setTickWidth] = useState(80);
  const [barSize, setBarSize] = useState(99);
  const [interval, setInterval] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const { chartColors: barColors, axisColors } = colors;
  const barChartRef = useRef();
  const barNums = axis.values.length;
  const axisHeight = 40;
  const { t } = useTranslation();

  useEffect(() => {
    if (!chartWidth) {
      return;
    }
    const { tickWidth, interval, barSize } = getBarAndWaterfallChartFactors({
      chartWidth,
      barNums,
      isDrilldown
    });
    setBarSize(barSize);
    setTickWidth(tickWidth);
    setInterval(interval);
  }, [barNums, chartWidth, interval, tickWidth, isDrilldown]);

  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      if (width) {
        setChartWidth(width);
      }
    });
    const barChartElement = barChartRef?.current;
    if (barChartRef) {
      resize.observe(barChartElement);
    }
    return () => {
      if (barChartElement) {
        resize.unobserve(barChartElement);
      }
    };
  }, []);

  const getBars = () =>
    data.keys.map((k, i) => (
      <Bar
        key={k}
        dataKey={k}
        barSize={barSize}
        fill={barColors[i % barColors.length]}
      >
        {data.values.map((_, index) => (
          <Cell
            fill={barColors[i % barColors.length]}
            key={`cell-${k}-${index}`}
          />
        ))}
      </Bar>
    ));

  const legendFormatter = useCallback(
    value => {
      const legendTitle = (() => {
        const label = legend?.translate ? t(value) : value;
        if (data?.values?.length > showTopValues) {
          return `${label} - Top ${showTopValues}`;
        }
        return label;
      })();
      return (
        <span style={{ color: colors.legendTextColor }}>{legendTitle}</span>
      );
    },
    [
      colors.legendTextColor,
      data?.values?.length,
      showTopValues,
      legend?.translate,
      t
    ]
  );

  const dataToRender = useMemo(() => {
    return filterData(data.values, { displayZero, showTopValues });
  }, [data.values, displayZero, showTopValues]);

  return (
    <div ref={barChartRef}>
      <ResponsiveContainer
        width="99%"
        height={fixedHeight ?? chartConstants.defaultHeight}
        className={className}
      >
        <BarChart
          data={dataToRender}
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 20
          }}
        >
          {dataToRender?.length === 0 ? (
            renderEmptyPlaceHolder?.()
          ) : (
            <>
              <XAxis
                dataKey="label"
                className={"bar-chart-Xaxis"}
                stroke={axisColors.stroke}
                interval={interval}
                tick={
                  <CustomizedTick
                    dependencies={{
                      isDrilldown,
                      tickWidth,
                      chartWidth,
                      className,
                      axisColors
                    }}
                  />
                }
                height={axisHeight + 40}
                tickMargin={5}
              ></XAxis>

              <YAxis
                stroke={axisColors.stroke}
                tickFormatter={DataFormater}
                domain={["auto", dataMax => (dataMax < 0 ? 0 : dataMax)]}
              />
              <Tooltip
                cursor={{ fill: axisColors.hoverOverlay }}
                content={
                  <CustomTooltip
                    dataType={dataType}
                    translate={legend?.translate}
                  />
                }
              />
              <Legend
                verticalAlign="top"
                height={25}
                formatter={legendFormatter}
              />
              {getBars()}
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChartTable.defaultProps = {
  displayZero: true,
  dataType: "currency"
};

BarChartTable.propTypes = {
  axis: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.array
  }),
  colors: PropTypes.shape({
    barColors: PropTypes.arrayOf(PropTypes.string),
    axisColors: PropTypes.shape({
      stroke: PropTypes.string,
      label: PropTypes.string
    }),
    chartColors: PropTypes.shape({
      barColors: PropTypes.arrayOf(PropTypes.string)
    }),
    legendTextColor: PropTypes.string
  }),
  data: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    )
  }),
  tickMaxLines: PropTypes.number,
  isDrilldown: PropTypes.bool,
  dataType: PropTypes.oneOf(["currency", "number", "percentage"]),
  className: PropTypes.string,
  fixedHeight: PropTypes.number,
  displayZero: PropTypes.bool,
  showTopValues: PropTypes.number,
  legend: PropTypes.shape({
    translate: PropTypes.bool
  }),
  renderEmptyPlaceHolder: PropTypes.func.isRequired
};

export default BarChartTable;
