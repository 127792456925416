import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  changeProject,
  resetTableFilter,
  saveAssignedToUsers,
  saveRequestFilter,
  saveTableFilters,
  saveUserFilterType
} from "@shared/features/actionItem/actionItemSlice";

export function useActionItemFilters() {
  const dispatch = useDispatch();
  const actionItem = useSelector(state => state.actionItem);

  const [requestFilter, setRequestFilter] = useState();
  const [userFilterType, setUserFilterType] = useState();
  const [assignedToUsers, setAssignedToUsers] = useState();
  const [tableFilters, setTableFilters] = useState();

  useEffect(() => {
    if (actionItem?.requestFilter) {
      setRequestFilter(structuredClone(actionItem.requestFilter));
    }
  }, [actionItem?.requestFilter]);

  useEffect(() => {
    if (actionItem?.userFilterType) {
      setUserFilterType(structuredClone(actionItem.userFilterType));
    }
  }, [actionItem?.userFilterType]);

  useEffect(() => {
    if (actionItem?.assignedToUsers) {
      setAssignedToUsers(actionItem.assignedToUsers);
    }
  }, [actionItem?.assignedToUsers]);

  useEffect(() => {
    if (actionItem?.tableFilters) {
      setTableFilters(actionItem.tableFilters);
    }
  }, [actionItem?.tableFilters]);

  const onChangeRequestFilter = useCallback(
    filters => {
      dispatch(saveRequestFilter({ filters }));
    },
    [dispatch]
  );

  const onChangeUserFilterType = useCallback(
    filter => {
      dispatch(saveUserFilterType({ filter }));
    },
    [dispatch]
  );

  const onChangeAssignedToUsers = useCallback(
    users => {
      dispatch(saveAssignedToUsers({ users }));
    },
    [dispatch]
  );

  const onChangeProject = useCallback(
    projectId => {
      dispatch(changeProject({ projectId }));
    },
    [dispatch]
  );

  const onChangeTableFilters = useCallback(
    (filters, key) => {
      dispatch(saveTableFilters({ filters, key }));
    },
    [dispatch]
  );

  const resetActionitemTableFilter = useCallback(() => {
    dispatch(resetTableFilter());
  }, [dispatch]);

  return {
    onChangeUserFilterType,
    onChangeRequestFilter,
    onChangeAssignedToUsers,
    onChangeTableFilters,
    onChangeProject,
    resetActionitemTableFilter,
    requestFilter,
    userFilterType,
    assignedToUsers,
    tableFilters
  };
}
