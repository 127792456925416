import { systemConstants } from "@shared/constants/systemConstants";

import { getTopPositionOfElement } from "@app/helpers/componentHelpers";

const MINIMUM_NUMBER_OF_COLUMNS = 26;
const MINIMUM_NUMBER_OF_ROWS = 500;
const PLACEHOLDER_CELL_WIDTH = 65;

const errorMessageStyle = {
  font: {
    bold: true,
    size: 12,
    color: {
      argb: "FFFF0000"
    },
    name: "Calibri",
    charset: 134,
    scheme: "minor"
  },
  alignment: {
    horizontal: "left"
  },
  border: {},
  fill: {
    type: "pattern",
    pattern: "none"
  }
};

const mergeCellsNumber = 20;

const getErrorSheetDataTemplate = errorMessage => ({
  data: [
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    new Array(mergeCellsNumber).fill(errorMessage)
  ],
  formats: [
    [{}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}],
    new Array(mergeCellsNumber).fill(errorMessageStyle)
  ],
  merges: {
    A3: {
      model: {
        top: 3,
        left: 1,
        bottom: 3,
        right: mergeCellsNumber
      }
    }
  }
});

const getDefaultSettings = () => ({
  data: null,
  cell: null,
  colWidths: PLACEHOLDER_CELL_WIDTH,
  manualRowResize: true,
  manualColumnResize: true,
  colHeaders: true,
  rowHeaders: true,
  bindRowsWithHeaders: true,
  filters: true,
  minCols: MINIMUM_NUMBER_OF_COLUMNS,
  minRows: MINIMUM_NUMBER_OF_ROWS,
  readOnly: true,
  dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
  mergeCells: [],
  licenseKey:
    process.env.REACT_APP_HANDSONTABLE_KEY || "non-commercial-and-evaluation",
  stretchH: "all"
});

const getResult = (rowIdx, colIdx) => ({
  row: rowIdx,
  col: colIdx,
  className: "",
  renderer: "ot.websheetCell"
});

const getHeaderNameForSelection = (th, t) =>
  t("common:ui.websheet.actions.cleaningWizard.columnName", {
    context: th.required ? undefined : "OPTIONAL",
    columnName: th.name
  });

const formatDate = pattern => {
  if (pattern?.includes("[$-F800") || pattern?.includes?.("[$-C09")) {
    return "dddd, MMMM DD, YYYY";
  }
  switch (pattern) {
    case "yyyy/mm/dd":
      return "YYYY/MM/DD";
    case "yyyy/m/d":
      return "YYYY/M/D";
    case "yyyy-mm-dd":
      return "YYYY-MM-DD";
    case "yyyy-m-d":
      return "YYYY-M-D";
    case "dd/mm/yyyy":
      return "DD/MM/YYYY";
    case "d/m/yyyy":
      return "D/M/YYYY";
    case "dd-mm-yyyy":
      return "DD-MM-YYYY";
    case "d-m-yyyy":
      return "D-M-YYYY";
    case "mm-dd-yy":
      return "MM/DD/YY";
    default:
      return null;
  }
};

const minimumYear = 1900;
const isLesserThanMinDate = input => {
  return new Date(input).getFullYear() < minimumYear;
};

/**
 * @param {*} jsDate Date to convert
 * @returns excel number representation of the date
 * source: https://stackoverflow.com/questions/70804856/convert-javascript-date-object-to-excel-serial-date-number
 */
function dateToSerialNumber(jsDate) {
  const NULL_DATE = 25569.0;
  const DAYS_MS = 1000 * 60 * 60 * 24;
  const tzOffset = jsDate.getTimezoneOffset() * 60 * 1000;
  const serialNumber = NULL_DATE + (jsDate.getTime() - tzOffset) / DAYS_MS;
  return serialNumber < 30 ? serialNumber - 1 : serialNumber;
}

/**
 * @param {*} serialNumber Float representing the date
 * @returns JS Date representation of the serial number
 * https://support.microsoft.com/en-au/office/datevalue-function-df8b07d4-7761-4a93-bc33-b7471bbff252
 * https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
 */
function serialNumberToDate(serialNumber) {
  if (serialNumber <= 29) {
    return new Date(Date.UTC(0, 0, serialNumber));
  }
  return new Date(Date.UTC(0, 0, serialNumber - 1));
}

const formatNumeric = pattern => {
  if (!pattern?.includes?.("[$-F800]") && pattern?.includes?.("$")) {
    return { pattern: "$0,0.00", culture: "en-AU" };
  } else if (pattern?.includes?.("#")) {
    return { pattern: "0,0", culture: "en-AU" };
  } else if (pattern?.includes?.("%")) {
    if (pattern?.includes?.(".")) {
      return {
        pattern: { output: "percent", mantissa: pattern.length - 3 }
      };
    } else {
      return {
        pattern: { output: "percent", mantissa: 0 }
      };
    }
  } else if (pattern?.includes?.(".")) {
    return { pattern };
  } else if (pattern === "0") {
    return { pattern: { mantissa: 0 } };
  }

  return null;
};

const mergingCells = merges =>
  Object.values(merges)
    .map(m => m.model)
    .filter(({ top, bottom }) => top !== 0 && bottom !== 0)
    .map(({ top, left, right, bottom }) => ({
      row: top - 1,
      col: left - 1,
      rowspan: bottom - top + 1,
      colspan: right - left + 1
    }));

const formatDollarSignWithZero = (pattern, TD) => {
  if (
    pattern?.includes?.("$") &&
    (pattern?.includes?.("*") || pattern?.includes?.("#"))
  ) {
    TD.innerHTML = `<div class='cellWithDollarSign'>
    <span>${TD.innerText.includes("$") ? "$" : ""}</span>
    <span>${TD.innerText.replace("$", "")}</span>
    </div>`;
    if (TD.children[0].children[1].innerText.charAt(0) === "-") {
      // file deepcode ignore GlobalReplacementRegex: intentional
      TD.children[0].children[1].innerText = `(${TD.children[0].children[1].innerText.replace(
        "-",
        ""
      )})`;
    }
    if (
      TD.children[0].children[1].innerText === "0" ||
      TD.children[0].children[1].innerText === "0.00"
    ) {
      TD.children[0].children[1].innerText = "-";
    }
  }
  if (pattern?.includes?.("-")) {
    if (TD.innerText === "0") {
      TD.innerText = "-";
    }
  }
};

const getLastCellForMerge = ({ merges, row, col, rowOffset = -1 }) => {
  const merge = Object.values(merges).find(({ model }) => {
    const { top, left, bottom, right } = model;
    return (
      top <= row + 1 - rowOffset &&
      row + 1 - rowOffset <= bottom &&
      left <= col + 1 &&
      col + 1 <= right
    );
  });

  if (!merge) {
    return {
      row,
      col
    };
  }
  return {
    row: merge.model.bottom - 1 + rowOffset,
    col: merge.model.right - 1
  };
};

const getHorizontalAlignment = horizontal => {
  switch (horizontal) {
    case "center":
      return " htCenter";
    case "right":
      return " htRight";
    case "left":
      return " htLeft";
    case "justify":
      return " htJustify";
    default:
      return "";
  }
};

const getVerticalAlignment = vertical => {
  switch (vertical) {
    case "top":
      return " htTop";
    case "middle":
      return " htMiddle";
    case "bottom":
      return " htBottom";
    default:
      return "";
  }
};

const getMultiFontsErrorCellRef = currentSheet => {
  const errorArrays = currentSheet?.data?.map(row => {
    const colIndexes = row.reduce((acc, curr, index) => {
      if (curr?.richText?.length > 1) {
        acc.push(index);
      }
      return acc;
    }, []);
    return colIndexes;
  });
  const result = errorArrays?.flatMap((row, index) =>
    row.map(col => `${convertNumToColumn(col + 1)}${index + 1}`)
  );
  return result?.join(", ");
};

const convertStringToFormula = (obj, formulaEvaluator) => {
  if (typeof obj == "string" && obj.startsWith("=")) {
    return {
      formula: wrapSheetNameInQuotes(obj.slice(1)),
      result: formulaEvaluator()
    };
  }
  return obj;
};

const convertColumnToNum = columnName => {
  const base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = 0;

  for (let i = 0, j = columnName.length - 1; i < columnName.length; i++, j--) {
    result += Math.pow(base.length, j) * (base.indexOf(columnName[i]) + 1);
  }
  return result;
};

const convertNumToColumn = columnIndex => {
  const letters = [];
  while (columnIndex > 0) {
    const temp = (columnIndex - 1) % 26;
    const letter = String.fromCharCode(temp + 65);
    columnIndex = (columnIndex - temp - 1) / 26;
    letters.unshift(letter);
  }
  return letters.join("");
};

const getCellDataByNameBox = (formula, data) => {
  const range = formula.split(":");
  const columnStartAt = convertColumnToNum(range[0].replace(/\d/g, "")) - 1;
  const rowStartAt = +range[0].replace(/\D/g, "") - 2;
  const columnEndAt = convertColumnToNum(range[1].replace(/\d/g, "")) - 1;
  const rowEndAt = +range[1].replace(/\D/g, "") - 2;
  const result = [];
  for (let r = rowStartAt; r <= rowEndAt; r += 1) {
    for (let c = +columnStartAt; c <= +columnEndAt; c += 1) {
      result.push(data[r]?.[c]);
    }
  }
  return result;
};

const getSource = (formula, data) => {
  let result = [];
  if (typeof formula === "string") {
    const pureFormula = formula.replace(/['"]+/g, "").replace(/['$]+/g, "");
    if (pureFormula.includes(",")) {
      result = pureFormula.split(",");
    } else if (pureFormula.includes(":")) {
      result = getCellDataByNameBox(pureFormula, data);
    } else {
      //only one value
      return [pureFormula];
    }
  }
  return result;
};

const formatHyperLink = (hyperlink, TD, value) => {
  TD.innerHTML = `<a href="${hyperlink}" target="_blank">${
    value || hyperlink
  }</a>`;
  return TD;
};
const { columnMultiplier, columnWidthContextMenu } =
  systemConstants.project.document.editExcelDocumentPage;

const widthToPixel = width =>
  width && Math.round(columnMultiplier * width) + columnWidthContextMenu;

const getColWidths = sheet =>
  sheet?.columns?.map(col => widthToPixel(col?.width) || 150);

const getMaxNumberOfColumns = (data, minCols = MINIMUM_NUMBER_OF_COLUMNS) =>
  Math.max(minCols, ...data.map(({ length }) => length));

const populateData = ({ data, minimumNumberOfRows = 0 }) => {
  // Find the size of the largest array and set all arrays to that size if greater than minCols, else set all to minCols
  const maxNumberOfColumns = getMaxNumberOfColumns(data);

  if (data.length < minimumNumberOfRows) {
    const oldLength = data.length;
    data.length = minimumNumberOfRows;
    data.fill([], oldLength);
  }

  data.forEach(rowData => {
    const oldLength = rowData.length;
    //sets new capacity and fills from old length to new capacity with null
    rowData.length = maxNumberOfColumns;
    rowData.fill(null, oldLength);
  });

  return data;
};

// wraps sheet name in single quotes if it does not have quotes
const wrapSheetNameInQuotes = input => {
  const formulaRegex =
    /(-?[A-Z.]+\(|,\s?-?[A-Z.]+\(|-?\(|,\s?-?\s?\(?)(.*=)?(\p{L}{0,32}?[^/\\?*[\]"'=,]{0,32}?)!(\$?[a-zA-Z]{1,3}|(\$?\d{1,7}))/gu;
  const refValueRegex =
    /([^']\p{L}{0,32}?[^/\\?*[\]"'=]{0,32}?)!(\$?[a-zA-Z]{1,3}|(\$?\d{1,7}))/gu;
  if (input.match(formulaRegex)) {
    return input.replace(
      formulaRegex,
      //e.g IF(A1=Sheet1!C1, SUM(Sheet1!B1:B3),SUM(Sheet1!A1:A3))
      //group1: IF( , SUM( ,SUM(
      //group2: A1=
      //group3: all Sheet1
      //group4/5: following chars
      (_, group1, group2, group3, group4, group5) =>
        `${group1}${group2 ?? ""}'${group3}'!${group4 || group5}`
    );
  }
  if (input.match(refValueRegex)) {
    return input.replace(
      refValueRegex,
      //e.g 貸借対照表!$C$8 group1: 貸借対照表 group2/3: following chars
      (_, group1, group2, group3) => `'${group1}'!${group2 || group3}`
    );
  }
  return input;
};

const getFormulaString = formula => {
  return `=${wrapSheetNameInQuotes(formula)}`;
};

const getParsedCellData = ({ data, isCleaningMode = false }) => {
  if (typeof data !== "object" || !data) {
    return data?.startsWith?.("=")
      ? `=${wrapSheetNameInQuotes(data.slice(1))}`
      : data;
  } else {
    const { formula, result: rawResult } = data;
    const getDataResult = () => {
      const errorResult = rawResult?.error;
      if (isCleaningMode) {
        return errorResult ?? rawResult;
      }
      return getFormulaString(formula);
    };
    return getDataResult();
  }
};

//converts array of sheet data with formula objects to just the raw calculated values
const getCalculatedSheets = dataObj => {
  const calculatedSheets = [];
  dataObj?.forEach(sheet => {
    const sheetData = sheet.data.map(row =>
      row.map(data =>
        getParsedCellData({
          data,
          isCleaningMode: true
        })
      )
    );
    calculatedSheets.push({ ...sheet, data: sheetData });
  });

  return calculatedSheets;
};
const documentHeaderHeightPx = 50;
const documentFooterHeightPx = 60;

const getVisibleSheetNames = dataObj => {
  return (
    dataObj
      ?.filter(sheet => sheet.state === "visible")
      .map(sheet => sheet.sheet) ?? []
  );
};

/**
 * @returns {Array<object>} - fills data array with 500 objects starting from the last row with data
 */
const populateRows = data => {
  const rowData = [];
  rowData.length = MINIMUM_NUMBER_OF_COLUMNS;
  rowData.fill({}).map(() => ({}));
  const oldLength = data.length;
  data.length = Math.max(oldLength, MINIMUM_NUMBER_OF_ROWS);
  data.fill(rowData, oldLength);

  return data;
};

/**
 * @returns {Array<object>} - reverts the populated data array to only contain relevant rows
 */
const unPopulateRows = data => {
  let firstNonNullOrEmptyIndex = data.length;
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i].every(elem => elem === null)) {
      firstNonNullOrEmptyIndex = i;
    } else {
      break;
    }
  }
  return data.slice(0, firstNonNullOrEmptyIndex);
};

/**
 * @returns {string} - element id for each websheet tab in tabs bar
 */
const getWebsheetSheetTabId = index => `ot-websheet-tab-${index}`;

/**
 * @returns {string} - element id for each websheet tab in dropdown
 */
const getWebsheetDropdownItemId = index => `ot-websheet-dropdown-item-${index}`;

/**
 * @param {object} options
 * @param {string} options.websheetElementId Id of the websheet element
 * @param {number} options.browserWindowHeight window.innerHeight
 * @returns The maximum height for the websheet
 */
const getWebsheetMaxHeight = ({ websheetElementId, browserWindowHeight }) => {
  const hotTopEdge = getTopPositionOfElement(websheetElementId);
  const paddingOffset = 5;
  const bottomOffsets = documentFooterHeightPx + paddingOffset;
  const maxHeightForWebsheet = browserWindowHeight - hotTopEdge - bottomOffsets;
  return maxHeightForWebsheet;
};

const getHiddenColumns = columnsInSheet => {
  const hiddenColumns = (columnsInSheet ?? [])
    .map((c, colId) => ({ colId, hidden: c.hidden }))
    .filter(c => c.hidden)
    .map(c => c.colId);
  return {
    columns: hiddenColumns
  };
};

const getHiddenRows = rowsInSheet => {
  const hiddenRows = (rowsInSheet ?? [])
    .map((r, rowId) => {
      return { rowId, hidden: r.hidden };
    })
    .filter(r => r.hidden)
    .map(r => r.rowId);
  return {
    rows: hiddenRows
  };
};

const getMergeCells = mergesInSheet => {
  if (!mergesInSheet) {
    return undefined;
  }
  return mergingCells(mergesInSheet);
};

/**
 * Evaluates the data in each cell and updates it in-place
 * @param {*} sheetData
 * @returns sheetData (mutated)
 */
const convertCellDataInSheet = sheetData => {
  sheetData.data.forEach(row =>
    row.forEach((data, i) => {
      row[i] = getParsedCellData({
        data
      });
    })
  );
  return sheetData;
};

const getSheetDataFormatter = () => {
  return {
    getHiddenColumns: d => getHiddenColumns(d),
    getHiddenRows: d => getHiddenRows(d),
    getMergeCells: d => getMergeCells(d)
  };
};

export default {
  documentHeaderHeightPx,
  documentFooterHeightPx,
  getWebsheetSheetTabId,
  getCalculatedSheets,
  getDefaultSettings,
  getHeaderNameForSelection,
  getErrorSheetDataTemplate,
  getResult,
  getFormulaString,
  formatDate,
  isLesserThanMinDate,
  getLastCellForMerge,
  formatNumeric,
  getHorizontalAlignment,
  getVerticalAlignment,
  populateData,
  getMaxNumberOfColumns,
  mergingCells,
  formatDollarSignWithZero,
  getParsedCellData,
  convertStringToFormula,
  getSource,
  getColWidths,
  convertColumnToNum,
  convertNumToColumn,
  formatHyperLink,
  populateRows,
  getVisibleSheetNames,
  getMultiFontsErrorCellRef,
  unPopulateRows,
  getWebsheetDropdownItemId,
  getWebsheetMaxHeight,
  getSheetDataFormatter,
  convertCellDataInSheet,
  dateToSerialNumber,
  serialNumberToDate,
  wrapSheetNameInQuotes,
  forTesting: {
    getHiddenColumns,
    getHiddenRows,
    getMergeCells
  }
};
