import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { useGetProjectTags } from "@shared/hooks";
import { useUpdateActionItemFieldMutation } from "@shared/services/actionItemService";

import Form from "@components/atoms/Form/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";

import ModalForm from "../../molecules/ModalForm/ModalForm";

export const EditActionTags = props => {
  const { onUpdate, query, project, queryConfig, t } = props;
  const [error, setError] = useState("");
  const [multiselectTags, setMultiselectTags] = useState([]);
  const { projectTags, loadingProjectTags } = useGetProjectTags(project.id);
  const [updateQueryField, { error: updateQueryError }] =
    useUpdateActionItemFieldMutation();

  useEffect(() => {
    if (projectTags && !loadingProjectTags) {
      const allTags = projectTags.map(tag => ({
        name: tag.name,
        value: tag
      }));
      setMultiselectTags(allTags);
    }
  }, [loadingProjectTags, projectTags]);

  useEffect(() => {
    setError(updateQueryError?.data?.message ?? updateQueryError);
  }, [updateQueryError]);

  const handleUpdateTags = ({ tags: updatedTags = [] }) => {
    const newTags = updatedTags.map(t => t.value);
    updateQueryField({
      queryId: query.id,
      field: "tags",
      value: newTags
    })
      .unwrap()
      .then(updatedQuery => {
        onUpdate(updatedQuery);
      });
  };
  const tagsField = useMemo(() => {
    return queryConfig?.fields.find(f => f.key === "tags") ?? {};
  }, [queryConfig]);

  const initialTags = useMemo(() => {
    if (!query?.tags?.length) {
      return [];
    }
    return query.tags.map(t => ({ id: t.id, name: t.name, value: t }));
  }, [query]);

  return (
    <ModalForm
      boxClassName="action-add-tags"
      title={t("common:ui.editTags.title")}
      handleCancel={props.onCancel}
      handleSubmit={handleUpdateTags}
      submitLabel={t("common:ui.forms.update.label")}
    >
      {error && <InlineAlert type="error" message={t(error)} />}
      <Form.Multiselect
        name="tags"
        label={t("stringFormat.capitalize", {
          key: "tags_other"
        })}
        items={multiselectTags}
        defaultValue={initialTags}
        required={tagsField.required}
        defaultValueComparer={(a, b) => b.id === a.id}
      />
    </ModalForm>
  );
};

EditActionTags.propTypes = {
  project: PropTypes.shape({ id: PropTypes.number.isRequired }),
  query: PropTypes.shape({ id: PropTypes.number.isRequired }),
  queryConfig: PropTypes.shape({ fields: PropTypes.array.isRequired })
};

export default withTranslation()(EditActionTags);
