import { useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";

import { clientDocumentActions } from "@shared/actions";

export function useSearchClientDocuments(clientId) {
  const [searchResults, setSearchResults] = useState([]);
  const clientDocuments = useSelector(state => state.clientDocuments);
  const dispatch = useDispatch();

  const searchDocuments = useMemo(
    () =>
      debounce(searchString => {
        if (searchString) {
          dispatch(
            clientDocumentActions.getClientDocumentsBySearchString(
              { id: clientId },
              encodeURIComponent(searchString)
            )
          );
        }
      }, 300),
    [dispatch, clientId]
  );

  useEffect(() => {
    setSearchResults(clientDocuments.documentsBySearchString || []);
  }, [clientDocuments.documentsBySearchString]);

  return {
    searchDocuments,
    searchResults
  };
}
