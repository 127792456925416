import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import Banner from "@components/atoms/Banner";

import DraftProjectDeleteModal from "../DraftProjectDeleteModal";
import DraftProjectPublishModal from "../DraftProjectPublishModal";
import "./DraftProjectBanner.scss";

interface DraftProjectBannerProps {
  projectId?: number;
  refreshAfterPublish: () => void;
  registerModal: (key: string, content: () => React.JSX.Element) => void;
  handleOpenModal: (key: string) => void;
  handleCloseModal: () => void;
}

const DraftProjectBanner = ({
  projectId,
  refreshAfterPublish,
  registerModal,
  handleOpenModal,
  handleCloseModal
}: DraftProjectBannerProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    registerModal?.("draftProjectPublish", () => (
      <DraftProjectPublishModal
        projectId={projectId}
        handleCloseModal={handleCloseModal}
        refreshAfterPublish={refreshAfterPublish}
      />
    ));
  }, [registerModal, projectId, refreshAfterPublish, handleCloseModal]);

  useEffect(() => {
    registerModal?.("draftProjectDelete", () => (
      <DraftProjectDeleteModal
        projectId={projectId}
        bannerModal={true}
        handleCloseModal={handleCloseModal}
      />
    ));
  }, [registerModal, projectId, handleCloseModal]);

  return (
    <Banner
      text={t("common:ui.projects.draft.banner")}
      iconName="warning"
      primaryButton={{
        label: t("common:ui.projects.draft.publishButton"),
        onClick: () => handleOpenModal("draftProjectPublish")
      }}
      actionDropdown={{
        onItemClick: () => handleOpenModal("draftProjectDelete"),
        items: [
          {
            name: t("common:ui.project.draft.deleteButton"),
            action: "delete"
          }
        ]
      }}
    />
  );
};

export default DraftProjectBanner;
