import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useLazyGetInsightsDrilldownQuery } from "@shared/services/insightsService";

import { useInsightsBoardStore } from "@app/stores/useInsightsBoardStore.ts";

import InlineAlert from "@components/atoms/InlineAlert";
import InsightsCardTable from "@components/molecules/InsightsCard/InsightsCardTable";
import Loading from "@components/molecules/Loading";
import SidePanelContentTemplate from "@components/templates/SidePanelContentTemplate";

const InsightsBoardSidePanel = props => {
  const { insightsBoardId, engagementTypeId } = props;
  const { t } = useTranslation();
  const selectedDrilldown = useInsightsBoardStore(state => state.drilldown);
  const clearDrilldown = useInsightsBoardStore(state => state.clearDrilldown);

  const [
    getInsightsDrilldown,
    { data: drilldownData, error: errorFetchingDrilldownData, isFetching }
  ] = useLazyGetInsightsDrilldownQuery();

  useEffect(() => {
    if (selectedDrilldown?.data?.queryName) {
      getInsightsDrilldown({
        insightsBoardId,
        type: selectedDrilldown?.data?.queryName,
        engagementTypeId,
        params: selectedDrilldown?.data?.params
      });
    }
  }, [
    selectedDrilldown,
    getInsightsDrilldown,
    insightsBoardId,
    engagementTypeId
  ]);

  const getContent = () => {
    if (drilldownData?.table?.length > 0) {
      return (
        <InsightsCardTable
          data={drilldownData.table}
          columns={drilldownData.columns}
        />
      );
    }
    return null;
  };

  const closeDrilldownHandler = useCallback(() => {
    clearDrilldown();
  }, [clearDrilldown]);

  return (
    <>
      {isFetching && <Loading />}
      {errorFetchingDrilldownData && (
        <div style={{ margin: "auto" }}>
          <InlineAlert
            type="error"
            message={t(
              "common:insights.drilldown.errors.failedToFetchData.message"
            )}
          />
        </div>
      )}
      {!isFetching && drilldownData && (
        <SidePanelContentTemplate
          title={t(drilldownData?.title?.key, drilldownData?.title?.properties)}
          content={getContent()}
          onClose={closeDrilldownHandler}
        />
      )}
    </>
  );
};

export default InsightsBoardSidePanel;
