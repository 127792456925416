import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants.js";

import { useToasts } from "@shared/hooks/useToasts.jsx";
import {
  useGetActionItemTypeQuery,
  useGetActionItemTypesFormOptionsQuery,
  useUpdateActionItemTypeMutation
} from "@shared/services/actionItemTypesService.js";

import { CrudFormMode } from "@app/types/ActionItemType.ts";
import { ApiErrorType } from "@app/types/Api.ts";

import FormPageTemplate from "@templates/FormPageTemplate/FormPageTemplate.jsx";

import ActionItemTypeForm from "./ActionItemTypeForm.tsx";
import {
  createAITFormSchema,
  displayName,
  fieldFormValuesToPayload,
  permissionFormValuesToPayload
} from "./actionItemType.tsx";

const UpdateActionItemType = () => {
  const { actionItemTypeId } = useParams();
  const [updateActionItemType, { isLoading: isUpdating }] =
    useUpdateActionItemTypeMutation();
  const { data: actionItemType, isLoading: isAitLoading } =
    useGetActionItemTypeQuery(
      { actionItemTypeId },
      { skip: !actionItemTypeId || isUpdating }
    );
  const { data: formOptions, isLoading: isOptionsLoading } =
    useGetActionItemTypesFormOptionsQuery({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!actionItemTypeId) {
      navigate(routeConstants.notFound);
    }
    if (!isAitLoading && !actionItemType?.configuration?.isUserManaged) {
      navigate(routeConstants.notFound);
    }
  }, [navigate, isAitLoading, actionItemTypeId, actionItemType]);

  const isLoading = useMemo(
    () => isAitLoading || isOptionsLoading,
    [isAitLoading, isOptionsLoading]
  );

  const { showSuccess, showError } = useToasts();

  const yupSchema = useMemo(() => {
    return createAITFormSchema(formOptions, t);
  }, [formOptions, t]);

  const aitDisplayName = useMemo(
    () => displayName(actionItemType, t),
    [actionItemType, t]
  );

  const handleSubmit = useCallback(
    formData => {
      const payload = structuredClone(formData);
      payload.id = actionItemTypeId;
      payload.permissions = permissionFormValuesToPayload(formData.permissions);
      payload.fields = fieldFormValuesToPayload(formData.fields);
      payload.projectYear = formData.projectYear?.value;
      payload.format = formData.format.value;
      payload.engagementTypeId = formData.engagementType.value;
      payload.createFrom = formData.createFrom?.map(
        ({ value }: { value: string }) => value
      );
      delete payload.engagementType;

      if (payload.name === aitDisplayName) {
        delete payload.name;
      }

      updateActionItemType({ actionItemType: payload })
        .unwrap()
        .then(updatedRequestType => {
          showSuccess({
            key: "admin:ui.updateRequestType.success.title"
          });
          navigate(`/admin/request-types/${updatedRequestType.id}`);
        })
        .catch((error: ApiErrorType) => {
          showError({
            key:
              error?.data.message ??
              "admin:ui.updateRequestType.error.generic.title"
          });
        });
    },
    [
      updateActionItemType,
      navigate,
      showError,
      showSuccess,
      actionItemTypeId,
      aitDisplayName
    ]
  );

  return (
    <FormPageTemplate
      title={t("admin:ui.updateRequestType.title", {
        name: aitDisplayName
      })}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageRequestTypes,
          label: t("admin:ui.manageRequestTypes.title")
        }
      ]}
      sticky
      form={{
        handleSubmit,
        handleCancel: () => navigate(routeConstants.admin.manageRequestTypes),
        yupSchema,
        disabled: isUpdating,
        contents: (
          <ActionItemTypeForm
            actionItemType={actionItemType}
            formOptions={formOptions}
            crudFormMode={CrudFormMode.UPDATE}
          />
        )
      }}
      isLoading={isLoading}
      other={{}}
      renderRawForm
    />
  );
};

export default UpdateActionItemType;
