export const manageClientProjectsConstants = {
  GET_PROJECTS_REQUEST: "GET_CLIENT_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS: "GET_CLIENT_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE: "GET_CLIENT_PROJECTS_FAILURE",
  GET_QUERIES_REQUEST: "GET_CLIENT_QUERIES_REQUEST",
  GET_QUERIES_SUCCESS: "GET_CLIENT_QUERIES_SUCCESS",
  GET_QUERIES_FAILURE: "GET_CLIENT_QUERIES_FAILURE",
  RESET: "CLIENT_PROJECTS_RESET",
  CLEAR_ERROR: "CLIENT_PROJECTS_CLEAR_ERROR"
};
