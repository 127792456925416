import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const filtersService = createApi({
  reducerPath: "filtersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Filters"],
  endpoints: builder => ({
    getFilters: builder.query({
      query: ({ projectId }) =>
        `/api/filters?${new URLSearchParams({
          for: "queries",
          projectId
        })}`,
      keepUnusedDataFor: 600, // keep for 10 minutes
      providesTags: ["Filters"]
    })
  })
});

export const { useGetFiltersQuery } = filtersService;
