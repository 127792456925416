import React from "react";

import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FilterConfig,
  SelectedFilterItem
} from "@shared/helpers/actionItemFilterHelper.ts";

import { QUERY_FILTER_TYPES } from "@app/helpers/actionItems";

import Form from "@components/atoms/Form";
import { Box } from "@components/fermions/Box/Box";

import { BorderStyle, InputWidth } from "../inputs/InputTemplate";
import "./PageFilter.scss";

interface PageFilterFormProps {
  filters: Filter[];
  formMethods: object;
}

interface Filter extends FilterConfig {
  selectedItem?: SelectedFilterItem;
}

function PageFilterForm(props: Readonly<PageFilterFormProps>) {
  const { filters, formMethods } = props;
  const { t } = useTranslation();

  function renderFilter(filter: Filter) {
    const sortComparator = (() => {
      if (filter.maintainOrder) {
        return () => 0;
      }
    })();

    switch (filter.type) {
      case QUERY_FILTER_TYPES.MULTI_CHOICE:
        return (
          <Form.Multiselect
            key={filter.key}
            placeholder={t(filter.placeholder)}
            label={filter.translate ? t(filter.label) : filter.label}
            name={filter.key}
            items={filter.options?.map(o => ({
              name: o.translate ? t(o.label) : o.label,
              value: o.value
            }))}
            borderStyle={BorderStyle.NONE}
            width={InputWidth.SMALL}
            allowUndefined
            {...(sortComparator && { sortComparator })}
          />
        );

      case QUERY_FILTER_TYPES.SINGLE_CHOICE:
        return (
          <Form.Dropdown
            key={filter.key}
            placeholder={t(filter.placeholder)}
            label={filter.translate ? t(filter.label) : filter.label}
            name={filter.key}
            items={filter.options?.map(o => ({
              name: o.translate ? t(o.label) : o.label,
              value: o.value
            }))}
            borderStyle={BorderStyle.NONE}
            width={InputWidth.SMALL}
            allowUndefined
            {...(sortComparator && { sortComparator })}
          />
        );

      default:
        return <></>;
    }
  }

  return (
    <FormProvider {...formMethods}>
      {filters.map(filter => (
        <Box key={filter.key}>{renderFilter(filter)}</Box>
      ))}
    </FormProvider>
  );
}

export default PageFilterForm;
