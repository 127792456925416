import React from "react";

import PropTypes from "prop-types";

import "./InfoLabelText.scss";

function InfoLabelText({ label, text, hideEmptyText, className }) {
  return hideEmptyText && !text ? (
    <></>
  ) : (
    <span className={`info-label-text ${className ?? ""}`}>
      <span className="info-label-text__label">{`${label}:`}</span>
      <span className="info-label-info">{text}</span>
    </span>
  );
}
InfoLabelText.defaultProps = {
  hideEmptyText: true
};

InfoLabelText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.any,
  hideEmptyText: PropTypes.bool
};

export default InfoLabelText;
