import React, { useMemo } from "react";

import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import DataTable from "@components/molecules/DataTable";

import "./AnalyticsTable.scss";

const AnalyticsTable = props => {
  const { table } = props;

  const formatCell = (type, cell) => {
    const value = cell.mergedCell ? "" : cell.value;
    if (type === "number") {
      const formattedNumber = Math.abs(value).toLocaleString("en-AU");
      return (
        <span className="data-table__data--number">
          {cell.value < 0 ? `(${formattedNumber})` : formattedNumber}
        </span>
      );
    } else if (type === "tag") {
      return value ? (
        <Pill
          label={value}
          variant={PillVariant.ACCENT}
          fillStyle={PillFillStyle.FILLED}
        />
      ) : (
        <></>
      );
    } else {
      return <span className="data-table__data--string">{value}</span>;
    }
  };

  const columns = useMemo(() => {
    if (table?.columns) {
      const renderWidth = c => {
        if (c.width) {
          return c.width;
        }
        if (c.style === "wide") {
          return 90;
        }
        if (c.type === "tag") {
          return 75;
        }
        return 50;
      };

      return table?.columns.map(c => {
        return {
          Header: c.name,
          accessor: c.name,
          isHidden: c.hidden,
          className: c.className,
          fixedWidth: c.fixedWidth ?? c.type === "tag",
          width: renderWidth(c),
          Cell: ({ value, row }) =>
            formatCell(c.type, value?.content ?? { row, value: c.value })
        };
      });
    }
    return [];
  }, [table.columns]);

  const data = useMemo(() => {
    const columnNames = table?.columns?.map(c => c.name);
    return (
      table?.rows?.map((row, index) => {
        const rowData = row.cells
          .map((col, colIndex) => {
            return {
              [columnNames[colIndex]]: {
                value: col.value
              }
            };
          })
          .reduce((result, obj) => {
            result[Object.keys(obj)[0]] = {
              content: Object.values(obj)[0],
              style: row.style
            };
            return result;
          }, {});
        return { id: index, style: row.style ?? "", ...rowData };
      }) ?? []
    );
  }, [table?.columns, table?.rows]);

  const rowClassNames = useMemo(
    () => rowId => {
      const rowStyles = table?.rows
        ?.filter(r => r.id !== undefined && r.id === rowId)
        .map(r => r.style?.join?.(" "));
      return rowStyles;
    },
    [table?.rows]
  );

  return (
    <div className="analytics-table">
      <DataTable
        className="ot-analytic-table"
        columns={columns}
        data={data}
        height={300}
        rowClassNames={rowClassNames}
      />
    </div>
  );
};

export default AnalyticsTable;
