import { useCallback, useRef } from "react";

interface Props {
  skip?: boolean;
  cb: () => unknown;
  delayMs?: number;
}

/**
 * Invokes the callback after delayMs (default 500ms) if skip evaluates to false.
 * If the handleOnMouseEnter event is called before the delay, the callback is cancelled.
 */
export function useMouseLeaveEnter(props: Props) {
  const { cb, skip, delayMs = 500 } = props;
  const autoCloseHandler = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleOnMouseLeave = useCallback(() => {
    if (skip) {
      return () => {};
    }
    return () => {
      autoCloseHandler.current = setTimeout(() => cb?.(), delayMs);
    };
  }, [cb, skip, delayMs]);

  // NB: this function returning a function is a bit redundant but it's to match the signature of handleOnMouseLeave
  const handleOnMouseEnter = useCallback(
    () => () => {
      autoCloseHandler.current && clearTimeout(autoCloseHandler.current);
    },
    []
  );

  return {
    handleOnMouseLeave,
    handleOnMouseEnter
  };
}
