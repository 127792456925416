import { useCallback, useEffect, useState } from "react";

import { useFetch } from "./useFetch";

export function useGetCopyProjectTagsList() {
  const { isLoading, data, error, doFetch } = useFetch({});
  const [projects, setProjects] = useState([]);

  const fetchProjectsWithTags = useCallback(
    currentEngagementId => {
      doFetch(`/users/me/clientProjectTags/${currentEngagementId}`);
    },
    [doFetch]
  );

  useEffect(() => {
    if (data && !error) {
      setProjects(data);
    }
  }, [data, error]);

  return {
    checkError: error,
    isCheckLoading: isLoading,
    projects,
    fetchProjectsWithTags
  };
}
