import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  editJSON,
  isEditing
} from "@shared/features/interactiveReport/interactiveReportSlice";
import {
  useGetReportJSONFileQuery,
  useSaveReportJSONFileMutation
} from "@shared/hooks";
import { useToasts } from "@shared/hooks/useToasts";
import { useGetSchemaQuery } from "@shared/services/jsonEditorService";

export function useInteractiveReportJSONEditor(projectId) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const schema = useGetSchemaQuery({
    id: "interactiveReport.oneteam.schema"
  });

  const [
    onSave,
    {
      isSuccess: isSuccessOnSaveJsonEditor,
      error: errorOnSaveJsonEditor,
      isLoading: isLoadingOnSaveJsonEditor
    }
  ] = useSaveReportJSONFileMutation();

  const onEdit = useSelector(
    state => state.interactiveReportJsonEditor?.[projectId]?.isEditing
  );

  const {
    data: jsonData,
    isLoading: isJsonDataLoading,
    isFetching: isJsonDataFetching
  } = useGetReportJSONFileQuery({
    projectId
  });

  const localJSONContent = useSelector(state => {
    const localJson = state.interactiveReportJsonEditor;
    return localJson?.[projectId]?.jsonContent;
  });

  const { showSuccess, showError } = useToasts();
  const showSuccessfullySavedToast = useCallback(() => {
    showSuccess({
      key: "interactiveReport:interactiveReport.jsonEditor.saveSuccess"
    });
  }, [showSuccess]);

  const [saveError, setSaveError] = useState(null);

  /**
   * Show Success Message upon successfully saving the json editor
   */
  useEffect(() => {
    if (isSuccessOnSaveJsonEditor) {
      showSuccessfullySavedToast();
    }
  }, [isSuccessOnSaveJsonEditor, showSuccessfullySavedToast]);

  /**
   * If there are errors upon saving updating the Error state
   */
  useEffect(() => {
    const errorData = errorOnSaveJsonEditor?.data;

    if (errorData?.context?.path && errorData?.key) {
      setSaveError({
        path: errorData.context.path,
        message: errorData.key,
        title: "Save Error"
      });
    } else if (errorData?.key) {
      showError({ key: errorData?.key });
    }
  }, [errorOnSaveJsonEditor, setSaveError, showError]);

  /**
   * Side effect of reverting the editing state upon successfull saving
   */
  useEffect(() => {
    if (isSuccessOnSaveJsonEditor) {
      dispatch(isEditing({ projectId, isEditing: !isSuccessOnSaveJsonEditor }));
    }
  }, [projectId, isSuccessOnSaveJsonEditor, dispatch]);

  /**
   * Changing the isEditing state of the store if localJSONContent is updated
   * @param {*} value
   */
  const onChangeJSONEditor = value => {
    if (localJSONContent != value) {
      dispatch(isEditing({ projectId, isEditing: true }));
      dispatch(
        editJSON({
          projectId: projectId,
          jsonContent: value
        })
      );
    }
  };

  /**
   *  Upon saving the json editor, Do basic parsing and if invalid invoking the same error message
      Wont be submitted to server if basic json rules are violated. 
   */
  const onSaveJSONEditor = () => {
    let parsedJsonContentString;
    try {
      parsedJsonContentString = localJSONContent
        ? JSON.parse(localJSONContent)
        : {};
    } catch (e) {
      setSaveError({
        path: e.message ? e.message : "",
        message: t(
          "interactiveReport:interactiveReport.jsonEditor.invalidSyntax"
        ),
        title: "Save Error"
      });
      return;
    }
    onSave({ projectId, jsonContent: parsedJsonContentString });
  };

  /**
   * Update the local state only if not editing
   */
  useEffect(() => {
    if (!onEdit && !isJsonDataFetching) {
      dispatch(
        editJSON({
          projectId: projectId,
          jsonContent: jsonData
        })
      );
    }
  }, [dispatch, jsonData, isJsonDataFetching, onEdit, projectId]);

  return {
    schema,
    onEdit,
    onChangeJSONEditor,
    onSaveJSONEditor,
    saveError,
    setSaveError,
    jsonData,
    editJSON,
    localJSONContent,
    isJsonDataLoading,
    isSuccessOnSaveJsonEditor,
    isLoadingOnSaveJsonEditor,
    errorOnSaveJsonEditor
  };
}
