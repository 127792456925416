import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import Loading from "@components/molecules/Loading/Loading";

import "./PageOverlay.scss";

const PageOverlay = props => {
  const { isVisible, text } = props;

  useEffect(() => {
    handleFreezeScrollPosition(isVisible);
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div className="page-overlay">
          <Loading />
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

PageOverlay.defaultProps = {
  isVisible: false,
  text: ""
};

PageOverlay.propTypes = {
  isVisible: PropTypes.bool,
  text: PropTypes.string
};

export default PageOverlay;
