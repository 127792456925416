export const manageClientEngagementProjectsConstants = {
  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_REQUEST:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_REQUEST",
  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_SUCCESS:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_SUCCESS",
  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_FAILURE:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_FAILURE",

  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_REQUEST:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_REQUEST",
  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_SUCCESS:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_SUCCESS",
  GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_FAILURE:
    "GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_FAILURE",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

  GET_MY_PROJECTS_REQUEST: "GET_MY_PROJECTS_REQUEST",
  GET_MY_PROJECTS_SUCCESS: "GET_MY_PROJECTS_SUCCESS",
  GET_MY_PROJECTS_FAILURE: "GET_MY_PROJECTS_FAILURE",

  ADD_CLIENT_ENGAGEMENT_PROJECT_REQUEST:
    "ADD_CLIENT_ENGAGEMENT_PROJECT_REQUEST",
  ADD_CLIENT_ENGAGEMENT_PROJECT_SUCCESS:
    "ADD_CLIENT_ENGAGEMENT_PROJECT_SUCCESS",
  ADD_CLIENT_ENGAGEMENT_PROJECT_FAILURE:
    "ADD_CLIENT_ENGAGEMENT_PROJECT_FAILURE",

  UPDATE_CLIENT_ENGAGEMENT_PROJECT_REQUEST:
    "UPDATE_CLIENT_ENGAGEMENT_PROJECT_REQUEST",
  UPDATE_CLIENT_ENGAGEMENT_PROJECT_SUCCESS:
    "UPDATE_CLIENT_ENGAGEMENT_PROJECT_SUCCESS",
  UPDATE_CLIENT_ENGAGEMENT_PROJECT_FAILURE:
    "UPDATE_CLIENT_ENGAGEMENT_PROJECT_FAILURE",

  CLEAR_OPEN_ITEMS: "CLEAR_OPEN_ITEMS",
  CLEAR_MY_PROJECTS: "CLEAR_MY_PROJECTS",
  CLEAR_ERROR: "CLIENT_ENGAGEMENT_PROJECT_CLEAR_ERROR",
  SET_ERROR: "CLIENT_ENGAGEMENT_PROJECT_SET_ERROR",
  RESET: "CLIENT_ENGAGEMENT_PROJECT_RESET",
  CLEAR_ADD_CLIENT_ENGAGEMENT_PROJECT_FLAG:
    "CLEAR_ADD_CLIENT_ENGAGEMENT_PROJECT_FLAG",
  CLEAR_UPDATE_CLIENT_ENGAGEMENT_PROJECT_FLAG:
    "CLEAR_UPDATE_CLIENT_ENGAGEMENT_PROJECT_FLAG"
};
