import React from "react";

import Title from "@components/atoms/Title/Title.tsx";
import { Inline } from "@components/fermions/index.tsx";

import "./SidePanelContentTemplate.scss";

interface SidePanelContentTemplateProps {
  title: string;
  titleStyle?: "text" | "styled";
  subtitle?: string;
  filters?: React.ReactNode;
  content?: React.ReactNode;
  onClose?: () => void;
  sidePanelContentClassName?: string;
  headerUnderline?: boolean;
}

const SidePanelContentTemplate = ({
  title,
  titleStyle = "text",
  subtitle,
  filters,
  content,
  onClose,
  sidePanelContentClassName,
  headerUnderline
}: SidePanelContentTemplateProps) => {
  return (
    <div className={`side-panel-template ${sidePanelContentClassName}`}>
      <Inline
        className={`${headerUnderline ? "side-panel-template__header--no-border" : "side-panel-template__header"}`}
      >
        {onClose && (
          <div className="side-panel-template__close" onClick={onClose}>
            <i className="material-icons">close</i>
          </div>
        )}
        {titleStyle === "styled" ? (
          <Title title={title} />
        ) : (
          <div className={"side-panel-template__title"}>{title}</div>
        )}
      </Inline>
      {subtitle && (
        <div className={"side-panel-template__subtitle"}>{subtitle}</div>
      )}
      {filters && (
        <div className={"side-panel-template__filter"}>{filters}</div>
      )}
      <div className={"side-panel-template__content"}>{content ?? ""}</div>
    </div>
  );
};

export default SidePanelContentTemplate;
