import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

export function useDocumentUploadInProgress() {
  const [documentUploadInProgress, setDocumentUploadInProgress] =
    useState(false);
  const manageDocumentUploads = useSelector(
    state => state.manageDocumentUploads
  );

  useEffect(() => {
    setDocumentUploadInProgress(
      !(
        manageDocumentUploads &&
        manageDocumentUploads.uploadingDocuments &&
        manageDocumentUploads.uploadingDocuments.length === 0
      )
    );
  }, [manageDocumentUploads]);

  return { documentUploadInProgress };
}
