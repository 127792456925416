import React from "react";

import "./ContainerBar.scss";

function ContainerBar(props) {
  return <div className="ot-container-bar">{props.children}</div>;
}

ContainerBar.propTypes = {};

export default ContainerBar;
