import React, { useMemo } from "react";

import { RoleMode } from "@app/types";

import { Box } from "@fermions";

import { Icon, IconColor, IconFillStyle } from "@atoms/Icon";

import { CheckboxInput } from "@molecules/inputs";

type Props = {
  visible: boolean;
  disabled: boolean;
  value: boolean;
  width: string;
  className: string;
  onChange: (value: boolean, name: string) => void;
  name: string;
  mode: RoleMode;
};
function ResourceRowTemplate(props: Props) {
  const { visible, disabled, value, width, className, onChange, name, mode } =
    props;

  const cellContent = useMemo(() => {
    if (!visible || value === undefined) {
      return <></>;
    }

    if (mode === RoleMode.VIEW) {
      if (!value) {
        return (
          <Icon
            name="cancel"
            fillStyle={IconFillStyle.OUTLINED}
            color={IconColor.MUTED}
          />
        );
      }
      return (
        <Icon
          name="check_circle"
          fillStyle={IconFillStyle.FILLED}
          color={IconColor.SECONDARY}
        />
      );
    }
    return (
      <CheckboxInput
        disabled={disabled}
        onChange={value => onChange(value, name)}
        value={value}
        className={className}
      />
    );
  }, [className, disabled, mode, name, onChange, value, visible]);

  return (
    <td className={className} style={{ width }}>
      <Box width="fill" alignment="center">
        {cellContent}
      </Box>
    </td>
  );
}

export default ResourceRowTemplate;
