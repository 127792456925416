import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants";

const ACTION_ITEM_TYPES = systemConstants.actionItemTypes;

export function useRequestPageNavigator() {
  const navigate = useNavigate();

  const openRequestUrl = useCallback(
    (url, openInNewWindow, replace) => {
      if (openInNewWindow) {
        window.open(url, "_blank");
      } else {
        navigate(url, {
          replace
        });
      }
    },
    [navigate]
  );

  const navigateToRequestPage = useCallback(
    (
      queryId,
      projectId,
      actionItemType,
      {
        websheetOpensNewWindow = false,
        opensNewWindow = false,
        replace = false,
        queryParams = {},
        hash = null
      } = {}
    ) => {
      const urlQueryParams = Object.entries(queryParams)?.length
        ? `?${Object.entries(queryParams)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
      const urlHash = hash !== null ? `#${hash}` : "";
      const urlAdditions = `${urlQueryParams}${urlHash}`;

      if (actionItemType === ACTION_ITEM_TYPES.websheet) {
        openRequestUrl(
          `/projects/${projectId}/websheets/${queryId}${urlAdditions}`,
          websheetOpensNewWindow || opensNewWindow,
          replace
        );
        return;
      }

      if (actionItemType === ACTION_ITEM_TYPES.conversation) {
        openRequestUrl(
          `/projects/${projectId}/queries/${queryId}${urlAdditions}`,
          opensNewWindow,
          replace
        );
        return;
      }

      if (actionItemType === ACTION_ITEM_TYPES.smartForm) {
        openRequestUrl(
          `/projects/${projectId}/smartforms/${queryId}${urlAdditions}`,
          opensNewWindow,
          replace
        );
        return;
      }

      if (actionItemType) {
        openRequestUrl(
          `/projects/${projectId}/queries${urlAdditions}`,
          opensNewWindow,
          replace
        );
      }
    },
    [openRequestUrl]
  );

  return {
    navigateToRequestPage
  };
}
