import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAuthUser } from "@shared/hooks";

import { AccessLevel, ResourceName } from "@app/types";

import SearchTextInput from "@components/organisms/SearchTextInput";

import "./ProjectDashboardFilter.scss";
import ProjectDashboardFilterForm from "./ProjectDashboardFilterForm";

const ProjectDashboardFilter = props => {
  const { filters, handleChange } = props;
  const formMethods = useForm({
    defaultValues: { myProjects: true, completed: false }
  });
  const { t } = useTranslation();

  const { user } = useAuthUser();
  const [searchString, setSearchString] = useState("");
  const watchFormValues = useWatch({
    control: formMethods.control
  });

  useEffect(() => {
    const dataToSubmit = Object.entries(watchFormValues).reduce(
      (acc, [key, val]) => {
        const filterVal = val?.value ?? val;
        if (filterVal !== undefined && filterVal !== null) {
          acc[key] = filterVal;
        }
        return acc;
      },
      {}
    );
    if (searchString) {
      dataToSubmit.search = searchString;
    }

    handleChange(dataToSubmit);
  }, [watchFormValues, handleChange, searchString]);

  return (
    <div className="project-dashboard-filter__container">
      <div className="project-dashboard-filter--left">
        <SearchTextInput
          className="project-dashboard-filter--search"
          handleChange={setSearchString}
          shouldDebounce={true}
          placeholder={t("common:ui.forms.searchProjects.label")}
        />
      </div>
      <div className="project-dashboard-filter--right">
        <ProjectDashboardFilterForm
          filters={filters}
          formMethods={formMethods}
          showMyProjects={
            user.isHostUser &&
            user.checkAccess(
              ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
              AccessLevel.READ
            )
          }
        />
      </div>
    </div>
  );
};

ProjectDashboardFilter.propTypes = {
  filters: PropTypes.shape({
    clients: PropTypes.arrayOf(PropTypes.string),
    projectYears: PropTypes.arrayOf(PropTypes.string),
    engagementTypes: PropTypes.arrayOf(PropTypes.string)
  }),
  handleChange: PropTypes.func
};

export default ProjectDashboardFilter;
