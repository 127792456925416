export type rejectedFileType = {
  errors: Array<{
    code: string;
  }>;
};

const handleDropRejection = (
  filesRejected: rejectedFileType[],
  invalidFileType: string,
  invalidFileNumber: string
) => {
  const rejectReasons = new Set();
  filesRejected.forEach(f => {
    f.errors.forEach(e => {
      rejectReasons.add(e.code);
    });
  });
  if (rejectReasons.has("too-many-files")) {
    return invalidFileNumber;
  } else if (rejectReasons.has("file-invalid-type")) {
    return invalidFileType;
  }
};

export const uploadFileHelper = {
  handleDropRejection
};
