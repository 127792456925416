import { useCallback, useRef } from "react";

export function useDidMount() {
  const didMountRef = useRef(true);

  const startLoading = useCallback(loading => {
    if (loading) {
      didMountRef.current = false;
    }
  }, []);

  const resetMountRef = useCallback(() => {
    didMountRef.current = true;
  }, []);

  return { didMountRef, startLoading, resetMountRef };
}
