import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useAuthUser, useCurrentProject } from "@shared/hooks";
import { useLazyGetClientProjectsForMenuQuery } from "@shared/services/clientProjectsService";

import { classNames, getScrollPosition } from "@app/helpers/componentHelpers";
import { AccessLevel, ResourceName } from "@app/types";

import { Icon, IconSize } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import { DropdownTheme } from "@components/molecules/DropdownList/DropdownHelper";

import "./ProjectMultilevelDropdown.scss";

const ProjectMultilevelDropdown = props => {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { currentProject } = useCurrentProject();
  const { name, dropdownItems, isActive, handleNavigate, isExpandable } = props;
  const [showDropdownMenu, setShowDropdownMenu] = useState(false); // Toggle top level
  const [expandedItemId, setExpandedItemId] = useState(null); // Toggle secondary level
  const [getClientProjects, { data: clientProjects, isFetching, isError }] =
    useLazyGetClientProjectsForMenuQuery();

  const handleClickSecondaryItem = useCallback(
    item => {
      if (!item?.id) {
        return;
      }
      handleNavigate(`/projects/${item.id}`);
      setShowDropdownMenu(false);
    },
    [handleNavigate]
  );

  const buttonRef = useRef(null);
  const navItemRef = useRef(null);

  useOnClickOutside(navItemRef, e => {
    //we need both navItemRef and buttonRef to check if the click is outside the dropdown
    //because there is no common parent that is a rectangle
    if (!buttonRef.current?.contains(e.target)) {
      setShowDropdownMenu(false);
    }
  });

  const handleExpandPrimaryItem = useCallback(
    item => {
      setExpandedItemId(prevItemId => {
        if (item?.id && prevItemId !== item?.id) {
          // when the item is getting opened, we want to call the API to get the projects
          getClientProjects(item?.id, true);
          return item?.id;
        }
        return item?.id;
      });
    },
    [getClientProjects]
  );

  const handlePrimaryListScroll = () => {
    setExpandedItemId(null);
  };

  const marginTop = useMemo(() => {
    const element = document.getElementById(
      `ot-dropdown-item-${expandedItemId}`
    );
    const position = getScrollPosition(
      element,
      "project-multilevel-dropdown__inner"
    );
    return position.top;
  }, [expandedItemId]);

  const primaryListActions = useMemo(() => {
    if (
      isExpandable &&
      user.checkAccess(ResourceName.CLIENTS, AccessLevel.CREATE)
    ) {
      const navigateToCreateClient = () => {
        setShowDropdownMenu(false);
        navigate(routeConstants.addClient);
      };

      return [
        {
          name: t("common:ui.manageClients.addClient"),
          iconName: "add",
          onClick: navigateToCreateClient,
          key: "add-client-action"
        }
      ];
    }
  }, [isExpandable, navigate, t, user]);

  const secondaryListActions = useMemo(() => {
    if (
      !isFetching &&
      !isError &&
      expandedItemId &&
      user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      )
    ) {
      const navigateToCreateProject = () => {
        setShowDropdownMenu(false);
        navigate(`/create-project#clientId=${expandedItemId}`);
      };
      return [
        {
          name: t("common:ui.project.title_create"),
          iconName: "add",
          onClick: navigateToCreateProject,
          key: "create-client-project-action"
        }
      ];
    }
  }, [expandedItemId, isError, isFetching, navigate, t, user]);

  const secondaryList = useMemo(
    () =>
      expandedItemId && !isError ? (
        <DropdownList
          key="project-multilevel-dropdown-secondary-list"
          className="project-multilevel-dropdown__secondary"
          isOpen
          items={clientProjects?.map(item => {
            return {
              ...item,
              tag: [
                item.year,
                item.status === systemConstants.project.status.draft
                  ? t("common:ui.projects.draft.label")
                  : undefined
              ],
              isActive: currentProject?.id === item.id
            };
          })}
          showSearch={!isFetching && clientProjects?.length > 0}
          theme={DropdownTheme.DARK}
          onClickItem={handleClickSecondaryItem}
          minWidthOverride={300}
          style={{ marginTop }}
          isLoading={isFetching}
          sortComparator={() => 0}
          searchPlaceholder={t("common:ui.forms.searchProjects.label")}
          actions={secondaryListActions}
        />
      ) : (
        <></>
      ),
    [
      clientProjects,
      currentProject?.id,
      handleClickSecondaryItem,
      isFetching,
      marginTop,
      secondaryListActions,
      expandedItemId,
      t,
      isError
    ]
  );

  useEffect(() => {
    if (!showDropdownMenu) {
      setExpandedItemId(null);
    }
  }, [showDropdownMenu]);

  const renderClassName = useMemo(
    () =>
      `project-multilevel-dropdown__items ${
        showDropdownMenu ? "project-multilevel-dropdown__items-show" : ""
      }`,
    [showDropdownMenu]
  );

  const primaryListItems = useMemo(() => {
    return dropdownItems.map(item => ({
      ...item,
      value: item?.id,
      hasSubItems: isExpandable,
      tag: [item.year],
      isActive: isExpandable
        ? currentProject?.client?.id === item.id
        : currentProject?.id === item?.id,
      isHover: expandedItemId === item.id
    }));
  }, [
    currentProject?.client?.id,
    currentProject?.id,
    dropdownItems,
    expandedItemId,
    isExpandable
  ]);

  return (
    <div
      className={classNames([
        "project-multilevel-dropdown",
        isActive ? "active" : "",
        showDropdownMenu ? "open" : ""
      ])}
      key="project-multilevel-dropdown"
      onMouseEnter={() => setShowDropdownMenu(true)}
      onMouseLeave={() => setShowDropdownMenu(false)}
      ref={buttonRef}
      role="button"
    >
      <div
        key="project-multilevel-dropdown-background"
        className={classNames([
          "project-multilevel-dropdown__background",
          isActive ? "active" : ""
        ])}
      />
      <button
        className="project-multilevel-dropdown__button"
        key="project-multilevel-dropdown-button"
      >
        <span className="project-multilevel-dropdown__label">{name}</span>
        <Icon
          name={showDropdownMenu ? "expand_less" : "expand_more"}
          className="material-icons"
          size={IconSize.S}
        />
      </button>
      <div
        key="project-multilevel-dropdown-inner"
        className={renderClassName}
        id="project-multilevel-dropdown__inner"
        onScroll={handlePrimaryListScroll}
        ref={navItemRef}
      >
        {/* Primary Item List */}
        <DropdownList
          key="project-multilevel-dropdown-primary-list"
          showSearch={primaryListItems?.length > 0}
          className="project-multilevel-dropdown__primary"
          items={primaryListItems}
          theme={DropdownTheme.DARK}
          searchPlaceholder={props.placeholder}
          onMouseEnterItem={item => {
            if (isExpandable) {
              handleExpandPrimaryItem(item);
            }
          }}
          onClickItem={item => {
            if (!isExpandable) {
              handleClickSecondaryItem(item);
            }
          }}
          onScroll={handlePrimaryListScroll}
          minWidthOverride={300}
          actions={primaryListActions}
        />
        {/* Secondary Item List */}
        {secondaryList}
      </div>
    </div>
  );
};

ProjectMultilevelDropdown.propTypes = {
  name: PropTypes.string,
  dropdownItems: PropTypes.array,
  isActive: PropTypes.bool,
  handleNavigate: PropTypes.func,
  isExpandable: PropTypes.bool,
  placeholder: PropTypes.string
};

export default ProjectMultilevelDropdown;
