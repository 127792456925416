import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";

import { classNames } from "@app/helpers/componentHelpers";

import { Box } from "@fermions";

import { Icon, IconSize } from "@atoms/Icon";

import { DropdownList, DropdownListProps } from "@molecules/DropdownList";

import {
  DropdownItemType,
  DropdownTheme,
  sortDropdownItems
} from "../../DropdownList/DropdownHelper";
import {
  InputLabel,
  InputLabelProps,
  InputTemplate,
  InputTemplateProps,
  InputType,
  InputWrapper,
  InputWrapperProps,
  getInputIdentifier
} from "../InputTemplate";

interface MultiSelectInputProps
  extends InputTemplateProps,
    InputLabelProps,
    InputWrapperProps,
    DropdownListProps {
  showSearch?: boolean;
  showSelectAll?: boolean;
  onClose?: () => void;
  selectedValues?: DropdownItemType[];
  onChange?: (values: DropdownItemType[]) => void;
}

export const MultiSelectInput = forwardRef(
  (
    {
      className,
      onChange,
      items = [],
      selectedValues = [],
      label,
      emptyLabel,
      labelPosition,
      required,
      description,
      hideLabel,
      disabled,
      placeholder,
      error,
      borderStyle,
      allowUndefined,
      onBlur,
      extraInputProps,
      width,
      closeOnMouseLeave,
      onClose,
      sortComparator,
      fontColor,
      showSearch = true,
      showSelectAll = true
    }: MultiSelectInputProps,
    fwdRef
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const setSelectedValues = useCallback(
      (values = []) => onChange?.(values),
      [onChange]
    );

    const multiSelectRef = useRef();

    const identifier = useMemo(
      () => getInputIdentifier(label, "multiselect-input"),
      [label]
    );

    const multiSelectLabel = useMemo(
      () => (
        <InputLabel
          label={label}
          disabled={disabled}
          required={required}
          description={description}
          id={identifier}
          hideLabel={hideLabel}
          fontColor={fontColor}
        />
      ),
      [label, disabled, required, description, identifier, hideLabel, fontColor]
    );

    const openElement = useMemo(
      () => (
        <DropdownList
          isOpen={isOpen}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          items={items}
          parentRef={multiSelectRef}
          showSearch={showSearch} // autoFocus search when open
          showSelectAll={showSelectAll}
          className={isOpen && !disabled ? "visible" : "hidden"}
          isMultiSelect
          theme={DropdownTheme.LIGHT}
          sortComparator={sortComparator}
        />
      ),
      [
        isOpen,
        selectedValues,
        setSelectedValues,
        items,
        multiSelectRef,
        disabled,
        sortComparator
      ]
    );

    const multiSelectIcons = useMemo(
      () => (
        <>
          {selectedValues?.length > 0 && (
            <Box className="input-wrapper__count">{selectedValues.length}</Box>
          )}
          <Icon
            name="expand_more"
            size={IconSize.S}
            className={classNames([
              "input-wrapper__icon",
              "input-wrapper__icon--rotating",
              `${isOpen}`
            ])}
          />
        </>
      ),
      [isOpen, selectedValues?.length]
    );

    const changeOpenStatus = useCallback(() => {
      setIsOpen(prev => {
        if (prev) {
          onClose?.();
        }
        return !prev;
      });
    }, [setIsOpen, onClose]);

    const current = useMemo(() => {
      if (emptyLabel) {
        return emptyLabel;
      }
      return (
        sortDropdownItems(selectedValues, sortComparator)
          ?.map(item => item.name)
          ?.join(", ") ?? ""
      );
    }, [selectedValues, sortComparator, emptyLabel]);

    const inputWrapperElement = useMemo(
      () => (
        <InputWrapper
          ref={fwdRef}
          current={current}
          setCurrent={setSelectedValues}
          placeholder={placeholder}
          disabled={disabled}
          type={InputType.MULTISELECT}
          isOpen={isOpen}
          clearValue={setSelectedValues}
          error={error}
          onClick={() => changeOpenStatus()}
          rightIcons={multiSelectIcons}
          borderStyle={borderStyle}
          allowUndefined={allowUndefined}
          onBlur={onBlur}
          id={identifier}
          extraInputProps={extraInputProps}
        />
      ),
      [
        selectedValues,
        setSelectedValues,
        placeholder,
        disabled,
        isOpen,
        changeOpenStatus,
        error,
        multiSelectIcons,
        extraInputProps,
        allowUndefined,
        borderStyle,
        fwdRef,
        identifier,
        onBlur,
        sortComparator
      ]
    );

    return (
      <InputTemplate
        className={classNames(["multi-select-input", className ?? ""])}
        ref={multiSelectRef}
        labelElement={multiSelectLabel}
        labelPosition={labelPosition}
        inputWrapperElement={inputWrapperElement}
        openElement={openElement}
        setIsOpen={changeOpenStatus}
        error={error}
        borderStyle={borderStyle}
        testId="test-multiselect-input"
        width={width}
        closeOnMouseLeave={!isOpen ? false : closeOnMouseLeave}
      />
    );
  }
);
