// ConditionalLogic is a Forest of Tree Structures
// Each Tree Structure is a set of nodes with a single root node
// This helper class provides methods to query the forest and the associated questions
// Terminology
// Node: Any node in the forest (includes both Roots and Leafs)
// Root: Any node which has no parent
// Leaf: Any node at the end of a tree (NB: for single-root trees, the root are also leaves)
import { keyBy, memoize } from "lodash";

const isRootPredicate = node => node.logicType === "root";

/**
 * @typedef {Object} ConditionalLogicForest
 * @property {function(): Object[]} getAllNodes
 * @property {function(): Object[]} getRootNodes
 * @property {function(): Object[]} getLeafNodes
 * @property {function(string): Object} getNodeById
 * @property {function(string): Object} getRootNode
 * @property {function(string): Object[]} getLeafNodesByRootId
 * @property {function(string): Object[]} getRootNodesWhichImpactQuestionId
 * @property {function(string): Object[]} getNodesByRootId
 * @property {function(string): Object} getQuestionById
 * @property {function(string): Object[]} getQuestionsInCategory
 * @property {function(string): Object[]} getQuestionsInSubcategory
 * @property {function(): Object[]} getAllQuestions
 */

/**
 * @param {object} options
 * @param {object} options.config The conditional logic config
 * @param {object[]} options.questions List of all questions
 * @returns {ConditionalLogicForest}
 */
export function ConditionalLogicForest({ config, questions }) {
  const getAllNodes = () => config;

  const getRootNodes = memoize(() => config.filter(isRootPredicate));

  const getLeafNodes = memoize(() => {
    const doesNodeHaveChild = new Set();

    config
      .filter(n => n.parentId)
      .forEach(n => {
        doesNodeHaveChild.add(n.parentId);
      });

    const leafNodes = config.filter(n => !doesNodeHaveChild.has(n.id));
    return leafNodes;
  });

  const getNodeById = memoize(nodeId => config.find(n => n.id === nodeId));

  const getRootNode = memoize(nodeId => {
    const node = getNodeById(nodeId);
    if (isRootPredicate(node)) {
      return node;
    }

    return getRootNode(node.parentId);
  });

  // root node is returned as the first element of the list. Other nodes are returned in original order in the config
  const getNodesByRootId = memoize(rootNodeId => {
    const nodes = config.filter(n => getRootNode(n.id).id === rootNodeId);
    nodes.sort((a, b) => {
      if (isRootPredicate(a)) {
        return -1;
      }
      if (isRootPredicate(b)) {
        return 1;
      }
      return 0;
    });
    return nodes;
  });

  const getLeafNodesByRootId = memoize(rootNodeId =>
    getLeafNodes().filter(n => getRootNode(n.id).id === rootNodeId)
  );

  const getRootNodesWhichImpactQuestionId = memoize(questionId => {
    const question = questions.find(q => q.questionId === questionId);
    if (!question) {
      return [];
    }

    const categoryId = question.categoryId;
    const subCategoryId = question.subCategoryId;

    const rootNodes = getRootNodes().filter(rootNode => {
      const { actions } = rootNode;
      return actions.some(a => {
        switch (a.type) {
          case "category": {
            return a.id === categoryId;
          }
          case "subCategory": {
            return a.id === subCategoryId;
          }
          case "question": {
            return a.id === questionId;
          }
          default:
            throw new Error(`unknown type ${a.type}`);
        }
      });
    });

    return rootNodes;
  });

  const questionsById = keyBy(questions, "questionId");
  const getQuestionById = questionId => {
    return questionsById[questionId];
  };
  const getQuestionsInCategory = memoize(categoryId =>
    Object.values(questionsById).filter(q => q.categoryId === categoryId)
  );
  const getQuestionsInSubcategory = memoize(subCategoryId =>
    Object.values(questionsById).filter(q => q.subCategoryId === subCategoryId)
  );
  const getAllQuestions = memoize(() => Object.values(questions));

  return {
    getAllNodes,
    getRootNodes,
    getLeafNodes,
    getNodeById,
    getRootNode,
    getLeafNodesByRootId,
    getRootNodesWhichImpactQuestionId,
    getNodesByRootId,
    getQuestionById,
    getQuestionsInCategory,
    getQuestionsInSubcategory,
    getAllQuestions
  };
}
