import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";

export function useProjectDocumentRevisions(document = null) {
  const dispatch = useDispatch();
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const [revisions, setRevisions] = useState([]);
  const [activeRevisionId, setActiveRevisionId] = useState();
  const [currentDocument, setCurrentDocument] = useState(document);
  const [updateRevisionSuccess, setUpdateRevisionSuccess] = useState(false);

  const fetchDocumentRevisions = useCallback(() => {
    if (currentDocument) {
      dispatch(
        manageProjectDocumentsActions.getProjectDocumentRevisions(
          currentDocument
        )
      );
      setActiveRevisionId(currentDocument?.documentRevisionId);
    }
  }, [dispatch, currentDocument]);

  useEffect(() => {
    if (manageProjectDocuments.documentRevisions?.length) {
      setRevisions(manageProjectDocuments.documentRevisions);
    }
  }, [manageProjectDocuments.documentRevisions]);

  useEffect(() => {
    setUpdateRevisionSuccess(manageProjectDocuments.updateDocumentSuccess);
  }, [manageProjectDocuments.updateDocumentSuccess]);

  const updateDocumentRevisionToActive = useCallback(() => {
    const item = revisions.find(r => r.id === activeRevisionId);
    dispatch(
      manageProjectDocumentsActions.updateProjectDocumentCurrentRevision(item)
    );
  }, [dispatch, activeRevisionId, revisions]);

  return {
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    setActiveRevisionId,
    activeRevisionId,
    revisions,
    updateRevisionSuccess,
    setCurrentDocument
  };
}
