import React from "react";

import { useTranslation } from "react-i18next";

import { CrudFormMode } from "@app/types/ActionItemType.ts";

import { Stack } from "@fermions";

import Icon from "@atoms/Icon/Icon.tsx";
import Text, { FontSize, FontWeight } from "@atoms/Text/Text.tsx";

import { useWorkflowCanvas } from "../WorkflowCanvas/WorkflowCanvasContext.tsx";
import { ActionEditor } from "./SidebarEditor/ActionEditor.tsx";
import { StepEditor } from "./SidebarEditor/StepEditor.tsx";
import "./WorkflowSidebar.scss";

export interface WorkflowSidebarProps {
  crudFormMode: CrudFormMode;
  onClose: () => void;
}

const WorkflowSidebar = (props: WorkflowSidebarProps) => {
  const { onClose, crudFormMode } = props;
  const { t } = useTranslation();
  const context = useWorkflowCanvas();
  const { selectedNodeId, selectedLaneId } = context;
  if (!selectedLaneId && !selectedNodeId) {
    return <></>;
  }
  const headerText = selectedNodeId
    ? t("admin:ui.requestTypes.workflow.action")
    : t("admin:ui.requestTypes.workflow.step");
  return (
    <Stack
      className="workflow-sidebar__container"
      gap="150"
      padding="200"
      height="100"
    >
      <Icon onClick={onClose} name="close" className="close-sidebar-icon" />
      <Text
        size={FontSize.XL}
        weight={FontWeight.SEMI_BOLD}
        text={headerText}
      />
      <Stack gap="200" height="100">
        {selectedLaneId && selectedNodeId ? (
          <ActionEditor onClose={onClose} crudFormMode={crudFormMode} />
        ) : (
          <StepEditor onClose={onClose} crudFormMode={crudFormMode} />
        )}
      </Stack>
    </Stack>
  );
};

export default WorkflowSidebar;
