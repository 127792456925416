import React from "react";

import { classNames } from "@app/helpers/componentHelpers.js";

import Text, { FontWeight } from "@atoms/Text/Text.tsx";

import { Inline } from "@components/fermions/index.tsx";

import { WCStep } from "./workflowCanvas.ts";

export const StepNode = ({
  node,
  onMouseEnterNode,
  onMouseLeaveNode,
  onClickNode,
  mode
}: {
  node: WCStep;
  onMouseEnterNode?: (e: Event, node: WCStep) => void;
  onMouseLeaveNode?: (e: Event, node: WCStep) => void;
  onClickNode?: (e: Event, node: WCStep) => void;
  mode?: "legend";
}) => {
  const handleMouseEnterNode = (e: Event) => {
    onMouseEnterNode?.(e, node);
  };
  const handleMouseLeaveNode = (e: Event) => {
    onMouseLeaveNode?.(e, node);
  };
  const handleClickNode = (e: Event) => {
    onClickNode?.(e, node);
  };

  const label = node.displayLabel();

  return (
    <Inline
      alignment="center"
      className={classNames([
        "workflow-canvas__node workflow-canvas__node--state",
        node.isHovered ? "workflow-canvas__node--hovered" : "",
        mode === "legend" ? "workflow-canvas__node--legend" : ""
      ])}
      data-nodeid={node.id}
      onMouseEnter={handleMouseEnterNode}
      onMouseLeave={handleMouseLeaveNode}
      onClick={handleClickNode}
      title={label}
    >
      <Text
        truncate={true}
        weight={FontWeight.SEMI_BOLD}
        text={label}
        size={mode === "legend" ? "s" : undefined}
      />
    </Inline>
  );
};
