import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import ions from "@ions";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";
import { SwimlaneItem, Swimlane as SwimlaneType } from "@app/types";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonSize } from "@atoms/Button";

import KanbanDropzone from "@molecules/KanbanDropzone/KanbanDropzone.tsx";

import { WorkflowInfo } from "../KanbanTemplate.tsx";
import "./Swimlane.scss";

interface SwimlaneProps {
  swimlane: SwimlaneType;
  renderItem: (item: SwimlaneItem, isDragging?: boolean) => React.JSX.Element;
  onClickItem?: (item: SwimlaneItem) => void;
  isDragging?: boolean;
  isValidZone?: boolean;
  workflowActions?: WorkflowInfo[];
  draggingItem?: SwimlaneItem;
  setDestinationAction?: (action: string) => void;
  distanceToTop?: number;
}

export const Swimlane = ({
  swimlane,
  renderItem,
  onClickItem,
  isDragging,
  isValidZone,
  workflowActions,
  setDestinationAction,
  distanceToTop
}: React.PropsWithChildren<SwimlaneProps>) => {
  const { t } = useTranslation();

  const [draggingOver, setDraggingOver] = useState(false);

  const DEFALUT_BOX_HEIGHT = 80;
  const DEFAULT_BOX_LIST_HEIGHT = 480;
  const DEFAULT_SMALL_BOX_HEIGHT = 55;

  const swimlaneAction = useMemo(() => {
    if (isDragging || draggingOver || !swimlane.action) {
      return <></>;
    }
    return (
      <Inline
        className={[
          "swimlane-template__action",
          returnStringIfTrue(
            swimlane.action.alwaysVisible,
            "swimlane-template__action--always-visible"
          )
        ]}
      >
        <Button
          key={swimlane.action.labelKey}
          iconName={swimlane.action.iconName ?? ""}
          label={t(swimlane.action.labelKey)}
          variant={
            ions.components.templates.kanban.swimlane.action.button_variant
          }
          onClick={swimlane.action.onClick}
          size={ButtonSize.SMALL}
        />
      </Inline>
    );
  }, [draggingOver, isDragging, swimlane.action, t]);

  const totalItemsLength = useMemo(
    () => swimlane.items.length,
    [swimlane.items]
  );

  const filteredItemsLength = useMemo(
    () =>
      swimlane.items.filter((si: { hidden: boolean }) => si.hidden !== true)
        ?.length,
    [swimlane.items]
  );
  const visibleCountText = useMemo(() => {
    if (totalItemsLength === 0 || filteredItemsLength === 0) {
      return;
    }

    if (totalItemsLength === filteredItemsLength) {
      return totalItemsLength;
    }

    return `${filteredItemsLength}/${totalItemsLength}`;
  }, [totalItemsLength, filteredItemsLength]);

  const filteredWorkflowAction = useMemo(() => {
    if (!workflowActions) {
      return [];
    }
    return workflowActions.filter(
      curr => curr && curr.queryStatus === swimlane.id
    );
  }, [swimlane.id, workflowActions]);

  const renderDraggableItem = useCallback(
    (item, index) => {
      return (
        <Draggable
          key={item.id}
          draggableId={`${item.id}`}
          index={index}
          isDragDisabled={false}
        >
          {(provided, snapshot) => (
            <div
              className={classNames([
                "droppable-item",
                returnStringIfTrue(
                  snapshot.isDragging,
                  "droppable-item--dragging"
                )
              ])}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{ userSelect: "none", ...provided.draggableProps?.style }}
              onClick={() => onClickItem?.(item)}
            >
              {renderItem(item, snapshot.isDragging)}
            </div>
          )}
        </Draggable>
      );
    },
    [onClickItem, renderItem]
  );

  const isShowDropZone = useMemo(() => {
    return isDragging && isValidZone;
  }, [isDragging, isValidZone]);

  useEffect(() => {
    if (!isDragging) {
      setDestinationAction?.("");
    }
  }, [isDragging, setDestinationAction]);

  const { calculatedHeight } = useMemo(() => {
    if (!filteredWorkflowAction || filteredWorkflowAction.length < 6) {
      return { calculatedHeight: DEFALUT_BOX_HEIGHT };
    } else {
      return {
        calculatedHeight: Math.max(
          DEFAULT_SMALL_BOX_HEIGHT,
          DEFAULT_BOX_LIST_HEIGHT / filteredWorkflowAction.length
        )
      };
    }
  }, [filteredWorkflowAction]);

  return (
    <Stack className={["swimlane-template"]}>
      <Inline className="swimlane-template__title" width="100" alignment="left">
        <Inline alignment="left" gap="050">
          <Box className="swimlane-template__title__text">{swimlane.title}</Box>
          {visibleCountText && (
            <Box className="swimlane-template__count" role="count">
              {visibleCountText}
            </Box>
          )}
        </Inline>
        {swimlaneAction}
      </Inline>

      <Box className="swimlane-template__title__shadow-cover" width="100" />
      <Box
        height={isValidZone ? "100" : ""}
        width="100"
        style={{ overflow: "scroll" }}
      >
        <Box
          width="100"
          gap="050"
          style={{ position: "sticky", top: `${distanceToTop}px` }}
        >
          {!filteredWorkflowAction.length && isValidZone && (
            <KanbanDropzone
              primaryLabel={swimlane.id}
              styles={{ height: `80px` }}
              onMouseEnter={() => setDestinationAction?.(swimlane.id)}
              onMouseLeave={() => setDestinationAction?.("")}
            />
          )}
          {filteredWorkflowAction &&
            isValidZone &&
            filteredWorkflowAction.map(curr => (
              <KanbanDropzone
                key={`${curr.key}-${curr.actionKey}`}
                primaryLabel={curr.stepName ?? curr.key}
                secondaryLabel={curr.actionName}
                styles={{
                  height: `${calculatedHeight}px`
                }}
                onMouseEnter={() => setDestinationAction?.(curr.actionKey)}
                onMouseLeave={() => setDestinationAction?.("")}
              />
            ))}
        </Box>
      </Box>
      <div
        className={classNames([
          "swimlane-template__contents",
          returnStringIfTrue(
            isShowDropZone,
            "swimlane-template__contents--dropzone"
          ),
          returnStringIfTrue(
            isShowDropZone,
            "swimlane-template__contents--dragging-out"
          )
        ])}
      >
        <Droppable key={swimlane.id} droppableId={swimlane.id}>
          {(provided, snapshot) => {
            setDraggingOver(snapshot.isDraggingOver);
            return (
              <div
                className={classNames([
                  "droppable-section",
                  returnStringIfTrue(
                    snapshot.isDraggingOver && isValidZone,
                    "droppable-section--dragging-over"
                  )
                ])}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {swimlane.items
                  .filter((si: { hidden: boolean }) => !si.hidden)
                  .map(renderDraggableItem)}
                {/*  occupy one space, make blue box scrollable */}
                <div style={{ height: "500px" }}></div>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
      <Box className="swimlane-template__end" width="100" alignment="left" />
    </Stack>
  );
};
