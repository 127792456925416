import { manageInteractiveReportConstants } from "../constants";

const initialState = {
  uploading: false,
  uploaded: "",
  error: ""
};

export function manageInteractiveReport(state = initialState, action = {}) {
  switch (action.type) {
    case manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_REQUEST:
      return {
        ...state,
        error: "",
        uploaded: "",
        uploading: true
      };
    case manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_SUCCESS:
      return {
        ...state,
        error: "",
        uploading: false,
        uploaded: action.result.document?.name
      };
    case manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error.key ?? action.error.message,
        uploading: false
      };
    case manageInteractiveReportConstants.RESET_INTERACTIVE_REPORT:
      return {
        ...state,
        uploaded: "",
        error: "",
        uploading: false
      };
    default:
      return state;
  }
}
