import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { util } from "@shared/services/interactiveReportService";

import { manageInteractiveReportAction } from "../actions";

export function useInteractiveReport() {
  const dispatch = useDispatch();
  const manageInteractiveReport = useSelector(
    state => state.manageInteractiveReport
  );
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState("");
  const [projectId, setProjectId] = useState("");
  const [error, setError] = useState();

  const reset = useCallback(() => {
    dispatch(manageInteractiveReportAction.reset());
  }, [dispatch]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const uploadInteractiveFile = useCallback(
    ({ file, projectId: id }) => {
      setProjectId(id);
      dispatch(
        manageInteractiveReportAction.uploadInteractiveReport({
          file,
          projectId: id
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setUploading(manageInteractiveReport?.uploading);
  }, [manageInteractiveReport?.uploading]);

  useEffect(() => {
    setUploaded(manageInteractiveReport?.uploaded);
    if (manageInteractiveReport?.uploaded) {
      dispatch(util.invalidateTags(["Menus", "Page", "JsonGet"]));
    }
  }, [manageInteractiveReport?.uploaded, dispatch, projectId]);

  useEffect(() => {
    setError(manageInteractiveReport?.error);
  }, [manageInteractiveReport?.error]);

  return {
    uploadInteractiveFile,
    uploading,
    uploaded,
    error
  };
}
