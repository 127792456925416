import React, { useEffect, useState } from "react";

import { routeConstants } from "@constants/routeConstants.js";

import { systemConstants } from "@shared/constants/systemConstants.ts";
import useAccessControl from "@shared/hooks/useAccessControl.tsx";
import { useFeatures } from "@shared/hooks/useFeatures.jsx";

import { AccessLevel } from "@app/types/Permission.ts";
import { ResourceName } from "@app/types/Resource.ts";

import NotFound from "@pages/NotFound/NotFound.jsx";

const features = systemConstants.features;
export const OTAI_REDIRECT_URL_KEY = "otAIRedirectUrl";

export const OneTeamAiRedirect = () => {
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { filterByPermissionFn } = useAccessControl();
  const [accessDenied, setAccessDenied] = useState<boolean | null>(null);
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (isFeaturesLoading || redirected) {
      return;
    }

    const otAiRedirectUrl = localStorage.getItem(OTAI_REDIRECT_URL_KEY);
    const hasOneTeamAiAccess =
      !isFeaturesLoading &&
      isEnabled(features.oneteamAI) &&
      filterByPermissionFn({
        permission: {
          resource: ResourceName.ONETEAM_AI,
          accessLevel: AccessLevel.READ,
          type: "HOST"
        }
      });

    setAccessDenied(!hasOneTeamAiAccess);
    if (!hasOneTeamAiAccess) {
      return;
    }

    localStorage.removeItem(OTAI_REDIRECT_URL_KEY);
    if (otAiRedirectUrl) {
      window.location.href = otAiRedirectUrl;
      setRedirected(true);
      return;
    }

    window.location.pathname = routeConstants.oneTeamAI.home;
    setRedirected(true);
  }, [filterByPermissionFn, isEnabled, isFeaturesLoading, redirected]);

  if (accessDenied === true) {
    localStorage.removeItem(OTAI_REDIRECT_URL_KEY);
    return <NotFound />;
  }

  return <></>;
};

export default OneTeamAiRedirect;
