import React from "react";

type Spacing =
  | "none"
  | "000"
  | "025"
  | "050"
  | "075"
  | "100"
  | "150"
  | "200"
  | "250"
  | "300"
  | "350"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000";

type Alignment =
  | "top-left"
  | "left"
  | "bottom-left"
  | "top-center"
  | "center"
  | "bottom-center"
  | "top-right"
  | "right"
  | "bottom-right";

type Size =
  | "auto"
  | "fill"
  | "stretch" // stretch will fill if in flex box (align-self: stretch)
  | "100" // 100%
  | "fit" // fit-content
  | "minContent" // min-content
  | "maxContent"; // max-content

type Shadow = "none" | "inner" | "outer";

type Border = { size: 0 | 1 | 2 | 3; color?: "light" | "dark" };

export interface FermionProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  // children: ReactNode;
  className?: string | string[];
  // Most used fermion props
  alignment?: Alignment;
  gap?: Spacing;
  padding?: Spacing;
  margin?: Spacing;
  width?: Size;
  height?: Size;
  contentsWidth?: Size;
  contentsHeight?: Size;
  shadow?: Shadow;
  border?: Border;
  wrap?: boolean;
}

const giveClassValueIfProvided = (key: string, value: unknown) =>
  value ? `${key}--${value}` : "";

export const getFermionClasses = ({
  alignment,
  gap,
  padding,
  margin,
  width,
  height,
  contentsWidth,
  contentsHeight,
  shadow,
  border,
  wrap
}: FermionProps) => {
  return [
    giveClassValueIfProvided("alignment", alignment),
    giveClassValueIfProvided("gap", gap),
    giveClassValueIfProvided("padding", padding),
    giveClassValueIfProvided("margin", margin),
    giveClassValueIfProvided("width", width),
    giveClassValueIfProvided("height", height),
    giveClassValueIfProvided("contentsWidth", contentsWidth),
    giveClassValueIfProvided("contentsHeight", contentsHeight),
    giveClassValueIfProvided("shadow", shadow),
    giveClassValueIfProvided("wrap", wrap),
    giveClassValueIfProvided(
      "border",
      border ? `${border.size}-${border.color}` : ""
    )
  ];
};

export function getClassArray(
  className: string | string[] | undefined
): string[] {
  if (className instanceof Array) {
    return className;
  }
  return [className ?? ""];
}

export function splitProps(props: FermionProps) {
  const {
    className,
    alignment,
    gap,
    padding,
    margin,
    width,
    height,
    contentsWidth,
    contentsHeight,
    shadow,
    border,
    wrap,
    ...divProps
  } = props;

  return {
    classProps: {
      className,
      alignment,
      gap,
      padding,
      margin,
      width,
      height,
      contentsWidth,
      contentsHeight,
      shadow,
      border,
      wrap
    },
    divProps
  };
}
