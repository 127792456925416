import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useHostLanguage() {
  const { i18n } = useTranslation();
  const authentication = useSelector(state => state.authentication);
  const [hostLanguage, setHostLanguage] = useState(null);
  useEffect(() => {
    if (authentication.host && i18n) {
      const hostLang = authentication.host.host?.properties?.i18n?.default;
      if (hostLang && hostLang !== i18n.language) {
        i18n?.changeLanguage(hostLang);
        setHostLanguage(hostLang);
      }
    }
  }, [authentication.host, i18n, hostLanguage]);
  return { hostLanguage };
}
