import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions/manageProjectDocumentsActions";

export function useDocumentTagging(document) {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );

  useEffect(() => {
    if (manageProjectDocuments.tags) {
      setTags(manageProjectDocuments.tags);
    }
  }, [manageProjectDocuments.tags]);

  const updateDocumentTags = useCallback(
    tagIdsToSet => {
      const updatedTagIds = new Set(tagIdsToSet);
      const documentTagIds = new Set(document.tags.map(t => t.id));
      const tagsRemoved = [...documentTagIds].filter(
        x => !updatedTagIds.has(x)
      );
      const tagsAdded = [...updatedTagIds].filter(x => !documentTagIds.has(x));

      dispatch(
        manageProjectDocumentsActions.updateProjectDocumentProperties({
          ...document,
          tagActions: {
            add: tagsAdded,
            remove: tagsRemoved
          }
        })
      );
    },
    [dispatch, document]
  );

  return {
    tags,
    updateDocumentTags
  };
}
