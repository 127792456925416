import { isEqual, sortBy } from "lodash";

import { queryConstants } from "@constants/queryConstants";

import {
  smartFormProgressStates,
  smartFormResponseType,
  smartFormUpdatedBy
} from "./smartFormConstants";

/**
 *
 * Returns an array of valid answers for the question ID
 * @param {number} questionId - question ID
 * @param {Object} answers - answers object
 * @param {number[]} questionEntities - question entities object
 * @return {Object[]} - array of answers
 */
export const getAnswerValuesForQuestion = (
  questionId,
  answers,
  questionEntities
) => {
  const answersForQuestion = answers?.[questionId];
  if (!answersForQuestion || answersForQuestion.length === 0) {
    return [];
  }

  const isAnswerForValidEntity = answer => {
    if (!questionEntities?.length || !answer?.entities?.length) {
      return true;
    }
    return answer?.entities?.some(answerEntity =>
      questionEntities.includes(answerEntity)
    );
  };

  const answerWithoutInvalidEntities = answer => {
    const updatedAnswer = { ...answer };
    if (answer?.entities?.length && questionEntities?.length) {
      const validEntities = answer.entities.filter(answerEntity =>
        questionEntities.includes(answerEntity)
      );
      updatedAnswer.entities = validEntities;
    }
    return updatedAnswer;
  };

  const invalidAnswers = ["", null, undefined];
  return answersForQuestion
    ?.filter(answer => !invalidAnswers.includes(answer.value))
    .filter(isAnswerForValidEntity)
    .map(answerWithoutInvalidEntities);
};

/**
 *
 * If a document is uploaded and marked complete in answer to the question type webSheet, treat it as complete entities
 * @param {number} questionId
 * @param {Object} answer
 * @param {boolean} isWebsheetOrDocument
 * @return {boolean}
 */
const isAnswerForEntityComplete = (
  questionId,
  answer,
  isWebsheetOrDocument
) => {
  return (
    (isWebsheetOrDocument &&
      answer.complete &&
      answer?.value.properties.questionId === questionId) ||
    (!isWebsheetOrDocument && !answer?.responseType)
  );
};

/**
 *
 * Get answer progress indicator
 * @param {Object[]} questionAnswers
 * @param {number} numberOfAssignedEntities
 * @param {number} numberOfAnsweredEntities
 * @param {number} questionRelevantEntitiesTotal
 * @param {boolean} isWebsheetOrDocument
 * @param {boolean} isAnsweredGlobally
 * @return {*}
 */
const answerProgressIndicator = (
  questionAnswers,
  numberOfAssignedEntities,
  numberOfAnsweredEntities,
  questionRelevantEntitiesTotal,
  isWebsheetOrDocument,
  isAnsweredGlobally
) => {
  const isError = questionAnswers.some(
    answer => answer.errorCheckStatus === queryConstants.queryErrorStatus.error
  );

  if (isError) {
    return smartFormProgressStates.ERROR;
  }

  const isAssignedEntitiesComplete =
    numberOfAssignedEntities === questionRelevantEntitiesTotal;
  const isAnsweredEntitiesComplete =
    numberOfAnsweredEntities === questionRelevantEntitiesTotal &&
    isWebsheetOrDocument;

  const isQuestionComplete =
    isWebsheetOrDocument && !isAnsweredEntitiesComplete
      ? [false]
      : [
          isAnsweredGlobally,
          isAssignedEntitiesComplete,
          isAnsweredEntitiesComplete
        ];

  return isQuestionComplete.some(Boolean)
    ? smartFormProgressStates.COMPLETED
    : smartFormProgressStates.IN_PROGRESS;
};

/**
 * @typedef {Object} QuestionAnswersAndStatus
 * @property {string} progressIndicator - progress indicator for the question
 * @property {boolean} isAnsweredGlobally - whether the question is answered globally
 * @property {number[]} answeredEntities - array of answered entityIds for the question
 * @property {number[]} assignedEntities - array of assigned entityIds for the question
 * @property {Object[]} answers - array of answers for the question
 */

/**
 *
 * Gets a question's answers and current answer status
 * @param {Object} question
 * @param {Object} answers
 * @param {Object} relevantEntitiesById
 * @return {QuestionAnswersAndStatus}
 */
const getQuestionAnswersAndStatus = (
  question,
  answers,
  relevantEntitiesById,
  relevantEntitiesForQuestion
) => {
  const questionAnswers = getAnswerValuesForQuestion(
    question.questionId,
    answers,
    relevantEntitiesForQuestion
  );

  if (!questionAnswers.length) {
    return {
      progressIndicator: smartFormProgressStates.NOT_STARTED,
      isAnsweredGlobally: true,
      answeredEntities: [],
      assignedEntities: [],
      answers: questionAnswers
    };
  }

  const isSingleEntity = Object.keys(relevantEntitiesById).length === 1;
  const isWebsheetOrDocument = [
    smartFormResponseType.WEBSHEET,
    smartFormResponseType.DOCUMENT
  ].includes(question.responseType);

  const relevantEntitiesTotal = Object.values(relevantEntitiesById)?.length;
  const questionRelevantEntitiesTotal =
    relevantEntitiesForQuestion?.length || relevantEntitiesTotal;

  const assignedEntityIds = new Set();
  const answeredEntityIds = new Set();
  let isSameAnswer = true;
  if (isSingleEntity && isWebsheetOrDocument) {
    questionAnswers
      .filter(answer => answer.complete)
      .forEach(() => {
        answeredEntityIds.add(Object.keys(relevantEntitiesById)[0]);
      });
  } else {
    questionAnswers
      .filter(answer => answer?.entities?.length > 0)
      .forEach(answer => {
        isSameAnswer &&= answer?.value === questionAnswers[0]?.value;
        answer?.entities?.forEach(entityId => {
          if (relevantEntitiesById[entityId]) {
            assignedEntityIds.add(entityId);

            if (
              isAnswerForEntityComplete(
                question.questionId,
                answer,
                isWebsheetOrDocument
              )
            ) {
              answeredEntityIds.add(entityId);
            }
          }
        });
      });
  }

  const answeredEntities = [...answeredEntityIds];
  const assignedEntities = [...assignedEntityIds];
  const relevantEntities =
    relevantEntitiesForQuestion ?? Object.keys(relevantEntitiesById);
  const isAnsweredGlobally =
    isEqual(
      sortBy(answeredEntities?.map(e => e?.toString?.())),
      sortBy(relevantEntities?.map(e => e?.toString?.()))
    ) && isSameAnswer;

  const progressIndicator = answerProgressIndicator(
    questionAnswers,
    assignedEntities.length,
    answeredEntities.length,
    questionRelevantEntitiesTotal,
    isWebsheetOrDocument,
    isAnsweredGlobally
  );
  return {
    answers: questionAnswers,
    progressIndicator,
    isAnsweredGlobally,
    assignedEntities,
    answeredEntities
  };
};

/**
 *
 * Gets extra question information that will be used to display it correctly
 * @param {Object} options
 * @param {Object} options.question
 * @param {Object} options.answers
 * @param {Object} options.relevantEntitiesById
 * @param {number} options.highlightedQuestionId
 * @param {Object} options.strategy
 * @return {Object}
 */
const getExtraQuestionAnswerInfo = ({
  question,
  answers,
  relevantEntitiesById,
  highlightedQuestionId = null,
  strategy
}) => {
  const relevantEntitiesForQuestion = strategy.getQuestionRelevantEntities(
    question,
    answers
  );

  const questionAnswersAndStatus = getQuestionAnswersAndStatus(
    question,
    answers,
    relevantEntitiesById,
    relevantEntitiesForQuestion.relevantEntities
  );

  const questionHighlighted =
    question.questionId === highlightedQuestionId ? { highlighted: true } : {};

  const mandatoryStatus = strategy.isQuestionMandatory(question)
    ? { isMandatory: true }
    : {};

  return {
    ...questionAnswersAndStatus,
    ...relevantEntitiesForQuestion,
    ...questionHighlighted,
    ...mandatoryStatus
  };
};

/**
 * @typedef {Object} SmartFormQuestionData
 * @property {number} questionId
 * @property {string} questionText
 * @property {string} responseType
 * @property {string} [categoryName]
 * @property {number} [categoryId]
 * @property {number} [subCategoryId]
 * @property {string} [subCategoryName]
 * @property {number} [parentQuestionId]
 * @property {string} [dependentAnswer]
 * @property {Object[]} [answers]
 * @property {string} [progressIndicator]
 * @property {boolean} [isAnsweredGlobally]
 * @property {number[]} [assignedEntities]
 * @property {number[]} [answeredEntities]
 * @property {Object[]} [relevantEntities]
 * @property {boolean} [questionHighlighted]
 */

/**
 * @typedef {Object} SmartFormCategoryData
 * @property {Object<string, SmartFormQuestionData[]>} subcategories - map of subcategory name to questions
 * @property {number} completeCount
 * @property {number} totalCount
 * @property {number} order
 * @property {boolean} [questionHighlighted]
 * @property {boolean} [complete]
 */

/**
 * Processes the smart form data to put the visible questions and corresponding answers
 *  into relevant categories, subcategories
 * @param {Object[]} questions - questions array
 * @param {Object} answers - answers object
 * @param {Object} options - options object
 * @param {string} options.defaultSubCategoryName - default subcategory name
 * @param {Object} options.relevantEntitiesById - relevant entities object
 * @param {number} [options.highlightedQuestionId] - questionId of the question to be highlighted
 * @param {Object} options.strategy - strategy object that implements the following methods:
 * - isQuestionVisible(question, answers) - returns true if the question is visible
 * - getQuestionRelevantEntities(question, answers) - returns relevantEntities
 * @return {Object<*, SmartFormCategoryData>}
 */
export const getRelevantSmartFormData = (
  questions,
  answers,
  {
    defaultSubCategoryName,
    relevantEntitiesById,
    highlightedQuestionId = null,
    strategy
  }
) => {
  const data = {};
  let order = 0;
  questions
    ?.filter(question => strategy.isQuestionVisible(question, answers))
    ?.forEach(question => {
      const categoryName = question.categoryName;
      const subCategoryName =
        question.subCategoryName || defaultSubCategoryName;
      if (!data[categoryName]) {
        data[categoryName] = {
          completeCount: 0,
          totalCount: 0,
          subcategories: {},
          order
        };
        order++;
      }
      if (!data[categoryName].subcategories[subCategoryName]) {
        data[categoryName].subcategories[subCategoryName] = [];
      }

      const questionWithAnswer = {
        ...question,
        ...getExtraQuestionAnswerInfo({
          question,
          answers,
          relevantEntitiesById,
          highlightedQuestionId,
          strategy
        })
      };
      data[categoryName].subcategories[subCategoryName].push(
        questionWithAnswer
      );

      data[categoryName].totalCount++;
      if (
        questionWithAnswer.progressIndicator ===
        smartFormProgressStates.COMPLETED
      ) {
        data[categoryName].completeCount++;
      }

      if (highlightedQuestionId === questionWithAnswer.questionId) {
        data[categoryName].questionHighlighted = true;
      }
    });
  return data;
};

/**
 * @typedef {Object<any, Object<any, []>>} AnswersByEntity
 */

/**
 *
 * @param {*} answers
 * @param {string[]} entities List of entityIds
 * @returns {AnswersByEntity}
 */
export const getAnswersByEntity = (answers, entities) => {
  const result = entities.reduce(
    (acc, entityId) => ({ ...acc, [entityId]: {} }),
    {}
  );
  function addAnswerToEntity(entityId, questionId, answer) {
    if (!result[entityId]) {
      result[entityId] = {};
    }
    if (!result[entityId][questionId]) {
      result[entityId][questionId] = [];
    }
    result[entityId][questionId].push(answer);
  }

  Object.entries(answers).forEach(([questionId, answers]) => {
    answers.forEach(answer => {
      if (!answer.entities?.length || entities.length === 1) {
        // answer counts for everything in entities (either it was answered globally or there is only one entity)
        entities.forEach(entityId => {
          addAnswerToEntity(entityId, questionId, answer.value);
        });
      } else {
        // answer counts for only the entities in answer.entities
        answer.entities.forEach(entityId => {
          addAnswerToEntity(entityId, questionId, answer.value);
        });
      }
    });
  }, {});
  return result;
};

/**
 * check if all questions are answered and at least one is provided by OT user
 * @param {object} params
 * @param {Array<object>} params.questions
 * @param {object} params.answers
 * @param {object} params.entities
 * @returns {boolean}
 */
const checkAllQuestionsCompleteAndSomeByOTUser = ({
  questions,
  answers,
  entities
}) => {
  const entityIds = Object.keys(entities ?? {});
  const someUpdatedByOTUser = Object.values(answers ?? {})
    .flat()
    .some(
      answer => !answer.updatedBy || answer.updatedBy === smartFormUpdatedBy.OT
    );
  if (!someUpdatedByOTUser) {
    return false;
  }

  const allCompleted = questions.every(question => {
    const isComplete = checkAnswersComplete({
      totalEntities: entityIds,
      answers: answers[question.questionId],
      responseType: question.responseType
    });
    return isComplete;
  });
  return allCompleted;
};

/**
 * check if answers of a question are completed
 * @param {object} params
 * @param {Array<string>} params.totalEntities
 * @param {Array<object>} params.answers
 * @param {string} params.responseType
 * @returns {boolean}
 */
const checkAnswersComplete = ({ totalEntities, answers, responseType }) => {
  // answers deleted or not provided
  if (!answers?.length) {
    return false;
  }
  // no multiple entities assigned to the query
  if ((totalEntities?.length ?? 0) < 2) {
    return true;
  }

  // a document or websheet answer
  if (
    responseType === smartFormResponseType.WEBSHEET ||
    responseType === smartFormResponseType.DOCUMENT
  ) {
    const completedEntities = answers
      .filter(answer => answer.complete)
      .flatMap(answer => answer.entities);
    return new Set(completedEntities).size === new Set(totalEntities).size;
  }
  return (
    answers.flatMap(answer => answer.entities).length === totalEntities.length
  );
};

export const smartFormStructure = {
  getAnswersByEntity,
  getRelevantSmartFormData,
  getAnswerValuesForQuestion,
  checkAllQuestionsCompleteAndSomeByOTUser,
  checkAnswersComplete,
  forTest: {
    getQuestionAnswersAndStatus,
    getExtraQuestionAnswerInfo
  }
};
