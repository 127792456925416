import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { useRequestPageNavigator } from "@shared/hooks";

import { QUERY_TYPE_ICON } from "@app/helpers";

import { Icon, IconColor } from "@atoms/Icon";

import "./ViewActionItemButton.scss";

export default function ViewActionItemButton(props) {
  const { t } = useTranslation();
  const { projectId, actionItemType, queryId, queryType } = props;
  const { navigateToRequestPage } = useRequestPageNavigator();
  const iconType = QUERY_TYPE_ICON[actionItemType];

  const clickHandler = useCallback(() => {
    navigateToRequestPage(queryId, projectId, actionItemType, {
      opensNewWindow: true
    });
  }, [actionItemType, navigateToRequestPage, projectId, queryId]);

  return (
    <div className="ot-view-action-item-btn">
      {iconType && (
        <Icon
          className="ot-view-action-item-btn__icon"
          onClick={clickHandler}
          name={iconType}
          color={IconColor.MUTED}
          hoverElement={
            <>
              {t("requests:requests.configured.name", {
                context: queryType
              })}
            </>
          }
        />
      )}
    </div>
  );
}
