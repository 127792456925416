import React, { useCallback, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import dateFormatter from "@shared/helpers/dateHelper";
import { hasProjectAccessRelativeToMembership } from "@shared/helpers/roleHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import ions from "@ions";

import { routeConstants } from "@app/constants/routeConstants";
import { classNames } from "@app/helpers/componentHelpers";
import { AccessLevel } from "@app/types";

import { Icon, IconSize } from "@atoms/Icon";
import { Pill, PillSize, PillStatus } from "@atoms/Pill";
import { FontSize, FontWeight, Text } from "@atoms/Text";

import { ActionDropdown } from "@molecules/ActionDropdown";

import MilestoneProgressBar from "@components/molecules/MilestoneProgressBar";

import "./ProjectPanel.scss";

const allMenuItems = (projectStatus, user, isMember) => {
  const hasUpdateAccess = hasProjectAccessRelativeToMembership(
    user,
    isMember,
    AccessLevel.UPDATE
  );
  const hasCreateAccess = hasProjectAccessRelativeToMembership(
    user,
    isMember,
    AccessLevel.CREATE
  );
  const hasDeleteAccess = hasProjectAccessRelativeToMembership(
    user,
    isMember,
    AccessLevel.DELETE
  );
  const isDraftProject = projectStatus === systemConstants.project.status.draft;

  const projectUpdate = hasUpdateAccess
    ? [
        {
          name: "common:ui.project.title_update",
          action: "UPDATE"
        }
      ]
    : [];

  const projectCopy =
    !isDraftProject && hasCreateAccess
      ? [
          {
            name: "common:ui.project.title_copy",
            action: "COPY"
          }
        ]
      : [];

  const projectPublish =
    isDraftProject && hasUpdateAccess
      ? [
          {
            name: "common:ui.projects.draft.publishButton",
            action: "PUBLISH"
          }
        ]
      : [];

  const projectDelete =
    isDraftProject && hasDeleteAccess
      ? [
          {
            name: "common:ui.project.draft.deleteButton",
            action: "DELETE"
          }
        ]
      : [];

  return [
    ...projectUpdate,
    ...projectCopy,
    ...projectPublish,
    ...projectDelete
  ];
};

const ProjectPanel = props => {
  const {
    id,
    projectName,
    projectStatus,
    clientName,
    engagementType,
    year,
    yearEnabled,
    colorStatus,
    currentMilestone,
    lastMilestone,
    totalMilestones,
    completedMilestone,
    user,
    isMember,
    handlePublishProject,
    handleDeleteProject
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectPanelTitleRef = useRef();
  const projectPanelProjectNameRef = useRef();

  const isDraft = projectStatus === systemConstants.project.status.draft;
  const projectPanelIons = isDraft
    ? ions.components?.molecules?.project_panel?.draft
    : ions.components?.molecules?.project_panel;
  const pillVariant = projectPanelIons?.pill_variant;
  const pillFillStyle = projectPanelIons?.pill_fill_style;

  function isElementOverflowing(ref) {
    return ref.current?.scrollWidth > ref.current?.clientWidth;
  }
  const { locale, options } = useLocaleDate();

  const onPanelClickHandler = useCallback(() => {
    navigate(`${routeConstants.projects}/${id}`);
  }, [id, navigate]);

  const onMenuItemClick = useCallback(
    item => {
      switch (item.action) {
        case "UPDATE":
          navigate(`${routeConstants.projects}/${id}/edit`);
          break;
        case "COPY":
          if (isDraft) {
            break;
          }
          navigate(`${routeConstants.projects}/${id}/copy`);
          break;
        case "PUBLISH":
          handlePublishProject(id);
          break;
        case "DELETE":
          handleDeleteProject(id);
          break;
      }
    },
    [navigate, id, isDraft, handlePublishProject, handleDeleteProject]
  );

  const title = user.isHostUser ? clientName : projectName;
  const isMilestonesCompleted = completedMilestone === totalMilestones;

  const nextMilestoneDateText = isMilestonesCompleted
    ? t("common:ui.dashboard.panel.milestones.complete")
    : t("common:ui.dashboard.panel.milestones.nextMilestoneDate", {
        nextMilestoneDate: dateFormatter(
          currentMilestone.date,
          locale,
          options.shortFormat
        )
      });

  return (
    <div
      className={classNames([
        "ot-projects-dashboard-project-panel",
        `ot-projects-dashboard-project-panel--${projectStatus.toLowerCase()}`
      ])}
      onClick={onPanelClickHandler}
    >
      <div className="ot-projects-dashboard-project-panel__top">
        <div
          className="ot-projects-dashboard-project-panel__top-title"
          ref={projectPanelTitleRef}
          title={isElementOverflowing(projectPanelTitleRef) ? title : ""}
        >
          <Text text={title} size={FontSize.XL} weight={FontWeight.SEMI_BOLD} />
          {user.isHostUser && (
            <ActionDropdown
              menuItems={allMenuItems(projectStatus, user, isMember)}
              onMenuItemClick={onMenuItemClick}
              disableFixedPositionCalculation
              positionRelative
            />
          )}
        </div>
        <div
          className="ot-projects-dashboard-project-panel__top-project-name"
          ref={projectPanelProjectNameRef}
          title={
            isElementOverflowing(projectPanelProjectNameRef) ? projectName : ""
          }
        >
          {user.isHostUser && (
            <Text
              text={projectName}
              weight={FontWeight.REGULAR}
              size={FontSize.L}
            />
          )}
        </div>
        <div className="ot-projects-dashboard-project-panel__pill">
          <span className="ot-projects-dashboard-project-panel__pill--engagement-type">
            <Pill
              variant={pillVariant}
              fillStyle={pillFillStyle}
              label={engagementType}
              size={PillSize.SMALL}
            />
          </span>
          {yearEnabled && year && (
            <Pill
              variant={pillVariant}
              fillStyle={pillFillStyle}
              label={year}
              size={PillSize.SMALL}
            />
          )}
        </div>
      </div>
      {isDraft && (
        <div className="ot-projects-dashboard-project-panel__draft-pill">
          <Pill
            variant={ions.components?.atoms?.draft_pill?.pill_variant}
            fillStyle={ions.components?.atoms?.draft_pill?.pill_fill_style}
            label={t("common:ui.projects.draft.label")}
            status={PillStatus.DRAFT}
            size={PillSize.SMALL}
          />
        </div>
      )}
      {!isDraft && (
        <>
          <div className="ot-projects-dashboard-project-panel__progress-bar">
            <MilestoneProgressBar
              colorStatus={colorStatus}
              totalMilestones={totalMilestones}
              completedMilestone={completedMilestone}
              milestoneName={
                isMilestonesCompleted
                  ? lastMilestone.name
                  : currentMilestone.name
              }
            />
          </div>
          <div className="ot-projects-dashboard-project-panel__bottom">
            <Icon name="calendar_today" size={IconSize.XS} />
            <Text
              weight={FontWeight.SEMI_BOLD}
              text={nextMilestoneDateText}
              size={FontSize.S}
              maxLines={1}
            />
          </div>
        </>
      )}
    </div>
  );
};

ProjectPanel.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  engagementType: PropTypes.string.isRequired,
  projectYear: PropTypes.string,
  colorStatus: PropTypes.oneOf(["behind", "inprogress", "complete"]).isRequired,
  totalMilestones: PropTypes.number.isRequired,
  completedMilestone: PropTypes.number.isRequired,
  currentMilestone: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }),
  lastMilestone: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string
  }),
  user: PropTypes.shape({
    isHostUser: PropTypes.bool.isRequired
  }).isRequired,
  handlePublishProject: PropTypes.func,
  handleDeleteProject: PropTypes.func
};

export default ProjectPanel;
