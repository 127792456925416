import { AppInsights } from "../../AppInsights";
import { systemConstants } from "../constants";
import { utilities } from "../helpers";

export async function handleErrorResponse(response) {
  const { appInsights } = await AppInsights({});
  const getErrorMessage = () => {
    return (
      systemConstants.support.message ||
      "An unexpected error occurred. Please try again, and if the problem persists contact support"
    );
  };

  let message = "";
  if (
    response &&
    response.message &&
    response.message.includes("fetch") &&
    !navigator.onLine
  ) {
    message = "Network error, please try again later.";
  } else if (
    (response && response.message && response.message.includes("JSON")) ||
    (response && response.message && response.message.includes("fetch")) ||
    (response && response.message && response.message.includes("null")) ||
    (response &&
      response.message &&
      response.message.includes("connect ECONNREFUSED"))
  ) {
    message = getErrorMessage();
  } else if (
    response &&
    response.message &&
    response.message === "Invalid request"
  ) {
    message = systemConstants.support.email
      ? `Invalid request. Please try again, and if the problem persists contact support on ${systemConstants.support.email}`
      : "Invalid request. Please try again, and if the problem persists contact support";
  } else if (
    response &&
    response.message &&
    (response.status === 400 || response.status === 403)
  ) {
    const errorObj = {
      ...response,
      message: response.message
    };
    appInsights?.trackTrace(errorObj);
    return Promise.reject(errorObj);
  } else {
    message = getErrorMessage();
  }
  const errorObj = {
    ...response,
    message: utilities.getFormattedErrorMessage(message)
  };
  appInsights?.trackTrace(errorObj);
  return Promise.reject(errorObj);
}
