import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as yup from "yup";

import { useGetProjectMembers } from "@shared/hooks";
import { useUpdateActionItemFieldMutation } from "@shared/services/actionItemService";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";

import "./ReassignQuery.scss";

const ReassignQuery = props => {
  const { onQueryReassigned, query, t } = props;
  const { members } = useGetProjectMembers(props.project);
  const [
    updateQueryField,
    {
      isSuccess: updateQuerySuccess,
      error: updateQueryError,
      data: updatedQuery
    }
  ] = useUpdateActionItemFieldMutation();
  const [updatedQueryUserId, setUpdatedQueryUserId] = useState(null);

  useEffect(() => {
    if (updateQuerySuccess) {
      setUpdatedQueryUserId(updatedQuery.assignedTo.id);
      onQueryReassigned(updatedQuery.assignedTo);
    }
  }, [updatedQuery, updateQuerySuccess, updatedQueryUserId, onQueryReassigned]);

  const handleSubmit = data => {
    const reassignedUser = data.assignedTo.value;
    updateQueryField({
      queryId: query.id,
      field: "assignedTo",
      value: reassignedUser
    });
  };

  const membersForDropdown = React.useMemo(() => {
    const clientUsers =
      members.clientUsers.map(user => ({
        name: user.name,
        value: user
      })) || [];
    const hostUsers =
      members.hostUsers.map(user => ({
        name: user.name,
        value: user
      })) || [];
    return [...clientUsers, ...hostUsers];
  }, [members.clientUsers, members.hostUsers]);

  const defaultAssignedTo = React.useMemo(
    () => query.assignedTo,
    [query.assignedTo]
  );

  return (
    <>
      <ModalForm
        boxClassName="ot-reassign-query"
        title={t("requests:requests.ui.reassignRequest.modalTitle")}
        handleCancel={props.onCancel}
        handleSubmit={handleSubmit}
        submitLabel={t(
          "requests:requests.ui.reassignRequest.actions.submitLabel"
        )}
        cancelLabel={t(
          "requests:requests.ui.reassignRequest.actions.cancelLabel"
        )}
        yupSchema={yup
          .object({
            assignedTo: yup
              .object()
              .required(
                t(
                  "requests:requests.ui.reassignRequest.validation.assignedToRequired.errorMessage"
                )
              )
          })
          .required()}
        defaultValues={{
          assignedTo: {
            name: defaultAssignedTo?.name,
            value: defaultAssignedTo
          }
        }}
        errorMessage={updateQueryError?.message || null}
      >
        <Form.Dropdown
          label={t(
            "requests:requests.ui.reassignRequest.fields.assignTo.label"
          )}
          name="assignedTo"
          items={membersForDropdown}
        />
      </ModalForm>
    </>
  );
};

ReassignQuery.defaultProps = {};

ReassignQuery.propTypes = {
  query: PropTypes.any.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onQueryReassigned: PropTypes.func.isRequired
};

export default withTranslation()(ReassignQuery);
