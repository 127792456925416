import React, { useCallback, useEffect, useState } from "react";

import * as CSV from "@vanillaes/csv";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box, Inline, Stack } from "@fermions";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import UploadFile from "@components/molecules/UploadFile";

import "./UploadProjectEntitiesFormField.scss";

/**
 * Serialize an array of entities to a string
 * @param {import("@app/helpers/entity").Entity[]} entities
 * @returns {string} the string representation
 */
const entitiesToString = entities =>
  entities
    ? CSV.stringify(
        entities?.map?.(({ name, externalId }) => [name, externalId])
      )
    : "";

function UploadProjectEntitiesFormField(props) {
  const { t } = useTranslation();
  const { name, required, defaultValue } = props;
  const { setValue, watch } = useFormContext();
  const [error, setErrorState] = useState("");
  const watchEntities = watch(name);

  useEffect(() => {
    if (watchEntities) {
      setErrorState("");
    }
  }, [watchEntities]);

  const handleDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(acceptedFile => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result;

          const removeHeader = () => {
            const formatted = CSV.parse(binaryStr).splice(1);
            return CSV.stringify(formatted);
          };
          setValue(name, removeHeader(), {
            shouldDirty: true,
            shouldValidate: true
          });
        };
        reader.readAsText(acceptedFile);
      });
    },
    [name, setValue]
  );

  const handleDropReject = () => {
    setErrorState(t("common:ui.projects.entities.fileUpload.errorMessage"));
  };

  return (
    <Inline gap="200" wrap>
      <div className="ot-upload-project-entities-form-field__textarea">
        <Form.TextArea
          name={name}
          label={t("common:ui.projects.entities.and.fileUpload.label")}
          defaultValue={
            defaultValue && typeof defaultValue === "string"
              ? defaultValue
              : entitiesToString(defaultValue)
          }
          required={required}
          placeholder={t("common:ui.projects.entities.placeholder")}
        />
      </div>
      <Stack
        style={{ gap: "5px" }}
        className="ot-upload-project-entities-form-field__upload-zone"
      >
        {!props.fileUploadLabel && <Box style={{ minHeight: "20px" }} />}
        <UploadFile
          handleDrop={handleDrop}
          supportedDocumentMimes={["csv"]}
          disabled={false}
          dropMessage={t("common:ui.projects.entities.fileUpload.dropMessage")}
          linkName={t("common:ui.forms.fileUpload.browseLabel")}
          label={
            props.fileUploadLabel &&
            t("common:ui.projects.entities.fileUpload.label")
          }
          hideUpload={false}
          handleRejection={handleDropReject}
          maxNumberOfFiles={1}
        />
        {error && <InlineAlert type="error" message={error} />}
      </Stack>
    </Inline>
  );
}
UploadProjectEntitiesFormField.defaultProps = {
  name: "entities",
  required: false,
  defaultValue: ""
};

UploadProjectEntitiesFormField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fileUploadLabel: PropTypes.string
};

export default UploadProjectEntitiesFormField;
