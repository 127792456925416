import React from "react";

import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";

import "./ProgressSpinner.scss";

function ProgressSpinner(props) {
  return (
    <div className="ot-progress-spinner">
      <div className="ot-progress-spinner__loader">
        <Oval color="#333" height={28} width={28} />
      </div>
      {props.showPercentage && (
        <div className="ot-progress-spinner__percentage">{`${
          props.percentage || 0
        }%`}</div>
      )}
    </div>
  );
}

ProgressSpinner.defaultProps = {
  showPercentage: true
};

ProgressSpinner.propTypes = {
  percentage: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool
};

export default ProgressSpinner;
