/**
 * @param { Array.<string> | string} availableTo The permission groups an action is available for.
 * @param { object } user The current user.
 * @param { boolean } [isRequestor] Determines if the current user is on the requestor team.
 * @returns { boolean } A flag that determines if the current user has permission for an action.
 */
export const menuItemAccess = ({
  availableTo,
  isRequestor,
  from,
  queryStatus,
  user = {}
}) => {
  const { isHostUser = false, isClientUser = false } = user;
  if (typeof availableTo === "string") {
    return menuItemAccess({
      // @ts-expect-error: Temporarily ignoring until the library types are updated
      availableTo: [availableTo],
      isRequestor,
      from,
      queryStatus,
      user: { isHostUser, isClientUser }
    });
  }
  if (typeof from === "string") {
    return menuItemAccess({
      // @ts-expect-error: Temporarily ignoring until the library types are updated
      availableTo,
      user,
      isRequestor,
      queryStatus,
      from: [from]
    });
  }

  if (
    !Array.isArray(availableTo) ||
    (from !== undefined && !Array.isArray(from))
  ) {
    return false;
  }

  if (Array.isArray(from) && !from.includes(queryStatus)) {
    return false;
  }
  const isProps = { isHostUser, isClientUser, isRequestor, ALL: true };
  const conditions = [
    { level: "HOST", prop: "isHostUser" },
    { level: "CLIENT", prop: "isClientUser" },
    { level: "REQUESTOR", prop: "isRequestor" },
    { level: "ALL", prop: "ALL" }
  ];
  return conditions
    .map(({ level, prop }) => availableTo.includes(level) && isProps[prop])
    .reduce((acc, b) => acc || b, false);
};
