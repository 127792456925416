import React, { useCallback, useEffect, useMemo, useState } from "react";

import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  manageDocumentDownloadsActions,
  manageProjectQueriesActions,
  manageQueryResponsesActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useCurrentProject,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useGetProjectQuery,
  useProjectQueries,
  useRequestPageNavigator,
  useUIConfig
} from "@shared/hooks";
import { useToasts } from "@shared/hooks/useToasts.jsx";
import {
  useLazyGetActionItemQuery,
  useUpdateActionItemFieldMutation,
  useUpdateWorkflowMutation
} from "@shared/services/actionItemService";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";
import { isAllowedToUpdateProperties } from "@app/helpers/actionItems.js";

import { Icon } from "@atoms/Icon";

import { ApproveRejectQueryFlag } from "@organisms/ApproveRejectQueryFlag";

import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import RequestAttachmentSummaryBox from "@components/molecules/RequestAttachmentSummaryBox";
import RequestConversationBox from "@components/molecules/RequestConversationBox";
import RequestDetailsBox from "@components/molecules/RequestDetailsBox";
import RequestEntitiesBox from "@components/molecules/RequestEntitiesBox";
import EditActionTags from "@components/organisms/EditActionTags";
import QueryActions from "@components/organisms/QueryActions";
import AddQueryResponse from "@components/organisms/QueryActions/AddQueryResponse";
import { buttonActionConstants } from "@components/organisms/QueryActions/QueryActions";
import ReassignQuery from "@components/organisms/QueryActions/ReassignQuery";
import UpdateCopiedTo from "@components/organisms/QueryActions/UpdateCopiedTo";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./RequestDetails.scss";

const statusTypes = systemConstants.project.queries.status;
const conversationActionItemType = systemConstants.actionItemTypes.conversation;
const responseTypes = systemConstants.project.queries.responses.responseTypes;

const RequestDetails = () => {
  const { t } = useTranslation();
  const { errorFetchingQuery } = useGetProjectQuery();
  const location = useLocation();
  const [query, setQuery] = useState(location.state?.query);
  const [queryResponses, setResponses] = useState([]);
  const { user: authUser } = useAuthUser();
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reassignQueryVisibility, setReassignQueryVisibility] = useState(false);
  const [addResponseVisibility, setAddResponseVisibility] = useState(false);
  const [responseActionType, setResponseActionType] = useState("");
  const [showEditTags, setShowEditTags] = useState(false);

  const [conversationConfig, setConversationConfig] = useState(null);
  const [showReminderDate, setShowReminderDate] = useState(false);
  const [pillConfig, setPillConfig] = useState();
  const [showUpdateCopiedTo, setShowUpdateCopiedTo] = useState(false);

  const dispatch = useDispatch();
  const manageQueryResponses = useSelector(state => state.manageQueryResponses);
  const { navigateToRequestPage } = useRequestPageNavigator();
  const navigate = useNavigate();
  const { projectId, queryId } = useParams();
  const { uiConfig } = useUIConfig();
  const { currentProject, onChangeCurrentProject } = useCurrentProject();
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: currentProject?.engagement.engagementTypeId,
      projectId: currentProject?.id
    },
    {
      skip: currentProject?.engagement.engagementTypeId === undefined
    }
  );
  const { members } = useGetProjectMembers({ id: projectId });
  const [isLoadingPageFirstTime, setIsLoadingPageFirstTime] = useState(true);
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );

  const [getQuery, { data: fetchedQuery }] = useLazyGetActionItemQuery();

  const [updateQueryField, { error: updateQueryError, data: updatedQuery }] =
    useUpdateActionItemFieldMutation();

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const { showError, showSuccess } = useToasts();

  const { invalidateQueryTag } = useProjectQueries({
    id: projectId
  });

  useEffect(() => {
    if (!projectId && currentProject && !queryId && query) {
      navigate(`/projects/${currentProject.id}/queries/${query.id}`, {
        replace: true
      });
    }
  }, [navigate, currentProject, projectId, query, queryId]);

  useEffect(() => {
    if (errorFetchingQuery || isInvalidProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [errorFetchingQuery, navigate, isInvalidProject]);

  useEffect(
    () => () => dispatch(manageDocumentDownloadsActions.reset()),
    [dispatch]
  );

  useEffect(() => {
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, currentProject, onChangeCurrentProject]);

  useEffect(() => {
    return () => {
      dispatch(manageDocumentDownloadsActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (fetchedQuery && projectId) {
      if (fetchedQuery.projectId != projectId) {
        navigate(routeConstants.login);
      } else {
        setQuery(fetchedQuery);
        setIsLoadingPageFirstTime(false);
      }
    } else if (isLoadingPageFirstTime && queryId) {
      getQuery({ queryId });
    }
  }, [
    fetchedQuery,
    isLoadingPageFirstTime,
    navigate,
    projectId,
    queryId,
    getQuery
  ]);

  useEffect(() => {
    if (
      query?.id &&
      manageQueryResponses.queryResponses &&
      manageQueryResponses.queryResponses[query.id]
    ) {
      setResponses(manageQueryResponses.queryResponses[query.id]);
    }
  }, [query?.id, manageQueryResponses.queryResponses]);

  useEffect(() => {
    if (query?.id) {
      dispatch(manageQueryResponsesActions.getQueryResponses(query.id));
    }
  }, [dispatch, query?.id, manageQueryResponses.isAdded]);

  useEffect(() => {
    if (
      updatedQuery &&
      query?.id &&
      updatedQuery.id === query.id &&
      currentProject
    ) {
      setQuery({ ...updatedQuery });
      setResponseActionType("");
      dispatch(manageProjectQueriesActions.getMyQueryCount(currentProject));
    }
  }, [updatedQuery, dispatch, currentProject, query?.id]);

  useEffect(() => {
    if (!query?.queryType) {
      return;
    }
    const filteredActionItemTypes = actionItemTypes
      ?.filter(
        actionItemType => actionItemType.configuration.key === query.queryType
      )
      ?.map(q => ({
        ...q.configuration,
        buttons: currentProject?.configuration.buttons
      }));
    if (filteredActionItemTypes?.length > 0) {
      setConversationConfig(filteredActionItemTypes[0]);
    }
  }, [actionItemTypes, query?.queryType, currentProject]);

  useEffect(() => {
    if (!conversationConfig?.type) {
      return;
    }
    // The actual config type isn't Conversation redirect appropriately
    if (conversationConfig.type !== conversationActionItemType) {
      navigateToRequestPage(
        query.id,
        query.projectId,
        conversationConfig.type,
        { replace: true }
      );
    }
  }, [conversationConfig, query, navigateToRequestPage]);

  function downloadAttachment({ id, name, documentRevisionId }) {
    if (documentRevisionId) {
      dispatch(
        manageDocumentDownloadsActions.downloadDocumentRevision({
          id,
          name,
          documentRevisionId
        })
      );
    } else {
      dispatch(
        manageDocumentDownloadsActions.downloadCurrentDocument({ id, name })
      );
    }
  }

  const isProjectMember = useCallback(
    (action, cb) => {
      if (
        authUser?.isHostUser &&
        !members.hostUsers?.some(member => member.id === authUser.id)
      ) {
        setShowProjectAccessModal(true);
        setErrorMessage(
          t("common:project.accessRequiredToDoAction", { action })
        );
      } else {
        cb();
      }
    },
    [authUser.id, authUser?.isHostUser, members.hostUsers, t]
  );

  const refetchQuery = useCallback(
    queryId => {
      getQuery({ queryId });
    },
    [getQuery]
  );

  const handleUpdateQueryField = useCallback(
    ({ field, value }) => {
      if (queryId) {
        updateQueryField({
          queryId,
          field,
          value
        });
      }
    },
    [queryId, updateQueryField]
  );

  const handleCancelReassignQuery = () => {
    setReassignQueryVisibility(false);
  };

  const handlePopupCancelClick = () => {
    setAddResponseVisibility(false);
  };

  const handleCopiedToUpdated = updatedQuery => {
    setQuery(updatedQuery);
    setShowUpdateCopiedTo(false);
  };
  const handleCopiedToCancelled = () => {
    setShowUpdateCopiedTo(false);
  };

  const handleTagsUpdated = updatedQuery => {
    setQuery(updatedQuery);
    setShowEditTags(false);
  };
  const handleCancelUpdateTags = () => {
    setShowEditTags(false);
  };

  const handlePopupAddClick = () => {
    setAddResponseVisibility(false);
    refetchQuery(query.id);
  };

  const showAddResponsePopup = useCallback(() => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionAddResponse"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        setResponseActionType(responseTypes.message);
        setAddResponseVisibility(true);
      }
    );
  }, [dispatch, isProjectMember, t]);

  const showRejectRequestPopup = useCallback(() => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.reject);
    setAddResponseVisibility(true);
  }, [dispatch]);

  const showApproveRequestPopup = useCallback(() => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.approve);
    setAddResponseVisibility(true);
  }, [dispatch]);

  const handleUpdateWorkflow = useCallback(
    action => {
      if (!queryId) {
        return;
      }
      updateWorkflow({
        queryId,
        action
      })
        .then(data => {
          if (
            data.data?.updatedQuery?.status === statusTypes.closed &&
            action.action === statusTypes.closed
          ) {
            showSuccess({
              key: t("requests:requests.ui.toastMessages.requestClosed")
            });
          }
          if (data?.error)
            showError({
              key: data.error.data?.message
            });
        })
        .catch(e => {
          showError({
            key: e.message
          });
        });
    },
    [queryId, showError, updateWorkflow]
  );

  const membershipCheckThenHandleUpdateWorkflow = useCallback(
    action => {
      isProjectMember(
        t(
          "requests:requests.ui.requestDetailsPage.accessRequiredActionWorkflow"
        ),
        () => {
          handleUpdateWorkflow(action);
        }
      );
    },
    [handleUpdateWorkflow, isProjectMember, t]
  );

  const closeRequest = useCallback(() => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionCloseRequest"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        setResponseActionType(responseTypes.close);
        membershipCheckThenHandleUpdateWorkflow({
          action: statusTypes.closed,
          actionType:
            systemConstants.project.queries.workflow.actionTypes.legacy
        });
      }
    );
  }, [dispatch, isProjectMember, membershipCheckThenHandleUpdateWorkflow, t]);

  const showReassignRequestPopup = useCallback(() => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionReassignRequest"
      ),
      () => {
        setReassignQueryVisibility(true);
      }
    );
  }, [isProjectMember, t]);

  const onQueryReassigned = useCallback(assignedTo => {
    setQuery(prevQuery => ({
      ...prevQuery,
      assignedTo
    }));
    setReassignQueryVisibility(false);
  }, []);

  const reopenRequest = useCallback(() => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.reopen);
    handleUpdateQueryField({ field: "status", value: statusTypes.open });
  }, [dispatch, handleUpdateQueryField]);

  const handleCloseAccessModal = () => {
    setShowProjectAccessModal(false);
    setErrorMessage("");
  };

  useEffect(() => {
    const reminderDateField = conversationConfig?.fields?.find(
      f => f.key === "reminderDate"
    );

    setShowReminderDate(
      reminderDateField &&
        !(
          reminderDateField.availableTo &&
          !authUser.isMemberOfUserGroup(reminderDateField.availableTo)
        )
    );
  }, [authUser, conversationConfig?.fields]);

  const [topConversationConfig, bottomConversationConfig] = useMemo(() => {
    const bottomActionNames = ["ADD_RESPONSE"];
    const [buttonMenu, otherMenus] = lodash.partition(
      conversationConfig?.menus,
      menu => menu.name === "BUTTONS"
    );

    const [topButtons, bottomButtons] = lodash.partition(
      buttonMenu?.[0]?.actions,
      item => !bottomActionNames.includes(item.action)
    );

    return [
      {
        ...conversationConfig,
        menus: [...otherMenus, { ...buttonMenu?.[0], actions: topButtons }]
      },
      {
        ...conversationConfig,
        buttons: [], // Do not duplicate extra buttons
        menus: [...otherMenus, { ...buttonMenu?.[0], actions: bottomButtons }]
      }
    ];
  }, [conversationConfig]);

  const closePage = useCallback(() => {
    invalidateQueryTag();
    navigate(-1);
  }, []);

  const queryActionRequestHandlers = useMemo(
    () => ({
      [buttonActionConstants.reassign]: showReassignRequestPopup,
      [buttonActionConstants.closeRequest]: closeRequest,
      [buttonActionConstants.reopen]: reopenRequest,
      [buttonActionConstants.addResponse]: showAddResponsePopup,
      [buttonActionConstants.approve]: showApproveRequestPopup,
      [buttonActionConstants.reject]: showRejectRequestPopup,
      [buttonActionConstants.closePage]: closePage
    }),
    [
      closeRequest,
      navigate,
      reopenRequest,
      showAddResponsePopup,
      showApproveRequestPopup,
      showReassignRequestPopup,
      showRejectRequestPopup
    ]
  );

  const conversationContent = () => {
    if (!query) {
      return <></>;
    }
    return (
      authUser.id && (
        <div className="request-details__interactions">
          <div className="request-details__conversation">
            {conversationConfig && (
              <RequestConversationBox
                query={query}
                responses={queryResponses}
                viewingUserId={authUser.id}
                handleFileDownload={downloadAttachment}
                title={t("requests:requests.configured.shortName", {
                  context: conversationConfig.key
                })}
              />
            )}
          </div>
          <div className="request-details__actions">
            <QueryActions
              queryId={query.id}
              queryType={query.queryType}
              queryFlag={query.flag}
              queryConfig={bottomConversationConfig}
              queryStatus={query.status}
              isUserRequestor={query.isUserOnRequestorTeam}
              requestHandlers={queryActionRequestHandlers}
            />
          </div>
        </div>
      )
    );
  };

  const milestoneContent = () => {
    if (!currentProject?.configuration?.milestones?.labels?.enabled) {
      return "";
    }
    const projectMilestones = currentProject.milestones;
    return projectMilestones?.find(m => m.id === query?.milestone)?.name ?? "";
  };

  const detailsContent = () => {
    if (!query) {
      return <></>;
    }
    return (
      <>
        <RequestDetailsBox
          disabled={
            !isAllowedToUpdateProperties({
              aitConfig: conversationConfig,
              query: query,
              user: authUser
            }) || query.status === statusTypes.closed
          }
          requestQuery={query}
          queryConfig={conversationConfig}
          showReminderDate={showReminderDate}
          hideRequiredByField={
            currentProject?.configuration?.requests?.hideRequiredByField ??
            false
          }
          updateQueryField={handleUpdateQueryField}
          canEditLabels={authUser?.isHostUser}
          onClickEditLabels={() => {
            setShowEditTags(true);
          }}
          onClickEditCopiedTo={() => {
            setShowUpdateCopiedTo(true);
          }}
          title={t("requests:requests.configured.shortName", {
            context: conversationConfig?.key
          })}
          pillConfig={pillConfig}
          milestoneContent={milestoneContent()}
          approvalRejectFlag={
            <ApproveRejectQueryFlag
              query={query}
              queryResponses={manageQueryResponses?.queryResponses?.[query?.id]}
            />
          }
        />
        <RequestAttachmentSummaryBox
          responses={queryResponses}
          attachments={query.attachments}
          onFileDownloadClicked={downloadAttachment}
        />

        {currentProject?.entities &&
          currentProject?.configuration.entities?.enabled && (
            <RequestEntitiesBox
              title={t(`requests:requests.configured.fields.entities.label`, {
                context: query.queryType
              })}
              boxClassName="request-details__entities"
              projectEntities={currentProject.entities ?? []}
              entityIdsToDisplay={query?.entities ?? []}
              action={<Icon name="lan" />}
            />
          )}
      </>
    );
  };

  return (
    <>
      <PageTemplate
        header={{
          title: t("requests:requests.configured.name", {
            context: conversationConfig?.key
          }),
          actions: query && (
            <QueryActions
              queryId={query.id}
              queryType={query.queryType}
              queryFlag={query.flag}
              queryConfig={topConversationConfig}
              queryStatus={query.status}
              isUserRequestor={query.isUserOnRequestorTeam}
              requestHandlers={queryActionRequestHandlers}
            />
          ),
          sticky: true
        }}
        body={{
          primary: conversationContent(),
          secondary: detailsContent(),
          secondaryWidth: "20vw"
        }}
        other={{
          smallPageSize: 1200,
          project: currentProject,
          error: t(updateQueryError?.data?.message)
        }}
      />

      {query && (
        <>
          <Popup
            visibility={reassignQueryVisibility}
            handleOutsideClick={handleCancelReassignQuery}
            width="50rem"
          >
            <ReassignQuery
              project={currentProject}
              query={query}
              onQueryReassigned={onQueryReassigned}
              onCancel={handleCancelReassignQuery}
            />
          </Popup>
          <Popup
            visibility={showEditTags}
            handleOutsideClick={handleCancelUpdateTags}
            width="50rem"
          >
            <EditActionTags
              project={currentProject}
              query={query}
              queryConfig={conversationConfig}
              onUpdate={handleTagsUpdated}
              onCancel={handleCancelUpdateTags}
            />
          </Popup>
          <Popup
            visibility={showUpdateCopiedTo}
            handleOutsideClick={handleCopiedToCancelled}
            width="50rem"
          >
            <UpdateCopiedTo
              project={currentProject}
              query={query}
              queryConfig={conversationConfig}
              onUpdate={handleCopiedToUpdated}
              onCancel={handleCopiedToCancelled}
            />
          </Popup>
          <Popup
            visibility={addResponseVisibility}
            handleOutsideClick={false}
            width="60rem"
          >
            <AddQueryResponse
              query={query}
              queryConfig={conversationConfig}
              project={currentProject}
              queryType={query.queryType}
              onSuccess={handlePopupAddClick}
              onCancel={handlePopupCancelClick}
              responseType={responseActionType}
            />
          </Popup>
          <ProjectAccessModal
            visibility={showProjectAccessModal}
            handleClose={handleCloseAccessModal}
            message={errorMessage}
          />
        </>
      )}
    </>
  );
};

export default RequestDetails;
