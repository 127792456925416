import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useGetFinalPackageTips() {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);

  const fetchFinalPackageTips = useCallback(
    projectId => {
      dispatch(finalPackageActions.getFinalPackageTips(projectId));
    },
    [dispatch]
  );

  return {
    finalPackageTips: finalPackage?.finalPackageTips,
    fetchFinalPackageTips
  };
}
