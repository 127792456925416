import React from "react";

import { useNavigate } from "react-router-dom";

import { useAuthUser } from "@shared/hooks/useAuthUser";

import { useDefaultLandingPageLink } from "@app/hooks/useDefaultLandingPageLink";

const LandingPage = React.memo(() => {
  const { user } = useAuthUser();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const navigate = useNavigate();

  const landingPageLink = getDefaultLandingPageLink(user);
  if (landingPageLink) {
    navigate(landingPageLink);
  } else {
    navigate("/not-found");
  }
  return <></>;
});

export default LandingPage;
