import React from "react";

import PropTypes from "prop-types";

import { Button, ButtonVariant } from "@atoms/Button";

import "./LogoHolder.scss";

const LogoHolder = props => {
  const { label } = props;

  const handleChangeClick = () => {
    props.handleLogoChangeClick();
  };

  const handleRemoveClick = () => {
    props.handleRemoveLogoClick();
  };

  return (
    <div className="logo-holder">
      <div className="logo-holder__container">
        {props.src ? (
          <img src={props.src} alt="Logo" className="logo-holder__image" />
        ) : (
          <span className="logo-holder__placeholder"></span>
        )}

        {label && <div className="logo-holder__label">{label}</div>}

        {(props.handleLogoChangeClick || props.handleRemoveLogoClick) && (
          <div className="logo-holder__action">
            {props.handleLogoChangeClick && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                onClick={handleChangeClick}
                className="logo-holder__action--text"
                label={props.src ? "Change" : "Add"}
              />
            )}
            {props.handleRemoveLogoClick && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                onClick={handleRemoveClick}
                className="logo-holder__action--text"
                label="Remove"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

LogoHolder.defaultProps = {};

LogoHolder.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
  handleLogoChangeClick: PropTypes.func,
  handleRemoveLogoClick: PropTypes.func
};

export default LogoHolder;
