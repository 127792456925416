import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const projectDashboardService = createApi({
  reducerPath: "projectDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["ProjectDashboard", "DashboardFilters"],
  endpoints: builder => ({
    getProjectsForDashboard: builder.query({
      query: params =>
        `/api/projects?${new URLSearchParams(params).toString()}`,
      providesTags: ["ProjectDashboard"]
    }),
    getFiltersForDashboard: builder.query({
      query: () => `/api/projects?for=filter`,
      providesTags: ["DashboardFilters"]
    })
  })
});

export const {
  useLazyGetProjectsForDashboardQuery,
  useGetFiltersForDashboardQuery,
  util: projectDashboardServiceUtil
} = projectDashboardService;
