import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useUpdateFinalPackageTip() {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);

  useEffect(() => {
    return () => {
      dispatch(finalPackageActions.resetUpdateFinalPackageTip());
    };
  }, [dispatch]);

  const updateFinalPackageTip = useCallback(
    (projectId, finalPackageTip) => {
      if (finalPackage?.isFinalPackageTipUpdating) return;
      dispatch(finalPackageActions.resetUpdateFinalPackageTip());
      dispatch(
        finalPackageActions.updateFinalPackageTip(projectId, finalPackageTip)
      );
    },
    [dispatch, finalPackage?.isFinalPackageTipUpdating]
  );

  return {
    isFinalPackageTipUpdating: finalPackage?.isFinalPackageTipUpdating,
    finalPackageTipUpdated: finalPackage?.finalPackageTipUpdated,
    finalPackageTipUpdateError: finalPackage?.finalPackageTipUpdateError,
    updateFinalPackageTip
  };
}
