import React, { useCallback, useEffect, useMemo, useState } from "react";

import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AITFormOptions,
  AITFormatConfig,
  AITFormatFormConfigRule,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import Form from "@atoms/Form/Form.jsx";
import Icon from "@atoms/Icon/Icon.tsx";
import { IconColor, IconFillStyle } from "@atoms/Icon/index.ts";
import Text from "@atoms/Text/Text.tsx";

import { DropdownItemType } from "@molecules/DropdownList/DropdownHelper.tsx";
import { MessageBox } from "@molecules/MessageBox/MessageBox.tsx";

import BoxTemplate from "@templates/BoxTemplate/BoxTemplate.jsx";
import { InputContainer } from "@templates/InputContainer/InputContainer.tsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

import { ActionItemTypeFormOptionDropdown } from "./ActionItemTypeFormOptionDropdown.tsx";

interface ActionItemTypeFormFieldsProps {
  actionItemType: ActionItemType;
  formOptions: AITFormOptions;
  formatConfig?: AITFormatConfig;
  crudFormMode: CrudFormMode;
}

interface RuleVisibility {
  value: string;
  name: string;
  isActive: boolean;
  isLocked: boolean;
  isDisabled: boolean;
}
interface RuleByField {
  [fieldKey: string]: RuleVisibility[];
}

export const getDataPickerProps = (field, defaultValue: string) => {
  const configuredEnableMinDate = field?.enableMinDate;
  const enableMinDate = configuredEnableMinDate || field.key === "reminderDate";
  if (!enableMinDate) {
    return null;
  }
  const defaultDate = new Date(defaultValue);
  const isDefaultDateInPast = defaultDate.getTime() < Date.now();
  const minDate = isDefaultDateInPast ? defaultDate : new Date();

  return minDate;
};

const ActionItemTypeFormFields = (props: ActionItemTypeFormFieldsProps) => {
  const {
    actionItemType,
    formOptions,
    formatConfig,
    crudFormMode = CrudFormMode.READ
  } = props;
  const { t } = useTranslation();
  const [visibleRulesPerField, setVisibleRulesPerField] = useState<RuleByField>(
    {}
  );
  const { watch, getValues, setValue, trigger } = useFormContext();
  const formsConfig = useMemo(() => {
    return (
      formatConfig?.formsConfig?.filter(
        ({ section }) => section === "fields"
      ) ?? []
    );
  }, [formatConfig?.formsConfig]);

  const documentExtensions = useMemo(() => {
    return formOptions.documentExtensions.map(ext => ({
      name: ext,
      value: ext
    }));
  }, [formOptions?.documentExtensions]);

  const engagementType = watch("engagementType");
  const selectedEngagementType = useMemo(() => {
    return formOptions.engagementTypes.find(
      item => item.id === engagementType?.value
    );
  }, [engagementType, formOptions?.engagementTypes]);

  const milestones = useMemo(() => {
    const etMilestones =
      selectedEngagementType?.configuration?.milestones?.labels;
    if (!etMilestones?.enabled) {
      return [];
    }
    return etMilestones.names.map(({ key }) => ({
      name: t(key),
      value: key
    }));
  }, [t, selectedEngagementType]);

  const defaultForExtensionRule = useCallback(
    (field, rule: AITFormatFormConfigRule) => {
      if (field.configuredField) {
        const existingExtensions =
          field.configuredField?.validations?.find(
            (v: { name: string }) => v.name === rule.validationKey
          )?.value ?? [];
        const asSet = new Set(existingExtensions);
        return documentExtensions.filter(ext => asSet.has(ext.value));
      } else if (rule.defaultValue) {
        return rule.defaultValue.map((v: string) => ({
          name: v,
          value: v
        }));
      } else {
        return [];
      }
    },
    [documentExtensions]
  );

  const singleFromOptions = (options: { value: unknown }[], value: unknown) => {
    return options.find(option => option.value === value);
  };

  const isFieldUsingRule = (field, rule: AITFormatFormConfigRule) => {
    const isRuleForFieldDefaultValue = rule.key === "defaultValue";
    if (isRuleForFieldDefaultValue) {
      return !!field.configuredField?.defaultValue;
    }

    const fieldValidators = field.configuredField?.validations;
    return fieldValidators?.find(v => v.name === rule.validationKey)?.value;
  };

  const ruleVisibilityHandler = useCallback(
    field => (selectedValues: DropdownItemType[]) => {
      const updateRule = (rule: RuleVisibility) => {
        const isActive = selectedValues.some(
          selectedValue => selectedValue.value === rule.value
        );
        return {
          ...rule,
          isActive
        };
      };
      setVisibleRulesPerField(prev => ({
        ...prev,
        [field.key]: prev[field.key]?.map(updateRule)
      }));
    },
    []
  );

  const aitConfigTypeFields = useMemo(() => {
    const context = {
      engagementType: selectedEngagementType
    };

    return formsConfig?.map(f => {
      const fieldKey = f.key;
      const fieldConfig = actionItemType?.configuration.fields.find(
        ({ key }) => key === fieldKey
      );
      const isVisible = f.condition ? get(context, f.condition) : true;
      return {
        ...f,
        isVisible: isVisible || !!fieldConfig,
        configuredField: fieldConfig,
        defaultActive:
          (crudFormMode === CrudFormMode.CREATE && f.active) || !!fieldConfig
      };
    });
  }, [
    selectedEngagementType,
    formsConfig,
    actionItemType?.configuration.fields,
    crudFormMode
  ]);

  useEffect(() => {
    Object.entries(visibleRulesPerField).forEach(([fieldKey, rules]) => {
      const activeRules = rules.filter(rule => rule.isActive).map(r => r.value);
      setValue(`fields.${fieldKey}.activeRules`, activeRules);
      trigger(`fields.${fieldKey}`);
    });
  }, [setValue, trigger, visibleRulesPerField, aitConfigTypeFields]);

  useEffect(() => {
    // handle case here if there's no AIT it should follow some default
    if (!aitConfigTypeFields) {
      return;
    }
    aitConfigTypeFields.forEach(field => {
      if (!field.isVisible) {
        setValue(`fields.${field.key}.active`, false);
      }
    });

    // initially visible if there's a value
    const fieldRuleEntries = aitConfigTypeFields.map(field => {
      const lockedRuleSet = new Set(field.locked);
      return [
        field.key,
        field.rules.map(rule => {
          const isActive = getValues(
            `fields.${field.key}.activeRules`
          )?.includes(rule.key);

          const lockedRuleKey = `rules.${rule.key}`;

          return {
            value: rule.key,
            name: t(`admin:ui.requestTypes.fieldRule.${rule.key}.label`),
            isLocked: lockedRuleSet.has(lockedRuleKey),
            isDisabled: lockedRuleSet.has(lockedRuleKey),
            isActive:
              isFieldUsingRule(field, rule) ||
              isActive ||
              lockedRuleSet.has(lockedRuleKey)
          };
        })
      ];
    });
    const rulesByField = Object.fromEntries(fieldRuleEntries) as RuleByField;
    setVisibleRulesPerField(rulesByField);
  }, [aitConfigTypeFields, formsConfig, setValue, t, getValues]);

  const resetRules = useCallback(
    field => {
      const lockedSet = new Set(field.locked);
      field.rules
        ?.filter((rule: AITFormatFormConfigRule) =>
          lockedSet.has(`rules.${rule.key}`)
        )
        .forEach((rule: AITFormatFormConfigRule) => {
          if (rule.type === "number") {
            setValue(
              `fields.${field.key}.rules.${rule.key}`,
              rule.defaultValue
            );
          }
          if (rule.type === "fileExtension") {
            const d = defaultForExtensionRule(field, rule);
            setValue(`fields.${field.key}.rules.${rule.key}`, d);
          }
        });
    },
    [setValue, defaultForExtensionRule]
  );
  const resetLockedFields = useCallback(
    field => {
      const getInitialAvailableBy = (value: string) => ({
        name: t(`admin:ui.manageRequestTypes.groups.fields.${value}.label`),
        value: value
      });
      const getInitialUpdatableBy = (value: string | string[]) => {
        const items = [value]
          .flat()
          .filter(c => c)
          .map(f => ({
            name: t(
              `admin:ui.manageRequestTypes.groups.fields.updatableBy.${f}.label`
            ),
            value: f
          }));
        return items;
      };
      field?.locked?.forEach((lockedField: string) => {
        const fieldVal = field[lockedField];
        const fieldKey = `fields.${field.key}.${lockedField}`;
        if (["availableTo", "creatableBy"].includes(lockedField)) {
          const val = getInitialAvailableBy(fieldVal);
          setValue(fieldKey, val);
        } else if (lockedField === "updatableBy") {
          const val = getInitialUpdatableBy(fieldVal);
          setValue(fieldKey, val);
        } else {
          setValue(fieldKey, fieldVal);
        }
      });
    },
    [setValue, t]
  );
  useEffect(() => {
    aitConfigTypeFields
      .filter(field => field.locked)
      .forEach(field => {
        resetLockedFields(field);
        resetRules(field);
      });
  }, [aitConfigTypeFields, getValues, resetLockedFields, resetRules]);

  const fieldName = useCallback(
    ({ label, i18nLabel, key }) => {
      if (label) {
        return label;
      }

      return t(
        [i18nLabel, `requests:requests.configured.fields.${key}.label`]?.filter(
          k => k
        ),
        {
          context: actionItemType?.configuration.key
        }
      );
    },
    [actionItemType?.configuration.key, t]
  );

  const visibleRulesForField = useCallback(
    field => {
      const visibleRulesSet = new Set(
        visibleRulesPerField[field.key]
          ?.filter(r => r.isActive)
          .map(r => r.value)
      );
      const isFieldActive = getValues(`fields.${field.key}.active`);
      const visibleRules =
        field.rules
          ?.filter(r => isFieldActive && visibleRulesSet.has(r.key))
          .map(r => ({
            ...r,
            isDisabled: visibleRulesPerField[field.key]?.find(
              rule => rule.value === r.key
            )?.isDisabled
          })) ?? [];
      return visibleRules;
    },
    [visibleRulesPerField, getValues]
  );

  const isPropertyLocked = (field: { locked?: string[] }, property: string) => {
    if (!field?.locked) {
      return false;
    }
    return field?.locked?.some(f => f === property);
  };

  const isAllRulesLocked = (rules: RuleVisibility[]) => {
    return rules.every(rule => rule.isLocked);
  };

  const MaybeRoundedCheckbox = useCallback(
    ({ name, defaultValue, required, disabled }) => {
      if (crudFormMode === CrudFormMode.READ) {
        if (defaultValue) {
          return (
            <>
              <Form.HiddenInput name={name} value={defaultValue} />
              <Icon
                name="check_circle"
                fillStyle={IconFillStyle.FILLED}
                color={IconColor.SECONDARY}
              />
            </>
          );
        }

        return (
          <>
            <Form.HiddenInput name={name} value={defaultValue} />
            <Icon
              name="cancel"
              fillStyle={IconFillStyle.OUTLINED}
              color={IconColor.MUTED}
            />
          </>
        );
      }

      return (
        <Form.Checkbox
          name={name}
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
        />
      );
    },
    [crudFormMode]
  );

  const availableForItems = useMemo(() => {
    return formatConfig?.groups.fields.availableTo?.map(f => ({
      name: t(`admin:ui.manageRequestTypes.groups.fields.${f}.label`),
      value: f
    }));
  }, [formatConfig?.groups?.fields?.availableTo, t]);

  const renderAvailableFor = useCallback(
    field => {
      const items = availableForItems;
      if (!items || items.length === 0) {
        return <> </>;
      }
      const getNameLabel = (value: string) =>
        t(`admin:ui.manageRequestTypes.groups.fields.${value}.label`);

      const defaultValue = (() => {
        const savedValue = getValues(`fields.${field.key}.availableTo`);
        const initialValue = items.find(i => i.value === field.availableTo);
        const valueFromConfig = actionItemType?.configuration.fields.find(
          f => f.key === field.key
        )?.availableTo;
        //new update value
        if (savedValue?.value) {
          return {
            name: getNameLabel(savedValue.value),
            value: savedValue.value
          };
        }
        //initial value
        if (crudFormMode === CrudFormMode.CREATE) {
          return initialValue;
        }
        const fieldVal = valueFromConfig ?? initialValue?.value;
        //user saved value
        return {
          name: getNameLabel(fieldVal as string),
          value: fieldVal
        };
      })();
      const isChanged = getValues(`fields.${field.key}.isCreatableByChanged`);
      if (!isChanged) {
        setValue(`fields.${field.key}.creatableBy`, defaultValue);
      }
      return (
        <Form.Dropdown
          items={items}
          key={"availableTo"}
          borderStyle={"none"}
          defaultValue={defaultValue}
          name={`fields.${field.key}.availableTo`}
          disabled={
            crudFormMode === CrudFormMode.READ ||
            !watch(`fields.${field.key}.active`) ||
            isPropertyLocked(field, "availableTo")
          }
          onChange={item => {
            setValue(`fields.${field.key}.creatableBy`, item);
            setValue(`fields.${field.key}.isCreatableByChanged`, true);
          }}
        />
      );
    },
    [
      actionItemType?.configuration.fields,
      availableForItems,
      crudFormMode,
      getValues,
      setValue,
      t,
      watch
    ]
  );

  const getUpdatableDefaultValue = useCallback(
    ({
      value,
      items,
      field,
      isChanged
    }: {
      value: string[] | string;
      items: DropdownItemType[];
      field: { key: string; updatableBy: string[] };
      isChanged: boolean;
    }) => {
      if (isChanged) {
        return getValues(`fields.${field.key}.updatableBy`) ?? [];
      }
      if (!value) {
        return [];
      }
      if (value === "ALL") {
        return items;
      }
      const valueSet = new Set([value].flat());
      return items.filter(item => valueSet.has(item.value));
    },
    [getValues]
  );

  const renderUpdatableBy = useCallback(
    field => {
      const items = formatConfig?.groups?.fields?.updatableBy?.map(f => ({
        name: t(
          `admin:ui.manageRequestTypes.groups.fields.updatableBy.${f}.label`
        ),
        value: f
      }));
      if (!items || items.length === 0) {
        return <></>;
      }
      const isFilesField = field.key === "files";
      const isChanged = getValues(`fields.${field.key}.isUpdatableByChanged`);
      const updatableByDefaultVal = formsConfig?.find(
        ({ key }) => key === field.key
      )?.updatableBy;
      const valueFromConfig = actionItemType?.configuration.fields.find(
        f => f.key === field.key
      )?.updatableBy;
      const editModeVal = valueFromConfig ?? updatableByDefaultVal;

      const defaultValue = isFilesField
        ? []
        : getUpdatableDefaultValue({
            value:
              crudFormMode === CrudFormMode.CREATE
                ? field?.updatableBy
                : editModeVal,
            items,
            field,
            isChanged
          });

      if (!isChanged) {
        setValue(`fields.${field.key}.updatableBy`, defaultValue);
      }

      const showEmptyLabel = isFilesField || defaultValue.length === 0;
      return (
        <Form.Multiselect
          borderStyle={"none"}
          key={"updatableBy"}
          name={`fields.${field.key}.updatableBy`}
          items={items}
          defaultValue={defaultValue}
          showSearch={false}
          showSelectAll={false}
          emptyLabel={
            showEmptyLabel
              ? t(
                  `admin:ui.manageRequestTypes.groups.fields.updatableBy.empty.label`
                )
              : null
          }
          disabled={
            crudFormMode === CrudFormMode.READ ||
            !watch(`fields.${field.key}.active`) ||
            isPropertyLocked(field, "updatableBy")
          }
          onChange={() => {
            setValue(`fields.${field.key}.isUpdatableByChanged`, true);
          }}
        />
      );
    },
    [
      formatConfig?.groups?.fields?.updatableBy,
      formsConfig,
      actionItemType?.configuration?.fields,
      crudFormMode,
      getValues,
      getUpdatableDefaultValue,
      t,
      watch,
      setValue
    ]
  );

  if (aitConfigTypeFields.length === 0) {
    return (
      <BoxTemplate
        title={t("admin:ui.manageRequestTypes.fields.title")}
        subtext={t("admin:ui.manageRequestTypes.fields.subtitle")}
      >
        <Stack gap="300" alignment="center" padding="500" className="empty">
          {t("admin:ui.requestTypes.fields.cta")}
        </Stack>
      </BoxTemplate>
    );
  }

  return (
    <BoxTemplate
      title={t("admin:ui.manageRequestTypes.fields.title")}
      subtext={t("admin:ui.manageRequestTypes.fields.subtitle")}
    >
      <table className="ait-fields-table">
        <thead>
          <tr>
            <th className="ait-fields-table__column--tiny">
              {t("admin:ui.manageRequestTypes.fields.active.label")}
            </th>
            <th className="ait-fields-table__column--small">
              {t("admin:ui.manageRequestTypes.fields.name.label")}
            </th>
            <th className="ait-fields-table__column--short">
              {t("admin:ui.manageRequestTypes.fields.required.label")}
            </th>
            <th className="ait-fields-table__column--medium">
              <Inline gap="100">
                {t("admin:ui.manageRequestTypes.fields.availableFor.label")}
                <span className="input-label__required">*</span>
              </Inline>
            </th>
            <th className="ait-fields-table__column--medium">
              {t("admin:ui.manageRequestTypes.fields.updatableBy.label")}
            </th>
            {crudFormMode !== CrudFormMode.READ && (
              <th className="ait-fields-table__column--tiny"></th>
            )}
            <th className="ait-fields-table__column--large"></th>
            <th className="ait-fields-table__column--filler"></th>
          </tr>
        </thead>
        <tbody>
          {aitConfigTypeFields
            ?.filter(field => field.isVisible)
            .map((field, idx) => (
              <tr key={field.key}>
                <td className="action-config-table-active">
                  <MaybeRoundedCheckbox
                    name={`fields.${field.key}.active`}
                    defaultValue={field.defaultActive || field.configuredField}
                    required
                    disabled={
                      crudFormMode === CrudFormMode.READ ||
                      isPropertyLocked(field, "active")
                    }
                  />
                </td>
                <td className="action-config-table-group action-config-table-field">
                  <Stack gap="300" alignment="start">
                    <Text text={fieldName(field)} />
                    {field.configuredField?.message && (
                      <MessageBox message={t(field.configuredField?.message)} />
                    )}
                  </Stack>
                </td>
                <td className="action-config-table-group action-config-table-required">
                  <Inline gap="500">
                    <MaybeRoundedCheckbox
                      name={`fields.${field.key}.required`}
                      required
                      defaultValue={
                        [CrudFormMode.READ, CrudFormMode.UPDATE].includes(
                          crudFormMode
                        )
                          ? field.configuredField?.required
                          : field.required
                      }
                      disabled={
                        crudFormMode === CrudFormMode.READ ||
                        !watch(`fields.${field.key}.active`) ||
                        isPropertyLocked(field, "required")
                      }
                    />
                  </Inline>
                </td>
                <td className="action-config-table-group action-config-table-group--no-padding">
                  {renderAvailableFor(field)}
                </td>
                <td className="action-config-table-group action-config-table-group--no-padding">
                  {renderUpdatableBy(field)}
                </td>
                {crudFormMode !== CrudFormMode.READ && (
                  <td className="action-config-table-group action-config-table-default">
                    {crudFormMode !== CrudFormMode.READ &&
                    visibleRulesPerField[field.key]?.length > 0 ? (
                      <ActionItemTypeFormOptionDropdown
                        items={visibleRulesPerField[field.key]}
                        onSelectedValuesChanged={ruleVisibilityHandler(field)}
                        disabled={
                          !watch(`fields.${field.key}.active`) ||
                          isAllRulesLocked(visibleRulesPerField[field.key])
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                )}

                <td className="action-config-table-group action-config-table-input">
                  <InputContainer gap="300" isInlined={false}>
                    {/* NB: currently determined by order in config */}
                    <Form.HiddenInput
                      name={`fields.${field.key}.order`}
                      value={idx}
                    />
                    <Form.HiddenInput
                      name={`fields.${field.key}.type`}
                      value={field.type}
                    />
                    {visibleRulesForField(field).map(rule => {
                      const isDisabled =
                        crudFormMode === CrudFormMode.READ || rule.isDisabled;
                      // string, date, number, milestone, csv
                      const isRuleForFieldDefaultValue =
                        rule.key === "defaultValue";

                      switch (rule.type) {
                        case "number": {
                          return (
                            <Form.NumberField
                              key={rule.key}
                              name={`fields.${field.key}.rules.${rule.key}`}
                              label={t(
                                `admin:ui.requestTypes.fieldRule.${rule.key}.label`
                              )}
                              required={rule.required}
                              disabled={isDisabled}
                              defaultValue={
                                isRuleForFieldDefaultValue
                                  ? field.configuredField?.defaultValue
                                  : (field.configuredField?.validations?.find(
                                      v => v.name === rule.validationKey
                                    )?.value ?? rule.defaultValue)
                              }
                              min={rule.min}
                              max={rule.max}
                              onChange={() => {
                                trigger(`fields.${field.key}`);
                              }}
                            />
                          );
                        }
                        case "milestone": {
                          return (
                            <Form.Dropdown
                              key={rule.key}
                              name={`fields.${field.key}.rules.${rule.key}`}
                              label={t(
                                `admin:ui.requestTypes.fieldRule.${rule.key}.label`
                              )}
                              required={rule.required}
                              items={milestones}
                              disabled={isDisabled || !milestones?.length}
                              defaultValue={singleFromOptions(
                                milestones,
                                field.configuredField?.defaultValue
                              )}
                            />
                          );
                        }
                        case "date": {
                          const minDate = getDataPickerProps(
                            field,
                            field.configuredField?.defaultValue ?? ""
                          );
                          return (
                            <Form.DateField
                              key={rule.key}
                              name={`fields.${field.key}.rules.${rule.key}`}
                              label={t(
                                `admin:ui.requestTypes.fieldRule.${rule.key}.label`
                              )}
                              minDate={minDate}
                              required={rule.required}
                              disabled={isDisabled}
                              defaultValue={
                                field.defaultActive ||
                                field.configuredField?.defaultValue
                                  ? field.configuredField?.defaultValue
                                  : null
                              }
                            />
                          );
                        }
                        case "string": {
                          return (
                            <Form.TextField
                              key={rule.key}
                              name={`fields.${field.key}.rules.${rule.key}`}
                              label={t(
                                `admin:ui.requestTypes.fieldRule.${rule.key}.label`
                              )}
                              required={rule.required}
                              disabled={isDisabled}
                              defaultValue={
                                isRuleForFieldDefaultValue
                                  ? field.configuredField?.defaultValue
                                  : field.configuredField?.validations?.find(
                                      v => v.name === rule.validationKey
                                    )?.value
                              }
                              maxLength={
                                watch(
                                  `fields.${field.key}.activeRules`
                                )?.includes("length") &&
                                watch(`fields.${field.key}.rules.length`)
                              }
                            />
                          );
                        }
                        case "fileExtension": {
                          return (
                            <Form.Multiselect
                              key={rule.key}
                              name={`fields.${field.key}.rules.${rule.key}`}
                              label={t(
                                `admin:ui.requestTypes.fieldRule.${rule.key}.label`
                              )}
                              required={rule.required}
                              items={documentExtensions}
                              disabled={isDisabled}
                              defaultValue={defaultForExtensionRule(
                                field,
                                rule
                              )}
                            />
                          );
                        }
                      }
                    })}
                  </InputContainer>
                </td>
                <td className="action-config-table-filler"></td>
              </tr>
            ))}
        </tbody>
      </table>
    </BoxTemplate>
  );
};

export default ActionItemTypeFormFields;
