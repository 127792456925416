import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants";
import { useCurrentProject, useGetProjectByIdQuery } from "@shared/hooks";
import { useGetProjectQuery } from "@shared/hooks/useGetProjectQuery";

import WebSheetDocument from "@components/organisms/WebSheetDocument";

import "./editDocument.scss";

const isQueryList = q =>
  q?.queryType == systemConstants.project.queries.queryTypes.queryList;
const documentTitle = q =>
  `${isQueryList(q) ? "Query List - " : ""}${q?.description ?? ""}`;

const EditDocument = () => {
  const documentEditInfo = JSON.parse(
    sessionStorage.getItem("documentEditInfo")
  );
  const { projectId, queryId, documentId, websheetId } = useParams();
  const { currentProject, onChangeCurrentProject } = useCurrentProject();

  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );
  const navigate = useNavigate();
  const { query, errorFetchingQuery, refetchQuery } = useGetProjectQuery();

  useEffect(() => {
    if (
      !projectId &&
      documentEditInfo?.project?.id &&
      !queryId &&
      documentEditInfo?.query?.id
    ) {
      navigate(
        `/projects/${documentEditInfo?.project?.id}/websheets/${documentEditInfo?.query?.id}`,
        {
          replace: true
        }
      );
    }
  }, [
    navigate,
    projectId,
    queryId,
    documentEditInfo?.project,
    documentEditInfo?.query
  ]);

  useEffect(() => {
    if (
      isInvalidProject ||
      (!projectId &&
        !documentEditInfo?.project &&
        !documentEditInfo?.item.clientId)
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [navigate, projectId, documentEditInfo, isInvalidProject]);

  useEffect(() => {
    if (errorFetchingQuery) {
      navigate(`/projects/${projectId ?? currentProject?.id}/queries`);
    }
  }, [errorFetchingQuery, navigate, projectId, currentProject]);

  useEffect(() => {
    const currentQuery = query || documentEditInfo?.query;
    if (currentQuery && projectId && currentQuery.projectId != projectId) {
      navigate("/not-found", { replace: true });
    }
  });

  useEffect(() => {
    if (
      projectId &&
      projectToSelect &&
      currentProject?.id !== projectToSelect.id
    ) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectId, currentProject, projectToSelect, onChangeCurrentProject]);

  useEffect(() => {
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, currentProject, onChangeCurrentProject]);

  useEffect(() => {
    if (
      queryId &&
      !query &&
      !errorFetchingQuery &&
      (!documentEditInfo?.query || queryId != documentEditInfo?.query?.id)
    ) {
      refetchQuery(queryId);
    }
  }, [queryId, query, refetchQuery, errorFetchingQuery, documentEditInfo]);

  // If this is a smartForm websheet
  if (websheetId) {
    return (
      <WebSheetDocument
        project={currentProject}
        document={{ id: websheetId, forceLatest: true }}
        query={query}
        loading={!currentProject || !query}
        type={"smartForm"}
      />
    );
  } else if (documentId) {
    return (
      <WebSheetDocument
        project={currentProject}
        document={{ id: documentId, forceLatest: true }}
        loading={!currentProject}
        type={"document"}
      />
    );
  } else if (query) {
    return (
      <WebSheetDocument
        title={documentTitle(query) || ""}
        project={currentProject}
        document={{ id: query.attachments?.[0]?.id, forceLatest: true }}
        query={query}
        loading={!currentProject}
        type={"query"}
      />
    );
  } else if (documentEditInfo) {
    return (
      <WebSheetDocument
        title={documentEditInfo.title}
        project={documentEditInfo.project}
        document={documentEditInfo.item}
        query={documentEditInfo.query}
        loading={false}
        type={documentEditInfo.query ? "query" : "document"}
      />
    );
  }
  return <></>;
};

export default EditDocument;
