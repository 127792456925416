import React, { useCallback, useState } from "react";

import PropTypes from "prop-types";

import { Inline } from "@fermions";

import Form from "@components/atoms/Form";
import Loading from "@components/molecules/Loading/Loading";

import BoxTemplate from "../BoxTemplate";
import PageTemplate from "../PageTemplate";
import "./FormPageTemplate.scss";

function FormPageTemplate(props) {
  const {
    title,
    sticky,
    breadcrumbs,
    isLoading,
    other,
    form,
    renderRawForm,
    alwaysShowBreadcrumbs
  } = props;

  const getForm = () => {
    if (renderRawForm) {
      return form?.contents ?? <></>;
    }
    return (
      <BoxTemplate className="ot-form-page-template">
        {form?.contents || <></>}
      </BoxTemplate>
    );
  };

  const [actionButtonsPortal, setActionButtonsPortal] = useState(null);
  const actionButtonsRef = useCallback(node => {
    setActionButtonsPortal(node);
  }, []);

  return (
    <Form {...form} actionButtonsPortal={actionButtonsPortal}>
      <PageTemplate
        header={{
          title,
          sticky: sticky ?? false,
          breadcrumbs,
          alwaysShowBreadcrumbs,
          actions: (
            <Inline wrap gap="200" alignment="right" ref={actionButtonsRef} />
          )
        }}
        other={other}
        body={{ primary: isLoading ? <Loading /> : getForm() }}
      />
    </Form>
  );
}

FormPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sticky: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string.isRequired,
          search: PropTypes.string,
          hash: PropTypes.string,
          state: PropTypes.object
        })
      ]),
      label: PropTypes.string.isRequired
    })
  ),
  form: PropTypes.shape({
    contents: PropTypes.element.isRequired,
    withoutProvider: PropTypes.bool,
    formMethods: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    yupSchema: PropTypes.object,
    submitLabel: PropTypes.string,
    submitButtonVariant: PropTypes.oneOf([
      "primary",
      "secondary",
      "tertiary",
      "text",
      "text-primary",
      "danger"
    ]),
    submitIconName: PropTypes.string,
    cancelLabel: PropTypes.string,
    defaultValues: PropTypes.object,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    customFormClassName: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool,
  other: PageTemplate.propTypes.other,
  renderRawForm: PropTypes.bool
};

export default FormPageTemplate;
