import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions/manageProjectDocumentsActions";
import { systemConstants } from "@shared/constants";

const documentStatus = systemConstants.project.document.status;

export function useDeleteProjectDocument() {
  const dispatch = useDispatch();

  const deleteProjectDocument = useCallback(
    document => {
      if (document.status === documentStatus.archived) {
        dispatch(manageProjectDocumentsActions.deleteProjectDocument(document));
      } else {
        const doc = {
          ...document,
          status: documentStatus.archived
        };
        if (document.properties?.entities?.some(e => typeof e !== "number")) {
          const entities = document.properties.entities
            .filter(e => e.externalId !== undefined)
            .map(e => e.externalId);
          doc.properties = { ...doc.properties, entities };
        }
        dispatch(
          manageProjectDocumentsActions.updateProjectDocumentProperties(doc)
        );
      }
    },
    [dispatch]
  );

  const forceDeleteProjectDocument = useCallback(
    document => {
      dispatch(manageProjectDocumentsActions.deleteProjectDocument(document));
    },
    [dispatch]
  );

  return {
    deleteProjectDocument,
    forceDeleteProjectDocument
  };
}
