import React, { useMemo } from "react";
import { Inline } from "@fermions";

import ions from "@ions";
import { Pill, PillFontWeight, PillSize } from "@atoms/Pill/Pill.tsx";
import { useTranslation } from "react-i18next";
import {
  FilterConfig,
  SelectedFilterItem
} from "@shared/helpers/actionItemFilterHelper.ts";

const pageFilterPillIons = ions.components?.templates?.page_filter?.pill;

interface PageFilterFormSecondaryProps {
  filters: Filter[];
  formMethods: {
    getValues: (key: string) => string;
    setValue: (key: string, value: unknown) => void;
  };
}

interface Filter extends FilterConfig {
  selectedItem?: SelectedFilterItem;
}

function PageFilterFormSecondary(
  props: Readonly<PageFilterFormSecondaryProps>
) {
  const { filters, formMethods } = props;

  const { t } = useTranslation();

  const filtersWithSelectedOptions = useMemo(() => {
    const selectedFilters = filters.map(filter => {
      const selectedValue = filter.selectedItem;

      const options = filter?.options?.map(option => {
        const enabled = selectedValue === option.value;
        return {
          ...option,
          enabled
        };
      });
      return {
        ...filter,
        options
      };
    });

    return selectedFilters;
  }, [filters]);

  const onClickSecondaryFilter =
    ({ key, value }: { key: string; value: unknown }) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      // selecting the active value means to deselect it
      if (formMethods.getValues(key) === value) {
        formMethods.setValue(key, null);
        return;
      }

      formMethods.setValue(key, value);
    };

  return (
    <>
      {filtersWithSelectedOptions.map((filter, idx) => (
        <React.Fragment key={filter.key}>
          <Inline gap="100">
            {filter.options?.map(option => {
              const pillIons = option.enabled
                ? pageFilterPillIons?.active
                : pageFilterPillIons?.inactive;

              return (
                <button
                  key={option.value}
                  onClick={onClickSecondaryFilter({
                    key: filter.key,
                    value: option.value
                  })}
                  className="page-filter__pill"
                >
                  <Pill
                    label={option.translate ? t(option.label) : option.label}
                    variant={pillIons?.pill_variant}
                    fillStyle={pillIons?.pill_fill_style}
                    fontWeight={PillFontWeight.NORMAL}
                    size={PillSize.DEFAULT}
                    isFilter
                    status={option.value}
                  />
                </button>
              );
            })}
          </Inline>

          {idx < filters.length - 1 && (
            <div className="page-filter__divider">&nbsp;</div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default PageFilterFormSecondary;
