import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const documentService = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "documentProperties",
    "archivedClientDocuments",
    "clientDocuments",
    "documentRevisions"
  ],
  endpoints: builder => ({
    getDocumentProperties: builder.query({
      query: ({ documentId }) => `/api/documents/${documentId}/properties`,
      providesTags: ["documentProperties"]
    }),
    getClientDocuments: builder.query({
      query: ({ clientId }) => `/api/clients/${clientId}/documents`,
      provideTags: ["clientDocuments"]
    }),
    getArchivedClientDocuments: builder.query({
      query: ({ clientId }) =>
        `/api/clients/${clientId}/documents?status=archived`,
      provideTags: ["archivedClientDocuments"]
    }),
    deleteClientDocument: builder.mutation({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["clientDocuments", "archivedClientDocuments"]
    }),
    restoreClientDocument: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}/restore`,
        method: "POST",
        body: document,
        invalidatesTags: ["clientDocuments", "archivedClientDocuments"]
      })
    }),
    updateProjectDocumentProperties: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      invalidatesTags: ["documentProperties"]
    }),
    updateClientDocumentProperties: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      invalidatesTags: ["documentProperties", "clientDocuments"]
    }),
    getDocumentRevisions: builder.query({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}/revisions`,
        method: "GET"
      }),
      providesTags: (_result, _, { documentId }) =>
        documentId
          ? [
              { type: "documentRevisions", id: documentId },
              { type: "documentRevisions", id: "LIST" }
            ]
          : [{ type: "documentRevisions", id: "LIST" }]
    }),
    setDocumentCurrentRevision: builder.mutation({
      query: ({ documentId, revisionId }) => ({
        url: `/api/documents/${documentId}/revisions/${revisionId}/current`,
        method: "PUT",
        body: {}
      }),
      invalidatesTags: (_, __, { documentId }) =>
        documentId ? [{ type: "documentRevisions", id: documentId }] : []
    })
  })
});

export const {
  useGetClientDocumentsQuery,
  useGetArchivedClientDocumentsQuery,
  useGetDocumentPropertiesQuery,
  useUpdateProjectDocumentPropertiesMutation,
  useUpdateClientDocumentPropertiesMutation,
  useDeleteClientDocumentMutation,
  useRestoreClientDocumentMutation,
  useSetDocumentCurrentRevisionMutation,
  useGetDocumentRevisionsQuery
} = documentService;
