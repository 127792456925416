import React, { useCallback, useEffect, useMemo, useState } from "react";

import { omit } from "lodash";
import { useTranslation } from "react-i18next";

import {
  getAvailableToDesc,
  getAvailableWhenDesc
} from "@shared/helpers/workflow/workflowCondition.ts";

import { workflowStepName } from "@app/helpers/actionItems.js";
import {
  ActionProps,
  CrudFormMode,
  Operation,
  UPDATE_WORKFLOW_STEP
} from "@app/types/ActionItemType.ts";

import { Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button/index.ts";
import { Divider } from "@atoms/Divider/Divider.tsx";
import TextInput from "@atoms/TextInput/index.js";

import { DropdownInput } from "@molecules/inputs";
import { InputLabel } from "@molecules/inputs/InputTemplate/InputLabel/InputLabel.tsx";

import {
  WorkflowCanvasState,
  useWorkflowCanvas
} from "@pages/ViewActionItemType/WorkflowCanvas/WorkflowCanvasContext.tsx";

import { WorkflowSidebarProps } from "../WorkflowSidebar.tsx";
import { AvailableTo } from "./Section/AvailableTo.tsx";
import { AvailableWhen } from "./Section/AvailableWhen.tsx";

type ItemList = {
  name: string;
  value: string;
};

const MAX_LENGTH = 250;
export const ActionEditor = ({
  crudFormMode,
  onClose
}: WorkflowSidebarProps) => {
  const context: WorkflowCanvasState = useWorkflowCanvas();
  const { t } = useTranslation();
  const {
    selectedNodeId,
    workflowConfig,
    setSelectedLaneId,
    setSelectedNodeId,
    setWorkflowConfig
  } = context;
  const [selectedStep, setSelectedStep] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<string>("");
  useEffect(() => {
    const step = selectedNodeId?.split("-")[0] ?? "";
    const action = selectedNodeId?.split("-")[1] ?? "";
    setSelectedStep(step);
    setSelectedAction(action);
  }, [selectedNodeId]);

  const hasUpdateAccess = useMemo(() => {
    return crudFormMode === CrudFormMode.UPDATE;
  }, [crudFormMode]);

  const dropDownItems = useMemo(() => {
    if (!selectedStep || !selectedAction || !workflowConfig?.steps) {
      return [];
    }

    return Object.entries(workflowConfig.steps).map(([stepKey, step]) => {
      const name = workflowStepName({
        workflowStep: { key: stepKey, name: step?.name },
        t
      });
      return { name, value: stepKey };
    });
  }, [workflowConfig, selectedStep, selectedAction, t]);

  const transitionToVal = useMemo(() => {
    if (!workflowConfig || !selectedStep || !selectedAction) {
      return;
    }
    const transitionStepKey =
      workflowConfig.steps[selectedStep].actions
        ?.find((ac: ActionProps) => {
          return ac.key === selectedAction;
        })
        ?.operations.find(
          (operation: Operation) => operation.type === UPDATE_WORKFLOW_STEP
        )?.value ?? "";
    const name = workflowStepName({
      workflowStep: {
        key: transitionStepKey,
        name: transitionStepKey
          ? workflowConfig.steps[transitionStepKey]?.name
          : ""
      },
      t
    });
    return name;
  }, [selectedStep, selectedAction, workflowConfig, t]);

  const workFlowStepVal = useMemo(() => {
    const stepName = workflowStepName({
      workflowStep: {
        key: selectedStep,
        name: workflowConfig?.steps[selectedStep]?.name
      },
      t
    });
    return stepName;
  }, [selectedStep, workflowConfig, t]);

  const workflowActionName = useMemo(() => {
    if (!workflowConfig || !selectedStep || !selectedAction) {
      return;
    }
    return (
      workflowConfig.steps[selectedStep].actions.find(
        ac => ac.key === selectedAction
      )?.name ?? ""
    );
  }, [workflowConfig, selectedAction, selectedStep]);

  const handleChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!workflowConfig || !selectedStep || !selectedAction) {
        return;
      }
      const nameVal = event.target.value;
      const currentSelectedStep = workflowConfig.steps[selectedStep];
      const notSelectedStepActions = currentSelectedStep?.actions.map(
        (action: ActionProps) => {
          if (action.key === selectedAction) {
            if (nameVal) {
              return { ...action, name: nameVal };
            }
            return omit(action, "name");
          }
          return action;
        }
      );
      setWorkflowConfig({
        ...workflowConfig,
        steps: {
          ...workflowConfig.steps,
          [selectedStep]: {
            ...currentSelectedStep,
            actions: notSelectedStepActions
          }
        }
      });
    },
    [setWorkflowConfig, workflowConfig, selectedAction, selectedStep]
  );

  const handleChangeTransitionTo = useCallback(
    (action: ItemList) => {
      if (!workflowConfig || !selectedStep || !selectedAction) {
        return;
      }

      const updatedOperations = workflowConfig.steps[selectedStep].actions.map(
        (ac: ActionProps) => {
          if (ac.key === selectedAction) {
            const operations = ac.operations.map((operation: Operation) => {
              return operation.type === UPDATE_WORKFLOW_STEP
                ? { ...operation, value: action.value }
                : operation;
            });
            return { ...ac, operations };
          }
          return ac;
        }
      );

      setWorkflowConfig({
        ...workflowConfig,
        steps: {
          ...workflowConfig.steps,
          [selectedStep]: {
            ...workflowConfig.steps[selectedStep],
            actions: updatedOperations
          }
        }
      });
    },
    [setWorkflowConfig, workflowConfig, selectedAction, selectedStep]
  );

  const handleChangeActionStepPos = useCallback(
    (step: ItemList) => {
      if (!workflowConfig || !selectedStep || !selectedAction) {
        return;
      }
      const oldStepConfig = workflowConfig.steps[selectedStep];
      const newStepConfig = workflowConfig.steps[step.value];

      const currentStepActions = oldStepConfig.actions.filter(
        (action: ActionProps) => action.key !== selectedAction
      );
      const toMoveAction = oldStepConfig.actions.filter(
        (action: ActionProps) => action.key === selectedAction
      );
      const newStepActions = [...newStepConfig.actions, ...toMoveAction];
      setWorkflowConfig({
        ...workflowConfig,
        steps: {
          ...workflowConfig.steps,
          [selectedStep]: {
            ...oldStepConfig,
            actions: currentStepActions
          },
          [step.value]: {
            ...newStepConfig,
            actions: newStepActions
          }
        }
      });
      setSelectedLaneId(step.value);
      const newNodeId = `${step.value}-${selectedAction}`;
      setSelectedNodeId(newNodeId);
    },
    [
      workflowConfig,
      setWorkflowConfig,
      selectedAction,
      selectedStep,
      setSelectedLaneId,
      setSelectedNodeId
    ]
  );

  const handleRemoveAction = () => {
    if (!workflowConfig || !selectedStep || !selectedAction) {
      return;
    }
    const currentStepObj = workflowConfig.steps[selectedStep];
    const filteredStepActions = currentStepObj?.actions?.filter(
      (action: ActionProps) => action.key !== selectedAction
    );
    setWorkflowConfig({
      ...workflowConfig,
      steps: {
        ...workflowConfig.steps,
        [selectedStep]: {
          ...currentStepObj,
          actions: filteredStepActions
        }
      }
    });
    onClose();
  };

  const availableToDesc = useMemo(() => {
    const rolesArr =
      workflowConfig?.steps[selectedStep]?.actions?.find(
        action => action.key === selectedAction
      )?.availableTo ?? [];
    const availableToDesc = getAvailableToDesc(rolesArr, t);
    return availableToDesc;
  }, [workflowConfig, selectedAction, selectedStep, t]);

  const availableWhenDesc = useMemo(() => {
    const conditions =
      workflowConfig?.steps[selectedStep]?.actions?.find(
        action => action.key === selectedAction
      )?.availableWhen ?? {};
    const availableWhenDesc = getAvailableWhenDesc(conditions, t);
    return availableWhenDesc;
  }, [workflowConfig, selectedAction, selectedStep, t]);

  return (
    <Stack height="100" className="workflow_action__container__content">
      <Stack gap="300">
        <Stack gap="075">
          <DropdownInput
            label={t("admin:ui.requestTypes.workflowAction.Status")}
            items={dropDownItems}
            value={{
              name: workFlowStepVal
            }}
            onChange={handleChangeActionStepPos}
            disabled={!hasUpdateAccess}
            allowUndefined={false}
          />
        </Stack>
        {selectedAction && (
          <Stack gap="075">
            <TextInput
              variant={ButtonVariant.TEXT}
              label={t("admin:ui.requestTypes.workflowAction.displayName")}
              value={workflowActionName ?? selectedAction}
              onChange={handleChangeName}
              maxLength={MAX_LENGTH}
              disabled={!hasUpdateAccess}
            />
          </Stack>
        )}
        <Stack gap="075">
          <InputLabel
            label={t("admin:ui.requestTypes.workflowAction.restrictions.label")}
          />
          <Stack gap="200">
            {availableToDesc.length > 0 && (
              <>
                <Stack gap="050">
                  <AvailableTo
                    content={availableToDesc}
                    crudFormMode={crudFormMode}
                  />
                </Stack>
                <Divider />
              </>
            )}
            {availableWhenDesc.length > 0 && (
              <>
                <Stack gap="050">
                  <AvailableWhen
                    content={availableWhenDesc}
                    crudFormMode={crudFormMode}
                  />
                </Stack>
                <Divider />
              </>
            )}
          </Stack>
        </Stack>

        <Stack gap="075">
          <DropdownInput
            label={t("admin:ui.requestTypes.workflowAction.transitionTo")}
            items={dropDownItems}
            value={{ name: transitionToVal }}
            onChange={handleChangeTransitionTo}
            disabled={!hasUpdateAccess}
            allowUndefined={false}
          />
        </Stack>
      </Stack>
      {hasUpdateAccess && (
        <Stack alignment="bottom-right">
          <Button
            className="btn__action--remove"
            iconName="delete"
            variant={ButtonVariant.TEXT_DANGER}
            label={t("admin:ui.requestTypes.workflowAction.btn.remove")}
            onClick={handleRemoveAction}
          />
        </Stack>
      )}
    </Stack>
  );
};
