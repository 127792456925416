import React, { useCallback, useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  useGetProjectManagersQuery,
  useUpdateProjectMutation
} from "@shared/hooks";

import {
  getEditProjectFormFieldsSchema,
  getProjectObjectFromProjectFormData
} from "@app/helpers/projectForm";
import { useGoBack } from "@app/hooks";
import { useProjectFormConfig } from "@app/hooks/useProjectFormConfig";

import { Inline } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import ProjectForm from "@components/molecules/ProjectForm/ProjectForm";
import FormPageTemplate from "@components/templates/FormPageTemplate";

function UpdateProjectForm({ project, isLoading }) {
  const { t } = useTranslation();
  const goBack = useGoBack();
  const {
    entitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    projectExternalIdEnabled,
    startDateLabel,
    endDateLabel
  } = useProjectFormConfig({
    engagementTypeConfig: project?.configuration,
    t
  });
  const [
    updateProject,
    { isLoading: isSubmitting, error, isError, isSuccess }
  ] = useUpdateProjectMutation();

  const { data: projectManagers } = useGetProjectManagersQuery(null, {
    skip: !projectManagerEnabled
  });

  const yupSchema = yup.object(getEditProjectFormFieldsSchema(t));

  const handleUpdate = useCallback(
    data => {
      const updatedProject = {
        id: project?.id,
        engagementId: project?.engagementId,
        ...getProjectObjectFromProjectFormData(
          data,
          {
            entitiesEnabled,
            projectManagerEnabled,
            projectYearEnabled,
            projectExternalIdEnabled,
            isUpdate: true
          },
          t
        )
      };
      updateProject({ project: updatedProject });
    },
    [
      entitiesEnabled,
      project,
      projectManagerEnabled,
      projectYearEnabled,
      t,
      updateProject
    ]
  );

  const handleCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  useEffect(() => {
    if (isSuccess) {
      goBack();
    }
  }, [goBack, isSuccess]);

  const renderPageActions = isValid => {
    return (
      <Inline gap="300">
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={handleCancel}
        />
        <Button
          label={t("common:ui.projects.update.buttonLabel")}
          disabled={!isValid}
          type="submit"
        />
      </Inline>
    );
  };

  return project ? (
    <FormPageTemplate
      title={t("common:ui.projects.title_update")}
      sticky
      other={{ project }}
      isLoading={isLoading}
      form={{
        yupSchema,
        handleSubmit: handleUpdate,
        handleCancel,
        cancelLabel: t("common:ui.forms.cancel.label"),
        disabled: isSubmitting || isSuccess,
        renderPageActions,
        contents: (
          <ProjectForm
            project={project}
            showUpdateFieldsOnly
            enableProjectManager={projectManagerEnabled}
            enableProjectYear={projectYearEnabled}
            enableProjectExternalId={projectExternalIdEnabled}
            startDateLabel={startDateLabel}
            endDateLabel={endDateLabel}
            enableEntities={entitiesEnabled}
            title={t("common:ui.project.title_formTitle")}
            projectManagers={projectManagers}
            isError={isError}
            error={error}
          />
        )
      }}
      renderRawForm={true}
    />
  ) : (
    <></>
  );
}

UpdateProjectForm.propTypes = {
  project: PropTypes.object,
  isLoading: PropTypes.bool
};

export default UpdateProjectForm;
