/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";

import { useLazyGetClientAccessTokenQuery } from "@shared/services/tokenService";

import { usePubSubStore } from "@app/stores/usePubSubStore";

export default function usePubSub() {
  const [data, setData] = useState(null);
  const [events, setEvents] = useState([]);
  const socket = usePubSubStore(state => state.socket);
  const setSocket = usePubSubStore(state => state.setSocket);
  const setupSocket = usePubSubStore(state => state.setupSocket);
  const [getClientAccessToken] = useLazyGetClientAccessTokenQuery();
  const onMessage = useCallback(
    event => {
      try {
        const parsed = JSON.parse(event.data);
        if (events.some(e => e === parsed.subject)) {
          setData({ ...parsed.data, timeStamp: event.timeStamp });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [events]
  );

  const onClose = useCallback(() => {
    setSocket(null);
    getClientAccessToken()
      .unwrap()
      .then(result => {
        setSocket(null);
        setupSocket({ url: result.url });
      });
  }, [getClientAccessToken, setSocket, setupSocket]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.removeEventListener("message", onMessage);
    socket.removeEventListener("close", onClose);
    socket.addEventListener("message", onMessage);
    socket.addEventListener("close", onClose);
    return () => {
      // clear event listeners when unmount
      socket?.removeEventListener("message", onMessage);
      socket?.removeEventListener("close", onClose);
    };
  }, [onClose, onMessage, socket]);

  const startPubSub = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    getClientAccessToken(null, true)
      .unwrap()
      .then(({ url }) => setupSocket({ url }))
      .catch(error => {
        socket?.close();
        setSocket(null);
        console.error(error);
      });
  }, [getClientAccessToken, setSocket, setupSocket, socket]);

  const subscribe = useCallback(
    eventName => {
      if (!socket) {
        startPubSub();
      }
      setEvents(prevEvents => {
        if (prevEvents.some(e => e === eventName)) {
          return prevEvents;
        }
        return [...prevEvents, eventName];
      });
    },
    [socket, startPubSub]
  );

  const unsubscribe = useCallback(
    eventName => {
      setEvents(e => {
        const eventsIndex = events.findIndex(e => e === eventName);
        if (eventsIndex !== -1) {
          e.splice(eventsIndex, 1);
          return e;
        }
      });
    },
    [events]
  );

  return {
    eventNames: events,
    value: data,
    subscribe,
    unsubscribe
  };
}
