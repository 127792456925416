import { useCallback, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { routeConstants } from "@constants";

export function useLastKnownPath(skipSavePath = false) {
  const location = useLocation();
  const lastKnownPathJSON = sessionStorage.getItem("lastKnownPath") ?? "{}";
  const lastKnownPath = JSON.parse(lastKnownPathJSON);

  const handlePreviousPath = useCallback(
    (previousLastKnownPath, removeLastKnownPath = false) => {
      if (location.pathname !== routeConstants.notFound && !skipSavePath) {
        // Previous path is the path before lastKnownPath
        // current -> invalid -> 404 (we want to go to current, not invalid)
        sessionStorage.setItem("previousPath", previousLastKnownPath);
        if (removeLastKnownPath) {
          sessionStorage.removeItem("lastKnownPath");
        }
      }
    },
    [location.pathname, skipSavePath]
  );

  const handleLastKnownPath = useCallback(() => {
    if (
      !skipSavePath &&
      location.pathname !== routeConstants.editDocument &&
      location.pathname !== routeConstants.notFound
    ) {
      sessionStorage.setItem(
        "lastKnownPath",
        JSON.stringify({ ...location, baseURL: window.location.hostname })
      );
    }
  }, [location, skipSavePath]);

  useEffect(() => {
    handleLastKnownPath();
    return () => {
      window.removeEventListener("beforeunload", handlePreviousPath);
      if (lastKnownPath.pathname !== location.pathname) {
        handlePreviousPath(lastKnownPathJSON, true);
      }
    };
  }, [
    handleLastKnownPath,
    handlePreviousPath,
    lastKnownPath.pathname,
    lastKnownPathJSON,
    location.pathname
  ]);
}
