import React, { useEffect } from "react";

import { isEqual } from "lodash";
import { useFormContext } from "react-hook-form";

type Props = {
  name: string;
  value: string | number | boolean | undefined | null;
};

function HiddenInput(props: Readonly<Props>) {
  const { name, value } = props;
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(name);
    if (!isEqual(currentValue, value)) {
      setValue(name, value);
    }
  }, [getValues, name, setValue, value]);

  return <input {...register(name, { value })} hidden />;
}

export default HiddenInput;
