import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import { Button, ButtonVariant } from "@atoms/Button";

import CleanupWizard from "./CleanupWizard";

export function useCleanupWizard() {
  const { t } = useTranslation();
  const { ref, callbackRef } = useCallbackRef();

  const getStepDescription = useCallback(() => {
    return t(
      "common:ui.websheet.actions.cleaningWizard.step.cleanupStep.description"
    );
  }, [t]);

  const getStepButtons = useCallback(
    ({ onNext, disableProgression, completeWizardActionButtons, context }) => {
      const canAppendMoreData =
        !context?.isReplay &&
        context?.numSheets - context?.numSheetsProcessed >= 1;

      return (
        <>
          {canAppendMoreData ? (
            <Button
              variant={ButtonVariant.SECONDARY}
              iconName="add"
              label={t(
                "common:ui.websheet.actions.cleaningWizard.appendMoreData"
              )}
              onClick={() => {
                onNext({ loop: true });
              }}
              disabled={disableProgression}
            />
          ) : (
            <></>
          )}
          {completeWizardActionButtons()}
        </>
      );
    },
    [t]
  );

  const getWizard = useCallback(
    commonWizardProps => {
      return <CleanupWizard {...commonWizardProps} ref={callbackRef} />;
    },
    [callbackRef]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  const cleanup = useCallback(() => {
    ref?.cleanup();
  }, [ref]);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission,
    cleanup,
    getStepButtons
  };
}
