import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks";

import { Box, Inline, Stack } from "@fermions";

import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import "./ApproveRejectQueryFlag.scss";

const responseTypes = systemConstants.project.queries.responses.responseTypes;
const flagTypes = systemConstants.project.queries.flags;

interface ApproveRejectQueryFlagProps {
  query: unknown;
  queryResponses: unknown[];
}

export const ApproveRejectQueryFlag = ({
  query,
  queryResponses
}: ApproveRejectQueryFlagProps) => {
  const { locale, options } = useLocaleDate();
  const { t } = useTranslation();
  const mostRecentApproveReject = useMemo(() => {
    if (query.flag === flagTypes.approved) {
      return queryResponses?.find(
        response => response.responseType === responseTypes.approve
      );
    } else if (query.flag === flagTypes.rejected) {
      return queryResponses?.find(
        response => response.responseType === responseTypes.reject
      );
    }
  }, [query.flag, queryResponses]);

  if (!mostRecentApproveReject) {
    return <></>;
  }
  return (
    <Stack
      alignment="left"
      gap="050"
      className={[
        "approve-reject-query-flag",
        `approve-reject-query-flag--${mostRecentApproveReject.responseType}`
      ]}
      width="fill"
    >
      <Inline className="approve-reject-query-flag__detail" width="fill">
        <Box className="approve-reject-query-flag__user">
          {`${mostRecentApproveReject.user.firstname} ${mostRecentApproveReject.user.lastname}`}
        </Box>

        <Box className="approve-reject-query-flag__date">
          {dateFormatter(
            mostRecentApproveReject.updatedAt,
            locale,
            options.longFormat
          )}
        </Box>
      </Inline>
      <Inline alignment="left" gap="050" wrap>
        {query.flag === flagTypes.approved && (
          <Pill
            label={t("requests:requests.configured.flags_APPROVED")}
            variant={PillVariant.SUCCESS}
            iconName="check_circle"
            fillStyle={PillFillStyle.FILLED}
          />
        )}
        {query.flag === flagTypes.rejected && (
          <Pill
            label={t("requests:requests.configured.flags_REJECTED")}
            variant={PillVariant.ERROR}
            iconName="cancel"
            fillStyle={PillFillStyle.FILLED}
          />
        )}
        {mostRecentApproveReject.response && (
          <Box className="approve-reject-query-flag__comment">
            {mostRecentApproveReject.response}
          </Box>
        )}
      </Inline>
    </Stack>
  );
};
