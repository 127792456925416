import React, { createContext, useState } from "react";

import { StoreApi, createStore, useStore } from "zustand";

import { WorkflowConfig } from "@app/types/ActionItemType.ts";

export interface WorkflowCanvasState {
  hoveredNodeId?: string;
  setHoveredNodeId: (nodeId?: string) => void;
  selectedNodeId?: string;
  setSelectedNodeId: (nodeId?: string) => void;
  hoveredLaneId?: string;
  setHoveredLaneId: (laneId?: string) => void;
  selectedLaneId?: string;
  setSelectedLaneId: (laneId?: string) => void;
  hoveredDividerId?: string;
  setHoveredDividerId: (dividerId?: string) => void;
  workflowConfig?: WorkflowConfig;
  setWorkflowConfig: (config?: WorkflowConfig) => void;
}

const createDefaultStore = () =>
  createStore<WorkflowCanvasState>()(set => ({
    hoveredNodeId: undefined,
    setHoveredNodeId: (nodeId?: string) =>
      set(() => ({ hoveredNodeId: nodeId })),
    selectedNodeId: undefined,
    setSelectedNodeId: (nodeId?: string) =>
      set(() => ({ selectedNodeId: nodeId })),
    hoveredLaneId: undefined,
    setHoveredLaneId: (laneId?: string) =>
      set(() => ({ hoveredLaneId: laneId })),
    selectedLaneId: undefined,
    setSelectedLaneId: (laneId?: string) =>
      set(() => ({ selectedLaneId: laneId })),
    hoveredDividerId: undefined,
    setHoveredDividerId: (dividerId?: string) =>
      set(() => ({ hoveredDividerId: dividerId })),
    workflowConfig: undefined,
    setWorkflowConfig: (config?: WorkflowConfig) =>
      set(() => ({ workflowConfig: config }))
  }));

interface WorkflowCanvasProviderProps {
  children: React.ReactNode;
}

export const WorkflowCanvasContext =
  createContext<StoreApi<WorkflowCanvasState>>(createDefaultStore());

export const WorkflowCanvasProvider = (props: WorkflowCanvasProviderProps) => {
  const [store] = useState(() => createDefaultStore());

  return (
    <WorkflowCanvasContext.Provider value={store}>
      {props.children}
    </WorkflowCanvasContext.Provider>
  );
};

export const useWorkflowCanvas = (): WorkflowCanvasState => {
  const store = React.useContext(WorkflowCanvasContext);
  return useStore(store);
};
