import React from "react";

import { Icon, IconSize } from "@atoms/Icon";

import "./SomethingWentWrong.scss";

const SomethingWentWrong = () => {
  return (
    <section className="somethingWentWrong">
      <Icon
        className="somethingWentWrong__icon"
        name="sentiment_dissatisfied"
        size={IconSize.L}
      />
      <p className="somethingWentWrong__message">Something went wrong</p>
      <p className="somethingWentWrong__description">
        <a href="/">Click here</a> to go back
      </p>
    </section>
  );
};
export default SomethingWentWrong;
