import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const aiCleaningAssistantService = createApi({
  reducerPath: "aiCleaningAssistantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  endpoints: builder => ({
    getAiCleaningAssistant: builder.query({
      query: ({ documentId, documentRevisionId, extra }) => ({
        url: `/api/aiCleaningAssistant/${documentId}`,
        method: "POST",
        body: { documentRevisionId, extra }
      })
    })
  })
});

export const {
  useLazyGetAiCleaningAssistantQuery,
  useGetAiCleaningAssistantQuery
} = aiCleaningAssistantService;
