import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementsActions } from "@shared/actions/manageClientEngagementsActions";

export function useRemoveEngagementMember() {
  const dispatch = useDispatch();
  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );

  const resetRemoveMember = useCallback(() => {
    dispatch(manageClientEngagementsActions.resetRemovedMember());
  }, [dispatch]);

  const removeMember = useCallback(
    (engagement, user) => {
      resetRemoveMember();
      dispatch(
        manageClientEngagementsActions.removeMember({
          engagement,
          member: user
        })
      );
    },
    [dispatch, resetRemoveMember]
  );

  useEffect(() => {
    return () => resetRemoveMember();
  }, [resetRemoveMember]);

  return {
    removeMember,
    resetRemoveMember,
    isRemovingMember: manageClientEngagements.isRemovingMember,
    removedMember: manageClientEngagements.removedMember,
    removeMemberError: manageClientEngagements.removeMemberError
  };
}
