import * as React from "react";
const SvgCube = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 500 562", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  fillOpacity: 1,
  stroke: "#9dc2ec",
  strokeWidth: 50,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1,
  paintOrder: "stroke markers fill"
}, d: "m 25,136.99971 v 288 l 225,112 224,-112 v -288 L 250,24.999706 Z", id: "path2292" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  fillOpacity: 1,
  stroke: "#9dc2ec",
  strokeWidth: 50,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1,
  paintOrder: "stroke markers fill"
}, d: "M 25,136.99971 248.27725,251.02394 474,136.99971", id: "path2294" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  fillOpacity: 1,
  stroke: "#9dc2ec",
  strokeWidth: 50,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1,
  paintOrder: "stroke markers fill"
}, d: "M 248.27725,251.02394 250,536.99971", id: "path2296" }));
export default SvgCube;
