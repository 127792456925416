export function formatAuditHistory(audit, prevAudits) {
  let change = [];
  if (audit.action === "CREATE") {
    change.push("Created");
  } else {
    if (audit.status !== prevAudits.status) {
      change.push(`WorkPaper set to ${audit.status}`);
    }
    if (audit.flag !== prevAudits.flag) {
      let changeText = `${audit.flag}`;
      if (audit.flag === "REJECTED" && audit.rejectionReason !== "") {
        changeText += ` because \"${audit.rejectionReason}\"`;
      }
      change.push(changeText);
    }
    if (audit.description !== prevAudits.description) {
      change.push(`Short Description changed to \"${audit.description}\"`);
    }

    const labelEquality =
      audit.labels.length === prevAudits.labels.length &&
      audit.labels.every(a => prevAudits.labels.includes(a));

    if (!labelEquality) {
      change.push(`Labels changed to \"${audit.labels}\"`);
    }
    if (audit.assignedTo !== prevAudits.assignedTo) {
      change.push(`Assigned to \"${audit.assignedTo}\"`);
    }
  }
  return change;
}
