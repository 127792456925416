export const manageProjectConstants = {
  GET_PROJECT_TODOS_REQUEST: "GET_PROJECT_TODOS_REQUEST",
  GET_PROJECT_TODOS_SUCCESS: "GET_PROJECT_TODOS_SUCCESS",
  GET_PROJECT_TODOS_FAILURE: "GET_PROJECT_TODOS_FAILURE",

  GET_PROJECT_MILESTONES_REQUEST: "GET_PROJECT_MILESTONES_REQUEST",
  GET_PROJECT_MILESTONES_SUCCESS: "GET_PROJECT_MILESTONES_SUCCESS",
  GET_PROJECT_MILESTONES_FAILURE: "GET_PROJECT_MILESTONES_FAILURE",

  GET_PROJECT_DOCUMENTS_REQUEST: "GET_PROJECT_DOCUMENTS_REQUEST",
  GET_PROJECT_DOCUMENTS_SUCCESS: "GET_PROJECT_DOCUMENTS_SUCCESS",
  GET_PROJECT_DOCUMENTS_FAILURE: "GET_PROJECT_DOCUMENTS_FAILURE",

  GET_PROJECT_DOCUMENT_REQUEST: "GET_PROJECT_DOCUMENT_REQUEST",
  GET_PROJECT_DOCUMENT_SUCCESS: "GET_PROJECT_DOCUMENT_SUCCESS",
  GET_PROJECT_DOCUMENT_FAILURE: "GET_PROJECT_DOCUMENT_FAILURE",

  GET_PROJECT_FILE_REQUEST: "GET_PROJECT_FILE_REQUEST",
  GET_PROJECT_FILE_SUCCESS: "GET_PROJECT_FILE_SUCCESS",
  GET_PROJECT_FILE_FAILURE: "GET_PROJECT_FILE_FAILURE",

  REORDER_PROJECT_MEMBER_REQUEST: "REORDER_PROJECT_MEMBER_REQUEST",
  REORDER_PROJECT_MEMBER_SUCCESS: "REORDER_PROJECT_MEMBER_SUCCESS",
  REORDER_PROJECT_MEMBER_FAILURE: "REORDER_PROJECT_MEMBER_FAILURE",

  ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST",
  ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS",
  ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE",

  RESET_UPDATE_PROJECT_TODO_STATUS_FLAG:
    "RESET_UPDATE_PROJECT_TODO_STATUS_FLAG",

  CLEAR_ERROR: "CLEAR_ERROR_PROJECT",
  RESET_DOCUMENT: "RESET_DOCUMENT",
  RESET: "RESET_PROJECT",

  SET_CURRENT_PROJECT: "SET_CURRENT_PROJECT",
  RESET_CURRENT_PROJECT: "RESET_CURRENT_PROJECT"
};
