import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientTagsService = {
  getClientTags,
  createClientTag,
  updateClientTag,
  deleteClientTag
};

function getClientTags(client) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${client.id}/tags`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createClientTag({ id: clientId }, tag) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };

  return fetch(`${baseURL}/api/clients/${clientId}/tags`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateClientTag(client, tag) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };

  return fetch(
    `${baseURL}/api/clients/${client.id}/tags/${tag.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteClientTag(client, tag) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };

  return fetch(
    `${baseURL}/api/clients/${client.id}/tags/${tag.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
