import React, { useState } from "react";

import { t } from "i18next";
import { withTranslation } from "react-i18next";

import { smartFormHelper } from "@app/helpers/smartForm";

import { MultiSelectInput } from "@molecules/inputs";

import { DropdownItemType } from "../DropdownList/DropdownHelper";
import "./SmartFormModal.scss";

type SmartFormEntitiesSelectorProps = {
  row: object;
  allowSameEntities: boolean;
  entities: Array<{ name: string; value: string }>;
  data: Array<object>;
  disableAnswerSubmissions: boolean;
  checkAnswerStatus: (docId: string) => {
    isCompleted: boolean;
    isCleaned: boolean;
    isRestored: boolean;
  };
  handleEntitiesChange: (entities: Array<object>, rowId: number) => void;
};

export const SmartFormEntitiesSelector = ({
  row,
  allowSameEntities,
  entities,
  data,
  disableAnswerSubmissions,
  checkAnswerStatus,
  handleEntitiesChange
}: SmartFormEntitiesSelectorProps) => {
  const [selectedEntities, setSelectedEntities] = useState(
    row.original.entities
  );

  const onSelectedValuesChanged = (selectedValues: DropdownItemType[]) => {
    setSelectedEntities(selectedValues);
  };

  const status = checkAnswerStatus(row.original.value?.id);
  const items = allowSameEntities
    ? entities
    : smartFormHelper.getEntitiesWithNoAnswer(entities, data, row.index);

  return (
    <MultiSelectInput
      placeholder={t("requests:requests.ui.smartForm.modal.multiselect.label")}
      items={items}
      onClose={() => {
        handleEntitiesChange(selectedEntities, row.index);
      }}
      disabled={
        disableAnswerSubmissions ||
        (status.isCompleted && status.isCleaned) ||
        row.original.value.isRestored
      }
      onChange={e => {
        onSelectedValuesChanged(e);
      }}
      selectedValues={selectedEntities}
    />
  );
};

export default withTranslation()(SmartFormEntitiesSelector);
