export const smartFormResponseType = {
  BOOLEAN: "boolean",
  TEXT: "text",
  SINGLE_CHOICE: "singleChoice",
  DATE: "date",
  DOCUMENT: "document",
  WEBSHEET: "websheet",
  NUMBER: "number"
};

export const smartFormUpdatedBy = {
  OT: "OT",
  API: "API"
};

export const smartFormProgressStates = {
  NOT_STARTED: "notstarted",
  IN_PROGRESS: "inprogress",
  COMPLETED: "completed",
  ERROR: "error"
};

export const smartFormActions = {
  OPEN_ENTITIES_MODAL: "openEntitiesModal",
  SAVE_ANSWERS: "saveAnswers",
  SUPPLY_ANSWER: "supplyAnswer",
  OPEN_COMMENT_MODAL: "openCommentModal",
  FILL_WEBSHEET: "fillWebsheet"
};
