import React, { useEffect } from "react";

import { get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { ToggleInput } from "@molecules/inputs/ToggleInput/ToggleInput.tsx";

interface ToggleSwitchProps {
  name: string;
  defaultValue?: boolean;
  disabled?: boolean;
  label?: string;
  required?: boolean;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const {
    getValues,
    setValue,
    formState: { errors },
    control
  } = useFormContext();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: props.required }}
      render={({ field: { onChange, ref } }) => {
        return (
          <ToggleInput
            value={getValues(props.name) ?? false}
            onChange={onChange}
            ref={ref}
            disabled={props.disabled}
            label={props.label}
            hideLabel={!props.label}
            required={props.required}
            error={get(errors, props.name?.split("."))?.message ?? ""}
          />
        );
      }}
    />
  );
};
export default ToggleSwitch;
