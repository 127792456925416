import React from "react";

import { useTranslation } from "react-i18next";

import { CrudFormMode } from "@app/types/index.ts";

import Icon, {
  IconColor,
  IconDesignStyle,
  IconFillStyle,
  IconSize
} from "@atoms/Icon/Icon.tsx";
import { Text } from "@atoms/Text";
import { FontColor, FontSize, FontWeight } from "@atoms/Text/Text.tsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

interface AvailableToProps {
  content: string[];
  crudFormMode: CrudFormMode;
}

export const AvailableTo = ({ content }: AvailableToProps) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Inline gap="050">
        <Icon
          name="group"
          designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
          fillStyle={IconFillStyle.FILLED}
          color={IconColor.TEXT_SECONDARY}
          size={IconSize.S}
        />
        <Text
          text={t(
            "admin:ui.requestTypes.workflowAction.restrictions.availableTo.title"
          )}
          size={FontSize.S}
          weight={FontWeight.SEMI_BOLD}
        />
      </Inline>
      <Stack gap="025">
        {content?.map((item: string) => {
          return (
            <Text
              key={item}
              text={item}
              size={FontSize.S}
              color={FontColor.SECONDARY}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
