import React, { useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks";

import HoverText from "@components/atoms/HoverText";

import "./MilestoneIcon.scss";

const MilestoneIcon = props => {
  const {
    type,
    isShowDate,
    isShowLabel,
    style,
    onClickMilestone,
    milestone,
    milestoneIndex,
    totalNumMilestones,
    milestoneName,
    t
  } = props;
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const [showHoverText, setShowHoverText] = useState(false);
  const typeClassName = `milestone-item__icon--${type}`;

  const getHoverTextPosition = milestone => {
    if (milestone.type == systemConstants.project.milestone.type.standard) {
      if (totalNumMilestones <= 8) {
        if (milestoneIndex <= 1) {
          return "top";
        } else if (totalNumMilestones - milestoneIndex <= 2) {
          return "top-left";
        } else {
          return "top-mid";
        }
      } else if (milestoneIndex <= 4) {
        return "top";
      } else if (totalNumMilestones - milestoneIndex <= 4) {
        return "top-left";
      } else {
        return "top-mid";
      }
    }
    return `${
      milestone.type == systemConstants.project.milestone.type.end
        ? "top-left"
        : "top"
    }`;
  };

  const milestoneStatus = t(
    `common:ui.projectSummary.milestoneHover.${milestone.status}.label`
  );

  const milestoneDate = dateFormatter(milestone.date, locale, shortFormat);

  return (
    <div
      className={`milestone-item`}
      style={style}
      onClick={() => onClickMilestone(milestone)}
    >
      {isShowDate && (
        <div className="milestone-item__label">
          <span
            className="milestone-item__label__date"
            data-testid="milestone-date"
          >
            {milestoneDate}
          </span>
          {isShowLabel && (
            <span
              className="milestone-item__label__name"
              data-testid="milestone-name"
              title={milestoneName}
            >
              {milestoneName}
            </span>
          )}
        </div>
      )}
      <i
        data-testid="milestone-flag"
        className={`material-icons milestone-item__icon ${typeClassName}`}
        onMouseEnter={() => setShowHoverText(true)}
        onMouseLeave={() => setShowHoverText(false)}
      >
        flag
      </i>
      <HoverText
        name={`${milestoneName} (${milestoneDate}) - ${milestoneStatus}`}
        position={getHoverTextPosition(milestone)}
        isActive={showHoverText}
        maxWidth={900}
      />
    </div>
  );
};

MilestoneIcon.defaultProps = {
  type: "open",
  isShowDate: false,
  isShowLabel: false
};

MilestoneIcon.propTypes = {
  type: PropTypes.string,
  isShowDate: PropTypes.bool,
  isShowLabel: PropTypes.bool,
  milestoneName: PropTypes.string
};

export default withTranslation()(MilestoneIcon);
