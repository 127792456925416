import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { AppInsights } from "./AppInsights";

const otOptions = {
  loadPath: `${process.env.REACT_APP_ONETEAM_BE_SERVER}/api/i18n/resources?lng={{lng}}&ns={{ns}}`
};

const missingKeyHandler = async (lng, ns, key, fallbackValue) => {
  const { appInsights } = await AppInsights({});
  if (!fallbackValue.includes(" ") && fallbackValue.includes(".")) {
    appInsights?.trackTrace({
      message: JSON.stringify({
        errorMessage: "Locize key missing.",
        properties: {
          lng,
          ns,
          key,
          fallbackValue,
          location: window.location.href
        }
      })
    });
  }
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      saveMissing: true, // This needs to be true for missing keys to be logged
      missingKeyHandler,
      lng: "en",
      fallbackLng: "en",
      ns: [
        "common",
        "requests",
        "risks",
        "interactiveReport",
        "api",
        "admin",
        "roles"
      ],
      appendNamespaceToCIMode: true,
      defaultNS: "common",
      load: "languageOnly",
      backend: otOptions,
      debug: !!process.env.REACT_APP_LOCIZE_DEBUG
    },
    null
  );
i18n.services.formatter.add("lowercase", value => value?.toLowerCase());
i18n.services.formatter.add("uppercase", value => value?.toUpperCase());
i18n.services.formatter.add("propercase", (value, _, options) => {
  return value?.length
    ? value
        .toLowerCase()
        .split(" ")
        .map(function (word, index) {
          if (
            options?.escapeArticle &&
            index === 0 &&
            (word === "a" || word === "an")
          ) {
            return word;
          }
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ")
    : value;
});
i18n.services.formatter.add("capitalize", value => {
  return value?.length
    ? `${value.substr(0, 1).toUpperCase()}${value.toLowerCase().substr(1)}`
    : value;
});

export default i18n;
