import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageQueryResponsesService = {
  getQueryResponses,
  addQueryResponse,
  updateQueryResponse
};

function getQueryResponses(queryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/queries/${queryId}/queryResponses`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addQueryResponse(queryResponse) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(queryResponse)
  };
  return fetch(`${baseURL}/api/queryResponse`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateQueryResponse(queryResponse) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(queryResponse)
  };
  return fetch(
    `${baseURL}/api/queryResponse/${queryResponse.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
