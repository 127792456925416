import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { uploadFileHelper } from "@app/helpers/uploadFileHelper";
import ProgressSpinner from "@components/atoms/ProgressSpinner";
import UploadFile from "@components/molecules/UploadFile";
import { Box, Stack } from "@fermions";
import FileLink from "@components/organisms/FileLink";
import { AuthUser, File } from "@app/types";

type Props = {
  bulkUpdateUsers: (data: FormData) => void;
  handleErrorState: (error: string) => void;
  isUploading: boolean;
  isUploadDisabled: boolean;
  user: AuthUser;
  downloadUrl: string;
};

type Error = {
  message: string;
};

const UploadUsers = ({
  bulkUpdateUsers,
  handleErrorState,
  user,
  isUploading,
  isUploadDisabled,
  downloadUrl
}: Props) => {
  const { t } = useTranslation();

  const handleDropHostUsersFile = useCallback(
    (files: File[]) => {
      if (files.length !== 1) {
        return;
      }
      handleErrorState("");
      const templateFile = files[0];
      const data = new FormData();
      data.append("document", JSON.stringify({ hostId: user.hostId }));
      data.append("file", templateFile);
      bulkUpdateUsers(data);
    },
    [bulkUpdateUsers, handleErrorState, user]
  );

  const handleDropReject = useCallback(
    errors => {
      const error = uploadFileHelper.handleDropRejection(
        errors,
        t("common:ui.uploadBulkUsers.error.fileType.mime"),
        t("common:ui.uploadBulkUsers.error.number.mime")
      );
      handleErrorState(error);
    },
    [t, handleErrorState]
  );

  const handleDownloadError = (error: Error) => {
    handleErrorState(error?.message);
  };

  const renderUploadFile = useCallback(() => {
    if (isUploading) {
      return (
        <span className="upload-users-spinner">
          <ProgressSpinner showPercentage={false} />
        </span>
      );
    } else {
      return (
        <UploadFile
          handleDrop={handleDropHostUsersFile}
          supportedDocumentMimes={["xlsx"]}
          dropMessage={t("common:ui.uploadBulkUsers.drop.message")}
          maxNumberOfFiles={1}
          handleRejection={handleDropReject}
          disabled={isUploadDisabled}
        />
      );
    }
  }, [
    handleDropHostUsersFile,
    handleDropReject,
    isUploadDisabled,
    isUploading,
    t
  ]);

  return (
    <Stack gap="100">
      <Box key={"bulkUser"} className="ot-query-actions-items">
        <FileLink
          key={"bulkUser"}
          label={t("common:ui.uploadBulkUsers.download.label")}
          apiUrl={downloadUrl}
          downloadingLabel={t("common:ui.uploadBulkUsers.downloading.label")}
          setErrorMessage={handleDownloadError}
        />
      </Box>
      {renderUploadFile()}
    </Stack>
  );
};

export default UploadUsers;
