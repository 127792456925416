import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExpandButton.scss";

const ExpandButton = ({
  id,
  isActive,
  state,
  type,
  onClick,
  isHidden,
  iconWidth
}) => {
  const { t } = useTranslation();
  const getIcon = () => {
    switch (type) {
      case "drilldown":
        return "expand_circle_down";
      case "newWindow":
        return "open_in_new";
      default:
        return "expand_circle_down";
    }
  };

  const getIconTypeClassName = () => {
    const iconClasses = ["ot-expand-button__icon"];
    if (isHidden) {
      iconClasses.push("ot-expand-button__icon--hidden");
    }
    switch (type) {
      case "drilldown":
        iconClasses.push("ot-expand-button--drilldown");
        break;
      case "newWindow":
        iconClasses.push("ot-expand-button--new-window");
        break;
      default:
        break;
    }
    return iconClasses.join(" ");
  };

  const getTitle = () => {
    switch (type) {
      case "drilldown":
        return t("common:expandButton.drilldown.hoverText");
      case "newWindow":
        return t("expandButton.openInNewWindow.hoverText");
      default:
        return "Drilldown";
    }
  };

  const getIconClassName = () => {
    const iconClasses = ["ot-expand-button__icon"];
    if (type === "drilldown" && isActive) {
      iconClasses.push(
        "ot-expand-button__icon--active",
        "material-symbols-filled"
      );
    } else if (type === "newWindow") {
      iconClasses.push("material-symbols-filled");
    } else if (state === "inactive") {
      iconClasses.push(
        "ot-expand-button__icon--inactive",
        "material-symbols-filled"
      );
    } else {
      iconClasses.push("ot-expand-button__icon material-symbols-outlined");
    }
    return iconClasses.join(" ");
  };

  return (
    <div
      onClick={isHidden ? null : e => onClick(e, id)}
      className={`ot-expand-button ${getIconTypeClassName()}`}
    >
      <i
        title={getTitle()}
        className={getIconClassName()}
        style={{ fontSize: iconWidth }}
      >
        {getIcon()}
      </i>
    </div>
  );
};

ExpandButton.defaultProps = {
  type: "drilldown",
  isHidden: false,
  iconWidth: "1.25rem"
};

ExpandButton.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["drilldown", "newWindow"]),
  isHidden: PropTypes.bool,
  iconWidth: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  state: PropTypes.string
};

export default ExpandButton;
