import React, { useCallback, useMemo, useRef } from "react";

import Handsontable from "handsontable";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { webSheetCell } from "@shared/helpers/webSheetCells";
import websheetUtilities from "@shared/helpers/websheetUtilities";
import { useAuthUser, useWindowSize } from "@shared/hooks";

import TableContent from "../TableContent/TableContent";
import WebsheetSheetTabs from "../WebsheetSheetTabs";

const WizardWebsheet = props => {
  const {
    data,
    firstRowHeader,
    colWidths,
    loading,
    error,
    hotTableComponent: setHotTableComponentRef,
    additionalClassName,
    onCurrentSheetNameChanged,
    rowHeaders,
    colHeaders,
    afterGetRowHeader,
    afterGetColHeader,
    editable,
    onSheetLoading,
    currentSheetName,
    hasErrorInCurrentSheet
  } = props;

  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const { user } = useAuthUser();
  const hotTableComponent = useRef(null);

  const setHotTableComponent = useCallback(
    node => {
      hotTableComponent.current = node;
      setHotTableComponentRef?.(node);
    },
    [setHotTableComponentRef]
  );

  const currentSheet = useMemo(
    () => data?.find(sheet => sheet.sheet === currentSheetName),
    [currentSheetName, data]
  );

  const settings = useMemo(() => {
    if (!currentSheet) {
      return;
    }

    const afterLoadData = () => {
      onSheetLoading(false);
    };

    const sheetData = websheetUtilities.populateData({
      data: structuredClone(currentSheet.data),
      minimumNumberOfRows: firstRowHeader ? 2 : 0
    });

    const height = websheetUtilities.getWebsheetMaxHeight({
      websheetElementId: "handsontable-id",
      browserWindowHeight: windowSize.height
    });

    const settings = {
      ...websheetUtilities.getDefaultSettings(),
      data: sheetData,
      rowHeaders: rowHeaders ?? false,
      colHeaders: colHeaders ?? false,
      dropdownMenu: null,
      manualColumnResize: false,
      disableVisualSelection: true,
      copyable: false,
      stretchH: "last",
      colWidths:
        colWidths ?? websheetUtilities.getColWidths(currentSheet) ?? [],
      afterGetRowHeader,
      afterGetColHeader,
      height: height,
      afterLoadData
    };

    const populatedFormats = websheetUtilities.populateRows(
      structuredClone(currentSheet.formats)
    );
    const cellParams = {
      isHostUser: user?.isHostUser,
      formats: populatedFormats,
      formatsLookup: currentSheet.formatsLookup,
      sheetData,
      currentSheet,
      Handsontable,
      isReadOnly: true,
      additionalClassName,
      t
    };

    if (editable) {
      cellParams.isReadOnly = false;
      settings.readOnly = false;
      settings.disableVisualSelection = false;
      settings.copyable = true;
    }
    if (firstRowHeader) {
      const dataWithoutHeaderRow = sheetData.slice(1);
      settings.data = dataWithoutHeaderRow;
      settings.colHeaders = sheetData[0];
      cellParams.formats = currentSheet.formats.slice(1);
      cellParams.rowOffset = -1;
    }
    settings.cells = hasErrorInCurrentSheet
      ? { className: "border-none" }
      : (row, col) => {
          cellParams.rowIdx = row;
          cellParams.colIdx = col;
          return webSheetCell(cellParams);
        };

    return settings;
  }, [
    currentSheet,
    firstRowHeader,
    rowHeaders,
    colWidths,
    afterGetRowHeader,
    afterGetColHeader,
    user,
    additionalClassName,
    t,
    editable,
    hasErrorInCurrentSheet,
    onSheetLoading,
    colHeaders,
    windowSize
  ]);

  const changeCurrentSheetName = useCallback(
    newSheetName => {
      if (!newSheetName || newSheetName === currentSheetName) {
        return;
      }
      onCurrentSheetNameChanged?.(newSheetName);
      hotTableComponent?.current?.hotInstance?.scrollViewportTo(0, 0);
    },
    [onCurrentSheetNameChanged, currentSheetName]
  );

  return (
    <>
      <TableContent
        settings={settings}
        loading={loading}
        error={error}
        hotTableComponent={setHotTableComponent}
      />
      <WebsheetSheetTabs
        currentSheetName={currentSheetName}
        sheetNames={websheetUtilities.getVisibleSheetNames(data)}
        updateSheet={sheetName => {
          changeCurrentSheetName(sheetName);
        }}
      />
    </>
  );
};

WizardWebsheet.defaultProps = {
  editable: false
};

WizardWebsheet.propTypes = {
  data: PropTypes.array,
  firstRowHeader: PropTypes.bool,
  colWidths: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  rowHeaders: PropTypes.bool,
  colHeaders: PropTypes.bool,
  error: PropTypes.string,
  hotTableComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  additionalClassName: PropTypes.func,
  onCurrentSheetNameChanged: PropTypes.func,
  currentSheetName: PropTypes.string,
  editable: PropTypes.bool,
  onSheetLoading: PropTypes.func,
  afterGetColHeader: PropTypes.func,
  afterGetRowHeader: PropTypes.func,
  hasErrorInCurrentSheet: PropTypes.bool
};

export default WizardWebsheet;
