import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  useBlockLayout,
  useFilters,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import VirtualScroller from "@shared-components/virtualScroller/VirtualScroller";

import UIConfigContext from "@app/helpers/UIConfigContext";
import { classNames } from "@app/helpers/componentHelpers";

import "./DataTable.scss";

/**
 * @type {React.FC<{
 * columns: any[],
 * data: any[]
 * }>}
 */
const DataTable = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const uiConfig = useContext(UIConfigContext);
  const {
    columns,
    data,
    className,
    onRowClick,
    sortFilter,
    onSortColClick,
    onMouseEnterRow,
    onMouseLeaveRow,
    rowClickHandler,
    rowClassNames,
    virtualScrollOptions,
    initialFilters,
    layoutMode,
    hideHeaders,
    selectedRowsChangeHandler,
    title,
    fitContentWidth
  } = props;
  const tableRef = useRef();

  const [showLeftFreezeEdge, setShowLeftFreezeEdge] = useState(false);
  const [showRightFreezeEdge, setShowRightFreezeEdge] = useState(false);

  const handleTableScrollForFreezeEdge = debounce(e => {
    const el = e?.target;
    if (!el) {
      return;
    }
    setShowLeftFreezeEdge(el.scrollLeft > 0);
    setShowRightFreezeEdge(el.scrollLeft + el.offsetWidth < el.scrollWidth);
  }, 250);

  useEffect(() => {
    const el = tableRef.current;
    if (!el || layoutMode !== "freeze") {
      return;
    }
    setShowLeftFreezeEdge(el.scrollLeft > 0);
    setShowRightFreezeEdge(el.scrollLeft + el.offsetWidth < el.scrollWidth);
  }, [tableRef, layoutMode]);

  const initialState = {
    hiddenColumns: columns.filter(c => c.isHidden).map(c => c.id || c.accessor),
    filters: initialFilters,
    autoResetHiddenColumns: false
  };

  const defaultColumn = React.useMemo(
    () => ({
      disableSortBy: true,
      disableFilters: true,
      width: 50
    }),
    []
  );

  const getRowId = useCallback(row => row?.original?.id ?? row?.id, []);

  const instance = useTable(
    {
      columns,
      data,
      initialState,
      defaultColumn,
      manualSortBy: !!sortFilter
    },
    useFilters,
    useSortBy,
    useRowSelect,
    layoutMode === "freeze" ? useBlockLayout : useFlexLayout
  );
  useImperativeHandle(ref, () => instance);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows
  } = instance;
  const headers = headerGroups[0]?.headers;

  const handleRowClick = useCallback(
    (e, row) => {
      if (onRowClick) {
        e.preventDefault();
        e.stopPropagation();
        onRowClick(getRowId(row));
      } else if (rowClickHandler?.isClickable(getRowId(row))) {
        e.preventDefault();
        e.stopPropagation();
        rowClickHandler.handler(getRowId(row), row?.original);
      }
    },
    [getRowId, onRowClick, rowClickHandler]
  );

  useEffect(() => {
    if (selectedRowsChangeHandler) {
      const selectedRows = selectedFlatRows?.map(d => d.original) ?? [];
      selectedRowsChangeHandler(selectedRows);
    }
  }, [selectedRowsChangeHandler, selectedFlatRows]);

  const handleCellClick = useCallback((e, cell) => {
    if (cell.column.clickableCell) {
      e.preventDefault();
      const allowPropagation = cell.column.clickableCell({ cell });
      if (!allowPropagation) {
        e.stopPropagation();
      }
    }
  }, []);

  const getHeadingClassNames = useCallback(
    column =>
      classNames([
        "data-table__heading",
        column.className ? `data-table__heading-${column.className}` : null,
        column.freeze?.position
          ? `data-table__heading--freeze-${column.freeze?.position}`
          : null,
        column.freeze?.isEdge ? `data-table__heading--freeze-edge` : null
      ]),
    []
  );

  const getRowClassNames = useCallback(
    row => {
      const names = [
        "data-table__row",
        ...(rowClassNames?.(getRowId(row)) ?? []),
        row.original?.unsaved ? `data-table__row--unsaved` : null,
        row.original?.isHidden ? `data-table__row--hide` : null,
        row.original?.highlighted ? `data-table__row--highlighted` : null
      ];
      if (onRowClick || rowClickHandler?.isClickable(getRowId(row))) {
        names.push("data-table__row--clickable");
      }

      return classNames(names);
    },
    [getRowId, onRowClick, rowClassNames, rowClickHandler]
  );

  const cellClassNames = cell =>
    classNames([
      "data-table__data",
      cell.column.className
        ? `data-table__data-${cell.column.className}`
        : null,
      cell.column.clickableCell ? "data-table__data--clickable" : null,
      cell.column.visibleOnHover ? "data-table__data--visibleOnHover" : null,
      cell.column.freeze?.position
        ? `data-table__data--freeze-${cell.column.freeze?.position}`
        : null,
      cell.column.freeze?.isEdge ? `data-table__data--freeze-edge` : null
    ]);

  const sortDirectionIcon = column => {
    if (
      (!sortFilter && !column.isSorted) ||
      (sortFilter && sortFilter.colName !== column.id)
    ) {
      return <i className="material-icons">unfold_more</i>;
    } else if (
      (!sortFilter && column.isSortedDesc) ||
      (sortFilter?.colName === column.id && sortFilter?.sortOrder === "desc")
    ) {
      return <i className="material-icons">keyboard_arrow_down</i>;
    }

    return <i className="material-icons">keyboard_arrow_up</i>;
  };

  const mergeSortAndLayoutProps = (columnProps, styleProps) => {
    return {
      ...columnProps,
      style: {
        ...columnProps.style,
        ...styleProps
      }
    };
  };

  // this allows us reference JS calculated values in CSS without overloading custom styles
  const freezeEdgeOffsets = (columns, layoutMode) => {
    if (layoutMode !== "freeze") {
      return null;
    }

    const leftEdgeColumn = columns.find(
      c => c.freeze?.position === "left" && c.freeze.isEdge
    );
    const freezeEdgeLeft = leftEdgeColumn?.totalLeft + leftEdgeColumn?.width;
    const rightEdgeColumnIdx = columns.findIndex(
      c => c.freeze?.position === "right" && c.freeze.isEdge
    );
    const rightEdgeColumn = columns[rightEdgeColumnIdx];
    const rightSideColumns = columns?.slice(rightEdgeColumnIdx + 1) ?? [];
    const freezeEdgeRight =
      rightSideColumns.reduce((acc, curr) => acc + curr.totalWidth, 0) +
      rightEdgeColumn?.width;

    return {
      ["--table-freeze-left-edge"]: `${freezeEdgeLeft}px`,
      ["--table-freeze-right-edge"]: `${freezeEdgeRight}px`
    };
  };

  const layoutStyleProps = (column, layoutMode, colIdx, allTableColumns) => {
    if (layoutMode === "freeze") {
      // Calculate left/right of us to adjust sticky positioning offsets
      let right = null;
      let left = null;

      switch (column?.freeze?.position) {
        case "right": {
          const rightSideColumns = allTableColumns?.slice(colIdx + 1) ?? [];
          right = rightSideColumns.reduce(
            (acc, curr) => acc + curr.totalWidth,
            0
          );
          break;
        }
        case "left": {
          left = column.totalLeft;
          break;
        }
      }

      let flexGrow = null;
      if (!column.fixedWidth && !column.freeze?.position) {
        if (column?.freeze?.style === "wide") {
          flexGrow = 2;
        } else {
          flexGrow = 1;
        }
      }
      return { left, right, display: "flex", flexGrow };
    }

    return {
      flex: column.fixedWidth ? "none" : `${column.totalFlexWidth} 0 auto`
    };
  };

  const resetSort = column => {
    column.onSortedChange([]);
    if (sortFilter) {
      onSortColClick({ colName: "", sortOrder: "" });
    }
  };

  const applySort = (desc, column) => {
    column.onSortedChange([{ id: column.id, desc }]);
    if (sortFilter) {
      onSortColClick({
        colName: column.id,
        sortOrder: desc ? "desc" : "asc"
      });
    }
  };

  const handleOnClickColumnSort = column => {
    if (column.canSort) {
      const isSortedDesc = column.isSortedDesc;
      let desc;
      if (isSortedDesc) {
        resetSort(column);
      } else {
        desc = isSortedDesc === false ? true : false;
        applySort(desc, column);
      }
      column.toggleSortBy(desc);
    }
  };

  if (tableRef.current) {
    const tableRows = tableRef.current.querySelector(
      virtualScrollOptions ? ".virtual-scroller" : ".data-table__rows"
    );
    const tableHeader = tableRef.current.querySelector(".data-table__header");
    const scrollWidth = tableRows?.offsetWidth - tableRows?.clientWidth;
    if (tableHeader?.style) {
      tableHeader.style.padding = `0 ${scrollWidth ?? 0}px 0 0`;
    }
  }

  const getHeaderStyle = useCallback(() => {
    if (uiConfig?.theme?.designSystem !== "ot") {
      return {};
    }

    const minWidth =
      layoutMode === "freeze" ? { minWidth: instance.totalColumnsWidth } : {};
    // Check if table is parent of most up to date page template
    if (document.getElementById("page-template")) {
      return {
        top: `0px`,
        ...minWidth
      };
    } else {
      return {
        top: `${
          (document
            .getElementsByClassName("heading")[0]
            ?.getBoundingClientRect()?.height || 0) +
          (document
            .getElementsByClassName("sub-nav__content--active")[0]
            ?.getBoundingClientRect()?.height || 0) -
          1 // To not be able to see the top border
        }px`,
        ...minWidth
      };
    }
  }, [layoutMode, uiConfig?.theme?.designSystem, instance.totalColumnsWidth]);

  const renderTitle = () =>
    title && <h4 className="data-table__title">{title}</h4>;

  const renderHeaders = useCallback(
    () =>
      hideHeaders ? (
        <div className="data-table__header data-table__header--hidden"></div>
      ) : (
        headerGroups.map((headerGroup, index) => (
          <div
            key={`h-${index}`}
            {...headerGroup.getHeaderGroupProps({
              style:
                layoutMode === "freeze"
                  ? {
                      width: null,
                      minWidth: instance.totalColumnsWidth
                    }
                  : null
            })}
            className="data-table__header"
            style={getHeaderStyle()}
          >
            {headers.map((column, idx) => (
              <div
                key={`ch-${column.Header}`}
                {...column.getHeaderProps([
                  {
                    ...mergeSortAndLayoutProps(
                      column.getSortByToggleProps(),
                      layoutStyleProps(column, layoutMode, idx, headers)
                    )
                  }
                ])}
                onClick={() => handleOnClickColumnSort(column)}
                className={getHeadingClassNames(column)}
              >
                <div
                  data-testid="testDataTable__heading"
                  className="data-table__header--text"
                  title={column.hoverText ?? ""}
                >
                  {column.render("Header")}
                </div>
                {column.canSort ? (
                  <span>{sortDirectionIcon(column)}</span>
                ) : null}
                {column.canFilter ? (
                  <div
                    data-testid="testDataTable__filter"
                    className="data-table__header__filter"
                  >
                    {column.render("Filter")}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ))
      ),
    [
      getHeaderStyle,
      headerGroups,
      headers,
      getHeadingClassNames,
      hideHeaders,
      instance.totalColumnsWidth,
      layoutMode
    ]
  );

  const renderRow = useCallback(
    (row, idx) => {
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            className: getRowClassNames(row),
            onMouseEnter: onMouseEnterRow
              ? () => onMouseEnterRow?.(row.id)
              : undefined,
            onMouseLeave: onMouseLeaveRow
              ? () => onMouseLeaveRow?.(row.id)
              : undefined,
            style:
              layoutMode === "freeze"
                ? {
                    width: null,
                    minWidth: instance.totalColumnsWidth
                  }
                : null
          })}
          key={`r-${getRowId(row)}-${idx}`}
          onClick={e => handleRowClick(e, row)}
          data-testid={`testDataTableRow-${row.index}`}
        >
          {row.cells.map((cell, cellIndex) => {
            return (
              <div
                {...cell.getCellProps([
                  {
                    style: layoutStyleProps(
                      cell.column,
                      layoutMode,
                      cellIndex,
                      headers
                    )
                  }
                ])}
                key={`c-${cellIndex}_${getRowId(row)}`}
                className={cellClassNames(cell)}
                onClick={e => handleCellClick(e, cell)}
              >
                {cell.render("Cell")}
              </div>
            );
          })}
          {(row.original?.unsaved || false) && (
            <div className="data-table__row__unsaved-message">
              <span className="data-table__row__unsaved-message__text">
                {t("common:ui.unsaved.label")}
              </span>
            </div>
          )}
        </div>
      );
    },
    [
      prepareRow,
      getRowClassNames,
      onMouseEnterRow,
      onMouseLeaveRow,
      layoutMode,
      instance.totalColumnsWidth,
      getRowId,
      t,
      handleRowClick,
      headers,
      handleCellClick
    ]
  );

  return (
    <div
      style={{
        ...freezeEdgeOffsets(instance.columns, layoutMode)
      }}
      className={className}
    >
      <div
        ref={tableRef}
        {...getTableProps({
          onScroll:
            layoutMode === "freeze" ? handleTableScrollForFreezeEdge : null
        })}
        className={classNames([
          "data-table",
          `${
            layoutMode === "freeze" ? "data-table--freeze" : "data-table--flex"
          }`,
          `${fitContentWidth ? "data-table--fit-content-width" : ""}`,
          className
        ])}
        data-testid="testDataTable"
      >
        {renderTitle()}
        {renderHeaders()}
        <div {...getTableBodyProps()} className="data-table__rows">
          {virtualScrollOptions ? (
            <VirtualScroller
              key="vscroll"
              itemHeight={virtualScrollOptions.itemHeight}
              visibleItems={virtualScrollOptions.visibleItems}
              tolerance={virtualScrollOptions.tolerance}
              paddingBottom={virtualScrollOptions.paddingBottom || 0}
              allItems={rows}
              row={renderRow}
            />
          ) : (
            rows.map(renderRow)
          )}
        </div>
      </div>
      {layoutMode === "freeze" && (
        <div className="data-table__freeze-shadow">
          {showLeftFreezeEdge && (
            <div className="data-table__freeze-shadow-left"></div>
          )}
          {showRightFreezeEdge && (
            <div className="data-table__freeze-shadow-right"></div>
          )}
        </div>
      )}
    </div>
  );
});

DataTable.defaultProps = {
  className: "",
  initialFilters: [],
  layoutMode: "flex",
  hideHeaders: false
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // See https://react-table-v7.tanstack.com/docs/api/useTable#column-options
      // Plus we have a few extra properties
      className: PropTypes.string,
      freeze: PropTypes.shape({
        position: PropTypes.oneOf(["left", "right"]),
        isEdge: PropTypes.bool
      }),
      isHidden: PropTypes.bool,
      fixedWidth: PropTypes.bool
    })
  ),
  data: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.number,
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  onSortColClick: PropTypes.func,
  sortFilter: PropTypes.shape({
    colName: PropTypes.string,
    sortOrder: PropTypes.string
  }),
  rowClassNames: PropTypes.func,
  rowClickHandler: PropTypes.shape({
    isClickable: PropTypes.func,
    handler: PropTypes.func
  }),
  virtualScrollOptions: PropTypes.shape({
    itemHeight: PropTypes.number.isRequired,
    visibleItems: PropTypes.number.isRequired,
    tolerance: PropTypes.number.isRequired,
    paddingBottom: PropTypes.number
  }),
  layoutMode: PropTypes.oneOf(["flex", "freeze"]),
  hideHeaders: PropTypes.bool,
  selectedRowsChangeHandler: PropTypes.func,
  title: PropTypes.string,
  fitContentWidth: PropTypes.bool
};

export default DataTable;
