import { manageClientTagsConstants } from "../constants";

const initialState = {
  loading: false,
  clientTags: [],
  addClientTagLoading: false,
  updateClientTagLoading: false,
  updatedClientTag: false,
  addedClientTag: false,
  deletedTag: false,
  error: ""
};

export function manageClientTags(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientTagsConstants.GET_CLIENT_TAGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientTagsConstants.GET_CLIENT_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientTags: action.tags
      };
    case manageClientTagsConstants.GET_CLIENT_TAGS_FAILURE:
      return {
        ...state,
        clientTags: [],
        error: action.error.key ?? action.error.message,
        loading: false
      };
    case manageClientTagsConstants.ADD_CLIENT_TAGS_REQUEST:
      return {
        ...state,
        addClientTagLoading: true
      };
    case manageClientTagsConstants.ADD_CLIENT_TAGS_SUCCESS:
      return {
        ...state,
        addClientTagLoading: false,
        addedClientTag: action.addedClientTag
      };
    case manageClientTagsConstants.ADD_CLIENT_TAGS_FAILURE:
      return {
        ...state,
        addedClientTag: false,
        error: action.error.key ?? action.error.message,
        addClientTagLoading: false
      };
    case manageClientTagsConstants.UPDATE_CLIENT_TAGS_REQUEST:
      return {
        ...state,
        updateClientTagLoading: true
      };
    case manageClientTagsConstants.UPDATE_CLIENT_TAGS_SUCCESS:
      return {
        ...state,
        updateClientTagLoading: false,
        updatedClientTag: action.updatedClientTag
      };
    case manageClientTagsConstants.UPDATE_CLIENT_TAGS_FAILURE:
      return {
        ...state,
        updatedClientTag: false,
        error: action.error.key ?? action.error.message,
        updateClientTagLoading: false
      };
    case manageClientTagsConstants.DELETE_CLIENT_TAGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientTagsConstants.DELETE_CLIENT_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedClientTag: action.deletedClientTag
      };
    case manageClientTagsConstants.DELETE_CLIENT_TAGS_FAILURE:
      return {
        ...state,
        deletedClientTag: false,
        error: action.error.key ?? action.error.message,
        loading: false
      };
    case manageClientTagsConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
