import React from "react";

import { Icon, IconColor, IconFillStyle } from "@atoms/Icon";

function DropdownFilterIcon(props) {
  const { active } = props;
  if (active) {
    return (
      <Icon
        name="filter_alt"
        color={IconColor.ACCENT}
        fillStyle={IconFillStyle.FILLED}
      />
    );
  }
  return <Icon name="filter_alt" />;
}
export default DropdownFilterIcon;
