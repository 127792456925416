// This component is based on: https://github.com/dhilt/react-virtual-scrolling/tree/basics
import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import "./virtualScroller.scss";

const DEFAULT_ITEMS = 10;
const DEFAULT_TOLERANCE = DEFAULT_ITEMS / 2;

const VirtualScroller = props => {
  const {
    allItems,
    itemHeight,
    height,
    visibleItems,
    tolerance = DEFAULT_TOLERANCE,
    paddingBottom = 0
  } = props;

  // number of visible items is calculated if visible height is specified
  const numVisibleItems = Math.floor(
    height ? height / itemHeight : visibleItems || DEFAULT_ITEMS
  );
  const totalHeight = allItems.length * itemHeight;
  const toleranceHeight = tolerance * itemHeight;
  const bufferedItems = Math.min(
    numVisibleItems + 2 * tolerance,
    allItems.length
  ); // number of buffered items,

  const [scrollStatus, setScrollStatus] = useState({
    topPaddingHeight: 0, // current top virtual height
    bottomPaddingHeight: totalHeight, // current bottom virtual height,
    firstIndex: -tolerance, // current first buffered index
    scrollPosition: 0, // current scroll top position
    offset: 0, // current data offset
    data: []
  });
  const viewportElement = useRef(null);

  useEffect(() => {
    viewportElement.current.scrollTop = 0;
    runScroller({ target: { scrollTop: 0 } }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems]);

  const runScroller = ({ target: { scrollTop } }, dataChanged) => {
    const { allItems, itemHeight, tolerance } = props;
    const { scrollPosition, firstIndex, offset, data } = scrollStatus;
    const index = Math.floor((scrollTop - toleranceHeight) / itemHeight);
    const newFirstIndex =
      scrollTop > scrollPosition && index >= firstIndex + tolerance // scrolling down
        ? index // more data needed at the bottom
        : scrollTop < scrollPosition &&
            index <= firstIndex &&
            index <= allItems.length - 1 - bufferedItems // scrolling up
          ? Math.max(-tolerance, index - tolerance) // more data needed at the top
          : firstIndex; // scrolling within buffered range, leave data as is

    const newOffset = Math.max(0, newFirstIndex);

    // only load more data when necessary or if we have no data yet or the data source changed
    if (newOffset !== offset || !data.length || dataChanged) {
      // Debug log only
      // console.log(`Get ${bufferedItems} virtual items from item ${offset} at scroll position ${scrollTop}.`);

      const newData = allItems.slice(newOffset, newOffset + bufferedItems);
      const topPaddingHeight = Math.max(newFirstIndex * itemHeight, 0);
      const bottomPaddingHeight = Math.max(
        totalHeight - topPaddingHeight - newData.length * itemHeight,
        0
      );

      setScrollStatus({
        ...scrollStatus,
        scrollPosition: scrollTop,
        topPaddingHeight,
        bottomPaddingHeight,
        firstIndex: newFirstIndex,
        offset: newOffset,
        data: newData
      });
    }
  };

  return (
    <div
      className="virtual-scroller"
      ref={viewportElement}
      onScroll={runScroller}
      style={{ paddingBottom: paddingBottom }}
    >
      <div style={{ height: scrollStatus.topPaddingHeight }}></div>
      {scrollStatus.data.map(props.row)}
      <div style={{ height: scrollStatus.bottomPaddingHeight }}></div>
    </div>
  );
};

VirtualScroller.propTypes = {
  itemHeight: PropTypes.number,
  height: PropTypes.number,
  visibleItems: PropTypes.number,
  tolerance: PropTypes.number,
  paddingBottom: PropTypes.number,
  allItems: PropTypes.any,
  row: PropTypes.any
};

export default VirtualScroller;
