import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useGetProjectForSelectionWithAllStatusesQuery } from "@shared/hooks";

import { Box } from "@fermions";

import Form from "@components/atoms/Form";
import Loading from "@components/molecules/Loading/Loading";
import FileDownloader from "@components/organisms/FileDownloader/FileDownloader";
import PageTemplate from "@components/templates/PageTemplate";

import "./DataExtraction.scss";

const DataExtraction = () => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState();
  const [downloadParams, setDownloadParams] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data: allProjects = [] } =
    useGetProjectForSelectionWithAllStatusesQuery(null);
  const projectList = useMemo(() => {
    return allProjects.map(p => ({
      name: p.name,
      value: p.id
    }));
  }, [allProjects]);

  const errorHandler = useCallback(message => {
    setDownloadParams(null);
    setErrorMsg(message);
    setIsDownloading(false);
  }, []);

  const downloadingHandler = () => {
    setErrorMsg(null);
    setIsDownloading(false);
  };

  const handleSubmit = useCallback(
    ({ project: { value: projectId } }) => {
      if (isDownloading) {
        return;
      }

      setErrorMsg(null);
      setIsDownloading(true);
      setDownloadParams({
        apiUrl: "audit/download",
        data: {
          projectIds: [projectId]
        },
        sequenceId: new Date().getTime()
      });
    },
    [isDownloading]
  );

  const yupSchema = yup.object({
    project: yup
      .object()
      .required(t("admin:ui.dataExtraction.download.projectSelection.error"))
  });

  return (
    <PageTemplate
      header={{
        title: t("ui.sideNavigation.admin.dataExtraction"),
        alwaysShowBreadcrumbs: true
      }}
      body={{
        primary: (
          <Box className="data-extraction" width="100">
            <Form
              handleSubmit={handleSubmit}
              submitLabel={t("admin:ui.dataExtraction.download.submitLabel")}
              yupSchema={yupSchema}
              disabled={isDownloading}
            >
              <Form.Dropdown
                name="project"
                label={t(
                  "admin:ui.dataExtraction.download.projectSelection.label"
                )}
                items={projectList}
                required
              />
            </Form>
            {isDownloading && (
              <Loading
                message={t("admin:ui.dataExtraction.download.loading")}
              />
            )}
            {downloadParams && (
              <FileDownloader
                downloadParams={downloadParams}
                onError={errorHandler}
                onDownloading={downloadingHandler}
              />
            )}
          </Box>
        )
      }}
      other={{
        error: errorMsg
      }}
    />
  );
};

export default DataExtraction;
