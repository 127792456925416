export const manageClientEngagementsConstants = {
  GET_CLIENT_ENGAGEMENTS_REQUEST: "GET_CLIENT_ENGAGEMENTS_REQUEST",
  GET_CLIENT_ENGAGEMENTS_SUCCESS: "GET_CLIENT_ENGAGEMENTS_SUCCESS",
  GET_CLIENT_ENGAGEMENTS_FAILURE: "GET_CLIENT_ENGAGEMENTS_FAILURE",

  GET_CLIENTS_REQUEST: "GET_CLIENTS_REQUEST",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE: "GET_CLIENTS_FAILURE",

  GET_MEMBERS_TO_ADD_REQUEST: "GET_MEMBERS_TO_ADD_REQUEST",
  GET_MEMBERS_TO_ADD_SUCCESS: "GET_MEMBERS_TO_ADD_SUCCESS",
  GET_MEMBERS_TO_ADD_FAILURE: "GET_MEMBERS_TO_ADD_FAILURE",

  GET_MEMBERS_REQUEST: "GET_MEMBERS_REQUEST",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAILURE: "GET_MEMBERS_FAILURE",

  ADD_MEMBER_REQUEST: "ADD_MEMBER_REQUEST",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAILURE: "ADD_MEMBER_FAILURE",

  REMOVE_MEMBER_REQUEST: "REMOVE_MEMBER_REQUEST",
  REMOVE_MEMBER_SUCCESS: "REMOVE_MEMBER_SUCCESS",
  REMOVE_MEMBER_FAILURE: "REMOVE_MEMBER_FAILURE",

  CLEAR_ERROR: "CLIENT_ENGAGEMENT_CLEAR_ERROR",
  RESET: "CLIENT_ENGAGEMENT_RESET",
  RESET_MEMBERS_TO_ADD: "RESET_MEMBERS_TO_ADD",
  RESET_ADDED_MEMBER: "RESET_ADDED_MEMBER",
  RESET_REMOVED_MEMBER: "RESET_REMOVED_MEMBER",
  RESET_MEMBERS: "RESET_MEMBERS"
};
