const prepareHeaders = (headers, { getState }) => {
  const token =
    getState().authentication?.token || localStorage.getItem("token");
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export default prepareHeaders;
