import { isRejectedWithValue } from "@reduxjs/toolkit";

export const rtkErrorLogger = () => next => action => {
  if (isRejectedWithValue(action)) {
    if (action?.payload?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
  }
  return next(action);
};
