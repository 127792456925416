import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { smartFormHelper } from "@app/helpers/smartForm";

import RadioButton from "@components/atoms/RadioButton/RadioButton";

import "./SmartFormRadioButtons.scss";

const SmartFormRadioButtons = props => {
  const {
    value,
    onChange,
    questionId,
    disabled,
    layout = "horizontal",
    options,
    allowUnselect = false,
    transformBooleans = true
  } = props;

  const formatValue = useCallback(
    v => (transformBooleans ? smartFormHelper.formatRadioButtonKey(v) : v),
    [transformBooleans]
  );

  const handleClearOption = useCallback(
    result => {
      if (!allowUnselect) {
        return;
      }
      const key = formatValue(result.target.value);
      if (key === value) {
        onChange(questionId, null);
      }
    },
    [allowUnselect, formatValue, onChange, questionId, value]
  );

  const handleChangeOption = useCallback(
    result => {
      const key = formatValue(result.target.value);
      onChange(questionId, key);
    },
    [formatValue, onChange, questionId]
  );

  return (
    <div className={`smart-form-radio-btns smart-form-radio-btns--${layout}`}>
      {options.map(option => {
        return (
          <div className="smart-form-radio-btns__btn" key={option.name}>
            <RadioButton
              value={option.value}
              checked={value === formatValue(option.value)}
              onChange={handleChangeOption}
              onClick={handleClearOption}
              testId={`smart-form-radio-button-${option.name}`}
              label={option.name}
              disabled={disabled}
            />
          </div>
        );
      })}
    </div>
  );
};

SmartFormRadioButtons.propTypes = {
  questionId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
  allowUnselect: PropTypes.bool,
  transformBooleans: PropTypes.bool
};
export default SmartFormRadioButtons;
