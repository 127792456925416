import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  context: null,
  isExpanded: false
};

export const secondaryNavSlice = createSlice({
  name: "secondaryNavSlice",
  initialState,
  reducers: {
    toggle: state => {
      state.isExpanded = !state.isExpanded;
    },
    setSecondaryNav: (state, action) => {
      state.isExpanded = !!action.payload.isExpanded;
      state.context = action.payload.context;
    },
    setMenuItemExpanded: (state, action) => {
      state[action.payload.id] = action.payload.isExpanded;
    }
  }
});

export const { toggle, setSecondaryNav, setMenuItemExpanded } =
  secondaryNavSlice.actions;

export default secondaryNavSlice.reducer;
