import React, { useEffect } from "react";

import { Button, ButtonVariant } from "@atoms/Button";

import "./warningMessage.scss";
import { useTranslation } from "react-i18next";

const WarningMessage = props => {
  const { t } = useTranslation();
  const confirmationButtonName = props.confirmationName || "Yes";

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      props.onYes();
    }
    if (event.key === "Escape") {
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  return (
    <div className="warning-message__container">
      <div className="warning-message__container-header">
        <h2>{props.title}</h2>
        <i onClick={props.onCancel} className="material-icons popup__close">
          close
        </i>
      </div>
      <div className="warning-message__container-message">{props.message}</div>
      <div className="warning-message__container-buttons">
        <div className="warning-message__container-buttons-cancel">
          <Button
            variant={ButtonVariant.SECONDARY}
            label={t("common:ui.forms.cancel.label")}
            onClick={props.onCancel}
          />
        </div>
        <div className="warning-message__container-buttons-confirm">
          <Button label={confirmationButtonName} onClick={props.onYes} />
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
