import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants";
import { useAuthUser, useFeatures, useProjectQueries } from "@shared/hooks";
import { useUIConfig } from "@shared/hooks/useUIConfig";

import { routeConstants } from "@app/constants/routeConstants";
import {
  DueDate,
  Perspective,
  Project,
  ProjectMembers,
  QueryStatus,
  SummaryIndicators
} from "@app/types";

import PieChartTable from "@components/atoms/Chart/PieChartTable/PieChartTable";
import ToggleButtonGroup from "@components/atoms/ToggleButtonGroup/ToggleButtonGroup";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import filterColorVariables from "../../../helpers/filterColorVariables.module.scss";
import legendColorVariable from "../../../helpers/legendColorVariable.module.scss";
import "./ActionItemsBoard.scss";
import {
  ToggleType,
  getPerspectiveAndDueDateStats,
  getQueryNumber
} from "./ActionItemsBoardHelper";

type Props = {
  projectMembers: ProjectMembers;
  project: Project;
};

const ActionItemsBoard = (props: Readonly<Props>) => {
  const { projectMembers, project } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user: authUser } = useAuthUser();
  const { isEnabled } = useFeatures();

  const { queries } = useProjectQueries(project);
  const { uiConfig } = useUIConfig();

  const [selectedType, setSelectedType] = useState("MYREQUESTS");
  const summaryIndicators = project.configuration?.requests?.summaryIndicators;

  const toggleButtons = [
    {
      type: ToggleType.MY_REQUESTS,
      label: t("requests:requests.ui.requestBoard.myRequestsLabel")
    },
    {
      type: ToggleType.TEAM_REQUESTS,
      label: t("requests:requests.ui.requestBoard.myTeamRequestsLabel")
    }
  ];

  const isByDate = useMemo(
    () => uiConfig?.actionItemFilters?.dueDate?.enabled,
    [uiConfig?.actionItemFilters?.dueDate?.enabled]
  );

  const isMyRequests = selectedType === ToggleType.MY_REQUESTS;

  const isRequestViewFiltersEnabled = useMemo(() => {
    return isEnabled(systemConstants.features.requestViewFilters);
  }, [isEnabled]);

  const queryItems = useMemo(
    () =>
      getPerspectiveAndDueDateStats({
        queries,
        authUser,
        isByDate,
        projectMembers
      }),
    [queries, authUser, isByDate, projectMembers]
  );

  const data = useMemo(() => {
    return {
      keys: ["requests"],
      values:
        summaryIndicators?.map(indicator => {
          return {
            label: t("requests:requests.indicators.label", {
              context: indicator.toLowerCase()
            }),
            requests: getQueryNumber({
              queryItems,
              indicator,
              selectedType,
              isByDate
            }),
            indicator
          };
        }) ?? []
    };
  }, [isByDate, queryItems, selectedType, summaryIndicators, t]);

  const filterTypes = systemConstants.dashboard.actionItemsFilterType;

  const goToActionItemsPage = (indicator: SummaryIndicators) => {
    const myDirectMap = {
      OPEN: filterTypes.myOpenItems,
      RESPONDED: filterTypes.myRespondedItems,
      ACTIVE: filterTypes.myActiveItems,
      RESOLVED: filterTypes.myClosedItems,
      UPCOMING: filterTypes.myUpcomingItems,
      OVERDUE: filterTypes.myPastDueItems
    };
    const teamDirectMap = {
      OPEN: filterTypes.myTeamOpenItems,
      RESPONDED: filterTypes.myTeamRespondedItems,
      RESOLVED: filterTypes.myTeamClosedItems,
      ACTIVE: filterTypes.myTeamActiveItems,
      UPCOMING: filterTypes.myTeamsUpcomingItems,
      OVERDUE: filterTypes.myTeamsPastDueItems
    };
    const type = (() => {
      if (isMyRequests) {
        return myDirectMap[indicator];
      } else {
        return teamDirectMap[indicator];
      }
    })();

    navigate(routeConstants.request.requests, {
      state: {
        project: props.project,
        filters: { type, members: projectMembers }
      }
    });
  };

  const goToActionItemsPageWithFilterParams = (
    indicator: SummaryIndicators
  ) => {
    const paramMap = {
      OPEN: { status: QueryStatus.OPEN.toLowerCase() },
      RESPONDED: { status: QueryStatus.RESPONDED.toLowerCase() },
      ACTIVE: {},
      RESOLVED: { status: QueryStatus.CLOSED.toLowerCase() },
      UPCOMING: { dueDate: DueDate.UPCOMING },
      OVERDUE: { dueDate: DueDate.OVERDUE }
    };
    const filter = paramMap[indicator] ?? {};
    const perspective = (() => {
      if (isMyRequests) {
        return Perspective.MY_REQUESTS;
      }
      return authUser.isHostUser
        ? Perspective.DELIVERY_TEAM_REQUESTS
        : Perspective.CLIENT_REQUESTS;
    })();
    navigate(
      `/projects/${project.id}/queries#${new URLSearchParams({
        ...filter,
        perspective
      })}`
    );
  };

  const handleLegendClick = (e: { indicator: SummaryIndicators }) => {
    const indicatorLabel = e.indicator;
    if (isRequestViewFiltersEnabled) {
      goToActionItemsPageWithFilterParams(indicatorLabel);
    } else {
      goToActionItemsPage(indicatorLabel);
    }
  };

  const renderEmptyPlaceHolder = () => (
    <foreignObject x={70} y={115} className="empty-place-holder">
      <div>{t("requests:requests.ui.requestBoard.emptyPlaceHolder")}</div>
    </foreignObject>
  );

  const getChartColors = (filterColorVariables: CSSModuleClasses) => {
    const chartColors =
      summaryIndicators?.map(indicator => filterColorVariables[indicator]) ??
      [];
    return {
      chartColors,
      legendTextColor: legendColorVariable["legendTextColor"]
    };
  };

  return (
    <DashboardBoxTemplate
      title={t("requests:requests.ui.requestBoard.title")}
      boxClassName={"action-items-board"}
    >
      <ToggleButtonGroup
        onClick={setSelectedType}
        toggleButtons={toggleButtons}
      />
      <PieChartTable
        data={data}
        handleLegendClick={handleLegendClick}
        pieRadius={"75%"}
        className="action-items-board__chart"
        isSecondaryStyle={true}
        colors={getChartColors(filterColorVariables)}
        displayZero={true}
        fixedHeight={240}
        chartMargin={{}}
        renderEmptyPlaceHolder={renderEmptyPlaceHolder}
        dataType="number"
      />
    </DashboardBoxTemplate>
  );
};

export default ActionItemsBoard;
