import { t } from "i18next";

import { systemConstants } from "@shared/constants";

import { routeConstants } from "@app/constants";
import { AccessLevel, ResourceName } from "@app/types";

const features = systemConstants.features;
export const adminNavs = ({
  i18nText,
  clientId
}: {
  i18nText: typeof t;
  clientId: number;
}) => {
  return {
    manageClientsAndUsers: {
      name: i18nText("admin:ui.navigation.manageUsers.label", {
        context: "HOST"
      }),
      path: routeConstants.manageUsers,
      icon: "person",
      permission: {
        resource: ResourceName.HOST_USERS,
        accessLevel: AccessLevel.READ
      }
    },
    manageClientUsers: {
      name: i18nText("admin:ui.navigation.manageUsers.label", {
        context: "CLIENT"
      }),
      path: `/admin/clients/${clientId}/users`,
      icon: "person",
      permission: {
        resource: ResourceName.CLIENT_USERS,
        accessLevel: AccessLevel.READ,
        type: "CLIENT"
      }
    },
    manageRoles: {
      name: i18nText("admin:ui.navigation.manageRoles.label"),
      icon: "manage_accounts",
      path: routeConstants.admin.manageUserRoles,
      permission: {
        resource: ResourceName.ROLES,
        accessLevel: AccessLevel.READ
      }
    },
    manageClients: {
      name: i18nText("admin:ui.navigation.manageClients.label"),
      path: routeConstants.manageClients,
      icon: "apartment",
      permission: {
        resource: ResourceName.CLIENTS,
        accessLevel: AccessLevel.READ
      }
    },
    manageRequestTypes: {
      name: i18nText("admin:ui.navigation.manageRequestTypes.label"),
      path: routeConstants.admin.manageRequestTypes,
      icon: "pending_actions",
      permission: {
        resource: ResourceName.REQUEST_TYPES,
        accessLevel: AccessLevel.READ
      }
    },
    auditReport: {
      name: i18nText("common:ui.sideNavigation.admin.auditReport"),
      path: routeConstants.manageAudits,
      icon: "report",
      permission: {
        resource: ResourceName.AUDIT_REPORT,
        accessLevel: AccessLevel.READ
      }
    },
    globalTags: {
      name: i18nText("common:ui.sideNavigation.admin.globalTags"),
      path: routeConstants.globalTags,
      icon: "sell",
      enabledFeatures: [features.globalTags]
    },
    dataExtraction: {
      name: i18nText("common:ui.sideNavigation.admin.dataExtraction"),
      path: routeConstants.dataExtraction,
      icon: "summarize",
      permission: {
        resource: ResourceName.DATA_EXTRACTION,
        accessLevel: AccessLevel.READ
      },
      enabledFeatures: [features.dataExtraction]
    },
    manageNews: {
      name: i18nText("common:ui.sideNavigation.admin.manageNews"),
      path: routeConstants.manageNews,
      icon: "newspaper",
      permission: {
        resource: ResourceName.MANAGE_NEWS_AND_IMAGE,
        accessLevel: AccessLevel.READ
      },
      enabledFeatures: [features.manageNews]
    }
  };
};
