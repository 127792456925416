import React, { forwardRef, useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";
import { useMouseLeaveEnter } from "@app/hooks/useMouseLeaveEnter.tsx";

import { Box, Inline, Stack } from "@fermions";

import "./InputTemplate.scss";
import { BorderStyle } from "./InputWrapper/InputWrapper";

export enum LabelPosition {
  TOP = "top",
  LEFT = "left"
}

export enum InputWidth {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  FILL = "fill",
  AUTO = "auto"
}

export interface InputTemplateProps {
  className?: string;
  labelElement?: React.JSX.Element;
  labelPosition?: LabelPosition;
  inputWrapperElement?: React.JSX.Element;
  openElement?: React.JSX.Element;
  setIsOpen?: (open: boolean) => void;
  error?: string;
  borderStyle?: BorderStyle;
  testId?: string;
  width?: InputWidth;
  closeOnMouseLeave?: boolean;
}

export const InputTemplate = forwardRef(
  (
    {
      className,
      labelElement,
      labelPosition = LabelPosition.TOP,
      inputWrapperElement,
      openElement,
      setIsOpen,
      error,
      borderStyle = BorderStyle.FULL,
      testId = "",
      width = InputWidth.FILL,
      closeOnMouseLeave = true
    }: InputTemplateProps,
    ref
  ) => {
    const Fermion = useMemo(
      () => (labelPosition === LabelPosition.LEFT ? Inline : Stack),
      [labelPosition]
    );

    const { handleOnMouseEnter, handleOnMouseLeave } = useMouseLeaveEnter({
      skip: !closeOnMouseLeave || !openElement,
      cb: () => setIsOpen?.(false)
    });

    return (
      <Stack gap="050" className={[`input-template--width-${width}`]}>
        <Fermion
          className={[
            className ?? "",
            "input-template__wrapper",
            `input-template--width-${width}`,
            `input-template--border-${borderStyle}`,
            `input-template__wrapper--${labelPosition}`
          ]}
          data-testid={testId}
        >
          {labelElement ?? <></>}
          <Stack gap="050">
            <Box
              className={classNames(["input-template"])}
              ref={ref}
              onMouseLeave={handleOnMouseLeave()}
              onMouseEnter={handleOnMouseEnter()}
            >
              {inputWrapperElement ?? <></>}
              {openElement ?? <></>}
            </Box>
          </Stack>
        </Fermion>
        {error && <span className="input-template__error">{error}</span>}
      </Stack>
    );
  }
);
