import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useAuthUser, useFeatures } from "@shared/hooks";
import useAccessControl from "@shared/hooks/useAccessControl";

import { adminNavs } from "@app/constants";

interface NavItem {
  name: string;
  path: string;
  icon: string;
  state?: unknown;
  onClick?: () => void;
}
export function useAdminNavItems() {
  const { t } = useTranslation();
  const { user } = useAuthUser({ withProfile: false });
  const { isEnabled } = useFeatures();
  const { filterByPermissionFn } = useAccessControl();

  const filterByEnabledFeaturesFn = item =>
    !item?.enabledFeatures || item.enabledFeatures.every(isEnabled);

  const adminNavItems = useMemo(() => {
    const adminUserNav = adminNavs({ i18nText: t, clientId: user.clientId });
    const navItems: NavItem[] = [
      adminUserNav.manageClientsAndUsers,
      adminUserNav.manageClientUsers,
      adminUserNav.manageRoles,
      adminUserNav.manageClients,
      adminUserNav.manageRequestTypes,
      adminUserNav.auditReport,
      adminUserNav.globalTags,
      adminUserNav.dataExtraction,
      adminUserNav.manageNews
    ];
    return navItems
      .filter(filterByPermissionFn)
      .filter(filterByEnabledFeaturesFn);
  }, [t, user, filterByPermissionFn, filterByEnabledFeaturesFn]);

  return {
    adminNavItems
  };
}
