import React, { useCallback, useEffect, useMemo, useState } from "react";

import { set } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { manageDocumentDownloadsActions } from "@shared/actions/manageDocumentDownloadsActions";
import { systemConstants } from "@shared/constants";
import { actionTypeConstants } from "@shared/constants/actionTypeConstants.js";
import { formatDateOnly, safeUtcDate } from "@shared/helpers/dateFormatUtils";
import dateFormatter from "@shared/helpers/dateHelper";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import {
  useAuthUser,
  useGetProjectMembers,
  useGetProjectTags,
  useLocaleDate,
  useRequestPageNavigator,
  useUpdateQuery
} from "@shared/hooks";
import { useGetLockedUpdateFieldsQuery } from "@shared/services/actionItemService";
import { useGetProjectMilestonesQuery } from "@shared/services/projectService";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import {
  getIndividualFieldPermission,
  getQueryFields
} from "@app/helpers/actionItemTypes.ts";
import {
  getActionItemTypeConfiguration,
  getQueryTypeDropdownOptionByKey,
  getQueryValidationSchema,
  getSelectedQueryType,
  validateQuery
} from "@app/helpers/actionItems";

import Form from "@components/atoms/Form";
import FileAttachmentList from "@components/molecules/FileAttachmentList";
import ModalForm from "@components/molecules/ModalForm";

const addFilesState = systemConstants.addFiles.state;

function UpdateActionForm(props) {
  const { t } = useTranslation();
  const { project, query, queryTypes, actionItemTypes, onSubmitted, onCancel } =
    props;

  const [showForm, setShowForm] = useState(false);
  const [assignUsers, setAssignUsers] = useState([]);
  const [entities, setEntities] = useState([]);
  const [labels, setLabels] = useState([]);
  const [queryType, setQueryType] = useState();
  const { members, membersLoading } = useGetProjectMembers(project);
  const { projectTags, loadingProjectTags } = useGetProjectTags(project.id);
  const { updateQueryError, updateQuery } = useUpdateQuery();
  const [error, setError] = useState("");
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [submitQuery, setSubmitQuery] = useState({});
  const { user } = useAuthUser();
  const [fields, setFields] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const dispatch = useDispatch();
  const { navigateToRequestPage } = useRequestPageNavigator();
  const { data: lockedFields, isLoading: loadingLockedFields } =
    useGetLockedUpdateFieldsQuery(
      { queryId: query.id },
      { skip: !query?.id, refetchOnMountOrArgChange: true }
    );
  const { data: milestones } = useGetProjectMilestonesQuery(
    {
      projectId: project?.id,
      onlyEngagementTypeMilestones: true
    },
    {
      skip: !project.id || !project.configuration?.milestones?.labels?.enabled,
      refetchOnMountOrArgChange: true
    }
  );
  const {
    locale,
    options: { numericFormat }
  } = useLocaleDate();

  const yupSchema = useMemo(() => {
    const userTypes = [
      user.xRole.type,
      query.isUserOnRequestorTeam ? "REQUESTOR" : ""
    ];

    return getQueryValidationSchema({
      configFields: fields,
      i18nText: t,
      userTypes,
      actionType: actionTypeConstants.UPDATE
    });
  }, [fields, query.isUserOnRequestorTeam, t, user.xRole.type]);

  const milestoneEnabled = useMemo(
    () => project.configuration?.milestones?.labels?.enabled,
    [project.configuration?.milestones?.labels?.enabled]
  );

  const allQueryFields = useMemo(() => {
    return actionItemTypes?.find(
      actionItemType => actionItemType.configuration.key === query.queryType
    )?.configuration?.fields;
  }, [actionItemTypes, query.queryType]);

  useEffect(() => {
    setQueryType(
      getQueryTypeDropdownOptionByKey(query.queryType, queryTypes, t)
    );
    const newSelectedQueryType = getSelectedQueryType({
      key: query.queryType
    });
    if (newSelectedQueryType.isConfigType) {
      const newFields = getQueryFields({
        key: query.queryType,
        actionItemTypes,
        roleType: [
          user.xRole.type,
          query.isUserOnRequestorTeam ? "REQUESTOR" : ""
        ],
        actionType: "update"
      });
      setFields(newFields);
    } else {
      setFields([]);
    }
  }, [query, queryTypes, actionItemTypes, t, user.xRole.type]);

  useEffect(() => {
    const q = { ...query, files: query.attachments || [] };
    if (q.entities) {
      set(q, "properties.entities", [...q.entities]);
      delete q.entities;
    }
    if (q.milestone) {
      set(q, "properties.milestone", q.milestone);
      delete q.milestone;
    }
    setSubmitQuery(q);
  }, [query]);

  useEffect(() => {
    setShowForm(true);
  }, []);

  useEffect(() => {
    setError(updateQueryError);
  }, [updateQueryError]);

  useEffect(() => {
    if (members && !membersLoading) {
      const allUsers = (members.clientUsers || [])
        .concat(members.hostUsers || [])
        .map(member => ({ name: member.name, value: member }));
      setAssignUsers(allUsers);
    } else setAssignUsers([]);
  }, [members, membersLoading, user.id]);

  useEffect(() => {
    const projectEntities = project?.entities?.map(e => ({
      name: e.name,
      value: e
    }));
    setEntities(projectEntities ?? []);
  }, [project]);

  useEffect(() => {
    if (projectTags && !loadingProjectTags) {
      const allTags = projectTags.map(tag => ({
        name: tag.name,
        value: tag
      }));
      setLabels(allTags);
    }
  }, [loadingProjectTags, projectTags]);

  const isFieldAvailable = useCallback(
    field => {
      if (!user?.xRole?.type) {
        return false;
      }
      const userRoleType = [
        user.xRole.type,
        query.isUserOnRequestorTeam ? "REQUESTOR" : ""
      ];
      const permissions = getIndividualFieldPermission({ field, userRoleType });
      return permissions.isEditable;
    },
    [query.isUserOnRequestorTeam, user?.xRole?.type]
  );

  const getUpdatedQuery = useCallback(
    (currentQuery, data) => {
      const updatedTagList = data.tags?.map(d => d.value) ?? [];
      const updatedCopiedTo = data.copiedTo?.map(d => d.value) ?? [];
      const newQuery = {
        ...currentQuery,
        projectId: project?.id,
        assignedTo: data.assignedTo?.value,
        query: data.query,
        description: data.description,
        requiredBy: safeUtcDate(data.requiredBy),
        tags: updatedTagList,
        copiedTo: updatedCopiedTo,
        reminderDate: formatDateOnly(data.reminderDate),
        properties: {}
      };

      if ((fields ?? []).some(f => f.type === "entity")) {
        newQuery.properties.entities =
          data.entities?.map(e => e.value.externalId) ?? [];
      }
      if ((fields ?? []).some(f => f.type === "milestone")) {
        newQuery.properties.milestone = data.milestone?.value?.id ?? null;
      }

      // need to delete everything that's not updatable
      allQueryFields.forEach(field => {
        if (!isFieldAvailable(field)) {
          if (newQuery.properties?.[field.key]) {
            delete newQuery.properties[field.key];
          }
          delete newQuery[field.key];
        }
      });

      return newQuery;
    },
    [allQueryFields, fields, isFieldAvailable, project?.id]
  );

  const onSubmittedHandler = useCallback(() => {
    setShowForm(false);
    onSubmitted?.();
  }, [onSubmitted]);

  const handleCancel = useCallback(() => {
    setError("");
    setShowForm(false);
    onCancel();
  }, [onCancel]);

  const handleSubmit = data => {
    setError("");
    setUploadError(null);
    const validatedQuery = validateQuery(data, t, query.queryType);
    if (validatedQuery.success) {
      const newQuery = getUpdatedQuery(submitQuery, data);

      if (fields.some(f => f.key === "file")) {
        setUploadState(addFilesState.upload);
      } else {
        updateQuery(newQuery, onSubmittedHandler);
      }

      setSubmitQuery(newQuery);
    } else {
      setUploadError(validatedQuery.error);
    }
  };

  const handleUploadComplete = attachedFiles => {
    setUploadState(addFilesState.finished);
    const newFiles =
      attachedFiles && Object.keys(attachedFiles).length > 0
        ? Object.keys(attachedFiles).map(key => {
            const file = attachedFiles[key];
            return {
              filePathId: file.filePathId,
              name: file.name,
              projectId: project.id,
              isDeleted: file.isDeleted || false,
              isNew: file.isNew || false
            };
          })
        : submitQuery.files;
    const newQuery = { ...submitQuery, files: newFiles };
    updateQuery(newQuery, onSubmittedHandler);
    setSubmitQuery(newQuery);
  };

  const handleUploadFailed = useCallback(error => {
    setError(error);
    setUploadState(addFilesState.finished);
  }, []);

  const defaultLabels = useMemo(() => {
    return labels?.length > 0 && submitQuery.tags
      ? submitQuery.tags.map(queryTag => ({
          name: queryTag.name,
          value: queryTag
        }))
      : [];
  }, [labels?.length, submitQuery.tags]);

  const defaultMilestone = useMemo(() => {
    const milestone = submitQuery.properties?.milestone;
    if (!milestone) {
      return null;
    }
    return milestones
      ?.map(m => ({ name: t(m.name), value: m }))
      ?.find(m => m.value.id == milestone);
  }, [milestones, submitQuery.properties?.milestone, t]);

  const defaultEntities = useMemo(() => {
    return submitQuery?.properties?.entities
      ?.map(entityId => {
        const entityObj = entities?.find(e => e.value.externalId === entityId);
        return {
          name: entityObj?.name,
          value: { ...entityObj?.value }
        };
      })
      .filter(queryEntity => !!queryEntity.name);
  }, [entities, submitQuery.properties?.entities]);

  const idComparator = (a, b) => a.id === b.id;
  const externalIdComparator = (a, b) => a.externalId === b.externalId;

  const getDefaultCopiedTo = useCallback(() => {
    const copiedTo = query?.copiedTo;
    if (!copiedTo?.length) {
      return [];
    }
    return copiedTo.map(u => ({ id: u.id, name: u.name, value: u }));
  }, [query]);

  const isFieldLocked = useCallback(
    field => lockedFields?.some(l => l === field?.key) || field?.disabled,
    [lockedFields]
  );

  const milestoneItems = useMemo(
    () =>
      milestones?.map(m =>
        milestoneUtilities.formatMilestoneDropdownItem(
          m,
          dateFormatter(m.date, locale, numericFormat),
          t
        )
      ) ?? [],
    [locale, milestones, numericFormat, t]
  );

  const getMilestoneField = field => {
    if (milestoneEnabled && milestoneItems?.length > 0) {
      const noSortingComparator = () => 0;
      return (
        <Form.Dropdown
          key={field?.key}
          name={field?.key}
          disabled={isFieldLocked(field)}
          label={t(`requests:requests.configured.fields.${field?.key}.label`, {
            context: query.queryType
          })}
          items={milestoneItems}
          defaultValue={defaultMilestone}
          required={field?.required}
          allowUndefined={!field?.required}
          sortComparator={noSortingComparator}
        />
      );
    }
  };

  const getEntitiesField = field => {
    if (entities?.length > 0) {
      return entities.length === 1 ? (
        <Form.HiddenInput key={field.key} name="entities" value={entities} />
      ) : (
        <Form.Multiselect
          key={field.key}
          name="entities"
          disabled={isFieldLocked(field)}
          required={field.required}
          label={t(`requests:requests.configured.fields.${field.key}.label`, {
            context: query.queryType
          })}
          items={entities}
          defaultValue={defaultEntities}
          defaultValueComparer={externalIdComparator}
        />
      );
    }
  };

  const getAttachmentType = useCallback(() => {
    return submitQuery.configType === systemConstants.actionItemTypes.websheet
      ? "websheet"
      : "document";
  }, [submitQuery]);

  const handleFileDownload = useCallback(
    ({ id: documentId, name: filename, documentRevisionId }) => {
      if (documentRevisionId) {
        dispatch(
          manageDocumentDownloadsActions.downloadDocumentRevision({
            id: documentId,
            name: filename,
            documentRevisionId
          })
        );
      } else {
        dispatch(
          manageDocumentDownloadsActions.downloadCurrentDocument({
            id: documentId,
            name: filename
          })
        );
      }
    },
    [dispatch]
  );

  const handleViewWebsheetQuery = useCallback(
    query => {
      const actionItemConfig = getActionItemTypeConfiguration(
        query.queryType,
        actionItemTypes
      );
      navigateToRequestPage(query.id, query.projectId, actionItemConfig?.type, {
        websheetOpensNewWindow: true
      });
    },
    [actionItemTypes, navigateToRequestPage]
  );

  const handleAttachmentAction = useCallback(
    attachment => {
      if (
        getAttachmentType() !== systemConstants.fileAttachmentItemType.websheet
      ) {
        handleFileDownload(attachment);
      } else {
        handleViewWebsheetQuery(submitQuery);
      }
    },
    [
      getAttachmentType,
      handleFileDownload,
      handleViewWebsheetQuery,
      submitQuery
    ]
  );

  const existingFiles = useMemo(() => {
    return submitQuery.attachments?.map(item => ({
      ...item,
      name:
        getAttachmentType() === systemConstants.fileAttachmentItemType.websheet
          ? t(
              "requests:requests.ui.populateRequestForm.websheetAttachmentDisplayName"
            )
          : item.name
    }));
  }, [getAttachmentType, submitQuery, t]);

  return (
    <div>
      <ModalForm
        title={t(
          "requests:requests.ui.populateRequestForm.titleUpdateRequest",
          {
            requestLongNameSingular: t("requests:requests.longName")
          }
        )}
        isLoading={loadingLockedFields}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        slidingForm={true}
        show={showForm}
        yupSchema={yupSchema}
      >
        {error && <ErrorBox message={error} />}
        <Form.Dropdown
          name={"queryType"}
          label={t(
            "requests:requests.ui.populateRequestForm.labelSelectRequestType",
            {
              requestLongNameSingular: t("requests:requests.longName")
            }
          )}
          items={queryType ? [queryType] : []}
          disabled={true}
          defaultValue={queryType}
        />
        <>
          {fields.map(f => {
            switch (f.type) {
              case "user":
                return (
                  <Form.Dropdown
                    key={f.key}
                    name={f.key}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    disabled={isFieldLocked(f)}
                    required={f.required}
                    items={assignUsers}
                    defaultValue={
                      submitQuery[f.key]?.name
                        ? {
                            name: submitQuery[f.key].name,
                            value: submitQuery[f.key]
                          }
                        : null
                    }
                  />
                );
              case "text":
                return (
                  <Form.TextField
                    key={f.key}
                    name={f.key}
                    disabled={isFieldLocked(f)}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    required={f.required}
                    defaultValue={submitQuery[f.key]}
                  />
                );
              case "copiedTo":
                return (
                  <Form.Multiselect
                    key={f.key}
                    name={f.key}
                    disabled={isFieldLocked(f)}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    required={f.required}
                    items={assignUsers}
                    defaultValue={getDefaultCopiedTo()}
                    defaultValueComparer={idComparator}
                  />
                );
              case "date": {
                const enableMinDate = f.key === "reminderDate";
                const isDisabled = isFieldLocked(f);
                return (
                  <Form.DateField
                    key={f.key}
                    name={f.key}
                    disabled={isDisabled}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    minDate={enableMinDate && !isDisabled ? new Date() : null}
                    required={f.required}
                    defaultValue={
                      submitQuery[f.key] && new Date(submitQuery[f.key])
                    }
                  />
                );
              }
              case "textarea":
                return (
                  <Form.TextArea
                    key={f.key}
                    name={f.key}
                    disabled={isFieldLocked(f)}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    required={f.required}
                    defaultValue={submitQuery[f.key]}
                  />
                );
              case "milestone":
                return getMilestoneField(f);
              case "entity":
                return getEntitiesField(f);
              case "tags": {
                return (
                  <Form.Multiselect
                    key={f.key}
                    required={f.required}
                    disabled={isFieldLocked(f)}
                    name={f.key}
                    label={t(
                      `requests:requests.configured.fields.${f.key}.label`,
                      {
                        context: query.queryType
                      }
                    )}
                    items={labels}
                    defaultValue={defaultLabels}
                    defaultValueComparer={idComparator}
                  />
                );
              }
              case "file":
                return (
                  <>
                    <FileAttachmentList
                      attachments={existingFiles}
                      onFileDownloadClicked={handleAttachmentAction}
                      showIconOnlyOnHover={false}
                      disabled={false}
                      columnMode={"single"}
                      itemType={getAttachmentType()}
                    />
                    <Form.UploadDocuments
                      name={f.key}
                      key={f.key}
                      disableUploads={
                        queryType.value.type ===
                          systemConstants.actionItemTypes.conversation ||
                        isFieldLocked(f)
                      }
                      dropMessage={t(
                        `requests:requests.configured.fields.${f.key}.label`,
                        {
                          context: query.queryType
                        }
                      )}
                      documentType={f.documentType || ""}
                      supportedDocumentMimes={
                        f.validations?.find(v => v.name === "extension")?.value
                      }
                      supportedDocumentMimesMessage={
                        f.validations?.find(v => v.name === "extension")
                          ? t(
                              "requests:requests.configured.fields.files.validation.extension.errorMessage",
                              {
                                context: query.queryType,
                                extensions: f.validations
                                  ?.find(v => v.name === "extension")
                                  ?.value?.join(", ")
                              }
                            )
                          : null
                      }
                      maxNumberOfFiles={
                        f.validations?.find(v => v.name === "count")?.value
                      }
                      maxNumberOfFilesError={
                        f.validations?.find(v => v.name === "count")
                          ? t(
                              "requests:requests.configured.fields.files.validation.count.errorMessage",
                              { context: query.queryType }
                            )
                          : null
                      }
                      projectId={project?.id}
                      state={uploadState}
                      errorMessage={uploadError}
                      existingFiles={submitQuery.attachments}
                      onUploadsComplete={handleUploadComplete}
                      onUploadsFailed={handleUploadFailed}
                      isHideDocumentsList={true}
                    />
                  </>
                );
            }
          })}
        </>
      </ModalForm>
    </div>
  );
}

UpdateActionForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  query: PropTypes.object,
  queryTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string
    })
  ),
  actionItemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      configuration: PropTypes.shape({
        key: PropTypes.string,
        fields: PropTypes.array
      })
    })
  )
};

export default UpdateActionForm;
