import { manageClientTagsConstants } from "../constants";
import { manageClientTagsService } from "../services";

export const manageClientTagsActions = {
  getClientTags,
  createClientTag,
  updateClientTag,
  deleteClientTag,
  reset
};

function getClientTags(client) {
  return dispatch => {
    dispatch(request());

    manageClientTagsService.getClientTags(client).then(
      tags => {
        dispatch(success(tags));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientTagsConstants.GET_CLIENT_TAGS_REQUEST };
  }
  function success(tags) {
    return { type: manageClientTagsConstants.GET_CLIENT_TAGS_SUCCESS, tags };
  }
  function failure(error) {
    return { type: manageClientTagsConstants.GET_CLIENT_TAGS_FAILURE, error };
  }
}

function createClientTag({ id: clientId }, tag) {
  return dispatch => {
    dispatch(request());

    manageClientTagsService.createClientTag({ id: clientId }, tag).then(
      addedClientTag => {
        dispatch(success(addedClientTag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientTagsConstants.ADD_CLIENT_TAGS_REQUEST };
  }
  function success(addedClientTag) {
    return {
      type: manageClientTagsConstants.ADD_CLIENT_TAGS_SUCCESS,
      addedClientTag
    };
  }
  function failure(error) {
    return { type: manageClientTagsConstants.ADD_CLIENT_TAGS_FAILURE, error };
  }
}

function updateClientTag(client, tag) {
  return dispatch => {
    dispatch(request());

    manageClientTagsService.updateClientTag(client, tag).then(
      updatedClientTag => {
        dispatch(success(updatedClientTag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientTagsConstants.UPDATE_CLIENT_TAGS_REQUEST };
  }
  function success(updatedClientTag) {
    return {
      type: manageClientTagsConstants.UPDATE_CLIENT_TAGS_SUCCESS,
      updatedClientTag
    };
  }
  function failure(error) {
    return {
      type: manageClientTagsConstants.UPDATE_CLIENT_TAGS_FAILURE,
      error
    };
  }
}

function deleteClientTag(client, tag) {
  return dispatch => {
    dispatch(request());

    manageClientTagsService.deleteClientTag(client, tag).then(
      deletedClientTag => {
        dispatch(success(deletedClientTag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientTagsConstants.DELETE_CLIENT_TAGS_REQUEST };
  }
  function success(deletedClientTag) {
    return {
      type: manageClientTagsConstants.DELETE_CLIENT_TAGS_SUCCESS,
      deletedClientTag
    };
  }
  function failure(error) {
    return {
      type: manageClientTagsConstants.DELETE_CLIENT_TAGS_FAILURE,
      error
    };
  }
}

function reset() {
  return { type: manageClientTagsConstants.RESET };
}
