import React from "react";

import ions from "@ions";
import { Context } from "@ions-interface";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import Title from "@components/atoms/Title";

export interface PageHeaderType {
  title?: string;
  hasTitleTooltip?: boolean;
  subtitle?: Node | string;
  subtitleActionIcon: Node;
  sticky?: boolean;
  icon?: string;
  actions?: Node;
  content?: Node;
  tagline?: string;
  context?: Context;
}

interface PageHeaderProps {
  header: PageHeaderType;
  classNames?: {
    banner: string;
  };
}

const PageHeader = ({ header, classNames }: PageHeaderProps) => {
  const fallbackContext = ions.context?.default?.pageTitle;
  return (
    <div
      id={returnStringIfTrue(header.sticky, "page-template__sticky-heading")}
      data-html2canvas-ignore
      className={`${returnStringIfTrue(
        classNames?.banner,
        classNames?.banner
      )} page-template__body__content__heading${returnStringIfTrue(
        header.sticky,
        "--sticky"
      )} page-template__body__content__heading--context-${
        header.context ?? fallbackContext
      }`}
    >
      {/* Tagline */}
      {header.tagline && (
        <div
          className="page-template__body__content__heading__tagline"
          role="header.tagline"
        >
          {header.tagline}
        </div>
      )}
      {/* Top of heading */}
      <Title
        title={header.title}
        hasTitleTooltip={header.hasTitleTooltip}
        subtitle={header.subtitle}
        subtitleActionIcon={header.subtitleActionIcon}
        actions={header.actions}
        icon={header.icon}
        context={header.context ?? fallbackContext}
      />
      {header.content && (
        <div className="page-template__body__content__heading__content">
          {header.content}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
