import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import { useGetProjectByIdQuery } from "@shared/services/projectService";

import CopyProjectForm from "@components/organisms/CopyProjectForm";

const CopyClientEngagementProject = () => {
  const navigate = useNavigate();

  const { projectId } = useParams();
  const {
    data: currentProject,
    isLoading,
    isFetching: isFetchingProject,
    error: errorFetchingProject
  } = useGetProjectByIdQuery(
    { projectId },
    { skip: !projectId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (
      !projectId ||
      currentProject?.status === systemConstants.project.status.draft
    ) {
      navigate(routeConstants.login);
    }
  }, [currentProject?.status, navigate, projectId]);

  useEffect(() => {
    if (errorFetchingProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [errorFetchingProject, navigate]);

  return (
    <CopyProjectForm
      project={currentProject}
      isLoading={isLoading || !!isFetchingProject}
    />
  );
};

export default CopyClientEngagementProject;
