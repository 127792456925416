import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";
import { useTooltip } from "@shared/hooks/useTooltip";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box } from "@fermions";

import {
  Icon,
  IconColor,
  IconDesignStyle,
  IconFillStyle,
  IconSize
} from "@atoms/Icon";

import OTTooltip from "@components/atoms/OTTooltip";

import "./SecondaryNavItem.scss";
import DashboardGrid from "./dashboardGrid.svg?react";

const defaultIconProps = {
  name: "stop",
  designStyle: "material-symbols",
  fillStyle: IconFillStyle.FILLED,
  size: IconSize.M,
  color: IconColor.INHERIT
};

const defaultIcon = (
  <Icon
    {...defaultIconProps}
    designStyle={IconDesignStyle.MATERIAL_ICONS}
    color={IconColor.OTHER}
  />
);
const SecondaryNavItem = props => {
  const { isExpanded, item, onClickNavigate, isMenuItemExpanded, isSubItem } =
    props;

  const { setMenuItemExpanded } = useSecondaryNav();
  const icon = useMemo(() => {
    if (!item?.icon && !item?.name) {
      return defaultIcon;
    }
    if (item?.icon?.custom) {
      return (
        <Box
          className={[
            "secondary-nav-item__icon-container",
            returnStringIfTrue(isExpanded, "expanded")
          ]}
        >
          <DashboardGrid style={{ width: "20px" }} />
        </Box>
      );
    }
    return (
      <Box
        className={[
          "secondary-nav-item__icon-container",
          returnStringIfTrue(isExpanded, "expanded")
        ]}
      >
        <Icon {...defaultIconProps} {...(item?.icon || {})} />
      </Box>
    );
  }, [item?.icon, item?.name, isExpanded]);
  const clickHandler = () => {
    if (item.id) {
      setMenuItemExpanded({ id: item.id, isExpanded: !isMenuItemExpanded });
    }
  };
  const renderSubItems = () => {
    if (item?.items && isMenuItemExpanded)
      return (
        <>
          {item.items.map(item => (
            <SecondaryNavItem
              key={item.name}
              isExpanded={isExpanded}
              item={item}
              isSubItem={true}
              onClickNavigate={() => onClickNavigate(item.link, item.state)}
            />
          ))}
        </>
      );
  };
  const getDisabledClass = () => (props.disabled ? "disabled" : "");
  const classNames = list => list?.filter(c => c).join(" ");

  const { elementProps, showTooltip } = useTooltip();

  const identifyingIcon = useMemo(() => {
    if (isSubItem) {
      return (
        <div className="secondary-nav-item__sub-item-indicator">
          <span className="sub-item-indicator-line" />
          <span className="sub-item-indicator-marker" />
        </div>
      );
    }
    return icon;
  }, [icon, isSubItem]);

  const isLongName = useMemo(
    () => item?.name?.length > 18,
    [item?.name?.length]
  );

  return (
    <>
      <div
        className="secondary-nav__item"
        onClick={() => onClickNavigate(item.link, item.state)}
        {...elementProps}
      >
        <div
          className={classNames([
            "secondary-nav-item",
            item?.isActive && "secondary-nav-item--active",
            isExpanded && "secondary-nav-item--expand",
            isSubItem && "secondary-nav-item--sub-item"
          ])}
          onClick={clickHandler}
        >
          {identifyingIcon}
          {item?.name && isExpanded && (
            <span
              className={classNames([
                "secondary-nav-item__name",
                item?.isActive && "secondary-nav-item__name--active"
              ])}
            >
              {item?.name}
            </span>
          )}
          {isExpanded && item?.items?.length > 0 && (
            <Icon
              className={classNames([
                "material-icons",
                "secondary-nav-item__expand",
                getDisabledClass(),
                "reveal"
              ])}
              name={isMenuItemExpanded ? "expand_less" : "expand_more"}
            />
          )}
        </div>
        {(!isExpanded || isLongName) && (
          <OTTooltip
            visible={showTooltip}
            content={
              <div className="secondary-nav-item__tooltip">
                {item?.name}
                {item?.items?.length > 0 && (
                  <Icon name="expand_more" size={IconSize.XS} />
                )}
              </div>
            }
          />
        )}
      </div>
      {renderSubItems()}
    </>
  );
};

SecondaryNavItem.defaultProps = {
  isActive: false,
  isSubItem: false
};

SecondaryNavItem.propTypes = {
  isExpanded: PropTypes.bool,
  isMenuItemExpanded: PropTypes.bool,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  showToolTip: PropTypes.bool,
  item: PropTypes.object,
  onClickNavigate: PropTypes.func,
  isSubItem: PropTypes.bool
};

export default SecondaryNavItem;
