export const queryConstants = {
  queryListNumError:
    "This type of request needs one file attachment, (and it needs to be in XLSX format). Make sure there is exactly one file attached and try again.",
  queryListFormatError:
    "This file format will not work for a Query List request. Please attach an Excel file in .XLSX format.",
  queryListMimes: ["xlsx"],
  queryErrorStatus: {
    error: "error",
    noError: "no error"
  }
};
