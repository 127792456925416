import React from "react";

import PropTypes from "prop-types";

import { Icon, IconDesignStyle, IconSize } from "@atoms/Icon";

import "./ReviewButton.scss";

const ReviewButton = ({ id, hidden, onClick, hoverText, filled }) => {
  return (
    <>
      {!hidden && (
        <div
          onClick={e => onClick?.(e, id)}
          className={`review-button ${filled ? "review-button--filled" : ""}`}
          style={filled ? { visibility: "visible" } : {}}
        >
          <Icon
            size={IconSize.L}
            hoverElement={filled ? hoverText : undefined}
            designStyle={IconDesignStyle.MATERIAL_ICONS}
            name={filled ? "star" : "star_outlined"}
          />
        </div>
      )}
    </>
  );
};

ReviewButton.propTypes = {
  id: PropTypes.number,
  hoverText: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool
};

export default ReviewButton;
