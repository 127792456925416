import React from "react";

import PropTypes from "prop-types";

import { ActionDropdown } from "@molecules/ActionDropdown";

import "./ProjectTableMenu.scss";

const ProjectTableMenu = props => {
  const { menuItems, onClickMenuItem, row } = props;
  return (
    <td className="table-data table-data--menu">
      {!!menuItems.length && (
        <ActionDropdown
          menuItems={menuItems}
          onMenuItemClick={menuItem => onClickMenuItem(menuItem.key, row)}
        />
      )}
    </td>
  );
};

ProjectTableMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  onClickMenuItem: PropTypes.func.isRequired
};

export default ProjectTableMenu;
