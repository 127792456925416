import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

import { authService } from "@shared/services";

let appInsights;

const getUserId = () => {
  try {
    return JSON.parse(atob(localStorage.token.split(".")[1])).id;
  } catch (error) {
    return "NA";
  }
};

export async function AppInsights({ reactPlugin }) {
  const userId = getUserId();
  if (!appInsights && reactPlugin) {
    const browserHistory = createBrowserHistory({
      basename: ""
    });

    const hostBrandWithDetails = await authService.getHostWithBrandDetails();
    const connectionString =
      hostBrandWithDetails?.host?.properties?.monitoring?.applicationInsights
        ?.connectionString || "";
    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(envelope => {
      envelope.data.properties = JSON.stringify({
        hostname: window.location.hostname,
        userId
      });
    });
  }

  return {
    appInsights
  };
}
