import React, { useCallback, useEffect } from "react";

import PropTypes from "prop-types";

import { useAuthUser, useUserProfile } from "@shared/hooks";

import HeaderMenu from "@components/molecules/HeaderMenu";

import "./LanguageToggle.scss";

const LanguageToggle = ({ languages, defaultLng }) => {
  const { user } = useAuthUser();
  const { userProfile, updateUserProfile, onChangeUserProfile } =
    useUserProfile();

  useEffect(() => {
    if (
      userProfile &&
      userProfile.properties?.languagePreferences?.UILanguage === undefined
    ) {
      onChangeUserProfile({
        properties: {
          ...userProfile.properties,
          languagePreferences: {
            ...userProfile.properties?.languagePreferences,
            UILanguage: defaultLng
          }
        }
      });
    }
  }, [userProfile, onChangeUserProfile, defaultLng]);

  const onLanguageSelected = useCallback(
    language => {
      updateUserProfile({
        ...user,
        ...userProfile,
        properties: {
          ...userProfile.properties,
          languagePreferences: {
            ...userProfile.properties?.languagePreferences,
            UILanguage: language
          }
        }
      });
    },
    [updateUserProfile, user, userProfile]
  );

  const transformData = languages?.map(language => {
    return {
      name: language?.label,
      value: language?.key,
      onClick: () => onLanguageSelected(language?.key)
    };
  });

  return (
    <HeaderMenu
      menuItems={transformData}
      type={"action"}
      icon={"language"}
      value={{
        value: userProfile?.properties?.languagePreferences?.UILanguage
      }}
      isNeverActive
    />
  );
};
LanguageToggle.propTypes = {
  languages: PropTypes.array,
  defaultLng: PropTypes.string
};

export default LanguageToggle;
