import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import {
  useAuthUser,
  useCurrentProject,
  useDownloadVisualisationWorkbook,
  useGetClientFromParams,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useShareStatus,
  useVisualisation
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";

import { routeConstants } from "@app/constants";

import { Button } from "@atoms/Button";

import Toggle from "@components/atoms/Toggle";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import FinalPackageDocumentsPanel from "@components/organisms/FinalPackageDocumentsPanel";
import FinalPackageTips from "@components/organisms/FinalPackageTips";
import FinalWorkpaperPanel from "@components/organisms/FinalWorkpaperPanel";
import FinalisationProcessPanel from "@components/organisms/FinalisationProcessPanel/FinalisationProcessPanel";
import RejectActionModalForm from "@components/organisms/RejectActionModalForm/RejectActionModalForm";
import SignedPackageUpload from "@components/organisms/SignedPackageUpload/SignedPackageUpload";
import Visualisation from "@components/organisms/Visualisation";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";
import SplitSectionTemplate from "@components/templates/SplitSectionTemplate/SplitSectionTemplate";

import "./FinalPackage.scss";

export const getHiddenClass = (data, user) => {
  if (user?.isHostUser) {
    return "";
  } else if (!data) {
    return "__hidden";
  } else {
    const filterVisualisationDocuments = Object.values(data).filter(
      item => item !== null
    );
    if (filterVisualisationDocuments.length === 0) {
      return "__hidden";
    }
  }
  return "";
};

const FinalPackage = () => {
  const { projectId } = useParams();
  const { currentProject: project, onChangeCurrentProject } =
    useCurrentProject();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || project?.id === projectId }
    );

  const [rejectQuery, setRejectQuery] = useState(null);
  const { user } = useAuthUser();
  const navigate = useNavigate();

  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const {
    finalPackageShareStatus,
    setShareStatus,
    getShareStatus,
    isFinalPackageShareStatusInitialised
  } = useShareStatus(project);

  const { client } = useGetClientFromParams();
  const { workbookDocument, visualisationDocuments } = useVisualisation();
  const { downloadVisualisationWorkbook, isDownloading } =
    useDownloadVisualisationWorkbook();
  const [disableDownload, setDisableDownload] = useState(false);
  const { isProjectMember } = useGetProjectMembers(project);
  const handleClickReject = query => {
    setRejectQuery(query);
  };

  const disableVisualisationDownload = usePubSub();
  const didMount = useRef(false);

  useEffect(() => {
    if (projectToSelect && project?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, project, onChangeCurrentProject]);

  useEffect(() => {
    if (isInvalidProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [isInvalidProject, navigate]);

  useEffect(() => {
    if (project && !project.configuration?.features?.finalPackage?.enabled) {
      navigate("/");
    }
  }, [
    navigate,
    project,
    project?.configuration?.features?.finalPackage?.enabled
  ]);

  useEffect(() => {
    if (!didMount.current) {
      disableVisualisationDownload.subscribe(
        systemConstants.project.events.projects
          .refreshProjectVisualisationDownloadStatus
      );
      didMount.current = true;
    }
  }, [disableVisualisationDownload]);

  useEffect(() => {
    getShareStatus();
  }, [getShareStatus, project]);

  useEffect(() => {
    if (disableVisualisationDownload?.value?.disable || !workbookDocument) {
      setDisableDownload(true);
    } else if (disableVisualisationDownload?.value?.document) {
      setDisableDownload(false);
    } else if (workbookDocument) {
      setDisableDownload(false);
    }
  }, [workbookDocument, disableVisualisationDownload]);

  useEffect(() => {
    if (!isFinalPackageShareStatusInitialised) {
      return;
    }
    if (!user.isHostUser && finalPackageShareStatus === false) {
      setRedirect(true);
      setErrorMessage(
        "The Final Package is not available at this point in time. Please check with your Project Manager for more information."
      );
      setShowProjectAccessModal(true);
    }
  }, [user, finalPackageShareStatus, isFinalPackageShareStatusInitialised]);

  const rightBody = () => (
    <div className="final-package__sidebar">
      <FinalWorkpaperPanel
        project={project}
        isProjectMember={isProjectMember}
        handleClickReject={handleClickReject}
      />
      <FinalPackageDocumentsPanel
        project={project}
        isProjectMember={isProjectMember}
        handleClickReject={handleClickReject}
      />
      <FinalisationProcessPanel
        project={project}
        isProjectMember={isProjectMember}
      ></FinalisationProcessPanel>
    </div>
  );
  const leftSidebar = () => (
    <div className="final-package__sidebar">
      <FinalPackageTips project={project} isProjectMember={isProjectMember} />
      <SignedPackageUpload project={project} />
    </div>
  );

  const handleClose = () => {
    setRejectQuery(null);
  };

  const projectError = msg => {
    setErrorMessage(msg);
    setShowProjectAccessModal(true);
  };

  const handleChange = e => {
    isProjectMember(
      user,
      `${!e.target.checked ? "un" : ""}publish final package`,
      () => {
        setShareStatus(e.target.checked);
      },
      projectError
    );
  };

  const handleDeleteVisualisation = useCallback(() => {
    setDisableDownload(true);
  }, []);

  const handleDownloadVisualisation = useCallback(() => {
    if (!isDownloading) {
      downloadVisualisationWorkbook(project?.id);
    }
  }, [downloadVisualisationWorkbook, isDownloading, project?.id]);

  return project ? (
    <MainPageTemplate>
      <div style={{ position: "relative" }}>
        <div
          className={`final-package__background final-package__container final-package${getHiddenClass(
            visualisationDocuments,
            user
          )}`}
        ></div>
        <PageBodyTemplate
          title={`${client ? `${client.name} - ` : ""}${project.name}`}
          subheading={
            project.configuration?.finalPackage?.name || "Final Package"
          }
          actions={
            <>
              {user?.isHostUser && (
                <div className="final-package__toggle">
                  <Toggle
                    label="Visible to client"
                    value={finalPackageShareStatus}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div
                className={`final-package${getHiddenClass(
                  workbookDocument,
                  user
                )}`}
              >
                <Button
                  iconName="download"
                  label="Download Visualisation"
                  disabled={disableDownload || isDownloading}
                  onClick={handleDownloadVisualisation}
                />
              </div>
            </>
          }
        >
          <div
            className={`final-package__images final-package__container final-package${getHiddenClass(
              visualisationDocuments,
              user
            )}`}
          >
            <Visualisation
              project={project}
              isProjectMember={isProjectMember}
              onDelete={handleDeleteVisualisation}
            />
          </div>

          <SplitSectionTemplate left={leftSidebar()} right={rightBody()} />
        </PageBodyTemplate>
        <RejectActionModalForm
          query={rejectQuery}
          visibility={!!rejectQuery}
          handleSubmit={handleClose}
          handleClose={handleClose}
          document={rejectQuery?.attachments[0]}
          title={"Workpaper"}
        />
      </div>
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={errorMessage}
        title={redirect && "Final Package is no longer available"}
        handleClose={() => {
          if (redirect) {
            navigate(routeConstants.project.summary, {
              state: {
                project
              }
            });
          }
          setShowProjectAccessModal(false);
          setErrorMessage("");
        }}
        handleOutsideClick={false}
      />
    </MainPageTemplate>
  ) : (
    <></>
  );
};

export default FinalPackage;
