import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import {
  smartFormActions,
  smartFormResponseType
} from "@app/helpers/smartForm";
import { getLabelAndIconForOpenModalButton } from "@app/helpers/smartForm/smartFormHelper";
import {
  SmartFormEntityType,
  SmartFormQuestionType
} from "@app/helpers/smartForm/smartFormTypes";

import { Button, ButtonVariant, ButtonSize } from "@atoms/Button";

import SmartFormEntityProgress from "../SmartFormEntityProgress";

interface SmartFormPerEntityResponseProps {
  responseType: string;
  question: SmartFormQuestionType;
  disableAnswerSubmissions: boolean;
  handlers: { [key: string]: () => void };
  relevantEntitiesById: { [key: string]: SmartFormEntityType };
  relevantEntitiesTotal: number;
}

export const SmartFormPerEntityResponse = ({
  responseType,
  question,
  disableAnswerSubmissions,
  handlers,
  relevantEntitiesById,
  relevantEntitiesTotal
}: SmartFormPerEntityResponseProps) => {
  const { t } = useTranslation();
  const renderSmartFormEntityProgress = useCallback(
    () => (
      <SmartFormEntityProgress
        assignedEntities={question.assignedEntities}
        answeredEntities={question.answeredEntities}
        relevantEntitiesById={relevantEntitiesById}
        questionRelevantEntities={question.relevantEntities}
        onClick={() => handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)}
        responseType={responseType}
        text={t("requests:requests.ui.smartForm.incompleteEntities.label")}
      />
    ),
    [handlers, question, relevantEntitiesById, responseType, t]
  );

  if (
    [smartFormResponseType.DOCUMENT, smartFormResponseType.WEBSHEET].includes(
      responseType
    )
  ) {
    if (relevantEntitiesTotal <= 1) {
      return <></>;
    }

    if (
      // Has 1 answer and answered all entities in that 1 answer
      question.answers.length > 0 &&
      relevantEntitiesTotal > 1
    ) {
      return renderSmartFormEntityProgress();
    }

    const { label, icon } = getLabelAndIconForOpenModalButton(
      responseType,
      question.answers?.length,
      disableAnswerSubmissions,
      t
    );
    return (
      <div className="smart-form-table__action-button">
        <Button
          iconName={icon}
          iconOutlined={true}
          variant={ButtonVariant.TEXT_PRIMARY}
          size={ButtonSize.SMALL}
          skipTextTransform
          label={label}
          onClick={() =>
            handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
          }
          disabled={question.answers?.length === 0 && disableAnswerSubmissions}
        />
      </div>
    );
  }

  const noAssignedEntities = question.assignedEntities?.length === 0;

  if (noAssignedEntities && disableAnswerSubmissions) {
    return <></>;
  }
  if (
    noAssignedEntities &&
    relevantEntitiesTotal > 1 &&
    (!question.relevantEntities || question.relevantEntities?.length > 1)
  ) {
    return (
      <div className="smart-form-table__action-button">
        <Button
          label={t("requests:requests.ui.smartForm.answerPerEntity.label")}
          onClick={() =>
            handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
          }
          iconName="lan"
          iconOutlined={true}
          size={ButtonSize.SMALL}
          variant={ButtonVariant.TEXT_PRIMARY}
          skipTextTransform
        />
      </div>
    );
  } else if (relevantEntitiesTotal > 1) {
    return renderSmartFormEntityProgress();
  }
  return <></>;
};
