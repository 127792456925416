import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { useUpdateProjectMemberMutation } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";

import ModalForm from "@components/molecules/ModalForm";
import ProjectUserForm from "@components/molecules/ProjectUserForm";

const UpdateProjectUser = props => {
  const { onClose, user, project } = props;

  const [updateUserToProject, { isError, error }] =
    useUpdateProjectMemberMutation();
  const { t } = useTranslation();

  const entities = useMemo(() => {
    if (!user) {
      return [];
    }
    return project.entities
      .filter(p => user.entities?.includes(p.externalId))
      .map(e => ({ name: e.name, value: e.externalId }));
  }, [project.entities, user]);

  const clientOption = useMemo(
    () => ({
      ...project.client,
      isClient: true,
      users: [user]
    }),
    [project.client, user]
  );

  const defaultValues = useMemo(() => {
    if (!user) {
      return null;
    }

    return {
      memberOf: clientOption,
      member: {
        name: user.name,
        value: user.id
      },
      entities
    };
  }, [clientOption, entities, user]);

  const entitiesEnabled = project.configuration?.entities?.enabled ?? false;

  const handleSubmit = data => {
    if (!entitiesEnabled) {
      return;
    }
    const payload = {
      projectId: project.id,
      projectMember: {
        entities: data.entities?.map(e => e.value) ?? []
      },
      memberId: data.member.value
    };

    updateUserToProject(payload)
      .unwrap()
      .then(() => onClose());
  };

  return (
    <ModalForm
      title={t("common:ui.projects.updateUser.title")}
      handleCancel={onClose}
      handleSubmit={handleSubmit}
      submitLabel={t("common:ui.forms.update.label")}
      defaultValues={defaultValues}
    >
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      <ProjectUserForm
        entities={project.entities}
        entitiesEnabled={entitiesEnabled}
        editMode={true}
      />
    </ModalForm>
  );
};

UpdateProjectUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number,
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        externalId: PropTypes.number
      })
    )
  })
};

export default UpdateProjectUser;
