import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { utilities } from "@shared/helpers";
import {
  projectDashboardServiceUtil,
  useAuthUser,
  useGetFiltersForDashboardQuery,
  useGetToDoListActionItemsQuery,
  useLazyGetProjectsForDashboardQuery
} from "@shared/hooks";
import { useUIConfig } from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";

import ProjectPanelList from "@app/pages/ProjectsDashboard/ProjectPanelList";
import { AccessLevel, ResourceName } from "@app/types";
import { useAppStore } from "@app/useAppStore";

import ProjectDashboardFilter from "@components/molecules/ProjectDashboardFilter";
import DraftProjectDeleteModal from "@components/organisms/DraftProjectDeleteModal";
import DraftProjectPublishModal from "@components/organisms/DraftProjectPublishModal";

import ProjectsDashboardTemplate from "./ProjectsDashboardTemplate";
import ProjectsTable from "./ProjectsTable";

const getScrollPercentage = target => {
  const scrollTop = target.scrollTop;
  const scrollHeight = target.scrollHeight;
  const clientHeight = target.clientHeight;
  return (scrollTop / (scrollHeight - clientHeight)) * 100;
};

const ProjectsDashboard = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const { uiConfig } = useUIConfig();
  const isMounted = useRef(false);
  const viewType = useAppStore(state => state.projectsDashboard.viewType);
  const setViewType = useAppStore(state => state.setProjectsDashboardViewType);
  const [projectFilterAndPageId, setProjectFilterAndPageId] = useState();
  const dispatch = useDispatch();
  const { data: filters } = useGetFiltersForDashboardQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const isToDoListEnabled = uiConfig.todoList?.enabled;
  const { data: toDoListQueries } = useGetToDoListActionItemsQuery(null, {
    skip: !isToDoListEnabled,
    refetchOnMountOrArgChange: true
  });

  const [
    fetchProjectsForDashboardQuery,
    { isFetching, data: latestFetchedPaginatedProject }
  ] = useLazyGetProjectsForDashboardQuery();

  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();

  const fetchProjects = useCallback(
    async ({ params, preferCache = true }) => {
      fetchProjectsForDashboardQuery(params, preferCache)
        .unwrap()
        .then(paginatedProjects => {
          if (params.pageId === 1) {
            //for when a filter is applied / on page load
            setProjects(paginatedProjects);
          } else {
            setProjects(prev =>
              utilities.getUniqueArrayOfObjectsById(prev, paginatedProjects)
            );
          }
        });
    },
    [fetchProjectsForDashboardQuery]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    //invalidate project cache on mount, to ensure latest
    dispatch(projectDashboardServiceUtil.invalidateTags(["ProjectDashboard"]));
  }, [dispatch, fetchProjects]);

  useEffect(() => {
    //fetch projects on filter/page change
    if (projectFilterAndPageId) {
      fetchProjects({ params: projectFilterAndPageId });
    }
  }, [fetchProjects, projectFilterAndPageId]);

  useEffect(() => {
    sessionStorage.removeItem("lastClient");
  }, []);

  const onProjectPanelScroll = useCallback(
    e => {
      e.preventDefault();
      const scrollPercentage = getScrollPercentage(e.target);
      if (
        scrollPercentage > 50 &&
        !isFetching &&
        latestFetchedPaginatedProject?.length > 0
      ) {
        setProjectFilterAndPageId(prev => ({
          ...prev,
          pageId: prev.pageId + 1
        }));
      }
    },
    [isFetching, latestFetchedPaginatedProject]
  );
  const handleFilterChange = useCallback(val => {
    //pageId should reset when filter changes
    setProjectFilterAndPageId({ ...val, pageId: 1 });
  }, []);

  const [projectToPublishId, setProjectToPublishId] = useState();
  const [projectDeleteId, setProjectDeleteId] = useState();

  useEffect(() => {
    registerModal?.("draftProjectPublish", () => (
      <DraftProjectPublishModal
        projectId={projectToPublishId}
        handleCloseModal={() => {
          setProjectToPublishId(undefined);
          handleCloseModal();
        }}
        refreshAfterPublish={() => {
          navigate(`${routeConstants.projects}/${projectToPublishId}`);
          setProjectToPublishId(undefined);
        }}
      />
    ));
  }, [
    registerModal,
    projectToPublishId,
    handleCloseModal,
    fetchProjects,
    projectFilterAndPageId,
    navigate
  ]);

  useEffect(() => {
    if (projectToPublishId) {
      handleOpenModal?.("draftProjectPublish");
    }
  }, [handleOpenModal, projectToPublishId]);

  useEffect(() => {
    registerModal?.("draftProjectDelete", () => (
      <DraftProjectDeleteModal
        projectId={projectDeleteId}
        handleCloseModal={() => {
          fetchProjects({ params: projectFilterAndPageId, preferCache: false });
          setProjectDeleteId(undefined);
          handleCloseModal();
        }}
      />
    ));
  }, [
    registerModal,
    projectDeleteId,
    handleCloseModal,
    fetchProjects,
    projectFilterAndPageId,
    navigate
  ]);

  useEffect(() => {
    if (projectDeleteId) {
      handleOpenModal?.("draftProjectDelete");
    }
  }, [handleOpenModal, projectDeleteId]);

  return (
    <ProjectsDashboardTemplate
      filtersView={
        <ProjectDashboardFilter
          filters={filters}
          handleChange={handleFilterChange}
        />
      }
      projectsPanelView={
        <ProjectPanelList
          projects={projects}
          isFetching={!isMounted.current || isFetching}
          onScrollHandler={onProjectPanelScroll}
          handlePublishProject={setProjectToPublishId}
          handleDeleteProject={setProjectDeleteId}
        />
      }
      projectsTableView={
        <ProjectsTable
          projects={projects}
          scrollHandler={onProjectPanelScroll}
          isFetching={!isMounted.current || isFetching}
          handlePublishProject={setProjectToPublishId}
        />
      }
      viewType={viewType}
      onChangeViewType={setViewType}
      showNoResultsFoundMessage={!projects?.length && !isFetching}
      enableCreateProject={user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      )}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
      toDoListEnabled={isToDoListEnabled}
      toDoListQueries={toDoListQueries}
    />
  );
};

export default ProjectsDashboard;
