import React from "react";

import PropTypes from "prop-types";

import { Box, Inline, Stack } from "@fermions";

import "./BoxTemplate.scss";

const BoxTemplate = props => {
  const {
    title,
    status,
    subtext,
    action,
    boxClassName,
    additionalClassNames = [],
    size,
    dataTestId,
    stickyTitle
  } = props;
  const colorSchemeClass = () => `box-template--${props.colorScheme}`;
  const sizeClass = () => {
    switch (size) {
      case "medium":
        return "box-template--medium";
      case "large":
        return "box-template--large";
      default:
        return "";
    }
  };

  return (
    <Stack
      className={[
        "box-template",
        colorSchemeClass(),
        sizeClass(),
        boxClassName,
        ...(additionalClassNames ?? [])
      ]}
      data-testid={dataTestId ?? null}
    >
      {(title || subtext || action) && (
        <Inline
          className={`box-template__header${stickyTitle ? "--sticky" : ""}`}
          style={
            stickyTitle
              ? {
                  top: "0px"
                }
              : {}
          }
          width="100"
        >
          <Stack gap="050" className="box-template__heading">
            <Inline gap="100" alignment="left">
              <h4 className="box-template__title">{title}</h4>
              {status && <div className="box-template__status"> {status}</div>}
            </Inline>
            {subtext && <div className="box-template__subtext">{subtext}</div>}
          </Stack>
          {action && (
            <Inline gap="100" className="box-template__action">
              {action}
            </Inline>
          )}
        </Inline>
      )}
      <Box
        className={[
          "box-template__content",
          boxClassName ? `${boxClassName}__content` : ""
        ]}
      >
        {props.children}
      </Box>
    </Stack>
  );
};

BoxTemplate.defaultProps = {
  colorScheme: "white"
};

BoxTemplate.propTypes = {
  boxClassName: PropTypes.string,
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.node,
  subtext: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
  colorScheme: PropTypes.oneOf(["white", "transparent"]),
  size: PropTypes.oneOf(["large", "medium"]),
  dataTestId: PropTypes.string,
  stickyTitle: PropTypes.bool
};

export default BoxTemplate;
