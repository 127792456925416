import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";
import { utilities } from "@shared/helpers/utilities";

export function useGetProjectTags(projectId) {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );

  const getProjectTags = useCallback(
    id => {
      if (!id) return;
      dispatch(manageProjectDocumentsActions.getAllTags({ id }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!initialized && projectId) {
      getProjectTags(projectId);
      setInitialized(true);
    }
  }, [getProjectTags, initialized, projectId]);

  useEffect(() => {
    return () => {
      dispatch(manageProjectDocumentsActions.resetAllTags());
    };
  }, [dispatch]);

  useEffect(() => {
    setTags(
      utilities.sortBy("name")(
        manageProjectDocuments.tags?.filter(t => t.projectId)
      )
    );
  }, [manageProjectDocuments.tags]);

  useEffect(() => {
    setIsLoading(manageProjectDocuments.fetchingProjectTags);
  }, [manageProjectDocuments.fetchingProjectTags]);

  useEffect(() => {
    setError(manageProjectDocuments.error);
  }, [manageProjectDocuments.error]);

  return {
    projectTags: tags ?? [],
    loadingProjectTags: isLoading,
    errorFetchingProjectTags: error,
    fetchProjectTags: getProjectTags
  };
}
