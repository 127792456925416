import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Button } from "@atoms/Button";

import ModalTemplate from "@components/templates/ModalTemplate";

import "./ApplicationNotification.scss";

const notificationTypes = {
  error: { icon: "error" },
  warning: { icon: "warning" },
  info: { icon: "check_circle" }
};

const ApplicationNotification = ({ title, type, message, clearMessage }) => {
  const [visibility, setVisibility] = useState(!!message);

  useEffect(() => {
    if (message) {
      setVisibility(true);
    }
  }, [message]);

  const onClose = () => {
    setVisibility(false);
    clearMessage && clearMessage();
  };

  const content = (
    <>
      <div
        className={`application-notification__icon application-notification__icon-${type}`}
      >
        <i className="material-icons">
          {notificationTypes[type].icon || "error"}
        </i>
      </div>
      <div className={"application-notification__message"}>{message}</div>
    </>
  );

  const footer = <Button label="OK" onClick={onClose} />;

  return (
    <>
      {visibility && (
        <div className="application-notification__container">
          <ModalTemplate
            boxClassName={"application-notification"}
            title={title}
            onClose={onClose}
            content={content}
            footer={footer}
          ></ModalTemplate>
        </div>
      )}
    </>
  );
};

ApplicationNotification.defaultProps = {
  type: "warning"
};

ApplicationNotification.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(notificationTypes)),
  message: PropTypes.string,
  clearMessage: PropTypes.func
};

export default ApplicationNotification;
