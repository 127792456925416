import { manageDashboardConstants } from "../constants";

const initialState = {
  todosLoading: false,
  contactsLoading: false,
  projectsLoading: false,
  updatedTodoLoading: false,
  projects: [],
  updatedTodo: null,
  error: ""
};

export function manageDashboard(state = initialState, action = {}) {
  switch (action.type) {
    case manageDashboardConstants.UPDATE_TODO_STATUS_REQUEST:
      return {
        ...state,
        updatedTodoLoading: true
      };
    case manageDashboardConstants.UPDATE_TODO_STATUS_SUCCESS:
      const updatedTodos = state.todos.map(todo => {
        if (todo.id === action.updatedTodo.todoId) {
          todo.completed = action.updatedTodo.completed;
        }
        return todo;
      });

      return {
        ...state,
        updatedTodoLoading: false,
        updatedTodo: action.updatedTodo,
        todos: updatedTodos,
        error: ""
      };
    case manageDashboardConstants.UPDATE_TODO_STATUS_FAILURE:
      return {
        ...state,
        updatedTodoLoading: false,
        updatedTodo: null,
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageDashboardConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
