import React from "react";

import PropTypes from "prop-types";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { SizeMe } from "react-sizeme";

import gridStyles from "./InsightsGridViewTemplate.module.scss";

const InsightsGridViewTemplate = props => {
  const { children, layouts, cols, rowHeight } = props;
  const gridGap = Array(2).fill(+gridStyles["ot-grid-gap"].match(/\d+/g));

  return (
    <SizeMe>
      {({ size }) => (
        <ResponsiveGridLayout
          className="ot-insights-grid-view-template"
          layouts={layouts}
          breakpoints={{ lg: 1200, sm: 768 }}
          cols={{ lg: cols, sm: 2 }}
          rowHeight={rowHeight}
          width={size.width}
          containerPadding={[0, 0]}
          margin={gridGap}
        >
          {children}
        </ResponsiveGridLayout>
      )}
    </SizeMe>
  );
};

InsightsGridViewTemplate.defaultProps = {
  layouts: { lg: [], sm: [] },
  cols: 12,
  rowHeight: 30
};

InsightsGridViewTemplate.propTypes = {
  layouts: PropTypes.shape({
    sm: PropTypes.arrayOf(
      PropTypes.shape({
        h: PropTypes.number,
        w: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number
      })
    ),
    lg: PropTypes.arrayOf(
      PropTypes.shape({
        h: PropTypes.number,
        w: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number
      })
    )
  }),
  cols: PropTypes.number,
  rowHeight: PropTypes.number,
  children: PropTypes.node
};

export default InsightsGridViewTemplate;
