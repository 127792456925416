export function authHeader(options = {}) {
  if (options.beforeLogin) {
    return { "Content-Type": "application/json" };
  }
  const token = localStorage.getItem("token");
  if (token) {
    const headers = { Authorization: `Bearer ${token}` };
    if (options.documentUpload) {
      return headers;
    }
    return {
      ...headers,
      "Content-Type": "application/json"
    };
  }
  return {};
}
