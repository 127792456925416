import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useFileUploadManagement,
  useUploadProjectDocuments
} from "@shared/hooks";

import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import UploadFile from "@components/molecules/UploadFile";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList";

import "./UploadDocuments.scss";

const addFilesState = systemConstants.addFiles.state;
const attachedFileStates = systemConstants.addFiles.attachedFile.state;

const _UploadDocuments = React.forwardRef((props, fwdRef) => {
  const [selectFileError, setSelectFileError] = useState(null);
  const { onRemoveFile, onAddFiles, files, addExistingAttachmentFiles } =
    useFileUploadManagement();
  const {
    uploadProjectDocuments,
    isUploading,
    uploadsFailed,
    uploadsCompleted,
    uploadError,
    getUploadFileState,
    clearUploadsCompleted
  } = useUploadProjectDocuments();
  const [addFiles, setAddFiles] = useState({});
  const [addFilesChanged, setAddFilesChanged] = useState(false);
  const {
    existingFiles,
    revisionFiles,
    onChange,
    error,
    projectId,
    state,
    maxNumberOfFiles,
    maxNumberOfFilesError,
    onUploadsFailed,
    onUploadsComplete,
    onBlur,
    supportedDocumentMimes,
    supportedDocumentMimesMessage,
    documentType,
    isHideDocumentsList,
    dropMessage,
    disableFileListActions,
    disableUploads,
    hideUpload,
    attachmentsPortal,
    attachmentsColumnMode,
    disableAttachmentList,
    removedFile,
    onFileSelectionChanged,
    label
  } = props;
  const { t } = useTranslation();
  const startUploads = useCallback(() => {
    if (files && Object.keys(files).length > 0) {
      uploadProjectDocuments(files, projectId, documentType);
    } else {
      onUploadsComplete(files);
    }
  }, [
    documentType,
    files,
    onUploadsComplete,
    projectId,
    uploadProjectDocuments
  ]);

  const updateFilesCurrentState = useCallback(
    updateFiles => {
      const filesWithState = { ...updateFiles };
      Object.keys(filesWithState).forEach(filename => {
        const fileState = getUploadFileState(filename);
        if (fileState) {
          filesWithState[filename] = {
            ...filesWithState[filename],
            ...fileState
          };
        }
      });
      return filesWithState;
    },
    [getUploadFileState]
  );

  useEffect(() => {
    const filesWithStatus = updateFilesCurrentState(files);
    setAddFiles(filesWithStatus);
  }, [files, updateFilesCurrentState]);

  useEffect(() => {
    if (state === addFilesState.finished && uploadsCompleted) {
      clearUploadsCompleted();
    }
  }, [clearUploadsCompleted, state, uploadsCompleted]);

  useEffect(() => {
    if (state === addFilesState.upload && !isUploading) {
      startUploads();
    }
  }, [isUploading, state, startUploads]);

  useEffect(() => {
    if (state !== addFilesState.finished && isUploading) {
      const filesWithStatus = updateFilesCurrentState(files);
      setAddFiles(filesWithStatus);
    }
  }, [files, isUploading, state, updateFilesCurrentState]);

  useEffect(() => {
    if (
      state !== addFilesState.finished &&
      uploadsCompleted &&
      onUploadsComplete
    ) {
      const updatedFiles = updateFilesCurrentState(addFiles);
      Object.keys(updatedFiles).forEach(key => {
        const file = updatedFiles[key];
        if (file.state === attachedFileStates.uploadFailed) {
          delete updatedFiles[key];
          onRemoveFile(key);
        }
      });
      onUploadsComplete(updatedFiles);
    }
  }, [
    addFiles,
    onRemoveFile,
    onUploadsComplete,
    state,
    updateFilesCurrentState,
    uploadsCompleted
  ]);

  useEffect(() => {
    if (state !== addFilesState.finished && uploadsFailed && onUploadsFailed) {
      const filesWithStatus = updateFilesCurrentState(files);
      setAddFiles(filesWithStatus);
      onUploadsFailed(uploadError);
    }
  }, [
    files,
    onUploadsFailed,
    state,
    updateFilesCurrentState,
    uploadError,
    uploadsFailed
  ]);

  useEffect(() => {
    if (existingFiles?.length > 0) {
      addExistingAttachmentFiles(existingFiles);
    }
  }, [addExistingAttachmentFiles, existingFiles]);

  useEffect(() => {
    if (revisionFiles?.length > 0) {
      onAddFiles(revisionFiles);
    }
  }, [revisionFiles, onAddFiles]);

  useEffect(() => {
    setAddFilesChanged(true);
    onFileSelectionChanged(addFiles && Object.values(addFiles).length > 0);
  }, [addFiles, onFileSelectionChanged]);

  useEffect(() => {
    if (addFilesChanged) {
      onChange?.(addFiles || []);
      setAddFilesChanged(false);
    }
  }, [addFiles, addFilesChanged, onChange]);

  useEffect(() => {
    setSelectFileError(error);
  }, [error]);

  useEffect(() => {
    if (removedFile) {
      onRemoveFile(removedFile);
    }
  }, [removedFile, onRemoveFile]);

  const onDrop = useCallback(
    acceptedFiles => {
      setSelectFileError(null);
      onAddFiles(acceptedFiles);
    },
    [onAddFiles]
  );

  const onFileRejected = useCallback(
    filesRejected => {
      const rejectReasons = new Set();
      filesRejected.forEach(f => {
        f.errors.forEach(e => {
          rejectReasons.add(e.code);
        });
      });
      if (rejectReasons.has("too-many-files")) {
        setSelectFileError(
          maxNumberOfFilesError
            ? maxNumberOfFilesError
            : `Cannot select more than ${maxNumberOfFiles} document(s) to upload. Please try again`
        );
      } else if (rejectReasons.has("file-invalid-type")) {
        const displayMimes = structuredClone(supportedDocumentMimes)
          .sort()
          .join(", ");
        setSelectFileError(
          supportedDocumentMimesMessage
            ? supportedDocumentMimesMessage
            : t("common:ui.upload.error.fileType.mime", { displayMimes })
        );
      }
    },
    [
      maxNumberOfFilesError,
      maxNumberOfFiles,
      supportedDocumentMimes,
      supportedDocumentMimesMessage,
      t
    ]
  );

  const isDisabled = useMemo(() => {
    if (hideUpload || disableUploads) {
      return true;
    }
    if (!files || !maxNumberOfFiles) {
      return false;
    }
    const numberOfFiles = Object.values(files).length;
    return numberOfFiles >= maxNumberOfFiles;
  }, [files, maxNumberOfFiles, disableUploads, hideUpload]);

  const maybeAttachmentList = useCallback(() => {
    const hasFilesAttached = addFiles && Object.values(addFiles).length;
    const template = hasFilesAttached ? (
      <UploadFileAttachmentList
        attachments={addFiles}
        onDelete={onRemoveFile}
        isHide={isHideDocumentsList}
        disabled={disableFileListActions}
        columnMode={attachmentsColumnMode}
      />
    ) : (
      <></>
    );

    if (attachmentsPortal) {
      return ReactDOM.createPortal(template, attachmentsPortal);
    }
    return template;
  }, [
    addFiles,
    attachmentsColumnMode,
    attachmentsPortal,
    disableFileListActions,
    isHideDocumentsList,
    onRemoveFile
  ]);

  return (
    <div className="ot-form-upload-documents" ref={fwdRef} onBlur={onBlur}>
      <UploadFile
        handleDrop={onDrop}
        supportedDocumentMimes={supportedDocumentMimes}
        dropMessage={dropMessage}
        disabled={isDisabled}
        maxNumberOfFiles={maxNumberOfFiles}
        label={label}
        handleRejection={onFileRejected}
        hideUpload={hideUpload}
      />
      {selectFileError && (
        <InlineAlert type="error" message={t(selectFileError)} />
      )}
      {!disableAttachmentList && maybeAttachmentList()}
    </div>
  );
});

function UploadDocuments(props) {
  const {
    name,
    projectId,
    required,
    state,
    existingFiles,
    onUploading,
    onUploadsComplete,
    onUploadsFailed,
    supportedDocumentMimes,
    supportedDocumentMimesMessage,
    maxNumberOfFiles,
    isHideDocumentsList,
    maxNumberOfFilesError,
    errorMessage,
    documentType,
    dropMessage,
    disableFileListActions,
    disableUploads,
    hideUpload,
    attachmentsPortal,
    attachmentsColumnMode,
    disableAttachmentList,
    removedFile,
    revisionFiles,
    label,
    onFileSelectionChanged,
    t
  } = props;
  const {
    formState: { errors },
    control
  } = useFormContext();

  const getError = useCallback(() => {
    if (name && errors && errors[name] && errors[name].message) {
      return errors[name].message;
    } else if (errorMessage) {
      return errorMessage;
    } else return "";
  }, [errorMessage, errors, name]);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <_UploadDocuments
            ref={ref}
            error={getError()}
            projectId={projectId}
            required={required}
            state={state}
            existingFiles={existingFiles}
            onUploading={onUploading}
            onUploadsComplete={onUploadsComplete}
            onUploadsFailed={onUploadsFailed}
            onChange={files => {
              onChange(files);
              props.onChange?.(files);
            }}
            onBlur={onBlur}
            supportedDocumentMimes={supportedDocumentMimes}
            supportedDocumentMimesMessage={supportedDocumentMimesMessage}
            documentType={documentType}
            maxNumberOfFiles={maxNumberOfFiles}
            maxNumberOfFilesError={maxNumberOfFilesError}
            isHideDocumentsList={isHideDocumentsList}
            dropMessage={dropMessage}
            disableFileListActions={disableFileListActions}
            disableUploads={disableUploads}
            hideUpload={hideUpload}
            attachmentsPortal={attachmentsPortal}
            attachmentsColumnMode={attachmentsColumnMode}
            disableAttachmentList={disableAttachmentList}
            removedFile={removedFile}
            revisionFiles={revisionFiles}
            t={t}
            label={label}
            onFileSelectionChanged={onFileSelectionChanged}
          />
        );
      }}
    />
  );
}

_UploadDocuments.defaultProps = {
  disableFileListActions: false,
  disableUploads: false,
  hideUpload: false,
  disableAttachmentList: false,
  onFileSelectionChanged: () => {}
};

_UploadDocuments.propTypes = {
  projectId: PropTypes.number,
  documentType: PropTypes.oneOf(["", "queryListDocument"]),
  onChange: PropTypes.func,
  state: PropTypes.oneOf(["add", "upload", "finished"]),
  existingFiles: PropTypes.array,
  onUploadsComplete: PropTypes.func,
  onUploadsFailed: PropTypes.func,
  maxNumberOfFiles: PropTypes.number,
  maxNumberOfFilesError: PropTypes.string,
  disableFileListActions: PropTypes.bool,
  disableUploads: PropTypes.bool,
  hideUpload: PropTypes.bool,
  disableAttachmentList: PropTypes.bool,
  supportedDocumentMimes: PropTypes.array,
  supportedDocumentMimesMessage: PropTypes.string,
  dropMessage: PropTypes.string,
  attachmentsPortal: PropTypes.object,
  attachmentsColumnMode: PropTypes.string,
  onFileSelectionChanged: PropTypes.func
};

UploadDocuments.defaultProps = {
  disableFileListActions: false,
  disableUploads: false,
  hideUpload: false,
  disableAttachmentList: false,
  onFileSelectionChanged: () => {}
};

UploadDocuments.propTypes = {
  name: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  documentType: PropTypes.oneOf(["", "queryListDocument"]),
  onChange: PropTypes.func,
  state: PropTypes.oneOf(["add", "upload", "finished"]),
  existingFiles: PropTypes.array,
  onUploading: PropTypes.func,
  onUploadsComplete: PropTypes.func,
  onUploadsFailed: PropTypes.func,
  maxNumberOfFiles: PropTypes.number,
  maxNumberOfFilesError: PropTypes.string,
  errorMessage: PropTypes.string,
  disableFileListActions: PropTypes.bool,
  disableUploads: PropTypes.bool,
  hideUpload: PropTypes.bool,
  disableAttachmentList: PropTypes.bool,
  supportedDocumentMimes: PropTypes.array,
  supportedDocumentMimesMessage: PropTypes.string,
  dropMessage: PropTypes.string,
  attachmentsPortal: PropTypes.object,
  attachmentsColumnMode: PropTypes.string,
  onFileSelectionChanged: PropTypes.func
};

export default UploadDocuments;
