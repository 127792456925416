import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementProjectsActions } from "@shared/actions";

export function useOpenItems() {
  const [openItems, setOpenItems] = useState({
    openQueries: null,
    openRisks: null
  });
  const manageClientEngagementProjects = useSelector(
    state => state.manageClientEngagementProjects
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenItems(manageClientEngagementProjects.openItems);
  }, [manageClientEngagementProjects.openItems]);

  const clearOpenItems = () => {
    dispatch(manageClientEngagementProjectsActions.clearOpenItems());
  };

  const getOpenRisks = project => {
    dispatch(manageClientEngagementProjectsActions.getOpenRisks(project));
  };

  const getOpenQueries = project => {
    dispatch(manageClientEngagementProjectsActions.getOpenQueries(project));
  };

  return { openItems, getOpenRisks, getOpenQueries, clearOpenItems };
}
