import React, { useCallback, useMemo } from "react";

import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AITFormOptions,
  AITFormatConfig,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import Form from "@atoms/Form/Form.jsx";

import BoxTemplate from "@templates/BoxTemplate/BoxTemplate.jsx";
import { InputContainer } from "@templates/InputContainer/InputContainer.tsx";

import { Stack } from "@components/fermions/index.tsx";

import { displayName } from "./actionItemType.tsx";

interface ActionItemTypeFormKeyInformation {
  actionItemType: ActionItemType;
  formOptions: AITFormOptions;
  formatConfig?: AITFormatConfig;
  crudFormMode: CrudFormMode;
}

const ActionItemTypeFormKeyInformation = (
  props: ActionItemTypeFormKeyInformation
) => {
  const {
    actionItemType,
    formatConfig,
    formOptions,
    crudFormMode = CrudFormMode.READ
  } = props;
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const engagementType = watch("engagementType");

  const isReadMode = crudFormMode === CrudFormMode.READ;
  const isLockedFromEdit =
    crudFormMode === CrudFormMode.READ || crudFormMode === CrudFormMode.UPDATE;

  const selectedEngagementType = useMemo(() => {
    return formOptions.engagementTypes.find(
      item => item.id === engagementType?.value
    );
  }, [engagementType, formOptions?.engagementTypes]);

  const formsConfig = useMemo(() => {
    return (
      formatConfig?.formsConfig?.filter(
        ({ section }) => section === "keyInformation"
      ) ?? []
    );
  }, [formatConfig]);

  const actionItemTypeConfigType = useMemo(() => {
    return formOptions.formats.map(item => ({
      name: t("requests:format.name", { context: item.type }),
      value: item.type
    }));
  }, [formOptions.formats, t]);

  const engagementTypes = useMemo(() => {
    return formOptions.engagementTypes.map(item => ({
      name: item.name,
      value: item.id
    }));
  }, [formOptions.engagementTypes]);

  const projectYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear];
    if (actionItemType?.configuration.projectYear) {
      years.push(+actionItemType.configuration.projectYear);
    }

    const oldestDate = Math.min(...years) - 10;
    const newestDate = Math.max(...years) + 10;

    const result = [];
    result.push({
      name: t("common:ui.projects.year.allYears"),
      value: undefined
    });
    for (let i = oldestDate; i <= newestDate; i++) {
      result.push({
        name: i.toString(),
        value: i.toString()
      });
    }
    return result;
  }, [actionItemType?.configuration.projectYear, t]);

  const formConfigForFieldKey = useCallback(
    (key: string) => formsConfig?.find(cfg => cfg.key === key),
    [formsConfig]
  );

  const createableFromOptions = useMemo(() => {
    return (
      formConfigForFieldKey("createFrom")?.options?.map(item => ({
        name: t(`admin:ui.requestTypes.createFrom.option.${item}.label`),
        value: item
      })) ?? []
    );
  }, [formConfigForFieldKey]);

  const singleFromOptions = (options: { value: unknown }[], value: unknown) => {
    return options.find(option => option.value === value);
  };

  const defaultOptions = useMemo(() => {
    if (actionItemType?.configuration?.createFrom) {
      return createableFromOptions.filter(option =>
        actionItemType?.configuration?.createFrom?.includes(option.value)
      );
    }
    if (createableFromOptions.length === 1) {
      return createableFromOptions.slice(0, 1);
    }
    return [];
  }, [createableFromOptions, actionItemType?.configuration?.createFrom]);

  const isCreatableFromLockedForEdit = useMemo(() => {
    if (isReadMode) {
      return true;
    }
    if (createableFromOptions.length === 1) {
      return createableFromOptions.every(opt =>
        defaultOptions.find(def => def.value === opt.value)
      );
    }
    return false;
  }, [createableFromOptions, defaultOptions, isReadMode]);

  const aitDisplayName = useMemo(
    () => displayName(actionItemType, t),
    [actionItemType, t]
  );
  const isFieldPresent = useCallback(
    (fieldKey: string) => {
      if (fieldKey !== "projectYear") {
        return true;
      }
      const context = {
        engagementType: selectedEngagementType
      };
      const isVisible = get(
        context,
        "engagementType.configuration.project.yearField.enabled"
      );
      return isVisible;
    },
    [selectedEngagementType]
  );

  return (
    <BoxTemplate title={t("admin:ui.manageRequestTypes.keyInformation.title")}>
      <Stack gap="300">
        <InputContainer>
          <Form.Dropdown
            name="format"
            label={t("admin:ui.requestTypes.format.label")}
            items={actionItemTypeConfigType}
            required
            disabled={isLockedFromEdit}
            defaultValue={singleFromOptions(
              actionItemTypeConfigType,
              actionItemType?.configuration?.type
            )}
            onChange={() => {
              setValue("createFrom", []);
            }}
          />
          <Form.TextField
            name="name"
            label={t("admin:ui.requestTypes.name.label")}
            required
            defaultValue={aitDisplayName}
            disabled={isReadMode}
          />
          <Form.TextField
            name="description"
            label={t("admin:ui.requestTypes.description.label")}
            required={false}
            defaultValue={actionItemType?.configuration?.description}
            disabled={isReadMode}
          />
          <Form.Dropdown
            name="engagementType"
            label={t("admin:ui.requestTypes.engagementTypes.label")}
            items={engagementTypes}
            required
            disabled={isLockedFromEdit}
            defaultValue={singleFromOptions(
              engagementTypes,
              actionItemType?.engagementTypeId
            )}
          />
          {isFieldPresent("projectYear") ? (
            <Form.Dropdown
              name="projectYear"
              label={t("admin:ui.requestTypes.projectYears.label")}
              items={projectYears}
              sortComparator={() => 0}
              disabled={isLockedFromEdit}
              defaultValue={singleFromOptions(
                projectYears,
                actionItemType?.configuration?.projectYear
              )}
            />
          ) : (
            <></>
          )}
          {(() => {
            const cfg = formConfigForFieldKey("createFrom");
            return cfg ? (
              <Form.Multiselect
                name={cfg.key}
                label={t(`admin:ui.requestTypes.${cfg.key}.label`)}
                items={createableFromOptions}
                required
                disabled={isCreatableFromLockedForEdit}
                defaultValue={defaultOptions}
              />
            ) : (
              <></>
            );
          })()}
        </InputContainer>
      </Stack>
    </BoxTemplate>
  );
};

export default ActionItemTypeFormKeyInformation;
