import React from "react";

import PropTypes from "prop-types";

import "./InteractiveReportDataTemplate.scss";

const InteractiveReportDataTemplate = ({
  dataTable,
  filterControls,
  chart,
  grids
}) => {
  return (
    <>
      <div className="ot-interactive-report-data-template">
        <div className={"ot-interactive-report-data-template__main-content"}>
          {grids && <div> {grids}</div>}
          {chart && (
            <div className="ot-interactive-report-data-template__main-content--chart">
              {chart}
            </div>
          )}
          <div className="ot-interactive-report-data-template__main-content--filters">
            {filterControls}
          </div>
          <div className="ot-interactive-report-data-template__main-content--table">
            {dataTable}
          </div>
        </div>
      </div>
    </>
  );
};

InteractiveReportDataTemplate.propTypes = {
  dataTable: PropTypes.node
};

export default InteractiveReportDataTemplate;
