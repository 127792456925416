export const manageArticleConstants = {
  GET_ARTICLES_REQUEST: "GET_ARTICLES_REQUEST",
  GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",
  GET_ARTICLES_FAILURE: "GET_ARTICLES_FAILURE",

  ADD_ARTICLE_REQUEST: "ADD_ARTICLE_REQUEST",
  ADD_ARTICLE_SUCCESS: "ADD_ARTICLE_SUCCESS",
  ADD_ARTICLE_FAILURE: "ADD_ARTICLE_FAILURE",

  EDIT_ARTICLE_REQUEST: "EDIT_ARTICLE_REQUEST",
  EDIT_ARTICLE_SUCCESS: "EDIT_ARTICLE_SUCCESS",
  EDIT_ARTICLE_FAILURE: "EDIT_ARTICLE_FAILURE",

  DELETE_ARTICLE_REQUEST: "DELETE_ARTICLE_REQUEST",
  DELETE_ARTICLE_SUCCESS: "DELETE_ARTICLE_SUCCESS",
  DELETE_ARTICLE_FAILURE: "DELETE_ARTICLE_FAILURE",

  RESET_DELETE_ARTICLE: "RESET_DELETE_ARTICLE",
  RESET_UPDATE_ARTICLE: "RESET_UPDATE_ARTICLE",
  RESET_ADD_ARTICLE: "RESET_ADD_ARTICLE"
};
