import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const engagementTypeService = createApi({
  reducerPath: "engagementTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["EngagementTypes", "EngagementType"],
  endpoints: builder => ({
    getEngagementTypes: builder.query({
      query: () => "/api/engagementTypes",
      providesTags: ["EngagementTypes"]
    }),
    getEngagementTypeById: builder.query({
      query: id => `/api/engagementTypes/${id}`,
      providesTags: ["EngagementType"]
    })
  })
});

export const { useGetEngagementTypesQuery, useGetEngagementTypeByIdQuery } =
  engagementTypeService;
