import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import "./dropdownMenu.scss";

const ItemTypes = {
  text: "text",
  link: "link"
};

const boxWidth = {
  small: "small",
  large: "large"
};

const alignType = {
  left: "left",
  right: "right"
};

const DropdownMenu = props => {
  const [hideMenu, setHideMenu] = useState(true);
  const ref = useRef();
  const handleMenuItemClick = event => {
    event.stopPropagation();
    setHideMenu(!hideMenu);
    if (props.type === ItemTypes.text)
      props.handleMenuItemClick(event.target.textContent, props.data);
  };

  const handleClick = event => {
    event.stopPropagation();
    setHideMenu(!hideMenu);
  };

  useOnClickOutside(ref, event => {
    event.stopPropagation();
    setHideMenu(true);
  });

  return (
    <div ref={ref} className="dropdown">
      <button className="dropbtn" onClick={handleClick}>
        {props.label}
      </button>
      <div
        className={`dropdown-content ${
          props.boxWidth === boxWidth.small ? "dropdown-box-size-small" : null
        } ${hideMenu ? " dropdown-content-hide" : " dropdown-content-show"}`}
        style={
          props.align === alignType.left ? { left: "0%" } : { right: "0%" }
        }
      >
        {props.type === ItemTypes.text
          ? props.menuItems.map(item => (
              <span
                key={item.name}
                className={
                  props.boxWidth === boxWidth.small
                    ? "menulink-small"
                    : "menulink"
                }
                onClick={handleMenuItemClick}
              >
                {item.name}
              </span>
            ))
          : props.menuItems.map(item => (
              <Link
                onClick={handleMenuItemClick}
                key={item.name}
                className="menulink"
                to={item.path}
              >
                {item.name}
              </Link>
            ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
