import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectQueriesActions } from "@shared/actions";

export function useGetProjectQuery(queryId) {
  const [query, setQuery] = useState();
  const [error, setError] = useState();
  const [currentQueryId, setCurrentQueryId] = useState(queryId);
  const [isLoading, setIsLoading] = useState(false);
  const manageProjectQueries = useSelector(state => state.manageProjectQueries);
  const dispatch = useDispatch();

  const getQuery = useCallback(
    id => {
      if (!id || (id === currentQueryId && isLoading)) {
        return;
      }
      dispatch(manageProjectQueriesActions.getProjectQuery(id));
      setIsLoading(true);
      setCurrentQueryId(id);
    },
    [currentQueryId, dispatch, isLoading]
  );

  useEffect(() => {
    return () => {
      dispatch(manageProjectQueriesActions.clearGetProjectQuery());
    };
  }, [dispatch]);

  useEffect(() => {
    if (queryId) {
      getQuery(queryId);
    }
  }, [getQuery, queryId]);

  useEffect(() => {
    setQuery(manageProjectQueries.query);
  }, [manageProjectQueries.query]);

  useEffect(() => {
    setIsLoading(manageProjectQueries.fetchingQuery);
  }, [manageProjectQueries.fetchingQuery]);

  useEffect(() => {
    setError(manageProjectQueries.error);
  }, [manageProjectQueries.error]);

  return {
    query,
    isLoadingQuery: isLoading,
    errorFetchingQuery: error,
    refetchQuery: getQuery
  };
}
