import { useCallback, useState } from "react";

import websheetWizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

export function useCleaningTemplate(savedState) {
  const [stateToSave, setStateToSave] = useState(savedState);
  const [firstPredictedRow, setFirstPredictedRow] = useState(null);
  const [splitColumns, setSplitColumns] = useState([]);
  const [unnecessaryPredictedRows, setUnnecessaryPredictedRows] =
    useState(null);
  const saveSplitColumn = useCallback(
    ({ splitColumnIndex, delimiter, sheetName, sheetIndex }) => {
      const entry = {
        columnIndex: splitColumnIndex,
        delimiter,
        sheetName,
        sheetIndex
      };
      setSplitColumns(prev => [...prev, entry]);
      setStateToSave(prev => {
        const splitColumns = prev?.splitColumns ?? [];
        splitColumns.push(entry);
        return {
          ...prev,
          splitColumns
        };
      });
    },
    []
  );

  const getHeadersWithData = useCallback(
    ({
      templateHeaders,
      selectedTemplateHeaders,
      headersSuggestion,
      sheetNameIndex
    }) => {
      const headerSuggestionsOffsetFromSplits = headersSuggestion?.map(
        header => {
          const offsetAmount = splitColumns
            .filter(sc => sc.sheetIndex === sheetNameIndex)
            .filter(sc => sc.columnIndex < header.columnIndex).length;
          return {
            ...header,
            columnIndex: header.columnIndex + offsetAmount
          };
        }
      );

      setSplitColumns([]);

      return templateHeaders
        .map(th => {
          const { columnIndex, sheetNameIndex: sheetIndex } =
            selectedTemplateHeaders[th.name] ?? {};

          const isBlankSelection = columnIndex === undefined;
          const isAdjusted = isBlankSelection
            ? true
            : sheetIndex === sheetNameIndex &&
              headerSuggestionsOffsetFromSplits?.find(
                header => header.templateHeader.name === th.name
              )?.columnIndex !== columnIndex;
          return {
            name: th.name,
            columnIndex, // index based on original input data
            isAdjusted,
            isBlankSelection
          };
        })
        .sort(websheetWizardUtilities.headerSortComparator);
    },
    [splitColumns]
  );

  const getColumnSelectionTemplate = useCallback(
    ({ sheetName, sheetNameIndex, headersWithData }) => {
      // We also filter out splits from other sheets as they're not relevant
      return {
        ...stateToSave,
        splitColumns: (stateToSave.splitColumns ?? []).filter(
          sc => sc.sheetName === sheetName
        ),
        columnsSelection: headersWithData,
        appliedTab: {
          name: sheetName,
          index: sheetNameIndex
        }
      };
    },
    [stateToSave]
  );
  const getRowSelectionTemplate = useCallback(
    ({ selectedRows, firstRow, data: websheetData }) => {
      if (firstPredictedRow) {
        setFirstPredictedRow(null);
        return {
          ...stateToSave,
          firstRowSelection: {
            firstRow: {
              rowIndex: selectedRows[selectedRows.length - 1],
              rowData: firstRow
            },
            isAdjusted: selectedRows.length !== firstPredictedRow
          }
        };
      }
      if (unnecessaryPredictedRows) {
        const isAdjusted = !websheetWizardUtilities.checkArraysEquality(
          selectedRows,
          unnecessaryPredictedRows
        );
        const unnecessaryRowsSelection = {
          isAdjusted
        };
        if (isAdjusted) {
          const wrappedRows = websheetData[0].data.map((row, rowIndex) => ({
            rowData: row,
            rowIndex
          }));

          const predictedRows = new Set(unnecessaryPredictedRows);

          unnecessaryRowsSelection.selectedRows = wrappedRows
            .filter(({ rowIndex }) => selectedRows.includes(rowIndex))
            .filter(({ rowIndex }) => !predictedRows.has(rowIndex));

          unnecessaryRowsSelection.unselectedRows = wrappedRows
            .filter(({ rowIndex }) => !selectedRows.includes(rowIndex))
            .filter(({ rowIndex }) => predictedRows.has(rowIndex));
        }
        return {
          ...stateToSave,
          unnecessaryRowsSelection
        };
      }
    },
    [firstPredictedRow, stateToSave, unnecessaryPredictedRows]
  );

  return {
    getColumnSelectionTemplate,
    stateToSave,
    saveSplitColumn,
    setFirstPredictedRow,
    setUnnecessaryPredictedRows,
    getRowSelectionTemplate,
    getHeadersWithData
  };
}
