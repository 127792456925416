import React from "react";

import PropTypes from "prop-types";

import "./AskHelp.scss";

const AskHelp = props => {
  const { message, type, iconName } = props;
  return (
    <div className="ask-help">
      <div className="ask-help__watermark">
        <i
          className={`ask-help__watermark__icon material-icons ask-help--${type}`}
        >
          {iconName}
        </i>
      </div>
      <h2 className={`ask-help__message ask-help--${type}`}>{message}</h2>
    </div>
  );
};

AskHelp.defaultProps = {
  type: "primary",
  iconName: "people"
};

AskHelp.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]),
  iconName: PropTypes.string
};

export default AskHelp;
