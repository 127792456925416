import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import { useGetProjectByIdQuery } from "@shared/services";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import UpdateProjectForm from "./UpdateProjectForm/UpdateProjectForm";

const UpdateProject = () => {
  const { projectId } = useParams();
  const { data: project, isLoading: loadingProject } = useGetProjectByIdQuery(
    { projectId: projectId },
    { skip: !projectId }
  );
  const { isLoading: loadingFeatures } = useGetFeaturesQuery();

  const defaultValue = useMemo(() => {
    if (!project) {
      return;
    }
    const projectCopy = structuredClone(project);
    projectCopy.engagementType = { id: project.engagement?.engagementTypeId };
    return projectCopy;
  }, [project]);

  return (
    <UpdateProjectForm
      project={defaultValue}
      isLoading={loadingProject || loadingFeatures}
    />
  );
};

UpdateProject.propTypes = {};

export default UpdateProject;
