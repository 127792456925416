import { TFunction } from "i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import {
  workflowActionName,
  workflowStepName
} from "@app/helpers/actionItems.js";
import {
  ActionItemType,
  ActionItemTypeWorkflow,
  UPDATE_WORKFLOW_STEP,
  WorkflowStep
} from "@app/types/ActionItemType.ts";

export interface WCNode<T> {
  id: string;
  type: string;
  data: T;
  displayLabel: () => string;

  // UX props
  canSelect?: boolean;
  isSelected?: boolean;
  isHidden?: boolean;
  zIndex?: number;
  isHovered?: boolean;
  order?: number;
  hasOperations?: boolean;
}

interface WorkflowStepData {}

export interface WCStep extends WCNode<WorkflowStepData> {
  actions?: WCAction[];
}

interface WorkflowActionData {}

export interface WCAction extends WCNode<WorkflowActionData> {
  sourceWorkflowId: string;
  targetWorkflowId: string;
}

export interface WCEdge {
  id: string;
  sourceId: string;
  targetId: string;

  // UX props
  isHidden?: boolean;
  isAnimated?: boolean;
  zIndex?: number;
}

interface Point {
  x: number;
  y: number;
}

const zigZagStrategy = ({
  source,
  target,
  padding = 15,
  directionFromSource = "right",
  directionIntoTarget = "left"
}: {
  source: Point;
  target: Point;
  padding?: number;
  directionFromSource?: string;
  directionIntoTarget?: string;
}): string => {
  // given the source and target, return the svg polyline string for the route
  // the route is horizontal until it reaches the junction towards target x
  // then it goes vertical until it reaches the target y
  // then it goes horizontal to the target x
  // direction tells us which way to enter/exit the source/target

  const { sign1, sign2 } = (() => {
    // special handling as line only needs to go vertical
    const isVerticallyAligned = directionFromSource === directionIntoTarget;
    if (isVerticallyAligned) {
      return {
        sign1: directionFromSource === "left" ? -1 : 1,
        sign2: directionIntoTarget === "left" ? -1 : 1
      };
    }

    return {
      sign1: directionFromSource === "left" ? 1 : -1,
      sign2: directionIntoTarget === "right" ? 1 : -1
    };
  })();
  const junctionX1 = target.x + sign1 * padding;
  const junctionX2 = target.x + sign2 * padding;

  const points = [
    `${source.x}, ${source.y}`,
    `${junctionX1}, ${source.y}`,
    `${junctionX2}, ${target.y}`,
    `${target.x}, ${target.y}`
  ];
  const routePath = points.join(" ");
  return routePath;
};

function centre(clientRect: DOMRect) {
  return {
    x: clientRect.x + clientRect.width / 2,
    y: clientRect.y + clientRect.height / 2
  };
}

function leftEdgeMidpoint(clientRect: DOMRect) {
  return {
    x: clientRect.x,
    y: clientRect.y + clientRect.height / 2
  };
}
function rightEdgeMidpoint(clientRect: DOMRect) {
  return {
    x: clientRect.x + clientRect.width,
    y: clientRect.y + clientRect.height / 2
  };
}

export const generateNodeKey = () => {
  return `CUSTOM_${new Date().getTime()}`;
};

export const generateEdgePath = (
  sourceDomElement: { getBoundingClientRect: () => DOMRect } | null,
  targetDomElement: { getBoundingClientRect: () => DOMRect } | null,
  canvasDomElement: { getBoundingClientRect: () => DOMRect } | null
) => {
  if (!canvasDomElement || !sourceDomElement || !targetDomElement) {
    return;
  }

  const canvasRect = canvasDomElement.getBoundingClientRect();
  const sourceRect = sourceDomElement.getBoundingClientRect();
  const targetRect = targetDomElement.getBoundingClientRect();

  const sourceCentre = centre(sourceRect);
  const targetCentre = centre(targetRect);
  const isVerticallyAligned =
    Math.abs(targetCentre.x - sourceCentre.x) < sourceRect.width / 2;
  const isSourceOnLeftSide = sourceCentre.x <= targetCentre.x;

  // if points are vertically aligned, out=right, in=right
  // if source is left of target, out=right, in=left
  // if source is right of target, out=left, in=right
  const { sourcePoint, directionFromSource, targetPoint, directionIntoTarget } =
    (() => {
      if (isVerticallyAligned) {
        return {
          sourcePoint: rightEdgeMidpoint(sourceRect),
          directionFromSource: "right",
          targetPoint: rightEdgeMidpoint(targetRect),
          directionIntoTarget: "right"
        };
      }
      if (isSourceOnLeftSide) {
        return {
          sourcePoint: rightEdgeMidpoint(sourceRect),
          directionFromSource: "right",
          targetPoint: leftEdgeMidpoint(targetRect),
          directionIntoTarget: "left"
        };
      }
      return {
        sourcePoint: leftEdgeMidpoint(sourceRect),
        directionFromSource: "left",
        targetPoint: rightEdgeMidpoint(targetRect),
        directionIntoTarget: "right"
      };
    })();

  const translatePointRelativeToCanvas = (point: Point) => {
    return {
      x: point.x - canvasRect.x,
      y: point.y - canvasRect.y
    };
  };

  const p = zigZagStrategy({
    source: translatePointRelativeToCanvas(sourcePoint),
    directionFromSource,
    target: translatePointRelativeToCanvas(targetPoint),
    directionIntoTarget
  });

  return p;
};

export const configToCanvas = ({
  config,
  actionItemType,
  t
}: {
  config: ActionItemTypeWorkflow;
  actionItemType?: ActionItemType;
  t: TFunction;
}) => {
  const { steps, stepsOrder } = config;

  const workflowSteps: WCStep[] = [];
  const workflowActions: WCAction[] = [];
  const workflowEdges: Map<string, WCEdge> = new Map();

  Object.entries(steps).forEach(([stepId, step]) => {
    const workflowStep: WCStep = {
      id: stepId,
      data: {},
      type: "step",
      displayLabel: () =>
        workflowStepName({
          workflowStep: { key: stepId, name: step.name },
          t
        }),
      order: stepsOrder.indexOf(stepId)
    };
    workflowSteps.push(workflowStep);

    step.actions?.forEach((action, idx) => {
      const generatedId = `${stepId}-${action.key}`;
      const actionNode = {
        id: generatedId,
        data: {},
        type: "action",
        hasOperations: action.operations.some(
          op => op.type !== UPDATE_WORKFLOW_STEP
        ),
        sourceWorkflowId: stepId,
        targetWorkflowId:
          action.operations.find(op => op.type === UPDATE_WORKFLOW_STEP)
            ?.value ?? "",
        order: idx,
        displayLabel: () =>
          workflowActionName({
            workflowAction: {
              key: action.key,
              config: {
                i18nActionKey: action?.i18nActionKey,
                name: action?.name
              }
            },
            actionItemTypeFormat: actionItemType?.type,
            actionItemTypeKey: actionItemType?.configuration?.key,
            t
          })
      };
      workflowActions.push(actionNode);

      workflowStep.actions ??= [];
      workflowStep.actions.push(actionNode);
    });
    workflowStep.actions = workflowStep.actions?.toSorted(
      (a, b) => (a.order ?? 0) - (b.order ?? 0)
    );
  });

  workflowActions.forEach(action => {
    const edgeId = `edge-${action.id}-${action.sourceWorkflowId}-${action.targetWorkflowId}`;
    if (workflowEdges.has(edgeId)) {
      return;
    }
    const edge = {
      id: edgeId,
      sourceId: action.id,
      targetId: action.targetWorkflowId
    };
    workflowEdges.set(edgeId, edge);
  });

  return {
    workflowSteps: workflowSteps.toSorted(
      (a, b) => (a.order ?? 0) - (b.order ?? 0)
    ),
    workflowActions,
    workflowEdges: [...workflowEdges.values()]
  };
};

export const createWorkflowStep = ({
  key
}: {
  key: string;
}): { [key: string]: WorkflowStep } => {
  return {
    [key]: {
      name: "",
      queryStatus: systemConstants.project.queries.status.open,
      operations: [],
      actions: []
    }
  };
};

export const addWorkflowStep = ({
  workflowConfig,
  workflowStep,
  ordinal
}: {
  workflowConfig: ActionItemTypeWorkflow;
  workflowStep: { [key: string]: WorkflowStep };
  ordinal: number;
}) => {
  const initialStep =
    ordinal === 0 ? Object.keys(workflowStep)[0] : workflowConfig.initialStep;

  const newStepsOrder = [
    ...workflowConfig.stepsOrder.slice(0, ordinal),
    ...Object.keys(workflowStep),
    ...workflowConfig.stepsOrder.slice(ordinal)
  ];

  const newSteps = {
    ...workflowConfig.steps,
    ...workflowStep
  };

  return {
    ...workflowConfig,
    initialStep,
    stepsOrder: newStepsOrder,
    steps: newSteps
  };
};
