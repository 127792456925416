import { TFunction } from "i18next";

const EXCLUDED_AVAILABLE_WHEN_KEYS = ["external.to.status", "query.status"];
const KEYWORD_PREFIX_OPERATOR = "$OP:";

const formatStringCondition = (key: string, val: string, t: TFunction) => {
  return `${t(`admin:workflow.action.${key}`)} ${t("admin:workflow.action.perform.IS")} "${t([`requests:requests.workflow.step.${val}.label`, val])}"`;
};

const formatObjectCondition = (key: string, value: object, t: TFunction) => {
  const keyType = Object.keys(value)[0].replace(KEYWORD_PREFIX_OPERATOR, "");
  const val = Object.values(value)[0];

  switch (keyType) {
    case "OR":
      const orSteps = val
        .map((step: string) => `"${step}"`)
        .join(` ${t("admin:workflow.action.perform.OR")} `);
      return `${t(`admin:workflow.action.${key}`)} ${t("admin:workflow.action.perform.IS")} ${orSteps}`;

    case "IN":
      const inSteps = val.map((step: string) => `"${step}"`).join(", ");
      return `${t(`admin:workflow.action.${key}`)} ${t("admin:workflow.action.perform.IS")} ${t("admin:workflow.action.perform.IN")} ${inSteps}`;

    default:
      const conditions = val.join(` ${key} `);
      return `${t(`admin:workflow.action.${key}`)} ${t("admin:workflow.action.perform.MUST")} ${t("admin:workflow.action.perform.BE")} ${conditions}`;
  }
};

const processCondition = (
  key: string,
  value: string | object,
  t: TFunction
): string => {
  if (Array.isArray(value)) {
    return value
      .map(val => {
        const [k, v] = Object.entries(val)[0];
        return typeof v === "string"
          ? formatStringCondition(k, v, t)
          : formatObjectCondition(k, v, t);
      })
      .join(" ");
  }

  if (typeof value === "object") {
    return formatObjectCondition(key, value, t);
  }

  return "";
};

export const getAvailableWhenDesc = (
  availableWhen: unknown,
  t: TFunction
): string[] => {
  if (!availableWhen || !Object.keys(availableWhen).length) {
    return [];
  }

  return Object.entries(availableWhen).reduce((acc: string[], [key, value]) => {
    const condition =
      typeof value === "string"
        ? formatStringCondition(key, value, t)
        : processCondition(key, value, t);

    if (condition && !EXCLUDED_AVAILABLE_WHEN_KEYS.includes(key)) {
      acc.push(condition);
    }
    return acc;
  }, []);
};

const rolesToDescText = (role: string, t: TFunction) => {
  return role ? t(`admin:workflow.action.perform.by.${role}`) : "";
};

export const getAvailableToDesc = (roles: string[], t: TFunction): string[] => {
  if (!roles.length) {
    return [];
  }
  return roles.map(role => rolesToDescText(role, t));
};
