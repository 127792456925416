import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";

import { chartConstants } from "@app/constants";
import { DataFormater, formatHoverText } from "@app/helpers/chart";

import PieChartToolTip from "./PieChartTooltip";

const PIE_CHART_MAX_SEGMENTS = 5;

const PieChartTable = props => {
  const {
    data,
    colors,
    className,
    fixedHeight,
    pieRadius,
    handleLegendClick,
    isSecondaryStyle,
    displayZero,
    renderEmptyPlaceHolder,
    chartMargin,
    dataType
  } = props;
  const { values, keys } = data;
  const { chartColors: segmentColors } = colors;
  const dataKey = keys[0];
  const pieChartRef = useRef();
  const [height, setHeight] = useState(chartConstants.defaultHeight);
  const isEmptyData = useMemo(
    () => !data.values?.some(value => value[dataKey] !== 0),
    [data.values, dataKey]
  );

  useEffect(() => {
    const axisTicks = pieChartRef.current.querySelector(
      ".recharts-legend-wrapper"
    );
    if (fixedHeight) {
      return setHeight(fixedHeight);
    }
    if (axisTicks) {
      if (axisTicks.offsetHeight < 230) {
        setHeight(230);
      } else {
        setHeight(axisTicks.offsetHeight + 30);
      }
    }
  }, [values, fixedHeight]);

  const getOtherToolTip = values => {
    if (values.length - PIE_CHART_MAX_SEGMENTS > 1) {
      return `Other (${values.length - PIE_CHART_MAX_SEGMENTS} items)`;
    } else {
      return `Other`;
    }
  };

  const formatData = useCallback(() => {
    const filteredData = displayZero
      ? values
      : values.filter(item => item[dataKey] !== 0);
    if (filteredData.length <= PIE_CHART_MAX_SEGMENTS) {
      return filteredData;
    }
    const sortedData = filteredData.sort((a, b) => b[dataKey] - a[dataKey]);
    return sortedData.slice(0, PIE_CHART_MAX_SEGMENTS).concat({
      label: getOtherToolTip(filteredData),
      [dataKey]: sortedData
        .slice(PIE_CHART_MAX_SEGMENTS)
        .reduce((acc, cur) => acc + cur[dataKey], 0),
      info: sortedData.slice(PIE_CHART_MAX_SEGMENTS).map(item => item.info)
    });
  }, [dataKey, displayZero, values]);

  const chartData = useMemo(() => formatData(), [formatData]);

  const formatPayload = useMemo(
    () =>
      chartData.map((d, i) => {
        const additionalInfo = d.info ? ` (${d.info})` : "";
        return {
          id: i,
          value: `${d.label}: ${DataFormater(d[dataKey])}${additionalInfo}`,
          type: "rect",
          color: segmentColors[i],
          indicator: d.indicator
        };
      }),
    [chartData, dataKey, segmentColors]
  );

  const renderSegments = data => {
    return keys.map((k, i) => (
      <React.Fragment key={i}>
        <Pie
          dataKey={k}
          nameKey="label"
          isAnimationActive={true}
          data={data}
          outerRadius={pieRadius}
          startAngle={90}
          endAngle={-270}
        >
          {data.map((_, index) => {
            return (
              <Cell
                fill={segmentColors[index]}
                key={`cell${k}-${index}`}
                stroke={segmentColors[index]}
                style={{ outline: "none" }}
              />
            );
          })}
        </Pie>
      </React.Fragment>
    ));
  };

  const customFormatter = value => {
    return <span style={{ color: colors.legendTextColor }}> {value}</span>;
  };

  return (
    <div ref={pieChartRef}>
      <ResponsiveContainer width="99%" height={height} className={className}>
        <PieChart data={chartData} margin={chartMargin}>
          {isEmptyData && renderEmptyPlaceHolder
            ? renderEmptyPlaceHolder()
            : renderSegments(chartData)}
          <Tooltip
            content={
              <PieChartToolTip
                isSecondaryStyle={isSecondaryStyle}
                formatHoverText={formatHoverText}
                dataKey={dataKey}
                dataType={dataType}
              />
            }
          />
          <Legend
            align="right"
            verticalAlign="bottom"
            layout="vertical"
            onClick={handleLegendClick}
            wrapperStyle={{ bottom: "25px" }}
            payload={formatPayload}
            width={"40%"}
            formatter={customFormatter}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

PieChartTable.defaultProps = {
  dataType: "currency",
  isSecondaryStyle: false
};

PieChartTable.propTypes = {
  colors: PropTypes.shape({
    segmentColors: PropTypes.arrayOf(PropTypes.string),
    legendTextColor: PropTypes.string
  }),
  data: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    )
  }),
  chartMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  }),
  pieRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataType: PropTypes.oneOf(["currency", "number", "percentage"]),
  isDrilldown: PropTypes.bool,
  isSecondaryStyle: PropTypes.bool,
  className: PropTypes.string,
  fixedHeight: PropTypes.number,
  displayZero: PropTypes.bool,
  renderEmptyPlaceHolder: PropTypes.func
};

export default PieChartTable;
