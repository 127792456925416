import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useGetProjectForSelectionQuery } from "@shared/hooks";
import { useGetHostRolesQuery } from "@shared/services/roleService";

export function useHostUserProfile(updateUserId) {
  const authentication = useSelector(state => state.authentication);
  const { data: roles = [], error } = useGetHostRolesQuery();
  const { data: projectsList } = useGetProjectForSelectionQuery({
    userId: updateUserId
  });
  const [teamUserFields, setTeamUserFields] = useState([]);

  useEffect(() => {
    setTeamUserFields(
      authentication.host?.host?.properties?.formsConfig?.teamUserFields ?? []
    );
  }, [authentication.host?.host?.properties?.formsConfig?.teamUserFields]);

  return {
    error,
    projectsList,
    roles,
    teamUserFields
  };
}
