import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import { Button } from "@atoms/Button";

import TextAreaInput from "@components/atoms/TextAreaInput";

import AiWizard from "./AiWizard";
import "./useAiWizard.scss";

export function useAiWizard() {
  const { t } = useTranslation();
  const { ref, callbackRef } = useCallbackRef();
  const [extra, setExtra] = useState("");
  const [prompt, setPrompt] = useState("");

  const getStepDescription = useCallback(() => {
    return (
      <>
        <TextAreaInput
          label={t("Custom prompt")}
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
        />
      </>
    );
  }, [prompt, t]);

  const getStepButtons = useCallback(
    ({ onNext, disableProgression }) => {
      return (
        <>
          <Button
            label={t("common:ui.websheet.actions.aiCleaningAssistant.rerun")}
            onClick={() => {
              setExtra(prompt);
            }}
          />
          <Button
            label={t("common:ui.forms.confirm.label")}
            disabled={disableProgression}
            onClick={onNext}
          />
        </>
      );
    },
    [prompt, t]
  );

  const getWizard = useCallback(
    commonWizardProps => {
      return (
        <AiWizard {...commonWizardProps} ref={callbackRef} extra={extra} />
      );
    },
    [callbackRef, extra]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  const cleanup = useCallback(() => {
    ref?.cleanup();
  }, [ref]);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission,
    cleanup,
    getStepButtons
  };
}
