import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import {
  useAuthUser,
  useDocumentDownloads,
  useGetProjectFoldersAndDocuments,
  useGetProjectMembers
} from "@shared/hooks";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import Popup from "@shared-components/popup/Popup";

import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import UploadFile from "@components/molecules/UploadFile";
import AddClientDocument from "@components/organisms/AddClientDocument";
import AddClientProjectDocument from "@components/organisms/AddClientProjectDocument";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

const UploadFileBox = props => {
  const {
    boxClassName,
    project,
    tags = [],
    clientId,
    onUploadComplete,
    title,
    subtext,
    checkIsMember,
    useLegacyApiModeForOldPages,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = props;
  const { t } = useTranslation();
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const { getProjectFoldersAndDocuments } = useGetProjectFoldersAndDocuments();
  const { resetDocumentDownloadsError } = useDocumentDownloads();
  const [isUploaded, setIsUploaded] = useState(false);
  const { isProjectMember } = useGetProjectMembers(project);
  const { user } = useAuthUser();
  const [dropboxError, setDropboxError] = useState(null);
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");
  const [disableUpload, setDisableUpload] = useState(false);

  const handleOpenProjectAccessModal = () => {
    setShowProjectAccessModal(disableUpload);
    if (disableUpload) {
      handleOpenModal?.("projectAccess", membershipErrorMessage);
    }
  };
  const handleDropZoneClick = () => {
    checkIsMember && handleOpenProjectAccessModal();
    resetDocumentDownloadsError();
  };

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setDisableUpload(true);
      });
    },
    [isProjectMember, user]
  );

  useEffect(() => {
    checkMembership("upload a document")(() => {
      setDisableUpload(false);
    });
  }, [checkMembership]);

  const handleDrop = useCallback(
    docs => {
      setDroppedFiles([...docs]);
      setShowDocumentModal(true);
      handleOpenModal?.("addClientProjectDocument");
    },
    [handleOpenModal]
  );

  const handleFileUpload = useCallback(() => {
    setIsUploaded(true);
  }, []);

  const handlePopupOutsideClick = useCallback(() => {
    setShowProjectAccessModal(false);
    handleCloseModal?.();
  }, [handleCloseModal]);

  const handleProjectDocumentsUploaded = useCallback(() => {
    getProjectFoldersAndDocuments(project);
    isUploaded && onUploadComplete?.(droppedFiles);
    setDroppedFiles([]);
    setDropboxError(null);
    setShowDocumentModal(false);
    setIsUploaded(false);
    handleCloseModal?.();
  }, [
    droppedFiles,
    getProjectFoldersAndDocuments,
    handleCloseModal,
    isUploaded,
    onUploadComplete,
    project
  ]);

  const handleCancelAddProjectDocuments = useCallback(() => {
    setDroppedFiles([]);
    setShowDocumentModal(false);
    handleCloseModal?.();
  }, [handleCloseModal]);

  const handleClientDocumentsUploaded = useCallback(() => {
    setDroppedFiles([]);
    setDropboxError(null);
    setShowDocumentModal(false);
    handleCloseModal?.();
  }, [handleCloseModal]);

  const handleCancelAddClientDocuments = useCallback(() => {
    setDroppedFiles([]);
    setShowDocumentModal(false);
    handleCloseModal?.();
  }, [handleCloseModal]);

  const onFileRejected = useCallback(
    filesRejected => {
      if (filesRejected.length) {
        setDropboxError(t("common:ui.upload.error.fileType.static"));
      } else {
        setDropboxError(null);
      }
    },
    [t]
  );

  const addClientProjectDocument = useMemo(
    () => () => (
      <Popup
        visibility={useLegacyApiModeForOldPages ? showDocumentModal : true}
        handleOutsideClick={false}
        width="60rem"
      >
        {!clientId && project && (
          <AddClientProjectDocument
            project={project}
            parentFolderId={null}
            droppedFiles={droppedFiles}
            tags={tags}
            error={dropboxError}
            onUpload={handleProjectDocumentsUploaded}
            onFileUpload={handleFileUpload}
            onCancel={handleCancelAddProjectDocuments}
            title={title}
            subtext={subtext}
          />
        )}
        {!project && clientId && (
          <AddClientDocument
            clientId={clientId}
            droppedFiles={droppedFiles}
            error={dropboxError}
            onUpload={handleClientDocumentsUploaded}
            onCancel={handleCancelAddClientDocuments}
            onFileUpload={onUploadComplete}
          />
        )}
      </Popup>
    ),
    [
      clientId,
      dropboxError,
      droppedFiles,
      handleCancelAddClientDocuments,
      handleCancelAddProjectDocuments,
      handleClientDocumentsUploaded,
      handleFileUpload,
      handleProjectDocumentsUploaded,
      useLegacyApiModeForOldPages,
      onUploadComplete,
      project,
      showDocumentModal,
      subtext,
      tags,
      title
    ]
  );

  useEffect(() => {
    if (!useLegacyApiModeForOldPages) {
      registerModal?.("addClientProjectDocument", addClientProjectDocument);
    }
  }, [addClientProjectDocument, useLegacyApiModeForOldPages, registerModal]);

  return (
    <DashboardBoxTemplate title={title} boxClassName={boxClassName}>
      <UploadFile
        handleDrop={handleDrop}
        supportedDocumentMimes={supportedDocumentMimes}
        handleClickBox={handleDropZoneClick}
        disabled={checkIsMember ? disableUpload : false}
        handleRejection={onFileRejected}
      />
      {useLegacyApiModeForOldPages && (
        <>
          {addClientProjectDocument()}
          <ProjectAccessModal
            visibility={showProjectAccessModal}
            message={membershipErrorMessage}
            handleClose={handlePopupOutsideClick}
            handleOutsideClick={handlePopupOutsideClick}
          />
        </>
      )}
    </DashboardBoxTemplate>
  );
};

UploadFileBox.defaultProps = {
  checkIsMember: false,
  useLegacyApiModeForOldPages: false
};

UploadFileBox.propTypes = {
  boxClassName: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number
  }),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number
    })
  ),
  clientId: PropTypes.number,
  useLegacyApiModeForOldPages: PropTypes.bool,
  onUploadComplete: PropTypes.func,
  title: PropTypes.string,
  subtext: PropTypes.string,
  checkIsMember: PropTypes.bool,
  // If not useLegacyApiModeForOldPages the below are required (pass in from useModalContent custom hook)
  registerModal: PropTypes.func,
  handleOpenModal: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default UploadFileBox;
