import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectQueriesActions } from "@shared/actions";

export function useUpdateQuery() {
  const manageProjectQueries = useSelector(state => state.manageProjectQueries);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(manageProjectQueriesActions.clearUpdatedQuery());
    };
  }, [dispatch]);

  useEffect(() => {
    setUpdated(manageProjectQueries.updatedQuery);
  }, [manageProjectQueries.updatedQuery]);

  useEffect(() => {
    setIsUpdating(manageProjectQueries.updatingQuery);
  }, [manageProjectQueries.updatingQuery]);

  useEffect(() => {
    setError(manageProjectQueries.error);
  }, [manageProjectQueries.error]);

  const updateQuery = useCallback(
    (query, cb) => {
      if (!query || isUpdating) return;
      dispatch(manageProjectQueriesActions.clearUpdatedQuery());
      dispatch(manageProjectQueriesActions.updateProjectQuery(query, cb));
    },
    [dispatch, isUpdating]
  );

  return {
    updatingQuery: isUpdating,
    updateQueryError: error,
    updateQuery,
    updatedQuery: updated
  };
}
