import React from "react";

import { ResourcePermission, RoleMode } from "@app/types";

import PermissionsMatrix from "./PermissionsMatrix/PermissionsMatrix";

type Props = {
  resourcesPermissions: ResourcePermission[];
  defaultValues?: number[];
  disabled?: boolean;
  mode: RoleMode;
};

function ResourcesForm(props: Props) {
  const { resourcesPermissions, defaultValues, disabled, mode } = props;
  return (
    <>
      {resourcesPermissions.map(resourcePermissions => (
        <PermissionsMatrix
          resourcePermissions={resourcePermissions}
          key={resourcePermissions.section}
          defaultPermissions={defaultValues}
          disabled={disabled}
          mode={mode}
        />
      ))}
    </>
  );
}

export default ResourcesForm;
