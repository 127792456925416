import React from "react";

import PropTypes from "prop-types";

import "./ReminderCircle.scss";

const ReminderCircle = props => {
  const { days, message, percentage, isCompleted } = props;
  const radius = 90;
  const normalizedRadius = 82;

  // Imagine we have a line going from 0 to 100
  // For each of the circle tracks, we specify a painted area (denoted by strokeDasharray) that looks like this
  // 0                100
  // |PPPPPPP         | progress  (highest z-index)
  // |        RRRRRRR | remainder
  // |G     GGG     GG| gap       (lowest z-index)
  const pathLength = 100;
  const scaleLength = ratio => (ratio / 100) * pathLength;
  const gapSize = percentage != 0 ? 0.75 : 0;
  const gapLength = scaleLength(gapSize);
  const progressLength = scaleLength(Math.round(percentage));
  const remainderLength = pathLength - gapLength * 2 - progressLength;

  return (
    <div className="reminder-circle">
      <div className="reminder-circle__progress-meter">
        <svg height={radius * 2} width={radius * 2}>
          <circle
            className="gap"
            strokeDasharray={[
              gapLength,
              progressLength - 2 * gapLength,
              gapLength * 3,
              remainderLength - 2 * gapLength,
              gapLength * 3
            ].join(" ")}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            pathLength={pathLength}
          />
          <circle
            className="remainder"
            strokeDasharray={[
              0,
              progressLength + gapLength,
              remainderLength,
              gapLength
            ].join(" ")}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            pathLength={pathLength}
          />
          <circle
            className="progress"
            strokeDasharray={[progressLength, pathLength - progressLength].join(
              " "
            )}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            pathLength={pathLength}
          />
        </svg>
      </div>
      <div className="reminder-circle__content">
        {isCompleted ? (
          <div className="reminder-circle__content__complete">
            {message.complete}
          </div>
        ) : (
          <>
            <div className="reminder-circle__content__top">
              <h1 className="reminder-circle__content__top__label"> {days} </h1>
            </div>
            <div className="reminder-circle__content__body">
              <span className="textbox-top">{message.top}</span>
              <span className="textbox-bot">{message.bottom}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ReminderCircle.defaultProps = {
  percentage: 0,
  isCompleted: false
};

ReminderCircle.propTypes = {
  days: PropTypes.string,
  message: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string
  }),
  percentage: PropTypes.number,
  isCompleted: PropTypes.bool
};

export default ReminderCircle;
