import moment from "moment";

export function formatDateOnly(
  date: Date | null | undefined | string
): string | null | undefined {
  if (date === null || date === undefined) {
    return date;
  }
  const safeDate = safeUtcDate(date);
  const outDate = safeDate ?? new Date();

  outDate.setHours(0, 0, 0, 0);
  return [
    outDate.getFullYear(),
    padTo2Digits(outDate.getMonth() + 1),
    padTo2Digits(outDate.getDate())
  ].join("-");
}

export function safeUtcDate(
  date: Date | string | undefined | null
): Date | undefined | null {
  if (date === null || date === "") {
    return null;
  } else if (date === undefined) {
    return undefined;
  }
  return moment(date).endOf("day").utc().toDate();
}

function padTo2Digits(num: number): string {
  return num.toString().padStart(2, "0");
}
