import React from "react";

import { useMenuActions } from "@shared/hooks/useMenuActions";

import { Box } from "@fermions";

import { Button } from "@atoms/Button";
import { IconSize } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import {
  DropdownCalculationType,
  DropdownItemType,
  Positioning
} from "../DropdownList/DropdownHelper";

interface ButtonMenuProps {
  variant: "primary" | "secondary" | "text";
  menuItems: DropdownItemType[];
  onMenuItemClick: (item: DropdownItemType) => void;
  disableFixedPositionCalculation?: boolean;
  positionRelative?: boolean;
  className?: string;
  positioning?: Positioning;
  disabled?: boolean;
}

export const ButtonMenu = ({
  label,
  variant = "primary",
  menuItems = [],
  onMenuItemClick,
  disableFixedPositionCalculation,
  positionRelative,
  className,
  positioning = Positioning.BOTTOM,
  disabled = false
}: ButtonMenuProps) => {
  const {
    menuItemsTransformed,
    menuItemClickHandler,
    handleClick,
    isOpen,
    ref,
    dropDownRef: buttonMenuRef
  } = useMenuActions({ menuItems, onMenuItemClick });

  return (
    <Box
      ref={ref}
      className={[
        "button-menu",
        `${
          disableFixedPositionCalculation || positionRelative
            ? "button-menu-position--relative"
            : "button-menu-position--absolute"
        }`,
        className ?? ""
      ]}
      data-testid="test-button-menu"
    >
      <Box
        data-testid="test__menu"
        ref={buttonMenuRef}
        className="button-dropdown__dots"
        style={{
          cursor: "pointer"
        }}
      >
        <Button
          onClick={handleClick}
          variant={variant}
          label={label ?? "Actions"}
          iconName={isOpen ? "expand_less" : "expand_more"}
          iconOnRight
          iconSize={IconSize.S}
          disabled={disabled}
        />
      </Box>
      <DropdownList
        parentRef={buttonMenuRef}
        calculation={
          disableFixedPositionCalculation
            ? DropdownCalculationType.ABSOLUTE
            : DropdownCalculationType.FIXED
        }
        isOpen={isOpen}
        items={menuItemsTransformed}
        onClickItem={menuItemClickHandler}
        positioning={positioning}
        showSearch={false}
        sortComparator={() => 0}
        style={{ maxHeight: "none" }}
      />
    </Box>
  );
};
