import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageExcelDocumentService = {
  getExcelDocumentAsJSON,
  updateExcelDocument,
  getLockForExcelDocument,
  refreshLockForExcelDocument,
  finishEditSession
};

function updateExcelDocument({
  documentId,
  documentRevisionId,
  workbookData,
  revisionProperties
}) {
  const authHeaderObj = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderObj.Authorization
    },
    body: JSON.stringify({ workbookData, revisionProperties })
  };

  return fetch(
    `${baseURL}/api/documents/${documentId}/revisions/${documentRevisionId}/websheet`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function finishEditSession({ fileLockId, documentId }) {
  const authHeaderObj = authHeader();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderObj.Authorization
    }
  };

  return fetch(
    `${baseURL}/api/documents/${documentId}/lock/${fileLockId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function refreshLockForExcelDocument({ fileLockId, documentId }) {
  const authHeaderObj = authHeader();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderObj.Authorization
    }
  };

  return fetch(
    `${baseURL}/api/documents/${documentId}/lock/${fileLockId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getLockForExcelDocument({ id }) {
  const authHeaderObj = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderObj.Authorization
    }
  };
  return fetch(`${baseURL}/api/documents/${id}/lock`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getExcelDocumentAsJSON({ id, documentRevisionId, forceLatest, tab }) {
  const authHeaderObj = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderObj.Authorization
    }
  };

  const q = new URLSearchParams();
  q.set("asJSONSpreadsheet", "true");
  forceLatest && q.set("latestIfAvailable", forceLatest);
  tab && q.set("tab", tab);

  return fetch(
    `${baseURL}/api/documents/${id}/revisions/${
      documentRevisionId ?? 0
    }?${q.toString()}`,
    requestOptions
  )
    .then(async response => {
      if (!response.ok) {
        const text = await response.text();
        const data = text && JSON.parse(text);
        const error = {
          status: response.status,
          message:
            systemConstants.support.message ||
            "An unexpected error occurred. Please try again, and if the problem persists contact support"
        };

        if (data && response.status !== 200) {
          error.message = data.message;
        }
        return Promise.reject(error);
      }
      const json = await response.json();
      return {
        id,
        documentRevisionId: documentRevisionId || json[0].documentRevisionId,
        json
      };
    })
    .catch(handleErrorResponse);
}
