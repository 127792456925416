export const menuConstants = {
  menuItemNames: {
    dashboard: "Dashboard",
    projects: "Projects",
    project: "Project",
    home: "Home",
    insights: "Insights",
    teams: "Teams",
    clientData: "Your Data Repository",
    permanentFiles: "Permanent Files",
    engagements: "Engagements",
    myProfile: "My Profile",
    admin: "Admin"
  },
  menuItemTypes: {
    NAV_LINK: "NAV_LINK",
    NAV_DROPDOWN: "NAV_DROPDOWN",
    NAV_SEARCHABLE_DROPDOWN: "NAV_SEARCHABLE_DROPDOWN",
    PROJECT_MULTILEVEL_DROPDOWN: "PROJECT_MULTILEVEL_DROPDOWN",
    NAV_SUBMENU: "NAV_SUBMENU",
    NAV_BOOKMARK: "NAV_BOOKMARK",
    NAV_SECTION_TITLE: "NAV_SECTION_TITLE",
    NAV_SECTION_END: "NAV_SECTION_END"
  },
  sideNavContext: {
    ALL_PROJECTS: "ALL_PROJECTS",
    PROJECT: "PROJECT",
    CLIENT: "CLIENT",
    INSIGHTS: "INSIGHTS",
    ADMIN: "ADMIN"
  },
  activeItemConfig: {
    home: ["/client-dashboard", "/create-project"],
    engagement: [
      "/engagement",
      "/engagement-users",
      "/add-client-engagement",
      "/update-client-engagement-project"
    ],
    manageClients: ["/admin/clients", "manage-clients", "admin/add-client"],
    manageUsers: ["/admin/manage-users", "/admin/add-user", "/admin/users"],
    manageRoles: [
      "/admin/manage-user-roles",
      "/admin/create-role",
      "/admin/roles"
    ],
    manageRequestTypes: ["/admin/manage-request-types", "admin/request-types"],
    projects: ["/projects", "/project", "/permanent-files"],
    queries: ["/queries", "/smartforms", "/websheets"],
    insightsBoard: ["/insights-board"]
  }
};
