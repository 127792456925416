import React, { useMemo } from "react";

import PropTypes from "prop-types";

import Avatar from "@components/atoms/Avatar/";

import "./AvatarList.scss";

const AvatarList = props => {
  const { users, numOfUsers, size, hoverPosition } = props;

  const renderedAvatars = useMemo(
    () =>
      users.map((user, index) => {
        if (index < numOfUsers) {
          return (
            <div key={index}>
              <Avatar
                size={size}
                user={user}
                showHover={true}
                hoverPosition={hoverPosition}
              />
            </div>
          );
        }
      }),
    [hoverPosition, numOfUsers, size, users]
  );

  return (
    <div className="avatar-list__container">
      {renderedAvatars}
      {users.length > numOfUsers && (
        <div
          className={`circle circle__${size} avatar-list__user-dots--${size}`}
        />
      )}
    </div>
  );
};

AvatarList.defaultProps = {
  size: "medium",
  user: [],
  numOfUsers: 5
};

AvatarList.propTypes = {
  size: PropTypes.oneOf(["x-small", "small", "medium", "large"]),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.string
      })
    })
  ),
  numOfUsers: PropTypes.number,
  hoverPosition: PropTypes.oneOf(["top", "bottom"])
};
export default AvatarList;
