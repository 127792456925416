export const manageDocumentUploadsConstants = {
  UPLOAD_DOCUMENT_REQUEST: "UPLOAD_DOCUMENT_REQUEST",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE: "UPLOAD_DOCUMENT_FAILURE",

  UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",

  CLEAR_UPLOADED_DOCUMENT: "CLEAR_UPLOADED_DOCUMENT",
  RESET_UPLOADING_DOCUMENTS: "RESET_UPLOADING_DOCUMENTS"
};
