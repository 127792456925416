export const manageQueryResponsesConstants = {
  GET_QUERY_RESPONSES_REQUEST: "GET_QUERY_RESPONSES_REQUEST",
  GET_QUERY_RESPONSES_SUCCESS: "GET_QUERY_RESPONSES_SUCCESS",
  GET_QUERY_RESPONSES_FAILURE: "GET_QUERY_RESPONSES_FAILURE",

  ADD_QUERY_RESPONSE_REQUEST: "ADD_QUERY_RESPONSE_REQUEST",
  ADD_QUERY_RESPONSE_SUCCESS: "ADD_QUERY_RESPONSE_SUCCESS",
  ADD_QUERY_RESPONSE_FAILURE: "ADD_QUERY_RESPONSE_FAILURE",

  UPDATE_QUERY_RESPONSE_REQUEST: "UPDATE_QUERY_RESPONSE_REQUEST",
  UPDATE_QUERY_RESPONSE_SUCCESS: "UPDATE_QUERY_RESPONSE_SUCCESS",
  UPDATE_QUERY_RESPONSE_FAILURE: "UPDATE_QUERY_RESPONSE_FAILURE",

  CLEAR_IS_ADDED: "CLEAR_IS_ADDED",
  CLEAR_IS_UPDATED: "CLEAR_IS_UPDATED",
  CLEAR_ERROR: "CLEAR_ERROR_QUERY_RESPONSE",
  RESET: "RESET_QUERY_RESPONSE"
};
