import { manageHostUsersConstants } from "../constants";

const initialState = {
  loading: false,
  error: "",
  engagementTypesWithTags: [],
  engagementTypes: [],
  addedTag: null,
  deletedTag: null,
  updatedTag: null
};

export function manageHostUsers(state = initialState, action = {}) {
  switch (action.type) {
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_REQUEST:
    case manageHostUsersConstants.CREATE_TAG_REQUEST:
    case manageHostUsersConstants.UPDATE_TAG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageHostUsersConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageHostUsersConstants.RESET:
      return initialState;
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_FAILURE:
    case manageHostUsersConstants.CREATE_TAG_FAILURE:
    case manageHostUsersConstants.UPDATE_TAG_FAILURE:
    case manageHostUsersConstants.DELETE_TAG_FAILURE:
      return {
        ...state,
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS:
      return {
        ...state,
        engagementTypesWithTags: action.engagementTypes
      };
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_SUCCESS:
      return {
        ...state,
        engagementTypes: action.engagementTypes
      };
    case manageHostUsersConstants.CREATE_TAG_SUCCESS:
      return {
        ...state,
        addedTag: action.tag
      };
    case manageHostUsersConstants.DELETE_TAG_SUCCESS:
      return {
        ...state,
        deletedTag: action.tag
      };
    case manageHostUsersConstants.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        updatedTag: action.tag
      };
    default:
      return state;
  }
}
