import React, { useCallback, useEffect, useState, useRef } from "react";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { systemConstants } from "@shared/constants";

import { Button, ButtonVariant } from "@atoms/Button";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./imageEditor.scss";

interface ImageEditorProps {
  image: string;
  cropConfig?: {
    x: number;
    y: number;
    aspect: number;
    width: number;
    height: number;
    unit: string;
  };
  onCancel: () => void;
  onSet: (image: string) => void;
  title?: string;
  supportedMimes?: string[];
}

const ImageEditor = (props: ImageEditorProps) => {
  const { image, cropConfig, onCancel, onSet, title, supportedMimes } = props;
  const initialCropObject = cropConfig ?? {
    x: 0,
    y: 0,
    aspect: 1,
    width: 100,
    height: 100,
    unit: "px"
  };

  const [src, setSrc] = useState(image);
  const [crop, setCrop] = useState(initialCropObject);
  const [enterKey, setEnterKey] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const handleCancel = () => {
    onCancel();
  };

  const getCroppedImg = useCallback(() => {
    const canvas = document.createElement("canvas");
    const image = imgRef.current;
    const scaleX = (image?.naturalWidth ?? 0) / (image?.width ?? 1);
    const scaleY = (image?.naturalHeight ?? 0) / (image?.height ?? 1);
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (!image) {
      return "";
    }

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;
  }, [crop.height, crop.width, crop.x, crop.y]);

  const handleSet = useCallback(() => {
    onSet(getCroppedImg());
  }, [getCroppedImg, onSet]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setCrop(initialCropObject);
      setSrc(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleCropChange = (croppedObject: ReactCrop) => {
    if (
      croppedObject.width >= initialCropObject.width &&
      croppedObject.height >= initialCropObject.height
    ) {
      setCrop(croppedObject);
    }
  };

  const getSupportedMimes = () => {
    if (supportedMimes) {
      const supportedMimeList = supportedMimes.map(mime => `image/${mime}`);
      return supportedMimeList.join();
    } else {
      const supportedMimeList = systemConstants.mimes.image.map(
        (mime: string) => `image/${mime}`
      );
      return supportedMimeList.join();
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      setEnterKey(true);
    }
  };

  const onImageLoad = () => {
    /**
     * Hacky method - https://github.com/sekoyo/react-image-crop/issues/579
     * This is to fix the issue where the crop mask is not updated on image change and first load
     */
    setTimeout(() => {
      document
        .querySelector(".ReactCrop__crop-mask")
        ?.setAttribute("height", "100%");
    }, 100);
  };

  useEffect(() => {
    if (enterKey) {
      handleSet();
      setEnterKey(false);
    }
  }, [enterKey, handleSet]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  return (
    <ModalTemplate
      boxClassName="image-editor"
      title={title ?? "Set Profile image"}
      onClose={handleCancel}
      content={
        <div className="image-editor__holder">
          <div className="image-editor__holder-image">
            <ReactCrop crop={crop} onChange={handleCropChange}>
              <img src={src} alt="" ref={imgRef} onLoad={onImageLoad} />
            </ReactCrop>
          </div>

          <div className="image-editor__holder-controls">
            <div className="image-editor__holder-image-input">
              <input
                type="file"
                accept={getSupportedMimes()}
                onChange={handleImageChange}
                onKeyDown={e => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              ></input>
            </div>
          </div>
        </div>
      }
      footer={
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            label="Cancel"
            onClick={handleCancel}
          />
          <Button disabled={!src} label="Set" onClick={handleSet} />
        </>
      }
    ></ModalTemplate>
  );
};

export default ImageEditor;
