import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { useFileUploadManagement } from "@shared/hooks";

import HorizontalGalleryLayout from "@components/atoms/HorizontalGalleryLayout/HorizontalGalleryLayout";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import UploadFile from "@components/molecules/UploadFile";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList";

import "./UploadImages.scss";

const addFilesState = systemConstants.addFiles.state;

// NB: This file is similar to UploadDocuments but specifically for images
// It support an image preview of the selected file
// The upload mechanism must be provided via 'props.uploadHooks', which helps decouple this from any specific API
const _UploadImages = React.forwardRef((props, fwdRef) => {
  const [selectFileError, setSelectFileError] = useState(null);
  const {
    onRemoveFile,
    onAddFiles,
    files,
    addExistingAttachmentFiles,
    setInitialAddFiles
  } = useFileUploadManagement();
  const [addFilesChanged, setAddFilesChanged] = useState(false);
  const {
    existingFiles,
    initialAddFiles,
    onChange,
    error,
    engagementTypeId,
    state,
    maxNumberOfFiles,
    maxNumberOfFilesError,
    onUploadsFailed,
    onUploadsComplete,
    onBlur,
    supportedDocumentMimes,
    supportedDocumentMimesMessage,
    isHideDocumentsList,
    dropMessage,
    disableFileListActions,
    disableUploads,
    hideUpload,
    uploadHooks,
    t
  } = props;
  const {
    uploadImageFiles,
    isUploading,
    uploadsFailed,
    uploadsCompleted,
    uploadError,
    getUploadFileState,
    clearUploadsCompleted
  } = uploadHooks;

  const startUploads = useCallback(() => {
    if (files && Object.keys(files).length > 0 && !isUploading) {
      uploadImageFiles(files, engagementTypeId);
    } else {
      onUploadsComplete(files);
    }
  }, [
    files,
    onUploadsComplete,
    engagementTypeId,
    uploadImageFiles,
    isUploading
  ]);

  const filesToUpload = useMemo(() => {
    const result = {};
    Object.keys(files).forEach(filename => {
      const fileState = getUploadFileState(filename) ?? {};
      const file = files[filename];
      result[filename] = {
        name: filename,
        size: file.size,
        ...file,
        ...fileState
      };
    });
    return result;
  }, [files, getUploadFileState]);

  useEffect(() => {
    if (state === addFilesState.finished && uploadsCompleted) {
      clearUploadsCompleted();
    }
  }, [clearUploadsCompleted, state, uploadsCompleted]);

  useEffect(() => {
    if (state === addFilesState.upload && !isUploading) {
      startUploads();
    }
  }, [isUploading, state, startUploads]);

  useEffect(() => {
    if (
      state !== addFilesState.finished &&
      uploadsCompleted &&
      onUploadsComplete
    ) {
      onUploadsComplete(filesToUpload);
    }
  }, [onRemoveFile, onUploadsComplete, state, uploadsCompleted, filesToUpload]);

  useEffect(() => {
    if (state !== addFilesState.finished && uploadsFailed && onUploadsFailed) {
      onUploadsFailed(uploadError);
    }
  }, [files, onUploadsFailed, state, uploadError, uploadsFailed]);

  useEffect(() => {
    if (existingFiles && existingFiles.length > 0) {
      addExistingAttachmentFiles(existingFiles);
    }
  }, [addExistingAttachmentFiles, existingFiles]);

  useEffect(() => {
    setAddFilesChanged(true);
  }, [filesToUpload]);

  useEffect(() => {
    if (filesToUpload && addFilesChanged) {
      onChange?.(filesToUpload || []);
      setAddFilesChanged(false);
    }
  }, [filesToUpload, addFilesChanged, onChange]);

  useEffect(() => {
    setSelectFileError(error);
  }, [error]);

  const onDrop = useCallback(
    acceptedFiles => {
      setSelectFileError(null);
      onAddFiles(acceptedFiles);
    },
    [onAddFiles]
  );

  useEffect(() => {
    if (initialAddFiles) {
      setInitialAddFiles(initialAddFiles);
    }
  }, [setInitialAddFiles, initialAddFiles]);

  const onFileRejected = useCallback(
    filesRejected => {
      const rejectReasons = new Set();
      filesRejected.forEach(f => {
        f.errors.forEach(e => {
          rejectReasons.add(e.code);
        });
      });
      if (rejectReasons.has("too-many-files")) {
        setSelectFileError(
          maxNumberOfFilesError
            ? maxNumberOfFilesError
            : `Cannot select more than ${maxNumberOfFiles} document(s) to upload. Please try again`
        );
      } else if (rejectReasons.has("file-invalid-type")) {
        const displayMimes = supportedDocumentMimes?.sort().join(", ");
        setSelectFileError(
          supportedDocumentMimesMessage
            ? supportedDocumentMimesMessage
            : t("common:ui.upload.error.fileType.mime", { displayMimes })
        );
      }
    },
    [
      maxNumberOfFilesError,
      maxNumberOfFiles,
      supportedDocumentMimes,
      supportedDocumentMimesMessage,
      t
    ]
  );

  const isDisabled = useMemo(() => {
    if (hideUpload || disableUploads) {
      return true;
    }
    if (!files || !maxNumberOfFiles) {
      return false;
    }
    const numberOfFiles = Object.values(files).length;
    return numberOfFiles >= maxNumberOfFiles;
  }, [files, maxNumberOfFiles, disableUploads, hideUpload]);

  const ImagePreviews = useCallback(({ filesToPreview }) => {
    const previews = Object.values(filesToPreview).filter(
      file => file instanceof File
    );

    const previewItem = file => (
      <div className="form-upload-image__previews-item" key={file.name}>
        <img src={URL.createObjectURL(file)}></img>
        <small>{file.name}</small>
      </div>
    );

    return (
      <HorizontalGalleryLayout>
        {previews.map(previewItem)}
      </HorizontalGalleryLayout>
    );
  }, []);

  return (
    <div className="ot-form-upload-images" ref={fwdRef} onBlur={onBlur}>
      <UploadFile
        handleDrop={onDrop}
        supportedDocumentMimes={supportedDocumentMimes}
        dropMessage={dropMessage}
        supportedDocumentMimesMessage={supportedDocumentMimesMessage}
        disabled={isDisabled}
        maxNumberOfFiles={maxNumberOfFiles}
        maxNumberOfFilesError={maxNumberOfFilesError}
        handleRejection={onFileRejected}
        hideUpload={hideUpload || isDisabled}
      />
      {selectFileError && (
        <InlineAlert type="error" message={t(selectFileError)} />
      )}
      {filesToUpload ? (
        <>
          {files && <ImagePreviews filesToPreview={files}></ImagePreviews>}
          <UploadFileAttachmentList
            attachments={filesToUpload}
            onDelete={onRemoveFile}
            isHide={isHideDocumentsList}
            disabled={disableFileListActions}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
});

function UploadImages(props) {
  // See _UploadImages for otherProps
  const { name, errorMessage, ...otherProps } = props;
  const {
    formState: { errors },
    control
  } = useFormContext();

  const getError = useCallback(() => {
    if (name && errors && errors[name] && errors[name].message) {
      return errors[name].message;
    } else if (errorMessage) {
      return errorMessage;
    } else return "";
  }, [errorMessage, errors, name]);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <_UploadImages
            ref={ref}
            error={getError()}
            onChange={files => {
              onChange(files);
              props.onChange?.(files);
            }}
            onBlur={onBlur}
            {...otherProps}
          />
        );
      }}
    />
  );
}

UploadImages.defaultProps = {
  disableFileListActions: false,
  disableUploads: false,
  hideUpload: false,
  maxNumberOfFiles: 1,
  supportedDocumentMimes: ["png"]
};

UploadImages.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  state: PropTypes.oneOf(["add", "upload", "finished"]),
  existingFiles: PropTypes.array,
  initialAddFiles: PropTypes.array,
  onUploading: PropTypes.func,
  onUploadsComplete: PropTypes.func,
  onUploadsFailed: PropTypes.func,
  maxNumberOfFiles: PropTypes.number,
  maxNumberOfFilesError: PropTypes.string,
  errorMessage: PropTypes.string,
  disableFileListActions: PropTypes.bool,
  disableUploads: PropTypes.bool,
  hideUpload: PropTypes.bool,
  supportedDocumentMimes: PropTypes.array,
  supportedDocumentMimesMessage: PropTypes.string,
  dropMessage: PropTypes.string,
  engagementTypeId: PropTypes.number
};

export default withTranslation()(UploadImages);
