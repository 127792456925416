import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Form from "@components/atoms/Form/Form";
import ModalForm from "@components/molecules/ModalForm";

const AddRiskNote = props => {
  const { onCancel, onAdd, riskId } = props;
  const { t } = useTranslation();

  const yupSchema = yup.object({
    message: yup
      .string()
      .required(t("risks:riskNote.fields.message.error.required"))
  });

  const handleSubmit = formData => {
    if (!formData.message) {
      return;
    }

    onAdd({
      note: {
        riskId,
        message: formData.message
      }
    });
  };

  return (
    <ModalForm
      boxClassName="risk-note-modal"
      title={t("risks:ui.riskCard.actions.addNote.label")}
      submitLabel={t("risks:ui.action.addNote.label")}
      handleCancel={onCancel}
      handleSubmit={handleSubmit}
      yupSchema={yupSchema}
    >
      <Form.TextArea name="message" label="" maxLength={500} />
    </ModalForm>
  );
};

AddRiskNote.PropTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  riskId: PropTypes.number.isRequired
};

export default AddRiskNote;
