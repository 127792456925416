import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { cmsActions } from "@shared/actions";

export function useUIConfig() {
  const cms = useSelector(state => state.cms);
  const [uiConfig, setUIConfig] = useState(cms.uiConfig);
  const [isUIConfigLoaded, setIsUIConfigLoaded] = useState(cms.uiConfigLoaded);
  const dispatch = useDispatch();

  const fetchUIConfig = useCallback(() => {
    if (cms?.uiConfig === null && !cms.uiConfigLoaded) {
      dispatch(cmsActions.getUIConfig());
    }
  }, [cms?.uiConfig, cms.uiConfigLoaded, dispatch]);

  useEffect(() => {
    setUIConfig(cms.uiConfig);
    if (cms.uiConfig !== null) setIsUIConfigLoaded(true);
  }, [cms.uiConfig]);

  useEffect(() => {
    setIsUIConfigLoaded(cms.uiConfigLoaded);
  }, [cms.uiConfigLoaded]);

  return { uiConfig, fetchUIConfig, isUIConfigLoaded };
}
