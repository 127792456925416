import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useFeatures } from "@shared/hooks";
import {
  useGetPermissionsQuery,
  useGetRoleByIdQuery
} from "@shared/services/roleService";

import { getErrorMessage } from "@app/helpers/error";

import ViewRoleForm from "./ViewRoleForm/ViewRoleForm";

const UpdateUserRole = () => {
  const {
    data: permissions,
    isLoading,
    error: permissionsError
  } = useGetPermissionsQuery();
  const { roleId } = useParams();
  const { data: role, error: roleError } = useGetRoleByIdQuery(roleId ?? "");
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translatedRole = useMemo(() => {
    if (role) {
      return {
        ...role,
        name: !role.createdBy ? t(role.name) : role.name
      };
    }
    return role;
  }, [role, t]);

  const error = useMemo(() => {
    const error = permissionsError ?? roleError;
    if (!error) {
      return "";
    }
    return getErrorMessage(error, t);
  }, [permissionsError, roleError, t]);

  useEffect(() => {
    if (
      !roleId ||
      (!isFeaturesLoading && !isEnabled(systemConstants.features.roles))
    ) {
      navigate(routeConstants.notFound);
    }
  }, [isFeaturesLoading, navigate, isEnabled, roleId]);

  return (
    <ViewRoleForm
      resourcesPermissions={permissions}
      role={translatedRole}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default UpdateUserRole;
