import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import ions from "@ions";

import { useDataTable } from "@app/hooks";

import { Inline } from "@fermions";

import { Pill, PillSize } from "@atoms/Pill";

import DataTable from "@components/molecules/DataTable";
import InfiniteScrollFooter from "@components/molecules/InfiniteScrollFooter";

import "./ProjectsTable.scss";

function ProjectsTable(props) {
  const { projects, scrollHandler, isFetching, handlePublishProject } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createColumn, createColumnForDropdownMenu } = useDataTable(projects);
  const { locale, options } = useLocaleDate();
  const { user } = useAuthUser();
  const onRowClick = useCallback(
    (_, row) => {
      navigate(`${routeConstants.projects}/${row.projectId}`);
    },
    [navigate]
  );
  const rowClickHandler = useMemo(
    () => ({ isClickable: () => true, handler: onRowClick }),
    [onRowClick]
  );

  const yearEnabled = useMemo(() => {
    return projects.some(project => project.yearEnabled);
  }, [projects]);

  const getMenuItems = useCallback(
    (projectId, projectStatus) => {
      if (!user?.isHostUser) {
        return [];
      }

      return [
        {
          label: t("common:ui.project.title_update"),
          key: "updateProject",
          projectId: projectId
        },
        ...(projectStatus !== systemConstants.project.status.draft
          ? [
              {
                label: t("common:ui.project.title_copy"),
                key: "copyProject",
                projectId: projectId
              }
            ]
          : [
              {
                label: t("common:ui.projects.draft.publishButton"),
                key: "publishProject",
                projectId: projectId
              }
            ])
      ];
    },
    [t, user?.isHostUser]
  );

  const menuItemClickHandler = useCallback(
    ({ menuItem }) => {
      switch (menuItem.key) {
        case "updateProject":
          navigate(`${routeConstants.projects}/${menuItem.projectId}/edit`);
          break;
        case "copyProject":
          navigate(`${routeConstants.projects}/${menuItem.projectId}/copy`);
          break;
        case "publishProject":
          handlePublishProject(menuItem.projectId);
          break;
        default:
          break;
      }
    },
    [handlePublishProject, navigate]
  );

  const data = useMemo(
    () =>
      projects.map(project => ({
        projectId: project.id,
        client: project.clientName,
        engagementType: project.engagementType,
        projectName: project.name,
        projectYear: project.year,
        nextMilestone:
          project.status === systemConstants.project.status.draft
            ? null
            : dateFormatter(
                project.currentMilestone?.date,
                locale,
                options.shortFormat
              ),
        actions: getMenuItems(project.id, project.status),
        status: project.status
      })),
    [getMenuItems, locale, options.shortFormat, projects]
  );

  const formatNameCell = useCallback(
    item => (
      <Inline gap="100" alignment="center">
        {item.row?.original?.status ===
          systemConstants.project.status.draft && (
          <Pill
            label={t("common:ui.projects.draft.label")}
            variant={ions.components?.atoms?.draft_pill?.pill_variant}
            fillStyle={ions.components?.atoms?.draft_pill?.pill_fill_style}
            size={PillSize.SMALL}
          />
        )}
        {item.value}
      </Inline>
    ),
    [t]
  );

  const columns = useMemo(
    () => [
      createColumn({
        Header: t("common:ui.projectTable.client"),
        accessor: "client",
        className: "client",
        maxWidth: 25,
        isHidden: !user?.isHostUser
      }),
      createColumn({
        Header: t("common:ui.projectTable.engagementType"),
        accessor: "engagementType",
        className: "engagementType",
        maxWidth: 25
      }),
      createColumn({
        Header: t("common:ui.projectTable.project"),
        accessor: "projectName",
        className: "projectName",
        Cell: formatNameCell
      }),
      createColumn({
        Header: t("common:ui.projectTable.projectYear"),
        accessor: "projectYear",
        className: "projectYear",
        maxWidth: 15,
        isHidden: !yearEnabled
      }),
      createColumn({
        Header: t("common:ui.projectTable.nextMilestone"),
        accessor: "nextMilestone",
        className: "nextMilestone",
        maxWidth: 15
      }),
      createColumnForDropdownMenu({
        Header: "",
        accessor: "actions",
        className: "actions",
        isHidden: !user?.isHostUser,
        onClickHandler: menuItemClickHandler
      })
    ],
    [
      createColumn,
      createColumnForDropdownMenu,
      formatNameCell,
      menuItemClickHandler,
      t,
      user?.isHostUser,
      yearEnabled
    ]
  );

  return (
    <div className="ot-dashboard-projects-table" onScroll={scrollHandler}>
      <DataTable
        data={data}
        columns={columns}
        rowClickHandler={rowClickHandler}
      ></DataTable>
      {isFetching && <InfiniteScrollFooter state="loading" />}
    </div>
  );
}

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      clientName: PropTypes.string,
      engagementType: PropTypes.string,
      name: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      currentMilestone: PropTypes.shape({
        date: PropTypes.string
      })
    })
  ),
  scrollHandler: PropTypes.func,
  isFetching: PropTypes.bool,
  handlePublishProject: PropTypes.func
};

export default ProjectsTable;
