import React, { useCallback, useMemo, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Inline, Stack } from "@fermions";

import { Icon, IconSize } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import {
  DropdownItemType,
  DropdownTheme
} from "@components/molecules/DropdownList/DropdownHelper";

import "./DropdownNav.scss";

enum ItemTypes {
  text = "text",
  link = "link"
}

interface DropdownNavProps {
  label: string;
  menuItems: DropdownItemType[];
  handleNavigate: unknown;
  isNeverActive?: boolean;
  forceSetActive?: boolean;
  state?: unknown;
  data?: unknown;
  type?: ItemTypes;
}

export const DropdownNav = ({
  label,
  menuItems,
  handleNavigate,
  isNeverActive,
  forceSetActive,
  state,
  data,
  type
}: DropdownNavProps) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const activeItem = useMemo(() => {
    if (isNeverActive) {
      return;
    }
    return menuItems.find(item => {
      return location.pathname === item.path;
    });
  }, [location.pathname, isNeverActive, menuItems]);

  const handleMenuItemClick = useCallback(
    item => {
      setIsOpen(false);
      if (type === ItemTypes.text) {
        handleMenuItemClick(item, data);
      } else {
        handleNavigate(item.path, { state: state });
      }
    },
    [data, handleNavigate, state, type]
  );

  const dropdownButtonRef = useRef();
  return (
    <Stack
      ref={ref}
      className={[
        "dropdown-nav",
        returnStringIfTrue(isOpen, "dropdown-nav--expanded"),
        returnStringIfTrue(!!activeItem || forceSetActive, "active")
      ]}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Inline
        className="dropdown-nav__btn"
        data-testid="dropdown-nav-label"
        ref={dropdownButtonRef}
        alignment="center"
        gap="025"
      >
        {label}
        <Icon
          name="keyboard_arrow_down"
          className={"dropdown-nav__icon"}
          size={IconSize.S}
        />
      </Inline>
      <DropdownList
        parentRef={dropdownButtonRef}
        items={menuItems}
        theme={DropdownTheme.DARK}
        showSearch={false}
        onClickItem={handleMenuItemClick}
        isOpen={isOpen}
        selectedValues={activeItem ? [activeItem] : []}
      />
    </Stack>
  );
};
