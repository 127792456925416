/**
 * @typedef {Object} DateOptions
 * @property {('numeric'|'2-digit')} year
 * @property {('numeric'|'2-digit'|'long'|'short'|'narrow')} month
 * @property {('numeric'|'2-digit')} day
 */

/**
 * @param {string} date
 * @param {("en-AU"|"ja-JA"|"en-JA"|"ja-AU")|string} locale
 * @param {DateOptions| {}} formatOptions
 * @returns {string}
 */
export default function dateFormatter(date, locale, formatOptions) {
  if (!date) {
    return null;
  }
  return new Date(date).toLocaleDateString(locale, formatOptions);
}

export function dateAndTimeFormatter(date, locale, formatOptions, timeZone) {
  formatOptions = {
    ...formatOptions,
    hour: "numeric",
    minute: "numeric",
    timeZone: timeZone
  };
  return dateFormatter(date, locale, formatOptions);
}

/**
 * @param {("en-AU"|"ja-JA"|"en-JA"|"ja-AU")} locale
 * @returns {string} date format string such as dd/MM/yyyy
 */
export function getDateFormatString(locale) {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
}

/**
 * @param {string} dateString
 * @returns {Date} one year added to the given date
 */
export function addOneYear(dateString) {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  return new Date(date.setFullYear(date.getFullYear() + 1));
}
