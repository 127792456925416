import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import { FormProvider, useForm, useWatch } from "react-hook-form";

import websheetUtilities from "@shared/helpers/websheetUtilities";
import wizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";
import { useCleaningTemplate } from "@shared/hooks/useCleaningTemplate";

import { useScrollSync } from "@app/hooks/useScrollSync";

import FormTemplate from "@components/atoms/Form/FormTemplate";

import WizardWebsheet from "../../WizardWebsheet";
import SplitColumnModal from "./SplitColumnModal";
import WizardWebsheetColumnHeader from "./WizardWebsheetColumnHeader";

const positionState = {
  left: "LEFT",
  middle: "MIDDLE",
  right: "RIGHT"
};

const ColumnSelectionWizard = React.forwardRef((props, fwdRef) => {
  const {
    loading,
    error,
    data: cleaningDataObj,
    handleOpenModal,
    handleCloseModal,
    onSheetLoading,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged,
    defaultSheetName,
    hasErrorInCurrentSheet,
    templateHeaders,
    setDropdownHeader,
    disallowedSheets = []
  } = props;
  const data = useMemo(
    () => cleaningDataObj?.websheet ?? [],
    [cleaningDataObj?.websheet]
  );

  const { syncElements, unsyncElements } = useScrollSync();
  const { saveSplitColumn, getColumnSelectionTemplate, getHeadersWithData } =
    useCleaningTemplate({});
  const [scrollBarPosition, setScrollBarPosition] = useState(
    positionState.left
  );
  const [headersSuggestion, setHeadersSuggestion] = useState(null);
  const formMethods = useForm();
  const watchFormValues = useWatch({ control: formMethods.control });
  const [currentSheetName, setCurrentSheetName] = useState(defaultSheetName);
  const hotTableComponent = useRef(null);
  const [isHotTableComponentMounted, setIsHotTableComponentMounted] =
    useState(false);
  const [splitColumnIndex, setSplitColumnIndex] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [delimiter, setDelimiter] = useState(null);
  const [splitErrorMessage, setSplitErrorMessage] = useState("");
  const isSuggested = useRef(false);

  // ref cannot be a dependency; but we need to know when hotTableComponent gets mounted
  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
    setIsHotTableComponentMounted(!!node);
  }, []);

  const onCurrentSheetNameChanged = useCallback(
    sheetName => {
      setCurrentSheetName(sheetName);
      parentOnCurrentSheetNameChanged?.(sheetName);
    },
    [parentOnCurrentSheetNameChanged]
  );

  useEffect(() => {
    if (isSuggested.current) {
      return;
    }

    const newLocalData = structuredClone(data);
    const suggestion = wizardUtilities.suggestSheetAndHeadersForWebsheet(
      newLocalData.filter(d => d.state !== "hidden"),
      templateHeaders,
      wizardUtilities.MAX_ROWS_TO_SEARCH,
      disallowedSheets
    );
    isSuggested.current = true;
    setHeadersSuggestion(suggestion?.headers);

    const resetFormMethods = updatedFields => {
      formMethods.reset(prev => {
        const updated = Object.keys(prev).reduce(
          (acc, fieldName) => ({
            ...acc,
            [fieldName]: null
          }),
          {}
        );
        return { ...updated, ...updatedFields };
      });
    };
    if (!suggestion) {
      setLocalData(newLocalData);
      resetFormMethods();
      return;
    }

    const { sheetIndex, sheetName, headers: headerSuggestions } = suggestion;

    const newFormFields = {};
    headerSuggestions.forEach(({ templateHeader, columnIndex }) => {
      const fieldName = wizardUtilities.sheetAndColumnToTemplateHeaderFieldName(
        sheetIndex,
        columnIndex
      );
      newFormFields[fieldName] = {
        name: templateHeader.name,
        value: templateHeader.name
      };
    });

    newLocalData.forEach(sheetData => {
      sheetData.defaultSheet = false;
    });
    newLocalData[sheetIndex].defaultSheet = true;
    setLocalData(newLocalData);
    resetFormMethods(newFormFields);
    onCurrentSheetNameChanged(sheetName);
  }, [
    data,
    formMethods,
    onCurrentSheetNameChanged,
    templateHeaders,
    disallowedSheets
  ]);

  useEffect(() => {
    if (!currentSheetName || !data) {
      return;
    }
    setLocalData(prev => {
      if (!prev) {
        return prev;
      }
      // We have local helpers but we can't use them here as we'd trigger a render-cycle
      const names = websheetUtilities.getVisibleSheetNames(prev);
      const currentSheetIndex = names.findIndex(sn => sn === currentSheetName);

      if (currentSheetIndex < 0 || !data[currentSheetIndex].downloaded) {
        return prev;
      }

      const newSheet = structuredClone(data[currentSheetIndex]);
      return prev.map(sheetData => {
        if (sheetData.sheet === newSheet.sheet) {
          newSheet.defaultSheet = true;
          return newSheet;
        }
        return {
          ...sheetData,
          defaultSheet: false
        };
      });
    });
  }, [data, currentSheetName]);

  const sheetNames = useMemo(
    () => localData?.map(sheet => sheet.sheet) ?? [],
    [localData]
  );
  const getSheetIndexFromName = useCallback(
    sheetName => sheetNames.findIndex(sn => sn === sheetName),
    [sheetNames]
  );
  const getSheetNameFromIndex = useCallback(
    sheetIndex => sheetNames[sheetIndex],
    [sheetNames]
  );

  const currentSheet = useMemo(
    () => localData?.find(sheet => sheet.sheet === currentSheetName),
    [currentSheetName, localData]
  );

  useEffect(() => {
    const formFields = watchFormValues ?? {};
    const selectedTemplateHeaders = Object.values(formFields)
      .filter(v => v)
      .map(({ value }) => value);
    setDropdownHeader(selectedTemplateHeaders);
  }, [setDropdownHeader, watchFormValues]);

  // The structure of this is { [templateHeader.name]: {sheetNameIndex, columnIndex} }
  const selectedTemplateHeaders = useMemo(() => {
    const formFields = watchFormValues ?? {};

    const templateHeaderEntries = Object.entries(formFields)
      .map(([fieldName, templateHeader]) => {
        return [
          templateHeader?.value,
          wizardUtilities.sheetAndColumnFromTemplateHeaderFieldName(fieldName)
        ];
      })
      .filter(([headerName, indexData]) => headerName && indexData)
      .map(([headerName, { sheetNameIndex, columnIndex }]) => [
        headerName,
        { sheetNameIndex: +sheetNameIndex, columnIndex: +columnIndex }
      ]);
    const result = Object.fromEntries(templateHeaderEntries);
    return result;
  }, [watchFormValues]);

  const highlightSelectedColumns = useCallback(
    (r, c) => {
      const { sheetNameIndex } =
        Object.values(selectedTemplateHeaders)?.[0] ?? {};
      const sheetName = getSheetNameFromIndex(sheetNameIndex);
      if (!sheetName) {
        return "";
      }

      const isViewingSheetForSelectedColumns = currentSheetName === sheetName;
      return isViewingSheetForSelectedColumns &&
        Object.values(selectedTemplateHeaders)
          .map(c => c.columnIndex)
          .includes(c)
        ? "selected-columns"
        : "";
    },
    [selectedTemplateHeaders, getSheetNameFromIndex, currentSheetName]
  );

  const canSubmit = useCallback(() => {
    const isAllRequiredHeadersSelected = templateHeaders.every(
      th => !th.required || selectedTemplateHeaders[th.name]?.columnIndex >= 0
    );
    return isAllRequiredHeadersSelected;
  }, [templateHeaders, selectedTemplateHeaders]);

  // Called when we we need to get the data.
  const getDataForSubmission = useCallback(() => {
    if (!hotTableComponent?.current?.hotInstance || !canSubmit()) {
      return {};
    }

    // use the first entry because we of the assumption that all selected headings must be from the same sheet/tab
    const { sheetNameIndex } = Object.values(selectedTemplateHeaders)[0];
    const sheetName = getSheetNameFromIndex(sheetNameIndex);

    const headersWithData = getHeadersWithData({
      templateHeaders,
      selectedTemplateHeaders,
      headersSuggestion,
      sheetNameIndex
    });
    const [websheet, updatedTableHeaders] =
      wizardUtilities.getColumnSelectionData(localData, {
        sheetName,
        headersWithData
      });

    return {
      websheet: [websheet],
      savedState: {
        ...(cleaningDataObj?.savedState ?? {}),
        ...getColumnSelectionTemplate({
          sheetName,
          sheetNameIndex,
          headersWithData: updatedTableHeaders
        }),
        ...(disallowedSheets?.length ? { disallowedSheets } : {})
      }
    };
  }, [
    canSubmit,
    getHeadersWithData,
    templateHeaders,
    selectedTemplateHeaders,
    headersSuggestion,
    getSheetNameFromIndex,
    localData,
    cleaningDataObj.savedState,
    getColumnSelectionTemplate,
    disallowedSheets
  ]);

  const handleSplitColumn = useCallback(() => {
    const newData = wizardUtilities.splitColumnByDelimiter(
      currentSheet,
      splitColumnIndex,
      delimiter
    );

    if (!newData) {
      setSplitErrorMessage(
        "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiterNotFound"
      );
      return;
    }

    const currentSheetIndex = getSheetIndexFromName(newData.sheet);
    saveSplitColumn({
      sheetName: newData.sheet,
      sheetIndex: currentSheetIndex,
      splitColumnIndex,
      delimiter
    });

    setLocalData(prev => {
      prev.forEach(sheetData => {
        sheetData.defaultSheet = false;
      });
      prev[currentSheetIndex] = { ...newData, defaultSheet: true };
      return [...prev];
    });

    const formFields = watchFormValues;
    const shiftedFieldValues = wizardUtilities.insertOneInFormFields(
      formFields,
      currentSheetIndex,
      splitColumnIndex
    );
    formMethods.reset(shiftedFieldValues);
    setSplitColumnIndex(null);
    handleCloseModal();
  }, [
    currentSheet,
    splitColumnIndex,
    delimiter,
    saveSplitColumn,
    getSheetIndexFromName,
    watchFormValues,
    formMethods,
    handleCloseModal
  ]);

  const beforeData = useMemo(
    () =>
      wizardUtilities.getBeforeSplittedPreviewData(
        currentSheet,
        splitColumnIndex
      ),
    [currentSheet, splitColumnIndex]
  );

  const afterData = useMemo(() => {
    if (!delimiter) {
      return beforeData;
    }
    return wizardUtilities.getSplittedPreviewData(
      currentSheet,
      splitColumnIndex,
      delimiter
    );
  }, [beforeData, currentSheet, delimiter, splitColumnIndex]);

  const getSplitColumnModal = useCallback(() => {
    const onInputChange = e => {
      setDelimiter(e.target.value);
      setSplitErrorMessage("");
    };
    return (
      <SplitColumnModal
        splitColumnIndex={splitColumnIndex}
        handleSubmit={handleSplitColumn}
        handleCancel={handleCloseModal}
        afterData={afterData}
        beforeData={beforeData}
        onInputChange={onInputChange}
        errorMessage={splitErrorMessage}
      />
    );
  }, [
    afterData,
    beforeData,
    handleCloseModal,
    handleSplitColumn,
    splitColumnIndex,
    splitErrorMessage
  ]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit,
      getModal: getSplitColumnModal,
      cleanup: () => (isSuggested.current = false)
    }),
    [getDataForSubmission, canSubmit, getSplitColumnModal]
  );

  const isHeaderEditingDisabled = useMemo(() => {
    if (loading) {
      return true;
    }
    if (hasErrorInCurrentSheet) {
      return true;
    }
    if (disallowedSheets.includes(currentSheetName)) {
      return true;
    }
    if (Object.values(selectedTemplateHeaders).length === 0) {
      return false;
    }

    const sheetNameIndex = Object.values(selectedTemplateHeaders)[0]
      ?.sheetNameIndex;
    return sheetNameIndex !== getSheetIndexFromName(currentSheetName);
  }, [
    loading,
    selectedTemplateHeaders,
    hasErrorInCurrentSheet,
    getSheetIndexFromName,
    currentSheetName,
    disallowedSheets
  ]);

  useEffect(() => {
    if (currentSheetName) {
      const cHeaders = document.querySelector("#wizard-websheet__table-header");
      cHeaders?.scrollTo(0, 0);
    }
  }, [currentSheetName]);

  useEffect(() => {
    const cHeaders = document.querySelector("#wizard-websheet__table-header");
    const hot = document.querySelector("#handsontable-id .wtHolder");
    if (!isHotTableComponentMounted || !cHeaders || !hot || !currentSheetName) {
      // this usually means we're not yet fully instantiated so we need to check again
      return;
    }
    //check the horizontal scroll bar position
    const handlerToAdjustHeaderBorders = e => {
      if (
        Math.ceil(e.target?.scrollLeft + e.target?.offsetWidth) >=
        e.target?.scrollWidth
      ) {
        setScrollBarPosition(positionState.right);
      } else if (e.target?.scrollLeft > 0) {
        setScrollBarPosition(positionState.middle);
      } else {
        setScrollBarPosition(positionState.left);
      }
    };
    cHeaders.addEventListener("scroll", handlerToAdjustHeaderBorders);

    syncElements([cHeaders, hot]);

    const observerCallback = entries => {
      const cHeadersWidthSyncer = document.querySelector(
        "#wizard-websheet__table-header .wizard-websheet__table-header__width-syncer"
      );
      if (entries[0]?.target && cHeadersWidthSyncer) {
        const { scrollWidth, clientWidth, offsetWidth } = entries[0].target;
        const isScrollBarVisible = scrollWidth > clientWidth;
        // NB: this calculation for scrollbar width works because there are no borders nor margins.
        const offset = isScrollBarVisible
          ? Math.abs(clientWidth - offsetWidth)
          : 0;
        cHeadersWidthSyncer.style.width = scrollWidth
          ? `${scrollWidth + offset}px`
          : null;
      }
    };
    const observer = new ResizeObserver(observerCallback);
    observer.observe(hot);
    // manually trigger for first time
    observerCallback([{ target: hot }]);

    return () => {
      observer.unobserve(hot);
      unsyncElements();
      cHeaders.removeEventListener("scroll", handlerToAdjustHeaderBorders);
    };
  }, [
    syncElements,
    unsyncElements,
    isHotTableComponentMounted,
    currentSheetName
  ]);

  const colWidths = useMemo(() => {
    return wizardUtilities.getColWidthsForWizard(currentSheet);
  }, [currentSheet]);

  const columnCount = useMemo(() => {
    return websheetUtilities.getMaxNumberOfColumns(currentSheet?.data || []);
  }, [currentSheet]);

  const handleOpenSplitColumnModal = useCallback(
    columnIndex => {
      handleOpenModal("websheetModal");
      setSplitColumnIndex(columnIndex);
    },
    [handleOpenModal]
  );

  const customHeadersForTableContent = useMemo(() => {
    const headerColWidths = colWidths
      .slice(0, columnCount)
      .map((w, idx) => ({ columnWidthPx: w, columnIndex: idx }));

    const currentSheetIndex = getSheetIndexFromName(currentSheetName);
    const originColumnWidth = () => {
      return scrollBarPosition === positionState.middle ? "51px" : "50px";
    };

    const templateHeaderOptions = templateHeaders.filter(
      ({ name }) => !selectedTemplateHeaders[name]
    );

    return (
      <div
        className={`wizard-websheet__table-header ${
          scrollBarPosition !== positionState.left
            ? "wizard-websheet__table-header--scrolled"
            : ""
        }`}
        id="wizard-websheet__table-header"
      >
        <div className="wizard-websheet__table-header__width-syncer"></div>
        {
          <div
            className="wizard-websheet__table-header-column wizard-websheet__table-header-column--origin"
            style={{
              width: originColumnWidth()
            }}
          ></div>
        }
        {headerColWidths.map(({ columnWidthPx, columnIndex }) => {
          return (
            <div
              className="wizard-websheet__table-header-column"
              key={`${currentSheetIndex}-col-${columnIndex}`}
              style={{ width: columnWidthPx }}
            >
              <WizardWebsheetColumnHeader
                templateHeaders={templateHeaderOptions}
                columnIndex={columnIndex}
                formFieldName={wizardUtilities.sheetAndColumnToTemplateHeaderFieldName(
                  currentSheetIndex,
                  columnIndex
                )}
                disabled={isHeaderEditingDisabled}
                handleOpenSplitColumnModal={handleOpenSplitColumnModal}
              />
            </div>
          );
        })}
      </div>
    );
  }, [
    colWidths,
    columnCount,
    getSheetIndexFromName,
    currentSheetName,
    templateHeaders,
    scrollBarPosition,
    selectedTemplateHeaders,
    isHeaderEditingDisabled,
    handleOpenSplitColumnModal
  ]);
  return (
    <>
      <FormProvider {...formMethods}>
        <FormTemplate>{customHeadersForTableContent}</FormTemplate>
      </FormProvider>
      <WizardWebsheet
        currentSheetName={currentSheetName}
        data={localData}
        hasErrorInCurrentSheet={hasErrorInCurrentSheet}
        colWidths={colWidths}
        loading={loading || !isSuggested.current}
        error={error}
        rowHeaders={true}
        onSheetLoading={onSheetLoading}
        hotTableComponent={setHotTableComponent}
        additionalClassName={highlightSelectedColumns}
        onCurrentSheetNameChanged={onCurrentSheetNameChanged}
      />
    </>
  );
});

ColumnSelectionWizard.displayName = "ColumnSelectionWizard";

export default ColumnSelectionWizard;
