import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { useAddProjectMemberMutation } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import { isUserAlreadyPresent } from "@app/helpers/users";

import ModalForm from "@components/molecules/ModalForm";
import ProjectUserForm from "@components/molecules/ProjectUserForm";

const AddProjectUser = props => {
  const { project, projectMembers, users, onClose } = props;
  const { t } = useTranslation();
  const [addUserToProject, { error, isError }] = useAddProjectMemberMutation();

  const defaultValues = useMemo(() => {
    return {
      memberOf: users?.client,
      member: null
    };
  }, [users?.client]);

  const yupSchema = yup.object({
    memberOf: yup
      .object()
      .required(t("common:ui.projectUserForm.validation.client")),
    member: yup
      .object()
      .required(t("common:ui.projectUserForm.validation.member"))
      .test(
        "is-already-present",
        t("common:ui.projectUserForm.validation.memberAlreadyExists"),
        val => !isUserAlreadyPresent(projectMembers, val)
      )
  });

  const entitiesEnabled = project.configuration?.entities?.enabled ?? false;

  const handleSubmit = data => {
    const payload = {
      projectId: project.id,
      projectMember: {
        memberId: data.member.id
      }
    };

    if (data.entities && entitiesEnabled) {
      payload.projectMember.entities = data.entities.map(e => e.value);
    }
    addUserToProject(payload)
      .unwrap()
      .then(() => onClose());
  };

  return (
    <ModalForm
      title={t("common:ui.projects.addUser.title")}
      handleCancel={onClose}
      handleSubmit={handleSubmit}
      submitLabel={t("common:ui.forms.add.label")}
      yupSchema={yupSchema}
      defaultValues={defaultValues}
    >
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      <ProjectUserForm
        users={users}
        projectMembers={projectMembers}
        entities={project.entities}
        entitiesEnabled={entitiesEnabled}
      />
    </ModalForm>
  );
};

AddProjectUser.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    entities: PropTypes.array
  }),
  onClose: PropTypes.func.isRequired,
  users: PropTypes.shape({
    host: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    }),
    client: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    })
  }),
  projectMembers: PropTypes.shape({
    hostUsers: PropTypes.array,
    clientUsers: PropTypes.array
  })
};

export default AddProjectUser;
