import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageAuditService = {
  getAuditRecordsByQueryId
};

function getAuditRecordsByQueryId(queryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/queries/${queryId}/audit`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
