import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { dateAndTimeFormatter } from "@shared/helpers/dateHelper";
import { useGetHostObject, useLocaleDate } from "@shared/hooks";

import { QUERY_TYPE_ICON } from "@app/helpers";
import { useDataTable } from "@app/hooks";
import { ActionItemType } from "@app/types";

import { Inline, Stack } from "@fermions";

import { Icon, IconColor } from "@atoms/Icon/Icon.tsx";
import { Pill, PillSize } from "@atoms/Pill";
import { FontColor, Text } from "@atoms/Text/Text.tsx";

import DataTable from "@components/molecules/DataTable";
import TextToolTip from "@components/molecules/TextToolTip";

import {
  ACTIONS,
  SortingFilter
} from "@pages/ManageRequestTypes/ManageRequestTypes.tsx";
import { displayName } from "@pages/ViewActionItemType/actionItemType.tsx";

type Props = {
  requestTypes: Array<ActionItemType>;
  canUpdate: boolean;
  yearEnabled: boolean;
  sortFilter: SortingFilter;
  onRequestTypeAction: (action: string, requestType?: ActionItemType) => void;
  onHandleSort: (sortingFilterObj: SortingFilter) => void;
};

const RequestTypeDataTable = (props: Props) => {
  const {
    requestTypes,
    onRequestTypeAction,
    canUpdate,
    sortFilter,
    yearEnabled
  } = props;

  const { findItem, createColumn, createColumnForDropdownMenu } =
    useDataTable(requestTypes);

  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });
  const { t } = useTranslation();
  const { hostObject } = useGetHostObject();
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  0;
  const handleAction = useCallback(
    (actionKey, requestTypeId) => {
      const requestType = findItem(requestTypeId);
      setRowDisplay({ rowId: -1, display: false });
      return onRequestTypeAction(actionKey, requestType);
    },
    [findItem, onRequestTypeAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const requestTypeId = cell.row.original.id;
      const actionKey = menuItem.key;
      handleAction(actionKey, requestTypeId);
    },
    [handleAction]
  );

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const renderNameCol = ({ cell }: { cell: { value: string } }) => (
    <Text text={cell.value} maxLines={1} truncate={true} />
  );

  const renderYearCol = ({ cell }: { cell: { value: string } }) =>
    cell.value ? <Pill label={cell.value} size={PillSize.SMALL} /> : "";

  const renderDescriptionCol = useCallback(
    ({
      cell
    }: {
      cell: {
        value: string;
        row: {
          id: number;
        };
      };
    }) => {
      return (
        <TextToolTip
          text={cell.value}
          className={`data-table-cell-${cell.row.id}-description`}
          maxLines={3}
          truncate={true}
        />
      );
    },
    []
  );

  const renderFormatColumn = useCallback(
    ({ cell }: { cell: { value: string } }) => (
      <Inline alignment="left" className="request-type-table__format" gap="050">
        <Icon
          name={QUERY_TYPE_ICON[cell.value] ?? QUERY_TYPE_ICON.CONVERSATION}
          className="request-type-table__format__icon"
          color={IconColor.TEXT_TERTIARY}
        />
        <Text
          text={t("requests:format.name", { context: cell.value })}
          color={FontColor.TERTIARY}
          truncate={true}
        />
      </Inline>
    ),
    [t]
  );

  const timeZone = hostObject?.host.properties.timeZone;

  const renderCreatedByColumn = useCallback(
    ({ cell }: { cell: { value: string } }) => {
      const [name, date] = cell.value.split("/");
      const formateDate =
        dateAndTimeFormatter(date, locale, shortFormat, timeZone) ?? "";
      return (
        <Stack>
          <Text
            text={name}
            maxLines={3}
            truncate={true}
            color={FontColor.TERTIARY}
          />
          <Text text={formateDate} color={FontColor.TERTIARY} />
        </Stack>
      );
    },
    [locale, shortFormat, timeZone]
  );

  const columns = useMemo(
    () => [
      createColumn({
        Header: t("common:ui.requestType.field.projectType"),
        accessor: "engagementType",
        width: 40,
        disableSortBy: false
      }),
      createColumn({
        Header: t("common:ui.requestType.field.year"),
        accessor: "year",
        width: 15,
        disableSortBy: false,
        Cell: renderYearCol,
        isHidden: !yearEnabled
      }),
      createColumn({
        Header: t("common:ui.requestType.field.name"),
        accessor: "name",
        width: 40,
        disableSortBy: false,
        Cell: renderNameCol
      }),
      createColumn({
        Header: t("common:ui.requestType.field.description"),
        accessor: "description",
        width: 40,
        Cell: renderDescriptionCol
      }),
      createColumn({
        Header: t("common:ui.requestType.field.format"),
        accessor: "type",
        width: 40,
        disableSortBy: false,
        Cell: renderFormatColumn
      }),
      createColumn({
        Header: t("common:ui.requestType.field.createdBy"),
        accessor: "createdBy",
        width: 40,
        disableSortBy: false,
        Cell: renderCreatedByColumn
      }),
      createColumn({
        Header: t("common:ui.requestType.field.updatedBy"),
        accessor: "updatedBy",
        width: 40,
        disableSortBy: false,
        Cell: renderCreatedByColumn
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        width: 30,
        className: "auto-height",
        onClickHandler: dropdownMenuActionHandler,
        displayCell: cellVisibilityHandler
      })
    ],
    [
      createColumn,
      t,
      renderDescriptionCol,
      renderFormatColumn,
      renderCreatedByColumn,
      createColumnForDropdownMenu,
      dropdownMenuActionHandler,
      cellVisibilityHandler,
      yearEnabled
    ]
  );

  const actionsMenuPerRequestType = useCallback(
    isUserManaged => {
      const actionItems = [];
      if (canUpdate && isUserManaged) {
        actionItems.push({
          key: ACTIONS.update.key,
          label: t(ACTIONS.update.label)
        });
      }
      actionItems.push({
        key: ACTIONS.view.key,
        label: t(ACTIONS.view.label)
      });
      return actionItems;
    },
    [canUpdate, t]
  );

  const data = useMemo(
    () =>
      requestTypes.map(item => {
        const engagementType = item.engagementType ? (
          <Pill label={t(item.engagementType)} size={PillSize.SMALL} />
        ) : (
          ""
        );
        const createdBy = `${item.createdBy}/${item.createdAt}`;
        const updatedBy = `${item.updatedBy}/${item.updatedAt}`;
        const name = displayName(item, t);
        const actionsMenu = actionsMenuPerRequestType(
          item.configuration?.isUserManaged
        );
        return {
          ...item,
          name,
          engagementType,
          createdBy,
          updatedBy,
          actionsMenu
        };
      }),
    [requestTypes, t, actionsMenuPerRequestType]
  );

  return (
    <DataTable
      className="clients-table"
      columns={columns}
      data={data}
      onMouseEnterRow={(rowId: number) =>
        setRowDisplay({ rowId, display: true })
      }
      onMouseLeaveRow={(rowId: number) =>
        setRowDisplay({ rowId, display: false })
      }
      sortFilter={sortFilter}
      onRowClick={(rowId: number) => handleAction(ACTIONS.view.key, rowId)}
      onSortColClick={(sortingFilterObj: SortingFilter) =>
        props.onHandleSort(sortingFilterObj)
      }
    />
  );
};

export default RequestTypeDataTable;
