import React from "react";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { routeConstants } from "@constants";

import { Button, ButtonVariant } from "@atoms/Button";

import ModalTemplate from "@components/templates/ModalTemplate";

import "./AddEngagementUserTemplate.scss";

const AddEngagementUserTemplate = props => {
  const {
    user,
    engagement,
    selectedUser,
    memberOptions,
    handleCancel,
    handleAdd,
    isLoading,
    renderClientDropdown,
    renderSelectedUserDropdown,
    t
  } = props;

  return (
    <ModalTemplate
      boxClassName="tag-editor"
      title={t("ui.engagement.addUserLabel")}
      onClose={handleCancel}
      content={
        <div className="add-engagement-user">
          <div className="add-engagement-user__container">
            {user.isHostUser && (
              <div className="add-engagement-user__member-from">
                <div className="add-engagement-user__dropdown">
                  {renderClientDropdown()}
                </div>
              </div>
            )}
            <div className="add-engagement-user__member">
              {!isLoading && memberOptions && memberOptions.length ? (
                <>
                  <div className="add-engagement-user__dropdown">
                    {renderSelectedUserDropdown()}
                  </div>
                </>
              ) : (
                <div className="add-engagement-user__dropdown-no-user">
                  <span>
                    No user accounts have been setup for this client. First you
                    will need to{" "}
                    <Link
                      to={routeConstants.manageClientUsers}
                      state={engagement.client}
                      className="add-client-engagement-project__links"
                    >
                      add one or more users.
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      footer={
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            label="Cancel"
            onClick={handleCancel}
          />
          <Button
            disabled={selectedUser && selectedUser.name ? false : true}
            label="Add"
            onClick={handleAdd}
          />
        </>
      }
    />
  );
};

export default withTranslation("common")(AddEngagementUserTemplate);
