import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import {
  constructRoleForBE,
  entityRestrictedDefault
} from "@shared/helpers/roleHelper";
import { useAddRoleMutation } from "@shared/services/roleService";

import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";
import { ResourcePermission, RoleFormData, RoleMode } from "@app/types";

import RoleFields from "@components/molecules/RoleFields";
import FormPageTemplate from "@components/templates/FormPageTemplate";

type Props = {
  resourcesPermissions: ResourcePermission[];
  isFeaturesLoading: boolean;
};

const AddRoleForm = (props: Props) => {
  const { resourcesPermissions, isFeaturesLoading } = props;
  const [addRole, { isSuccess, isLoading, error }] = useAddRoleMutation();
  const { t } = useTranslation();
  const goBack = useGoBack();

  useEffect(() => {
    if (isSuccess) {
      goBack();
    }
  }, [isSuccess, goBack]);

  const yupSchema = yup.object({
    name: yup.string().required(t("roles:ui.name.requiredMessage"))
  });

  const handleFormSubmit = (data: RoleFormData) => {
    addRole(constructRoleForBE({ data, resourcesPermissions }));
  };

  return (
    <FormPageTemplate
      title={t("roles:ui.addRole.title")}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageUserRoles,
          label: t("admin:ui.manageUserRoles.title")
        }
      ]}
      sticky
      form={{
        contents: (
          <RoleFields
            defaultValue={{
              type: "CLIENT",
              entityRestricted: entityRestrictedDefault
            }}
            resourcesPermissions={resourcesPermissions}
            mode={RoleMode.CREATE}
          />
        ),
        yupSchema,
        submitLabel: "Save",
        handleSubmit: handleFormSubmit,
        handleCancel: () => goBack(),
        disabled: isLoading
      }}
      isLoading={isFeaturesLoading}
      other={{ error: error ? getErrorMessage(error) : "" }}
      renderRawForm
    />
  );
};

AddRoleForm.defaultProps = {
  resourcesPermissions: []
};

export default AddRoleForm;
