import { manageFinalPackageDocumentsConstants } from "../constants";

const initialState = {
  visualisation: {
    loading: false,
    uploading: false,
    documents: [],
    updateSuccess: false,
    refresh: false
  },
  error: ""
};

export function manageFinalPackageDocuments(state = initialState, action = {}) {
  switch (action.type) {
    case manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_REQUEST:
      return {
        ...state,
        error: "",
        visualisation: {
          ...state.visualisation,
          loading: true,
          refresh: false
        }
      };
    case manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_SUCCESS:
      return {
        ...state,
        visualisation: {
          loading: false,
          refresh: false,
          documents: action.result.documents
        }
      };
    case manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_FAILURE:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          loading: false,
          refresh: false
        },
        error: action.error.key ?? action.error.message
      };
    case manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_REQUEST:
      return {
        ...state,
        error: "",
        visualisation: {
          ...state.visualisation,
          uploading: true
        }
      };
    case manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_SUCCESS:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          refresh: true,
          uploading: false
        }
      };
    case manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_FAILURE:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          uploading: false
        },
        error: action.error.key ?? action.error.message
      };
    case manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_REQUEST:
      return {
        ...state,
        error: "",
        visualisation: {
          ...state.visualisation,
          loading: true,
          updateSuccess: false
        }
      };
    case manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_SUCCESS:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          updateSuccess: true,
          loading: false,
          refresh: true
        }
      };
    case manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_FAILURE:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          loading: false
        },
        error: action.error.key ?? action.error.message
      };

    case manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_REQUEST:
      return {
        ...state,
        error: "",
        visualisation: {
          ...state.visualisation,
          updateSuccess: false,
          loading: true
        }
      };
    case manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_SUCCESS:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          updateSuccess: true,
          refresh: true,
          loading: false
        }
      };
    case manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_FAILURE:
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          loading: false
        },
        error: action.error.key ?? action.error.message
      };
    case manageFinalPackageDocumentsConstants.RESET_VISUALISATION_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageFinalPackageDocumentsConstants.RESET_VISUALISATION:
      return {
        ...state,
        visualisation: { folderId: null, documents: [] }
      };

    default:
      return state;
  }
}
