import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useGetClientProjectsWithSameEngagementQuery } from "@shared/hooks";

import Form from "@components/atoms/Form";
import Loading from "@components/molecules/Loading/Loading";

function SelectProjectCopyStep({ project }) {
  const { t } = useTranslation();
  const { data: projects, isLoading: loadingProjects } =
    useGetClientProjectsWithSameEngagementQuery(
      { projectId: project?.id },
      {
        skip: !project?.id,
        refetchOnMountOrArgChange: true
      }
    );

  const getProjectSelectionDropdown = () => {
    if (projects?.length) {
      return (
        <Form.Dropdown
          key={"project"}
          name={"project"}
          label={t("stringFormat.capitalize", {
            key: "common:projects.displayName_one"
          })}
          required={true}
          items={projects?.map(p => ({ name: p.name, value: p }))}
        />
      );
    } else {
      return (
        <span>
          {t("requests:requests.ui.copyRequests.noProjectsToCopyFrom")}
        </span>
      );
    }
  };
  return <>{loadingProjects ? <Loading /> : getProjectSelectionDropdown()}</>;
}

SelectProjectCopyStep.propTypes = {
  project: PropTypes.shape({ id: PropTypes.number })
};

export default SelectProjectCopyStep;
