import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { routeConstants } from "@constants/routeConstants.js";

import { systemConstants } from "@shared/constants/systemConstants.ts";
import useAccessControl from "@shared/hooks/useAccessControl.tsx";
import { useFeatures } from "@shared/hooks/useFeatures.jsx";

import otaiLogoSrc from "@app/assets/img/oneteamai-white.svg";
import { AccessLevel } from "@app/types/Permission.ts";
import { ResourceName } from "@app/types/Resource.ts";
import { RoleType } from "@app/types/Role.ts";

const features = systemConstants.features;

interface NavItemAccessControl {
  permission?: {
    resource: ResourceName;
    accessLevel: AccessLevel;
    type: RoleType;
  };
  enabledFeatures?: string[];
}
interface NavItemCore {
  name: string;
  path: string;
  icon: string;
  image?: {
    src: string;
    alt: string;
  };
}

interface RestrictedNavItem {
  prepare: () => NavItemCore;
  accessControl: () => NavItemAccessControl;
}

interface NavItem extends NavItemAccessControl, NavItemCore {}

export function useAppNavItems() {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const { filterByPermissionFn } = useAccessControl();

  const appNavItems = useMemo(() => {
    const hasPermissionPredicate = (item: RestrictedNavItem) => {
      const acl = item.accessControl();
      return !acl?.permission || filterByPermissionFn(acl);
    };
    const hasEnabledFeaturePredicate = (item: RestrictedNavItem) => {
      const acl = item.accessControl();
      return !acl?.enabledFeatures || acl.enabledFeatures?.every(isEnabled);
    };
    const restrictedToNavItem = (item: RestrictedNavItem): NavItem => ({
      ...item.prepare(),
      ...item.accessControl()
    });

    const navItems: RestrictedNavItem[] = [
      {
        prepare: () => ({
          name: t("common:ui.navigation.apps.oneteamAI.label"),
          path: routeConstants.oneTeamAI.redirect,
          icon: "nearby",
          image: {
            src: otaiLogoSrc,
            alt: t("common:ui.navigation.apps.oneteamAI.label")
          }
        }),
        accessControl: () => ({
          permission: {
            resource: ResourceName.ONETEAM_AI,
            accessLevel: AccessLevel.READ,
            type: "HOST"
          },
          enabledFeatures: [features.oneteamAI]
        })
      }
    ];

    return navItems
      .filter(hasPermissionPredicate)
      .filter(hasEnabledFeaturePredicate)
      .map(restrictedToNavItem);
  }, [t, filterByPermissionFn, isEnabled]);

  return {
    appNavItems
  };
}
