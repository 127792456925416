import { manageClientProjectsConstants } from "../constants";

const initialState = {
  clientId: null,
  isProjectsLoading: false,
  isQueriesLoading: false,
  projects: [],
  queries: [],
  error: ""
};

export function manageClientProjects(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientProjectsConstants.GET_PROJECTS_REQUEST:
      return {
        ...state,
        isProjectsLoading: true,
        clientId: action.clientId
      };
    case manageClientProjectsConstants.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isProjectsLoading: false,
        projects: action.projects,
        error: ""
      };
    case manageClientProjectsConstants.GET_PROJECTS_FAILURE:
      return {
        ...state,
        isProjectsLoading: false,
        projects: [],
        error: action.error
      };
    case manageClientProjectsConstants.GET_QUERIES_REQUEST:
      return {
        ...state,
        isQueriesLoading: true,
        clientId: action.clientId
      };
    case manageClientProjectsConstants.GET_QUERIES_SUCCESS:
      return {
        ...state,
        queries: action.queries,
        isQueriesLoading: false,
        error: ""
      };
    case manageClientProjectsConstants.GET_QUERIES_FAILURE:
      return {
        ...state,
        queries: [],
        isQueriesLoading: false,
        error: action.error
      };
    case manageClientProjectsConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };

    case manageClientProjectsConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
