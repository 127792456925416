import React, { useCallback } from "react";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import ArrowNavigationButtonGroup from "@components/molecules/ArrowNavigationButtonGroup";

import "./WebsheetSheetTabs.scss";
import WebsheetTabsContent from "./WebsheetTabsContent";
import WebsheetTabsDropdown from "./WebsheetTabsDropdown";

const WebsheetSheetTabs = props => {
  const { currentSheetName, sheetNames, updateSheet } = props;

  const handleClickTab = useCallback(
    (sheet, index) => {
      const targetTab = document.getElementById(
        websheetUtilities.getWebsheetSheetTabId(index)
      );
      const targetDropdownItem = document.getElementById(
        websheetUtilities.getWebsheetDropdownItemId(index)
      );
      targetTab?.scrollIntoView(false);
      targetDropdownItem?.scrollIntoView(false);
      updateSheet(sheet);
    },
    [updateSheet]
  );
  const currentSheetIndex = sheetNames.findIndex(
    name => name === currentSheetName
  );

  const handlePreviousArrowClick = useCallback(() => {
    if (currentSheetIndex === -1) {
      return;
    }
    const targetIndex = currentSheetIndex - 1;
    const target = sheetNames[targetIndex];
    handleClickTab(target, targetIndex);
  }, [currentSheetIndex, handleClickTab, sheetNames]);

  const handleNextArrowClick = useCallback(() => {
    if (currentSheetIndex === -1) {
      return;
    }
    const targetIndex = currentSheetIndex + 1;
    const target = sheetNames[targetIndex];
    handleClickTab(target, targetIndex);
  }, [currentSheetIndex, handleClickTab, sheetNames]);
  return (
    <div
      className="websheet-sheet-tabs"
      style={{ height: `${websheetUtilities.documentFooterHeightPx}px` }}
    >
      {currentSheetName && (
        <>
          {sheetNames.length > 1 && (
            <>
              <ArrowNavigationButtonGroup
                onClickPrevious={handlePreviousArrowClick}
                disablePrevious={currentSheetIndex === 0}
                disableNext={currentSheetIndex === sheetNames.length - 1}
                onClickNext={handleNextArrowClick}
              />
              <WebsheetTabsDropdown
                sheetNames={sheetNames}
                handleClickTab={handleClickTab}
                currentSheetName={currentSheetName}
              />
            </>
          )}
          <WebsheetTabsContent
            handleClickTab={handleClickTab}
            currentSheetName={currentSheetName}
            sheetNames={sheetNames}
          />
        </>
      )}
    </div>
  );
};

export default WebsheetSheetTabs;
