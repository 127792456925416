import React, { useCallback, useEffect, useRef, useState } from "react";

import { useAuthUser, useVisualisation } from "@shared/hooks";

import { visualisationConstants } from "@app/constants/visualisationConstants";

import VisualisationTemplate from "@components/templates/VisualisationTemplate";

const Visualisation = props => {
  const { project, onDelete, isProjectMember } = props;
  const { user } = useAuthUser({ withProfile: false });
  const {
    visualisationDocuments,
    workbookDocument,
    refresh,
    loadingVisualisations,
    uploadingVisualisation,
    fetchVisualisationFolder,
    resetVisualisationFolder,
    uploadVisualisationFile,
    updateVisualisationTitle,
    deleteVisualisationFile
  } = useVisualisation();

  const [droppedFile, setDroppedFile] = useState(null);
  const [droppedFilePosition, setDroppedFilePosition] = useState(null);
  const [documents, setDocuments] = useState();
  const [visualisationConfig, setVisualisationConfig] = useState({
    image: {
      label: "Upload Visualisation",
      supportedMimes: visualisationConstants.visualisationMimes,
      formatError: visualisationConstants.visualisationFormatError,
      supportedDocumentMimesMessage:
        visualisationConstants.supportedDocumentMimesMessage
    },
    workbook: {
      label: "Upload Workbook",
      supportedMimes: visualisationConstants.workbookMimes,
      formatError: visualisationConstants.workbookFormatError
    }
  });
  const isUploadDisabled = useRef(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);

  const checkMembership = useCallback(
    msg => cb => {
      if (isProjectMember) {
        isProjectMember(user, msg, cb, errMessage => {
          setMembershipErrorMessage(errMessage);
          isUploadDisabled.current = true;
        });
      }
    },
    [isProjectMember, user]
  );

  useEffect(() => {
    checkMembership("")(() => {
      isUploadDisabled.current = false;
    });
  }, [checkMembership]);

  useEffect(() => {
    const config = project.configuration?.finalPackage?.visualisation;
    if (!config) {
      return;
    }
    setVisualisationConfig(config);
  }, [project]);

  useEffect(() => {
    if (refresh) {
      fetchVisualisationFolder(project.id);
    }
  }, [refresh, fetchVisualisationFolder, project]);

  useEffect(() => {
    setDocuments(visualisationDocuments);
  }, [visualisationDocuments]);

  useEffect(() => {
    fetchVisualisationFolder(project.id);
  }, [project, fetchVisualisationFolder]);

  useEffect(() => {
    if (droppedFile) {
      uploadVisualisationFile({
        projectId: project.id,
        file: droppedFile,
        positionNum: droppedFilePosition
      });
      setDroppedFile(null);
    }
  }, [droppedFile, project, droppedFilePosition, uploadVisualisationFile]);

  const handleUploadPermission = msg => {
    checkMembership(msg)(() => {});
    setShowProjectAccessModal(isUploadDisabled.current);
    return !isUploadDisabled.current;
  };

  const handleCloseModal = useCallback(() => {
    setShowProjectAccessModal(false);
    setMembershipErrorMessage("");
  }, []);

  const handleDrop = useCallback((acceptedFile, positionNum) => {
    if (acceptedFile.length) {
      const newFile = acceptedFile[0];
      newFile.state = "selected";
      setDroppedFilePosition(positionNum);
      setDroppedFile(newFile);
    }
  }, []);

  const handleDelete = useCallback(
    document => {
      onDelete();
      resetVisualisationFolder();
      deleteVisualisationFile({ projectId: project.id, document });
    },
    [deleteVisualisationFile, resetVisualisationFolder, project, onDelete]
  );

  const handleEditTitle = ({ documentId, name }) => {
    updateVisualisationTitle({
      documentId,
      name,
      projectId: project.id
    });
  };

  return (
    <VisualisationTemplate
      user={user}
      config={visualisationConfig}
      onDrop={handleDrop}
      onDelete={handleDelete}
      onEditTitle={handleEditTitle}
      project={project}
      isProjectMember={isProjectMember}
      isUploadDisabled={isUploadDisabled}
      documents={documents}
      workbook={workbookDocument}
      loading={loadingVisualisations}
      uploading={uploadingVisualisation}
      showProjectAccessModal={showProjectAccessModal}
      membershipErrorMessage={membershipErrorMessage}
      handleUploadPermission={handleUploadPermission}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default Visualisation;
