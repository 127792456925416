import { manageExcelDocumentConstants } from "../constants";
import { manageExcelDocumentService } from "../services";

export const manageExcelDocumentActions = {
  getExcelDocumentAsJSON,
  updateExcelDocument,
  getLockForExcelDocument,
  refreshLockForExcelDocument,
  finishEditSession,
  reset
};

function finishEditSession({ fileLockId, documentId, documentRevisionId }) {
  return dispatch => {
    dispatch(request());

    manageExcelDocumentService
      .finishEditSession({ fileLockId, documentId, documentRevisionId })
      .then(
        lock => {
          dispatch(success(lock));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_REQUEST
    };
  }
  function success(lock) {
    return {
      type: manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_SUCCESS,
      lock
    };
  }
  function failure(error, lock) {
    return {
      type: manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_FAILURE,
      error,
      lock
    };
  }
}

function refreshLockForExcelDocument({
  fileLockId,
  documentId,
  documentRevisionId
}) {
  return dispatch => {
    dispatch(request());

    manageExcelDocumentService
      .refreshLockForExcelDocument({
        fileLockId,
        documentId,
        documentRevisionId
      })
      .then(
        lock => {
          dispatch(success(lock));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_REQUEST
    };
  }
  function success(lock) {
    return {
      type: manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_SUCCESS,
      lock
    };
  }
  function failure(error, lock) {
    return {
      type: manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_FAILURE,
      error,
      lock
    };
  }
}

function getLockForExcelDocument({ id, documentRevisionId }) {
  return dispatch => {
    dispatch(request());

    manageExcelDocumentService
      .getLockForExcelDocument({ id, documentRevisionId })
      .then(
        lock => {
          dispatch(success(lock));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_REQUEST
    };
  }
  function success(lock) {
    return {
      type: manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_SUCCESS,
      lock
    };
  }
  function failure(error, lock) {
    return {
      type: manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_FAILURE,
      error,
      lock
    };
  }
}

/**
 *
 * @param {object} options
 * @param {string} options.id
 * @param {string} [options.documentRevisionId]
 * @param {boolean} options.forceLatest
 * @param {string} [options.tab]
 * @returns
 */
function getExcelDocumentAsJSON({ id, documentRevisionId, forceLatest, tab }) {
  return dispatch => {
    dispatch(request());

    manageExcelDocumentService
      .getExcelDocumentAsJSON({ id, documentRevisionId, forceLatest, tab })
      .then(
        document => dispatch(success(document, tab)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return {
      type: manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_REQUEST
    };
  }
  function success(document, tab) {
    return {
      type: manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_SUCCESS,
      tab,
      document
    };
  }
  function failure(error) {
    return {
      type: manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_FAILURE,
      error
    };
  }
}

function updateExcelDocument({
  documentId,
  documentRevisionId,
  workbookData,
  revisionProperties,
  cb
}) {
  return dispatch => {
    dispatch(request());

    manageExcelDocumentService
      .updateExcelDocument({
        documentId,
        documentRevisionId,
        workbookData,
        revisionProperties
      })
      .then(
        document => {
          dispatch(success(document));
          cb?.(document);
        },
        error => dispatch(failure(error, document))
      );
  };

  function request() {
    return {
      type: manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error, document) {
    return {
      type: manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_FAILURE,
      error,
      document
    };
  }
}

function reset() {
  return {
    type: manageExcelDocumentConstants.RESET_DOWNLOAD_EXCEL_DOCUMENT_DOWNLOAD
  };
}
