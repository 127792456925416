import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useLocaleDate() {
  const [locale, setLocale] = useState("en-AU");
  const [options, setOptions] = useState({
    longFormat: {},
    shortFormat: {},
    numericFormat: {}
  });
  const { i18n, t } = useTranslation();
  const authentication = useSelector(state => state.authentication);

  useEffect(() => {
    const hostDateConfig = authentication?.host?.host?.properties?.dateConfig;
    if (hostDateConfig) {
      const {
        locale = "AU",
        longFormat,
        shortFormat,
        numericFormat
      } = hostDateConfig;
      setLocale(`${i18n.language}-${locale}`);
      setOptions({ longFormat, shortFormat, numericFormat });
    }
  }, [authentication?.host?.host?.properties?.dateConfig, i18n, t]);

  return { locale, options };
}
