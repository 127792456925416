import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { getIsEntityRestricted } from "@shared/helpers/roleHelper";
import { getIsOneTeamAIEnabled } from "@shared/helpers/roleHelper.ts";
import { useAuthUser } from "@shared/hooks";

import {
  AccessLevel,
  RTKError,
  ResourceName,
  ResourcePermission,
  RoleGet,
  RoleMode
} from "@app/types";

import { Inline } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import { MessageBox } from "@molecules/MessageBox";

import RoleFields from "@components/molecules/RoleFields";
import FormPageTemplate from "@components/templates/FormPageTemplate";

type Props = {
  resourcesPermissions?: ResourcePermission[];
  role?: RoleGet;
  error: RTKError;
  isLoading: boolean;
};

const ViewRoleForm = (props: Props) => {
  const { resourcesPermissions, role, error, isLoading } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuthUser();
  const hasEditAccess = user.checkAccess(
    ResourceName.ROLES,
    AccessLevel.UPDATE
  );

  const editButton = useMemo(() => {
    if (!role) {
      return <></>;
    }

    if (!role.createdBy) {
      return (
        <MessageBox message={t("roles:ui.updateRole.cannotUpdate.message")} />
      );
    }

    return (
      <Button
        variant={ButtonVariant.SECONDARY}
        iconName="edit"
        label={t("common:ui.forms.edit.label")}
        onClick={() => navigate(`/admin/roles/${role?.id}/edit`)}
        disabled={!role?.createdBy || !hasEditAccess}
      />
    );
  }, [hasEditAccess, navigate, role, t]);

  const renderPageActions = () => {
    return (
      <Inline gap="300" alignment="right">
        <Button
          variant={ButtonVariant.TEXT}
          label={t("common:ui.forms.close.label")}
          onClick={() => navigate(routeConstants.admin.manageUserRoles)}
        />
        {editButton}
      </Inline>
    );
  };

  const entityRestricted = useMemo(
    () =>
      getIsEntityRestricted({
        role,
        resourcesPermissions
      }),
    [resourcesPermissions, role]
  );

  const oneTeamAIEnabled = useMemo(
    () => getIsOneTeamAIEnabled({ role, resourcesPermissions }),
    [resourcesPermissions, role]
  );

  return (
    <FormPageTemplate
      title={t("roles:ui.viewRole.title", {
        roleName: role?.name
      })}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageUserRoles,
          label: t("admin:ui.manageUserRoles.title")
        }
      ]}
      sticky
      form={{
        contents: (
          <RoleFields
            defaultValue={{
              ...role,
              entityRestricted,
              oneTeamAIEnabled
            }}
            resourcesPermissions={resourcesPermissions}
            mode={RoleMode.VIEW}
          />
        ),
        renderPageActions
      }}
      isLoading={isLoading}
      other={{ error }}
      renderRawForm
    />
  );
};

ViewRoleForm.defaultProps = {
  resourcesPermissions: []
};

export default ViewRoleForm;
