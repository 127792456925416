export const manageProjectQueriesConstants = {
  GET_PROJECT_QUERIES_REQUEST: "GET_PROJECT_QUERIES_REQUEST",
  GET_PROJECT_QUERIES_SUCCESS: "GET_PROJECT_QUERIES_SUCCESS",
  GET_PROJECT_QUERIES_FAILURE: "GET_PROJECT_QUERIES_FAILURE",

  GET_PROJECT_QUERY_REQUEST: "GET_PROJECT_QUERY_REQUEST",
  GET_PROJECT_QUERY_SUCCESS: "GET_PROJECT_QUERY_SUCCESS",
  GET_PROJECT_QUERY_FAILURE: "GET_PROJECT_QUERY_FAILURE",

  ADD_PROJECT_QUERY_REQUEST: "ADD_PROJECT_QUERY_REQUEST",
  ADD_PROJECT_QUERY_SUCCESS: "ADD_PROJECT_QUERY_SUCCESS",
  ADD_PROJECT_QUERY_FAILURE: "ADD_PROJECT_QUERY_FAILURE",

  UPDATE_PROJECT_QUERY_REQUEST: "UPDATE_PROJECT_QUERY_REQUEST",
  UPDATE_PROJECT_QUERY_SUCCESS: "UPDATE_PROJECT_QUERY_SUCCESS",
  UPDATE_PROJECT_QUERY_FAILURE: "UPDATE_PROJECT_QUERY_FAILURE",

  UPDATE_PROJECT_QUERIES_SUCCESS: "UPDATE_PROJECT_QUERIES_SUCCESS",

  CLEAR_GET_PROJECT_QUERY: "CLEAR_GET_PROJECT_QUERY",
  CLEAR_ADDED_QUERY: "CLEAR_ADDED_QUERY",
  CLEAR_UPDATED_QUERY: "CLEAR_UPDATED_QUERY",
  CLEAR_ERROR: "CLEAR_ERROR_PROJECT_QUERIES",
  RESET: "RESET_PROJECT_QUERIES",

  GET_MY_QUERY_COUNT_REQUEST: "GET_MY_QUERY_COUNT_REQUEST",
  GET_MY_QUERY_COUNT_SUCCESS: "GET_MY_QUERY_COUNT_SUCCESS",
  GET_MY_QUERY_COUNT_FAILURE: "GET_MY_QUERY_COUNT_FAILURE"
};
