import React, { useCallback } from "react";

import PropTypes from "prop-types";

import EditableListItem from "../EditableListItem/EditableListItem";
import "./FinalPackageTipsList.scss";

export const FinalPackageTipsList = ({
  allowEdit,
  items,
  onEditItem,
  onDeleteItem
}) => {
  const onEditClicked = useCallback(
    item => {
      return () => {
        onEditItem?.(item);
      };
    },
    [onEditItem]
  );

  const onDeleteClicked = useCallback(
    item => {
      return () => {
        onDeleteItem?.(item);
      };
    },
    [onDeleteItem]
  );

  return (
    <div className="final-package-tips-list">
      {items?.map(item => {
        return (
          <div
            key={item.id || item.key}
            className="final-package-tips-list__item"
          >
            <EditableListItem
              text={item.tip}
              disabledText={!item?.id}
              canEdit={allowEdit}
              onEditClicked={onEditClicked(item)}
              onDeleteClicked={onDeleteClicked(item)}
            />
          </div>
        );
      })}
    </div>
  );
};

FinalPackageTipsList.propTypes = {
  allowEdit: PropTypes.bool,
  items: PropTypes.array,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func
};

export default FinalPackageTipsList;
