import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "@shared/constants";

import { Audit } from "@app/types";

import prepareHeaders from "./rtkUtilities";

export const auditService = createApi({
  reducerPath: "auditApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["Audits"],
  endpoints: builder => ({
    getAuditByEmail: builder.query<Audit[], string>({
      query: email => `/api/audit/report?${new URLSearchParams({ email })}`,
      providesTags: ["Audits"]
    })
  })
});

export const { useGetAuditByEmailQuery } = auditService;
