import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useInteractiveReportCharts } from "@shared/hooks/useInteractiveReportCharts";

import { getChartColors, parseWaterfallData } from "@app/helpers/chart";

import { Button } from "@atoms/Button";

import BarChartTable from "@components/atoms/Chart/BarChartTable";
import PieChartTable from "@components/atoms/Chart/PieChartTable";
import WaterfallChartTable from "@components/atoms/Chart/WaterfallChartTable/WaterfallChartTable";

import "./InteractiveReportChart.scss";
import chartVariables from "./chartVariables.module.scss";

const InteractiveReportChart = props => {
  const {
    chart,
    tickMaxLines,
    fixedHeight,
    isDrilldown,
    enableHidden,
    pieRadius,
    chartMargin,
    chartFileName
  } = props;
  const { pageId } = useParams();
  const { hiddenCharts, toggleChart, downloadChart } =
    useInteractiveReportCharts();
  const { id: chartId } = chart;
  const isHiddenChart = hiddenCharts[pageId]?.[chartId];
  const headerStyle = "primary";
  const borderStyle = "none";
  const { t } = useTranslation();

  const formatData = useCallback(() => {
    const dataKeys = chart.chartValues.map(c => c.label);
    //this maps each axis to a particular chartValue's value
    const values =
      chart?.axis?.values?.map((a, i) => {
        const data = {};
        data.label = chart.translate ? t(a) : a;
        chart.chartValues.forEach(v => {
          data[v.label] = v.values[i];
          data.info = v.info?.[i];
        });
        return data;
      }) ?? [];
    return {
      values,
      keys: dataKeys
    };
  }, [chart, t]);

  const renderChart = type => {
    if (!chart?.chartValues) {
      return <></>;
    }
    const data = formatData();

    const renderEmptyPlaceHolder = () => (
      <foreignObject x={0} y={85} className="interactive-report-chart__empty">
        <div>
          {t("interactiveReport:interactiveReport.ui.chart.empty.message")}
        </div>
      </foreignObject>
    );

    switch (type) {
      case "bar":
        return (
          <BarChartTable
            data={data}
            axis={chart.axis}
            className={`interactive-report-bar-chart`}
            isDrilldown={isDrilldown}
            colors={getChartColors(type, chartVariables)}
            displayZero={chart?.displayZero}
            tickMaxLines={tickMaxLines}
            fixedHeight={fixedHeight}
            showTopValues={chart?.showTopValues}
            dataType={chart?.dataType}
            legend={chart?.legend}
            renderEmptyPlaceHolder={renderEmptyPlaceHolder}
          />
        );
      case "line":
        return <></>;
      case "pie":
        return (
          <PieChartTable
            data={data}
            className={`interactive-report-pie-chart`}
            colors={getChartColors(type, chartVariables)}
            displayZero={chart?.displayZero}
            isDrilldown={isDrilldown}
            fixedHeight={fixedHeight}
            pieRadius={pieRadius}
            chartMargin={chartMargin}
            renderEmptyPlaceHolder={renderEmptyPlaceHolder}
            dataType={chart?.dataType}
          />
        );
      case "waterfall-combo":
      case "waterfall":
        return (
          <WaterfallChartTable
            data={parseWaterfallData(data)}
            axis={chart.axis}
            className="interactive-report-waterfall-chart"
            isDrilldown={isDrilldown}
            colors={getChartColors("waterfall", chartVariables)}
            displayZero={chart?.displayZero}
            fixedHeight={fixedHeight}
            dataType={chart?.dataType}
          />
        );
      default:
        return <></>;
    }
  };

  const elementId = () =>
    isDrilldown
      ? "interactive-report-chart-drilldown"
      : "interactive-report-chart";

  return (
    <div
      className={`interactive-report-chart border--${borderStyle}`}
      id={elementId()}
    >
      {enableHidden && (
        <div
          className={`interactive-report-chart__header${
            isHiddenChart ? "--hidden" : ""
          } interactive-report-chart__header--${headerStyle}`}
        >
          <Button
            label={isHiddenChart ? "SHOW GRAPH" : "HIDE GRAPH"}
            onClick={() => toggleChart(pageId, chartId)}
          />
          {!isHiddenChart && (
            <Button
              label="DOWNLOAD GRAPH"
              onClick={() =>
                downloadChart({
                  fileName: chartFileName,
                  elementId: elementId()
                })
              }
            />
          )}
        </div>
      )}
      <div
        className={`interactive-report-chart__content--${
          isHiddenChart ? "collapse" : "expand"
        }`}
      >
        {renderChart(chart.type)}
      </div>
    </div>
  );
};

InteractiveReportChart.defaultProps = {
  enableHidden: true
};

InteractiveReportChart.propTypes = {
  pieRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.shape({
    type: PropTypes.string.isRequired,
    axis: PropTypes.shape({
      label: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string)
    }),
    chartValues: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.number)
      })
    ),
    displayZero: PropTypes.bool,
    dataType: PropTypes.oneOf(["currency", "number", "percentage"]),
    legend: PropTypes.shape({
      translate: PropTypes.bool
    })
  }),
  enableHidden: PropTypes.bool,
  tickMaxLines: PropTypes.number,
  fixedHeight: PropTypes.number,
  isDrilldown: PropTypes.bool,
  chartMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  })
};

export default InteractiveReportChart;
