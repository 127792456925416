import {
  ToDoType,
  manageProjectConstants,
  manageProjectDocumentsConstants
} from "../constants";
import { manageProjectService } from "../services";

export const manageProjectActions = {
  getProjectTodos,
  updateProjectTodoStatus,
  updateProjectTodo,
  getProjectDocuments,
  getProjectDocument,
  getProjectFoldersAndDocuments,
  resetUpdateProjectTodoStatusFlag,
  reorderProjectMembers,
  clearError,
  resetDocument,
  reset,
  setCurrentProject,
  resetCurrentProject
};

function getProjectFoldersAndDocuments(project) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectFoldersAndDocuments(project).then(
      projectFoldersAndDocuments => {
        dispatch(success(projectFoldersAndDocuments));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST
    };
  }
  function success(projectFoldersAndDocuments) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS,
      projectFoldersAndDocuments
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE,
      error
    };
  }
}

function reorderProjectMembers(project, members) {
  return dispatch => {
    dispatch(request());

    manageProjectService.reorderProjectMembers(project, members).then(
      members => {
        dispatch(success(members));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_REQUEST
    };
  }
  function success(members) {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_SUCCESS,
      members
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_FAILURE,
      error
    };
  }
}

function getProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectDocument(document).then(
      document => {
        dispatch(success(document));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function getProjectDocuments({ id: projectId }) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectDocuments({ id: projectId }).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_REQUEST
    };
  }
  function success(documents) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function getProjectTodos(project, type = ToDoType.ASSIGNED_TO_ME) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectTodos(project, type).then(
      todos => {
        dispatch(success(todos));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_REQUEST
    };
  }
  function success(todos) {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_SUCCESS,
      todos
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_FAILURE,
      error
    };
  }
}

function updateProjectTodoStatus(todo) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectTodoStatus(todo).then(
      todo => {
        dispatch(success(todo));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_REQUEST
    };
  }
  function success(todo) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_SUCCESS,
      todo
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_FAILURE,
      error
    };
  }
}

function updateProjectTodo(todo) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectTodo(todo).then(
      updatedTodo => {
        dispatch(success(updatedTodo));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_REQUEST
    };
  }
  function success(todo) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_SUCCESS,
      todo
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_FAILURE,
      error
    };
  }
}

function resetUpdateProjectTodoStatusFlag() {
  return { type: manageProjectConstants.RESET_UPDATE_PROJECT_TODO_STATUS_FLAG };
}

function clearError() {
  return { type: manageProjectConstants.CLEAR_ERROR };
}

function resetDocument() {
  return { type: manageProjectConstants.RESET_DOCUMENT };
}

function reset() {
  return { type: manageProjectConstants.RESET };
}

function setCurrentProject(project) {
  return {
    type: manageProjectConstants.SET_CURRENT_PROJECT,
    currentProject: project
  };
}

function resetCurrentProject() {
  return { type: manageProjectConstants.RESET_CURRENT_PROJECT };
}
