import { finalPackageConstants } from "../constants";
import { finalPackageService } from "../services";

export const finalPackageActions = {
  getFinalPackageTips,
  getFinalPackageShareStatus,
  setFinalPackageShareStatus,
  updateFinalPackageTip,
  resetUpdateFinalPackageTip,
  deleteFinalPackageTip,
  resetDeleteFinalPackageTip,
  getFinalPackageDocuments,
  resetGetFinalPackageDocuments,
  getFinalPackageProcessEntries,
  createFinalPackageProcessEntry,
  deleteFinalPackageProcessEntry,
  resetCreateFinalPackageProcessEntry,
  resetGetFinalPackageProcessEntries
};

function resetGetFinalPackageProcessEntries() {
  return dispatch =>
    dispatch({
      type: finalPackageConstants.RESET_GET_FINAL_PACKAGE_PROCESSES
    });
}

function resetCreateFinalPackageProcessEntry() {
  return dispatch =>
    dispatch({
      type: finalPackageConstants.RESET_CREATE_FINAL_PACKAGE_PROCESSES
    });
}

function createFinalPackageProcessEntry(item) {
  return dispatch => {
    dispatch(request());

    finalPackageService.createFinalPackageProcessEntry(item).then(
      processItems => {
        dispatch(success(processItems));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_REQUEST
      };
    }
    function success({ items: finalPackageProcessEntries }) {
      return {
        type: finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_SUCCESS,
        finalPackageProcessEntries
      };
    }
    function failure(error) {
      return {
        type: finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_FAILURE,
        error
      };
    }
  };
}

function getFinalPackageProcessEntries({ projectId }) {
  return dispatch => {
    dispatch(request());

    finalPackageService.getFinalPackageProcessEntries(projectId).then(
      processItems => {
        dispatch(success(processItems));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_REQUEST
      };
    }
    function success({ items: finalPackageProcessEntries }) {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_SUCCESS,
        finalPackageProcessEntries
      };
    }
    function failure(error) {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_FAILURE,
        error
      };
    }
  };
}

function deleteFinalPackageProcessEntry({ projectId, id }) {
  return dispatch => {
    dispatch(request());

    finalPackageService.deleteFinalPackageProcessEntry({ projectId, id }).then(
      () => {
        dispatch(success());
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_REQUEST
      };
    }
    function success() {
      return {
        type: finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_SUCCESS
      };
    }
    function failure(error) {
      return {
        type: finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_FAILURE,
        error
      };
    }
  };
}

function getFinalPackageTips(projectId) {
  return dispatch => {
    dispatch(request());

    finalPackageService.getFinalPackageTips(projectId).then(
      finalPackageTips => {
        dispatch(success(finalPackageTips));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_TIPS_REQUEST
      };
    }
    function success(finalPackageTips) {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_TIPS_SUCCESS,
        finalPackageTips
      };
    }
    function failure(error) {
      return {
        type: finalPackageConstants.GET_FINAL_PACKAGE_TIPS_FAILURE,
        error
      };
    }
  };
}

function updateFinalPackageTip(projectId, finalPackageTip) {
  return dispatch => {
    dispatch(request());

    finalPackageTip?.id
      ? finalPackageService
          .updateFinalPackageTip(projectId, finalPackageTip)
          .then(
            updatedFinalPackageTip => {
              dispatch(success(updatedFinalPackageTip));
            },
            error => {
              dispatch(failure(error));
            }
          )
      : finalPackageService.addFinalPackageTip(projectId, finalPackageTip).then(
          updatedFinalPackageTip => {
            dispatch(success(updatedFinalPackageTip));
          },
          error => {
            dispatch(failure(error));
          }
        );
  };

  function request() {
    return { type: finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_REQUEST };
  }
  function success(updatedFinalPackageTip) {
    return {
      type: finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_SUCCESS,
      updatedFinalPackageTip
    };
  }
  function failure(error) {
    return {
      type: finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_FAILURE,
      error
    };
  }
}

function resetUpdateFinalPackageTip() {
  return { type: finalPackageConstants.RESET_UPDATE_FINAL_PACKAGE_TIP };
}

function deleteFinalPackageTip(projectId, finalPackageTip) {
  return dispatch => {
    dispatch(request());

    finalPackageService.deleteFinalPackageTip(projectId, finalPackageTip).then(
      deletedFinalPackageTip => {
        dispatch(success(deletedFinalPackageTip));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_REQUEST };
  }
  function success(deletedFinalPackageTip) {
    return {
      type: finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_SUCCESS,
      deletedFinalPackageTip
    };
  }
  function failure(error) {
    return {
      type: finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_FAILURE,
      error
    };
  }
}

function resetDeleteFinalPackageTip() {
  return { type: finalPackageConstants.RESET_DELETE_FINAL_PACKAGE_TIP };
}

function getFinalPackageDocuments(projectId) {
  return dispatch => {
    dispatch(request());

    finalPackageService.getFinalPackageDocuments(projectId).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_REQUEST
    };
  }
  function success(response) {
    return {
      type: finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_SUCCESS,
      documents: response.documents,
      finalPackageFolder: response.finalPackageFolder
    };
  }
  function failure(error) {
    return {
      type: finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_FAILURE,
      error
    };
  }
}

function resetGetFinalPackageDocuments() {
  return dispatch =>
    dispatch({
      type: finalPackageConstants.RESET_GET_FINAL_PACKAGE_DOCUMENTS
    });
}

function getFinalPackageShareStatus(projectId) {
  return dispatch => {
    dispatch(request());

    finalPackageService.getFinalPackageShareStatus(projectId).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_REQUEST
    };
  }
  function success(shareStatus) {
    return {
      type: finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_SUCCESS,
      shareStatus
    };
  }
  function failure(error) {
    return {
      type: finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_FAILURE,
      error
    };
  }
}

function setFinalPackageShareStatus({ projectId, shareStatus }) {
  return dispatch => {
    dispatch(request());

    finalPackageService
      .setFinalPackageShareStatus({ projectId, shareStatus })
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_REQUEST
    };
  }
  function success(shareStatus) {
    return {
      type: finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_SUCCESS,
      shareStatus
    };
  }
  function failure(error) {
    return {
      type: finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_FAILURE,
      error
    };
  }
}
