export const visualisationConstants = {
  visualisationFormatError: "Files must be in .png format to upload",
  visualisationAmountError:
    "Only one file can be uploaded. Make sure there is exactly one file attached and try again",
  workbookFormatError:
    "Only the following file types can be uploaded: .twb, .twbx. and .png",
  visualisationMimes: ["png"],
  workbookMimes: ["twb", "png", "twbx"],
  maximumFileNum: 1,
  supportedDocumentMimesMessage: "Drag and drop a PNG file or "
};
