import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import ConversationMessage from "@components/molecules/ConversationMessage";

import "./ActionItemCommentsThread.scss";

function ActionItemCommentsThread(props) {
  const { comments, onFileDownload, authUserId } = props;
  const [responseSequenceId, setResponseSequenceId] = useState(1);
  const bottomOfConversations = useRef(null);

  useEffect(() => {
    if (comments) {
      setResponseSequenceId(prev => (!prev ? 1 : prev));
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [comments]);

  useEffect(() => {
    if (responseSequenceId) {
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [responseSequenceId]);

  return (
    <div className="comment-thread-modal__content">
      <div className="request-conversation__content-message">
        {comments?.map(comment => {
          return (
            <ConversationMessage
              key={comment.id}
              align={authUserId === comment.userId ? "right" : "left"}
              attachments={comment.attachments}
              user={{
                ...comment.user,
                name:
                  comment.user.name ||
                  `${comment.user.firstname} ${comment.user.lastname}`
              }}
              message={comment.response}
              updatedAt={comment.createdAt}
              onFileDownloadClicked={onFileDownload}
            />
          );
        })}
      </div>
      <div ref={bottomOfConversations}></div>
    </div>
  );
}

ActionItemCommentsThread.propTypes = {
  comments: PropTypes.array,
  onFileDownload: PropTypes.func,
  authUserId: PropTypes.number,
  actionItemTypeKey: PropTypes.string
};

export default ActionItemCommentsThread;
