import React, { useMemo } from "react";

import { Link, To } from "react-router-dom";

import { classNames } from "@app/helpers/componentHelpers";

import { Inline } from "@fermions";

import { Icon, IconSize } from "@atoms/Icon";

import "./Breadcrumbs.scss";

interface Breadcrumb {
  label: string;
  linkTo?: To;
  current?: boolean;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  currentPageTitle?: string;
  skipAddCurrentPage?: boolean;
}

const BreadcrumbDivider = () => {
  return (
    <Icon
      className="breadcrumb-divider"
      name="chevron_right"
      size={IconSize.XS}
    />
  );
};

const Breadcrumb = ({ crumb }: { crumb: Breadcrumb }) => {
  return (
    <Link
      to={crumb.linkTo}
      state={crumb.linkTo?.state}
      className={classNames([
        "ot-breadcrumb",
        crumb.current ? "ot-breadcrumb--current" : ""
      ])}
    >
      {crumb.label}
    </Link>
  );
};

const Breadcrumbs = ({
  breadcrumbs = [],
  currentPageTitle,
  skipAddCurrentPage
}: BreadcrumbsProps) => {
  const breadcrumbList = useMemo(() => {
    return [
      ...(breadcrumbs ?? []),
      ...(currentPageTitle && !skipAddCurrentPage
        ? [{ label: currentPageTitle, current: true, linkTo: {} }]
        : [])
    ];
  }, [breadcrumbs, currentPageTitle, skipAddCurrentPage]);

  return (
    <Inline
      className="breadcrumbs"
      data-testid="testBreadcrumbs"
      alignment="left"
    >
      {breadcrumbList.map((crumb, index) => (
        <React.Fragment key={crumb.label}>
          {index !== 0 && <BreadcrumbDivider />}
          <Breadcrumb crumb={crumb} />
        </React.Fragment>
      ))}
    </Inline>
  );
};

export default Breadcrumbs;
