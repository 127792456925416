import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ButtonVariant } from "@atoms/Button";

import ModalTemplate from "@components/templates/ModalTemplate";

const DangerModal = props => {
  const handleConfirmClick = () => props.handleConfirmation?.();
  const { t } = useTranslation();

  return (
    <ModalTemplate
      boxClassName="danger-modal"
      title={props.title || "Warning"}
      content={props.content || "Are you sure you want to do this?"}
      footer={
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            label={t("common:ui.forms.no.label")}
            onClick={props.onCancel}
          />
          <Button
            variant={ButtonVariant.DANGER}
            label={t("common:ui.forms.yes.label")}
            onClick={handleConfirmClick}
          />
        </>
      }
      onClose={props.onCancel}
    />
  );
};

DangerModal.defaultProps = {};

DangerModal.propTypes = {
  onCancel: PropTypes.func,
  handleConfirmation: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node
};

export default DangerModal;
