import React, { useCallback, useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Form from "@components/atoms/Form/Form";

const CopyProjectTagsFormFields = props => {
  const { t } = useTranslation();
  const { resetField } = useFormContext();
  const [projectsList, setProjectsList] = useState([]);
  const [defaultProject, setDefaultProject] = useState(null);
  const [tagsList, setTagsList] = useState([]);

  const onProjectSelectionChanged = useCallback(
    selectedProject => {
      setTagsList(
        selectedProject.value?.tags?.map(t => ({
          name: t.name,
          value: t
        })) ?? []
      );
      resetField("tags");
    },
    [resetField]
  );

  useEffect(() => {
    if (props.projects?.length > 0) {
      setProjectsList(props.projects.map(p => ({ name: p.name, value: p })));
      setDefaultProject(props.projects[0]);
      setTagsList(
        props.projects[0].tags?.map(t => ({ name: t.name, value: t }))
      );
    } else {
      setProjectsList([]);
    }
  }, [props.projects]);

  return (
    <>
      <Form.Dropdown
        label={t("common:ui.copyProjectTagsFormFields.project.title")}
        items={projectsList}
        name={"projects"}
        defaultValue={defaultProject}
        onChange={onProjectSelectionChanged}
      />
      <Form.Multiselect
        label={t("common:ui.copyProjectTagsFormFields.label.title")}
        name="tags"
        items={tagsList}
        disabled={tagsList.length === 0}
        required={true}
      />
    </>
  );
};

export default CopyProjectTagsFormFields;
