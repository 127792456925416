import React, { useCallback, useEffect } from "react";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Box, Stack } from "@fermions";

import { Icon, IconFillStyle } from "@atoms/Icon";

import { systemConstants } from "../../constants";
import "./dragNDrop.scss";

export interface DocumentsDragNDropProps {
  handleDrop: (files) => void;
  message?: string;
  linkName?: string;
  error?: string | boolean;
  className?: string;
  onDropRejected?: () => void;
  openFileSelection?: boolean;
  supportedMimes?: string[];
  maxFiles?: number;
}

const DocumentsDragNDrop = ({
  handleDrop,
  message,
  linkName,
  error,
  className,
  onDropRejected,
  openFileSelection,
  supportedMimes,
  maxFiles
}: DocumentsDragNDropProps) => {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();
  const onDrop = useCallback(
    acceptedFiles => {
      handleDrop(acceptedFiles);
    },
    [handleDrop]
  );

  const getSupportedMimes = () => {
    const list = supportedMimes ?? systemConstants.mimes.document;
    const result = list.map((ext: string) => `.${ext}`);
    return result.join(",");
  };

  const onRejected = useCallback(
    fileRejections => {
      if (onDropRejected) {
        appInsights?.trackTrace({
          message: JSON.stringify({
            errorMessage: "Unsupported file type blocked.",
            filesProperty: fileRejections.map(({ file }) => ({
              name: file.name,
              type: file.type
            }))
          })
        });
        onDropRejected(fileRejections);
      }
    },
    [appInsights, onDropRejected]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected: onRejected,
    accept: getSupportedMimes(),
    maxFiles: maxFiles || 0
  });

  useEffect(() => {
    if (openFileSelection) {
      open();
    }
  }, [openFileSelection, open]);

  return (
    <Stack
      className={[
        "drag-n-drop",
        className ?? "",
        error ? "drag-n-drop--error" : ""
      ]}
      alignment="center"
      {...getRootProps()}
    >
      <input className="drag-n-drop__input" {...getInputProps()} />
      <Stack className="drag-n-drop__text" alignment="center">
        <Icon
          className="drag-n-drop__icon"
          name="cloud_upload"
          fillStyle={IconFillStyle.FILLED}
        />
        {message ? (
          <>
            <Box className="drag-n-drop__text__main">{message}</Box>
            <Box className="drag-n-drop__text__link">{linkName}</Box>
          </>
        ) : (
          <>
            <Box className="drag-n-drop__text__main">
              {t("common:ui.forms.fileUpload.dragDropLabel")}
            </Box>
            <Box className="drag-n-drop__text__link">
              {t("common:ui.forms.fileUpload.browseLabel")}
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default DocumentsDragNDrop;
