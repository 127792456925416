import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";

export function useGetProjectDocuments() {
  const [error, setError] = useState();
  const [documents, setDocuments] = useState([]);
  const manageProject = useSelector(state => state.manageProject);
  const [isLoading, setIsLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();

  const getProjectDocuments = useCallback(
    id => {
      if (!id || isLoading) return;
      dispatch(manageProjectActions.getProjectDocuments({ id }));
    },
    [dispatch, isLoading]
  );

  useEffect(() => {
    setIsLoading(manageProject.documentsLoading);
  }, [manageProject.documentsLoading]);

  useEffect(() => {
    setDocuments(manageProject.documents || []);
  }, [manageProject.documents]);

  useEffect(() => {
    setError(manageProject.error);
  }, [manageProject.error]);

  useEffect(() => {
    setFetched(manageProject.documentsLoaded);
  }, [manageProject.documentsLoaded]);

  return {
    projectDocuments: documents,
    loadingProjectDocuments: isLoading,
    fetchedProjectDocuments: fetched,
    getProjectDocuments,
    errorFetchingProjectDocuments: error
  };
}
