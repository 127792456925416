import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useWindowSize } from "@shared/hooks/useWindowSize";

import "./BulletList.scss";

const BulletList = props => {
  const { list, panelRef, panelBodyRef } = props;
  const windowSize = useWindowSize();
  const [listSize, setListSize] = useState(null);

  useEffect(() => {
    const listWidth = panelBodyRef.current?.offsetWidth;
    const listHeight = panelRef.current?.offsetHeight;
    if (listWidth && listHeight) {
      setListSize({
        width: listWidth,
        height: listHeight - 90
      });
    }
  }, [windowSize, panelRef, panelBodyRef]);

  return (
    <div
      className="bullet-list"
      style={{ maxWidth: listSize?.width, maxHeight: listSize?.height }}
    >
      {listSize &&
        list.map((l, index) => (
          <div key={index} className="bullet-list__item">
            {l}
          </div>
        ))}
    </div>
  );
};

BulletList.defaultProps = {
  columnSize: 1
};

BulletList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string)
};

export default BulletList;
