import React from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants";

import Popup from "@shared-components/popup/Popup";

import AddOrCopyProjectActionItems from "@components/organisms/AddOrCopyProjectActionItems";

const CommentModal = ({
  source,
  project,
  onClickCancelComment,
  onClickSubmitComment,
  displayCommentModal
}) => {
  return (
    <>
      {displayCommentModal && (
        <div className="comment-modal">
          <Popup
            visibility={displayCommentModal}
            handleOutsideClick={onClickCancelComment}
            width="60rem"
          >
            <AddOrCopyProjectActionItems
              source={source}
              project={project}
              onCancel={onClickCancelComment}
              onSubmitted={onClickSubmitComment}
              isSlidingForm={false}
              disableCopy={true}
              modalClassName={"add-request-modal"}
              enableWebSheet={false}
              viewScope={systemConstants.viewScopeType.report}
            />
          </Popup>
        </div>
      )}
    </>
  );
};

CommentModal.propTypes = {
  source: PropTypes.shape({
    pageId: PropTypes.number,
    path: PropTypes.array,
    revision: PropTypes.number
  }),
  project: PropTypes.object,
  onClickCancelComment: PropTypes.func,
  onClickSubmitComment: PropTypes.func,
  displayCommentModal: PropTypes.bool
};
export default CommentModal;
