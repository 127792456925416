import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import "./ToggleButtonGroup.scss";

const ToggleButtonGroup = ({ onClick, toggleButtons, defaultType }) => {
  const [selectedType, setSelectedType] = useState(
    defaultType && toggleButtons.some(btn => btn.type == defaultType)
      ? toggleButtons.filter(btn => btn.type == defaultType)[0].type
      : toggleButtons[0].type
  );

  const getActiveClassName = type => {
    return selectedType === type
      ? "toggle-button--active"
      : "toggle-button--inactive";
  };

  useEffect(() => {
    onClick(selectedType);
  }, [onClick, selectedType]);

  return (
    <div className="toggle-button-group" data-html2canvas-ignore>
      {toggleButtons.map((btn, index) => (
        <span
          key={index}
          className={`toggle-button ${getActiveClassName(btn.type)}`}
          onClick={() => setSelectedType(btn.type)}
        >
          {btn.label}
        </span>
      ))}
    </div>
  );
};

ToggleButtonGroup.propTypes = {
  onClick: PropTypes.func.isRequired,
  toggleButtons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  defaultType: PropTypes.string
};
export default ToggleButtonGroup;
