import React, { forwardRef, useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";

import {
  InputLabel,
  InputLabelProps,
  InputTemplate,
  InputTemplateProps,
  InputType,
  InputWrapper,
  InputWrapperProps,
  getInputIdentifier
} from "../InputTemplate";

interface NumberInputProps
  extends InputTemplateProps,
    InputLabelProps,
    InputWrapperProps {
  value?: number | string;
  onChange?: (value?: number | string) => void;
  min?: number;
  max?: number;
}

export const NumberInput = forwardRef(
  (
    {
      className,
      value,
      onChange,
      label,
      labelPosition,
      required,
      description,
      hideLabel,
      disabled,
      placeholder,
      error,
      borderStyle,
      extraInputProps,
      width,
      fontColor,
      min,
      max
    }: NumberInputProps,
    fwdRef
  ) => {
    const identifier = useMemo(
      () => getInputIdentifier(label, "number-input"),
      [label]
    );

    const labelElement = useMemo(
      () => (
        <InputLabel
          label={label}
          disabled={disabled}
          required={required}
          description={description}
          id={identifier}
          hideLabel={hideLabel}
          fontColor={fontColor}
        />
      ),
      [label, disabled, required, description, identifier, hideLabel, fontColor]
    );

    const inputWrapperElement = useMemo(
      () => (
        <InputWrapper
          ref={fwdRef}
          current={value}
          setCurrent={onChange}
          placeholder={placeholder}
          disabled={disabled}
          type={InputType.NUMBER}
          clearValue={onChange}
          error={error}
          borderStyle={borderStyle}
          id={identifier}
          extraInputProps={{ ...extraInputProps, min: min, max: max }}
        />
      ),
      [
        fwdRef,
        value,
        onChange,
        placeholder,
        disabled,
        error,
        borderStyle,
        identifier,
        extraInputProps,
        min,
        max
      ]
    );

    return (
      <InputTemplate
        className={classNames(["number-input", className ?? ""])}
        labelElement={labelElement}
        labelPosition={labelPosition}
        inputWrapperElement={inputWrapperElement}
        error={error}
        borderStyle={borderStyle}
        width={width}
      />
    );
  }
);
