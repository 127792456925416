import React from "react";

import PropTypes from "prop-types";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";

import Cleaned from "./Cleaned.svg?react";
import Complete from "./Complete.svg?react";
import "./WebsheetStatusIcon.scss";

const WebSheetStatusIcon = ({ color = "success", status }) => {
  const displayIcons = () => {
    switch (status?.mark) {
      case "cleaned":
        return <Cleaned />;
      case "complete":
        return <Complete />;
      default:
        return <></>;
    }
  };

  return (
    <ContainerWithTooltip
      tooltipContent={status?.text}
      className={`websheet-status-icon websheet-status-icon--${color}`}
    >
      {displayIcons()}
    </ContainerWithTooltip>
  );
};

WebSheetStatusIcon.defaultProps = {
  color: "success"
};

WebSheetStatusIcon.propTypes = {
  color: PropTypes.oneOf(["success"]),
  status: PropTypes.shape({
    mark: PropTypes.string,
    text: PropTypes.string
  })
};

export default WebSheetStatusIcon;
