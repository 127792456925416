import PropTypes from "prop-types";

// Set all defaults to reduce the need of .?
export const defaultProps = {
  header: {
    title: "",
    hasTitleTooltip: false,
    subtitle: "",
    subtitleActionIcon: null,
    sticky: false,
    actions: null,
    content: null,
    breadcrumbs: [],
    alwaysShowBreadcrumbs: false,
    tagline: ""
  },
  body: {
    header: null,
    primary: null,
    secondary: null,
    secondaryWidth: null,
    secondaryMaxWidth: null,
    secondaryOnRight: false
  },
  sidePanel: {
    open: false,
    content: null,
    width: null
  },
  modal: {
    open: false,
    content: null
  },
  other: {
    loading: null,
    error: null,
    help: null,
    unsaved: false,
    smallPageSize: 1400,
    isolationMode: false,
    hideFooter: false
  },
  classNames: {
    page: "",
    banner: ""
  },
  skipSavePath: false
};

export const propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.node,
    sticky: PropTypes.bool,
    actions: PropTypes.node,
    content: PropTypes.node,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        linkTo: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            pathname: PropTypes.string,
            state: PropTypes.any
          })
        ]),
        label: PropTypes.string
      })
    ),
    alwaysShowBreadcrumbs: PropTypes.bool,
    tagline: PropTypes.string
  }),
  body: PropTypes.shape({
    header: PropTypes.node,
    primary: PropTypes.node,
    secondary: PropTypes.node,
    secondaryWidth: PropTypes.string,
    secondaryMaxWidth: PropTypes.string,
    secondaryMinWith: PropTypes.string,
    secondaryOnRight: PropTypes.bool
  }),
  sidePanel: PropTypes.shape({
    open: PropTypes.bool,
    content: PropTypes.node,
    width: PropTypes.string
  }),
  modal: PropTypes.shape({
    open: PropTypes.bool,
    content: PropTypes.node
  }),
  other: PropTypes.shape({
    loading: PropTypes.string || PropTypes.bool,
    error: PropTypes.string,
    help: PropTypes.string,
    pageReplacementMessage: PropTypes.string,
    unsaved: PropTypes.bool,
    smallPageSize: PropTypes.number,
    isolationMode: PropTypes.bool,
    hideFooter: PropTypes.bool
  }),
  classNames: PropTypes.shape({
    page: PropTypes.string,
    banner: PropTypes.string
  }),
  skipSavePath: PropTypes.bool,
  onPageScroll: PropTypes.func
};

export function getBodySecondaryStyle(
  windowSizeWidth,
  smallPageSize,
  secondaryWidth,
  secondaryMaxWidth,
  secondaryMinWidth,
  pageHeaderHeight,
  navigationBarHeight,
  bodyPadding
) {
  if (windowSizeWidth <= smallPageSize) {
    return {};
  }

  const height = `calc(100vh - ${top} - ${pageHeaderHeight} - ${navigationBarHeight} - ${bodyPadding})`;
  const width = secondaryWidth ? { width: secondaryWidth } : {};
  const maxWidth = secondaryMaxWidth ? { maxWidth: secondaryMaxWidth } : {};
  const minWidth = secondaryMinWidth ? { minWidth: secondaryMinWidth } : {};
  return {
    top: "0px",
    height,
    ...width,
    ...maxWidth,
    ...minWidth
  };
}

export const getCurrentContentWidth = (sideNavigationEnabled, sideNavWidth) =>
  sideNavigationEnabled ? `calc(100vw - ${sideNavWidth})` : "100%";
