import React from "react";

import { Box } from "@fermions";

import "./Placeholder.scss";

export enum PlaceholderContext {
  LIGHT = "light",
  DARK = "dark",
  SURFACE = "surface"
}

interface PlaceholderProps {
  content: string | Element;
  // Which kind of background is it using
  context?: PlaceholderContext;
}

export const Placeholder = ({
  content,
  context = PlaceholderContext.LIGHT
}: PlaceholderProps) => {
  return (
    <Box
      className={["placeholder", `placeholder--context-${context}`]}
      alignment="center"
      width="100"
      height="100"
    >
      {content ?? ""}
    </Box>
  );
};
