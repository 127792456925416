import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDocumentDownloads, useFinalPackageProcess } from "@shared/hooks";
import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useDeleteFinalPackageProcess } from "@shared/hooks/useDeleteFinalPackageProcess";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { Button, ButtonVariant } from "@atoms/Button";

import PopupOverlay from "@components/atoms/PopupOverlay/PopupOverlay";
import DangerModal from "@components/molecules/DangerModal/DangerModal";
import Loading from "@components/molecules/Loading/Loading";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate";

import AddFinalPackageProcessForm from "../AddFinalPackageProcessForm/AddFinalPackageProcessForm";
import FinalisationProcessDataTable from "../FinalisationProcessDataTable/FinalisationProcessDataTable";
import UpdateFinalPackageProcessForm from "../UpdateFinalPackageProcessForm/UpdateFinalPackageProcessForm";
import "./FinalisationProcessPanel.scss";

const FinalisationProcessPanel = props => {
  const { project, isProjectMember } = props;
  const { user } = useAuthUser();
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");
  const { processItems, fetchProcessEntries, isLoading, error } =
    useFinalPackageProcess(project.id);
  const { deleteProcessEntry, isDeleting, didDeleteEntry } =
    useDeleteFinalPackageProcess();
  const [itemToDelete, setItemToDelete] = useState(false);
  const [finalisationProcessConfig, setFinalisationProcessConfig] = useState({
    name: "Finalisation Process",
    fieldNames: [
      { key: "name", label: "Entity", type: "text", required: true },
      { key: "signOffDate", label: "Public Officer Sign Off", type: "date" },
      {
        key: "signOffDocument",
        label: "",
        formLabel: "Signed Final FBT Package",
        type: "document"
      },
      { key: "lodgementDate", label: "Lodged with ATO", type: "date" },
      { key: "lodgementReceipt", label: "Lodgement Receipt", type: "file" }
    ]
  });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const { fetchProjectDocument, downloadedDocuments } = useDocumentDownloads();
  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [requestedDownloadFile, setRequestedDownloadFile] = useState(null);
  const downloadRef = useRef(null);

  useEffect(() => {
    const config = project.content?.finalPackage?.finalisationProcess;
    if (!config) {
      return;
    }

    const fieldFormData = {
      name: { type: "text", required: true },
      signOffDate: { type: "date" },
      signOffDocument: { type: "document" },
      lodgementDate: { type: "date" },
      lodgementReceipt: { type: "file" }
    };
    const fields = config.fieldNames.map(f => ({
      ...f,
      ...fieldFormData[f.key]
    }));
    setFinalisationProcessConfig({
      name: config.name,
      fieldNames: fields
    });
  }, [project]);

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setShowProjectAccessModal(true);
      });
    },
    [isProjectMember, user]
  );

  const openCreateEntryForm = useCallback(() => {
    checkMembership("create an entry")(() => {
      setShowCreateForm(true);
    });
  }, [checkMembership]);

  const onDownloadFile = useCallback(
    document => {
      fetchProjectDocument(document);
      setRequestedDownloadFile(document);
      setDownloadClicked(true);
    },
    [fetchProjectDocument]
  );

  const onEditItem = useCallback(
    item => {
      checkMembership("edit this entry")(() => {
        setUpdateItem(item);
      });
    },
    [checkMembership]
  );

  const handleDeleteConfirmation = useCallback(() => {
    if (!isDeleting && itemToDelete) {
      deleteProcessEntry(itemToDelete);
    }
  }, [isDeleting, itemToDelete, deleteProcessEntry]);

  const onDeleteItem = useCallback(
    item => {
      checkMembership("delete this entry")(() => {
        if (!isDeleting) {
          setItemToDelete(item);
        }
      });
    },
    [checkMembership, isDeleting]
  );
  const onCreateEntryCancelled = useCallback(() => {
    setShowCreateForm(false);
  }, []);

  const onCreateEntrySubmitted = useCallback(() => {
    setShowCreateForm(false);
    fetchProcessEntries();
  }, [fetchProcessEntries]);

  const onUpdateEntryCancelled = useCallback(() => {
    setUpdateItem(null);
  }, []);

  const onUpdateEntrySubmitted = useCallback(() => {
    setUpdateItem(null);
    fetchProcessEntries();
  }, [fetchProcessEntries]);

  useEffect(() => {
    if (downloadedDocuments?.length === 0 || !requestedDownloadFile) {
      return;
    }

    const docFile = downloadedDocuments.find(
      doc =>
        doc.id === requestedDownloadFile.id &&
        doc.documentRevisionId === requestedDownloadFile.documentRevisionId
    );
    if (docFile) {
      setDownloadFile({
        documentId: requestedDownloadFile.id,
        downloadName: docFile.downloadName,
        file: docFile.objectURL
      });
    }
  }, [downloadedDocuments, requestedDownloadFile]);

  useEffect(() => {
    if (
      downloadFile?.documentId === requestedDownloadFile?.id &&
      downloadClicked
    ) {
      downloadRef.current.click();
      setDownloadClicked(false);
    }
  }, [downloadFile, requestedDownloadFile, downloadClicked]);

  useEffect(() => {
    if (didDeleteEntry) {
      fetchProcessEntries();
      setItemToDelete(null);
    }
  }, [didDeleteEntry, fetchProcessEntries]);

  const cancelDelete = useCallback(() => {
    setItemToDelete(null);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowProjectAccessModal(false);
    setMembershipErrorMessage("");
  }, []);

  return (
    <>
      <BoxTemplate
        boxClassName="finalisation-process-panel"
        title={finalisationProcessConfig?.name}
        action={
          user.isHostUser && (
            <Button
              variant={ButtonVariant.SECONDARY}
              iconName="add_circle"
              label="Create Entry"
              onClick={openCreateEntryForm}
            />
          )
        }
      >
        {isLoading ? (
          <Loading message={"Loading Items ..."} />
        ) : error ? (
          <ErrorBox message={error.key ?? error.message} />
        ) : (
          <>
            <FinalisationProcessDataTable
              items={processItems}
              config={finalisationProcessConfig}
              isHostUser={user.isHostUser}
              onDownloadFile={onDownloadFile}
              onEditItem={onEditItem}
              onDeleteItem={onDeleteItem}
            ></FinalisationProcessDataTable>

            {downloadFile && (
              <div>
                <a
                  className="hidden"
                  ref={downloadRef}
                  href={downloadFile.file}
                  download={downloadFile.downloadName}
                ></a>
              </div>
            )}
          </>
        )}
      </BoxTemplate>

      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={membershipErrorMessage}
        handleClose={handleCloseModal}
        handleOutsideClick={handleCloseModal}
      />

      <PopupOverlay
        isVisible={itemToDelete}
        isModal={true}
        onClose={cancelDelete}
        showClose={false}
        width={"60rem"}
      >
        <DangerModal
          title="Delete Entry"
          content="Are you sure you want to delete the entry?"
          handleConfirmation={handleDeleteConfirmation}
          onCancel={cancelDelete}
        ></DangerModal>
      </PopupOverlay>

      {showCreateForm && (
        <AddFinalPackageProcessForm
          project={project}
          config={finalisationProcessConfig}
          onCancel={onCreateEntryCancelled}
          onSubmitted={onCreateEntrySubmitted}
        />
      )}
      {updateItem && (
        <UpdateFinalPackageProcessForm
          entry={updateItem}
          project={project}
          config={finalisationProcessConfig}
          onCancel={onUpdateEntryCancelled}
          onSubmitted={onUpdateEntrySubmitted}
        />
      )}
    </>
  );
};

export default FinalisationProcessPanel;
