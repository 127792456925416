import React, { useMemo } from "react";

import { Inline } from "@fermions";

import { Icon, IconColor, IconFillStyle, IconSize } from "@atoms/Icon";

import "./MessageBox.scss";

export enum MessageBoxType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}
interface MessageBoxProps {
  message: string;
  type?: MessageBoxType;
}

export const MessageBox = ({
  message,
  type = MessageBoxType.INFO
}: MessageBoxProps) => {
  const iconName = useMemo(() => {
    switch (type) {
      case MessageBoxType.SUCCESS:
        return "check_circle";
      case MessageBoxType.WARNING:
        return "error";
      case MessageBoxType.ERROR:
        return "cancel";
      case MessageBoxType.INFO:
      default:
        return "info";
    }
  }, [type]);

  return (
    <Inline
      alignment="left"
      gap="050"
      className={["message-box", `message-box--${type}`]}
    >
      <Icon
        name={iconName}
        fillStyle={IconFillStyle.FILLED}
        size={IconSize.XS}
        color={IconColor.INHERIT}
      />
      {message}
    </Inline>
  );
};
