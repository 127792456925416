import React from "react";

import FormTemplate from "@components/atoms/Form/FormTemplate";

function FormWithoutProvider(props) {
  const { actionButtons, methods } = props;
  return (
    <FormTemplate
      autoComplete={props.autoComplete}
      onSubmit={methods?.handleSubmit(props.handleSubmit)}
      actionButtons={actionButtons?.(methods.formState)}
      customFormClassName={props.customFormClassName}
    >
      {props.children}
    </FormTemplate>
  );
}

FormWithoutProvider.propTypes = {};

export default FormWithoutProvider;
