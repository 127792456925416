import { manageInteractiveReportConstants } from "../constants";
import { manageDocumentUploadsService } from "../services/";

export const manageInteractiveReportAction = {
  reset,
  uploadInteractiveReport
};

function uploadInteractiveReport({ file, projectId }) {
  return dispatch => {
    dispatch(request());
    manageDocumentUploadsService
      .uploadInteractiveReport({
        dispatch,
        file,
        projectId
      })
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_REQUEST
    };
  }

  function success(result) {
    return {
      type: manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_SUCCESS,
      result
    };
  }

  function failure(error) {
    return {
      type: manageInteractiveReportConstants.UPLOAD_INTERACTIVE_REPORT_FAILURE,
      error
    };
  }
}

function reset() {
  return dispatch => {
    dispatch({
      type: manageInteractiveReportConstants.RESET_INTERACTIVE_REPORT
    });
  };
}
