import { ResourceName } from ".";

export const enum AccessLevel {
  READ = "Read",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete"
}

export type Permission = {
  id: number;
  accessLevel?: AccessLevel;
  hostOnly?: boolean;
  alwaysAllowed?: boolean;
};

export type myPermissionGet = {
  [key in ResourceName]: AccessLevel[] | undefined;
};
