/**
 * ValidationConfig's properties differ depending on the validator
 * @typedef {Object} ValidationConfig
 * @property {string} check name of the validator checker to use
 */

/**
 * @typedef {Object} sumIsZeroProps
 * @property {Array<string>} [columns] The columns to check against. Each column is checked individually for whether its sum = 0
 * @property {number} [precision] Number of decimal places to check when determining how close to 0 we are
 * @typedef {sumIsZeroProps & ValidationConfig} sumIsZeroConfig
 */

/**
 * @param {Number} value value being checked. Standard number rounding apply when compared against the target
 * @param {Number} target target compared against
 * @param {Number} [precision] Number of decimal places to check when determining how close 'value' is to 'target' (defaults to 0)
 * @returns
 */
const isWithinPrecision = (value, target, precision = 0) => {
  const round = (value, exponent = 0) => {
    const multiplier = Math.pow(10, exponent);
    return Math.round(value * multiplier) / multiplier;
  };
  // need to handle precision=0 as n^0 = 1 but in reality we want '0' decimal places
  const tolerance =
    precision === 0 ? 0 : round(Math.pow(10, -precision), precision);
  const absDelta = round(Math.abs(value - target), precision);
  return absDelta <= tolerance;
};

/**
 * @typedef {Object} ValidationError
 * @property {string} i18n message key
 * @property {Object} context additional context information for rendering the message
 */

/**
 * @typedef {Function} ValidatorFunction
 * @property {*} webSheetData message key
 * @returns {Object} result
 * @property {Object} result.isValid TRUE if the validation passed. FALSE otherwise.
 * @property {Object} result.errors Information on failed validation checks if validation failed
 */

/**
 * @param {sumIsZeroConfig} validationConfig
 * @returns {ValidatorFunction} A validator function that operates on the first sheet of the given websheet to valid each column specified whether their sums is zero within the desired precision
 */
const sumIsZero =
  ({ columns: columnsToValidate, precision = 0 }) =>
  webSheetData => {
    const firstSheet = webSheetData?.[0];
    const data = firstSheet?.data;
    if (!data) {
      return {
        isValid: false
      };
    }
    const headers = data[0];
    if (!headers || !columnsToValidate) {
      return {
        isValid: false
      };
    }

    const validatedColumns = columnsToValidate.map(columnName => {
      const columnIndex = headers.findIndex(col => col === columnName);
      if (columnIndex === -1) {
        return {
          isValid: false,
          column: columnName
        };
      }
      const res = data.slice(1).reduce((acc, cellInRow) => {
        return Number(cellInRow[columnIndex]) + acc;
      }, 0);

      const isValid = isWithinPrecision(res, 0, precision);

      if (isValid) {
        return {
          isValid
        };
      }

      return {
        isValid,
        column: columnName
      };
    });

    const isValid = validatedColumns.every(({ isValid }) => isValid);

    const getErrors = () => {
      const headerNamesCommaJoined = validatedColumns
        .filter(({ isValid }) => !isValid)
        .map(c => c.column)
        .join(", ");
      return [
        {
          i18n: "common:ui.websheet.actions.cleaningWizard.validationError.sumIsZero.description",
          context: {
            headerNamesCommaJoined
          }
        }
      ];
    };

    const errors = !isValid ? getErrors() : undefined;
    return {
      isValid,
      errors
    };
  };

export default {
  validators: {
    sumIsZero
  },
  forTest: {
    isWithinPrecision
  }
};
