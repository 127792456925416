import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  SmartFormEntityType,
  SmartFormQuestionType
} from "@app/helpers/smartForm/smartFormTypes";

import { Inline } from "@fermions";

import { Icon, IconSize } from "@atoms/Icon";

import EntitiesListIconCell from "../EntitiesListIconCell";

interface SmartFormQuestionProps {
  question: SmartFormQuestionType;
  relevantEntitiesById: { [key: string]: SmartFormEntityType };
  relevantEntitiesTotal: number;
  alwaysShowEntitiesIcon: boolean;
}

export const SmartFormQuestionText = ({
  question,
  relevantEntitiesById,
  relevantEntitiesTotal,
  alwaysShowEntitiesIcon
}: SmartFormQuestionProps) => {
  const { t } = useTranslation();

  const getReleventEntities = useCallback(() => {
    if (
      !question.relevantEntities ||
      question.relevantEntities?.length === relevantEntitiesTotal
    ) {
      return [`${t("requests:requests.ui.smartForm.all.label")}`];
    }
    return Object.entries(relevantEntitiesById)
      .filter(([id]) => question?.relevantEntities?.includes(id))
      .map(([, name]) => name);
  }, [
    question.relevantEntities,
    relevantEntitiesById,
    relevantEntitiesTotal,
    t
  ]);

  const entityIcon = useMemo(() => {
    if (
      (!question?.parentQuestionId && !alwaysShowEntitiesIcon) ||
      relevantEntitiesTotal <= 1
    ) {
      return <></>;
    }
    return (
      <EntitiesListIconCell entities={getReleventEntities()} color="accent" />
    );
  }, [
    alwaysShowEntitiesIcon,
    question?.parentQuestionId,
    getReleventEntities,
    relevantEntitiesTotal
  ]);

  const dependentQuestionIndicator = useMemo(() => {
    if (!question?.parentQuestionId) {
      return <></>;
    }
    return (
      <span className="smart-form-table__dependent-question__list-icon">∟</span>
    );
  }, [question?.parentQuestionId]);

  const mandatoryClassName = question?.isMandatory
    ? "smart-form-table__questionText__mandatory"
    : "";

  const questionContent = useMemo(() => {
    if (!question?.additionalInfo) {
      return (
        <div
          id={`smart-form-question-${question?.questionId}`}
          className="smart-form-table__questionText"
        >
          <span className={mandatoryClassName}>{question?.text}</span>
        </div>
      );
    }
    return (
      <details className="smart-form-table__disclosure">
        <summary
          id={`smart-form-question-${question?.questionId}`}
          className="smart-form-table__questionText"
        >
          <span className={mandatoryClassName}>{question?.text}</span>
          <Icon
            className="smart-form-table__info-icon smart-form-table__disclosure__toggle"
            name="info"
            size={IconSize.S}
          />
        </summary>
        <div>
          {question?.additionalInfo.split("\n").map((string, i) => {
            return <p key={i}>{string}</p>;
          })}
        </div>
      </details>
    );
  }, [
    mandatoryClassName,
    question?.additionalInfo,
    question?.questionId,
    question?.text
  ]);

  return (
    <Inline gap="025" alignment="center">
      {dependentQuestionIndicator}
      {entityIcon}
      {questionContent}
    </Inline>
  );
};
