import { LegacyRef, useMemo } from "react";

import { useWindowSize } from "./useWindowSize.tsx";

interface FixedInWindowProps {
  style: React.CSSProperties | undefined;
  elementRef?: LegacyRef<HTMLDivElement>;
  windowPadding?: number;
  forceNoCalculate?: boolean;
}

export function useFixedWithinWindow({
  style = {},
  elementRef,
  windowPadding = 4,
  forceNoCalculate = false
}: FixedInWindowProps) {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const styleWithinWindow = useMemo(() => {
    if (
      forceNoCalculate ||
      !windowWidth ||
      !windowHeight ||
      !elementRef?.current ||
      style.position !== "fixed" ||
      process.env.NODE_ENV === "test"
    ) {
      return style;
    }

    const elementPosition = elementRef?.current?.getBoundingClientRect?.();
    const rawNumberStyle = getStyleToRawNumbers(style);

    return getNewStyle(
      rawNumberStyle,
      elementPosition,
      windowWidth,
      windowHeight,
      windowPadding
    );
  }, [
    forceNoCalculate,
    elementRef,
    style,
    windowWidth,
    windowHeight,
    windowPadding
  ]);

  return { styleWithinWindow };
}

function pixels(value: number) {
  return `${value}px`;
}

function getStyleToRawNumbers(style: React.CSSProperties) {
  const newStyle: { [key: string]: unknown } = {};
  for (const [key, value] of Object.entries(style)) {
    const numValue = parseInt(value, 10);
    newStyle[key] = isNaN(numValue) ? value : numValue;
  }
  return newStyle;
}

function getValueWithin(
  value: number,
  smallestValue: number,
  largestValue: number
) {
  return Math.max(smallestValue, Math.min(value, largestValue));
}

function getNewStyle(
  rawNumberStyle: React.CSSProperties,
  elementPosition: unknown,
  windowWidth: number,
  windowHeight: number,
  windowPadding: number
) {
  const newStyle: { [key: string]: string | number } = {};

  Object.entries(rawNumberStyle).forEach(([key, value]) => {
    if (!["top", "bottom", "left", "right"].includes(key)) {
      newStyle[key] = typeof value === "number" ? pixels(value) : value;
    }
    switch (key) {
      case "top":
        newStyle[key] = getValueWithin(
          value,
          windowPadding,
          windowHeight - elementPosition.height - windowPadding
        );
        break;
      case "bottom":
        newStyle[key] = getValueWithin(
          value,
          windowPadding + elementPosition.height,
          windowHeight - windowPadding
        );
        break;
      case "left":
        newStyle[key] = getValueWithin(
          value,
          windowPadding,
          windowWidth - elementPosition.width - windowPadding
        );
        break;
      case "right":
        newStyle[key] = getValueWithin(
          value,
          windowPadding + elementPosition.width,
          windowWidth - windowPadding
        );
        break;
      default:
        newStyle[key] = value;
        break;
    }

    if (typeof newStyle[key] === "number") {
      newStyle[key] = pixels(newStyle[key]);
    }
  });

  return newStyle;
}
