import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;
export const manageProfileService = {
  getUserDetails,
  updateUser
};

function getUserDetails({ id: userId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/users/${userId}/profile`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateUser(user) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(user)
  };
  return fetch(`${baseURL}/api/users/${user.id}/profile`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
