import { manageProjectConstants } from "../constants";

const initialState = {
  loading: false,
  documentsLoading: false,
  documentsLoaded: false,
  todosLoading: false,
  milestonesLoading: false,
  documentLoading: false,
  projectLoading: false,
  messages: [],
  todos: [],
  milestones: [],
  documents: [],
  foldersAndDocuments: [],
  updatedTodo: null,
  error: "",
  document: null,
  addedMember: false,
  removedMember: false,
  currentProject: null
};

export function manageProject(state = initialState, action = {}) {
  switch (action.type) {
    case manageProjectConstants.ADD_PROJECT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.ADD_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        addedMember: true,
        error: ""
      };
    case manageProjectConstants.ADD_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        addedMember: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.REORDER_PROJECT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.REORDER_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case manageProjectConstants.REORDER_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.REMOVE_PROJECT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        removedMember: true,
        error: ""
      };
    case manageProjectConstants.REMOVE_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        removedMember: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.REMOVE_PROJECT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        documentLoading: true
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentLoading: false,
        document: action.document,
        error: ""
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        documentLoading: false,
        document: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        foldersAndDocuments: action.projectFoldersAndDocuments,
        error: ""
      };
    case manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        foldersAndDocuments: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENTS_REQUEST:
      return {
        ...state,
        documentsLoading: true,
        documentsLoaded: false
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        documentsLoaded: true,
        documents: action.documents,
        error: ""
      };
    case manageProjectConstants.GET_PROJECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        documentsLoaded: false,
        documents: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.GET_PROJECT_TODOS_REQUEST:
      return {
        ...state,
        todosLoading: true
      };
    case manageProjectConstants.GET_PROJECT_TODOS_SUCCESS:
      return {
        ...state,
        todosLoading: false,
        todos: action.todos,
        error: ""
      };
    case manageProjectConstants.GET_PROJECT_TODOS_FAILURE:
      return {
        ...state,
        todosLoading: false,
        todos: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.GET_PROJECT_MILESTONES_REQUEST:
      return {
        ...state,
        milestonesLoading: true
      };
    case manageProjectConstants.GET_PROJECT_MILESTONES_SUCCESS:
      return {
        ...state,
        milestonesLoading: false,
        milestones: action.milestones,
        error: ""
      };
    case manageProjectConstants.GET_PROJECT_MILESTONES_FAILURE:
      return {
        ...state,
        milestonesLoading: false,
        milestones: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS:
      return {
        ...state,
        loading: false,
        milestones: action.milestones,
        error: ""
      };
    case manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_REQUEST:
    case manageProjectConstants.UPDATE_PROJECT_TODO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_SUCCESS:
    case manageProjectConstants.UPDATE_PROJECT_TODO_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedTodo: action.todo,
        error: ""
      };
    case manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_FAILURE:
    case manageProjectConstants.UPDATE_PROJECT_TODO_FAILURE:
      return {
        ...state,
        loading: false,
        updatedTodo: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectConstants.RESET_UPDATE_PROJECT_TODO_FLAG:
      return {
        ...state,
        updatedTodo: null
      };
    case manageProjectConstants.RESET_DOCUMENT:
      return {
        ...state,
        document: null
      };
    case manageProjectConstants.RESET_REMOVED_MEMBER:
      return {
        ...state,
        removedMember: false
      };
    case manageProjectConstants.RESET_ADDED_MEMBER:
      return {
        ...state,
        addedMember: false
      };
    case manageProjectConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageProjectConstants.RESET:
      return {
        ...initialState,
        currentProject: state.currentProject
      };
    case manageProjectConstants.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.currentProject
      };
    case manageProjectConstants.RESET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: null
      };
    case manageProjectConstants.GET_PROJECT_REQUEST:
      return {
        ...state,
        projectLoading: true
      };
    case manageProjectConstants.GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        currentProject: action.updatedProject
      };
    case manageProjectConstants.GET_PROJECT_FAILURE:
      return {
        ...state,
        projectLoading: false,
        error: action.error.key ?? action.error.message
      };

    default:
      return state;
  }
}
