import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

export function useFeatures() {
  const { data: features, isLoading, isError } = useGetFeaturesQuery();

  const isEnabled = useCallback(name => !!features?.[name], [features]);
  const navigate = useNavigate();
  const {
    projectManagerField,
    engagementManagerField,
    projectRisks,
    interactiveReport,
    downloadWebsheet,
    aiCLeaningAssistant,
    externalUsers
  } = systemConstants.features;

  useEffect(() => {
    if (isError) {
      navigate(routeConstants.notFound);
    }
  }, [isError, navigate]);

  return {
    isLoading,
    isEnabled,
    isProjectManagerFieldEnabled: isEnabled(projectManagerField),
    isEngagementManagerFieldEnabled: isEnabled(engagementManagerField),
    isRiskFlagEnabled: isEnabled(projectRisks),
    isInteractiveReportEnabled: isEnabled(interactiveReport),
    isDownloadWebsheetEnabled: isEnabled(downloadWebsheet),
    isAiCleaningAssistantEnabled: isEnabled(aiCLeaningAssistant),
    isExternalUsersEnabled: isEnabled(externalUsers)
  };
}
