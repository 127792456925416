import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants.js";

import { createPagination } from "@shared/helpers/utilities.js";
import { useAuthUser } from "@shared/hooks";
import { useLazyGetAllActionItemTypesQuery } from "@shared/services";

import { AccessLevel, ActionItemType, ResourceName } from "@app/types";

import { Stack } from "@fermions";

import { Button } from "@atoms/Button";

import DropdownPagination from "@components/molecules/DropdownPagination/DropdownPagination.tsx";
import RequestTypeTable from "@components/organisms/RequestTypeDataTable/RequestTypeDataTable.tsx";
import PageTemplate from "@components/templates/PageTemplate";

import { sortByOrderAndType } from "./manageRequestTypesHelper.ts";

export type SortingFilter = {
  colName: string;
  sortOrder: string;
};

export const ACTIONS = {
  create: {
    key: "CREATE",
    label: "common:ui.manageRequestType.addRequestType",
    handler: (navigate: (url: string) => void) =>
      navigate(routeConstants.admin.addRequestType)
  },
  view: {
    key: "VIEW",
    label: "common:ui.manageRequestType.menu.view",
    handler: (navigate: (url: string) => void, requestType: { id: number }) =>
      navigate(`/admin/request-types/${requestType.id}`)
  },
  update: {
    key: "UPDATE",
    label: "common:ui.manageRequestType.menu.update",
    handler: (navigate: (url: string) => void, requestType: { id: number }) =>
      navigate(`/admin/request-types/${requestType.id}/edit`)
  }
};

const ITEMS_PER_PAGE = 20;

const ManageRequestTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const [pagination, setPagination] = useState({
    countPerPage: ITEMS_PER_PAGE,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const [requestTypes, setRequestTypes] = useState([]);
  const allRecords = useRef<ActionItemType[]>([]);
  const [sortFilter, setSortFilter] = useState<SortingFilter>({
    colName: "default",
    sortOrder: "asc"
  });
  const [fetchRequestTypes, { error }] = useLazyGetAllActionItemTypesQuery();

  const canUpdate = useMemo(() => {
    return user.checkAccess(ResourceName.REQUEST_TYPES, AccessLevel.UPDATE);
  }, [user]);

  useEffect(() => {
    fetchRequestTypes()
      .unwrap()
      .then((result: Array<ActionItemType>) => {
        setRequestTypes(prev => [...prev, ...requestTypes]);
        allRecords.current = toSort(result);
        createPagination({
          list: allRecords.current,
          setPagination,
          countPerPage: ITEMS_PER_PAGE
        });
      });
    // eslint-disable-next-line
  }, [fetchRequestTypes]);

  const defaultSort = useCallback(
    (
      { sortOrder }: SortingFilter,
      records: ActionItemType[]
    ): ActionItemType[] => {
      return records?.toSorted((a: ActionItemType, b: ActionItemType) => {
        if (a["engagementType"] === b["engagementType"]) {
          if (String(a["year"]) === String(b["year"])) {
            return sortByOrderAndType({ colName: "name", sortOrder }, a, b, t);
          } else {
            return sortByOrderAndType({ colName: "year", sortOrder }, a, b, t);
          }
        } else {
          return sortByOrderAndType(
            { colName: "engagementType", sortOrder },
            a,
            b,
            t
          );
        }
      });
    },
    [sortFilter]
  );

  const toSort = useCallback(
    (records: ActionItemType[]): ActionItemType[] => {
      if (sortFilter.colName === "default") {
        return defaultSort(sortFilter, records);
      } else {
        return records?.toSorted((a: ActionItemType, b: ActionItemType) => {
          return sortByOrderAndType(sortFilter, a, b, t);
        });
      }
    },
    [sortFilter, defaultSort]
  );
  const countRequestTypes = allRecords.current.length ?? 0;
  const yearEnabled = useMemo(() => {
    if (countRequestTypes) {
      return allRecords.current.some(
        (requestType: ActionItemType) => requestType.year
      );
    }
    return false;
  }, [countRequestTypes]);

  useEffect(() => {
    let sortedData;
    const records = [...allRecords.current];
    if (sortFilter.colName) {
      sortedData = toSort(records);
    }
    if (allRecords.current) {
      createPagination({
        list: sortFilter.colName ? sortedData : records,
        setPagination,
        sortedList: true,
        countPerPage: ITEMS_PER_PAGE
      });
    }
  }, [sortFilter, toSort]);

  useEffect(() => {
    if (pagination.pages.length) {
      const slicedData = pagination.pages[pagination.currentPageIndex].data;
      setRequestTypes(slicedData);
    } else {
      setRequestTypes([]);
    }
  }, [pagination]);

  const handleRequestTypeAction = useCallback(
    (action, requestType = undefined) => {
      const actionObj = Object.values(ACTIONS).find(a => a.key === action);
      actionObj?.handler(navigate, requestType);
    },
    [canUpdate, navigate]
  );

  const headerActions = () => {
    if (!user.checkAccess(ResourceName.REQUEST_TYPES, AccessLevel.CREATE)) {
      return <></>;
    }
    return (
      <Button
        label={t(ACTIONS.create.label)}
        iconName="add"
        onClick={() => handleRequestTypeAction(ACTIONS.create.key)}
      />
    );
  };

  const handleColSortOrder = ({ colName, sortOrder }: SortingFilter) => {
    setSortFilter({ colName, sortOrder });
  };
  return (
    <PageTemplate
      header={{
        title: t("common:ui.manageRequestType.title"),
        actions: headerActions(),
        alwaysShowBreadcrumbs: true
      }}
      body={{
        primary: (
          <Stack className="client-manager" width="100" gap="300">
            {requestTypes?.length ? (
              <>
                <RequestTypeTable
                  requestTypes={requestTypes}
                  onRequestTypeAction={handleRequestTypeAction}
                  canUpdate={user.checkAccess(
                    ResourceName.REQUEST_TYPES,
                    AccessLevel.UPDATE
                  )}
                  onHandleSort={handleColSortOrder}
                  sortFilter={sortFilter}
                  yearEnabled={yearEnabled}
                />
                <DropdownPagination
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </>
            ) : (
              ""
            )}
          </Stack>
        )
      }}
      other={{
        error: error
      }}
    />
  );
};

export default ManageRequestTypes;
