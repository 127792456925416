import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { manageClientsActions } from "@shared/actions";

export function useClients({ enableAll = false }) {
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const { t } = useTranslation();
  const manageClients = useSelector(state => state.manageClients);

  useEffect(() => {
    const clientsList = manageClients.clients.map(client => client);
    if (enableAll) {
      clientsList.unshift({
        id: "ALL CLIENTS",
        name: t("common:ui.header.dropdown.defaultClientDropdownName")
      });
    }
    setClients(clientsList);
  }, [manageClients, enableAll, t]);

  const fetchClients = useCallback(
    user => {
      if (user.isHostUser) {
        dispatch(manageClientsActions.getClients({ hostId: user.hostId }));
      }
    },
    [dispatch]
  );

  return { clients, fetchClients };
}
