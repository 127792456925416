import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  useClientDocumentDownloads,
  useGetClientByIdQuery,
  useSearchClientDocuments
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";
import {
  useGetArchivedClientDocumentsQuery,
  useGetClientDocumentsQuery
} from "@shared/services/documentService";

import { routeConstants } from "@app/constants/routeConstants";

import { Box, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import InlineAlert from "@components/atoms/InlineAlert";
import Loading from "@components/molecules/Loading";
import SearchBar from "@components/molecules/SearchBar";
import DocumentsTable from "@components/organisms/DocumentsTable";
import ClientDocumentsTableActions from "@components/organisms/DocumentsTable/ClientDocumentsTableActions";
import UploadFileBox from "@components/organisms/UploadFileBox";
import PageTemplate from "@components/templates/PageTemplate/";

const PermanentFiles = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { clientId: stateClientId } = location.state ?? {};
  const { clientId: paramsClientId } = useParams();
  const clientId = paramsClientId ?? stateClientId;
  const [activeDocumentAction, setActiveDocumentAction] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { searchDocuments, searchResults } = useSearchClientDocuments(clientId);
  const { fetchCurrentDocument } = useClientDocumentDownloads();
  const {
    data: archivedDocuments,
    isLoading: loadingArchivedDocuments,
    error: errorLoadingArchivedDocuments
  } = useGetArchivedClientDocumentsQuery({ clientId }, { skip: !clientId });
  const {
    data: documents,
    isLoading: loadingDocuments,
    error: errorLoadingDocuments,
    refetch: fetchDocuments
  } = useGetClientDocumentsQuery({ clientId }, { skip: !clientId });

  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();

  const { data: currentClient, error: isInvalidClient } = useGetClientByIdQuery(
    clientId,
    {
      skip: !clientId
    }
  );

  const manageDocumentDownloads = useSelector(
    state => state.manageDocumentDownloads
  );

  useEffect(() => {
    if (stateClientId && !paramsClientId) {
      navigate(`/clients/${stateClientId}${routeConstants.permanentFiles}`, {
        replace: true
      });
    }
  }, [paramsClientId, stateClientId, navigate]);

  useEffect(() => {
    if (isInvalidClient) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [isInvalidClient, navigate]);

  useEffect(() => {
    if (showArchived) {
      setIsLoading(loadingArchivedDocuments);
    } else {
      setIsLoading(loadingDocuments);
    }
  }, [loadingArchivedDocuments, loadingDocuments, showArchived]);

  useEffect(() => {
    if (showArchived) {
      setError(errorLoadingArchivedDocuments);
    } else {
      setError(errorLoadingDocuments);
    }
  }, [errorLoadingArchivedDocuments, errorLoadingDocuments, showArchived]);

  useEffect(() => {
    if (searchString) {
      searchDocuments(searchString);
    }
  }, [searchString, searchDocuments]);

  const handleDownloadDocument = useCallback(
    ({ document, cb }) => {
      fetchCurrentDocument(document, cb);
    },
    [fetchCurrentDocument]
  );

  const handleOpenDocumentForEdit = item => {
    sessionStorage.setItem(
      "documentEditInfo",
      JSON.stringify({
        item,
        title: item.name
      })
    );
    window.open(`${routeConstants.editDocument}`);
    sessionStorage.removeItem("documentEditInfo");
  };

  const handleDocumentAction = documentAction => {
    setActiveDocumentAction(documentAction);
  };

  const handleDocumentActionFinished = () => {
    setActiveDocumentAction(null);
  };

  const handleUploadCompleted = useCallback(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const clickArchivedFolder = useCallback(
    () => setShowArchived(prevState => !prevState),
    []
  );

  const handleChange = useCallback(value => {
    setSearchString(value);
  }, []);

  const leftSidebar = useMemo(
    () => (
      <Stack className="permanent-files__secondary" gap="050" width="100">
        {!showArchived && (
          <UploadFileBox
            title={t("common:ui.documents.fileUpload.label")}
            clientId={clientId}
            boxClassName={"upload-file-box"}
            onUploadComplete={handleUploadCompleted}
            useLegacyApiModeForOldPages={false}
            registerModal={registerModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        )}
        <Button
          iconOutlined={showArchived}
          iconName={`${showArchived ? "inventory_2" : "delete"}`}
          label={t("common:ui.documents.binText", {
            context: showArchived ? "activeDocument" : "archivedDocument"
          })}
          variant={
            showArchived ? ButtonVariant.TEXT : ButtonVariant.TEXT_PRIMARY
          }
          onClick={event => clickArchivedFolder(event)}
        />
      </Stack>
    ),
    [
      clickArchivedFolder,
      clientId,
      handleCloseModal,
      handleOpenModal,
      handleUploadCompleted,
      registerModal,
      showArchived,
      t
    ]
  );

  const getDocuments = useMemo(
    () => () => {
      if (searchString) {
        return { documents: searchResults };
      } else if (showArchived) {
        return { documents: archivedDocuments };
      } else {
        return { documents };
      }
    },
    [archivedDocuments, documents, searchResults, searchString, showArchived]
  );

  const rightBody = useMemo(() => {
    return (
      <>
        {error && (
          <InlineAlert type="error" message={t(error.key ?? error.message)} />
        )}
        {isLoading ? (
          <Loading message={"Loading Documents"} minHeight="250" />
        ) : (
          <Stack className="permanent-files" width="100" gap="200">
            <Box className="permanent-files__search-box-input" width="fill">
              <SearchBar
                value={searchString}
                onChange={handleChange}
                placeholder={t("common:ui.documents.searchLabel")}
              />
            </Box>
            <DocumentsTable
              folder={getDocuments()}
              handleOpenDocumentForEdit={handleOpenDocumentForEdit}
              handleRestoreDocument={handleDocumentAction}
              handleDownloadDocument={handleDownloadDocument}
              handleUploadDocumentRevision={handleDocumentAction}
              handleUpdateDocumentProperties={handleDocumentAction}
              handleReviewRevisionHistory={handleDocumentAction}
              handleDeleteDocument={handleDocumentAction}
              handleReviewReviewHistory={handleDocumentAction}
              downloadingErrorDocumentId={
                manageDocumentDownloads.errorDocumentId
              }
            />
          </Stack>
        )}
      </>
    );
  }, [
    error,
    getDocuments,
    handleChange,
    handleDownloadDocument,
    isLoading,
    manageDocumentDownloads.errorDocumentId,
    searchString,
    t
  ]);

  return (
    <>
      <PageTemplate
        header={{
          title: t("common:ui.documents.navigation.title", {
            context: "client"
          })
        }}
        body={{
          primary: rightBody,
          secondary: leftSidebar,
          secondaryWidth: "40vw"
        }}
        other={{ client: currentClient, smallPageSize: 1600 }}
        modal={{
          open: modalOpen,
          content: getModalContent()
        }}
      />
      {activeDocumentAction?.action && (
        <ClientDocumentsTableActions
          clientId={clientId}
          action={activeDocumentAction}
          onFinished={handleDocumentActionFinished}
        />
      )}
    </>
  );
};

export default PermanentFiles;
