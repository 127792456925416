import { manageClientEngagementsConstants } from "../constants";

const initialState = {
  loading: false,
  getMembersLoading: false,
  engagements: [],
  clients: [],
  membersToAdd: [],
  members: [],
  addedMember: false,
  isRemovingMember: false,
  removedMember: false,
  removeMemberError: "",
  error: "",
  reload: false
};

export function manageClientEngagements(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientEngagementsConstants.GET_CLIENT_ENGAGEMENTS_REQUEST:
    case manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_REQUEST:
    case manageClientEngagementsConstants.ADD_MEMBER_REQUEST:
    case manageClientEngagementsConstants.GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementsConstants.REMOVE_MEMBER_REQUEST:
      return { ...state, isRemovingMember: true };
    case manageClientEngagementsConstants.GET_CLIENT_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        engagements: action.engagements,
        error: ""
      };
    case manageClientEngagementsConstants.GET_CLIENT_ENGAGEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        engagements: [],
        error: action.error.key ?? action.error.message
      };

    case manageClientEngagementsConstants.REMOVE_MEMBER_SUCCESS:
      return {
        ...state,
        isRemovingMember: false,
        removedMember: true,
        removeMemberError: ""
      };
    case manageClientEngagementsConstants.REMOVE_MEMBER_FAILURE:
      return {
        ...state,
        isRemovingMember: false,
        removedMember: false,
        removeMemberError: action.error?.message
      };

    case manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        membersToAdd: action.members,
        error: ""
      };
    case manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        membersToAdd: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementsConstants.GET_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        getMembersLoading: true
      };
    case manageClientEngagementsConstants.GET_MEMBERS_SUCCESS:
      let engagementMembers = [];
      if (
        action.members &&
        action.members.hostUsers &&
        action.members.hostUsers.length
      ) {
        engagementMembers = [...engagementMembers, ...action.members.hostUsers];
      }
      if (
        action.members &&
        action.members.clientUsers &&
        action.members.clientUsers.length
      ) {
        engagementMembers = [
          ...engagementMembers,
          ...action.members.clientUsers
        ];
      }

      engagementMembers.sort((a, b) => a.firstname.localeCompare(b.firstname));
      return {
        ...state,
        loading: false,
        getMembersLoading: false,
        members: engagementMembers,
        error: ""
      };
    case manageClientEngagementsConstants.GET_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        getMembersLoading: false,
        members: [],
        error: action.error.key ?? action.error.message
      };

    case manageClientEngagementsConstants.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        addedMember: true,
        error: ""
      };
    case manageClientEngagementsConstants.ADD_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        addedMember: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementsConstants.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.clients,
        error: ""
      };
    case manageClientEngagementsConstants.GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        clients: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementsConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageClientEngagementsConstants.RESET_MEMBERS_TO_ADD:
      return {
        ...state,
        membersToAdd: []
      };
    case manageClientEngagementsConstants.RESET_ADDED_MEMBER:
      return {
        ...state,
        addedMember: false
      };
    case manageClientEngagementsConstants.RESET_REMOVED_MEMBER:
      return {
        ...state,
        removedMember: false,
        isRemovingMember: false,
        removeMemberError: ""
      };
    case manageClientEngagementsConstants.RESET_MEMBERS:
      return {
        ...state,
        members: []
      };
    case manageClientEngagementsConstants.RESET:
      return {
        ...initialState,
        reload: state.reload
      };
    default:
      return state;
  }
}
