import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { safeUtcDate } from "@shared/helpers/dateFormatUtils";
import {
  useGetFinalPackageSignedDocuments,
  useUpdateFinalPackageProcess
} from "@shared/hooks";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { CheckboxInput } from "@molecules/inputs";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";

import "./UpdateFinalPackageProcessForm.scss";

const addFilesState = systemConstants.addFiles.state;
const noFileItem = {
  name: "-- no file --",
  value: -1
};

const defaultMessageStrings = Object.freeze({
  lodgementReceipt: Object.freeze({
    requiredError: "You must enter a lodgement date."
  })
});

function UpdateFinalPackageProcessForm(props) {
  const { entry, project, config, onCancel, onSubmitted } = props;
  const [newEntry, setNewEntry] = useState();
  const [keepExistingReceipt, setKeepExistingReceipt] = useState(
    !!entry.lodgementReceipt
  );
  const [showForm, setShowForm] = useState(false);
  const { updated, isUpdating, error, setError, updateProcess } =
    useUpdateFinalPackageProcess();
  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const [documentsForDropdown, setDocumentsForDropdown] = useState([
    noFileItem
  ]);
  const {
    signedDocuments,
    fetched: signedDocumentsLoaded,
    fetchSignedDocuments
  } = useGetFinalPackageSignedDocuments();
  const [fields, setFields] = useState([]);
  const [uploadState, setUploadState] = useState(addFilesState.add);

  const messageStrings = useMemo(() => {
    if (!fields?.length) {
      return defaultMessageStrings;
    }
    const lodgementReceipt = fields.find(f => f.key === "lodgementReceipt");
    const entity = fields.find(f => f.key === "name");
    if (!lodgementReceipt || !entity) {
      return defaultMessageStrings;
    }
    return {
      lodgementReceipt: {
        requiredError: `A ${lodgementReceipt.label} has been uploaded for this ${entity.label}. You must enter a lodgement date.`
      }
    };
  }, [fields]);

  const yupSchema = yup.object({
    name: yup.string().required("Please enter a value"),
    signOffDocument: yup.object(),
    signOffDate: yup.string().nullable(true),
    lodgementDate: yup.string().when("lodgementReceipt", {
      is: lodgementReceipt => {
        return entry.lodgementReceipt || Object.values(lodgementReceipt).length;
      },
      then: () =>
        yup
          .string()
          .nullable(true)
          .required(messageStrings.lodgementReceipt.requiredError),
      otherwise: () => yup.string().nullable()
    }),
    lodgementReceipt: yup.object()
  });

  useEffect(() => {
    if (project) {
      fetchSignedDocuments(project.id);
    }
  }, [fetchSignedDocuments, project]);

  useEffect(() => {
    if (signedDocumentsLoaded) {
      setShowForm(true);
      const configFields = config.fieldNames.map(f => ({
        key: f.key,
        label: f.formLabel ?? f.label,
        type: f.type,
        required: f.required,
        defaultValue: entry[f.key]
      }));
      setFields(configFields);
    }
  }, [config, entry, signedDocumentsLoaded]);

  useEffect(() => {
    if (!isUpdating && updated) {
      setShowForm(false);
      onSubmitted();
    }
  }, [isUpdating, onSubmitted, updated]);

  useEffect(() => {
    setDocumentsForDropdown([
      noFileItem,
      ...(signedDocuments ?? []).map(d => ({
        name: d.name,
        value: d.id
      }))
    ]);
  }, [signedDocuments]);

  const handleCancel = useCallback(() => {
    setShowForm(false);
    onCancel();
  }, [onCancel]);

  const onUploadsComplete = useCallback(
    files => {
      setUploadState(addFilesState.finished);
      const file = Object.values(files ?? {})?.map(file => {
        return {
          filePathId: file.filePathId,
          name: file.name,
          projectId: project?.id,
          isDeleted: file.isDeleted || false,
          isNew: file.isNew || false
        };
      })[0];
      if (!keepExistingReceipt && file) {
        updateProcess({ ...newEntry, file });
      } else {
        updateProcess({ ...newEntry });
      }
    },
    [newEntry, updateProcess, project?.id, keepExistingReceipt]
  );

  const onUploadsFailed = useCallback(
    error => {
      setError(error);
      setUploadState(addFilesState.finished);
    },
    [setError]
  );

  const handleSubmit = useCallback(
    data => {
      const newEntry = {
        ...entry,
        name: data.name,
        signOffDate: safeUtcDate(data.signOffDate),
        lodgementDate: safeUtcDate(data.lodgementDate),
        signOffDocumentId:
          data.signOffDocument?.value === -1
            ? null
            : data.signOffDocument?.value,
        projectId: project.id
      };
      setNewEntry(newEntry);
      setUploadState(addFilesState.upload);
    },
    [entry, project.id]
  );

  return (
    <ModalForm
      title={"Update Entry"}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      slidingForm={true}
      show={showForm}
      yupSchema={yupSchema}
    >
      {error && <ErrorBox message={error} />}
      <>
        {fields.map(f => {
          switch (f.type) {
            case "document":
              return (
                <Form.Dropdown
                  key={f.key}
                  name={f.key}
                  label={f.label}
                  required={f.required}
                  defaultValue={f.defaultValue ?? noFileItem}
                  items={documentsForDropdown}
                />
              );
            case "text":
              return (
                <Form.TextField
                  key={f.key}
                  name={f.key}
                  label={f.label}
                  defaultValue={f.defaultValue ?? ""}
                  required={f.required}
                />
              );
            case "date":
              return (
                <Form.DateField
                  key={f.key}
                  name={f.key}
                  label={f.label}
                  defaultValue={f.defaultValue ? new Date(f.defaultValue) : ""}
                />
              );
            case "file":
              return (
                <div className="file-upload" key={f.key}>
                  <div>{f.label}</div>

                  <div
                    style={{
                      display: entry.lodgementReceipt ? "block" : "none"
                    }}
                  >
                    <CheckboxInput
                      label="Upload and display a new Lodgement receipt. This will remove the previous file and replace it with a new one"
                      onChange={() =>
                        setKeepExistingReceipt(!keepExistingReceipt)
                      }
                      value={!keepExistingReceipt}
                    />
                  </div>
                  <div
                    style={{
                      display: keepExistingReceipt ? "none" : "block"
                    }}
                  >
                    <Form.UploadDocuments
                      name={f.key}
                      projectId={project?.id}
                      state={uploadState}
                      supportedDocumentMimes={supportedDocumentMimes}
                      maxNumberOfFiles={1}
                      maxNumberOfFilesError="Lodgement receipt needs one file only. Make sure there is exactly one file attached and try again."
                      onUploadsComplete={onUploadsComplete}
                      onUploadsFailed={onUploadsFailed}
                    />
                  </div>
                </div>
              );
          }
        })}
      </>
    </ModalForm>
  );
}

UpdateFinalPackageProcessForm.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  project: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  config: PropTypes.shape({
    title: PropTypes.string,
    fieldNames: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf(["text", "date", "document", "file"])
      })
    )
  })
};

export default UpdateFinalPackageProcessForm;
