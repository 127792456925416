import React, { useCallback, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@app/constants";
import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

import "./InsightsBoardTable.scss";

function InsightsBoardTable(props) {
  const { insightsBoards } = props;
  const {
    createColumn,
    caseInsensitiveSortType,
    createColumnForMultiselectFilter
  } = useDataTable();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef();

  const handleRowClick = insightsBoardId => {
    navigate(`${routeConstants.insightsBoard}/${insightsBoardId}`);
  };

  const handleFilterChange = useCallback(
    key => e => {
      const newVal = e.map(u => u.value);
      tableRef.current.setFilter(key, newVal);
    },
    []
  );

  const columns = useMemo(() => {
    return [
      createColumn({
        Header: t("common:insights.board.table.column.name"),
        accessor: "name",
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        minWidth: 50
      }),
      createColumn({
        Header: t("common:insights.board.table.column.insights"),
        accessor: "insights",
        disableSortBy: false,
        minWidth: 50
      }),
      createColumnForMultiselectFilter({
        filterLabel: t("common:insights.board.table.column.level"),
        accessor: "level",
        allOptionLabel: t("common:allOption"),
        onChangeHandler: handleFilterChange("level"),
        minWidth: 100
      }),
      createColumn({
        Header: t("common:insights.board.table.column.createdBy"),
        accessor: "createdBy",
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        minWidth: 50
      })
    ];
  }, [
    caseInsensitiveSortType,
    createColumn,
    createColumnForMultiselectFilter,
    handleFilterChange,
    t
  ]);

  const data = useMemo(() => {
    return (
      insightsBoards?.map(i => ({
        ...i,
        name: i.translate ? t(i.name) : i.name,
        insights: i.insights.length,
        level: t(`common:insights.level.${i.level}`),
        createdBy: i.createdBy ?? t("common:insights.createdBy.system")
      })) ?? []
    );
  }, [insightsBoards, t]);

  return (
    <DataTable
      ref={tableRef}
      className="insights-board-table"
      columns={columns}
      data={data}
      insightsBoards={insightsBoards}
      onRowClick={handleRowClick}
    />
  );
}

export default InsightsBoardTable;
