import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import {
  useGetProjectByIdQuery,
  useToasts,
  useUpdateProjectMutation
} from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";

import ModalContentTemplate from "@components/templates/ModalContentTemplate";

import "./DraftProjectPublishModal.scss";

interface DraftProjectPublishModalProps {
  projectId?: number;
  handleCloseModal: () => void;
  refreshAfterPublish: () => void;
}

const DraftProjectPublishModal = ({
  projectId,
  handleCloseModal,
  refreshAfterPublish
}: DraftProjectPublishModalProps) => {
  const { t } = useTranslation();

  const [updateProject] = useUpdateProjectMutation();

  const { showProjectPublishedToast, showProjectPublishFailedToast } =
    useToasts();

  const { data: projectData } = useGetProjectByIdQuery(
    { projectId },
    { skip: !projectId }
  );

  const handlePublish = useCallback(async () => {
    const updatedProject = {
      ...projectData,
      id: projectId,
      status: systemConstants.project.status.open
    };

    updateProject({ project: updatedProject })
      .unwrap()
      .then(() => {
        showProjectPublishedToast();
        refreshAfterPublish();
      })
      .catch(err => {
        showProjectPublishFailedToast(getErrorMessage(err, t));
      })
      .finally(handleCloseModal);

    handleCloseModal();
  }, [projectData, projectId]);

  return (
    <ModalContentTemplate
      header={{
        title: t("common:ui.projects.draft.publish.title", {
          projectName: projectData?.name
        })
      }}
      body={{
        message: t("common:ui.projects.draft.publish.warning")
      }}
      footer={{
        submitText: t("common:ui.forms.yes.label"),
        handleSubmit: handlePublish,
        disableSubmit: !projectData?.id || !projectId,
        cancelText: t("common:ui.forms.no.label"),
        handleCancel: handleCloseModal
      }}
      modalClassName="draft-project-publish-modal"
    />
  );
};

export default DraftProjectPublishModal;
