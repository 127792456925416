import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ConversationList.scss";
import ConversationListItem from "./ConversationListItem";

const ConversationList = ({
  allConversations,
  onClickItem,
  conversationId
}) => {
  const { t } = useTranslation();
  const renderEmptyPlaceholder = () => {
    return (
      <div className="smart-form-comment-modal-conversation-list__empty-placeholder">
        {t("common:ui.smartform.conversation.noConversationFound.placeholder")}
      </div>
    );
  };

  const renderConversationList = () => {
    return (
      <div className="smart-form-comment-modal-conversation-list__container">
        {allConversations?.map(conversation => {
          return (
            <ConversationListItem
              key={conversation.conversationId}
              conversation={conversation}
              onClickItem={onClickItem}
              isActive={conversationId === conversation.conversationId}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {allConversations?.length
        ? renderConversationList()
        : renderEmptyPlaceholder()}
    </>
  );
};

ConversationList.propTypes = {
  allConversations: PropTypes.array,
  conversationId: PropTypes.number,
  onClickItem: PropTypes.func
};

export default ConversationList;
