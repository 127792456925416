import { breakpoints, colWidth } from "@shared/helpers/gridLayoutHelper";

/**
 * @param {Number} totalItems
 * @param {Number} width
 * @returns {Object[]} [{i: 'item 1', x: 0, y: 0, w: 2, h: 1}]
 * @description Generate layout for react-grid-layout
 * i: id, x: x-axis, y: y-axis, w: width, h: height
 */
export function generateLayout(totalItems, width) {
  const rows = [];
  let x = 0;
  let w = 2;
  if (width > 2500) {
    w = 1.5;
  }
  for (let i = 0; i < totalItems; i++) {
    const row = {
      i: `item ${i + 1}`,
      x,
      y: 0,
      w,
      h: 1
    };
    rows.push(row);
    if (w === 1.5) {
      x += 1.5;
    } else {
      x += 2;
    }
    if (x === getColumnSpace(width)) {
      x = 0;
    }
  }

  return rows;
}

/**
 *
 * @param {Number} screenWidth
 * @returns {Number}
 * @description Get column space for react-grid-layout
 */

export function getColumnSpace(screenWidth) {
  if (screenWidth >= breakpoints.xl) {
    return colWidth.xl;
  }
  if (breakpoints.lg <= screenWidth && screenWidth < breakpoints.xl) {
    return colWidth.lg;
  }
  if (breakpoints.md <= screenWidth && screenWidth < breakpoints.lg) {
    return colWidth.md;
  }
  if (breakpoints.sm <= screenWidth && screenWidth < breakpoints.md) {
    return colWidth.sm;
  }
  return colWidth.xs;
}
export default { generateLayout, getColumnSpace };
