import React, { useCallback, useEffect, useRef, useState } from "react";

import useOnClickOutside from "use-onclickoutside";

import { useMouseLeaveEnter } from "@app/hooks/useMouseLeaveEnter.tsx";

import { Box } from "@fermions";

import { Icon } from "@atoms/Icon/Icon.tsx";

import { DropdownList } from "@molecules/DropdownList/DropdownList.tsx";

import { Inline } from "@components/fermions/index.tsx";
import {
  DropdownItemType,
  Positioning
} from "@components/molecules/DropdownList/DropdownHelper.tsx";

interface ActionItemTypeFormOptionDropdownProps {
  items: DropdownItemType[];
  onSelectedValuesChanged: (values: DropdownItemType[]) => void;
  disabled?: boolean;
}

export const ActionItemTypeFormOptionDropdown = ({
  items,
  onSelectedValuesChanged,
  disabled
}: ActionItemTypeFormOptionDropdownProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [checkedItems, setCheckedItems] = useState<DropdownItemType[]>([]);
  const toggleRef = useRef();
  const ref = useRef();

  useEffect(() => {
    setCheckedItems(items.filter(i => i.isActive));
  }, [items]);

  const onToggleMenu = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      if (disabled) {
        return;
      }

      setShowMenu(prev => !prev);
    },
    [disabled]
  );

  const handleSelectedValues = (selectedValues: DropdownItemType[]) => {
    onSelectedValuesChanged(selectedValues);
  };

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  const { handleOnMouseEnter, handleOnMouseLeave } = useMouseLeaveEnter({
    skip: !showMenu,
    cb: () => setShowMenu?.(false)
  });

  return (
    <Box
      alignment="center"
      ref={ref}
      onMouseLeave={handleOnMouseLeave()}
      onMouseEnter={handleOnMouseEnter()}
    >
      {/* TODO need better interaction with disabled state and auto-hiding */}
      <Box ref={toggleRef} alignment="center">
        <button
          className="action-item-type-option-dropdown__button"
          onClick={onToggleMenu}
          type="button"
          disabled={disabled}
        >
          <Inline gap="025">
            <Icon name="settings" />
            <Icon name={showMenu ? "expand_less" : "expand_more"} />
          </Inline>
        </button>
      </Box>
      <DropdownList
        items={items}
        parentRef={toggleRef}
        isMultiSelect
        selectedValues={checkedItems}
        setSelectedValues={handleSelectedValues}
        isOpen={showMenu}
        showSearch={false}
        positioning={Positioning.BOTTOM_LEFT}
      />
    </Box>
  );
};
