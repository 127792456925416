import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./LogoNav.scss";

const LogoNav = props => {
  const { logo, link } = props;
  const [logoLink, setLogoLink] = useState(link);
  useEffect(() => {
    setLogoLink(link);
  }, [link]);
  return (
    <div className="logo-nav">
      <NavLink to={logoLink}>
        {logo && <img src={logo} alt="Logo" className="logo__img" />}
      </NavLink>
    </div>
  );
};

LogoNav.defaultProps = {
  link: "/"
};

LogoNav.propTypes = {
  logo: PropTypes.string,
  link: PropTypes.string
};

export default LogoNav;
