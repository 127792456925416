import { manageProjectQueriesConstants } from "../constants";

const initialState = {
  loading: false,
  queries: [],
  query: null,
  addingQuery: false,
  addedQuery: false,
  updatingQuery: false,
  updatedQuery: false,
  fetchingQuery: false,
  fetchedQuery: false,
  error: ""
};

export function manageProjectQueries(state = initialState, action = {}) {
  switch (action.type) {
    case manageProjectQueriesConstants.GET_PROJECT_QUERIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectQueriesConstants.GET_PROJECT_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        queries: action.queries,
        error: ""
      };
    case manageProjectQueriesConstants.GET_PROJECT_QUERIES_FAILURE:
      return {
        ...state,
        loading: false,
        queries: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectQueriesConstants.GET_PROJECT_QUERY_REQUEST:
      return {
        ...state,
        query: null,
        fetchingQuery: true,
        fetchedQuery: false,
        loading: true
      };
    case manageProjectQueriesConstants.GET_PROJECT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingQuery: false,
        fetchedQuery: true,
        query: action.query,
        error: ""
      };
    case manageProjectQueriesConstants.GET_PROJECT_QUERY_FAILURE:
      return {
        ...state,
        fetchingQuery: false,
        fetchedQuery: false,
        loading: false,
        query: null,
        error: action.error?.key ?? action.error?.message
      };
    case manageProjectQueriesConstants.GET_MY_QUERY_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectQueriesConstants.GET_MY_QUERY_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        myQueryCount: action.queries.queries,
        error: ""
      };
    case manageProjectQueriesConstants.GET_MY_QUERY_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        myQueryCount: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectQueriesConstants.ADD_PROJECT_QUERY_REQUEST:
      return {
        ...state,
        loading: true,
        addingQuery: true
      };
    case manageProjectQueriesConstants.ADD_PROJECT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        addingQuery: false,
        addedQuery: action.addedQuery,
        error: ""
      };
    case manageProjectQueriesConstants.ADD_PROJECT_QUERY_FAILURE:
      return {
        ...state,
        loading: false,
        addingQuery: false,
        addedQuery: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_REQUEST:
      return {
        ...state,
        loading: true,
        updatingQuery: true
      };
    case manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        updatingQuery: false,
        updatedQuery: action.updatedQuery,
        error: ""
      };
    case manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_FAILURE:
      return {
        ...state,
        loading: false,
        updatedQuery: false,
        updatingQuery: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectQueriesConstants.UPDATE_PROJECT_QUERIES_SUCCESS:
      return {
        ...state,
        queries: action.updatedQueries
      };
    case manageProjectQueriesConstants.CLEAR_ADDED_QUERY:
      return {
        ...state,
        addedQuery: false,
        addingQuery: false,
        error: ""
      };
    case manageProjectQueriesConstants.CLEAR_UPDATED_QUERY:
      return {
        ...state,
        updatedQuery: false,
        updatingQuery: false,
        error: ""
      };
    case manageProjectQueriesConstants.CLEAR_GET_PROJECT_QUERY:
      return {
        ...state,
        fetchingQuery: false,
        fetchedQuery: false,
        query: null,
        error: ""
      };
    case manageProjectQueriesConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageProjectQueriesConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
