import React, { useEffect, useState } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { Icon, IconDesignStyle } from "@atoms/Icon";

import { CheckboxInput } from "@molecules/inputs";

import "./CheckBoxGroup.scss";

const CheckBoxGroup = props => {
  const {
    formState: { errors },
    control,
    getValues,
    setValue
  } = useFormContext();

  const [error, setError] = useState();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue || (currentValue.length === 0 && props.defaultValue)) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  useEffect(() => {
    const error = get(errors, props.name?.split("."))?.message ?? "";
    setError(error);
  }, [errors, props.name]);

  const getErrorMessage = () => {
    if (error?.length > 0) {
      return (
        <div className="check-box-group__errmsg">
          <Icon
            designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
            name="error"
            className="check-box-group__errmsg-icon"
          />
          <div>{error}</div>
        </div>
      );
    }
  };

  const getRequiredClass = () => (props.required ? "asterisk" : "");

  const getErrorClass = () => (error?.length > 0 ? "error" : "");

  const isChecked = value => {
    return getValues(props.name)?.some(i => i.value === value) ?? false;
  };

  const addOrRemoveItem = item => {
    const currentValues = structuredClone(getValues(props.name)) ?? [];
    const itemIndex = currentValues.findIndex(v => v.value === item.value);
    if (itemIndex === -1) {
      currentValues.push(item);
      return currentValues;
    }
    currentValues.splice(itemIndex, 1);
    return currentValues;
  };
  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange } }) => {
        return (
          <div>
            <div className="check-box-group">
              <label className={`check-box-group__label ${getRequiredClass()}`}>
                {props.label}
              </label>
              <div className={`check-box-group__buttons ${getErrorClass()}`}>
                {props.items.map((item, key) => (
                  <CheckboxInput
                    disabled={props.disabled}
                    value={isChecked(item.value)}
                    onChange={() => {
                      const newList = addOrRemoveItem(item);
                      onChange(newList);
                    }}
                    label={item.name}
                    key={key}
                  />
                ))}
              </div>
            </div>
            {getErrorMessage()}
          </div>
        );
      }}
    />
  );
};

CheckBoxGroup.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ),
  disabled: PropTypes.bool
};

export default CheckBoxGroup;
