import { manageArticleConstants } from "../constants";

const initialState = {
  loading: false,
  articles: [],
  addingArticle: false,
  addedArticle: null,
  updatingArticle: false,
  updatedArticle: null,
  deletingArticle: false,
  deletedArticle: null,
  error: ""
};

export function manageArticles(state = initialState, action = {}) {
  switch (action.type) {
    case manageArticleConstants.GET_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageArticleConstants.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: action.articles
      };
    case manageArticleConstants.GET_ARTICLES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        articles: []
      };
    case manageArticleConstants.ADD_ARTICLE_REQUEST:
      return {
        ...state,
        addingArticle: true,
        addedArticle: null
      };
    case manageArticleConstants.ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        addingArticle: false,
        addedArticle: action.article
      };
    case manageArticleConstants.ADD_ARTICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        addingArticle: false
      };
    case manageArticleConstants.EDIT_ARTICLE_REQUEST:
      return {
        ...state,
        updatingArticle: true,
        updatedArticle: null
      };
    case manageArticleConstants.EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        updatingArticle: false,
        updatedArticle: action.article
      };
    case manageArticleConstants.EDIT_ARTICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        updatingArticle: false
      };
    case manageArticleConstants.DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        deletingArticle: true,
        deletedArticle: null
      };
    case manageArticleConstants.DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        deletingArticle: false,
        deletedArticle: action.article
      };
    case manageArticleConstants.DELETE_ARTICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingArticle: false,
        deletedArticle: null
      };
    case manageArticleConstants.RESET_DELETE_ARTICLE:
      return {
        ...state,
        error: "",
        deletingArticle: false,
        deletedArticle: null
      };
    case manageArticleConstants.RESET_UPDATE_ARTICLE:
      return {
        ...state,
        error: "",
        updatingArticle: false,
        updatedArticle: null
      };
    case manageArticleConstants.RESET_ADD_ARTICLE:
      return {
        ...state,
        error: "",
        addingArticle: false,
        addedArticle: null
      };

    default:
      return state;
  }
}
