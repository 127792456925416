import React, { useMemo } from "react";

import { useTooltip } from "@shared/hooks/useTooltip.jsx";

import ions from "@ions";
import { Context, PageTitleAccentType } from "@ions-interface";

import { classNames } from "@app/helpers/componentHelpers.js";

import { Inline, Stack } from "@fermions";

import OTTooltip from "@components/atoms/OTTooltip";

import "./Title.scss";

interface TitleProps {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  icon?: React.ReactNode;
  subtitlePosition?: "inline" | "newline";
  invert?: boolean;
  subtitleActionIcon?: React.ReactNode;
  hasTitleTooltip?: boolean;
  context?: Context;
  className?: string;
}

export const Title = (props: TitleProps) => {
  const {
    title,
    subtitle,
    actions,
    icon,
    subtitlePosition = "newline",
    subtitleActionIcon,
    invert,
    hasTitleTooltip = false,
    context = Context.LIGHT,
    className
  } = props;
  const {
    showTooltip: showSubtitleTooltip,
    elementProps: subtitleElementProps
  } = useTooltip();
  const { showTooltip: showTitleTooltip, elementProps: titleElementProps } =
    useTooltip();

  const renderTitleAndSubtitle = () => {
    if (subtitle && subtitlePosition === "newline") {
      return (
        <div className="ot-title__wrapper">
          <div className="ot-title__title__wrapper">
            {title && (
              <div className="ot-title__title__wrapper">
                <h1
                  className={classNames([
                    "ot-title__title",
                    `context-${context}`
                  ])}
                  {...titleElementProps}
                >
                  {title}
                  {hasTitleTooltip && (
                    <OTTooltip visible={showTitleTooltip} content={title} />
                  )}
                </h1>
              </div>
            )}
            {icon && <h1 className="ot-title__icon">{icon}</h1>}
          </div>
          {subtitle && (
            <h3 className="ot-title__subtitle__wrapper">
              <span className="ot-title__subtitle" {...subtitleElementProps}>
                {subtitle}
              </span>
              {subtitleActionIcon}
              <OTTooltip visible={showSubtitleTooltip} content={subtitle} />
            </h3>
          )}
        </div>
      );
    }

    return (
      <>
        {title && (
          <div className="ot-title__title__wrapper">
            <h1
              className={classNames([
                "ot-title__title",
                invert ? "invert" : "",
                `context-${context}`
              ])}
              {...titleElementProps}
            >
              {title}
              {hasTitleTooltip && (
                <OTTooltip visible={showTitleTooltip} content={title} />
              )}
            </h1>
          </div>
        )}
        {icon && <h1 className="ot-title__icon">{icon}</h1>}
        {title && subtitle && <div className="ot-title__divider">{"|"}</div>}
        {subtitle && (
          <h1
            className={classNames(["ot-title__subtitle", `context-${context}`])}
          >
            {subtitle}
          </h1>
        )}
      </>
    );
  };

  const MaybeDecorativeHorizontalBar = useMemo(() => {
    if (
      ions.components?.atoms?.page_title?.heading?.accent_type ===
        PageTitleAccentType.LINE &&
      (title || subtitle)
    ) {
      return (
        <div className="ot-title__horizontal-bar">
          <span className="bar"></span>
        </div>
      );
    }
    return <span className="ot-title__custom-blank" />;
  }, [subtitle, title]);

  return (
    <Inline gap="200" style={{ justifyContent: "space-between" }}>
      <Stack
        className={classNames([
          className ?? "",
          "ot-title",
          `ot-title--subtitle-${subtitlePosition}`,
          `ot-title--context-${context}`
        ])}
      >
        <Inline gap="200">{renderTitleAndSubtitle()}</Inline>
        {MaybeDecorativeHorizontalBar}
      </Stack>
      {actions && <div className={`ot-title__actions`}>{actions}</div>}
    </Inline>
  );
};

export default Title;
