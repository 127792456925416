export function sortUsersByFirstname(listOfUsers) {
  return listOfUsers.sort((a, b) =>
    (a.firstname || "").localeCompare(b.firstname)
  );
}

export function sortUsersByOrder(listOfUsers) {
  return listOfUsers.sort((a, b) => a.projectOrder - b.projectOrder);
}

export function formatBusinessNumber({
  businessPhoneNumber,
  businessCountryCode,
  businessAreaCode
}) {
  if (businessPhoneNumber) {
    const businessNumberWithSpace = businessPhoneNumber.match(/.{1,4}/g);
    const formattedBusinessNumber = `${
      businessCountryCode ? businessCountryCode + " " : ""
    }${
      businessAreaCode ? businessAreaCode + " " : ""
    }${businessNumberWithSpace.join(" ")}`;
    return formattedBusinessNumber;
  }
  return null;
}

export function formatMobileNumber({ mobileNumber, mobileCountryCode }) {
  if (mobileNumber) {
    const phoneNumberWithSpace = mobileNumber.match(/.{1,3}/g);
    const formattedMobileNumber = `${
      mobileCountryCode ? mobileCountryCode + " " : ""
    }${phoneNumberWithSpace.join(" ")}`;
    return formattedMobileNumber;
  }
  return null;
}

export function isUserAlreadyPresent(projectMembers, userToCheck) {
  return (
    projectMembers.hostUsers.some(
      member =>
        member.id === userToCheck.id && member.email === userToCheck.email
    ) ||
    projectMembers.clientUsers.some(
      member =>
        member.id === userToCheck.id && member.email === userToCheck.email
    )
  );
}
