import React, { MouseEventHandler } from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { ButtonSize, ButtonVariant } from "@ions-interface";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";
import OTTooltip from "@atoms/OTTooltip";

import "./Button.scss";

export { ButtonVariant, ButtonSize } from "@ions-interface";

type ButtonType = "button" | "submit" | "reset";

export interface ButtonProps {
  label: string;
  variant?: ButtonVariant;
  type?: ButtonType;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement> | ((e?: unknown) => void);
  className?: string;
  id?: string;
  value?: string | number;
  size?: ButtonSize;
  hoverElement?: string | React.JSX.Element;
  iconName?: string;
  iconSize?: IconSize;
  iconOutlined?: boolean;
  iconOnRight?: boolean;
  skipTextTransform?: boolean;
}

export const Button = ({
  label,
  variant,
  type = "button",
  onClick,
  disabled,
  size = ButtonSize.DEFAULT,
  className = "",
  id,
  value,
  hoverElement,
  iconName,
  iconSize,
  iconOutlined,
  iconOnRight,
  skipTextTransform
}: ButtonProps) => {
  const { elementProps, showTooltip } = useTooltip();

  const { buttonType, buttonVariant } = resolveButtonVariantAndType({
    type,
    variant
  });

  const handleClick: React.MouseEventHandler | undefined = onClick
    ? e => {
        e.preventDefault();
        onClick?.(e);
      }
    : undefined;

  const buttonClassNames: string = classNames([
    className,
    "ot-button",
    `ot-button--variant-${buttonVariant}`,
    `ot-button--size-${size}`
  ]);

  const icon = (
    <>
      {iconName && (
        <Icon
          className="ot-button__icon"
          name={iconName}
          size={iconSize}
          fillStyle={
            iconOutlined ? IconFillStyle.OUTLINED : IconFillStyle.FILLED
          }
        />
      )}
    </>
  );

  const buttonLabelClassNames = classNames([
    "ot-button__label",
    returnStringIfTrue(
      skipTextTransform,
      `ot-button__label--skip-text-transform`
    )
  ]);
  return (
    <button
      className={buttonClassNames}
      disabled={disabled}
      onClick={handleClick}
      type={buttonType}
      id={id}
      value={value}
      {...(hoverElement ? elementProps : {})}
    >
      {!iconOnRight && icon}
      <span className={buttonLabelClassNames}>{label}</span>
      {iconOnRight && icon}
      {showTooltip && (
        <OTTooltip visible={showTooltip} content={hoverElement} />
      )}
    </button>
  );
};

function resolveButtonVariantAndType({
  type,
  variant
}: {
  type?: ButtonType;
  variant?: string | ButtonVariant;
}): {
  buttonType: ButtonType;
  buttonVariant: string;
} {
  const buttonType =
    type && ["button", "submit", "reset"].includes(type) ? type : "button";

  const buttonVariant =
    variant && Object.values(ButtonVariant).includes(variant)
      ? variant
      : ButtonVariant.PRIMARY;

  return {
    buttonType,
    buttonVariant
  };
}
