import { manageClientEngagementsConstants } from "../constants";
import { manageClientEngagementsService } from "../services";

export const manageClientEngagementsActions = {
  getClients,
  getMembersToAdd,
  getMembers,
  addMember,
  removeMember,
  clearError,
  resetMembersToAdd,
  resetAddedMember,
  resetRemovedMember,
  resetMembers,
  reset
};

function addMember({ engagement, member }) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementsService.addMember({ engagement, member }).then(
      memberObject => {
        dispatch(success(memberObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementsConstants.ADD_MEMBER_REQUEST
    };
  }
  function success(memberObject) {
    return {
      type: manageClientEngagementsConstants.ADD_MEMBER_SUCCESS,
      member: memberObject
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementsConstants.ADD_MEMBER_FAILURE,
      error
    };
  }
}

function removeMember({ engagement, member }) {
  return dispatch => {
    dispatch(request());
    manageClientEngagementsService.removeMember({ engagement, member }).then(
      memberObject => {
        dispatch(success(memberObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementsConstants.REMOVE_MEMBER_REQUEST
    };
  }
  function success(memberObject) {
    return {
      type: manageClientEngagementsConstants.REMOVE_MEMBER_SUCCESS,
      member: memberObject
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementsConstants.REMOVE_MEMBER_FAILURE,
      error
    };
  }
}

function getMembers(engagement) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementsService.getMembers(engagement).then(
      members => {
        dispatch(success(members));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_REQUEST
    };
  }
  function success(members) {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_SUCCESS,
      members
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_FAILURE,
      error
    };
  }
}

function getMembersToAdd(engagement) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementsService.getMembersToAdd(engagement).then(
      members => {
        dispatch(success(members));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_REQUEST
    };
  }
  function success(members) {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_SUCCESS,
      members
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementsConstants.GET_MEMBERS_TO_ADD_FAILURE,
      error
    };
  }
}

function getClients(hostUser) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementsService.getClients(hostUser).then(
      clients => {
        dispatch(success(clients));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementsConstants.GET_CLIENTS_REQUEST
    };
  }
  function success(clients) {
    return {
      type: manageClientEngagementsConstants.GET_CLIENTS_SUCCESS,
      clients
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementsConstants.GET_CLIENTS_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: manageClientEngagementsConstants.CLEAR_ERROR };
}

function resetMembersToAdd() {
  return { type: manageClientEngagementsConstants.RESET_MEMBERS_TO_ADD };
}

function resetAddedMember() {
  return { type: manageClientEngagementsConstants.RESET_ADDED_MEMBER };
}

function resetRemovedMember() {
  return { type: manageClientEngagementsConstants.RESET_REMOVED_MEMBER };
}

function resetMembers() {
  return { type: manageClientEngagementsConstants.RESET_MEMBERS };
}

function reset() {
  return { type: manageClientEngagementsConstants.RESET };
}
