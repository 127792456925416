import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { smartFormHelper } from "@app/helpers/smartForm";

import { BorderStyle, DropdownInput } from "@molecules/inputs";

import SmartFormRadioButtons from "../SmartFormRadioButtons";

const SmartFormSingleChoice = ({
  options,
  handleOnChange,
  questionId,
  disabled,
  value,
  layout
}) => {
  const onChange = useCallback(
    (selectedItem, selectedValue) => {
      if (options.length < 4) {
        return handleOnChange(selectedValue);
      }
      return handleOnChange(selectedItem?.value ?? null);
    },
    [handleOnChange, options.length]
  );
  return (
    <>
      {options.length < 4 ? (
        <SmartFormRadioButtons
          options={options}
          allowUnselect={true}
          layout={layout}
          onChange={onChange}
          value={value}
          questionId={questionId}
          disabled={disabled}
          transformBooleans={false}
        />
      ) : (
        <DropdownInput
          items={options || []}
          onChange={onChange}
          value={{ value }}
          transformSelected={selected =>
            smartFormHelper.getSingleSelectValueName(selected.value, options)
          }
          showClear
          disabled={disabled}
          sortComparator={() => 0}
          borderStyle={BorderStyle.NONE_WITH_PADDING}
        />
      )}
    </>
  );
};

SmartFormSingleChoice.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
  questionId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired
};
export default SmartFormSingleChoice;
