import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import {
  useGetDocumentRevisionsQuery,
  useSetDocumentCurrentRevisionMutation
} from "@shared/services/documentService";

import { Button, ButtonVariant } from "@atoms/Button";

import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import DocumentRevisionDataTable from "./DocumentRevisionDataTable";
import "./DocumentRevisionHistory.scss";

const revisionOf = {
  clientProject: "projectDocument"
};

const DocumentRevisionHistory = props => {
  const { document, fetchDocument, downloadedDocuments, canSelectActive, t } =
    props;
  const downloadRef = useRef(null);
  const [downloadItem, setDownloadItem] = useState(document);
  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [activeRevisionId, setActiveRevisionId] = useState();
  const { data: revisions } = useGetDocumentRevisionsQuery(
    { documentId: document.id },
    { skip: !document?.id }
  );
  const [setDocumentCurrentRevision] = useSetDocumentCurrentRevisionMutation();

  useEffect(() => {
    if (revisions?.length > 0) {
      setActiveRevisionId(revisions.filter(r => r.current)[0].id);
    }
  }, [revisions, setActiveRevisionId]);

  const handleRestoreClick = () => {
    if (document.documentRevisionId === activeRevisionId) {
      return;
    }
    setDocumentCurrentRevision({
      documentId: document.id,
      revisionId: activeRevisionId
    });
    props.onSubmit();
  };

  const selectRevision = item => {
    setActiveRevisionId(item.id);
  };

  useEffect(() => {
    if (downloadedDocuments?.length === 0) {
      return;
    }

    downloadedDocuments.forEach(doc => {
      if (
        doc.id === downloadItem.id &&
        doc.documentRevisionId === downloadItem.documentRevisionId
      ) {
        setDownloadFile({
          downloadName: doc.downloadName,
          file: doc.objectURL
        });
      }
    });
  }, [downloadedDocuments, downloadItem]);

  useEffect(() => {
    if (downloadItem && downloadFile?.file && downloadClicked) {
      downloadRef.current.click();
      setDownloadClicked(false);
    }
  }, [downloadItem, downloadFile, downloadClicked]);

  const handleDownload = item => {
    if (props.revisionOf === revisionOf.clientProject) {
      const downloadDoc = {
        ...document,
        documentRevisionId: item.id,
        downloadName: item.name,
        name: item.name,
        file: null
      };
      setDownloadClicked(true);
      setDownloadItem(downloadDoc);
      setDownloadFile(null);
      fetchDocument(downloadDoc);
    }
  };

  const renderFooter = () => {
    if (!canSelectActive) {
      return <></>;
    }
    return (
      <>
        <Button
          variant={ButtonVariant.SECONDARY}
          label={t("common:ui.forms.cancel.label")}
          onClick={props.onCancel}
        />
        <Button
          label={t("common:documents.revision.saveActiveRevision")}
          onClick={handleRestoreClick}
        />
      </>
    );
  };

  return (
    <ModalTemplate
      boxClassName="document-revision-history"
      title={t("common:documents.revision.label")}
      onClose={props.onCancel}
      content={
        <>
          <div className="document-revision-history__table">
            <DocumentRevisionDataTable
              documentRevisions={revisions || []}
              activeRevisionId={activeRevisionId}
              handleDownloadDocument={handleDownload}
              handleRevisionSelected={selectRevision}
              canSelectActive={canSelectActive}
            ></DocumentRevisionDataTable>
          </div>

          {downloadFile ? (
            <div>
              <a
                className="hidden"
                ref={downloadRef}
                href={downloadFile.file}
                download={downloadFile.downloadName}
              ></a>
            </div>
          ) : (
            ""
          )}
        </>
      }
      footer={renderFooter()}
    />
  );
};

DocumentRevisionHistory.defaultProps = {};

DocumentRevisionHistory.propTypes = {
  document: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchDocument: PropTypes.func,
  downloadedDocuments: PropTypes.array,
  canSelectActive: PropTypes.bool
};
export default withTranslation()(DocumentRevisionHistory);
