import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { CheckboxInput } from "@molecules/inputs";

function Checkbox(props) {
  const {
    getValues,
    setValue,
    formState: { errors },
    control
  } = useFormContext();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <CheckboxInput
            label={props.label}
            ref={ref}
            onBlur={onBlur}
            value={getValues(props.name) ?? false}
            onChange={newVal => {
              onChange(newVal);
              props.onChange?.(newVal);
            }}
            disabled={props.disabled}
            required={props.required}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            placeholder={props.placeholder}
            style={props.style || {}}
          />
        );
      }}
    />
  );
}

Checkbox.propTypes = {
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func
};

export default Checkbox;
