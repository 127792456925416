import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import websheetWizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import WizardWebsheet from "../../WizardWebsheet";

const CleanupWizard = React.forwardRef((props, fwdRef) => {
  const { data: cleaningDataObj, onSheetLoading } = props;
  const data = useMemo(
    () => cleaningDataObj?.websheet ?? [],
    [cleaningDataObj?.websheet]
  );
  const hotTableComponent = useRef(null);
  const [currentSheetName, setCurrentSheetName] = useState();

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const afterGetColHeader = (_, TH) => {
    TH.className += "wizard-websheet__col-header";
  };

  const afterGetRowHeader = (row, TH) => {
    TH.className += "wizard-websheet__row-header";
  };

  const getDataForSubmission = useCallback(() => {
    if (!hotTableComponent?.current?.hotInstance) {
      return {};
    }
    return {
      websheet: websheetWizardUtilities.getCleanupData({
        data,
        currentSheetName,
        hotTableComponent
      }),
      savedState: cleaningDataObj.savedState
    };
  }, [cleaningDataObj.savedState, currentSheetName, data]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit: () => true
    }),
    [getDataForSubmission]
  );

  return (
    <WizardWebsheet
      currentSheetName={data?.[0]?.sheet}
      data={data}
      onSheetLoading={onSheetLoading}
      firstRowHeader={true}
      editable={true}
      hotTableComponent={setHotTableComponent}
      onCurrentSheetNameChanged={setCurrentSheetName}
      afterGetRowHeader={afterGetRowHeader}
      rowHeaders={true}
      afterGetColHeader={afterGetColHeader}
    />
  );
});

export default CleanupWizard;
