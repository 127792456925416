import React from "react";

import { useGetEngagementTypesQuery } from "@shared/services";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import AddClientProjectForm from "./AddClientProjectForm";

const CreateProject = () => {
  const { isLoading: loadingEngagementTypes } = useGetEngagementTypesQuery();
  const { isLoading: loadingFeatures } = useGetFeaturesQuery();

  return (
    <AddClientProjectForm
      isLoading={loadingFeatures || loadingEngagementTypes}
    />
  );
};

CreateProject.propTypes = {};

export default CreateProject;
