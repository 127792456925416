import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ions from "@ions";

import { Button } from "@atoms/Button";
import { Text } from "@atoms/Text";

import { CheckboxInput } from "@molecules/inputs";

import ContainerBar from "@components/atoms/ContainerBar";
import { InputLabelFontColor } from "@components/molecules/inputs/InputTemplate";

import "./WizardStepsBar.scss";

function WizardStepsBar(props) {
  const { t } = useTranslation();
  const {
    steps,
    currentStepNumber,
    onNext,
    onComplete,
    onStartOver,
    disableProgression,
    onStepBarDisplayed,
    isNextFileAvailable,
    context
  } = props;
  const [applyCleanseToNextFile, setApplyCleanseToNextFile] = useState(false);
  // this ref is to handle an edge case:
  // if file is too large and time consuming to be loaded,
  // there may be an instant that disableProgression becomes false but page still stays in the cleaning mode
  // and user would see two toasts if they click the button again at that moment
  const isCompleteClicked = useRef(false);

  useEffect(() => {
    onStepBarDisplayed(true);
  }, [onStepBarDisplayed]);

  const currentStep = useMemo(() => {
    return steps
      .map((s, idx) => ({ ...s, ordinal: idx + 1 }))
      .find(s => s.stepNumber === currentStepNumber);
  }, [steps, currentStepNumber]);

  const totalSteps = useMemo(() => (steps ?? []).length, [steps]);

  const handleComplete = debounce(() => {
    if (isCompleteClicked.current) {
      return;
    }
    onComplete(applyCleanseToNextFile);
    isCompleteClicked.current = true;
  }, 300);

  const completeWizardActionButtons = useCallback(() => {
    return (
      <div className="ot-wizard-steps-bar__complete-actions">
        {isNextFileAvailable && (
          <CheckboxInput
            value={applyCleanseToNextFile}
            label={t(
              "common:ui.websheet.actions.cleaningWizard.applyToNextFile"
            )}
            onChange={() => setApplyCleanseToNextFile(prev => !prev)}
            fontColor={InputLabelFontColor.LIGHT}
          />
        )}
        <Button
          label={t("common:ui.forms.complete.label")}
          onClick={handleComplete}
          disabled={disableProgression}
        />
      </div>
    );
  }, [
    applyCleanseToNextFile,
    disableProgression,
    handleComplete,
    isNextFileAvailable,
    t
  ]);

  const renderActionButtons = useCallback(() => {
    const renderNextOrComplete = () =>
      currentStep.ordinal < steps?.length ? (
        <Button
          label={t("common:ui.forms.next.label")}
          onClick={onNext}
          disabled={disableProgression}
        />
      ) : (
        completeWizardActionButtons()
      );

    if (currentStep.getActionButtons) {
      return currentStep.getActionButtons({
        onNext,
        disableProgression,
        completeWizardActionButtons: renderNextOrComplete,
        context
      });
    }

    return (
      <>
        {currentStep.ordinal > 1 && (
          <Button
            variant={
              ions.components?.templates?.content_container?.header
                ?.button_variant
            }
            iconName="refresh"
            label={t("common:ui.forms.startOver.label")}
            onClick={onStartOver}
          />
        )}
        {renderNextOrComplete()}
      </>
    );
  }, [
    currentStep,
    t,
    onStartOver,
    steps?.length,
    onNext,
    disableProgression,
    completeWizardActionButtons,
    context
  ]);

  return (
    <div className="ot-wizard-steps-bar">
      <ContainerBar>
        <div className="ot-wizard-steps-bar__content">
          <div className="ot-wizard-steps-bar__info">
            <div className="ot-wizard-steps-bar__progress">
              <Text
                text={t("common:ui.steps.progress", {
                  currentStep: currentStep.ordinal,
                  totalSteps
                })}
              />
            </div>
            <div className="ot-wizard-steps-bar__description">
              {currentStep.description}
            </div>
          </div>
          <div className="ot-wizard-steps-bar__actions">
            {renderActionButtons()}
          </div>
        </div>
      </ContainerBar>
    </div>
  );
}

WizardStepsBar.propTypes = {
  onNext: PropTypes.func,
  onStartOver: PropTypes.func,
  onComplete: PropTypes.func,
  currentStepNumber: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.any,
      stepNumber: PropTypes.number
    })
  ).isRequired,
  disableProgression: PropTypes.bool,
  onStepBarDisplayed: PropTypes.func.isRequired
};

export default WizardStepsBar;
