import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import { useUpdateFinalPackageTip } from "@shared/hooks/useUpdateFinalPackageTip";

import Popup from "@shared-components/popup/Popup";

import Form from "@components/atoms/Form/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";

import ModalForm from "../../molecules/ModalForm/ModalForm";

const tipsStatus = systemConstants.finalPackage.finalPackageTips.status;

export const EditFinalPackageTipModalForm = ({
  handleClose,
  handleSubmit,
  visibility,
  projectId,
  finalPackageTip
}) => {
  const { t } = useTranslation();
  const {
    updateFinalPackageTip,
    finalPackageTipUpdateError,
    finalPackageTipUpdated
  } = useUpdateFinalPackageTip();
  const yupSchema = yup.object({
    tip: yup.string().required("Please enter recommendation details")
  });

  const onUpdate = useCallback(
    data => {
      updateFinalPackageTip(projectId, {
        ...finalPackageTip,
        projectId,
        status: tipsStatus.active,
        tip: data.tip
      });
    },
    [finalPackageTip, projectId, updateFinalPackageTip]
  );

  useEffect(() => {
    if (finalPackageTipUpdated) {
      handleSubmit?.();
    }
  }, [finalPackageTipUpdated, handleSubmit]);
  return (
    <Popup
      visibility={visibility}
      handleOutsideClick={handleClose}
      width="50rem"
    >
      <ModalForm
        yupSchema={yupSchema}
        boxClassName=""
        title="Edit recommendation"
        handleCancel={handleClose}
        handleSubmit={onUpdate}
        submitLabel="Update"
      >
        {finalPackageTipUpdateError && (
          <InlineAlert type="error" message={t(finalPackageTipUpdateError)} />
        )}
        <Form.TextArea
          defaultValue={finalPackageTip?.id && finalPackageTip?.tip}
          name="tip"
          label="Recommendation"
          maxLength={500}
        />
      </ModalForm>
    </Popup>
  );
};

export default EditFinalPackageTipModalForm;
