import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { clientDocumentActions } from "@shared/actions";

export function useClientDocumentRevisions(clientId, document) {
  const dispatch = useDispatch();
  const clientDocuments = useSelector(state => state.clientDocuments);
  const [revisions, setRevisions] = useState([]);
  const [activeRevisionId, setActiveRevisionId] = useState();

  const fetchDocumentRevisions = useCallback(() => {
    dispatch(
      clientDocumentActions.getClientDocumentRevisions(clientId, document.id)
    );
    setActiveRevisionId(document.documentRevisionId);
  }, [dispatch, document, clientId]);

  useEffect(() => {
    if (clientDocuments.documentRevisions.length) {
      setRevisions(clientDocuments.documentRevisions);
    }
  }, [clientDocuments.documentRevisions]);

  const updateDocumentRevisionToActive = useCallback(() => {
    const item = revisions.find(r => r.id === activeRevisionId);
    dispatch(
      clientDocumentActions.updateClientDocumentCurrentRevision(clientId, item)
    );
  }, [revisions, dispatch, clientId, activeRevisionId]);

  return {
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    setActiveRevisionId,
    activeRevisionId,
    revisions
  };
}
