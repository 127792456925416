import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useDeleteFinalPackageProcess() {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);
  const [deleteError, setDeleteError] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [didDeleteEntry, setDeletedEntry] = useState();

  const resetDeleteProcessEntry = useCallback(() => {
    dispatch(finalPackageActions.resetDeleteFinalPackageProcessEntry());
  }, [dispatch]);

  const deleteProcessEntry = useCallback(
    entry => {
      dispatch(finalPackageActions.deleteFinalPackageProcessEntry(entry));

      return () => {
        dispatch(finalPackageActions.resetDeleteFinalPackageProcessEntry());
      };
    },
    [dispatch]
  );

  useEffect(() => {
    setDeleteError(finalPackage.finalPackageProcessDeleteError);
  }, [finalPackage.finalPackageProcessDeleteError]);

  useEffect(() => {
    setIsDeleting(finalPackage.isFinalPackageProcessDeleting);
  }, [finalPackage.isFinalPackageProcessDeleting]);

  useEffect(() => {
    setDeletedEntry(finalPackage.finalPackageProcessDeleted);
  }, [finalPackage.finalPackageProcessDeleted]);

  return {
    resetDeleteProcessEntry,
    deleteProcessEntry,
    deleteError,
    isDeleting,
    didDeleteEntry
  };
}
