import { ToDoType, systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageProjectService = {
  getProjectMessages,
  getProjectTodos,
  updateProjectTodoStatus,
  updateProjectTodo,
  getProjectDocuments,
  getProjectDocument,
  getProjectFoldersAndDocuments,
  updateProjectMessageStatus,
  reorderProjectMembers
};

function getProjectFoldersAndDocuments(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/documents/foldersAndDocuments`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectDocument({ id: documentId, documentRevisionId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/documents/${documentId}/revisions/${documentRevisionId}`,
    requestOptions
  )
    .then(response => response.blob())
    .then(blob => {
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    })
    .catch(handleErrorResponse);
}

function getProjectDocuments({ id: projectId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/projects/${projectId}/documents`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectMessages(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/projects/${project.id}/messages`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectTodos(project, type = ToDoType.ASSIGNED_TO_ME) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/todos?type=${type}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function reorderProjectMembers(project, members) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(members)
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/userProjectOrder`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectTodoStatus(todo) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(todo)
  };
  return fetch(`${baseURL}/api/todos/${todo.id}/status`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectTodo(todo) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(todo)
  };
  return fetch(`${baseURL}/api/todos/${todo.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectMessageStatus(message) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(message)
  };
  return fetch(`${baseURL}/api/messages/${message.id}/status`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
