import React, { useCallback, useMemo } from "react";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants.js";

import { useToasts } from "@shared/hooks/useToasts.jsx";
import {
  useCreateActionItemTypeMutation,
  useGetActionItemTypeQuery,
  useGetActionItemTypesFormOptionsQuery
} from "@shared/services/actionItemTypesService.js";

import { CrudFormMode } from "@app/types/ActionItemType.ts";
import { ApiErrorType } from "@app/types/Api.ts";

import FormPageTemplate from "@templates/FormPageTemplate/FormPageTemplate.jsx";

import ActionItemTypeForm from "./ActionItemTypeForm.tsx";
import {
  createAITFormSchema,
  fieldFormValuesToPayload,
  permissionFormValuesToPayload
} from "./actionItemType.tsx";

const CreateActionItemType = () => {
  const { actionItemTypeId } = useParams();
  const { data: actionItemType, isLoading: isAitLoading } =
    useGetActionItemTypeQuery(
      { actionItemTypeId },
      { skip: !actionItemTypeId }
    );
  const { data: formOptions, isLoading: isOptionsLoading } =
    useGetActionItemTypesFormOptionsQuery({});
  const [createActionItemType, { isLoading: isCreating }] =
    useCreateActionItemTypeMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isLoading = useMemo(
    () => isAitLoading || isOptionsLoading,
    [isAitLoading, isOptionsLoading]
  );

  const { showSuccess, showError } = useToasts();

  const handleSubmit = useCallback(
    formData => {
      const context = {
        engagementType: formOptions.engagementTypes.find(
          item => item.id === formData.engagementType?.value
        )
      };
      const getIfConditionMet = (conditionKey: string, value: unknown) => {
        const isSatisfied = get(context, conditionKey);
        return isSatisfied ? value : undefined;
      };
      const formatConfig =
        formOptions.formats.find(f => f.type === formData.format.value) ?? {};
      const validFieldKeys =
        formatConfig?.formsConfig
          .filter(cfg => cfg.section === "fields")
          .map(cfg => cfg.key) ?? [];
      const payload = structuredClone(formData);
      payload.permissions = permissionFormValuesToPayload(formData.permissions);
      payload.fields = fieldFormValuesToPayload(
        formData.fields,
        validFieldKeys
      );
      payload.projectYear = getIfConditionMet(
        "engagementType.configuration.project.yearField.enabled",
        formData.projectYear?.value
      );
      payload.format = formData.format.value;
      payload.engagementTypeId = formData.engagementType.value;
      payload.createFrom = formData.createFrom?.map(
        ({ value }: { value: string }) => value
      );
      delete payload.engagementType;

      createActionItemType({ actionItemType: payload })
        .unwrap()
        .then(createdRequestType => {
          showSuccess({
            key: "admin:ui.createRequestType.success.title"
          });
          navigate(`/admin/request-types/${createdRequestType.id}`);
        })
        .catch((error: ApiErrorType) => {
          showError({
            key:
              error?.data.message ??
              "admin:ui.createRequestType.error.generic.title"
          });
        });
    },
    [
      createActionItemType,
      formOptions?.engagementTypes,
      navigate,
      showError,
      showSuccess
    ]
  );

  const yupSchema = useMemo(() => {
    return createAITFormSchema(formOptions, t);
  }, [formOptions, t]);

  return (
    <FormPageTemplate
      title={t("admin:ui.createRequestType.title")}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageRequestTypes,
          label: t("admin:ui.manageRequestTypes.title")
        }
      ]}
      sticky
      form={{
        handleSubmit,
        handleCancel: () => navigate(routeConstants.admin.manageRequestTypes),
        yupSchema,
        disabled: isCreating,
        contents: (
          <ActionItemTypeForm
            actionItemType={actionItemType}
            formOptions={formOptions}
            crudFormMode={CrudFormMode.CREATE}
          />
        )
      }}
      isLoading={isLoading}
      other={{}}
      renderRawForm
    />
  );
};

export default CreateActionItemType;
