import React, { LegacyRef, forwardRef, useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";

import "../Fermions.scss";
import {
  FermionProps,
  getClassArray,
  getFermionClasses,
  splitProps
} from "../fermionsConfig.tsx";

interface BoxProps extends FermionProps {}

export const Box = forwardRef(
  (props: BoxProps, ref?: LegacyRef<HTMLDivElement> | undefined) => {
    const { className, children } = props;

    const { divProps, classProps } = splitProps(props);

    const classArray = useMemo(() => getClassArray(className), [className]);

    return (
      <div
        className={classNames([
          ...classArray,
          "fermion",
          "box",
          ...getFermionClasses(classProps)
        ])}
        {...divProps}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
