import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ModalForm from "@components/molecules/ModalForm";

import "./SendToHost.scss";

const SendToHost = props => {
  const { t } = useTranslation();
  const { handleSubmit, lockSubmit, handleCancel, error, i18nActionKey } =
    props;

  const title = t(
    i18nActionKey
      ? `requests:requests.configured.menu.actions.${i18nActionKey}.label`
      : "requests:requests.configured.menu.actions.SEND_TO_HOST.label"
  );

  return (
    <ModalForm
      boxClassName="ot-reassign-query"
      title={title}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      disabled={lockSubmit}
      submitLabel={t(
        `${
          lockSubmit
            ? "requests:requests.ui.smartform.modal.submitButton.finalised.label"
            : "common:ui.forms.submit.label"
        }`
      )}
      cancelLabel={t("common:ui.forms.cancel.label")}
      errorMessage={error || null}
    >
      <div>{`${t("requests:requests.ui.smartForm.sendToHost.message")}`}</div>
    </ModalForm>
  );
};

SendToHost.defaultProps = {
  lockSubmit: false
};

SendToHost.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lockSubmit: PropTypes.bool,
  error: PropTypes.string,
  i18nActionKey: PropTypes.string
};

export default SendToHost;
