import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useFinalPackageDocuments(projectId) {
  const finalPackage = useSelector(state => state.finalPackage);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();
  const [finalPackageFolder, setFinalPackageFolder] = useState({});

  // finalPackage documents are really just Documents in a special folder.
  // We need to fetch them differently to get the folder information for uploads
  // Apart from that all other ProjectDocument methods will work for now
  const fetchDocuments = useCallback(() => {
    if (!projectId) {
      return;
    }
    dispatch(finalPackageActions.getFinalPackageDocuments(projectId));

    return () => {
      dispatch(finalPackageActions.resetGetFinalPackageDocuments());
    };
  }, [projectId, dispatch]);

  useEffect(fetchDocuments, [fetchDocuments]);

  useEffect(() => {
    setIsLoading(finalPackage.documentsLoading);
  }, [finalPackage.documentsLoading]);

  useEffect(() => {
    setDocuments(finalPackage.documents || []);
  }, [finalPackage.documents]);

  useEffect(() => {
    setError(finalPackage.error);
  }, [finalPackage.error]);

  useEffect(() => {
    setFinalPackageFolder(finalPackage.finalPackageFolder);
  }, [finalPackage.finalPackageFolder]);

  return {
    documents,
    fetchDocuments,
    isLoading,
    error,
    finalPackageFolder
  };
}
