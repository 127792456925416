import React from "react";

import "./PageModal.scss";

export interface PageModalType {}

interface PageModalProps {
  open: boolean;
  content: React.JSX.Element;
  classNames?: unknown;
}

const PageModal = ({ open, content }: PageModalProps) => {
  return open ? <div className="page-template-modal">{content}</div> : <></>;
};

export default PageModal;
