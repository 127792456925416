import React, { useCallback, useMemo } from "react";

import { intersection } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AccessLevel, ResourcePermission, RoleMode } from "@app/types";

import MatrixTemplate from "@components/templates/MatrixTemplate/MatrixTemplate";

import ResourceRow from "./ResourceRow";

const accessLevels: AccessLevel[] = [
  AccessLevel.READ,
  AccessLevel.CREATE,
  AccessLevel.UPDATE,
  AccessLevel.DELETE
];

type Props = {
  resourcePermissions: ResourcePermission;
  defaultPermissions?: number[];
  disabled?: boolean;
  mode: RoleMode;
};

function PermissionsMatrix(props: Readonly<Props>) {
  const { resourcePermissions, defaultPermissions, disabled, mode } = props;

  const { t } = useTranslation();

  const { watch } = useFormContext();
  const watchRoleType = watch("type");

  const relevantResources = useMemo(() => {
    if (watchRoleType === "HOST") {
      return resourcePermissions.resources;
    }
    return resourcePermissions.resources.filter(
      resource => !resource.permissions.every(p => p.hostOnly)
    );
  }, [resourcePermissions.resources, watchRoleType]);

  const getResourceRows = useCallback(
    ({ className, cellWidth }) =>
      relevantResources.map(resource => {
        const permissionIds = resource.permissions.map(p => p.id);
        const defaultChecked =
          defaultPermissions && intersection(permissionIds, defaultPermissions);
        return (
          <ResourceRow
            resource={resource}
            key={resource.name}
            hostMode={watchRoleType === "HOST"}
            className={className}
            accessLevels={accessLevels}
            cellWidth={cellWidth}
            defaultChecked={defaultChecked}
            disabled={disabled}
            mode={mode}
          />
        );
      }),
    [relevantResources, defaultPermissions, watchRoleType, disabled, mode]
  );

  return (
    <MatrixTemplate
      getRows={getResourceRows}
      rowHeaders={relevantResources.map(r =>
        t("roles:ui.role.resource", { context: r.name })
      )}
      columnHeaders={accessLevels.map(level =>
        t("roles:ui.role.accessLevel", { context: level })
      )}
      header={resourcePermissions.section}
    />
  );
}

export default PermissionsMatrix;
