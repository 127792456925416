import React from "react";

// default is nothing. the structure is the uiConfig
const UIConfigContext = React.createContext({
  theme: {},
  sideNavigation: {},
  multipleProjectSelection: {},
  groupLabelDropdown: {}
});

export default UIConfigContext;
