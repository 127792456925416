import { useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";

export function useSearchProjectDocuments() {
  const [searchResults, setSearchResults] = useState([]);
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const dispatch = useDispatch();

  const searchDocuments = useMemo(
    () =>
      debounce((project, searchString) => {
        if (searchString) {
          dispatch(
            manageProjectDocumentsActions.getProjectDocumentsBySearchString(
              project,
              encodeURIComponent(searchString)
            )
          );
        }
      }, 300),
    [dispatch]
  );

  useEffect(() => {
    setSearchResults(manageProjectDocuments.documentsBySearchString || []);
  }, [manageProjectDocuments.documentsBySearchString]);

  return {
    searchDocuments,
    searchResults
  };
}
