import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import * as _ from "lodash";
import { useTranslation } from "react-i18next";

import { useUrlHash } from "@shared/hooks/useUrlHash";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";
import {
  PageInfoItem,
  PaginationType,
  paginationHelper
} from "@app/helpers/pagination";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import "./Pagination.scss";
import { PaginationNumber } from "./PaginationNumber";

const defaultStartingPage = 1;
const pageKey = "page";

interface PaginationProps {
  totalPages: number;
  handlePageChange: (page: number) => void;
  pageInfo: PageInfoItem[];
  type?: PaginationType;
  sticky?: boolean;
  startingPage?: number;
  maxNumberOfVisiblePages?: number;
}

export const Pagination = ({
  totalPages,
  handlePageChange,
  pageInfo,
  type = PaginationType.NONE,
  sticky = true,
  startingPage,
  maxNumberOfVisiblePages = 9
}: PaginationProps) => {
  const isMounted = useRef(false);
  const { urlHashDetail, updateUrlHash } = useUrlHash();

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(defaultStartingPage);
  const pages = useMemo(() => _.range(1, totalPages + 1), [totalPages]);

  const handlePageUpdate = useCallback(
    page => {
      updateUrlHash(pageKey, page);
    },
    [updateUrlHash]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const page = urlHashDetail.get("page");
    if (isMounted.current) {
      const updatedPage =
        page && !isNaN(+page) && pageInfo[+page] ? +page : defaultStartingPage;
      setCurrentPage(updatedPage);
      handlePageChange?.(updatedPage);
    }
    // Working to find a better solution for ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlHashDetail.get("page")]);

  useEffect(() => {
    if (isMounted.current) {
      if (startingPage) {
        handlePageUpdate(startingPage);
      }
    }
    // Working to find a better solution for ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingPage]);

  const visiblePages = useMemo(
    () =>
      paginationHelper.getVisiblePages(
        currentPage,
        totalPages,
        maxNumberOfVisiblePages
      ),
    [currentPage, maxNumberOfVisiblePages, totalPages]
  );

  const progressMessage = useMemo(
    () => paginationHelper.getPaginationMessage(t, pageInfo, type, totalPages),
    [pageInfo, t, totalPages, type]
  );

  const renderPageNumbers = useMemo(() => {
    let previousPage = 0;
    return (
      <Inline key="pagination-number-list" className="pagination__numbers">
        {pages
          ?.filter(pageNum => visiblePages.includes(pageNum))
          ?.map(page => {
            const breaker = page - 1 !== previousPage ? <div>...</div> : null;
            previousPage = page;
            return (
              <React.Fragment key={`pagination-number-${page}`}>
                {breaker}
                <PaginationNumber
                  t={t}
                  page={page}
                  isActive={page === currentPage}
                  information={pageInfo?.[page]}
                  handlePageUpdate={handlePageUpdate}
                  type={type}
                />
              </React.Fragment>
            );
          })}
      </Inline>
    );
  }, [currentPage, handlePageUpdate, pageInfo, pages, t, type, visiblePages]);

  return (
    <Stack
      key="pagination"
      className={[
        "pagination",
        returnStringIfTrue(sticky, "pagination--sticky")
      ]}
      alignment="center"
    >
      <Inline
        key="pagination-context"
        className="pagination__content"
        alignment="center"
      >
        <Box key="pagination-prev" className="pagination__prev">
          {currentPage > 1 && (
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => handlePageUpdate(currentPage - 1)}
              iconName="chevron_left"
              label={t("common:ui.pagination.previous")}
            />
          )}
        </Box>
        {renderPageNumbers}
        <Box key="pagination-next" className="pagination__next">
          {currentPage < totalPages && (
            <Button
              variant={ButtonVariant.TEXT}
              className="pagination__next"
              onClick={() => handlePageUpdate(currentPage + 1)}
              iconName="chevron_right"
              label={t("common:ui.pagination.next")}
              iconOnRight
            />
          )}
        </Box>
      </Inline>
      {progressMessage && (
        <Box key="pagination-summary" className="pagination__summary">
          {progressMessage}
        </Box>
      )}
    </Stack>
  );
};
