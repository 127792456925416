import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button, ButtonVariant } from "@atoms/Button";

import ConditionalField from "@components/atoms/Form/ConditionalField/index";

import CheckBoxGroup from "./CheckBoxGroup/CheckBoxGroup";
import CheckListItem from "./CheckListItem/CheckListItem";
import Checkbox from "./Checkbox/Checkbox";
import DateField from "./DateField/DateField";
import Dropdown from "./Dropdown/Dropdown";
import "./Form.scss";
import FormWithProvider from "./FormWithProvider/FormWithProvider";
import FormWithoutProvider from "./FormWithoutProvider/FormWithoutProvider";
import HiddenInput from "./HiddenInput/HiddenInput.tsx";
import Multiselect from "./Multiselect/Multiselect";
import NumberField from "./NumberField/NumberField.tsx";
import RadioGroup from "./RadioGroup/RadioGroup";
import TextArea from "./TextArea/TextArea";
import TextField from "./TextField/TextField";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch.tsx";
import UploadDocuments from "./UploadDocuments/UploadDocuments";
import UploadImages from "./UploadImages/UploadImages";

function Form(props) {
  const { t } = useTranslation();
  const context = props.context ?? {};
  const resolver =
    props.customResolver ?? yupResolver(props.yupSchema || yup.object({}));
  const formOptions = {
    resolver,
    context,
    defaultValues: props.defaultValues || {},
    mode: "onChange"
  };
  const methods = props.formMethods;

  const actionButtons = formState => {
    const { isValid } = formState ?? { isValid: true };

    const template = props.renderPageActions?.(isValid) ?? (
      <div className="ot-form__action-buttons">
        {props.handleCancel && (
          <span className="ot-form__action-buttons--cancel">
            <Button
              variant={ButtonVariant.TEXT}
              label={props.cancelLabel ?? t("common:ui.forms.cancel.label")}
              onClick={props.handleCancel}
            />
          </span>
        )}
        <span className="ot-form__action-buttons--submit">
          <Button
            type="submit"
            variant={props.submitButtonVariant ?? ButtonVariant.PRIMARY}
            label={props.submitLabel ?? t("common:ui.forms.submit.label")}
            disabled={props.disabled || !isValid}
            iconName={props.submitIconName}
          />
        </span>
      </div>
    );

    if (props.actionButtonsPortal === null) {
      return "";
    }
    if (props.actionButtonsPortal) {
      return ReactDOM.createPortal(template, props.actionButtonsPortal);
    }
    return template;
  };
  return props.formMethods && props.withoutProvider ? (
    <FormWithoutProvider
      methods={methods}
      handleSubmit={props.handleSubmit}
      actionButtons={actionButtons}
      customFormClassName={props.customFormClassName}
    >
      {props.children}
    </FormWithoutProvider>
  ) : (
    <FormWithProvider
      handleSubmit={props.handleSubmit}
      formOptions={formOptions}
      actionButtons={actionButtons}
      customFormClassName={props.customFormClassName}
    >
      {props.children}
    </FormWithProvider>
  );
}

Form.defaultProps = {
  withoutProvider: false,
  autoComplete: "off",
  handleSubmit: () => {}
};

Form.propTypes = {
  withoutProvider: PropTypes.bool,
  formMethods: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  yupSchema: PropTypes.object,
  submitLabel: PropTypes.string,
  submitButtonVariant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "text",
    "text-primary",
    "danger"
  ]),
  submitIconName: PropTypes.string,
  cancelLabel: PropTypes.string,
  actionButtonsPortal: PropTypes.object,
  defaultValues: PropTypes.object,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  customFormClassName: PropTypes.string,
  children: PropTypes.node,
  renderPageActions: PropTypes.func
};

Form.TextField = TextField;
Form.TextArea = TextArea;
Form.Dropdown = Dropdown;
Form.Multiselect = Multiselect;
Form.DateField = DateField;
Form.UploadDocuments = UploadDocuments;
Form.UploadImages = UploadImages;
Form.RadioGroup = RadioGroup;
Form.ConditionalField = ConditionalField;
Form.Checkbox = Checkbox;
Form.CheckBoxGroup = CheckBoxGroup;
Form.CheckListItem = CheckListItem;
Form.HiddenInput = HiddenInput;
Form.ToggleSwitch = ToggleSwitch;
Form.NumberField = NumberField;

export default Form;
