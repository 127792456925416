import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  useActionItemFilters,
  useAuthUser,
  useGetProjectMembers,
  useUIConfig
} from "@shared/hooks";

import { expectedActionIndicators } from "@app/helpers";
import { Project, ProjectMembers, User } from "@app/types";

const filterTypes = systemConstants.dashboard.actionItemsFilterType;

const getHostTeam = (members: ProjectMembers) => [
  ...(members?.hostUsers ?? [])
];
const getClientTeam = (members: ProjectMembers) => [
  ...(members?.clientUsers ?? [])
];

const lowerCaseSort = (a: string, b: string) => a.localeCompare(b);

const filterValueEquals = (filterVal: string[], memberList: User[]) => {
  if (filterVal.length !== memberList.length) {
    return false;
  }
  filterVal.sort(lowerCaseSort);
  const m = memberList.map(member => member.name).sort(lowerCaseSort);
  return filterVal.every((val, index) => val === m[index]);
};

const UserFilterTypes = {
  None: 1,
  MyActions: 2,
  ClientTeamActions: 3,
  HostTeamActions: 4
};

export function useRequestFilter(project: Project) {
  const { user } = useAuthUser();
  const { uiConfig } = useUIConfig();
  const actionIndicators = expectedActionIndicators();
  const { members } = useGetProjectMembers(project);
  const [currentUserFilter, setCurrentUserFilter] = useState(
    UserFilterTypes.None
  );
  // This is to prevent the table header rerender after selecting assigned to/ user filter
  const currentUserFilterRef = useRef(currentUserFilter);

  useEffect(() => {
    currentUserFilterRef.current = currentUserFilter;
  }, [currentUserFilter]);

  const {
    onChangeRequestFilter,
    onChangeAssignedToUsers,
    onChangeUserFilterType,
    resetActionitemTableFilter,
    requestFilter,
    userFilterType,
    assignedToUsers,
    tableFilters,
    onChangeTableFilters
  } = useActionItemFilters();

  const userIsOnlyUserInTeam = useCallback(
    teamType => {
      if (teamType === "host") {
        return (
          members.hostUsers.length === 1 && members.hostUsers[0].id === user.id
        );
      } else if (teamType === "client") {
        return (
          members.clientUsers.length === 1 &&
          members.clientUsers[0].id === user.id
        );
      }
    },
    [members.hostUsers, members.clientUsers, user.id]
  );

  const getMyUser = useCallback(() => {
    const userInfo = utilities.stripMethodsFromObject(user);
    return [userInfo];
  }, [user]);

  useEffect(() => {
    if (userFilterType) {
      setCurrentUserFilter(structuredClone(userFilterType));
    }
  }, [userFilterType]);

  const filterUsers = useMemo(() => assignedToUsers, [assignedToUsers]);

  const actionItemsFilter = useMemo(() => {
    return (
      structuredClone(requestFilter) ??
      ({} as { dueDate?: string; status?: string })
    );
  }, [requestFilter]);

  const onChangeStatusFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.status) {
        delete updatedFilter.status;
      } else {
        updatedFilter.status = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onChangeLabelsFilter = useCallback(
    labels => onChangeTableFilters(labels, "labels"),
    [onChangeTableFilters]
  );

  const onChangeWorkflowFilter = useCallback(
    workflowSteps => onChangeTableFilters(workflowSteps, "workflowStep"),
    [onChangeTableFilters]
  );

  const onChangeQueryTypeFilter = useCallback(
    queryType => onChangeTableFilters(queryType, "queryType"),
    [onChangeTableFilters]
  );

  const onChangeCreatedByFilter = useCallback(
    createdBy => onChangeTableFilters(createdBy, "createdBy"),
    [onChangeTableFilters]
  );

  const onChangeDueDateFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.dueDate) {
        delete updatedFilter.dueDate;
      } else {
        updatedFilter.dueDate = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onClickMyActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.MyActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.MyActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getMyUser());
  }, [
    currentUserFilter,
    getMyUser,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickClientTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.ClientTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.ClientTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getClientTeam(members));
  }, [
    currentUserFilter,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickHostTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.HostTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.HostTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getHostTeam(members));
  }, [
    currentUserFilter,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onAssignToFilterValueChanged = useCallback(
    (selectedUsers: { value: string }[]) => {
      const filterValue = selectedUsers.map(u => u.value);
      onChangeAssignedToUsers(selectedUsers);
      if (filterValueEquals(filterValue, [user])) {
        if (
          (userIsOnlyUserInTeam("client") &&
            currentUserFilterRef.current ===
              UserFilterTypes.ClientTeamActions) ||
          (userIsOnlyUserInTeam("host") &&
            currentUserFilterRef.current === UserFilterTypes.HostTeamActions)
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.MyActions) {
          onChangeUserFilterType(UserFilterTypes.MyActions);
          return;
        }
      }
      if (filterValueEquals(filterValue, members.hostUsers)) {
        if (
          userIsOnlyUserInTeam("host") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.HostTeamActions) {
          onChangeUserFilterType(UserFilterTypes.HostTeamActions);
          return;
        }
      }
      if (filterValueEquals(filterValue, members.clientUsers)) {
        if (
          userIsOnlyUserInTeam("client") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }
        if (
          currentUserFilterRef.current !== UserFilterTypes.ClientTeamActions
        ) {
          onChangeUserFilterType(UserFilterTypes.ClientTeamActions);
          return;
        }
      }
      if (currentUserFilterRef.current !== UserFilterTypes.None) {
        onChangeUserFilterType(UserFilterTypes.None);
      }
    },
    [
      members.clientUsers,
      members.hostUsers,
      onChangeAssignedToUsers,
      onChangeUserFilterType,
      user,
      userIsOnlyUserInTeam
    ]
  );

  const setOldFilters = useCallback(
    (filters: { type: string; members: ProjectMembers }) => {
      const updatedFilter = structuredClone(actionItemsFilter);
      resetActionitemTableFilter();
      const userTypes = user?.isHostUser
        ? UserFilterTypes.HostTeamActions
        : UserFilterTypes.ClientTeamActions;
      const filteredUsers = user?.isHostUser
        ? getHostTeam(filters.members)
        : getClientTeam(filters.members);
      switch (filters.type) {
        case filterTypes.myPastDueItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsPastDueItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myUpcomingItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsUpcomingItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myActiveItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamActiveItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myOpenItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.open.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myRespondedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.responded.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamOpenItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.open.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myTeamRespondedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.responded.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myClosedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.closed.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamClosedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.closed.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        default:
          setCurrentUserFilter(UserFilterTypes.None);
      }
    },
    [
      actionIndicators.overdue.key,
      actionIndicators.upcoming.key,
      actionItemsFilter,
      getMyUser,
      onChangeAssignedToUsers,
      onChangeRequestFilter,
      onChangeUserFilterType,
      resetActionitemTableFilter,
      uiConfig.indicators.closed.key,
      uiConfig.indicators.open.key,
      uiConfig.indicators.responded.key,
      user?.isHostUser
    ]
  );

  return {
    actionItemsFilter,
    currentUserFilter,
    filterUsers,
    onAssignToFilterValueChanged,
    onChangeCreatedByFilter,
    onChangeDueDateFilter,
    onChangeLabelsFilter,
    onChangeWorkflowFilter,
    onChangeQueryTypeFilter,
    onChangeStatusFilter,
    onClickClientTeamActionsFilter,
    onClickHostTeamActionsFilter,
    onClickMyActionsFilter,
    setOldFilters,
    tableFilters
  };
}
