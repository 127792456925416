import { systemConstants } from "@shared/constants/systemConstants";

import { manageProjectQueriesConstants } from "../constants";
import { manageProjectQueriesService } from "../services";

export const manageProjectQueriesActions = {
  getProjectQueries,
  getProjectQuery,
  getMyQueryCount,
  addProjectQuery,
  updateProjectQuery,
  updateProjectQueries,
  clearGetProjectQuery,
  clearAddedQuery,
  clearUpdatedQuery,
  clearError,
  reset
};

function getProjectQuery(queryId) {
  return dispatch => {
    dispatch(request());

    manageProjectQueriesService.getQuery(queryId).then(
      query => {
        dispatch(success(query));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERY_REQUEST
    };
  }
  function success(query) {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERY_SUCCESS,
      query
    };
  }
  function failure(error) {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERY_FAILURE,
      error
    };
  }
}

function getMyQueryCount(project) {
  return dispatch => {
    dispatch(request());

    manageProjectQueriesService.getMyQueryCount(project).then(
      count => {
        dispatch(success(count));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectQueriesConstants.GET_MY_QUERY_COUNT_REQUEST
    };
  }
  function success(queries) {
    return {
      type: manageProjectQueriesConstants.GET_MY_QUERY_COUNT_SUCCESS,
      queries
    };
  }
  function failure(error) {
    return {
      type: manageProjectQueriesConstants.GET_MY_QUERY_COUNT_FAILURE,
      error
    };
  }
}

function getProjectQueries({ id: projectId }) {
  return dispatch => {
    dispatch(request());

    manageProjectQueriesService.getProjectQueries({ id: projectId }).then(
      queries => {
        dispatch(success(queries));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERIES_REQUEST
    };
  }
  function success(queries) {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERIES_SUCCESS,
      queries
    };
  }
  function failure(error) {
    return {
      type: manageProjectQueriesConstants.GET_PROJECT_QUERIES_FAILURE,
      error
    };
  }
}

function addProjectQuery(query) {
  return dispatch => {
    dispatch(request());

    manageProjectQueriesService.addProjectQuery(query).then(
      addedQuery => {
        dispatch(success(addedQuery));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectQueriesConstants.ADD_PROJECT_QUERY_REQUEST
    };
  }
  function success(addedQuery) {
    return {
      type: manageProjectQueriesConstants.ADD_PROJECT_QUERY_SUCCESS,
      addedQuery
    };
  }
  function failure(error) {
    return {
      type: manageProjectQueriesConstants.ADD_PROJECT_QUERY_FAILURE,
      error
    };
  }
}

function updateProjectQuery(query, cb) {
  return dispatch => {
    dispatch(request());

    manageProjectQueriesService.updateProjectQuery(query).then(
      updatedQuery => {
        dispatch(success(updatedQuery));
        cb?.();
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_REQUEST
    };
  }
  function success(updatedQuery) {
    return {
      type: manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_SUCCESS,
      updatedQuery
    };
  }
  function failure(error) {
    return {
      type: manageProjectQueriesConstants.UPDATE_PROJECT_QUERY_FAILURE,
      error
    };
  }
}

function updateProjectQueries({ queries, incomingQueries, updateType }) {
  return dispatch => {
    if (updateType === systemConstants.pubsub.added) {
      const updatedQueries = [...queries, ...incomingQueries];
      dispatch(success(updatedQueries));
    }
    if (updateType === systemConstants.pubsub.updated) {
      const updatedQueries = queries.map(q => {
        const matchingQuery = incomingQueries.find(iq => q.id === iq.id);
        if (matchingQuery) {
          return matchingQuery;
        }
        return q;
      });
      dispatch(success(updatedQueries));
    }
  };

  function success(updatedQueries) {
    return {
      type: manageProjectQueriesConstants.UPDATE_PROJECT_QUERIES_SUCCESS,
      updatedQueries
    };
  }
}

function clearGetProjectQuery() {
  return { type: manageProjectQueriesConstants.CLEAR_GET_PROJECT_QUERY };
}

function clearUpdatedQuery() {
  return { type: manageProjectQueriesConstants.CLEAR_UPDATED_QUERY };
}

function clearAddedQuery() {
  return { type: manageProjectQueriesConstants.CLEAR_ADDED_QUERY };
}

function clearError() {
  return { type: manageProjectQueriesConstants.CLEAR_ERROR };
}

function reset() {
  return { type: manageProjectQueriesConstants.RESET };
}
