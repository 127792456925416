import { handleErrorResponse, handleResponse } from ".";
import { systemConstants } from "../constants";
import { authHeader } from "../helpers";

const baseURL = systemConstants.serverURL;

export const manageArticlesService = {
  getArticles,
  addArticle,
  updateArticle
};

function getArticles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/articles`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addArticle(article) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ ...article })
  };

  return fetch(`${baseURL}/api/articles`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateArticle(article) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ ...article })
  };

  return fetch(`${baseURL}/api/articles/${article.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
