import React, { useMemo, useRef, useState } from "react";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import { Box } from "@fermions";

import { Icon } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import "./WebsheetTabsDropdown.scss";

const WebsheetTabsDropdown = ({
  sheetNames,
  handleClickTab,
  currentSheetName
}) => {
  const items = useMemo(
    () =>
      sheetNames.map((name, index) => ({
        id: websheetUtilities.getWebsheetDropdownItemId(index),
        name,
        index
      })),
    [sheetNames]
  );
  const currentItem = useMemo(
    () => items.find(item => item.name === currentSheetName),
    [currentSheetName, items]
  );

  const websheetTabDropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box className="websheet-tabs-dropdown">
      <Box ref={websheetTabDropdownRef} height="100" alignment="center">
        <Icon name="menu" onClick={() => setIsOpen(!isOpen)} />
      </Box>
      <DropdownList
        items={items}
        selectedValues={[currentItem]}
        onClickItem={item => {
          handleClickTab(item.name, item.index);
          setIsOpen(false);
        }}
        positioning="top"
        parentRef={websheetTabDropdownRef}
        isOpen={isOpen}
        minWidthOverride={200}
      />
    </Box>
  );
};

export default WebsheetTabsDropdown;
