import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const documentMimesService = createApi({
  reducerPath: "documentMimesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["getSupportedMimes"],
  endpoints: builder => ({
    getSupportedMimes: builder.query({
      query: ({ type }) => ({
        url: `/api/documentMimes?type=${type}`,
        method: "GET"
      })
    })
  })
});

export const { useGetSupportedMimesQuery } = documentMimesService;
