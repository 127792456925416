import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import { useAuthUser } from "@shared/hooks/useAuthUser";

import { useDataTable } from "@app/hooks";

import { Text } from "@atoms/Text";

import Avatar from "@components/atoms/Avatar";
import DataTable from "@components/molecules/DataTable";

const actions = {
  removeUserFromEngagement: "Remove User"
};

const tableConstants = {
  email: "Email",
  name: "Name",
  role: "Role"
};

const EngagementMembersDataTable = props => {
  const { members, handleRemoveFromEngagement } = props;
  const { user } = useAuthUser();
  const { findItem, createColumn, createColumnForDropdownMenu } =
    useDataTable(members);
  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });

  const handleAction = useCallback(
    (action, actionItemId) => {
      setRowDisplay({ rowId: -1, display: false });
      if (action === actions.removeUserFromEngagement) {
        const item = findItem(actionItemId);
        handleRemoveFromEngagement(item);
      }
    },
    [findItem, handleRemoveFromEngagement]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const action = menuItem.name;
      const actionItemId = cell.row.original.id;
      handleAction(action, actionItemId);
    },
    [handleAction]
  );

  const getActionsMenuForMember = useCallback(() => {
    if (!handleRemoveFromEngagement || !user.isHostUser) {
      return [];
    } else {
      return [{ name: actions.removeUserFromEngagement }];
    }
  }, [handleRemoveFromEngagement, user.isHostUser]);

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const columns = useMemo(() => {
    const result = [
      createColumn({
        Header: "",
        accessor: "id",
        width: 75,
        fixedWidth: true,
        Cell: ({ cell }) => {
          const userValue = findItem(cell.value);
          return <Avatar user={userValue} size="small" />;
        }
      }),
      createColumn({
        Header: tableConstants.name,
        accessor: "name",
        width: 150,
        Cell: ({ value }) => <Text text={value} inline={true} truncate />
      }),
      createColumn({
        Header: tableConstants.email,
        accessor: "email",
        width: 150
      }),
      createColumn({
        Header: tableConstants.role,
        accessor: "role",
        width: 150
      })
    ];

    if (handleRemoveFromEngagement) {
      result.push(
        createColumnForDropdownMenu({
          accessor: "actionsMenu",
          width: 55,
          fixedWidth: true,
          onClickHandler: dropdownMenuActionHandler,
          displayCell: cellVisibilityHandler
        })
      );
    }

    return result;
  }, [
    createColumn,
    handleRemoveFromEngagement,
    findItem,
    createColumnForDropdownMenu,
    dropdownMenuActionHandler,
    cellVisibilityHandler
  ]);

  const data = React.useMemo(
    () =>
      members.map(item => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
          role: item.role.name,
          actionsMenu: getActionsMenuForMember(item)
        };
      }),
    [getActionsMenuForMember, members]
  );

  return (
    <DataTable
      className="engagement-members-table"
      columns={columns}
      data={data}
      onMouseEnterRow={rowId => setRowDisplay({ rowId, display: true })}
      onMouseLeaveRow={rowId => setRowDisplay({ rowId, display: false })}
    ></DataTable>
  );
};

EngagementMembersDataTable.defaultProps = {};

EngagementMembersDataTable.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      avatar: PropTypes.shape({
        image: PropTypes.string.isRequired
      })
    })
  ),
  handleRemoveFromEngagement: PropTypes.func
};

export default EngagementMembersDataTable;
