import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface IPubSubState {
  socket: WebSocket | null;
  setSocket: (socket: WebSocket | null) => void;
  setupSocket: (options: { url: string }) => void;
}
export const usePubSubStore = create<IPubSubState>()(
  devtools(
    set => ({
      socket: null,
      setSocket: socket => set(() => ({ socket })),
      setupSocket: ({ url }) => {
        if (!usePubSubStore.getState().socket) {
          const newSocket = new WebSocket(url);
          set(() => ({ socket: newSocket }));
        }
      }
    }),
    {
      name: "ot-pub-sub-store"
    }
  )
);
