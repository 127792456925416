export const clientDocumentConstants = {
  RESET: "RESET_ALL_CLIENT_DOCUMENTS",

  GET_CLIENT_DOCUMENTS_REQUEST: "GET_CLIENT_DOCUMENTS_REQUEST",
  GET_CLIENT_DOCUMENTS_SUCCESS: "GET_CLIENT_DOCUMENTS_SUCCESS",
  GET_CLIENT_DOCUMENTS_FAILURE: "GET_CLIENT_DOCUMENTS_FAILURE",
  GET_CLIENT_DOCUMENTS_RESET: "GET_CLIENT_DOCUMENTS_RESET",

  DELETE_CLIENT_DOCUMENTS_REQUEST: "DELETE_CLIENT_DOCUMENTS_REQUEST",
  DELETE_CLIENT_DOCUMENTS_SUCCESS: "DELETE_CLIENT_DOCUMENTS_SUCCESS",
  DELETE_CLIENT_DOCUMENTS_FAILURE: "DELETE_CLIENT_DOCUMENTS_FAILURE",
  DELETE_CLIENT_DOCUMENTS_RESET: "DELETE_CLIENT_DOCUMENTS_RESET",

  UPDATE_CLIENT_DOCUMENTS_PROPERTIES_REQUEST:
    "UPDATE_CLIENT_DOCUMENTS_PROPERTIES_REQUEST",
  UPDATE_CLIENT_DOCUMENTS_PROPERTIES_SUCCESS:
    "UPDATE_CLIENT_DOCUMENTS_PROPERTIES_SUCCESS",
  UPDATE_CLIENT_DOCUMENTS_PROPERTIES_FAILURE:
    "UPDATE_CLIENT_DOCUMENTS_PROPERTIES_FAILURE",
  UPDATE_CLIENT_DOCUMENTS_PROPERTIES_RESET:
    "UPDATE_CLIENT_DOCUMENTS_PROPERTIES_RESET",

  UPLOAD_CLIENT_DOCUMENT_REQUEST: "UPLOAD_CLIENT_DOCUMENT_REQUEST",
  UPLOAD_CLIENT_DOCUMENT_SUCCESS: "UPLOAD_CLIENT_DOCUMENT_SUCCESS",
  UPLOAD_CLIENT_DOCUMENT_FAILURE: "UPLOAD_CLIENT_DOCUMENT_FAILURE",
  UPLOAD_CLIENT_DOCUMENT_RESET: "UPLOAD_CLIENT_DOCUMENT_RESET",

  GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_REQUEST:
    "GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_REQUEST",
  GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS:
    "GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS",
  GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_FAILURE:
    "GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_FAILURE",

  GET_CLIENT_DOCUMENT_REVISIONS_REQUEST:
    "GET_CLIENT_DOCUMENT_REVISIONS_REQUEST",
  GET_CLIENT_DOCUMENT_REVISIONS_SUCCESS:
    "GET_CLIENT_DOCUMENT_REVISIONS_SUCCESS",
  GET_CLIENT_DOCUMENT_REVISIONS_FAILURE:
    "GET_CLIENT_DOCUMENT_REVISIONS_FAILURE",
  GET_CLIENT_DOCUMENT_REVISIONS_RESET: "GET_CLIENT_DOCUMENT_REVISIONS_RESET",

  UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_REQUEST:
    "UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_REQUEST",
  UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_SUCCESS:
    "UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_SUCCESS",
  UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_FAILURE:
    "UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_FAILURE",
  UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_RESET:
    "UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_RESET",

  RESTORE_CLIENT_DOCUMENT_REQUEST: "RESTORE_CLIENT_DOCUMENT_REQUEST",
  RESTORE_CLIENT_DOCUMENT_SUCCESS: "RESTORE_CLIENT_DOCUMENT_SUCCESS",
  RESTORE_CLIENT_DOCUMENT_FAILURE: "RESTORE_CLIENT_DOCUMENT_FAILURE"
};
