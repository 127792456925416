import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import {
  useDeleteProjectMutation,
  useGetProjectByIdQuery,
  useToasts
} from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";

import ModalContentTemplate from "@components/templates/ModalContentTemplate";

import "./DraftProjectDeleteModal.scss";

interface DraftProjectDeleteModalProps {
  projectId?: number;
  bannerModal?: boolean;
  handleCloseModal: () => void;
}

const DraftProjectDeleteModal = ({
  projectId,
  bannerModal,
  handleCloseModal
}: DraftProjectDeleteModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteProject] = useDeleteProjectMutation();

  const { showProjectDeleteToast, showProjectDeleteFailedToast } = useToasts();

  const { data: projectData } = useGetProjectByIdQuery(
    { projectId },
    { skip: !projectId }
  );

  const handleDelete = useCallback(async () => {
    if (!projectId) {
      return;
    }
    deleteProject({ projectId })
      .unwrap()
      .then(() => {
        showProjectDeleteToast();
      })
      .catch(err => {
        showProjectDeleteFailedToast(getErrorMessage(err, t));
      })
      .finally(
        !bannerModal
          ? handleCloseModal
          : () => navigate(routeConstants.clientDashboard)
      );
  }, [projectId]);

  return (
    <ModalContentTemplate
      header={{
        title: t("common:ui.projects.draft.delete.title", {
          projectName: projectData?.name
        })
      }}
      body={{
        message: t("common:ui.projects.draft.delete.warning")
      }}
      footer={{
        submitText: t("common:ui.forms.yes.label"),
        handleSubmit: handleDelete,
        disableSubmit: !projectData?.id || !projectId,
        cancelText: t("common:ui.forms.no.label"),
        handleCancel: handleCloseModal
      }}
      modalClassName="draft-project-delete-modal"
    />
  );
};

export default DraftProjectDeleteModal;
