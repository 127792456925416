import React, { ReactElement, isValidElement } from "react";

import { Box, Inline, Stack } from "@fermions";

import { Icon } from "@atoms/Icon";

import { ToastIcon, ToastIconProps } from "./ToastIcon/ToastIcon";
import "./ToastNotification.scss";

export { ToastType } from "./ToastIcon/ToastIcon";
interface ToastNotificationProps extends ToastIconProps {
  source?: string;
  title: string | ReactElement;
  description?: string | Element;
  actions?: ReactElement;
  closeToast?: () => void;
}

export const ToastNotification = ({
  source,
  title,
  description,
  actions,
  type,
  closeToast
}: ToastNotificationProps) => {
  const getDescription = () => {
    if (isValidElement(description) || typeof description === "string") {
      return description;
    }
    return <></>;
  };

  const getTitle = () => {
    if (isValidElement(title) || typeof title === "string") {
      return title;
    }
    return <></>;
  };

  return (
    <Inline className="ot-toast-notification" alignment="center">
      <ToastIcon type={type} />
      <Stack width="fill" gap="025" alignment="left">
        {source && (
          <div className="ot-toast-notification__source">{source}</div>
        )}
        <Stack width="fill" gap="000" alignment="left">
          <div className="ot-toast-notification__title">{getTitle()}</div>
          {description && (
            <div className="ot-toast-notification__description">
              {getDescription()}
            </div>
          )}
        </Stack>
        {actions ?? <></>}
      </Stack>
      <Box width="fit" alignment="center">
        <Icon
          className="ot-toast-notification__close"
          onClick={closeToast}
          name="close"
        />
      </Box>
    </Inline>
  );
};
