import React from "react";

import PropTypes from "prop-types";

import { Icon, IconColor, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import "./CircleIndicator.scss";

const StatusIndicator = props => {
  return (
    <Icon
      name="lens"
      className={`circle-indicator circle-indicator--color-${props.colorScheme}`}
      designStyle={IconDesignStyle.MATERIAL_ICONS}
      color={IconColor.INHERIT}
      fillStyle={IconFillStyle.FILLED}
    />
  );
};

StatusIndicator.defaultProps = {
  colorScheme: "dark"
};

StatusIndicator.propTypes = {
  colorScheme: PropTypes.oneOf([
    "dark",
    "success",
    "error",
    "warning",
    "disabled"
  ]).isRequired
};

export default StatusIndicator;
