import React from "react";

import PropTypes from "prop-types";

import "./SplitSectionTemplate.scss";

/**
 * Renders two components side-by-side with a vertical divider between them.
 * The vertical divider's height will be equal to the taller of the two elements
 *
 * @param   {element} left   On the left-side, takes up space element requires
 * @param   {element} right  On the right-side, fills the remaining space
 */
const SplitSectionTemplate = props => {
  return (
    <div className="split-section-layout__container">
      <div className="split-section-layout__left">{props.left}</div>
      <div className="split-section-layout__divider"></div>
      <div className="split-section-layout__right">{props.right}</div>
    </div>
  );
};

SplitSectionTemplate.defaultProps = {
  growPreference: "right"
};

SplitSectionTemplate.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired
};

export default SplitSectionTemplate;
