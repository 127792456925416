import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { loggedUser } from "@shared/helpers";
import { useUserProfile } from "@shared/hooks";
import { useGetProfileQuery } from "@shared/services";

import { AuthUser } from "@app/types";

export function useAuthUser(props?: { withProfile?: boolean }) {
  const authentication = useSelector(state => state.authentication);
  const { data: profile } = useGetProfileQuery(authentication?.user?.id, {
    skip: !authentication?.user?.id
  });

  const getUserWithInfo = useCallback((): AuthUser => {
    if (authentication?.user?.id) {
      const xRole = profile?.role;
      return {
        ...authentication.user,
        name: `${authentication.user.firstname} ${authentication.user.lastname}`,
        isHostUser: loggedUser.doesBelongToHost(authentication.user),
        isClientUser: loggedUser.doesBelongToClient(authentication.user),
        isMemberOfUserGroup: userGroup =>
          loggedUser.doesBelongToUserGroup(userGroup, authentication.user),
        isLoggedIn: authentication.loggedIn && authentication.verified,
        checkAccess: (resource, accessLevel) =>
          loggedUser.checkAccess(xRole?.permissions, resource, accessLevel),
        xRole
      };
    } else {
      return {
        isHostUser: false,
        isClientUser: false,
        isMemberOfUserGroup: () => false,
        checkAccess: () => false,
        isLoggedIn: false
      };
    }
  }, [
    authentication.loggedIn,
    authentication.user,
    authentication.verified,
    profile?.role
  ]);

  const { userProfile, fetchUserProfile } = useUserProfile();
  const [user, setUser] = useState<AuthUser>(getUserWithInfo());
  const withProfile = props?.withProfile || false;

  useEffect(() => {
    if (authentication.user?.id) {
      setUser(getUserWithInfo);
    }
  }, [
    authentication.user,
    authentication.loggedIn,
    authentication.verified,
    fetchUserProfile,
    userProfile.id,
    getUserWithInfo
  ]);

  useEffect(() => {
    if (user.isLoggedIn && userProfile.id !== user.id && withProfile) {
      fetchUserProfile({ id: user.id });
    }
  }, [fetchUserProfile, user.isLoggedIn, user.id, userProfile.id, withProfile]);

  return { user, userProfile };
}
