import {
  AccessLevel,
  AuthUser,
  Permission,
  ResourceName,
  ResourcePermission,
  Role,
  RoleFormData,
  RoleGet,
  RolePost
} from "@app/types";

export const entityRestrictedDefault = true;
export const oneTeamAIDefault = false;

export const getEntityRestrictedPermissionId = (
  resourcesPermissions: ResourcePermission[]
) => {
  return resourcesPermissions
    ?.find(resource => resource.section === ResourceName.ENTITY_RESTRICTION)
    ?.resources?.[0]?.permissions?.find(
      (permission: Permission) => permission.accessLevel === AccessLevel.READ
    )?.id;
};

export const getOneTeamAIPermissionId = (
  resourcesPermissions: ResourcePermission[]
) => {
  return resourcesPermissions
    ?.find(resource => resource.section === ResourceName.ONETEAM_AI)
    ?.resources?.[0]?.permissions?.find(
      (permission: Permission) => permission.accessLevel === AccessLevel.READ
    )?.id;
};

// NB: this mutates the data object
function sanitizeResourcePermission(
  data: RoleFormData,
  {
    getResourceName,
    isSet,
    permissionIdGetter
  }: {
    getResourceName: () => string;
    isSet: (data: RoleFormData) => boolean;
    permissionIdGetter: () => string;
  }
) {
  const resourceName = getResourceName();
  const permissionId = permissionIdGetter();
  if (permissionId) {
    if (isSet(data)) {
      data.resources[resourceName] = [{ id: permissionId }];
    } else {
      data.resources[resourceName] = [];
    }
  }
  return data;
}

export function constructRoleForBE({
  data,
  existingRole,
  resourcesPermissions = []
}: {
  data: RoleFormData;
  existingRole?: Role;
  resourcesPermissions?: ResourcePermission[];
}): RolePost {
  if (data.type === "HOST") {
    data.resources[ResourceName.ENTITY_RESTRICTION] = [];

    sanitizeResourcePermission(data, {
      getResourceName: () => ResourceName.ONETEAM_AI,
      isSet: (data: RoleFormData) => data.oneTeamAIEnabled,
      permissionIdGetter: () => getOneTeamAIPermissionId(resourcesPermissions)
    });
  } else {
    data.resources[ResourceName.ONETEAM_AI] = [];

    sanitizeResourcePermission(data, {
      getResourceName: () => ResourceName.ENTITY_RESTRICTION,
      isSet: (data: RoleFormData) => data.entityRestricted,
      permissionIdGetter: () =>
        getEntityRestrictedPermissionId(resourcesPermissions)
    });
  }

  return {
    ...(existingRole ? { id: existingRole.id } : {}),
    name: data.name,
    type: data.type,
    description: data.description,
    permissions: Object.values(data.resources || {}).flat() as Permission[],
    properties: {}
  };
}

export function getIsEntityRestricted({
  role,
  resourcesPermissions = []
}: {
  role?: Partial<RoleGet>;
  resourcesPermissions?: ResourcePermission[];
}): boolean | undefined {
  if (role?.type !== "CLIENT") {
    return;
  }
  const entityRestrictedPermissionId =
    getEntityRestrictedPermissionId(resourcesPermissions);

  if (!role || !entityRestrictedPermissionId) {
    return entityRestrictedDefault;
  }

  return role?.permissions?.includes(entityRestrictedPermissionId);
}

export function getIsOneTeamAIEnabled({
  role,
  resourcesPermissions = []
}: {
  role?: Partial<RoleGet>;
  resourcesPermissions?: ResourcePermission[];
}): boolean | undefined {
  if (role?.type !== "HOST") {
    return;
  }

  const oneTeamAIPermissionId = getOneTeamAIPermissionId(resourcesPermissions);
  if (!oneTeamAIPermissionId) {
    return oneTeamAIDefault;
  }

  return role.permissions?.includes(oneTeamAIPermissionId);
}

export const hasProjectAccessRelativeToMembership = (
  user: AuthUser,
  isMember: boolean,
  accessLevel: AccessLevel
) =>
  user.checkAccess(
    isMember
      ? ResourceName.PROJECTS_WITH_MEMBERSHIP
      : ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
    accessLevel
  );
