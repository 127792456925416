import { externalLinkValidationConstants } from "../constants";

const initialState = {
  externalObjectPresent: false,
  loading: false,
  project: null,
  client: null,
  query: null,
  valid: false,
  error: "",
  externalObject: null
};

export function externalLinkValidation(state = initialState, action = {}) {
  switch (action.type) {
    case externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_REQUEST:
      return {
        ...state,
        externalObjectPresent: true,
        loading: true
      };
    case externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        valid: action.validationObject.success,
        project: action.validationObject.externalObject.project,
        client: action.validationObject.externalObject.client,
        query: action.validationObject.externalObject.query,
        externalObject: action.validationObject.externalObject,
        error: ""
      };
    case externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        valid: false,
        project: null,
        client: null,
        externalObject: null,
        error: action.error.key ?? action.error.message
      };
    case externalLinkValidationConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case externalLinkValidationConstants.RESET_EXTERNAL_LINK_OBJECT_STORE:
      return initialState;
    default:
      return state;
  }
}
