import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";

import { keyBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useDidMount,
  useFeatures,
  useGetProjectMembers,
  useProjectQueries,
  useRequestPageNavigator,
  useToasts,
  useUIConfig,
  useUrlHash
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";
import {
  useUpdateActionItemFieldMutation,
  useUpdateWorkflowMutation
} from "@shared/services/actionItemService";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";
import { useGetEngagementTypeByIdQuery } from "@shared/services/engagementTypeService.js";
import { useGetFiltersQuery } from "@shared/services/filtersService";

import { expectedQueryTypes } from "@app/helpers";
import {
  formatQueryType,
  getActionItemTypeConfiguration
} from "@app/helpers/actionItems";
import { sanitizeLastActor } from "@app/helpers/actionItems.js";
import { getErrorMessage } from "@app/helpers/error";
import { Project, Query } from "@app/types";
import { useAppStore } from "@app/useAppStore";
import { useStaticAppStore } from "@app/useStaticAppStore";

import { Button, ButtonSize, ButtonVariant } from "@atoms/Button";

import {
  DataViewSwitcher,
  Switchers as DataViewSwitcherEnum
} from "@molecules/DataViewSwitcher";

import ActionItemsFilterLegend from "@components/molecules/ActionItemsFilterLegend";
import { actionItemsFilters } from "@components/molecules/ActionItemsFilterLegend/ActionItemsFilterLegend";
import AskHelp from "@components/molecules/AskHelp";
import Loading from "@components/molecules/Loading";
import PageFilter from "@components/molecules/PageFilter";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import ActionItemsTable from "@components/organisms/ActionItemsTable";
import AddOrCopyProjectActionItems from "@components/organisms/AddOrCopyProjectActionItems";
import UpdateActionForm from "@components/organisms/UpdateActionForm";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import ActionItemsKanbanView from "../ActionItemsKanbanView/index.js";
import DraftQueryDeleteModal from "../DraftQueryDeleteModal/index.js";
import WorkpaperAudits from "../WorkpaperAudits/WorkpaperAudits.jsx";
import { useRequestFilter } from "./useRequestFilter.tsx";

const UserFilterTypes = {
  None: 1,
  MyActions: 2,
  ClientTeamActions: 3,
  HostTeamActions: 4
};

const statusTypes = systemConstants.project.queries.status;

type Props = {
  project: Project;
  filters: {
    type: string;
  };
};

const ActionItems = (props: Readonly<Props>) => {
  const { t } = useTranslation();
  const { navigateToRequestPage } = useRequestPageNavigator();

  const { projectId } = useParams();
  const { isEnabled } = useFeatures();
  const { user } = useAuthUser();
  const [allQueries, setAllQueries] = useState<Query[]>([]);

  //used for filtering queries, filter options should not change when queries change
  const [allQueriesData, setAllQueriesData] = useState<Query[]>([]);

  const isRequestViewSwitcherEnabled = useMemo(() => {
    return isEnabled(systemConstants.features.requestViewSwitcher);
  }, [isEnabled]);

  const isRequestViewFiltersEnabled = useMemo(() => {
    return isEnabled(systemConstants.features.requestViewFilters);
  }, [isEnabled]);

  const selectedView = useStaticAppStore(state =>
    isRequestViewSwitcherEnabled
      ? state?.requestsDashboard?.viewType
      : DataViewSwitcherEnum.TABLE
  );

  const setSelectedView = useStaticAppStore(
    (state: { setRequestsDashboardViewType: () => void }) =>
      state.setRequestsDashboardViewType
  );

  const setRequestsDashboardFiltersState = useAppStore(
    (state: { setRequestsDashboardFilters: () => void }) =>
      state.setRequestsDashboardFilters
  );

  const requestsDashboardFiltersState = useAppStore(state =>
    projectId ? state.requestsDashboardFilters[projectId ?? ""]?.filters : {}
  );

  const [project, setProject] = useState(props.project);
  const { isProjectMember, members, membersLoading } = useGetProjectMembers(
    props.project
  );

  const { urlHashDetail, clearUrlHash } = useUrlHash();
  const [historyId, setHistoryId] = useState(null);
  const [updateQueryField] = useUpdateActionItemFieldMutation();
  const { showQueryUpdateErrorToast, showSuccess } = useToasts();
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const { startLoading } = useDidMount();
  const [showCreateActionForm, setShowCreateActionForm] = useState(false);
  const [showUpdateActionForm, setShowUpdateActionForm] = useState(false);
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [sortedColumn, setSortedColumn] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [deleteQuery, setDeleteQuery] = useState(null);
  const {
    modalOpen,
    registerModal,
    handleCloseModal,
    handleOpenModal,
    getModalContent
  } = useModalContent();

  const {
    actionItemsFilter,
    currentUserFilter,
    filterUsers,
    onAssignToFilterValueChanged,
    onChangeCreatedByFilter,
    onChangeDueDateFilter,
    onChangeLabelsFilter,
    onChangeWorkflowFilter,
    onChangeQueryTypeFilter,
    onChangeStatusFilter,
    onClickClientTeamActionsFilter,
    onClickHostTeamActionsFilter,
    onClickMyActionsFilter,
    setOldFilters,
    tableFilters = {}
  } = useRequestFilter(props.project);

  const engagementTypeId = project?.engagement?.engagementTypeId;
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId,
      projectId: project?.id
    },
    { skip: engagementTypeId === undefined }
  );

  const { data: currentEngagementType } = useGetEngagementTypeByIdQuery(
    engagementTypeId,
    { skip: !engagementTypeId }
  );

  const { queryTypes } = useMemo(
    () => expectedQueryTypes(actionItemTypes, t),
    [actionItemTypes, t]
  );

  const { queries, fetchQueries, isLoading, error } = useProjectQueries({
    id: projectId
  });
  const { queries: unfilteredQueries } = useProjectQueries(
    {
      id: projectId
    },
    {
      skip: Object.keys(requestsDashboardFiltersState ?? {})?.length === 0
    }
  );

  const [queriesData, setQueriesData] = useState([]);
  const { uiConfig } = useUIConfig();
  const [pillsConfig, setPillsConfig] = useState({
    filter: {
      shape: "rectangle",
      activeType: "outline"
    },
    status: {
      shape: "rectangle",
      activeType: "outline"
    }
  });
  const [filterConfig, setFilterConfig] = useState({
    status: {
      enabled: true,
      filters: ["open", "close"]
    },
    dueDate: {
      enabled: true,
      filters: ["upcoming", "overdue"]
    }
  });
  const [indicatorUiConfig, setIndicatorUiConfig] = useState();

  const renderErrorToastAction = useCallback(
    query => {
      if (query.allowedToEdit) {
        return (
          <Button
            label={t("requests:requests.ui.toastActions.updateQuery.label")}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.MINIMAL}
            onClick={() => {
              setShowUpdateActionForm(true);
            }}
          />
        );
      } else {
        const actionItemConfig = getActionItemTypeConfiguration(
          query.queryType,
          actionItemTypes
        );
        return (
          <Button
            label={t("requests:requests.ui.toastActions.viewQuery.label")}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.MINIMAL}
            onClick={() => {
              navigateToRequestPage(
                query.id,
                query.projectId,
                actionItemConfig?.type,
                {
                  websheetOpensNewWindow: true
                }
              );
            }}
          />
        );
      }
    },
    [actionItemTypes, navigateToRequestPage, t]
  );

  const resetQuery = useCallback(
    (query, error) => {
      setAllQueries(prev => {
        const queries = structuredClone(prev);
        const toResetQuery = queries.find(q => q.id === query.id);
        if (toResetQuery) {
          toResetQuery.isLoading = false;
          toResetQuery.status = query.status;
        }
        return queries;
      });
      setSelectedQuery(query);
      showQueryUpdateErrorToast(
        getErrorMessage(error, t),
        renderErrorToastAction(query)
      );
    },
    [showQueryUpdateErrorToast, t, renderErrorToastAction]
  );

  const handleUpdateWorkflow = useCallback(
    ({ payload, updatedQuery, originalQuery }) => {
      const queryId = updatedQuery.id;
      if (!queryId) {
        return;
      }
      updateWorkflow({
        queryId,
        action: payload
      })
        .unwrap()
        .then(data => {
          const { updatedQuery } = data;
          if (updatedQuery) {
            const updatedQueryIndex = queriesData.findIndex(
              (q: Query) => q.id === queryId
            );
            const updatedQueriesData = [...queriesData];
            updatedQueriesData[updatedQueryIndex] = updatedQuery;
            setQueriesData(updatedQueriesData);
            if (
              updatedQuery.status === statusTypes.closed &&
              payload.action === statusTypes.closed
            ) {
              showSuccess({
                key: t("requests:requests.ui.toastMessages.requestClosed")
              });
            }
          }
        })
        .catch(error => {
          resetQuery(originalQuery, error);
        });
    },
    [updateWorkflow, queriesData, showSuccess, t, resetQuery]
  );

  const handleUpdateQueryStatus = useCallback(
    (query, status, confirmed = false) => {
      if (
        query.configType === systemConstants.actionItemTypes.smartForm ||
        status === statusTypes.closed
      ) {
        const updatedQuery = { ...query, status };
        handleUpdateWorkflow({
          payload: {
            action: status,
            confirmed
          },
          updatedQuery,
          originalQuery: query
        });
      } else {
        updateQueryField({
          queryId: query.id,
          field: "status",
          value: status
        })
          .unwrap()
          .then()
          .catch(error => {
            resetQuery(query, error);
          });
      }
    },
    [handleUpdateWorkflow, resetQuery, updateQueryField]
  );

  const applyFilterType = useCallback(
    filters => {
      //New filter feature would navigate directly from ActionItemsBoardComponent
      if (!isRequestViewFiltersEnabled) {
        setOldFilters(filters);
      }
    },
    [isRequestViewFiltersEnabled, setOldFilters]
  );

  useEffect(() => {
    if (queries) {
      setQueriesData(queries);
    }
  }, [queries]);

  useEffect(() => {
    if (props.filters?.type) {
      applyFilterType(props.filters);
    }
  }, [applyFilterType, props.filters, members]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillsConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (uiConfig?.indicators) {
      setIndicatorUiConfig(uiConfig.indicators);
    }
  }, [uiConfig?.indicators]);

  useEffect(() => {
    if (uiConfig?.actionItemFilters) {
      setFilterConfig(uiConfig.actionItemFilters);
    }
  }, [uiConfig?.actionItemFilters]);

  const enableIndicatorIcon = useMemo(
    () => isEnabled(systemConstants.features.indicatorIcon),
    [isEnabled]
  );
  const enableIndicatorPill = useMemo(
    () => isEnabled(systemConstants.features.indicatorPill),
    [isEnabled]
  );

  useEffect(() => {
    if (props.project) {
      setProject(props.project);
    }
  }, [props.project]);

  useEffect(() => {
    if (deleteQuery) {
      registerModal?.("draftQueryDelete", () => (
        <DraftQueryDeleteModal
          query={deleteQuery}
          handleCloseModal={() => {
            setDeleteQuery(null);
            handleCloseModal();
          }}
        />
      ));
    }
  }, [registerModal, deleteQuery, handleCloseModal]);

  useLayoutEffect(() => {
    startLoading(isLoading);
  }, [isLoading, startLoading]);

  const projectError = (msg: string) => {
    setErrorMessage(msg);
    setShowProjectAccessModal(true);
  };

  const handleAddQuery = useCallback(() => {
    isProjectMember(
      user,
      t("requests:requests.ui.requestListPage.accessRequiredAction"),
      () => setShowCreateActionForm(true),
      projectError
    );
  }, [isProjectMember, t, user]);

  const handleUpdateQuery = useCallback(
    query => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionEditRequest"
        ),
        () => {
          setShowUpdateActionForm(true);
          setSelectedQuery(query);
        },
        projectError
      );
    },
    [isProjectMember, user, t]
  );

  const handleCloseQuery = useCallback(
    query => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionCloseRequest"
        ),
        () => {
          handleUpdateQueryStatus(query, statusTypes.closed);
        },
        projectError
      );
    },
    [isProjectMember, user, t, handleUpdateQueryStatus]
  );

  const handleReopenQuery = useCallback(
    query => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionReopenRequest"
        ),
        () => {
          handleUpdateQueryStatus(query, statusTypes.open);
        },
        projectError
      );
    },
    [isProjectMember, user, t, handleUpdateQueryStatus]
  );

  const handleSelectQuery = useCallback(
    query => {
      const actionItemConfig = getActionItemTypeConfiguration(
        query.queryType,
        actionItemTypes
      );
      navigateToRequestPage(query.id, query.projectId, actionItemConfig?.type, {
        websheetOpensNewWindow: true
      });
    },
    [actionItemTypes, navigateToRequestPage]
  );

  const handleHistoryQuery = useCallback(query => setHistoryId(query.id), []);

  const headingActions = () => {
    return (
      <div>
        <Button
          onClick={handleAddQuery}
          iconName="add"
          label={t(
            "requests:requests.ui.requestListPage.buttonActionCreateRequest"
          )}
          disabled={membersLoading || !members}
        />
      </div>
    );
  };

  const requestActionHandler = useCallback(
    (query, action, confirmed) => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestDetailsPage.accessRequiredActionWorkflow"
        ),
        () => {
          if (confirmed) {
            handleUpdateQueryStatus(query, action, confirmed);
          }
          handleUpdateQueryStatus(query, action);
        },
        projectError
      );
    },
    [isProjectMember, user, t, handleUpdateQueryStatus]
  );

  const actionHandlers = useMemo(
    () => ({
      VIEW: handleSelectQuery,
      EDIT: handleUpdateQuery,
      DELETE: (query: Query) => {
        setDeleteQuery(query);
        handleOpenModal("draftQueryDelete");
      },
      CLOSE: handleCloseQuery,
      HISTORY: handleHistoryQuery,
      CREATE: handleAddQuery,
      REOPEN: handleReopenQuery
    }),
    [
      handleSelectQuery,
      handleUpdateQuery,
      handleCloseQuery,
      handleHistoryQuery,
      handleAddQuery,
      handleReopenQuery,
      handleOpenModal
    ]
  );

  useEffect(() => {
    setAllQueries(queries);
    if (
      !allQueriesData ||
      (queries && queries.length > allQueriesData.length)
    ) {
      setAllQueriesData(queries);
    }
  }, [allQueriesData, queries]);

  const renderActionItemsTableView = () => {
    if (!queries || isLoading) {
      return (
        <Loading
          message={t("requests:requests.ui.requestListPage.loadingRequest")}
        />
      );
    }

    if (!queries.length) {
      return (
        <AskHelp
          message={t("requests:requests.ui.requestListPage.noRequestsPresent")}
        />
      );
    }

    return (
      <ActionItemsTable
        queries={queriesData}
        columnConfig={
          currentEngagementType?.configuration?.uiConfig?.table?.columns
        }
        queryTypes={queryTypes}
        actionItemTypes={actionItemTypes}
        onClickQuery={handleSelectQuery}
        actionHandlers={actionHandlers}
        onAssignToFilterValueChanged={onAssignToFilterValueChanged}
        onQueryTypeFilterChanged={onChangeQueryTypeFilter}
        onCreatedByFilterChanged={onChangeCreatedByFilter}
        onSortedColumnChanged={setSortedColumn}
        onLabelFiltersChanged={onChangeLabelsFilter}
        onWorkflowFiltersChanged={onChangeWorkflowFilter}
        filterLabel={tableFilters.labels}
        filterUsers={filterUsers}
        filterCreatedBy={tableFilters.createdBy}
        filterQueryType={tableFilters.queryType}
        filter={actionItemsFilter}
        sortedColumn={sortedColumn}
        user={user}
        engagementTypeId={engagementTypeId}
        enableIndicatorIcon={enableIndicatorIcon}
        enableIndicatorPill={enableIndicatorPill}
        indicatorUiConfig={indicatorUiConfig}
        hideRequiredByField={
          project?.configuration?.requests?.hideRequiredByField ?? false
        }
        project={project}
        requestViewFiltersEnabled={isRequestViewFiltersEnabled}
      />
    );
  };

  const renderActionItemsKanbanView = () => {
    return (
      <ActionItemsKanbanView
        allQueries={isLoading ? [] : (allQueries ?? [])}
        setAllQueries={setAllQueries}
        unfilteredQueries={unfilteredQueries}
        requestsDashboardFiltersState={requestsDashboardFiltersState}
        actionItemTypes={actionItemTypes}
        requestActionHandler={requestActionHandler}
        onClickQuery={handleSelectQuery}
        actionHandlers={actionHandlers}
        project={project}
        indicatorUiConfig={indicatorUiConfig}
        loading={
          isLoading && t("requests:requests.ui.requestListPage.loadingRequest")
        }
      />
    );
  };

  const primaryContent = () => {
    if (selectedView === DataViewSwitcherEnum.TABLE) {
      return renderActionItemsTableView();
    } else {
      return renderActionItemsKanbanView();
    }
  };

  const actionItemsFilterLegend = () => {
    if (!queries?.length) {
      return <></>;
    }
    return (
      <ActionItemsFilterLegend
        handlers={{
          [actionItemsFilters.STATUS]: onChangeStatusFilter,
          [actionItemsFilters.DUE_DATE]: onChangeDueDateFilter,
          [actionItemsFilters.MY_ACTIONS]: onClickMyActionsFilter,
          [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
            onClickClientTeamActionsFilter,
          [actionItemsFilters.HOST_TEAM_ACTIONS]: onClickHostTeamActionsFilter
        }}
        enabled={{
          [actionItemsFilters.MY_ACTIONS]:
            currentUserFilter === UserFilterTypes.MyActions,
          [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
            currentUserFilter === UserFilterTypes.ClientTeamActions,
          [actionItemsFilters.HOST_TEAM_ACTIONS]:
            currentUserFilter === UserFilterTypes.HostTeamActions
        }}
        labels={{
          [actionItemsFilters.MY_ACTIONS]:
            t("requests:requests.myActionsFilterLabel") ?? "",
          [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
            t("requests:requests.clientTeamActionsFilterLabel") ?? "",
          [actionItemsFilters.HOST_TEAM_ACTIONS]:
            t("requests:requests.hostTeamActionsFilterLabel") ?? ""
        }}
        pillsConfig={pillsConfig}
        indicatorUiConfig={indicatorUiConfig}
        filterConfig={filterConfig}
        status={actionItemsFilter}
        enableIndicatorIcon={enableIndicatorIcon}
        enableIndicatorPill={enableIndicatorPill}
      />
    );
  };

  useEffect(() => {
    if (requestsDashboardFiltersState === undefined) {
      return;
    }
    fetchQueries(requestsDashboardFiltersState);
  }, [fetchQueries, requestsDashboardFiltersState]);

  const handleChange = useCallback(
    filterParams => {
      setRequestsDashboardFiltersState(projectId, filterParams);
    },
    [projectId, setRequestsDashboardFiltersState]
  );

  const handleResetFilter = useCallback(() => {
    setRequestsDashboardFiltersState(projectId, undefined);
    fetchQueries();
  }, [fetchQueries, projectId, setRequestsDashboardFiltersState]);

  const { data: filterData, isLoading: isLoadingFilters } = useGetFiltersQuery({
    projectId
  });

  useEffect(() => {
    if (!urlHashDetail.size) {
      return;
    }
    const filterParams = Object.fromEntries([...urlHashDetail]);

    if (filterParams.status) {
      filterParams.status = [filterParams.status];
    } else {
      filterParams.status = [
        systemConstants.filterParams.open,
        systemConstants.filterParams.responded
      ];
    }

    setRequestsDashboardFiltersState(projectId, filterParams);
    clearUrlHash();
  }, [
    projectId,
    setRequestsDashboardFiltersState,
    urlHashDetail,
    clearUrlHash
  ]);

  const customFormatters = useMemo(() => {
    const entitiesById = keyBy(project?.entities, "externalId");

    return {
      queryType: (queryType: string) => {
        return formatQueryType(queryType, queryTypes, t);
      },
      entities: (externalId: string) => {
        return entitiesById?.[externalId]?.name;
      }
    };
  }, [project?.entities, queryTypes, t]);

  const renderPageFilter = useMemo(() => {
    if (urlHashDetail.size) {
      return <></>;
    }
    const sanitizedFilterData = sanitizeLastActor(filterData, allQueriesData);

    const switcher = isRequestViewSwitcherEnabled ? (
      <DataViewSwitcher
        selectedView={selectedView}
        onChangeView={setSelectedView}
        switchersToEnable={{
          selectedView,
          enabledSwitchers: [
            DataViewSwitcherEnum.KANBAN,
            DataViewSwitcherEnum.TABLE
          ]
        }}
      />
    ) : (
      <></>
    );

    return (
      <PageFilter
        dataViewSwitcher={switcher}
        filtersFromServerConfig={sanitizedFilterData}
        dashboardFiltersState={requestsDashboardFiltersState}
        isLoadingFilters={isLoadingFilters}
        isLoading={isLoading}
        handleChange={handleChange}
        data={allQueriesData}
        customFormatters={customFormatters}
        onResetFilter={handleResetFilter}
      />
    );
  }, [
    urlHashDetail.size,
    filterData,
    allQueriesData,
    isRequestViewSwitcherEnabled,
    selectedView,
    setSelectedView,
    requestsDashboardFiltersState,
    isLoadingFilters,
    isLoading,
    handleChange,
    customFormatters,
    handleResetFilter
  ]);

  const secondaryContent = () => {
    if (!isRequestViewFiltersEnabled) {
      return actionItemsFilterLegend();
    }
    return <>{renderPageFilter}</>;
  };

  const closeActionForms = () => {
    setShowCreateActionForm(false);
    setShowUpdateActionForm(false);
  };

  return (
    <>
      <PageTemplate
        header={{
          title: t("requests:requests.ui.requestListPage.pageTitle"),
          actions: headingActions()
        }}
        body={{
          primary: primaryContent(),
          header: secondaryContent()
        }}
        other={{
          error: getErrorMessage(error, t),
          project
        }}
        modal={{
          open: modalOpen,
          content: getModalContent()
        }}
      />
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={errorMessage}
        handleClose={() => {
          setShowProjectAccessModal(false);
          setErrorMessage("");
        }}
      />
      <WorkpaperAudits
        queryId={historyId}
        handleClose={() => {
          setHistoryId(null);
        }}
      />
      {showCreateActionForm && (
        <AddOrCopyProjectActionItems
          project={project}
          actionItemTypes={actionItemTypes}
          onCancel={closeActionForms}
          onSubmitted={closeActionForms}
          isSlidingForm={true}
          modalClassName={"add-request-sideModal"}
          enableWebSheet={true}
          viewScope={systemConstants.viewScopeType.project}
        />
      )}
      {showUpdateActionForm && (
        <UpdateActionForm
          project={project}
          query={selectedQuery}
          queryTypes={queryTypes}
          actionItemTypes={actionItemTypes}
          onCancel={closeActionForms}
          onSubmitted={closeActionForms}
        />
      )}
    </>
  );
};

export default ActionItems;
