import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageProjectQueriesService = {
  getQuery,
  getProjectQueries,
  addProjectQuery,
  getMyQueryCount,
  updateProjectQuery
};

function getQuery(queryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/queries/${queryId}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectQueries({ id: projectId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/projects/${projectId}/queries`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getMyQueryCount(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/queries/assignToUserCount?status=${systemConstants.project.queries.status.open}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addProjectQuery(query) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(query)
  };
  return fetch(`${baseURL}/api/queries`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectQuery(query) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(query)
  };
  return fetch(`${baseURL}/api/queries/${query.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
