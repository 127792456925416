import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  manageDocumentUploadsActions,
  manageProjectDocumentsActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useDocumentCheck, useGetSupportedMimesQuery } from "@shared/hooks";

import { convertEntitiesToIds } from "@app/helpers/entity";

import { Button, ButtonVariant } from "@atoms/Button";

import { MultiSelectInput } from "@molecules/inputs";

import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import TextAreaInput from "@components/atoms/TextAreaInput";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./AddClientProjectDocument.scss";

const attachedFileStates = systemConstants.addFiles.attachedFile.state;

const AddClientProjectDocument = props => {
  const {
    project,
    droppedFiles,
    title,
    subtext,
    onUpload,
    onFileUpload,
    parentFolderId,
    tags
  } = props;
  const { entities, isEntityRestricted } = project;
  const entityEnabled = project.configuration.entities?.enabled;
  const initialEntities = (() => {
    if (entities?.length === 1) {
      return entities;
    }
    if (isEntityRestricted) {
      return entities;
    }
    return [];
  })();

  const dispatch = useDispatch();
  const manageDocumentUploads = useSelector(
    state => state.manageDocumentUploads
  );
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([...droppedFiles]);
  const { data: supportedDocumentMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.document
  });
  const [attachmentsObject, setAttachmentsObject] = useState({});
  const [files, setFiles] = useState([...droppedFiles]);
  const [uploadSubmitted, setUploadSubmitted] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(initialEntities);
  const inputRef = useRef(null);
  const [error, setError] = useState(props.error);
  const [uploadedDocuments, setUploadedDocuments] = useState(false);
  const uploadAbortError = "As per user request aborting document(s) upload.";
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagItems] = useState(
    tags.map(tag => ({ name: tag.name, id: tag.id }))
  );
  const { check, fetchCheck, isActionTypeSmartForm } = useDocumentCheck(
    project.id
  );
  const [requiresOverrideMessage, setRequiresOverrideMessage] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState(null);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (documents?.length > 0) {
      const formattedDocuments = {};
      documents.forEach(doc => {
        if (doc.name) {
          if (!uploadSubmitted && doc.error) {
            doc.state = attachedFileStates.uploadFailed;
          }
          if (doc.pathId) {
            doc.state = attachedFileStates.uploaded;
          }
          if (!doc.state) {
            doc.state = attachedFileStates.selected;
          }
          formattedDocuments[doc.name] = doc;
        }
      });
      setAttachmentsObject(formattedDocuments);
    } else {
      setAttachmentsObject({});
    }
  }, [documents, uploadSubmitted]);

  useEffect(() => {
    if (!droppedFiles?.length) {
      return;
    }

    const documentList = droppedFiles.map(documentObject => {
      return {
        id: documentObject.id || null,
        name: documentObject.name,
        state: attachedFileStates.selected,
        projectId: project.id,
        projectFolderId: parentFolderId,
        revisionName: ""
      };
    });
    setFiles([...droppedFiles]);
    setDocuments([...documentList]);
  }, [droppedFiles, parentFolderId, project.id]);

  useEffect(() => {
    documents.forEach(documentObject => {
      if (documentObject.pathId) {
        if (!documentObject.metadataUpdateStated) {
          const appendOverrideMessage = check.some(
            c => c.present && c.name === documentObject.name
          );
          const revisionProperties = appendOverrideMessage
            ? { overrideMessage }
            : null;
          const documentProperties = entityEnabled
            ? { entities: convertEntitiesToIds(selectedEntities) }
            : null;
          dispatch(
            manageProjectDocumentsActions.uploadNewProjectDocument({
              ...documentObject,
              documentPathId: documentObject.pathId,
              tags: selectedTags,
              properties: revisionProperties,
              documentProperties,
              projectId: props.project.id
            })
          );
          documentObject.metadataUpdateStated = true;
        }
      }

      if (!documentObject.pathId && documentObject.error === uploadAbortError) {
        documentObject.aborted = true;
      }

      return documentObject;
    });
  }, [
    props?.project?.id,
    documents,
    dispatch,
    selectedTags,
    check,
    overrideMessage,
    selectedEntities,
    entityEnabled
  ]);

  useEffect(() => {
    if (documents.some(documentObject => !documentObject.updatedMetaData)) {
      setUploadedDocuments(false);
    } else {
      setUploadedDocuments(true);
    }
  }, [documents]);

  useEffect(() => {
    if (manageProjectDocuments.error) {
      setError(manageProjectDocuments.error);
    }
  }, [manageProjectDocuments.error]);

  useEffect(() => {
    if (!manageProjectDocuments?.newUploadedDocuments?.length) {
      return;
    }

    manageProjectDocuments.newUploadedDocuments.forEach(
      newUploadedDocumentObject => {
        setDocuments(prevDocuments =>
          prevDocuments.map(documentObject => {
            if (documentObject.name === newUploadedDocumentObject.name) {
              documentObject.updatedMetaData = true;
            }
            return documentObject;
          })
        );
      }
    );
  }, [manageProjectDocuments.newUploadedDocuments]);

  useEffect(() => {
    if (!manageProjectDocuments?.uploadedDocuments?.length) {
      return;
    }

    manageProjectDocuments.uploadedDocuments.forEach(uploadedDocumentObject => {
      setDocuments(prevDocuments =>
        prevDocuments.map(documentObject => {
          if (documentObject.name === uploadedDocumentObject.name) {
            documentObject.updatedMetaData = true;
          }
          return documentObject;
        })
      );
    });
  }, [manageProjectDocuments.uploadedDocuments]);

  useEffect(() => {
    if (!manageDocumentUploads?.uploadingDocuments?.length) {
      return;
    }

    setDocuments(prevDocuments => {
      const documentsToUpdate = prevDocuments.map(document => {
        let updatedDocument = document;

        const documentUploaded = manageDocumentUploads.uploadingDocuments.find(
          uploadingDocument =>
            uploadingDocument.projectId === document.projectId &&
            uploadingDocument.name === document.name
        );

        if (documentUploaded?.documentPathId) {
          updatedDocument.pathId = documentUploaded.documentPathId;
          updatedDocument.state = attachedFileStates.attached;
        }
        if (documentUploaded?.error) {
          setError(documentUploaded.error);
          updatedDocument.error = documentUploaded.error;
          updatedDocument.state = attachedFileStates.selected;
        }
        if (documentUploaded?.uploadProgress) {
          updatedDocument.uploadProgress = documentUploaded.uploadProgress;
          updatedDocument.state = attachedFileStates.uploading;
        }
        if (documentUploaded?.reqSource) {
          updatedDocument.reqSource = documentUploaded.reqSource;
        }

        return updatedDocument;
      });
      return documentsToUpdate;
    });
  }, [manageDocumentUploads.uploadingDocuments]);

  const handleChange = event => {
    event.stopPropagation();
    const addedDocuments = [...event.target.files];
    event.target.value = null;
    if (
      addedDocuments?.some(
        document => document.size > systemConstants.project.document.maxSize
      )
    ) {
      setError("The maximum supported file size is 50 GB for documents.");
    } else {
      const newDocuments = addedDocuments.filter(documentObject => {
        let documentNotAdded = true;
        files.forEach(file => {
          if (documentObject.name === file.name) {
            documentNotAdded = false;
          }
        });
        return documentNotAdded;
      });

      setFiles(files.concat(newDocuments));
      setDocuments(
        documents.concat(
          newDocuments.map(file => {
            return {
              id: file.id || null,
              name: file.name,
              projectId: project.id,
              projectFolderId: parentFolderId,
              revisionName: ""
            };
          })
        )
      );
      setError(false);
    }
  };

  const handleCancel = useCallback(() => {
    if (!uploadSubmitted || error) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      dispatch(manageProjectDocumentsActions.clearError());
      setOverrideMessage(null);
      setRequiresOverrideMessage(false);
      onUpload();
    }
  }, [dispatch, error, onUpload, uploadSubmitted]);

  const handleUpload = () => {
    if (files.length) {
      fetchCheck(files.map(f => f.name));
    }
    onFileUpload();
  };

  const handleConfirmOverride = useCallback(() => {
    if (requiresOverrideMessage && !overrideMessage) {
      return;
    }

    setUploadSubmitted(true);
    files.forEach(file => {
      dispatch(
        manageDocumentUploadsActions.uploadDocument({
          file,
          projectId: project.id
        })
      );
    });
  }, [requiresOverrideMessage, overrideMessage, files, project.id, dispatch]);

  useEffect(() => {
    if (!check?.length) {
      return;
    }
    const hasExistingFilenamesPresent = check.some(c => c.present);
    if (hasExistingFilenamesPresent) {
      setRequiresOverrideMessage(true);
    } else {
      setRequiresOverrideMessage(false);
      handleConfirmOverride();
    }
  }, [check, handleConfirmOverride]);

  const handleReqCancel = useCallback(() => {
    let isReqCancelled = false;
    documents.forEach(docObject => {
      if (docObject?.reqSource && !docObject.pathId) {
        isReqCancelled = true;
        docObject.reqSource.cancel(uploadAbortError);
      }
    });
    if (!isReqCancelled) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      setOverrideMessage(null);
      onUpload();
    }
  }, [dispatch, documents, onUpload]);

  const handleDone = useCallback(() => {
    if (uploadedDocuments) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      setOverrideMessage(null);
      onUpload();
    }
  }, [dispatch, onUpload, uploadedDocuments]);

  const handleAddFile = () => {
    inputRef.current?.click?.();
  };

  const handleRemove = removedDocumentName => {
    const updatedFiles = files.filter(
      fileObject => fileObject.name !== removedDocumentName
    );
    const updatedDocuments = documents.filter(
      documentObject => documentObject.name !== removedDocumentName
    );
    setFiles(updatedFiles);
    setDocuments(updatedDocuments);
    setError("");
    setUploadSubmitted(false);
  };

  const getUploadCancellationError = () => {
    const uploadCancelledDocuments = documents.filter(
      docObject => docObject.error === uploadAbortError
    );

    const uploadedDocumentsCount =
      documents.length - uploadCancelledDocuments.length;
    return `${uploadedDocumentsCount} document(s) uploaded and ${uploadCancelledDocuments.length} document(s) were cancelled.`;
  };

  const getSupportedMimes = useCallback(() => {
    const supportedMimesList =
      supportedDocumentMimes ?? systemConstants.mimes.document;
    return supportedMimesList.map(mime => `.${mime}`).join();
  }, [supportedDocumentMimes]);

  const handleClose = useMemo(() => {
    if (uploadedDocuments) {
      return handleDone;
    }

    if (!uploadSubmitted || error) {
      return handleCancel;
    }

    return handleReqCancel;
  }, [
    uploadedDocuments,
    uploadSubmitted,
    error,
    handleReqCancel,
    handleDone,
    handleCancel
  ]);

  const getLabelsField = () => {
    if (documents.length > 0 && tags?.length > 0) {
      return (
        <MultiSelectInput
          onChange={setSelectedTags}
          disabled={uploadSubmitted}
          label={t("common:ui.documents.fileUpload.selectLabels")}
          items={tagItems}
          selectedValues={selectedTags}
        />
      );
    }
  };

  const getEntitiesField = () => {
    if (
      entityEnabled &&
      entities?.length > 1 &&
      (!check.length || check.some(({ present }) => !present))
    ) {
      return (
        <MultiSelectInput
          onChange={setSelectedEntities}
          disabled={uploadSubmitted}
          label={t("requests:requests.configured.fields.entities.label")}
          items={entities}
          selectedValues={selectedEntities}
        />
      );
    }
  };

  const content = () => {
    return (
      <>
        {error && (
          <InlineAlert
            type="error"
            message={
              error === uploadAbortError
                ? getUploadCancellationError()
                : t(error)
            }
          />
        )}
        <div className="hidden">
          <input
            ref={inputRef}
            onChange={handleChange}
            type="file"
            multiple
            accept={getSupportedMimes()}
          />
        </div>
        <div>
          {documents.length ? (
            <>
              {error ? (
                ""
              ) : (
                <p>
                  {t(
                    "common:ui.documents.fileUpload.uploadAttachmentFileList",
                    {
                      count: documents.length
                    }
                  )}
                </p>
              )}
              <div className="add-file__container--file-box">
                {documents && (
                  <UploadFileAttachmentList
                    attachments={attachmentsObject}
                    onDelete={handleRemove}
                    disabled={!uploadSubmitted && requiresOverrideMessage}
                  />
                )}
              </div>
            </>
          ) : (
            <p>{t("common:documents.fileUpload.addDocumentsHintText")}</p>
          )}
          {!uploadSubmitted && !requiresOverrideMessage ? (
            <>
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                label={t("common:documents.fileUpload.addDocuments")}
                iconName="add_circle"
                onClick={handleAddFile}
              />
            </>
          ) : (
            ""
          )}
        </div>
        {getLabelsField()}
        {getEntitiesField()}
        {!uploadSubmitted && requiresOverrideMessage && (
          <div>
            {isActionTypeSmartForm ? (
              <InlineAlert
                type="warning"
                message={t("common:ui.upload.error.fileName.cannotOverwrite")}
              ></InlineAlert>
            ) : (
              <>
                <InlineAlert
                  type="warning"
                  message={t("common:ui.upload.error.fileName.existed")}
                ></InlineAlert>
                <TextAreaInput
                  label={t("common:ui.upload.fileUpload.labelOverrideMessage")}
                  value={overrideMessage ?? ""}
                  required={true}
                  onChange={v => setOverrideMessage(v.target.value)}
                  maxLength={255}
                />
              </>
            )}
          </div>
        )}
        {subtext && (
          <div className="add-file__container--subtext">{subtext}</div>
        )}
      </>
    );
  };

  const footers = () => {
    if ((!uploadSubmitted && !requiresOverrideMessage) || error) {
      return (
        <>
          <Button
            variant={error ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
            disabled={uploadSubmitted && !error}
            label={t("common:documents.fileUpload.buttons", {
              context:
                error === uploadAbortError ? "CLOSE" : error ? "DONE" : "CANCEL"
            })}
            onClick={handleCancel}
          />
          {error ? (
            ""
          ) : (
            <Button
              disabled={!files.length || uploadSubmitted || !!error}
              label={t("common:documents.fileUpload.buttons", {
                context: "UPLOAD"
              })}
              onClick={handleUpload}
            />
          )}
        </>
      );
    } else if (!uploadSubmitted && requiresOverrideMessage) {
      return (
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            disabled={!requiresOverrideMessage}
            label={t("common:documents.fileUpload.buttons", {
              context: "CANCEL"
            })}
            onClick={handleCancel}
          />
          <Button
            disabled={!requiresOverrideMessage || !overrideMessage}
            label={t("common:ui.forms.upload.label")}
            onClick={handleConfirmOverride}
          />
        </>
      );
    } else {
      return (
        <>
          {uploadedDocuments ? (
            <Button
              disabled={!uploadedDocuments}
              label={t("common:documents.fileUpload.buttons", {
                context: "DONE"
              })}
              onClick={handleDone}
            />
          ) : (
            <Button
              variant={ButtonVariant.SECONDARY}
              label={t("common:documents.fileUpload.buttons", {
                context: "CANCEL"
              })}
              onClick={handleReqCancel}
            />
          )}
        </>
      );
    }
  };

  return (
    <ModalTemplate
      boxClassName="upload-documents"
      title={title || "Upload document(s)"}
      onClose={handleClose}
      content={content()}
      footer={footers()}
    />
  );
};

AddClientProjectDocument.propTypes = {
  title: PropTypes.string,
  subtext: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number,
    isEntityRestricted: PropTypes.bool,
    entities: PropTypes.array,
    configuration: PropTypes.object
  }),
  droppedFiles: PropTypes.array,
  error: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number
    })
  ),
  parentFolderId: PropTypes.number,
  onUpload: PropTypes.func,
  onFileUpload: PropTypes.func
};

export default AddClientProjectDocument;
