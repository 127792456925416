import { StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface SmartFormResponse {
  questionId: string;
  response: unknown;
  cursor: {
    selectionStart: number;
    selectionEnd: number;
    selectionDirection: "forward" | "backward" | "none";
  };
}

interface SmartFormResponseSlice {
  smartFormResponse: SmartFormResponse;
  setActiveQuestion: (
    questionId: string,
    smartFormResponse: string,
    cursor: {
      selectionStart: number;
      selectionEnd: number;
      selectionDirection: "forward" | "backward" | "none";
    }
  ) => void;
  clearActiveQuestion: () => void;
}

const createSmartFormResponseSlice: StateCreator<
  SmartFormResponseSlice
> = set => ({
  smartFormResponse: {
    questionId: "",
    response: "",
    cursor: {
      selectionStart: -1,
      selectionEnd: -1,
      selectionDirection: "none"
    }
  },
  setActiveQuestion: (questionId, response, cursor) =>
    set(() => ({
      smartFormResponse: {
        questionId,
        response,
        cursor
      }
    })),
  clearActiveQuestion: () =>
    set(() => ({
      smartFormResponse: {
        questionId: "",
        response: "",
        cursor: {
          selectionStart: -1,
          selectionEnd: -1,
          selectionDirection: "none"
        }
      }
    }))
});

export const useSmartFormResponseStore = create<SmartFormResponseSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createSmartFormResponseSlice(...a)
      }),
      {
        name: "ot-app-store"
      }
    ),
    { name: "ot-app-store", enabled: process.env.NODE_ENV === "development" }
  )
);
