import { useCallback, useEffect, useMemo, useState } from "react";

import { useFetch } from "./useFetch";

export function useDocumentCheck(projectId) {
  const { isLoading, data, error, doFetch } = useFetch({});
  const [check, setCheck] = useState([]);

  const fetchCheck = useCallback(
    documentNames => {
      const queryParams = new URLSearchParams(
        documentNames.map(n => ["name", n])
      );
      doFetch(`/projects/${projectId}/documents/present?${queryParams}`);
    },
    [doFetch, projectId]
  );

  useEffect(() => {
    if (data && !error) {
      setCheck(data.present);
    }
  }, [data, error]);

  const resetCheck = () => {
    setCheck([]);
  };

  const isActionTypeSmartForm = useMemo(
    () => check?.some(c => c.actionItemTypeKey === "SMARTFORM"),
    [check]
  );

  return {
    checkError: error,
    isCheckLoading: isLoading,
    check,
    resetCheck,
    fetchCheck,
    isActionTypeSmartForm
  };
}
