import React, { useCallback } from "react";

import PropTypes from "prop-types";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks";

const CoversationListItem = ({ conversation, onClickItem, isActive }) => {
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const onClick = useCallback(() => {
    onClickItem(conversation.conversationId);
  }, [onClickItem, conversation.conversationId]);

  const latestResponseDate = dateFormatter(
    conversation.createdAt,
    locale,
    shortFormat
  );

  return (
    <div
      className={`smart-form-comment-modal-conversation-list__item__container ${
        isActive ? "is-active" : ""
      }`}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onClick}
    >
      <div className="smart-form-comment-modal-conversation-list__item__top">
        <div className="smart-form-comment-modal-conversation-list__item__top__title">
          {conversation.conversationTitle}
        </div>
        <div className="smart-form-comment-modal-conversation-list__item__top__displayDate">
          {latestResponseDate}
        </div>
      </div>
      <div className="smart-form-comment-modal-conversation-list__item__bottom">
        <div className="smart-form-comment-modal-conversation-list__item__bottom__latestResponse">
          {conversation.response}
        </div>
        <div className="smart-form-comment-modal-conversation-list__item__bottom__entities"></div>
      </div>
    </div>
  );
};

CoversationListItem.propTypes = {
  conversation: PropTypes.object,
  onClickItem: PropTypes.func,
  isActive: PropTypes.bool
};
export default CoversationListItem;
