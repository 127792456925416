import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { authActions } from "@shared/actions";

interface HostObject {
  host: {
    properties: {
      timeZone: string;
    };
    name: string;
  };
}

export function useGetHostObject() {
  const authentication = useSelector(state => state.authentication);
  const [hostObject, setHostObject] = useState<HostObject | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const refetchData = () => {
    if (!isLoading) {
      dispatch(authActions.getHostWithBrandDetails());
    }
    setHostObject(authentication.host);
  };

  useEffect(() => {
    if (!authentication.host) {
      dispatch(authActions.getHostWithBrandDetails());
    }
    setHostObject(authentication.host);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication.host]);

  useEffect(() => {
    setIsLoading(authentication.loading);
  }, [authentication.loading]);

  useEffect(() => {
    setError(authentication.error);
  }, [authentication.error]);

  return { isLoading, error, hostObject, refetchData };
}
