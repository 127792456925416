import React, { useCallback, useMemo, useState } from "react";

import * as yup from "yup";

import AddActionForm from "@components/organisms/AddActionForm";

export function useAddActionFormStep({
  project,
  selectedQueryType,
  source,
  handleSubmitted
}) {
  const [schema, setSchema] = useState(yup.object({}));
  const [handleSubmit, setHandleSubmit] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const formRef = useCallback(node => {
    if (node != null) {
      setSchema(node.getSchema() ?? yup.object({}));
      setHandleSubmit(() => node.handleSubmit);
      setDisableSubmit(() => node.disableSubmit);
    }
  }, []);

  const content = useMemo(() => {
    return (
      <AddActionForm
        ref={formRef}
        selectedQueryType={selectedQueryType?.queryType}
        fields={selectedQueryType?.fields}
        project={project}
        source={source}
        onSubmitted={handleSubmitted}
      />
    );
  }, [
    formRef,
    handleSubmitted,
    project,
    selectedQueryType?.fields,
    selectedQueryType?.queryType,
    source
  ]);

  return {
    schema,
    handleSubmit,
    content,
    disableSubmit
  };
}
