export const manageClientsConstants = {
  GET_CLIENTS_REQUEST: "GET_CLIENTS_REQUEST",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE: "GET_CLIENTS_FAILURE",

  GET_CLIENT_REQUEST: "GET_CLIENT_REQUEST",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_FAILURE: "GET_CLIENT_FAILURE",

  GET_CLIENTS_FROM_CRM_REQUEST: "GET_CLIENTS_FROM_CRM_REQUEST",
  GET_CLIENTS_FROM_CRM_SUCCESS: "GET_CLIENTS_FROM_CRM_SUCCESS",
  GET_CLIENTS_FROM_CRM_FAILURE: "GET_CLIENTS_FROM_CRM_FAILURE",

  ADD_CLIENT_REQUEST: "ADD_CLIENT_REQUEST",
  ADD_CLIENT_SUCCESS: "ADD_CLIENT_SUCCESS",
  ADD_CLIENT_FAILURE: "ADD_CLIENT_FAILURE",

  UPDATE_CLIENT_REQUEST: "UPDATE_CLIENT_REQUEST",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE: "UPDATE_CLIENT_FAILURE",

  RESET_CLIENTS: "RESET_CLIENTS",
  CLEAR_ERROR: "CLEAR_ERROR_CLIENTS",
  CLEAR_GET_CLIENT_FLAGS: "CLEAR_GET_CLIENT_FLAGS",
  CLEAR_ADD_CLIENT_FLAGS: "CLEAR_ADD_CLIENT_FLAGS",
  CLEAR_UPDATE_CLIENT_FLAGS: "CLEAR_UPDATE_CLIENT_FLAGS"
};
