import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Button, ButtonVariant } from "@atoms/Button";
import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";
import {
  Pill,
  PillFillStyle,
  PillFontWeight,
  PillSize,
  PillVariant
} from "@atoms/Pill";

import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate";

import "./IndexList.scss";

const folderTypes = {
  folder: "folder",
  project: "project",
  host: "host",
  hostFolder: "hostFolder",
  tag: "tag"
};

const IndexList = ({
  tags,
  canAddTag,
  handleFolderSelection,
  selectedFolder,
  handleAddTag,
  handleEditTag,
  handleDeleteTag,
  handleResetTag,
  isArchivedFolder,
  t
}) => {
  const [selected, setSelected] = useState(selectedFolder);

  useEffect(() => {
    setSelected(selectedFolder);
  }, [selectedFolder]);

  const clickResetTag = useCallback(
    event => {
      event.stopPropagation();
      if (
        selected.type !== folderTypes.tag &&
        selected.id !== "archivedFolder"
      ) {
        return;
      }
      selected.type = folderTypes.folder;
      selected.id = null;
      handleResetTag();
    },
    [handleResetTag, selected]
  );

  const clickTag = useCallback(
    (event, tag) => {
      event.stopPropagation();
      if (selected.type === folderTypes.tag && selected.id === tag.id) {
        clickResetTag(event);
        return;
      }
      const item = {
        id: tag.id,
        type: folderTypes.tag,
        name: tag.name,
        isRoot: true,
        documents: [],
        folders: []
      };
      setSelected(item);
      handleFolderSelection(item);
    },
    [clickResetTag, handleFolderSelection, selected.id, selected.type]
  );

  const clickArchivedFolder = useCallback(
    event => {
      event.stopPropagation();
      if (selected.id === "archivedFolder") {
        clickResetTag(event);
        return;
      }
      const item = {
        id: "archivedFolder",
        name: "Archived documents",
        isRoot: true,
        type: folderTypes.folder,
        documents: [],
        folders: []
      };
      setSelected(item);
      handleFolderSelection(item);
    },
    [clickResetTag, handleFolderSelection, selected.id]
  );

  const RenderTag = ({ tag, canShowEditOptions, onClick }) => {
    const isSelected =
      selected.id === tag.id && selected.type === folderTypes.tag;

    return (
      <div
        className={`index-list__tags-item ${
          isSelected ? "index-list__tags-item--selected" : ""
        }`}
        onClick={event => onClick(event, tag)}
      >
        <Pill
          label={tag.name}
          variant={
            isSelected ? PillVariant.ACCENT : PillVariant.TRANSPARENT_DARK
          }
          fillStyle={isSelected ? PillFillStyle.FILLED : PillFillStyle.OUTLINE}
          size={PillSize.FILL}
          fontWeight={isSelected ? PillFontWeight.SEMI_BOLD : undefined}
        />
        {canShowEditOptions && (
          <div className="index-list__tags-item-actions">
            <Icon
              name="edit"
              fillStyle={IconFillStyle.FILLED}
              className="index-list__tags-item-actions-edit"
              size={IconSize.XS}
              onClick={handleEditTag(tag)}
            />
            <Icon
              name="delete"
              fillStyle={IconFillStyle.FILLED}
              className="material-icons index-list__tags-item-actions-delete"
              size={IconSize.XS}
              onClick={handleDeleteTag(tag)}
            />
          </div>
        )}
      </div>
    );
  };

  const renderTags = () => {
    return (
      !isArchivedFolder && (
        <>
          <BoxTemplate
            title={t("ui.indexList.labels.title")}
            action={
              <div className="index-list__tags-actions">
                {(selected.type === folderTypes.tag || isArchivedFolder) && (
                  <Button
                    iconName="rotate_left"
                    label={t("common:ui.documents.resetButtonLabel")}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    onClick={clickResetTag}
                  />
                )}
                {canAddTag && (
                  <Button
                    iconName="add"
                    label={t("common:ui.label.add")}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    onClick={handleAddTag}
                  />
                )}
              </div>
            }
          >
            {!tags?.length ? (
              <div className="index-list__tags-empty">
                <div>{t("common:ui.indexList.labels.emptyPlaceHolder")}</div>
              </div>
            ) : (
              <div className="index-list__tags-listing">
                {tags.map(tag => (
                  <RenderTag
                    key={tag.id}
                    tag={tag}
                    canShowEditOptions={canAddTag && tag.projectId}
                    onClick={clickTag}
                  />
                ))}
                <RenderTag
                  tag={{
                    id: -1,
                    name: t("stringFormat.capitalize", {
                      key: "common:unlabelled"
                    })
                  }}
                  canShowEditOptions={false}
                  onClick={event => clickTag(event, { id: -1 })}
                />
              </div>
            )}
          </BoxTemplate>
        </>
      )
    );
  };

  const renderSpecialFolders = () => (
    <BoxTemplate
      boxClassName={`${isArchivedFolder ? "index-list__box" : ""}`}
      colorScheme="transparent"
    >
      <div className="index-list__folders">
        <Button
          iconName={`${isArchivedFolder ? "inventory_2" : "delete"}`}
          iconOutlined={isArchivedFolder}
          label={t("common:ui.documents.binText", {
            context: isArchivedFolder ? "activeDocument" : "archivedDocument"
          })}
          variant={
            isArchivedFolder ? ButtonVariant.TEXT : ButtonVariant.TEXT_PRIMARY
          }
          onClick={event => clickArchivedFolder(event)}
        />
      </div>
    </BoxTemplate>
  );

  return (
    <>
      <div className="index-list">
        {renderTags()}
        {renderSpecialFolders()}
      </div>
    </>
  );
};

IndexList.propTypes = {
  foldersAndDocuments: PropTypes.array,
  tags: PropTypes.array,
  canAddTag: PropTypes.bool.isRequired,
  handleFolderSelection: PropTypes.func.isRequired,
  selectedFolder: PropTypes.object,
  handleAddTag: PropTypes.func.isRequired,
  handleEditTag: PropTypes.func.isRequired,
  handleDeleteTag: PropTypes.func.isRequired,
  handleResetTag: PropTypes.func.isRequired
};

export default withTranslation()(IndexList);
