import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;
export const cmsService = {
  getContent,
  getUIConfig
};

function getContent() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/hostBrands/content/${window.location.host}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getUIConfig() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/hostBrands/uiConfig/${window.location.host}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
