import { manageDocumentDownloadsConstants } from "../constants";

const initialState = {
  loading: false,
  downloadedDocuments: [],
  error: "",
  errorDocumentId: -1
};

export function manageDocumentDownloads(state = initialState, action = {}) {
  switch (action.type) {
    case manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        errorDocumentId: -1
      };
    case manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadedDocuments: [...state.downloadedDocuments, action.document],
        error: "",
        errorDocumentId: -1
      };
    case manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message,
        errorDocumentId: action.errorDocumentId
      };
    case manageDocumentDownloadsConstants.RESET_ERROR:
      return {
        ...state,
        error: "",
        errorDocumentId: -1
      };
    case manageDocumentDownloadsConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
