import React, { useCallback, useContext } from "react";

import UIConfigContext from "@app/helpers/UIConfigContext";

const ThemedStylesheet = () => {
  const uiConfig = useContext(UIConfigContext);

  const render = useCallback(() => {
    if (uiConfig?.theme?.stylesheetUrl) {
      return (
        <link
          type="text/css"
          rel="stylesheet"
          href={uiConfig?.theme?.stylesheetUrl}
        />
      );
    }
    return <></>;
  }, [uiConfig?.theme]);

  return render();
};
export default ThemedStylesheet;
