import { isNil } from "lodash";

import { smartFormResponseType } from ".";

export function formatDocumentAnswersPayload(previousAnswers, data) {
  const getPreviousAnswerStateForFile = documentId =>
    Object.entries(previousAnswers).reduce((acc, [entityId, documentIds]) => {
      const docId = documentIds?.find(dId => dId === documentId);
      if (!docId) {
        return acc;
      }

      return {
        ...acc,
        [entityId]: docId
      };
    }, {});

  return data.map(d => {
    const fileAndAnswerInfo = d.value;

    const previousAnswerStateForFile = getPreviousAnswerStateForFile(
      fileAndAnswerInfo.documentId
    );

    const finalAnswerStateForFile =
      fileAndAnswerInfo.entities?.reduce(
        (acc, e) => ({
          ...acc,
          [e]: fileAndAnswerInfo.documentId
        }),
        {}
      ) ?? {};

    return {
      value: {
        ...fileAndAnswerInfo,
        entityChanges: formatSmartFormPayload(
          previousAnswerStateForFile,
          finalAnswerStateForFile
        )
      }
    };
  });
}

/**
 * Compare previous answer and final answer
 * @param {Object} prev {1:"test1",2:"test2",3:"test3"}
 * @param {Object} final {1: "test1a"}
 * @returns {Object[]} payload [{entities:[1],value: "test1a"},{entities:[2,3],isDeleted: true}]
 */
export function formatSmartFormPayload(prev, final) {
  const toUpdatedAnswers = [];
  const toDeleteAnswers = [];

  //find toDelete and toUpdate Answers
  Object.keys(prev).forEach(entityId => {
    if (isNil(final[entityId])) {
      toDeleteAnswers.push({
        value: prev[entityId],
        entities: [entityId],
        isDeleted: true
      });
    } else if (final[entityId] !== prev[entityId]) {
      toUpdatedAnswers.push({
        value: final[entityId],
        entities: [entityId]
      });
    }
  });

  //find new answers
  Object.keys(final).forEach(entityId => {
    if (isNil(prev[entityId])) {
      toUpdatedAnswers.push({
        entities: [entityId],
        value: final[entityId],
        isAdded: true
      });
    }
  });

  toUpdatedAnswers.push(...toDeleteAnswers);
  const formattedPayload = toUpdatedAnswers;
  return formattedPayload;
}

/**
 * Opens a new tab with smartform websheet
 * @param {Object} details
 */
export function openSmartFormWebSheet({ projectId, queryId, id }) {
  if (!projectId || !queryId || !id) {
    return;
  }

  return () =>
    window.open(
      `/projects/${projectId}/smartforms/${queryId}/websheets/${id}`,
      "_blank"
    );
}

/**
 * Gets label and for websheet or document open modal button
 * @param {string} responseType
 * @param {number} numberOfAnswers
 * @param {boolean} isAnswerSubmissionsDisabled
 * @param {any} t
 * @returns {{ label: string; icon: string }} details
 */
export function getLabelAndIconForOpenModalButton(
  responseType,
  numberOfAnswers,
  isAnswerSubmissionsDisabled,
  t
) {
  if (responseType === smartFormResponseType.DOCUMENT) {
    if (numberOfAnswers) {
      return {
        icon: "description",
        label: t("requests:requests.ui.smartForm.modal.viewDocuments.label")
      };
    }

    if (isAnswerSubmissionsDisabled) {
      return {
        icon: "description",
        label: t("requests:requests.ui.smartForm.modal.noDocuments.label")
      };
    }

    return {
      icon: "description",
      label: t("requests:requests.ui.smartForm.modal.uploadDocuments.label")
    };
  }

  if (responseType === smartFormResponseType.WEBSHEET) {
    if (numberOfAnswers) {
      return {
        icon: "view_module",
        label: t("requests:requests.ui.smartForm.modal.viewWebsheets.label")
      };
    }

    if (isAnswerSubmissionsDisabled) {
      return {
        icon: "view_module",
        label: t("requests:requests.ui.smartForm.modal.noWebsheets.label")
      };
    }

    return {
      icon: "view_module",
      label: t("requests:requests.ui.smartForm.modal.fillWebsheet.label")
    };
  }
  return {
    icon: "",
    label: ""
  };
}
