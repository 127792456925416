import { clientDocumentConstants } from "../constants";

const initialState = {
  documentsLoading: false,
  documents: [],
  uploading: false,
  uploadedDocuments: [],
  documentsBySearchString: [],
  revisionsLoading: false,
  documentRevisions: [],
  error: ""
};

export function clientDocuments(state = initialState, action = {}) {
  switch (action.type) {
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_REQUEST:
      return {
        ...state,
        documentsLoading: true,
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        documents: action.documents,
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        error: action.error
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_RESET:
      return {
        ...state,
        documentsLoading: false,
        documents: [],
        error: ""
      };
    case clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_REQUEST:
      return {
        ...state,
        uploading: true,
        error: ""
      };
    case clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadedDocuments: [
          ...state.uploadedDocuments,
          action.uploadedObject.document
        ],
        error: ""
      };
    case clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_FAILURE:
      return {
        ...state,
        uploading: false,
        error: action.error
      };
    case clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_RESET:
      return {
        ...state,
        uploading: false,
        uploadedDocuments: [],
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_REQUEST:
      return {
        ...state,
        revisionsLoading: true,
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_SUCCESS:
      return {
        ...state,
        revisionsLoading: false,
        documentRevisions: action.documentRevisions,
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_FAILURE:
      return {
        ...state,
        revisionsLoading: false,
        documentRevisions: [],
        error: action.error.key ?? action.error.message
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_REQUEST:
      return {
        ...state,
        documentsLoading: true
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        updatedDocumentWithNewRevsion: action.updatedObject.document,
        documents:
          action.updatedObject.updatedProjectFoldersAndDocuments.documents,
        error: ""
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        updatedDocumentWithNewRevsion: false,
        error: action.error.key ?? action.error.message
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_REQUEST:
      return {
        ...state,
        documentsLoading: true
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        updatedDocumentWithNewRevsion: action.updatedObject.document,
        documents: action.updatedObject.documents,
        error: ""
      };
    case clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        updatedDocumentWithNewRevsion: false,
        error: action.error.key ?? action.error.message
      };
    case clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_REQUEST:
      return {
        ...state,
        documentsLoading: true
      };
    case clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        deletedDocument: action.deletedDocumentObject.document,
        documents: action.deletedDocumentObject.documents,
        error: ""
      };
    case clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        deletedDocument: false,
        error: action.error.key ?? action.error.message
      };

    case clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_REQUEST:
      return {
        ...state,
        documentsLoading: true
      };
    case clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        deletedDocument: action.restoredDocumentObject.document,
        documents: action.restoredDocumentObject.documents,
        error: ""
      };
    case clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        deletedDocument: false,
        error: action.error.key ?? action.error.message
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS:
      return {
        ...state,
        loading: false,
        documentsBySearchString: action.documents,
        error: ""
      };
    case clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_FAILURE:
      return {
        ...state,
        loading: false,
        documentsBySearchString: [],
        error: action.error.key ?? action.error.message
      };
    case clientDocumentConstants.RESET:
      return { ...initialState };
    default:
      return state;
  }
}
