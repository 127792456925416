import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import { useGetClientByIdQuery, useUpdateClientMutation } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";

import { Button, ButtonVariant } from "@atoms/Button";

import { ClientForm } from "@organisms/ClientForm";

import FormPageTemplate from "@components/templates/FormPageTemplate";

export const UpdateClient = () => {
  const { clientId } = useParams();
  const goBack = useGoBack();
  const { data: client, isLoading } = useGetClientByIdQuery(clientId, {
    skip: !clientId
  });
  const [updateClient, { isSuccess, isLoading: isSubmitting, error }] =
    useUpdateClientMutation();

  const defaultValue = useMemo(() => {
    if (!client) {
      return;
    }
    const clientCopy = structuredClone(client);
    return clientCopy;
  }, [client]);

  const [clientLogoModal, setClientLogoModal] = useState(<></>);
  const [logo, setLogo] = useState(<></>);

  const { t } = useTranslation();

  const handleUpdate = useCallback(
    data => {
      const updatedClient = {
        id: client?.id,
        name: data.name?.trim(),
        acn: data.acn?.trim(),
        abn: data.abn?.trim(),
        logo
      };
      updateClient({ client: updatedClient })
        .unwrap()
        .then(() => {
          goBack();
        });
    },
    [client, goBack, logo, updateClient]
  );
  const yupSchema = yup.object({
    name: yup.string().required(t("common:ui.client.field.nameError"))
  });

  const formContents = useMemo(
    () => (
      <ClientForm
        client={defaultValue}
        setClientLogoModal={setClientLogoModal}
        logo={logo}
        setLogo={setLogo}
      />
    ),
    [defaultValue, logo]
  );

  const renderUpdateClientActions = isValid => {
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={goBack}
        />
        <Button
          disabled={!isValid}
          label={t("common:ui.updateClient.submitLabel")}
          type="submit"
        />
      </>
    );
  };

  return (
    <>
      <FormPageTemplate
        title={t("common:ui.updateClient.title")}
        sticky
        form={{
          contents: formContents,
          yupSchema,
          handleSubmit: handleUpdate,
          handleCancel: goBack,
          disabled: isSubmitting || isSuccess,
          renderPageActions: renderUpdateClientActions
        }}
        isLoading={isLoading}
        renderRawForm={true}
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        other={{
          error: error ? getErrorMessage(error, t) : ""
        }}
      />
      {clientLogoModal}
    </>
  );
};
