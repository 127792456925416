import React, { useCallback, useMemo, useRef, useState } from "react";

import { TFunction } from "i18next";
import {
  DragDropContext,
  OnDragEndResponder,
  OnDragStartResponder
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup.jsx";
import { systemConstants } from "@shared/constants/systemConstants.ts";
import { useLazyGetWorkflowStepQuery } from "@shared/services/actionItemService.js";

import {
  workflowActionName,
  workflowStepName
} from "@app/helpers/actionItems.js";
import { SwimlaneItem, Swimlane as SwimlaneType } from "@app/types";

import { Box, Inline } from "@fermions";

import ActionConfirmation from "@organisms/QueryActions/ActionConfirmation/ActionConfirmation.tsx";

import { Swimlane } from "@components/templates/KanbanTemplate/Swimlane/Swimlane";

import "./KanbanTemplate.scss";

export interface KanbanTemplateProps {
  swimlanes: SwimlaneType[];
  renderItem: (item: SwimlaneItem, isDragging?: boolean) => React.JSX.Element;
  onClickItem?: (item: SwimlaneItem) => void;
  onMoveItem: (
    destinationId: string,
    item: SwimlaneItem,
    destinationAction?: string,
    confirmed?: boolean
  ) => void;
  overridingMessage?: React.JSX.Element | string;
}

type WorkflowConfirmation = {
  title: string;
  message: string;
  translate: boolean;
  confirmKey: string;
};

export type WorkflowInfo = {
  key: string;
  queryStatus: string;
  i18nActionKey: string;
  actionName: string;
  stepName: string;
  actionKey: string;
  confirmationContent: WorkflowConfirmation[];
};

function generateWorkflowStatusMap(nextActions, t: TFunction): WorkflowInfo[] {
  return nextActions.map(curr => {
    return {
      key: curr.nextStep.key,
      queryStatus: curr.nextStep.queryStatus,
      i18nActionKey: curr.config.i18nActionKey ?? curr.config.key,
      actionName: workflowActionName({ workflowAction: curr, t }),
      stepName: workflowStepName({
        workflowStep: curr.nextStep,
        t
      }),
      actionKey: curr.key,
      confirmationContent: curr.config?.beforeUserTriggers?.filter(
        trigger => trigger.type === "confirmationDialog"
      )
    };
  });
}

export const KanbanTemplate = ({
  swimlanes,
  renderItem,
  onClickItem,
  onMoveItem,
  overridingMessage
}: KanbanTemplateProps) => {
  const swimlanesById: { [id: string]: SwimlaneType } = useMemo(
    () =>
      Object.fromEntries(
        swimlanes.map((swimlane: SwimlaneType) => [swimlane.id, swimlane])
      ),
    [swimlanes]
  );
  const [draggingSwimlaneId, setDraggingSwimlaneId] = useState<string>();

  const [fetchWorkflowStep, { data: workFlowSteps, originalArgs, isFetching }] =
    useLazyGetWorkflowStepQuery();

  const [draggingItem, setDraggingItem] = useState<SwimlaneItem>();
  const [destinationAction, setDestinationAction] = useState<string>();
  const [scrollTop, setScrollTop] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);

  const [confirmDestinationAction, setConfirmDestinationAction] =
    useState<string>();
  const [confirmationContent, setConfirmationContent] =
    useState<WorkflowConfirmation>();
  const [destinationDraggableId, setDestinationDraggableId] =
    useState<string>();
  const [destinationItem, setDestinationItem] = useState();

  const { t } = useTranslation();

  const workflowActions = useMemo(() => {
    if (
      draggingItem?.data?.actionItemType.configuration?.workflow &&
      workFlowSteps &&
      draggingItem?.id === originalArgs?.queryId &&
      !isFetching
    ) {
      return generateWorkflowStatusMap(workFlowSteps.nextActions, t);
    }
    return [];
  }, [
    draggingItem?.data?.actionItemType.configuration?.workflow,
    draggingItem?.id,
    isFetching,
    originalArgs?.queryId,
    t,
    workFlowSteps
  ]);

  const onDragStart: OnDragStartResponder = useCallback(
    ({ draggableId, source }) => {
      const item = swimlanesById[source.droppableId]?.items?.find(
        item => String(item.id) == draggableId
      );
      setDraggingSwimlaneId(item.swimlaneId);

      if (
        item?.data?.actionItemType.configuration?.workflow &&
        item?.data?.configType === systemConstants.actionItemTypes.smartForm
      ) {
        fetchWorkflowStep({ queryId: item.data.id });
        item.acceptableLanesToMove = [];
      }
      setDraggingItem(item);
    },
    [swimlanesById, fetchWorkflowStep]
  );

  const handleActionConfirmation = () => {
    onMoveItem?.(
      destinationDraggableId,
      destinationItem,
      confirmDestinationAction,
      true
    );
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setConfirmationContent(undefined);
    setDestinationAction(undefined);
    setDraggingItem(undefined);
    setConfirmDestinationAction(undefined);
    setDestinationDraggableId(undefined);
  };

  const getWorkflowConfirmationModal = () => {
    if (!confirmationContent) {
      return;
    }
    return (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <ActionConfirmation
          title={confirmationContent.title}
          message={confirmationContent.message}
          handleSubmit={handleActionConfirmation}
          handleCancel={handleCloseModal}
          error=""
        />
      </Popup>
    );
  };

  const onDragEnd: OnDragEndResponder = ({
    draggableId,
    source,
    destination
  }) => {
    setDraggingItem(undefined);

    // dropped outside the list
    if (!destination || !destinationAction) {
      return;
    }
    const sourceLane = source.droppableId;
    const destinationLane = destination.droppableId;

    const item = swimlanesById[source.droppableId]?.items?.find(
      item => String(item.id) == draggableId
    );

    if (
      !item ||
      !isAllowedToMove(
        item,
        destination.droppableId,
        source.droppableId,
        workflowActions
      )
    ) {
      return;
    }

    const targetAction = workflowActions?.find(
      action => action.actionKey === destinationAction
    );

    if (
      targetAction?.confirmationContent &&
      targetAction.confirmationContent.length > 0
    ) {
      setConfirmDestinationAction(destinationAction);
      setConfirmationContent(targetAction.confirmationContent[0]);
      setDestinationDraggableId(destination.droppableId);
      setDestinationItem(item);
      return;
    }

    if (sourceLane !== destinationLane) {
      onMoveItem?.(destination.droppableId, item, destinationAction);
    }
  };

  const smoothScrollTo = (
    element: HTMLElement,
    target: number,
    duration: number = 500
  ) => {
    const start = element.scrollTop;
    const change = target - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      element.scrollTop = start + change * progress;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const currentScrollTop = event.currentTarget.scrollTop;
    if (draggingItem && currentScrollTop - lastScrollTop.current < -20) {
      smoothScrollTo(event.currentTarget, scrollTop);
    } else if (!draggingItem) {
      lastScrollTop.current = currentScrollTop;
    }
    setScrollTop(event.currentTarget.scrollTop);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {getWorkflowConfirmationModal()}
      <Inline
        width="100"
        className="kanban-template"
        onScroll={handleScroll}
        ref={scrollRef}
      >
        <Inline width="100" className="kanban-template__lanes">
          {swimlanes.map((swimlane: SwimlaneType) => (
            <Swimlane
              key={swimlane.id}
              swimlane={swimlane}
              renderItem={renderItem}
              onClickItem={onClickItem}
              isDragging={!!draggingItem}
              draggingItem={draggingItem}
              isValidZone={
                draggingItem &&
                isAllowedToMove(
                  draggingItem,
                  swimlane.id,
                  draggingSwimlaneId,
                  workflowActions,
                  isFetching
                )
              }
              setDestinationAction={setDestinationAction}
              workflowActions={workflowActions}
              distanceToTop={scrollTop}
            />
          ))}
        </Inline>
        {overridingMessage && (
          <Box className="kanban-template__overriding-message">
            {overridingMessage}
          </Box>
        )}
      </Inline>
    </DragDropContext>
  );
};

function isAllowedToMove(
  item: SwimlaneItem,
  swimlaneId: string,
  draggingSwimlaneId?: string,
  workflowActions?: WorkflowInfo[],
  isFetching?: boolean
) {
  return (
    (item?.acceptableLanesToMove?.includes(swimlaneId) ||
      workflowActions?.map(curr => curr.queryStatus).includes(swimlaneId)) &&
    swimlaneId !== draggingSwimlaneId &&
    !isFetching
  );
}
