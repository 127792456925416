import React from "react";

import PropTypes from "prop-types";

import "./input.scss";

const Input = React.forwardRef((props, ref) => {
  const getDefaultMaxLength = () => {
    switch (props.type) {
      case "password":
        return 1024;
      case "text":
        return 255;
      default:
        return 524288;
    }
  };
  return (
    <div
      className={
        "input-container" + (props.error ? " input-container__error" : "")
      }
    >
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={
          "form-control" + (props.size === "small" ? " input-box-small" : "")
        }
        onChange={props.handleChange}
        onKeyDown={props.handleKeyDown}
        onBlur={props.handleFocusOut}
        onFocus={props.handleFocus}
        value={props.value}
        pattern={props.pattern}
        ref={ref}
        autoFocus={props.autoFocus}
        maxLength={props.maxLength || getDefaultMaxLength()}
        autoComplete={props.autoComplete || "off"}
        disabled={props.disabled}
        aria-label={props.type}
      />
      {props.search && (
        <i
          onClick={props.onSearchClick}
          className="material-icons input-search-icon"
        >
          search
        </i>
      )}
    </div>
  );
});

Input.propTypes = {
  error: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
  handleFocusOut: PropTypes.func,
  value: PropTypes.any.isRequired,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  handleFocus: PropTypes.func
};

export default Input;
