const NUMBER_FILTER = {
  applyTo: "number",
  label: "Is",
  valueType: "text"
};

export const filters = [
  {
    ...NUMBER_FILTER,
    name: "Greater than",
    value: "gt",
    evaluate: (a, b) => a > b
  },
  {
    ...NUMBER_FILTER,
    name: "Greater than or equal to",
    value: "gte",
    evaluate: (a, b) => a >= b
  },
  {
    ...NUMBER_FILTER,
    name: "Less than",
    value: "lt",
    evaluate: (a, b) => a < b
  },
  {
    ...NUMBER_FILTER,
    name: "Less than or equal to",
    value: "lte",
    evaluate: (a, b) => a <= b
  },
  {
    ...NUMBER_FILTER,
    name: "Equal to",
    value: "eq",
    evaluate: (a, b) => a == b
  },
  {
    ...NUMBER_FILTER,
    name: "Not equal to",
    value: "neq",
    evaluate: (a, b) => a != b
  },
  {
    name: "contains",
    value: "contains",
    applyTo: "string",
    label: "",
    valueType: "text",
    evaluate: (a, b) =>
      String(a)?.toLowerCase().includes(String(b)?.toLowerCase())
  },
  {
    name: "is",
    value: "is",
    applyTo: "string",
    label: "",
    valueType: "lookup",
    evaluate: (a, b) => String(a)?.toLowerCase() === String(b)?.toLowerCase()
  },
  {
    name: "is not",
    value: "isNot",
    applyTo: "string",
    label: "",
    valueType: "lookup",
    evaluate: (a, b) => String(a)?.toLowerCase() !== String(b)?.toLowerCase()
  }
];

export function getOperatorsForNumberTypes() {
  return filters.filter(({ applyTo }) => applyTo === "number");
}

export function getOperatorsForOtherTypes() {
  return filters.filter(({ applyTo }) => applyTo !== "number");
}

export function evaluateCondition(a, b, op) {
  const filter = filters.find(({ value }) => value === op);
  if (filter) {
    return filter.evaluate(a, b);
  }
  return true;
}
