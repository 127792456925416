import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useTooltip } from "@shared/hooks/useTooltip";

import { Icon, IconColor, IconSize } from "@atoms/Icon";

import OTTooltip from "@components/atoms/OTTooltip";

import "./EntitiesListIconCell.scss";

const EntitiesListIconCell = props => {
  const { entities, color } = props;
  const { t } = useTranslation();
  const { elementProps, showTooltip } = useTooltip();

  const maxEntities = 10;
  const numEntitiesOverMax = entities.splice(maxEntities)?.length;

  const hoverElement = (
    <span className="relevant-entities">
      {`${t("requests:requests.ui.smartForm.relevantEntities.label")}:`}
      <span className="relevant-entities__names">
        {entities.join(", ")}
        {numEntitiesOverMax
          ? t("requests:requests.ui.smartForm.numOfOtherEntities", {
              count: numEntitiesOverMax
            })
          : ""}
      </span>
    </span>
  );
  return entities.length ? (
    <div
      className="entities-list-icon-cell"
      role="cell-relevant-entities-icon"
      {...elementProps}
    >
      <Icon name="lan" size={IconSize.S} color={color ?? IconColor.INHERIT} />
      {showTooltip && (
        <OTTooltip visible={showTooltip} content={hoverElement} />
      )}
    </div>
  ) : null;
};

EntitiesListIconCell.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.oneOf(["muted", "accent"])
};

export default EntitiesListIconCell;
