import React, { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useGetProjectFoldersAndDocuments } from "@shared/hooks";
import { manageProjectDocumentsService } from "@shared/services/manageProjectDocumentsService";

import UploadFileBox from "@components/organisms/UploadFileBox/UploadFileBox";

const SignedPackageUpload = props => {
  const { project, t } = props;
  const files = useRef(null);
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const { getProjectFoldersAndDocuments } = useGetProjectFoldersAndDocuments();
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    setDocuments(manageProjectDocuments.foldersAndDocuments.documents);
  }, [manageProjectDocuments.foldersAndDocuments.documents]);

  const onUploadComplete = droppedFiles => {
    files.current = droppedFiles;
    getProjectFoldersAndDocuments(project);
  };

  useEffect(() => {
    if (documents && files.current) {
      const documentIds = documents
        .filter(document =>
          files.current.some(file => file.name === document.name)
        )
        .map(document => document.id);
      manageProjectDocumentsService.signProjectDocument(
        documentIds,
        project.id
      );
      files.current = null;
    }
  }, [documents, project.id]);

  return (
    <>
      <UploadFileBox
        project={project}
        boxClassName={"box-class"}
        onUploadComplete={onUploadComplete}
        title={t("common:ui.documents.uploadFile")}
        subtext={`Uploaded signed FBT documents are stored in ${
          project.content?.dataRepositoryTitle ?? "Your Data Repository"
        }`}
        checkIsMember={true}
        useLegacyApiModeForOldPages={true}
      />
    </>
  );
};

export default withTranslation()(SignedPackageUpload);
