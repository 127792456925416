import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  manageDocumentDownloadsActions,
  manageExcelDocumentActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants";
import documentUtilities from "@shared/helpers/documentUtilities";
import websheetUtilities from "@shared/helpers/websheetUtilities";
import {
  useAuthUser,
  useCleanDocumentMutation,
  useFeatures,
  useGetAnswersQuery,
  useGetProjectMembers,
  useMarkDocumentMutation,
  useRequestPageNavigator,
  useToasts,
  useUpdateQuery,
  useUrlHash
} from "@shared/hooks";
import { useGetActionItemTypesQuery } from "@shared/services";
import {
  useGetCleaningTemplateByIdQuery,
  useGetCleaningTemplatesByTemplateHeadersQuery,
  useSaveCleaningTemplateMutation
} from "@shared/services/cleaningTemplateService";
import {
  useGetDocumentPropertiesQuery,
  useGetDocumentRevisionsQuery
} from "@shared/services/documentService";
import { useUpdateExcelDocumentMutation } from "@shared/services/excelDocumentService";

import ErrorBox from "@shared-components/errorBox/ErrorBox";
import Popup from "@shared-components/popup/Popup";

import { isExcel } from "@app/helpers";
import {
  getActionItemTypeConfiguration,
  getQuestionConfig,
  getWebsheetButtons,
  isEditAvailable
} from "@app/helpers/actionItems";
import { useHasUserInteraction } from "@app/hooks/useHasUserInteraction";
import { useWebsheetFileLocking } from "@app/hooks/useWebsheetFileLocking";
import { QueryStatus } from "@app/types";

import { Button, ButtonSize } from "@atoms/Button";
import { ButtonVariant } from "@atoms/Button/index.ts";

import CleanseProcessModal from "@components/molecules/CleanseProcessModal";
import Loading from "@components/molecules/Loading/Loading";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import WebSheetActions, {
  ACTION
} from "@components/molecules/WebSheetActions/WebSheetActions";
import EditableWebsheet from "@components/organisms/EditableWebsheet";
import ReassignQuery from "@components/organisms/QueryActions/ReassignQuery";
import UpdateCopiedTo from "@components/organisms/QueryActions/UpdateCopiedTo";
import RejectActionModal from "@components/organisms/RejectActionModalForm";
import SaveCleaningTemplateModal from "@components/organisms/SaveCleaningTemplateModal";
import PreCleansingWizard from "@components/organisms/WebsheetCleaningWizard/steps/PreCleansingWizard/PreCleansingWizard";
import WizardSidePanel from "@components/organisms/WebsheetCleaningWizard/steps/PreCleansingWizard/WizardSidePanel";
import WebsheetDetails from "@components/organisms/WebsheetDetails";
import ModalContentTemplate from "@components/templates/ModalContentTemplate";
import SidePanelContentTemplate from "@components/templates/SidePanelContentTemplate";
import WebsheetTemplate from "@components/templates/WebsheetTemplate";

import "./WebSheetDocument.scss";

const statusTypes = systemConstants.project.queries.status;
const actionItemTypeConstants = systemConstants.actionItemTypes;

// Feature flag for pre-cleansing wizard
const IS_PRE_CLEANSE_ENABLED = false;

const ErrorFallback = ({ resetErrorBoundary }) => {
  resetErrorBoundary();
  return (
    <div className="websheet-document__spinner">
      <Loading />
    </div>
  );
};

function findQuestionIdAndAnswer(query, document) {
  if (!query?.answers) {
    return {};
  }
  const [questionId, answers] =
    Object.entries(query.answers).find(([, answers]) =>
      answers.some(({ value }) => value == document.id)
    ) ?? [];
  if (!questionId) {
    return {};
  }
  const answer = answers.find(({ value }) => value == document.id);
  return { questionId, answer };
}

export const CLEANING_PROCESS_NEW_KEY = "NEW";

const CLEANSING_KEY = "cleansing";
const PROCESS_KEY = "process";

/**
 *
 * @param {{document:object, loading:boolean, title?:string, project:object, query?:object, type: string}} props
 * @returns
 */
const WebSheetDocument = props => {
  const {
    project,
    loading,
    title: pTitle,
    document: pDocument,
    query: pQuery,
    type
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuthUser();
  const { isDownloadWebsheetEnabled, isAiCleaningAssistantEnabled } =
    useFeatures();
  const [updateExcelDocument, { isLoading: isSavingWebsheet }] =
    useUpdateExcelDocumentMutation();
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: project?.engagement?.engagementTypeId,
      projectId: project?.id
    },
    { skip: project?.engagement?.engagementTypeId === undefined }
  );
  const { urlHashDetail, updateUrlHash, clearUrlHash } = useUrlHash();

  const isMounted = useRef(false);
  const [data, setData] = useState([]);
  const websheetRefHandler = useRef(null);
  const websheetRef = useCallback(node => {
    if (node != null) {
      websheetRefHandler.current = node;
    }
  }, []);

  const [currentSheet, setCurrentSheet] = useState({
    sheet: undefined,
    downloaded: undefined
  });
  const [errorTabs, setErrorTabs] = useState([]);
  const [errorMessageObj, setErrorMessageObj] = useState({});
  const [cleaningWizardMode, setCleaningWizardMode] = useState(false);
  const [isAiWizard, setIsAiWizard] = useState(false);
  const [isSheetLoadingLocally, setIsSheetLoadingLocally] = useState(true);
  const [isPreCleanseFinished, setIsPreCleanseFinished] = useState(
    !IS_PRE_CLEANSE_ENABLED
  );
  const [preCleanFormData, setPreCleanFormData] = useState({});
  const [invalidCells, setInvalidCells] = useState({});
  //@ts-expect-error useSelector unknown warning
  const manageExcelDocument = useSelector(state => state.manageExcelDocument);

  const { navigateToRequestPage } = useRequestPageNavigator();
  const [title, setTitle] = useState(pTitle);
  const [document, setDocument] = useState(
    pDocument ? { ...pDocument } : undefined
  );
  const { data: wsProperties } = useGetDocumentPropertiesQuery(
    { documentId: document?.id },
    { skip: !document?.id }
  );
  const {
    acquireFileLock,
    releaseFileLock,
    refreshFileLock,
    fileLock,
    fileLockError
  } = useWebsheetFileLocking();
  const { isUserActive, userLastActive, markUserStillActive } =
    useHasUserInteraction();
  const { showApplyNewCleanToast, showSaveCleanToast } = useToasts();

  const [query, setQuery] = useState(structuredClone(pQuery));
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [inactiveMessage, setInactiveMessage] = useState("");
  const [hasLocalChanges, setHasLocalChanges] = useState(false);
  const { isProjectMember } = useGetProjectMembers(props.project);

  const [errorMessage, setErrorMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const { updateQuery } = useUpdateQuery();

  const [modalOpen, setModalOpen] = useState(false);
  const [contentKey, setContentKey] = useState("");
  const [websheetModalFn, setWebsheetModalFn] = useState(null);
  const [cellValidationError, setCellValidationError] = useState(null);
  const { data: revisions } = useGetDocumentRevisionsQuery(
    { documentId: document?.id },
    { skip: !document?.id }
  );

  const [
    saveCleaningTemplate,
    {
      isSuccess: isSaveCleaningTemplateSuccess,
      isError: isSaveCleaningTemplateError,
      data: newCleaningTemplateData,
      error: saveTemplateError
    }
  ] = useSaveCleaningTemplateMutation();
  const { data: cleaningTemplateData } = useGetCleaningTemplateByIdQuery(
    {
      id: revisions?.[0]?.properties?.createCleaningTemplate
    },
    {
      skip:
        !revisions?.length || !revisions[0]?.properties?.createCleaningTemplate,
      refetchOnMountOrArgChange: true
    }
  );
  const [savedCleaningTemplate, setSavedCleaningTemplate] = useState();

  useEffect(() => {
    setSavedCleaningTemplate(current => {
      if (cleaningTemplateData) {
        return cleaningTemplateData;
      } else if (newCleaningTemplateData?.cleaningTemplate) {
        return newCleaningTemplateData?.cleaningTemplate;
      }
      return current;
    });
  }, [
    savedCleaningTemplate,
    newCleaningTemplateData?.cleaningTemplate,
    cleaningTemplateData
  ]);

  const cleanseName = savedCleaningTemplate?.name;

  const [isReplay, setIsReplay] = useState(false);
  const [savedState, setSavedState] = useState({});
  const [isWebsheetSpinnerDisabled, setIsWebsheetSpinnerDisabled] =
    useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDownloadDocument = useCallback(
    ({ document }) => {
      const downloadName = (() => {
        if (type === "query") {
          return query?.description;
        }
        if (type === "smartForm") {
          return documentUtilities.stripIdFromFileName({
            fileName: data[0]?.fileName,
            id: document.id
          });
        }
        return data[0]?.fileName;
      })();
      dispatch(
        manageDocumentDownloadsActions.downloadDocumentRevision({
          ...document,
          forceLatest: true,
          name: data[0]?.fileName,
          downloadName
        })
      );
    },
    [data, dispatch, query?.description, type]
  );

  const { questionId, answer } = findQuestionIdAndAnswer(query, document);
  const [markDocument, { error, isError: isMarkDocumentError }] =
    useMarkDocumentMutation();

  const [cleanDocument, { data: cleanDocumentDataObject }] =
    useCleanDocumentMutation();
  const isCleaned =
    cleanDocumentDataObject?.isCleaned || revisions?.[0]?.properties?.isCleaned;

  const [isComplete, setIsComplete] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const shouldContinueToNextFile = useRef({
    isContinue: false,
    process: undefined
  });

  useEffect(() => {
    setIsArchived(
      wsProperties?.properties?.status ===
        systemConstants.project.document.status.archived
    );
  }, [wsProperties]);

  useEffect(() => {
    setIsComplete(answer?.complete ?? false);
  }, [answer?.complete]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setContentKey("");
  }, []);

  const handleOpenModal = useCallback(contentKey => {
    setModalOpen(true);
    setContentKey(contentKey);
  }, []);

  useEffect(() => {
    if (revisions?.[0]?.properties?.invalidCells) {
      setInvalidCells(revisions[0].properties.invalidCells);
    }
  }, [revisions]);

  useEffect(() => {
    if (pTitle) {
      setTitle(pTitle);
    }
  }, [pTitle]);

  useEffect(() => {
    if (pQuery) {
      setQuery(structuredClone(pQuery));
    }
  }, [pQuery]);

  useEffect(() => {
    if (document?.id != pDocument?.id) {
      setDocument({ ...pDocument });
    }
  }, [document?.id, pDocument]);

  useEffect(() => {
    if (isMarkDocumentError) {
      setErrorMessage(error?.data?.key ?? error?.data?.message ?? error?.error);
    } else {
      setErrorMessage("");
    }
  }, [error, isMarkDocumentError]);

  useEffect(() => {
    if (isSaveCleaningTemplateError) {
      setModalErrorMessage(
        saveTemplateError?.data?.key ?? saveTemplateError?.data.message
      );
    } else {
      setModalErrorMessage("");
    }
  }, [
    isSaveCleaningTemplateError,
    saveTemplateError?.data?.key,
    saveTemplateError?.data.message
  ]);

  const files = useMemo(
    () => [
      {
        isDeleted: false,
        isNew: false,
        id: document?.id,
        name: document?.name,
        projectId: props.project?.id
      }
    ],
    [document?.id, document?.name, props.project?.id]
  );

  const finishEditSession = useCallback(() => {
    clearUrlHash();
    releaseFileLock();
  }, [releaseFileLock, clearUrlHash]);

  const handleMouseMove = useCallback(
    () => markUserStillActive(),
    [markUserStillActive]
  );

  const switchToEditMode = useCallback(
    (cleaningMode = false) => {
      isProjectMember(
        user,
        t("common:ui.websheet.accessRequiredActionEditFile"),
        () => {
          markUserStillActive();
          acquireFileLock({
            documentId: document?.id,
            documentRevisionId: document?.documentRevisionId
          });
          if (cleaningMode && !isCleaned) {
            setCleaningWizardMode(true);
            setIsWebsheetSpinnerDisabled(false);
          }
        },
        error => {
          handleOpenModal("projectAccess");
          setErrorMessage(error);
        }
      );
    },
    [
      acquireFileLock,
      document?.documentRevisionId,
      document?.id,
      handleOpenModal,
      isCleaned,
      isProjectMember,
      markUserStillActive,
      t,
      user
    ]
  );

  const handleUpdate = useCallback(
    (_, cb, additional) => {
      const result = websheetRefHandler.current?.handleUpdate?.();
      const workbookData = result.websheet ?? result;
      if (document?.documentRevisionId && workbookData) {
        const revisionProperties = {
          ...revisions[0].properties,
          invalidCells
        };
        updateExcelDocument({
          documentId: document.id,
          documentRevisionId: document.documentRevisionId,
          workbookData,
          revisionProperties,
          additional
        })
          .unwrap()
          .then(updatedDocument => cb?.(updatedDocument));
      } else {
        cb?.();
      }
    },
    [
      document.documentRevisionId,
      document.id,
      invalidCells,
      revisions,
      updateExcelDocument
    ]
  );

  const queryConfig = useMemo(
    () => getActionItemTypeConfiguration(query?.queryType, actionItemTypes),
    [query, actionItemTypes]
  );

  const questionConfig = useMemo(
    () => getQuestionConfig(queryConfig, questionId),
    [queryConfig, questionId]
  );

  const templateHeadersName = useMemo(
    () => questionConfig?.templateHeaders?.map(header => header.name),
    [questionConfig]
  );

  const { data: savedCleanTemplates } =
    useGetCleaningTemplatesByTemplateHeadersQuery(
      {
        projectId: project?.id,
        templateHeaders: templateHeadersName
      },
      { skip: !project?.id || !templateHeadersName?.length }
    );

  const enableCleaningWizard = useCallback(() => {
    if (!isReadOnly) finishEditSession();
    setIsSheetLoadingLocally(true);
    if (savedCleanTemplates?.length > 0) {
      handleOpenModal("cleanseWizardProcess");
    } else {
      updateUrlHash(CLEANSING_KEY, true);
      updateUrlHash(PROCESS_KEY, CLEANING_PROCESS_NEW_KEY);
      switchToEditMode(true);
    }
  }, [
    isReadOnly,
    finishEditSession,
    savedCleanTemplates?.length,
    handleOpenModal,
    updateUrlHash,
    switchToEditMode
  ]);

  const disableCleaningWizard = useCallback(() => {
    clearUrlHash();
    setCleaningWizardMode(false);
    setIsSheetLoadingLocally(false);
    if (!isReadOnly) finishEditSession();
  }, [clearUrlHash, finishEditSession, isReadOnly]);

  useEffect(() => {
    if (isReadOnly || isUserActive || cleaningWizardMode) {
      return;
    }
    if (hasLocalChanges) {
      const message =
        "Your session has been inactive for 5 minutes. Your changes have been saved to a new version. Click switch to edit mode to continue";
      setInactiveMessage(message);
      handleUpdate?.(null, finishEditSession);
    } else {
      const message =
        "Your session has been inactive for 5 minutes. Click switch to edit mode to continue";
      setInactiveMessage(message);
      finishEditSession();
    }
  }, [
    cleaningWizardMode,
    finishEditSession,
    handleUpdate,
    hasLocalChanges,
    isReadOnly,
    isUserActive
  ]);

  useEffect(() => {
    if (isReadOnly || isUserActive || !cleaningWizardMode) {
      return;
    }
    const message =
      "Your session has been inactive for 5 minutes. Start cleansing wizard to retry.";
    setInactiveMessage(message);
    disableCleaningWizard();
  }, [
    cleaningWizardMode,
    disableCleaningWizard,
    handleUpdate,
    hasLocalChanges,
    isReadOnly,
    isUserActive
  ]);

  useEffect(() => {
    if (document.id) {
      dispatch(
        manageExcelDocumentActions.getExcelDocumentAsJSON({
          id: document.id,
          documentRevisionId: document.documentRevisionId,
          forceLatest: document.forceLatest
        })
      );
    }
  }, [dispatch, document]);

  useEffect(() => {
    const document = manageExcelDocument?.download?.downloaded;
    const tab = document?.json?.find(
      ({ sheet }) => sheet === currentSheet.sheet
    );
    if (
      currentSheet.sheet &&
      currentSheet.downloaded === false &&
      document?.id &&
      document.documentRevisionId &&
      !tab?.downloaded &&
      manageExcelDocument?.download?.loading === false
    ) {
      dispatch(
        manageExcelDocumentActions.getExcelDocumentAsJSON({
          id: document.id,
          documentRevisionId: document.documentRevisionId,
          forceLatest: false,
          tab: currentSheet.sheet
        })
      );
    }
  }, [
    dispatch,
    currentSheet.sheet,
    currentSheet.downloaded,
    manageExcelDocument?.download?.loading,
    manageExcelDocument?.download?.downloaded
  ]);

  useEffect(() => {
    if (manageExcelDocument.download.downloaded) {
      const newData = structuredClone(
        manageExcelDocument.download.downloaded.json
      );
      if (
        manageExcelDocument.download.selectedTabIndex !== null &&
        manageExcelDocument.download.selectedTabIndex >= 0
      ) {
        newData[manageExcelDocument.download.selectedTabIndex].defaultSheet =
          true;
      }
      if (!newData.some(sheet => sheet?.sheet === currentSheet.sheet)) {
        setCurrentSheet(newData.find(sheet => sheet.state === "visible"));
      }
      setData(newData);
      setHasLocalChanges(false);
      setIsWebsheetSpinnerDisabled(true);
    }
  }, [
    manageExcelDocument.download.downloaded,
    setHasLocalChanges,
    document,
    document?.documentRevisionId,
    document?.id,
    manageExcelDocument.download.selectedTabIndex,
    currentSheet.sheet
  ]);

  useEffect(() => {
    if (!isReadOnly && userLastActive) {
      refreshFileLock();
    }
  }, [isReadOnly, userLastActive, refreshFileLock]);

  useEffect(() => {
    const downloadedFileData = manageExcelDocument.download.downloaded?.json;
    // No downloadedFileData nor lock so most we will allow is read only
    if (!downloadedFileData || !fileLock?.documentRevisionId) {
      setIsReadOnly(true);
      return;
    }

    // DocumentRevision differs from what we loaded initially. SetDocument so we refetch the latest data
    // Once the data has been retrieved (via another useEffect), this useEffect gets called again and it'll continue through
    if (fileLock.documentRevisionId !== document.documentRevisionId) {
      setIsReadOnly(true);
      setDocument({
        ...document,
        id: fileLock.documentId,
        documentRevisionId: fileLock.documentRevisionId,
        forceLatest: false
      });
      return;
    }

    // Lock matches document acquired so we can enable editing mode
    setHasLocalChanges(false);
    setIsReadOnly(false);
    setInactiveMessage("");
  }, [
    fileLock?.documentId,
    fileLock?.documentRevisionId,
    document,
    manageExcelDocument.download.downloaded?.json
  ]);

  useEffect(() => {
    if (fileLockError) {
      disableCleaningWizard();
    }
  }, [disableCleaningWizard, fileLockError]);

  const errorContent = useMemo(() => {
    const message =
      manageExcelDocument.download.error ||
      fileLockError ||
      manageExcelDocument.update.error ||
      inactiveMessage ||
      errorMessage ||
      error?.error;

    if (!message) {
      return null;
    }

    return <ErrorBox type="component" message={message} />;
  }, [
    error?.error,
    errorMessage,
    inactiveMessage,
    manageExcelDocument.download.error,
    fileLockError,
    manageExcelDocument.update.error
  ]);

  const getValidProcessTemplate = useCallback(
    processName => {
      return processName === CLEANING_PROCESS_NEW_KEY
        ? {}
        : savedCleanTemplates?.filter(
            savedTemplate => savedTemplate.name === processName
          )?.[0];
    },
    [savedCleanTemplates]
  );

  useEffect(() => {
    if (!queryConfig?.type) {
      return;
    }
    // The actual config type isn't websheet (or websheet in smartform) redirect appropriately
    if (
      type === "smartForm" &&
      queryConfig?.type !== actionItemTypeConstants.smartForm
    ) {
      navigateToRequestPage(query.id, query.projectId, queryConfig.type, {
        replace: true
      });
    } else if (
      type === "query" &&
      queryConfig?.type !== actionItemTypeConstants.websheet
    ) {
      navigateToRequestPage(query.id, query.projectId, queryConfig.type, {
        replace: true
      });
    }
  }, [query, navigateToRequestPage, queryConfig?.type, type]);

  const onQueryReassigned = useCallback(
    assignedTo => {
      setQuery(prevQuery => ({
        ...prevQuery,
        assignedTo
      }));
      handleCloseModal();
    },
    [handleCloseModal]
  );

  const getReassignModal = useCallback(() => {
    return (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <ReassignQuery
          project={props.project}
          query={query}
          document={document}
          onQueryReassigned={onQueryReassigned}
          onCancel={handleCloseModal}
        />
      </Popup>
    );
  }, [document, handleCloseModal, onQueryReassigned, props.project, query]);

  const copiedToOnUpdate = useCallback(
    ({ copiedTo }) => {
      handleCloseModal();
      setQuery(prevQuery => ({ ...prevQuery, copiedTo }));
    },
    [handleCloseModal]
  );

  const getCopiedToModal = useCallback(
    () => (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <UpdateCopiedTo
          project={props.project}
          query={query}
          document={document}
          queryConfig={queryConfig}
          onUpdate={copiedToOnUpdate}
          onCancel={handleCloseModal}
        />
      </Popup>
    ),
    [
      copiedToOnUpdate,
      document,
      handleCloseModal,
      props.project,
      query,
      queryConfig
    ]
  );

  const handleClose = useCallback(() => {
    handleCloseModal();
    setErrorMessage("");
    setModalErrorMessage("");
  }, [handleCloseModal]);

  const getProjectAccessModal = useCallback(
    () => (
      <ProjectAccessModal
        visibility={true}
        handleClose={handleClose}
        message={errorMessage}
      />
    ),
    [errorMessage, handleClose]
  );

  const handleSubmit = useCallback(() => {
    handleCloseModal();
    window.close();
  }, [handleCloseModal]);

  const getRejectModal = useCallback(
    () => (
      <RejectActionModal
        visibility={true}
        handleSubmit={handleSubmit}
        handleClose={handleCloseModal}
        query={query}
        document={document}
        title={"Draft Workpaper"}
      />
    ),
    [document, handleCloseModal, handleSubmit, query]
  );

  // Gets further information on the question answers
  const { data: questionAnswers } = useGetAnswersQuery(
    { queryId: query?.id, questionId },
    { skip: !query?.id || !questionId, refetchOnMountOrArgChange: true }
  );

  const nextFileId = useMemo(() => {
    const currentQuestionAnswers = questionAnswers?.answers
      ? Object.values(questionAnswers?.answers)?.[0]
      : undefined;
    if (!currentQuestionAnswers) return;

    const isDocumentAlreadyCleansed = answer =>
      answer?.value?.documentRevisions?.[0]?.properties?.isCleaned;

    const currentDocAnswerIndex = currentQuestionAnswers.findIndex(
      answer => answer.value?.id == document.id || answer?.id == document.id
    );
    if (currentDocAnswerIndex === -1) {
      return null;
    }
    // Check answers in order (starting at current doc answer)
    let index =
      currentDocAnswerIndex === currentQuestionAnswers.length - 1
        ? 0
        : currentDocAnswerIndex + 1;

    let nextDocumentToCleanse;
    while (index !== currentDocAnswerIndex && !nextDocumentToCleanse) {
      const currentAnswer = currentQuestionAnswers[index];
      if (
        isExcel(currentAnswer?.value?.name) &&
        !isDocumentAlreadyCleansed(currentAnswer)
      ) {
        nextDocumentToCleanse = currentAnswer;
      }
      index = index === currentQuestionAnswers.length - 1 ? 0 : index + 1;
    }

    return nextDocumentToCleanse?.value?.id;
  }, [document.id, questionAnswers]);

  const openNextFile = useCallback(
    processName => {
      if (nextFileId && project?.id && query?.id && processName) {
        // Go to next file (open in new tab and close current tab so user cannot go back)
        const nextFileLink = `/projects/${project?.id}/smartforms/${query?.id}/websheets/${nextFileId}#${CLEANSING_KEY}=true&${PROCESS_KEY}=${processName}`;
        const newWindow = window.open(nextFileLink, "_blank");
        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed == "undefined"
        ) {
          // Handle popup blocked
          window.location.replace(
            `${window.location.href.split("/projects")[0]}${nextFileLink}`
          );
        } else {
          window.close();
        }
      }
    },
    [nextFileId, project?.id, query?.id]
  );

  const handleContinueToNextFile = useCallback(() => {
    const processName = shouldContinueToNextFile.current.process;
    if (processName === CLEANING_PROCESS_NEW_KEY) {
      handleOpenModal("saveCleaningTemplateBeforeNextFile");
    } else {
      openNextFile(processName);
    }
  }, [handleOpenModal, openNextFile, shouldContinueToNextFile]);

  useEffect(() => {
    if (
      isSaveCleaningTemplateSuccess &&
      shouldContinueToNextFile.current.isContinue
    ) {
      handleContinueToNextFile();
      shouldContinueToNextFile.current = {
        isContinue: false,
        process: undefined
      };
    }
  }, [
    handleContinueToNextFile,
    isSaveCleaningTemplateSuccess,
    shouldContinueToNextFile
  ]);

  const handleSaveTemplate = useCallback(
    async (cleaningTemplateFormData, continueToNextFile) => {
      const payload = {
        id: savedCleaningTemplate?.id,
        ...cleaningTemplateFormData,
        projectId: project?.id
      };
      const savedTemplate = await saveCleaningTemplate({
        cleaningTemplate: payload
      });

      if (!savedTemplate?.error) {
        handleCloseModal();
        clearUrlHash();
        if (continueToNextFile) {
          shouldContinueToNextFile.current = {
            isContinue: false,
            process: undefined
          };
          openNextFile(cleaningTemplateFormData.name);
        }
      }
    },
    [
      clearUrlHash,
      handleCloseModal,
      openNextFile,
      project?.id,
      saveCleaningTemplate,
      savedCleaningTemplate?.id
    ]
  );

  const handleCloseTemplate = useCallback(
    continueToNextFile => {
      handleClose();
      if (continueToNextFile) {
        clearUrlHash();
      }
    },
    [clearUrlHash, handleClose]
  );

  const getSaveCleaningTemplateModal = useCallback(
    (continueToNextFile = false) => (
      <SaveCleaningTemplateModal
        visibility={true}
        handleSubmit={handleSaveTemplate}
        handleClose={handleCloseTemplate}
        errorMessage={modalErrorMessage}
        continueToNextFile={continueToNextFile}
      />
    ),
    [handleCloseTemplate, handleSaveTemplate, modalErrorMessage]
  );

  const onCellValidationError = useCallback(
    validationError => {
      setCellValidationError(validationError);
      handleOpenModal("cellValidationError");
    },
    [handleOpenModal]
  );

  const getCellValidationModal = useCallback(() => {
    return (
      <ModalContentTemplate
        header={{
          title: (
            <ErrorBox
              type="component"
              message={t(
                "requests:requests.websheet.cell.validation.error.title"
              )}
            />
          )
        }}
        body={{
          message: cellValidationError?.message ?? ""
        }}
        footer={{
          submitText: t("requests:requests.ui.modal.button.close"),
          handleSubmit: handleCloseModal,
          hideCancel: true,
          handleCancel: handleCloseModal
        }}
      />
    );
  }, [cellValidationError?.message, handleCloseModal, t]);

  const getCleanseProcessModal = useCallback(() => {
    updateUrlHash(CLEANSING_KEY, true);

    const title = t(
      "common:ui.websheet.actions.cleaningWizard.savedProcessModal.title"
    );
    const cancelSelectCleanseProcess = () => {
      setIsSheetLoadingLocally(false);
      clearUrlHash();
      handleCloseModal();
    };

    const handleSelectCleanseProcess = item => {
      const { savedProcess, isNew } = item;
      setSavedState(savedProcess);
      setIsReplay(!isNew);
      updateUrlHash(
        PROCESS_KEY,
        !isNew ? savedProcess.name : CLEANING_PROCESS_NEW_KEY
      );
      switchToEditMode(true);
      handleCloseModal();
    };

    const savedProcess = [...(savedCleanTemplates ?? [])];
    // savedProcess.push({ name: "AI_WIZARD", isNew: true });

    return (
      <CleanseProcessModal
        visibility={true}
        handleNext={handleSelectCleanseProcess}
        handleClose={cancelSelectCleanseProcess}
        title={title}
        savedProcess={savedProcess}
      />
    );
  }, [
    t,
    savedCleanTemplates,
    updateUrlHash,
    handleCloseModal,
    clearUrlHash,
    switchToEditMode
  ]);

  const getModalContent = useCallback(() => {
    switch (contentKey) {
      case "reassign":
        return getReassignModal();
      case "copiedTo":
        return getCopiedToModal();
      case "projectAccess":
        return getProjectAccessModal();
      case "reject":
        return getRejectModal();
      case "cellValidationError":
        return getCellValidationModal();
      case "saveCleaningTemplate":
        return getSaveCleaningTemplateModal();
      case "saveCleaningTemplateBeforeNextFile":
        return getSaveCleaningTemplateModal(true);
      case "websheetModal":
        return websheetModalFn?.() ?? <></>;
      case "cleanseWizardProcess":
        return getCleanseProcessModal();
      default:
        return <></>;
    }
  }, [
    contentKey,
    getReassignModal,
    getCopiedToModal,
    getProjectAccessModal,
    getRejectModal,
    getCellValidationModal,
    getCleanseProcessModal,
    getSaveCleaningTemplateModal,
    websheetModalFn
  ]);

  const handleSwitchToEditMode = useCallback(() => {
    switchToEditMode();
  }, [switchToEditMode]);

  const showReassignRequestPopup = useCallback(() => {
    isProjectMember(
      user,
      t("common:ui.websheet.accessRequiredActionReassignRequest"),
      () => {
        handleOpenModal("reassign");
      },
      error => {
        handleOpenModal("projectAccess");
        setErrorMessage(error);
      }
    );
  }, [handleOpenModal, isProjectMember, t, user]);

  const showRejectActionPopup = useCallback(() => {
    isProjectMember(
      user,
      "",
      () => {
        handleOpenModal("reject");
      },
      error => {
        handleOpenModal("projectAccess");
        setErrorMessage(error);
      }
    );
  }, [handleOpenModal, isProjectMember, user]);

  const isQueryActive = useMemo(
    () =>
      query?.status === systemConstants.project.queries.status.open ||
      query?.status === systemConstants.project.queries.status.responded,
    [query?.status]
  );

  const isQueryDraft = useMemo(
    () => query?.status === QueryStatus.DRAFT,
    [query?.status]
  );

  const doMarkDocument = useCallback(() => {
    setIsComplete(complete => {
      const isNowComplete = !complete;
      markDocument({
        answer: { ...answer, complete: isNowComplete },
        queryId: query.id,
        questionId
      });
      if (isNowComplete) {
        clearUrlHash();
      }
      return isNowComplete;
    });
  }, [answer, markDocument, query?.id, questionId, clearUrlHash]);

  const handleAiCleaningAssistant = useCallback(() => {
    setIsAiWizard(true);
    switchToEditMode(true);
  }, [switchToEditMode]);

  const handleComplete = useCallback(() => {
    if (!query || !questionId || !answer) {
      return;
    }
    isProjectMember(
      user,
      "",
      () => {
        if (isReadOnly) {
          doMarkDocument();
        } else {
          handleUpdate?.(null, () => {
            doMarkDocument();
            finishEditSession();
          });
        }
      },
      error => {
        handleOpenModal("projectAccess");
        setErrorMessage(error);
      }
    );
  }, [
    query,
    questionId,
    answer,
    isProjectMember,
    user,
    isReadOnly,
    doMarkDocument,
    handleUpdate,
    finishEditSession,
    handleOpenModal
  ]);

  const handleApprove = useCallback(() => {
    isProjectMember(
      user,
      "",
      () => {
        handleUpdate?.(null, () =>
          updateQuery(
            {
              ...query,
              reassign: true,
              status: systemConstants.project.queries.status.closed,
              assignedTo: query.requestedBy,
              properties: {
                flag: systemConstants.project.queries.flags.approved
              },
              files
            },
            () => window.close()
          )
        );
      },
      error => {
        handleOpenModal("projectAccess");
        setErrorMessage(error);
      }
    );
  }, [
    files,
    handleOpenModal,
    handleUpdate,
    isProjectMember,
    query,
    updateQuery,
    user
  ]);

  const handleReadyForReview = useCallback(() => {
    isProjectMember(
      user,
      t("common:ui.websheet.accessRequiredActionSendForReview"),
      () => {
        handleUpdate?.(null, () =>
          updateQuery(
            {
              ...query,
              reassign: true,
              assignedTo: query.requestedBy,
              files
            },
            () => window.close()
          )
        );
      },
      error => {
        handleOpenModal("projectAccess");
        setErrorMessage(error);
      }
    );
  }, [
    files,
    handleOpenModal,
    handleUpdate,
    isProjectMember,
    query,
    t,
    updateQuery,
    user
  ]);

  const handleSaveCleanseRecord = useCallback(
    async (cleanDocumentDataObject, completedCleaningTemplate) => {
      const payload = {
        properties: {
          ...completedCleaningTemplate,
          templateHeaders: templateHeadersName,
          questionId
        },
        projectId: project?.id,
        revisionId: cleanDocumentDataObject.documentRevision.id,
        queryId: query?.id
      };
      saveCleaningTemplate({
        cleaningTemplate: payload
      })
        .unwrap()
        .then(() => {
          const templateName = savedCleaningTemplate?.name ?? savedState.name;
          if (templateName) {
            showSaveCleanToast({
              fileName: data[0]?.fileName,
              templateName
            });
          } else {
            showApplyNewCleanToast();
          }
        });
    },
    [
      data,
      project?.id,
      query?.id,
      questionId,
      saveCleaningTemplate,
      savedCleaningTemplate,
      savedState.name,
      showApplyNewCleanToast,
      showSaveCleanToast,
      templateHeadersName
    ]
  );

  const handleCompleteCleaning = useCallback(
    (continueToNextFile = false) => {
      shouldContinueToNextFile.current = {
        isContinue: continueToNextFile,
        process: urlHashDetail.get(PROCESS_KEY) || CLEANING_PROCESS_NEW_KEY
      };
      handleUpdate?.(
        null,
        updatedDocument => {
          const result = websheetRefHandler.current?.handleUpdate?.();
          const completedCleaningTemplate = result.savedState;
          if (!updatedDocument?.id) {
            return;
          }
          dispatch(
            manageExcelDocumentActions.getExcelDocumentAsJSON({
              id: updatedDocument.id,
              forceLatest: true
            })
          );

          cleanDocument({
            queryId: query?.id,
            documentRevisionId: updatedDocument.documentRevisionId
          })
            .unwrap()
            .then(data => {
              if (completedCleaningTemplate) {
                handleSaveCleanseRecord(data, completedCleaningTemplate);
              }
              disableCleaningWizard();
            });
        },
        { isCleaned: true }
      );
    },
    [
      urlHashDetail,
      handleUpdate,
      dispatch,
      cleanDocument,
      query?.id,
      disableCleaningWizard,
      handleSaveCleanseRecord
    ]
  );

  useEffect(() => {
    if (!cleanDocumentDataObject) {
      return;
    }
    setQuery(prev => {
      const { answer = null } = findQuestionIdAndAnswer(prev, {
        id: cleanDocumentDataObject.value
      });
      if (!answer) {
        return prev;
      }
      answer.complete = cleanDocumentDataObject.complete;
      setIsComplete(cleanDocumentDataObject.complete);
      return { ...prev };
    });
  }, [cleanDocumentDataObject]);

  const handleSave = useCallback(() => {
    handleUpdate(null, finishEditSession);
    setErrorMessage("");
  }, [finishEditSession, handleUpdate]);

  const saveButton = useMemo(
    () => (
      <Button
        iconName="done"
        label={t("ui.websheet.actions.saveAndSwitchToViewMode")}
        variant={ButtonVariant.TEXT}
        size={ButtonSize.MINIMAL}
        onClick={handleSave}
        disabled={
          !!(
            !manageExcelDocument.download?.downloaded &&
            manageExcelDocument.download?.error
          )
        }
      />
    ),
    [
      handleSave,
      manageExcelDocument.download?.downloaded,
      manageExcelDocument.download?.error,
      t
    ]
  );

  const contentDetails = useMemo(() => {
    return (
      !cleaningWizardMode && (
        <WebsheetDetails
          project={project}
          query={query}
          actionItemConfig={queryConfig}
          websheetProperties={wsProperties}
          type={type}
          handleOpenModal={handleOpenModal}
        />
      )
    );
  }, [
    cleaningWizardMode,
    handleOpenModal,
    project,
    query,
    queryConfig,
    type,
    wsProperties
  ]);

  const showEditOrSaveButton = useMemo(() => {
    const editActionAvailable = isEditAvailable(query, actionItemTypes);
    if (
      !query ||
      query?.status === statusTypes.closed ||
      !editActionAvailable ||
      cleaningWizardMode ||
      isArchived ||
      isSavingWebsheet
    ) {
      return false;
    }

    return true;
  }, [
    actionItemTypes,
    cleaningWizardMode,
    isArchived,
    isSavingWebsheet,
    query
  ]);

  const getQueryActionButtons = useCallback(() => {
    const showWebsheetButtons = props.document?.showWebsheetButtons ?? true;
    return showWebsheetButtons && actionItemTypes
      ? getWebsheetButtons(query?.queryType, actionItemTypes)
      : [];
  }, [actionItemTypes, query?.queryType, props.document?.showWebsheetButtons]);

  const switchCleaningMode = useCallback(() => {
    if (cleaningWizardMode) {
      setIsPreCleanseFinished(!IS_PRE_CLEANSE_ENABLED);
      disableCleaningWizard();
      setIsAiWizard(false);
    } else if (isCleaned) {
      handleOpenModal("saveCleaningTemplate");
    } else if (!isReadOnly) {
      handleUpdate(null, enableCleaningWizard);
    } else {
      enableCleaningWizard();
    }
  }, [
    cleaningWizardMode,
    disableCleaningWizard,
    enableCleaningWizard,
    handleOpenModal,
    handleUpdate,
    isCleaned,
    isReadOnly
  ]);

  useEffect(() => {
    if (
      isMounted.current &&
      savedCleanTemplates !== undefined &&
      !isCleaned &&
      !isComplete &&
      !fileLock &&
      !cleaningWizardMode &&
      window.location.hash.includes(`${CLEANSING_KEY}=true`)
    ) {
      const currentProcess = urlHashDetail.get(PROCESS_KEY) || "";
      if (savedCleanTemplates?.length > 0 && currentProcess) {
        handleCloseModal();
        const validProcessTemplate = getValidProcessTemplate(currentProcess);
        if (validProcessTemplate) {
          setSavedState(validProcessTemplate);
          setIsReplay(currentProcess !== CLEANING_PROCESS_NEW_KEY);
          switchToEditMode(true);
          return;
        }
        // Process not found in savedCleanTemplates
        updateUrlHash(PROCESS_KEY, undefined);
      }
      switchCleaningMode();
    } else if (
      isMounted.current &&
      !isCleaned &&
      urlHashDetail.get(CLEANSING_KEY) === "true" &&
      !!getValidProcessTemplate(urlHashDetail.get(PROCESS_KEY)) &&
      isComplete
    ) {
      // Mark document as incomplete when forcing cleansing through hash
      handleComplete();
    } else if (
      isMounted.current &&
      isCleaned &&
      urlHashDetail.get(CLEANSING_KEY) &&
      urlHashDetail.get(PROCESS_KEY)
    ) {
      clearUrlHash();
    }
  }, [
    cleaningWizardMode,
    clearUrlHash,
    fileLock,
    getValidProcessTemplate,
    handleCloseModal,
    handleComplete,
    isCleaned,
    isComplete,
    savedCleanTemplates,
    switchCleaningMode,
    switchToEditMode,
    updateUrlHash,
    urlHashDetail
  ]);

  const requestHandlers = useMemo(() => {
    return {
      [ACTION.reassign]: showReassignRequestPopup,
      [ACTION.review]: handleReadyForReview,
      [ACTION.approve]: handleApprove,
      [ACTION.reject]: showRejectActionPopup,
      [ACTION.complete]: handleComplete,
      [ACTION.cleaningWizard]: switchCleaningMode,
      [ACTION.aiCleaningAssistant]: handleAiCleaningAssistant
    };
  }, [
    handleAiCleaningAssistant,
    handleApprove,
    handleComplete,
    handleReadyForReview,
    showReassignRequestPopup,
    showRejectActionPopup,
    switchCleaningMode
  ]);

  const isEntityAssigned = useMemo(
    () =>
      !query?.entities?.length || wsProperties?.properties?.entities?.length,
    [query?.entities?.length, wsProperties?.properties?.entities?.length]
  );

  const disabledStates = useMemo(
    () => ({
      [ACTION.reassign]: !isQueryActive && !isQueryDraft,
      [ACTION.approve]: !isQueryActive,
      [ACTION.reject]: !isQueryActive,
      [ACTION.download]: !currentSheet.downloaded,
      [ACTION.review]:
        query?.status === systemConstants.project.queries.status.closed,
      [ACTION.complete]: !isQueryActive || !isEntityAssigned || isArchived,
      [ACTION.cleaningWizard]:
        !isQueryActive ||
        !isEntityAssigned ||
        (isComplete && !isCleaned) ||
        cleanseName ||
        isSheetLoadingLocally
    }),
    [
      isQueryActive,
      isQueryDraft,
      query?.status,
      isEntityAssigned,
      isArchived,
      isComplete,
      isCleaned,
      cleanseName,
      currentSheet,
      isSheetLoadingLocally
    ]
  );

  const wizardCleaningStates = useMemo(() => {
    const canCleanWebsheet = questionConfig?.templateHeaders?.length;
    const getStartHoverElement = () => {
      if (!isEntityAssigned) {
        return t("common:ui.websheet.actions.cleaningWizard.hover.entities");
      } else if (isComplete) {
        return t("ui.websheet.actions.cleaningWizard.promptMessage", {
          context: "completed"
        });
      }
    };
    const startHoverElement = getStartHoverElement();
    const result = [
      {
        isVisible: isQueryActive && canCleanWebsheet,
        label: t("common:ui.websheet.actions.cleaningWizard.start"),
        variant: "primary",
        hoverElement: startHoverElement
      },
      {
        isVisible:
          isQueryActive &&
          canCleanWebsheet &&
          (wsProperties?.properties?.entities?.length < 2 ||
            isPreCleanseFinished),
        label: t("common:ui.websheet.actions.cleaningWizard.cancel"),
        variant: "secondary"
      }
    ];
    if (isCleaned) {
      result[0] = {
        isVisible: canCleanWebsheet,
        label: cleanseName
          ? t("ui.websheet.actions.cleaningWizard.cleanseSaved", {
              name: cleanseName
            })
          : t("common:ui.websheet.actions.cleaningWizard.saveTemplate"),
        variant: "primary"
      };
    }
    return result;
  }, [
    questionConfig?.templateHeaders?.length,
    t,
    wsProperties?.properties?.entities?.length,
    isPreCleanseFinished,
    isCleaned,
    isEntityAssigned,
    isComplete,
    cleanseName,
    isQueryActive
  ]);

  const customStates = useMemo(() => {
    return {
      [ACTION.complete]: {
        isVisible: isQueryActive
      },
      [ACTION.cleaningWizard]: wizardCleaningStates[+cleaningWizardMode],
      [ACTION.aiCleaningAssistant]: {
        isVisible:
          isAiCleaningAssistantEnabled &&
          wizardCleaningStates[+cleaningWizardMode].isVisible &&
          !isCleaned,
        label: t("common:ui.websheet.actions.aiCleaningAssistant.label")
      }
    };
  }, [
    isQueryActive,
    wizardCleaningStates,
    cleaningWizardMode,
    isAiCleaningAssistantEnabled,
    isCleaned,
    t
  ]);

  const hasErrorInCurrentSheet = errorTabs.some(
    sheet => sheet === currentSheet.sheet
  );

  const switchToEditModeButton = useMemo(
    () => (
      <Button
        iconName="edit"
        label={t("common:ui.websheet.actions.switchToEditMode")}
        variant={ButtonVariant.TEXT}
        size={ButtonSize.MINIMAL}
        className={"websheet-document__switchModeButton"}
        onClick={handleSwitchToEditMode}
        disabled={hasErrorInCurrentSheet}
      />
    ),
    [handleSwitchToEditMode, hasErrorInCurrentSheet, t]
  );

  const headerActions = useMemo(() => {
    return (
      <>
        {isDownloadWebsheetEnabled && (
          <Button
            disabled={disabledStates[ACTION.download]}
            variant={ButtonVariant.TEXT}
            iconName="download"
            label={t("common:ui.websheet.actions.download")}
            skipTextTransform
            onClick={() => {
              handleDownloadDocument({
                document
              });
            }}
          />
        )}
        <WebSheetActions
          isReadOnly={isReadOnly}
          isComplete={isComplete}
          isSingleEntity={query?.entities?.length === 1}
          type={type}
          queryActionsConfig={getQueryActionButtons()}
          requestHandlers={requestHandlers}
          disabledStates={disabledStates}
          customStates={customStates}
          activeStates={cleaningWizardMode ? [ACTION.cleaningWizard] : null}
          user={user}
        />
      </>
    );
  }, [
    cleaningWizardMode,
    customStates,
    disabledStates,
    document,
    getQueryActionButtons,
    handleDownloadDocument,
    isComplete,
    isDownloadWebsheetEnabled,
    isReadOnly,
    query?.entities,
    requestHandlers,
    t,
    type,
    user
  ]);

  useEffect(() => {
    if (
      !title &&
      manageExcelDocument.download.downloaded?.json?.[0]?.fileName
    ) {
      setTitle(manageExcelDocument.download.downloaded.json[0].fileName);
    }
  }, [manageExcelDocument.download.downloaded?.json, title]);

  useEffect(() => {
    if (isReadOnly) {
      window.removeEventListener("unload", finishEditSession);
      return;
    }
    window.addEventListener("unload", finishEditSession);

    return () => {
      window.removeEventListener("unload", finishEditSession);
    };
  }, [isReadOnly, finishEditSession]);

  const onCurrentSheetNameChanged = useCallback(
    newCurrentSheetName => {
      if (
        !newCurrentSheetName ||
        (newCurrentSheetName === currentSheet.sheet && currentSheet.downloaded)
      ) {
        return;
      }
      const sheet = data.find(sheet => sheet.sheet === newCurrentSheetName);
      if (sheet) {
        setCurrentSheet(sheet);
      }
    },
    [currentSheet?.sheet, currentSheet?.downloaded, data]
  );

  const handleError = useCallback(() => {
    setErrorTabs(prev => {
      const s = new Set(prev);
      s.add(currentSheet.sheet);
      return [...s];
    });
    const errorCellRef =
      websheetUtilities.getMultiFontsErrorCellRef(currentSheet);
    let errorMessageObj = {};
    if (errorCellRef) {
      errorMessageObj = {
        key: "common:ui.websheet.errors",
        context: "multipleFonts",
        cellRef: errorCellRef
      };
    } else if (isDownloadWebsheetEnabled) {
      errorMessageObj = {
        key: "common:ui.websheet.errors",
        context: "defaultWithDownload"
      };
    } else {
      errorMessageObj = {
        key: "common:ui.websheet.errors",
        context: "defaultWithoutDownload"
      };
    }
    setErrorMessageObj({ [currentSheet.sheet]: errorMessageObj });
  }, [currentSheet, isDownloadWebsheetEnabled]);

  useEffect(() => {
    const currentErrorMessage = errorMessageObj[currentSheet.sheet];
    if (currentErrorMessage) {
      const errorMessage = t(currentErrorMessage.key, {
        ...currentErrorMessage
      });
      const getNewCurrentSheet = sheetObj => ({
        ...sheetObj,
        ...websheetUtilities.getErrorSheetDataTemplate(errorMessage)
      });

      setCurrentSheet(prev => getNewCurrentSheet(prev));
      setData(data =>
        data.map(sheetObj =>
          sheetObj.sheet === currentSheet.sheet
            ? getNewCurrentSheet(sheetObj)
            : sheetObj
        )
      );
      setErrorMessageObj({});
    }
  }, [currentSheet.sheet, errorMessageObj, onCurrentSheetNameChanged, t]);

  const setWebsheetModalRenderer = useCallback(fn => {
    setWebsheetModalFn(() => fn);
  }, []);

  const renderSidePanelWizard = useCallback(() => {
    return (
      <WizardSidePanel
        setIsPreCleanseFinished={setIsPreCleanseFinished}
        switchCleaningMode={switchCleaningMode}
        wizardEntities={wsProperties?.properties?.entities}
        data={data}
        setPreCleanFormData={setPreCleanFormData}
      />
    );
  }, [
    setIsPreCleanseFinished,
    switchCleaningMode,
    wsProperties?.properties?.entities,
    data
  ]);

  const handleInvalidCells = useCallback(
    (cellId, isValid, currentSheetName) => {
      if (!currentSheetName) {
        return;
      }
      if (isValid) {
        setInvalidCells(prev =>
          prev[currentSheetName]?.some(cell => cell === cellId)
            ? {
                ...prev,
                [currentSheetName]: prev[currentSheetName].filter(
                  cell => cell !== cellId
                )
              }
            : prev
        );
      } else {
        setInvalidCells(prev => {
          const prevInvalidCells = prev[currentSheetName] ?? [];
          return {
            ...prev,
            [currentSheetName]: [...prevInvalidCells, cellId]
          };
        });
      }
    },
    []
  );

  const wizardSidePanel = (
    <SidePanelContentTemplate
      title={t(
        "common:ui.websheet.actions.cleaningWizard.preCleanse.assignEntitiesToTabs"
      )}
      content={renderSidePanelWizard()}
      sidePanelContentClassName="pre-cleanse-wizard__side-panel"
    />
  );

  const renderCleaningWizard = useCallback(() => {
    if (
      !fileLock?.documentRevisionId ||
      fileLock?.documentRevisionId !== document?.documentRevisionId
    ) {
      return <></>;
    }

    return (
      <ErrorBoundary onError={handleError} FallbackComponent={ErrorFallback}>
        <PreCleansingWizard
          loading={
            manageExcelDocument?.download?.loading ||
            (isReadOnly && !isWebsheetSpinnerDisabled)
          }
          error={manageExcelDocument?.download?.error}
          hasErrorInCurrentSheet={hasErrorInCurrentSheet}
          handleCompleteCleaning={handleCompleteCleaning}
          defaultSheetName={currentSheet.sheet}
          data={data}
          questionConfig={questionConfig}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          setWebsheetModalRenderer={setWebsheetModalRenderer}
          ref={websheetRef}
          onSheetLoading={setIsSheetLoadingLocally}
          onCurrentSheetNameChanged={onCurrentSheetNameChanged}
          isReplay={isReplay}
          isAiWizard={isAiWizard}
          documentId={fileLock?.documentId}
          documentRevisionId={fileLock?.documentRevisionId}
          savedState={savedState}
          wizardEntities={wsProperties?.properties?.entities}
          isPreCleanseFinished={isPreCleanseFinished}
          onStepBarDisplayed={setIsWebsheetSpinnerDisabled}
          preCleanFormData={preCleanFormData}
          isPreCleanseEnabled={IS_PRE_CLEANSE_ENABLED}
          isNextFileAvailable={!!nextFileId}
        />
      </ErrorBoundary>
    );
  }, [
    fileLock?.documentRevisionId,
    fileLock?.documentId,
    document?.documentRevisionId,
    handleError,
    manageExcelDocument?.download?.loading,
    manageExcelDocument?.download?.error,
    isReadOnly,
    isWebsheetSpinnerDisabled,
    hasErrorInCurrentSheet,
    handleCompleteCleaning,
    currentSheet.sheet,
    data,
    questionConfig,
    handleOpenModal,
    handleCloseModal,
    setWebsheetModalRenderer,
    websheetRef,
    onCurrentSheetNameChanged,
    isReplay,
    isAiWizard,
    savedState,
    wsProperties?.properties?.entities,
    isPreCleanseFinished,
    preCleanFormData,
    nextFileId
  ]);

  const renderEditableWebsheet = useCallback(() => {
    return (
      <ErrorBoundary onError={handleError} FallbackComponent={ErrorFallback}>
        <EditableWebsheet
          onCurrentSheetNameChanged={onCurrentSheetNameChanged}
          isReadOnly={isReadOnly}
          invalidCells={invalidCells}
          updateInvalidCells={handleInvalidCells}
          currentSheetName={currentSheet?.sheet}
          user={user}
          data={data}
          setErrorMessage={setErrorMessage}
          handleOpenModal={handleOpenModal}
          setHasLocalChanges={setHasLocalChanges}
          markUserStillActive={markUserStillActive}
          loading={manageExcelDocument?.download?.loading}
          error={manageExcelDocument?.download?.error}
          ref={websheetRef}
          hasLoadError={hasErrorInCurrentSheet}
          onSheetLoading={setIsSheetLoadingLocally}
          onCellValidationError={onCellValidationError}
          isHidden={isSavingWebsheet}
        />
      </ErrorBoundary>
    );
  }, [
    currentSheet?.sheet,
    data,
    handleError,
    handleInvalidCells,
    handleOpenModal,
    hasErrorInCurrentSheet,
    invalidCells,
    isReadOnly,
    isSavingWebsheet,
    manageExcelDocument?.download?.error,
    manageExcelDocument?.download?.loading,
    markUserStillActive,
    onCellValidationError,
    onCurrentSheetNameChanged,
    user,
    websheetRef
  ]);

  const renderSheetLoading = useCallback(() => {
    const isLoadingWebsheet =
      (manageExcelDocument?.download?.loading || isReadOnly) &&
      !isWebsheetSpinnerDisabled;
    const isLoading = isLoadingWebsheet || isSavingWebsheet;
    const message = isSavingWebsheet
      ? t("common:ui.websheet.savingProgress")
      : null;
    return (
      <>
        {isLoading && (
          <div className="sheet-loading-spinner">
            <div className="sheet-loading-spinner__container">
              <Loading message={message} />
            </div>
          </div>
        )}
      </>
    );
  }, [
    manageExcelDocument?.download?.loading,
    isReadOnly,
    isWebsheetSpinnerDisabled,
    isSavingWebsheet,
    t
  ]);

  const renderWebsheetTable = useMemo(() => {
    let selectedComponent;
    if (cleaningWizardMode && !isCleaned) {
      selectedComponent = renderCleaningWizard;
    } else {
      selectedComponent = renderEditableWebsheet;
    }
    return (
      <>
        {renderSheetLoading()}
        {selectedComponent?.()}
      </>
    );
  }, [
    cleaningWizardMode,
    isCleaned,
    renderSheetLoading,
    renderCleaningWizard,
    renderEditableWebsheet
  ]);

  const isSidePanelOpen = useCallback(
    () =>
      IS_PRE_CLEANSE_ENABLED &&
      cleaningWizardMode &&
      !isCleaned &&
      (wsProperties?.properties?.entities?.length || 0) > 1 &&
      !isPreCleanseFinished,
    [
      cleaningWizardMode,
      isCleaned,
      wsProperties?.properties?.entities?.length,
      isPreCleanseFinished
    ]
  );

  return (
    <WebsheetTemplate
      project={project}
      headerActions={headerActions}
      contentDetails={contentDetails}
      error={errorContent}
      shortDescription={title}
      showEditOrSaveButton={showEditOrSaveButton}
      saveButton={saveButton}
      switchToEditModeButton={switchToEditModeButton}
      inEditMode={!isReadOnly}
      isLoading={loading}
      modalOpen={modalOpen}
      getModalContent={getModalContent}
      handleMouseMove={handleMouseMove}
      sidePanelContent={wizardSidePanel}
      isSidePanelOpen={isSidePanelOpen()}
    >
      {renderWebsheetTable}
    </WebsheetTemplate>
  );
};

export default WebSheetDocument;
