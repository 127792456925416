import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";

export function useDeleteProjectTag() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const [requireFPConfirmation, setRequireFPConfirmation] = useState(false);
  const [fpName, setFPName] = useState("Final Package");
  const [error, setError] = useState("");

  const deleteTag = useCallback(
    (project, tag, force = false) => {
      if (!project || !tag?.id || !tag?.name) return;

      const fpEnabled = project.configuration?.features?.finalPackage?.enabled;
      if (fpEnabled) {
        const signedPackageConfig =
          project.configuration?.finalPackage?.signedPackage;
        const isSignedLabel = tag.name === signedPackageConfig?.signedLabel;
        const isConfirmationNeeded =
          signedPackageConfig?.modifySignedLabel?.showConfirmationMessage;
        setRequireFPConfirmation(isSignedLabel && isConfirmationNeeded);
        setFPName(project.configuration?.finalPackage?.name ?? "Final Package");
      } else {
        setRequireFPConfirmation(false);
      }

      dispatch(
        manageProjectDocumentsActions.deleteProjectTag(project, tag, force)
      );
    },
    [dispatch]
  );

  const resetDeleteTag = useCallback(() => {
    dispatch(manageProjectDocumentsActions.resetDeleteProjectTag());
  }, [dispatch]);

  useEffect(() => {
    if (
      manageProjectDocuments.deletedProjectTagError?.message ===
      t("common:ui.globalTags.deleteTagHasAssociations")
    ) {
      if (
        requireFPConfirmation &&
        manageProjectDocuments.deletedProjectTagError.documentCount > 0
      ) {
        const documentWithCount = t(
          "common:ui.documents.displayNameWithCount",
          { count: manageProjectDocuments.deletedProjectTagError.documentCount }
        );
        setError(
          t("common:ui.label.error.deleteUsingLabel", {
            documentWithCount,
            fpName
          })
        );
      } else {
        const items = [
          t("common:ui.documents.displayNameWithCount", {
            count: manageProjectDocuments.deletedProjectTagError.documentCount
          }),
          t("requests:requests.requestWithCount", {
            count: manageProjectDocuments.deletedProjectTagError.queriesCount
          })
        ].filter(a => a);
        setError(
          t("common:ui.deleteProjectTag.hasAssociatedTagsWarningMessage", {
            count: items.length,
            items
          })
        );
      }
    } else {
      setError(t(manageProjectDocuments.deletedProjectTagError?.message) ?? "");
    }
  }, [
    fpName,
    manageProjectDocuments.deletedProjectTagError.documentCount,
    manageProjectDocuments.deletedProjectTagError?.message,
    manageProjectDocuments.deletedProjectTagError.queriesCount,
    requireFPConfirmation,
    t
  ]);

  return {
    deleteProjectTagError: error,
    deleteProjectTag: deleteTag,
    resetDeleteProjectTag: resetDeleteTag
  };
}
