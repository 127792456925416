import React, { useCallback, useMemo } from "react";

import { Trans, useTranslation } from "react-i18next";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import { FontWeight, Text } from "@atoms/Text";

import RowSelectionWizard, { ROW_SELECTION_TYPE } from "./RowSelectionWizard";

export function useFirstDataRowSelectionWizard({ templateHeaders }) {
  const { t } = useTranslation();
  const { ref, callbackRef } = useCallbackRef();

  const getStepDescription = useCallback(() => {
    return (
      <>
        <Trans
          i18nKey="common:ui.websheet.actions.cleaningWizard.step.selectSingleRow.description"
          shouldUnescape={true}
          components={{
            underline: (
              <Text
                text={t(
                  "common:ui.websheet.actions.cleaningWizard.step.selectSingleRow.firstRow"
                )}
                weight={FontWeight.LIGHT}
                decoration="underline"
                className="wizard-websheet__step2-desc"
              />
            )
          }}
        />
      </>
    );
  }, [t]);

  const getWizard = useCallback(
    commonWizardProps => {
      return (
        <RowSelectionWizard
          {...commonWizardProps}
          ref={callbackRef}
          firstRowHeader={true}
          rowSelectionType={ROW_SELECTION_TYPE.SINGLE}
          templateHeaders={templateHeaders}
        />
      );
    },
    [callbackRef, templateHeaders]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission
  };
}
