import React from "react";

import PropTypes from "prop-types";

import { useGetQuestionWithCommentsQuery } from "@shared/services/smartformService";

import SmartFormTable from "@components/molecules/SmartFormTable";
import { processedQuestionPropType } from "@components/molecules/SmartFormTable/SmartFormTable";
import BoxTemplate from "@components/templates/BoxTemplate";

const SmartFormCategory = ({
  queryId,
  categoryName,
  categoryData,
  handlers,
  dataTestId,
  relevantEntitiesById,
  disableAnswerSubmissions,
  alwaysShowEntitiesIcon
}) => {
  const { data: questionWithComments } = useGetQuestionWithCommentsQuery(
    { queryId },
    { skip: !queryId }
  );

  return (
    <>
      {Object.entries(categoryData.subcategories)?.map(
        ([subCategoryName, subCategoryData], idx) => (
          <BoxTemplate
            title={`${categoryName} - ${subCategoryName}`}
            boxClassName="smart-form-category"
            dataTestId={dataTestId}
            stickyTitle={true}
            key={idx}
          >
            <div className="smart-form-category__table">
              <SmartFormTable
                questionWithComments={questionWithComments}
                questions={subCategoryData}
                handlers={handlers}
                relevantEntitiesById={relevantEntitiesById}
                disableAnswerSubmissions={disableAnswerSubmissions}
                alwaysShowEntitiesIcon={alwaysShowEntitiesIcon}
              ></SmartFormTable>
            </div>
          </BoxTemplate>
        )
      )}
    </>
  );
};

SmartFormCategory.defaultProps = {};

SmartFormCategory.propTypes = {
  queryId: PropTypes.number,
  categoryName: PropTypes.string.isRequired,
  categoryData: PropTypes.shape({
    subcategories: PropTypes.objectOf(
      PropTypes.arrayOf(processedQuestionPropType)
    ),
    completeCount: PropTypes.number,
    totalCount: PropTypes.number
  }),
  dataTestId: PropTypes.string.isRequired,
  handlers: PropTypes.shape({
    openCommentModal: PropTypes.func,
    openEntitiesModal: PropTypes.func,
    saveAnswers: PropTypes.func,
    supplyAnswer: PropTypes.func
  }),
  relevantEntitiesById: PropTypes.any,
  disableAnswerSubmissions: PropTypes.bool,
  alwaysShowEntitiesIcon: PropTypes.bool
};

export default SmartFormCategory;
