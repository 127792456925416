import React from "react";

import { Box, Inline } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";
import { Icon, IconColor, IconFillStyle, IconSize } from "@atoms/Icon";

import { ActionDropdown } from "@molecules/ActionDropdown";
import { DropdownItemType } from "@molecules/DropdownList";

import "./Banner.scss";

interface BannerProps {
  text: string;
  iconName?: string;
  primaryButton?: {
    onClick: () => void;
    label: string;
    disabled?: boolean;
  };
  actionDropdown?: {
    onItemClick: (item: DropdownItemType) => void;
    items: DropdownItemType[];
  };
}

const Banner = ({
  text,
  iconName,
  primaryButton,
  actionDropdown
}: BannerProps) => {
  return (
    <div className="banner" data-testid="testBanner">
      <Inline className="banner__left">
        <Inline className="banner__left__message" gap="050">
          {iconName && (
            <Icon
              name={iconName}
              fillStyle={IconFillStyle.FILLED}
              size={IconSize.S}
              color={IconColor.INHERIT}
            />
          )}
          {text}
        </Inline>
        {primaryButton && (
          <Button {...primaryButton} variant={ButtonVariant.TEXT} />
        )}
      </Inline>
      <Box className="banner__right">
        {actionDropdown && (
          <ActionDropdown
            menuItems={actionDropdown.items}
            onMenuItemClick={actionDropdown.onItemClick}
            positionRelative
          />
        )}
      </Box>
    </div>
  );
};

export default Banner;
