import React from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { classNames } from "@app/helpers/componentHelpers";

import OTTooltip from "@components/atoms/OTTooltip";

interface ContainerWithTooltipProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  tooltipContent: string | React.JSX.Element;
  hideTooltip?: boolean;
}

export const ContainerWithTooltip = ({
  tooltipContent,
  hideTooltip,
  children,
  className = "",
  ...props
}: React.PropsWithChildren<ContainerWithTooltipProps>) => {
  const { showTooltip, elementProps } = useTooltip();
  return (
    <div
      {...props}
      {...(hideTooltip ? {} : elementProps)}
      className={classNames([className, "container-with-tooltip"])}
    >
      {children}
      {showTooltip && (
        <OTTooltip visible={showTooltip} content={tooltipContent} />
      )}
    </div>
  );
};
