import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { actionTypeConstants } from "@shared/constants/actionTypeConstants.js";
import { formatDateOnly, safeUtcDate } from "@shared/helpers/dateFormatUtils";
import dateFormatter from "@shared/helpers/dateHelper";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import {
  useAddActionItemMutation,
  useAuthUser,
  useDocumentCheck,
  useGetProjectMembers,
  useGetProjectTags,
  useLocaleDate
} from "@shared/hooks";
import { useGetProjectMilestonesQuery } from "@shared/services/projectService";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { getIndividualFieldPermission } from "@app/helpers/actionItemTypes.ts";
import {
  getQueryValidationSchema,
  validateQuery
} from "@app/helpers/actionItems";
import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import EntitiesField from "@components/molecules/EntitiesField";

const addFilesState = systemConstants.addFiles.state;

const AddActionForm = React.forwardRef((props, fwdRef) => {
  const { source, project, onCancel, fields, onSubmitted, selectedQueryType } =
    props;
  const { t } = useTranslation();
  const [assignUsers, setAssignUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [entities, setEntities] = useState([]);
  const { members, membersLoading } = useGetProjectMembers(project);
  const [
    addActionItem,
    {
      error: addQueryError,
      isSuccess: addedQuery,
      isLoading: addingQuery,
      data: addQueryResponse
    }
  ] = useAddActionItemMutation();

  const { data: milestones } = useGetProjectMilestonesQuery(
    {
      projectId: project.id,
      onlyEngagementTypeMilestones: true
    },
    {
      skip: !project.id || !project.configuration?.milestones?.labels?.enabled,
      refetchOnMountOrArgChange: true
    }
  );
  const { projectTags, loadingProjectTags } = useGetProjectTags(project.id);
  const [error, setError] = useState("");
  const {
    check,
    resetCheck,
    isCheckLoading,
    fetchCheck,
    isActionTypeSmartForm
  } = useDocumentCheck(project.id);
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [submitQuery, setSubmitQuery] = useState([]);
  const [uploadData, setUploadData] = useState({});
  const [isFilesAttached, setIsFilesAttached] = useState(false);
  const { user } = useAuthUser();
  const [uploadError, setUploadError] = useState(null);
  const {
    locale,
    options: { numericFormat }
  } = useLocaleDate();
  const requiresOverrideMessage = useMemo(() => {
    return check?.some(c => c.present);
  }, [check]);
  const isRequiredFilesAttached = useCallback(() => {
    return !(
      fields?.some(f => f.type === "file" && f.required) && !isFilesAttached
    );
  }, [fields, isFilesAttached]);
  const disableSubmit = useMemo(() => {
    return (
      isCheckLoading ||
      addingQuery ||
      uploadState === addFilesState.upload ||
      !isRequiredFilesAttached()
    );
  }, [addingQuery, isCheckLoading, uploadState, isRequiredFilesAttached]);
  const { getValues, setValue } = useFormContext();
  const getSchema = useCallback(() => {
    const additionalFields = {
      queryType: { type: "object", required: true }
    };
    if (requiresOverrideMessage) {
      additionalFields.overrideMessage = { type: "string", required: true };
    }
    if (!fields) {
      return;
    }
    const userTypes = [user.xRole.type];

    return getQueryValidationSchema({
      configFields: fields,
      additionalFields,
      i18nText: t,
      userTypes,
      actionType: actionTypeConstants.CREATE
    });
  }, [fields, requiresOverrideMessage, t, user?.xRole?.type]);

  const milestoneEnabled = useMemo(
    () => project.configuration?.milestones?.labels?.enabled,
    [project.configuration?.milestones?.labels?.enabled]
  );

  useEffect(() => {
    const projectEntities = project?.entities?.map(e => ({
      name: e.name,
      value: e
    }));
    setEntities(projectEntities ?? []);
  }, [project]);

  useEffect(() => {
    if (members && !membersLoading) {
      const allUsers = (members.clientUsers || [])
        .concat(members.hostUsers || [])
        .map(member => ({ name: member.name, value: member }));
      setAssignUsers(allUsers);
    } else setAssignUsers([]);
  }, [members, membersLoading, user.id]);

  useEffect(() => {
    if (projectTags && !loadingProjectTags) {
      const allTags = projectTags.map(tag => ({
        name: tag.name,
        value: tag
      }));
      setLabels(allTags);
    }
  }, [loadingProjectTags, projectTags]);

  useEffect(() => {
    if (!addingQuery && addedQuery) {
      onSubmitted({ queryId: addQueryResponse?.queryId });
    }
  }, [addingQuery, addedQuery, onSubmitted, addQueryResponse?.queryId]);

  useEffect(() => {
    setError(addQueryError?.data?.key ?? addQueryError?.data?.message);
  }, [addQueryError]);

  const handleCancel = useCallback(() => {
    setError("");
    onCancel();
  }, [onCancel]);

  const generateDataToSave = useCallback(
    data => {
      const dataToSave = {};
      Object.entries(data).forEach(([key, value]) => {
        if (fields.find(field => field.key === key)) {
          dataToSave[key] = value;
        }
        if (key === "queryType") {
          dataToSave[key] = value;
        }
      });
      return dataToSave;
    },
    [fields]
  );

  const addFieldsByConfig = useCallback(
    (newQuery, dataToSave) => {
      const findConfig = key => (fields ?? []).find(f => f.key === key);
      if (findConfig("remapTo")) {
        newQuery.properties.remapTo = dataToSave.remapTo?.value;
        newQuery.description = t(findConfig("description").value, {
          displayName: source?.path?.join(" | "),
          remapToValue: dataToSave.remapTo?.value
        });
      }
      if (findConfig("entities")) {
        newQuery.properties.entities =
          dataToSave.entities?.map(e => e.value?.externalId) ?? [];
      }

      if (findConfig("milestone")) {
        newQuery.properties.milestone = dataToSave.milestone?.value?.id;
      }
    },
    [fields, source?.path, t]
  );

  const generateNewQuery = useCallback(
    dataToSave => {
      const newTags = dataToSave?.tags?.map(dataTag => dataTag.value) ?? [];
      return {
        projectId: props.project.id,
        assignedTo: dataToSave.assignedTo?.value,
        query: dataToSave.query,
        description: dataToSave.description,
        copiedTo: dataToSave.copiedTo?.map(d => d.value) ?? [],
        queryType: dataToSave.queryType.key,
        requiredBy: safeUtcDate(dataToSave.requiredBy),
        tags: newTags,
        properties: {
          source,
          reminderDate: formatDateOnly(dataToSave.reminderDate)
        }
      };
    },
    [props.project.id, source]
  );

  const isFieldAvailable = useCallback(
    field => {
      const userRoleType = [user.xRole.type];
      const permissions = getIndividualFieldPermission({ field, userRoleType });
      return permissions.isCreatable;
    },
    [user?.xRole?.type]
  );

  const formatNewQuery = useCallback(
    (queryData, fieldsConfig) => {
      const newQuery = generateNewQuery(queryData);
      addFieldsByConfig(newQuery, queryData);
      fieldsConfig.forEach(field => {
        if (!isFieldAvailable(field)) {
          if (newQuery.properties?.[field.key]) {
            delete newQuery.properties[field.key];
          }
          delete newQuery[field.key];
        }
      });
      return newQuery;
    },
    [addFieldsByConfig, generateNewQuery, isFieldAvailable]
  );

  const handleSubmit = useCallback(
    data => {
      if (disableSubmit) {
        return;
      }
      setUploadData(data);
      const dataToSave = generateDataToSave(data);
      if (isCheckLoading.current) {
        return;
      }

      setError("");
      setUploadError(null);
      const validatedQuery = validateQuery(
        dataToSave,
        t,
        selectedQueryType?.key
      );
      const fieldsConfig = data.queryType.fields;
      const newQuery = formatNewQuery(dataToSave, fieldsConfig);

      if (requiresOverrideMessage) {
        handleFreezeScrollPosition(false);
        setSubmitQuery({
          ...newQuery,
          overrideMessage: data.overrideMessage
        });
        setUploadState(addFilesState.upload);
      } else {
        const fileFields = (fields ?? []).filter(
          f => f.type === "file" && !f.rename
        );
        const files = fileFields.flatMap(f => Object.values(dataToSave[f.key]));
        if (files.length && files.length !== check?.length) {
          fetchCheck(files.map(f => f.name));
        } else if (!files.length) {
          if (validatedQuery.success) {
            handleFreezeScrollPosition(false);
            setSubmitQuery(newQuery);
            setUploadState(addFilesState.upload);
            if ((fields ?? []).filter(f => f.type === "file").length == 0) {
              addActionItem({ ...newQuery });
            }
          } else {
            setUploadError(validatedQuery.error);
          }
        }
      }
    },
    [
      addActionItem,
      check?.length,
      disableSubmit,
      fetchCheck,
      fields,
      formatNewQuery,
      generateDataToSave,
      isCheckLoading,
      requiresOverrideMessage,
      selectedQueryType?.key,
      t
    ]
  );

  useEffect(() => {
    if (!check?.length) {
      return;
    }
    const dataToSave = generateDataToSave(uploadData);
    const validatedQuery = validateQuery(dataToSave, t, selectedQueryType?.key);
    const fileFields = (fields ?? []).filter(
      f => f.type === "file" && !f.rename
    );
    const files = fileFields.flatMap(f => Object.values(dataToSave[f.key]));
    if (!requiresOverrideMessage && files.length === check.length) {
      if (validatedQuery.success) {
        const newQuery = generateNewQuery(dataToSave);
        addFieldsByConfig(newQuery, dataToSave);
        handleFreezeScrollPosition(false);
        setSubmitQuery(newQuery);
        setUploadState(addFilesState.upload);
        if ((fields ?? []).filter(f => f.type === "file").length == 0) {
          addActionItem({ ...newQuery });
        }
      } else {
        setUploadError(validatedQuery.error);
      }
    }
  }, [
    addActionItem,
    addFieldsByConfig,
    check,
    check.length,
    fields,
    generateDataToSave,
    generateNewQuery,
    requiresOverrideMessage,
    selectedQueryType?.key,
    t,
    uploadData,
    uploadData.files,
    uploadData.response
  ]);

  useImperativeHandle(fwdRef, () => {
    return {
      handleSubmit,
      handleCancel,
      getSchema,
      disableSubmit
    };
  }, [getSchema, handleCancel, handleSubmit, disableSubmit]);

  const handleFilesChanged = useCallback(
    field => () => {
      if (field.type !== "file" || field.rename) {
        return;
      }
      resetCheck();
    },
    [resetCheck]
  );

  const handleFilesAttachment = useCallback(hasFiles => {
    setIsFilesAttached(hasFiles);
  }, []);

  const handleUploadComplete = useCallback(
    uploadedFiles => {
      setUploadState(addFilesState.finished);
      const newFiles =
        uploadedFiles && Object.keys(uploadedFiles).length > 0
          ? Object.keys(uploadedFiles).map(key => {
              const file = uploadedFiles[key];
              return {
                filePathId: file.filePathId,
                name: file.name,
                projectId: project?.id,
                isDeleted: file.isDeleted || false,
                isNew: file.isNew || false
              };
            })
          : [];
      addActionItem({ ...submitQuery, files: newFiles });
    },
    [addActionItem, project?.id, submitQuery]
  );

  const handleUploadError = useCallback(error => {
    setError(error);
    setUploadState(addFilesState.finished);
  }, []);

  const renderI18nItems = useCallback(
    key => {
      const items = t(key, { returnObjects: true });
      if (Array.isArray(items)) {
        return items.map((name, index) => ({ name, index }));
      }
      return [];
    },
    [t]
  );

  const milestoneItems = useMemo(
    () =>
      milestones?.map(m =>
        milestoneUtilities.formatMilestoneDropdownItem(
          m,
          dateFormatter(m.date, locale, numericFormat),
          t
        )
      ) ?? [],
    [locale, milestones, numericFormat, t]
  );

  const getEntitiesField = useCallback(
    field => {
      return (
        <EntitiesField
          key={field.key}
          entities={entities}
          field={field}
          queryType={selectedQueryType}
        />
      );
    },
    [entities, selectedQueryType]
  );

  const getMilestoneField = useCallback(
    field => {
      const defaultMilestone =
        milestoneItems?.find(m => m.value.name === field.defaultValue) ?? null;
      if (
        !selectedQueryType?.key ||
        !field ||
        !milestoneEnabled ||
        milestoneItems?.length === 0
      ) {
        return <></>;
      }
      const noSortingComparator = () => 0;
      return (
        <Form.Dropdown
          key={field?.key}
          name={field?.key}
          defaultValue={defaultMilestone}
          label={t(`requests:requests.configured.fields.${field?.key}.label`, {
            context: selectedQueryType?.key
          })}
          items={milestoneItems}
          required={field?.required}
          allowUndefined={!field?.required}
          sortComparator={noSortingComparator}
        />
      );
    },
    [selectedQueryType?.key, milestoneEnabled, t, milestoneItems]
  );

  const getOverrideMessage = () => {
    if (project?.configuration?.entities?.enabled) {
      return t(
        "requests:requests.ui.populateRequestForm.withEntities.overrideMessageAlert"
      );
    } else {
      return t(
        "requests:requests.ui.populateRequestForm.withoutEntities.overrideMessageAlert"
      );
    }
  };

  const remapItems = useCallback(
    f => {
      return renderI18nItems(f.items).map(item => ({
        name: item.name,
        value: item.name
      }));
    },
    [renderI18nItems]
  );

  const getDatePickerProps = useCallback(
    f => {
      const configuredEnableMinDate = f?.enableMinDate;
      const enableMinDate = configuredEnableMinDate || f.key === "reminderDate";
      const isChanged = getValues().isChanged?.[f.key];
      if (!f.defaultValue || !enableMinDate) {
        const defaultValue = (() => {
          if (isChanged) {
            return getValues(f.key) ?? null;
          }
          return f.defaultValue ? new Date(f.defaultValue) : null;
        })();

        return {
          defaultValue,
          minDate: enableMinDate ? new Date() : null
        };
      }

      const defaultDate = new Date(f.defaultValue);
      const isDefaultDateInPast = defaultDate.getTime() < Date.now();
      const minDate = isDefaultDateInPast
        ? new Date(f.defaultValue)
        : new Date();

      const defaultValue = (() => {
        if (isChanged) {
          return getValues([f.key]) ?? null;
        }
        return isDefaultDateInPast ? minDate : defaultDate;
      })();

      return {
        defaultValue,
        minDate
      };
    },
    [getValues]
  );

  const onDateChange = key => {
    setValue(`isChanged.${key}`, true);
  };

  return selectedQueryType && fields?.length ? (
    <>
      {error && <ErrorBox message={error} />}
      {fields?.filter(isFieldAvailable).map(f => {
        switch (f.type) {
          case "user":
            return (
              <Form.Dropdown
                key={f.key}
                name={f.key}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                required={f.required}
                items={assignUsers}
              />
            );
          case "text":
            return (
              <Form.TextField
                key={f.key}
                name={f.key}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                required={f.required}
                visible={f.visible}
                defaultValue={f.defaultValue}
              />
            );
          case "copiedTo":
            return (
              <Form.Multiselect
                key={f.key}
                name={f.key}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                required={f.required}
                items={assignUsers}
              />
            );
          case "textarea":
            return (
              <Form.TextArea
                key={f.key}
                name={f.key}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                required={f.required}
              />
            );
          case "date": {
            const { defaultValue, minDate } = getDatePickerProps(f);
            return (
              <Form.DateField
                key={f.key}
                name={f.key}
                defaultValue={defaultValue}
                minDate={minDate}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                onChangeDate={() => onDateChange(f.key)}
                required={f.required}
              />
            );
          }
          case "remapTo":
            return (
              <Form.Dropdown
                key={f.key}
                name={f.key}
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                items={remapItems(f)}
                required={f.required}
              />
            );
          case "milestone": {
            return getMilestoneField(f);
          }
          case "entity": {
            return getEntitiesField(f);
          }
          case "tags": {
            return (
              <Form.Multiselect
                key={f.key}
                required={f.required}
                name="tags"
                label={t(`requests:requests.configured.fields.${f.key}.label`, {
                  context: selectedQueryType.key
                })}
                items={labels}
              />
            );
          }
          case "file":
            return (
              <Form.UploadDocuments
                key={f.key}
                name={f.key}
                dropMessage={t(
                  `requests:requests.configured.fields.${f.key}.label`,
                  {
                    context: selectedQueryType.key
                  }
                )}
                errorMessage={uploadError}
                documentType={f.documentType || ""}
                supportedDocumentMimes={
                  f.validations?.find(v => v.name === "extension")?.value ??
                  systemConstants.mimes.document
                }
                supportedDocumentMimesMessage={
                  f.validations?.find(v => v.name === "extension")
                    ? t(
                        "requests:requests.configured.fields.files.validation.extension.errorMessage",
                        {
                          context: selectedQueryType.key,
                          extensions: f.validations
                            ?.find(v => v.name === "extension")
                            ?.value?.join(", ")
                        }
                      )
                    : null
                }
                projectId={project?.id}
                state={uploadState}
                maxNumberOfFiles={
                  f.validations?.find(v => v.name === "count")?.value
                }
                maxNumberOfFilesError={
                  f.validations?.find(v => v.name === "count")
                    ? t(
                        "requests:requests.configured.fields.files.validation.maximum.errorMessage",
                        {
                          context: selectedQueryType.key,
                          max: f.validations?.find(v => v.name === "count")
                            .value
                        }
                      )
                    : null
                }
                onUploadsComplete={handleUploadComplete}
                onUploadsFailed={handleUploadError}
                onChange={handleFilesChanged(f)}
                onFileSelectionChanged={handleFilesAttachment}
              />
            );
        }
      })}
      {requiresOverrideMessage && (
        <div key="overrideMessage">
          {isActionTypeSmartForm ? (
            <InlineAlert
              type="warning"
              message={t("common:ui.upload.error.fileName.cannotOverwrite")}
            ></InlineAlert>
          ) : (
            <>
              <InlineAlert
                type="warning"
                message={getOverrideMessage()}
              ></InlineAlert>
              <Form.TextArea
                label={t(
                  "requests:requests.ui.populateRequestForm.labelOverrideMessage"
                )}
                autoFocus={true}
                name="overrideMessage"
                maxLength="255"
                data-testid="overrideMessage"
                required={true}
              />
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <></>
  );
});

AddActionForm.defaultProps = {};

AddActionForm.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    engagement: PropTypes.shape({
      engagementTypeId: PropTypes.number
    })
  }),
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func.isRequired,
  source: PropTypes.any,
  fields: PropTypes.any,
  selectedQueryType: PropTypes.any
};

export default AddActionForm;
