import { manageDocumentUploadsConstants } from "../constants";

const initialState = {
  uploadingDocuments: []
};

export function manageDocumentUploads(state = initialState, action = {}) {
  switch (action.type) {
    case manageDocumentUploadsConstants.UPLOAD_DOCUMENT_REQUEST:
      const updatedUploadingDocuments = [
        ...state.uploadingDocuments,
        action.metadata
      ];
      return {
        ...state,
        uploadingDocuments: updatedUploadingDocuments
      };
    case manageDocumentUploadsConstants.UPLOAD_DOCUMENT_SUCCESS:
      const updatedUploadingDocumentsAfterSuccess =
        state.uploadingDocuments.map(document => {
          if (
            document.projectId === action.metadata.projectId &&
            document.name === action.metadata.name
          ) {
            document.documentPathId = action.metadata.documentPathId;
            document.uploadCompleted = true;
          }
          return document;
        });
      return {
        ...state,
        uploadingDocuments: updatedUploadingDocumentsAfterSuccess
      };
    case manageDocumentUploadsConstants.UPLOAD_DOCUMENT_FAILURE:
      const updatedUploadingDocumentsAfterFailure =
        state.uploadingDocuments.map(document => {
          if (
            document.projectId === action.metadata.projectId &&
            document.name === action.metadata.name
          ) {
            document.error = action.metadata.error;
          }
          return document;
        });
      return {
        ...state,
        uploadingDocuments: updatedUploadingDocumentsAfterFailure
      };
    case manageDocumentUploadsConstants.UPDATE_UPLOAD_PROGRESS:
      const updatedUploadingDocumentsAfterProgress =
        state.uploadingDocuments.map(document => {
          if (
            document.projectId === action.metadata.projectId &&
            document.name === action.metadata.name
          ) {
            document.uploadProgress = action.metadata.uploadProgress;
            document.reqSource = action.metadata.reqSource;
          }
          return document;
        });
      return {
        ...state,
        uploadingDocuments: updatedUploadingDocumentsAfterProgress
      };
    case manageDocumentUploadsConstants.CLEAR_UPLOADED_DOCUMENT:
      const updatedUploadingDocumentsAfterClear =
        state.uploadingDocuments.filter(document => {
          if (
            document.projectId === action.metadata.projectId &&
            document.name === action.metadata.name
          ) {
            return false;
          }
          return true;
        });

      return {
        ...state,
        uploadingDocuments: updatedUploadingDocumentsAfterClear
      };
    case manageDocumentUploadsConstants.RESET_UPLOADING_DOCUMENTS:
      return {
        ...state,
        uploadingDocuments: []
      };
    default:
      return state;
  }
}
