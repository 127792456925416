import React, { useEffect, useRef } from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Inline } from "@fermions";

import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";

import OTTooltip from "@components/atoms/OTTooltip";

import "./InputLabel.scss";

export enum InputLabelFontColor {
  DEFAULT = "default",
  LIGHT = "light"
}

export interface InputLabelProps {
  label?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  hideLabel?: boolean;
  fontColor?: InputLabelFontColor;
  onlyShowDescriptionOnOverflow?: boolean;
  externalLabelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
}

export const InputLabel = ({
  id,
  label,
  required,
  disabled,
  description,
  hideLabel = false,
  onlyShowDescriptionOnOverflow = false,
  fontColor = InputLabelFontColor.DEFAULT,
  externalLabelProps
}: InputLabelProps) => {
  const { showTooltip, elementProps } = useTooltip();
  const inputLabelRef = useRef<HTMLLabelElement>(null);

  const isLabelOverflown =
    inputLabelRef.current?.scrollHeight > inputLabelRef.current?.clientHeight;
  const showDescription =
    (isLabelOverflown && onlyShowDescriptionOnOverflow && !!description) ||
    (!onlyShowDescriptionOnOverflow && !!description);

  useEffect(() => {}, [label, required]);

  return hideLabel || (!label && !description) ? (
    <></>
  ) : (
    <Inline
      className={classNames([
        "input-label",
        returnStringIfTrue(disabled, "input-label--disabled"),
        `input-label--font-${fontColor}`
      ])}
    >
      <label id={id} {...externalLabelProps} ref={inputLabelRef}>
        {label}
      </label>
      {showDescription && (
        <>
          <span className="input-label__description" {...elementProps}>
            <Icon
              name="info"
              size={IconSize.XS}
              fillStyle={IconFillStyle.FILLED}
            />
          </span>
          <OTTooltip visible={showTooltip} content={description} />
        </>
      )}
      {required && !disabled && (
        <span className="input-label__required">*</span>
      )}
    </Inline>
  );
};
