import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { Context } from "@ions-interface";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Inline } from "@fermions";

import { Icon, IconSize } from "@atoms/Icon";
import { Pill, PillSize } from "@atoms/Pill/Pill.tsx";

import "./InputWrapper.scss";

export enum InputType {
  TEXT = "text",
  DROPDOWN = "dropdown",
  MULTISELECT = "multiselect",
  DATE = "date",
  NUMBER = "number"
}

export enum BorderStyle {
  FULL = "full",
  NONE = "none",
  NONE_WITH_PADDING = "none-with-padding"
}

export function getInputIdentifier(label?: string): string {
  return (label ?? "checkbox-input")?.replaceAll(" ", "-");
}

export interface InputWrapperProps {
  current?: string | number;
  setCurrent?: (value: string) => void;
  currentPills?: string[];
  placeholder?: string;
  disabled?: boolean;
  type?: InputType;
  className?: string;
  isOpen?: boolean;
  onClick?: () => void;
  clearValue?: () => void;
  error?: string;
  rightIcons?: React.JSX.Element;
  borderStyle?: BorderStyle;
  allowUndefined?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
  extraInputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  context?: Context;
}

export const InputWrapper = forwardRef(
  (
    {
      current,
      setCurrent,
      currentPills,
      placeholder,
      disabled,
      type = InputType.TEXT,
      className,
      isOpen,
      onClick,
      clearValue,
      error,
      rightIcons = <></>,
      borderStyle = BorderStyle.FULL,
      allowUndefined = true,
      onBlur,
      id,
      extraInputProps,
      context = Context.LIGHT
    }: InputWrapperProps,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ) => {
    const [focus, setFocus] = useState(false);

    const canOpen = useMemo(
      () =>
        [InputType.DROPDOWN, InputType.MULTISELECT, InputType.DATE].includes(
          type
        ),
      [type]
    );

    useEffect(() => {
      if (canOpen) {
        setFocus(isOpen ?? false);
      }
    }, [canOpen, isOpen]);

    const handleClick = useCallback(
      e => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.();
      },
      [onClick]
    );

    const renderCurrentPills = useCallback(() => {
      const validCurrentPills = currentPills?.filter(p => p);
      if (!validCurrentPills?.length) {
        return <></>;
      }
      return (
        <Inline gap="050" className="input-wrapper__input-container__pills">
          {validCurrentPills.map((pill, index) => (
            <Pill key={`${pill}-${index}`} label={pill} size={PillSize.SMALL} />
          ))}
        </Inline>
      );
    }, [currentPills]);

    const input = useMemo(
      () => (
        <Box className="input-wrapper__input-container">
          <input
            aria-labelledby={id}
            placeholder={allowUndefined ? placeholder : ""}
            ref={ref}
            value={current ?? ""}
            onChange={event => {
              setCurrent?.(event?.target?.value);
            }}
            disabled={disabled}
            readOnly={canOpen}
            onFocus={() => setFocus(true)}
            onBlur={event => {
              setFocus(false);
              onBlur?.(event);
            }}
            title={`${current ?? ""}`}
            data-testid={`test_${id}`}
            type={type === InputType.NUMBER ? "number" : "text"}
            {...extraInputProps}
          />
          {renderCurrentPills()}
        </Box>
      ),
      [
        id,
        allowUndefined,
        placeholder,
        ref,
        current,
        disabled,
        canOpen,
        type,
        extraInputProps,
        renderCurrentPills,
        setCurrent,
        onBlur
      ]
    );

    return (
      <Inline
        className={classNames([
          "input-wrapper",
          `input-wrapper--${type}`,
          `input-wrapper--context-${context}`,
          `input-wrapper--border-${borderStyle}`,
          returnStringIfTrue(focus, "input-wrapper--focus"),
          returnStringIfTrue(isOpen, "input-wrapper--open"),
          returnStringIfTrue(disabled, "input-wrapper--disabled"),
          returnStringIfTrue(error, "input-wrapper--error"),
          returnStringIfTrue(!disabled && error, "input-wrapper--error"),
          className ?? ""
        ])}
        onClick={disabled ? undefined : handleClick}
        data-testid={`test_${id}_wrapper`}
      >
        {input}
        {allowUndefined && clearValue && (
          <Icon
            name="close"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              clearValue();
            }}
            size={IconSize.S}
            className={classNames([
              "input-wrapper__clear",
              current?.length && !disabled ? "visible" : ""
            ])}
          />
        )}
        {rightIcons}
      </Inline>
    );
  }
);
