import { max } from "lodash";

/**
 * Aligns the order of the templateHeaders array based on the column names in the first row of the data array.
 * @param {Object} p
 * @param {Array} p.data - The data array containing rows of values, with the first row representing column names.
 * @param {Array} p.templateHeaders - The templateHeaders array containing objects with column names and their properties.
 * @returns {Array} alignedTemplateHeaders - A new array of templateHeaders with the order aligned to the data's column names.
 */
const alignTemplateHeadersOrder = ({ data, templateHeaders }) => {
  const dataHeaders = data[0];

  //map header names to their templateHeader objects.
  const templateHeaderMap = templateHeaders.reduce((map, header) => {
    map[header.name] = header;
    return map;
  }, {});

  // align the order of the template headers to the data headers.
  const alignedTemplateHeaders = dataHeaders.map(
    header => templateHeaderMap[header]
  );

  return alignedTemplateHeaders;
};

// to handle value when the dataType for the column is number
const checkValueTypeForNumber = value => {
  if (typeof value === "number") {
    return "number";
  }
  if (typeof value !== "string") {
    return "blank";
  }
  //optional negative sign or comma and currency symbols
  const optionalSignAndCurrency = "([-,])?([$¥€£])?";
  //numbers with optional decimal places and comma separators
  const numberWithDecimalsAndCommas =
    "(\\d+([.,]\\d{1,2})?|\\d{1,3}(,\\d{3}|\\d{0})*([.,]\\d+)?)";
  //matching numbers with or without currency symbols, negative sign or comma
  const normalNumberPattern = `^${optionalSignAndCurrency}${numberWithDecimalsAndCommas}$`;
  //matching numbers enclosed in brackets
  const bracketNumberPattern = `^([$¥€£])?\\(${optionalSignAndCurrency}${numberWithDecimalsAndCommas}\\)$`;

  const regex = new RegExp(`${normalNumberPattern}|${bracketNumberPattern}`);
  if (regex.test(value)) {
    return "number";
  } else {
    return "string";
  }
};

/**
 * evaluate the data type of a value based on the expected type.
 * @param {Number|String} value
 * @param {String} expectedType
 * @returns
 */
const evaluateDataTypeAtCell = (value, expectedType, required) => {
  if (value === "" || value === "blank" || (value === null && required)) {
    return "blank";
  }
  if (expectedType === "number") {
    return checkValueTypeForNumber(value);
  } else if (expectedType === "string") {
    return "string";
  }
  return "blank";
};

/**
 * Calculate the percentage of uniformity for each element of the nested arrays of dataType
 * the the remaining elements starting from the currentIndex then
 * count the mismatch elements that are different from the expected type
 * @param {Object} p
 * @param {Array<Array<String>>} p.dataType - An array containing nested arrays with data types.
 * @param {Array<Object>} p.alignedTemplateHeaders - An array containing template header objects.
 * @returns {Array<Array<Object>>} An array containing nested arrays with uniformity objects.
 */
const calculateUniformity = ({ dataType, alignedTemplateHeaders }) => {
  return dataType.map((typeArray, typeArrayIndex) => {
    const expectedType = alignedTemplateHeaders[typeArrayIndex].type;

    return typeArray.map((elementType, elementTypeIndex) => {
      const remainingElements = typeArray.slice(elementTypeIndex);
      const mismatchCount = remainingElements.reduce((count, currentItem) => {
        return count + (currentItem !== expectedType ? 1 : 0);
      }, 0);

      return {
        type: elementType,
        score: 1 - mismatchCount / remainingElements.length
      };
    });
  });
};

/**
 * Calculate the average score across all rows
 * @param {Array<Array<{type: string, score: number}>>} uniformityScores
 * @returns {Array<number>} - An array of average scores.
 */
const calculateAvgScore = uniformityScores => {
  // use the length of the first nested array.
  // assuming they all have the same length.
  const arrayLength = uniformityScores[0].length;

  // create an array with the same length as the longest nested array and fill it with 0.
  const sumScores = new Array(arrayLength).fill(0);

  // calculate the sum of each nested array.
  uniformityScores.forEach(nestedArray => {
    nestedArray.forEach((item, index) => {
      sumScores[index] += item.score;
    });
  });
  // calculate the average score for each row.
  const averageScores = sumScores.map(
    scoreSum => scoreSum / uniformityScores.length
  );

  return averageScores;
};

/**
 * find the index and value of the largest element in an array
 * @param {*} arr - array of average uniformity scores for each rows
 * @returns {{index: number, value: number}} - An object containing the index and value of the largest element.
 */
const findLargestElementIndexAndValue = arr => {
  const maxValue = max(arr);
  const maxIndex = arr.findIndex(value => value === maxValue);

  return { index: maxIndex, value: maxValue };
};

/**
 * Evaluates data types for each cell in a 2D array and treats "blank" values
 * as the template header type if the column is not required.
 *
 * @param {Object} p
 * @param {Array<Array>} p.rows - 2D array containing the data rows.
 * @param {Array<Object>} p.alignedTemplateHeaders - array of aligned template header objects.
 * @returns {Array<Array>} - 2D array containing the evaluated data types.
 */
const transposeToColumnsAndEvaluateDataTypes = ({
  rows,
  alignedTemplateHeaders
}) => {
  // transpose the data from rows to work with columns
  const dataByColumns = Array.from(
    { length: alignedTemplateHeaders.length },
    (_, index) =>
      rows.map(arr => (arr[index] !== undefined ? arr[index] : "blank"))
  );

  // evaluate all the data here and store it in a 2D array
  const dataType = dataByColumns.map((col, i) => {
    return col.map(value =>
      evaluateDataTypeAtCell(
        value,
        alignedTemplateHeaders[i].type,
        alignedTemplateHeaders[i].required
      )
    );
  });

  // if required is false then "blank" should be treated as the template header type.
  for (let colIdx = 0; colIdx < dataType.length; colIdx++) {
    for (let rowIdx = 0; rowIdx < dataType[colIdx].length; rowIdx++) {
      if (
        dataType[colIdx][rowIdx] === "blank" &&
        !alignedTemplateHeaders[colIdx].required
      ) {
        dataType[colIdx][rowIdx] = alignedTemplateHeaders[colIdx].type;
      }
    }
  }
  return dataType;
};

/**
 * The function predicts the first row of the data by calculating the average uniformity score for each row
 * it first aligns the template headers with the data and then evaluates the data types for each cell
 * then it calculates the average uniformity score for each row and returns the index of the row with the highest score.
 * @param {Object} p
 * @param {Object} p.sheetData - An array of object containing sheet data.
 * @param {Array} p.templateHeaders - An array containing the template headers.
 * @param {Number} p.rowOffset - The number of rows to skip before evaluating the data.
 * @param {Number} p.maxRowsToSearch - The maximum number of rows to consider
 * @returns {{index: number, value: number}}
 */
const suggestRowHeader = ({
  sheetData,
  templateHeaders,
  rowOffset,
  maxRowsToSearch
}) => {
  const data = sheetData.data;

  const alignedTemplateHeaders = alignTemplateHeadersOrder({
    data,
    templateHeaders
  });

  const numberOfRowsToSearch = Math.min(data.length - 1, maxRowsToSearch);

  const rows = data.slice(rowOffset, numberOfRowsToSearch + rowOffset);

  const dataType = transposeToColumnsAndEvaluateDataTypes({
    rows,
    alignedTemplateHeaders
  });

  const uniformityScores = calculateUniformity({
    dataType,
    alignedTemplateHeaders
  });

  const averageScores = calculateAvgScore(uniformityScores);

  const predictedRow = findLargestElementIndexAndValue(averageScores);

  return predictedRow;
};

export default {
  suggest: suggestRowHeader,
  forTest: {
    checkValueTypeForNumber
  }
};
