import { useCallback, useState } from "react";

export function useCallbackRef() {
  const [ref, setRef] = useState(null);

  const callbackRef = useCallback(node => {
    if (node != null) {
      setRef(node);
    }
  }, []);

  return {
    callbackRef,
    ref
  };
}
