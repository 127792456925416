import { uniqBy } from "lodash";
import { FieldValues } from "react-hook-form";

import { systemConstants } from "@shared/constants/systemConstants";

const FILTER_SELECT_TYPE = systemConstants.requestPageFilter.selectType;

export type SelectedFilterItem =
  | string
  | string[]
  | { name: unknown; value: unknown }
  | { name: unknown; value: unknown }[]
  | null;

export interface FilterConfig {
  type: "singleChoice" | "multiChoice";
  slot: string;
  key: string;
  label: string;
  placeholder?: string;
  translate?: boolean;
  options?: FilterConfigOption[];
  optionKey?: string;
  uiControl?: string;
  maintainOrder?: boolean;
}

export interface FilterConfigOption {
  value: string;
  label: string;
  translate?: boolean;
  enabled?: boolean;
}

const mapFilterOptions = ({
  keyValPairs,
  filter,
  formatters
}: {
  keyValPairs: { label: string; value: string }[];
  filter: { key: string };
  formatters: Record<string, (label: string) => string>;
}) => {
  const customFormatter = formatters?.[filter.key];
  if (!customFormatter) {
    return keyValPairs;
  }

  const formatKeyValPair = (kvp: { label: string; value: string }) => ({
    ...kvp,
    label: customFormatter(kvp.value)
  });

  return keyValPairs.map(formatKeyValPair);
};

const formatFilterItem = (optionKey?: string) => element => ({
  value: element.id ?? element,
  label: optionKey ? element[optionKey] : element
});

export const resolveFilterOptions = ({
  filterConfig,
  dataSource,
  formatters
}: {
  filterConfig: FilterConfig;
  dataSource: unknown[];
  formatters: Record<string, (label: string) => string>;
  t: (key: string) => string;
}) => {
  const valuesFromDataForFilter = dataSource
    .map(d => d[filterConfig.key])
    .filter(d => d);

  const formatter = formatFilterItem(filterConfig.optionKey);
  const mapOptions = kvp =>
    mapFilterOptions({ keyValPairs: kvp, filter: filterConfig, formatters });

  const filterOptions = (() => {
    if (filterConfig.options) {
      return filterConfig.options;
    }

    return valuesFromDataForFilter
      .map(el => (Array.isArray(el) ? el : [el]))
      .map(el => el.map(formatter))
      .flatMap(mapOptions);
  })();
  const uniqueOptions = uniqBy(filterOptions, o => o.value);

  return {
    ...filterConfig,
    options: uniqueOptions
  };
};

export const resolveDefaultValue = ({
  filter,
  defaultValue,
  t
}: {
  filter: FilterConfig;
  defaultValue?: string | string[];
  t: (key: string) => string;
}) => {
  const availableOptions = filter.options ?? [];

  if (filter.type === FILTER_SELECT_TYPE.singleChoice) {
    const match = availableOptions.find(o => o.value === defaultValue);
    const result = match ? defaultValue : undefined;
    return result;
  }

  if (filter.type === FILTER_SELECT_TYPE.multiChoice) {
    const formatter = o => ({
      name: o.translate ? t(o.label) : o.label,
      value: o.value
    });
    const matches = availableOptions.filter(o =>
      defaultValue?.includes(o.value)
    );
    const result = matches.map(formatter);
    return result?.length ? result : undefined;
  }

  return null;
};

export const convertFormValuesToFilterState = (formValues: FieldValues) => {
  const isEmptyValuePredicate = (value: unknown) => {
    if (!value) {
      return true;
    }

    if (Array.isArray(value)) {
      const c = value.map(f => f.value);
      return c.every(f => f === null);
    }

    return false;
  };

  const formatValue = (value: unknown) => {
    if (Array.isArray(value)) {
      return value.map(f => f.value);
    }

    return value;
  };

  // filter empty or arrays which are empty
  const dataToSubmit = Object.entries(formValues)
    .map(([key, val]) => [key, val?.value ?? val])
    .filter(([, value]) => !isEmptyValuePredicate(value))
    .reduce((acc, [key, value]) => {
      acc[key] = formatValue(value);
      return acc;
    }, {});

  return dataToSubmit;
};
