import React from "react";

import { Context } from "@ions-interface";

import { classNames } from "@app/helpers/componentHelpers";

import "./Divider.scss";

enum DividerDirection {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical"
}

enum Size {
  THIN = 1,
  THICK = 2
}

interface DividerProps {
  direction?: DividerDirection;
  context?: Context;
  size?: Size;
}

export const Divider = ({
  direction = DividerDirection.HORIZONTAL,
  context = Context.LIGHT,
  size = Size.THIN
}: DividerProps) => {
  return (
    <span
      className={classNames([
        "divider",
        `divider--direction-${direction}`,
        `divider--context-${context}`,
        `divider--size-${size}`
      ])}
    />
  );
};
