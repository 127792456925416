import React from "react";

import { classNames } from "@app/helpers/componentHelpers";

import { Icon, IconFillStyle } from "@atoms/Icon";

import "./ToastIcon.scss";

export enum ToastType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  NOTIFICATION = "notification"
}

const toastIconName = {
  [ToastType.INFO]: "info",
  [ToastType.SUCCESS]: "check_circle",
  [ToastType.WARNING]: "error",
  [ToastType.ERROR]: "cancel",
  [ToastType.NOTIFICATION]: "notifications"
};

export interface ToastIconProps {
  readonly type?: ToastType;
}

export function ToastIcon({ type = ToastType.INFO }: ToastIconProps) {
  return (
    <Icon
      name={toastIconName[type]}
      fillStyle={IconFillStyle.FILLED}
      className={classNames(["ot-toast-icon", `ot-toast-icon--${type}`])}
    />
  );
}
