import React from "react";

import { useTranslation } from "react-i18next";

import "./errorBox.scss";

type ErrorBoxProps = {
  type?: string;
  message: string;
  locizeContext?: object;
  customMessage?: React.ReactNode;
  whiteSpaceStyle: WhiteSpace;
};

enum WhiteSpace {
  normal = "normal",
  preLine = "pre-line"
}

const ErrorBox = (props: ErrorBoxProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`error-box ${
        props.type === "component" ? "component-error" : "page-error"
      }`}
    >
      <i className="material-icons error-box--icon">warning</i>
      <span
        className={`error-box--text error-box--text--${props.whiteSpaceStyle}`}
      >
        {t(props.message, { ...props.locizeContext })}
      </span>
      {props.customMessage}
    </div>
  );
};

ErrorBox.defaultProps = {
  whiteSpaceStyle: WhiteSpace.normal
};

export default ErrorBox;
