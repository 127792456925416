import React, { useCallback, useRef, useState } from "react";

import useOnClickOutside from "use-onclickoutside";

import { DropdownList } from "@molecules/DropdownList";

import DropdownFilterIcon from "@components/molecules/DropdownFilterIcon";
import {
  DropdownCalculationType,
  DropdownItemId,
  DropdownItemType
} from "@components/molecules/DropdownList/DropdownHelper";

import "./DataTableDropdownMultiselect.scss";

interface DataTableDropdownMultiselectProps {
  label?: string;
  items: DropdownItemType[];
  value?: DropdownItemId[];
  onChange?: (values: DropdownItemType[]) => void;
}

export const DataTableDropdownMultiselect = ({
  label = "",
  items,
  value,
  onChange
}: DataTableDropdownMultiselectProps) => {
  const [selectedItems, setSelectedItems] = useState(
    (value ?? [])?.map(item => ({ value: item }))
  );

  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef();

  const onToggleMenu = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleOnSelectOptions = useCallback(
    updatedItems => {
      setSelectedItems(updatedItems);
      onChange?.(updatedItems);
    },
    [onChange]
  );

  useOnClickOutside(dropdownRef, () => setShowMenu(false));

  return (
    <div className="data-table-dropdown-multiselect" ref={dropdownRef}>
      <div
        onClick={onToggleMenu}
        id={`table-dropdown-select-${label}`}
        className="data-table-dropdown-multiselect__selector"
      >
        <div className="data-table-dropdown-multiselect__selector-label">
          {label}
        </div>
        <div className="data-table-dropdown-multiselect__selector-selection">
          <DropdownFilterIcon
            active={
              selectedItems.length > 0 && selectedItems.length !== items.length
            }
          />
        </div>
      </div>
      <DropdownList
        items={items}
        calculation={DropdownCalculationType.ABSOLUTE}
        selectedValues={selectedItems}
        setSelectedValues={handleOnSelectOptions}
        parentRef={dropdownRef}
        isOpen={showMenu}
        isMultiSelect
        showSearch
      />
    </div>
  );
};

export default DataTableDropdownMultiselect;
