import React, { Suspense, lazy } from "react";

import { isNil } from "lodash";
import PropTypes from "prop-types";

const JsonEditor = lazy(() => import("@components/molecules/JsonEditor"));

const JsonEditorInteractiveBox = ({
  schema,
  localJSONContent,
  isSuccessOnSaveJsonEditor,
  errorOnSaveJsonEditor,
  onChangeJSONEditor,
  disabled
}) => {
  return (
    <>
      {schema?.data && !isNil(localJSONContent) ? (
        <Suspense fallback={<></>}>
          <JsonEditor
            schema={schema?.data}
            localJSONContent={localJSONContent}
            isSuccess={isSuccessOnSaveJsonEditor}
            error={errorOnSaveJsonEditor}
            onChangeJSONEditor={onChangeJSONEditor}
            renderSideBySide={true}
            disabled={disabled}
          />
        </Suspense>
      ) : undefined}
    </>
  );
};

JsonEditorInteractiveBox.propTypes = {
  schema: PropTypes.object,
  localJSONContent: PropTypes.string,
  isSuccessOnSaveJsonEditor: PropTypes.bool,
  errorOnSaveJsonEditor: PropTypes.object,
  onChangeJSONEditor: PropTypes.func,
  disabled: PropTypes.bool
};

export default JsonEditorInteractiveBox;
