import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import InfoLabelText from "@components/atoms/InfoLabelText/InfoLabelText";

import "./DocumentProperties.scss";

function DocumentProperties({ document, source }) {
  const { t } = useTranslation();
  const {
    locale,
    options: { longFormat }
  } = useLocaleDate();

  const { smartFormName, refNo, categoryName } = source || {};

  const getUploadBy = () => (
    <InfoLabelText
      label={t("common:documents.uploadedBy")}
      text={document.uploadedBy?.name}
      className="document-properties__item"
    />
  );

  const getSource = () =>
    document?.properties?.actionItemTypeKey ===
      systemConstants.actionItemTypes.smartForm && source ? (
      <InfoLabelText
        className="document-properties__item"
        label={t("requests:requests.sourceLabel")}
        text={t("common:document.properties.source", {
          requestKey: smartFormName,
          refNo,
          categoryName
        })}
      />
    ) : (
      ""
    );

  const getEntities = () =>
    document.properties?.entities?.length &&
    document?.properties?.actionItemTypeKey ===
      systemConstants.actionItemTypes.smartForm &&
    source ? (
      <InfoLabelText
        className="document-properties__item"
        label={t(`requests:requests.configured.fields.entities.label`)}
        text={document.properties.entities.map(e => e.name).join(", ")}
      />
    ) : (
      ""
    );

  const getUploadDate = () => (
    <InfoLabelText
      className="document-properties__item"
      label={t("common:documents.uploadDate")}
      text={dateFormatter(document.createdAt, locale, longFormat)}
    />
  );

  const getLastModifiedDate = () => (
    <InfoLabelText
      className="document-properties__item"
      label={t("common:documents.lastModified")}
      text={dateFormatter(document.updatedAt, locale, longFormat)}
    />
  );

  return (
    <div className="document-properties">
      {getUploadBy()}
      {getEntities()}
      {getSource()}
      {getUploadDate()}
      {getLastModifiedDate()}
    </div>
  );
}

DocumentProperties.propTypes = {
  source: PropTypes.shape({
    smartFormName: PropTypes.string,
    refNo: PropTypes.string,
    categoryName: PropTypes.string
  })
};

export default DocumentProperties;
