import React from "react";

import PropTypes from "prop-types";

import List from "@components/atoms/List";
import BoxTemplate from "@components/templates/BoxTemplate";

const RequestEntitiesBox = ({
  projectEntities,
  entityIdsToDisplay,
  title,
  boxClassName,
  action
}) => {
  const render = () => {
    // at most 1 entitiy so no need to show the box as if there's an entity on the request we can assume its the only one
    if (projectEntities.length <= 1) {
      return <></>;
    }

    const entitiesToDisplay = projectEntities.filter(entity =>
      entityIdsToDisplay.includes(entity.externalId)
    );

    if (entitiesToDisplay.length === 0) {
      return <></>;
    }

    return (
      <BoxTemplate title={title} boxClassName={boxClassName} action={action}>
        <List
          list={entitiesToDisplay?.map(data => {
            return {
              id: data.externalId,
              name: data.name
            };
          })}
        />
      </BoxTemplate>
    );
  };

  return <>{render()}</>;
};

RequestEntitiesBox.defaultProps = {
  action: ""
};

RequestEntitiesBox.propTypes = {
  boxClassName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  projectEntities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number.isRequired
    })
  ).isRequired,
  entityIdsToDisplay: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default RequestEntitiesBox;
