import { useCallback, useMemo, useState } from "react";

import { systemConstants } from "@shared/constants";

const attachedFileStates = systemConstants.addFiles.attachedFile.state;

// A complementary hook for <AddFiles> and <UploadDocuments>
export function useFileUploadManagement() {
  const [oldFiles, setOldFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const files = useMemo(() => {
    const result = {};
    oldFiles.forEach(f => {
      result[f.name] = f;
    });
    newFiles.forEach(f => {
      result[f.name] = f;
    });
    return result;
  }, [oldFiles, newFiles]);

  const onRemoveFile = useCallback(
    fileName => {
      const maybeOldFile = oldFiles.find(f => f.name === fileName);
      if (maybeOldFile) {
        maybeOldFile.isDeleted = true;
        return;
      }
      setNewFiles(prevNewFiles =>
        prevNewFiles.filter(f => f.name !== fileName)
      );
    },
    [oldFiles]
  );

  const setInitialAddFiles = useCallback(
    incomingNewFiles => {
      if (!incomingNewFiles?.length) {
        return;
      }

      const updatedNewFiles = {};

      incomingNewFiles
        .filter(f => f?.name)
        .forEach(newFile => {
          const maybeExistingFile = oldFiles[newFile.name];
          if (maybeExistingFile) {
            return; // skip over it, as its already in the list
          }

          newFile.isNew = true;
          newFile.isDeleted = false;
          newFile.state = attachedFileStates.selected;
          updatedNewFiles[newFile.name] = newFile;
        });

      setNewFiles(Object.values(updatedNewFiles));
    },
    [oldFiles]
  );

  const onAddFiles = useCallback(
    incomingNewFiles => {
      if (!incomingNewFiles?.length) {
        return;
      }

      setNewFiles(prevNewFiles => {
        const updatedNewFiles = prevNewFiles.reduce((acc, f) => {
          acc[f.name] = f;
          return acc;
        }, {});

        const getMaybeExisting = fileName => {
          const existingFiles = [...oldFiles, ...prevNewFiles];
          return existingFiles.find(f => f.name === fileName);
        };

        incomingNewFiles
          .filter(f => f?.name)
          .forEach(newFile => {
            const maybeExistingFile = getMaybeExisting(newFile.name);
            if (maybeExistingFile) {
              return; // skip over it, as its already in the list
            }

            newFile.isNew = true;
            newFile.isDeleted = false;
            newFile.state = attachedFileStates.selected;
            updatedNewFiles[newFile.name] = newFile;
          });

        return Object.values(updatedNewFiles);
      });
    },
    [oldFiles]
  );

  const addExistingAttachmentFiles = useCallback(attachments => {
    const incomingOldFiles = attachments.map(file => ({
      ...file,
      state: attachedFileStates.selected,
      isDeleted: file.isDeleted ?? false,
      isNew: false
    }));
    setOldFiles(incomingOldFiles);
  }, []);

  return {
    onRemoveFile,
    onAddFiles,
    files,
    addExistingAttachmentFiles,
    setInitialAddFiles
  };
}
