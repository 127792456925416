import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import TextAreaInput from "@components/atoms/TextAreaInput";

import "./TextArea.scss";

function TextArea(props) {
  const {
    formState: { errors },
    getValues,
    setValue,
    control
  } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue || (currentValue.length === 0 && props.defaultValue)) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: props.required }}
      shouldUnregister={props.shouldUnregister}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <TextAreaInput
            label={props.label}
            ref={ref}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            value={getValues(props.name) || ""}
            required={props.required}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            maxLength={props.maxLength}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={props.placeholder}
          />
        );
      }}
    />
  );
}

TextArea.defaultProps = {
  autoFocus: false,
  required: false,
  disabled: false,
  shouldUnregister: false
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  shouldUnregister: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool
};

export default TextArea;
