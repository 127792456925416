import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, ButtonVariant } from "@atoms/Button";

import ConversationList from "@components/organisms/ConversationList/ConversationList";

const LeftPanel = props => {
  const {
    onClickCreate,
    onClickConversation,
    allConversations,
    conversationId
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="smart-form-comments-modal-template__panel-top">
        <Button
          iconName={"add"}
          variant={ButtonVariant.TEXT}
          onClick={onClickCreate}
          label={t("common:ui.smartform.conversation.action.newMessage.label")}
          className={
            "smart-form-comments-modal-template__panel-top__create-button"
          }
        />
        <div className="panel__top__search-bar">{/** SEARCH BAR */}</div>
      </div>
      <div
        className={`smart-form-comments-modal-template__panel-bot ${
          allConversations?.length ? "" : "is-empty"
        }`}
      >
        <ConversationList
          allConversations={allConversations}
          onClickItem={onClickConversation}
          conversationId={conversationId}
        />
      </div>
    </>
  );
};

LeftPanel.defaultProps = {};

LeftPanel.propTypes = {
  onClickCreate: PropTypes.func,
  onClickConversation: PropTypes.func,
  allConversations: PropTypes.array,
  conversationId: PropTypes.number
};

export default LeftPanel;
