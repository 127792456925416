import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FontSize, FontWeight, Text } from "@atoms/Text";

import HoverText from "@components/atoms/HoverText";

import "./MilestoneProgressBar.scss";

const MilestoneProgressBar = props => {
  const { colorStatus, completedMilestone, totalMilestones, milestoneName } =
    props;
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const progressWidthSubtitleRef = useRef();

  const hoverText = t("common:ui.dashboard.panel.milestones.overallRatio", {
    completedMilestone,
    totalMilestones
  });

  const isMilestoneNameOverflowing =
    progressWidthSubtitleRef.current?.scrollWidth >
    progressWidthSubtitleRef.current?.clientWidth;

  const onHover = () => {
    setIsHovered(true);
  };

  const onHoverOut = () => {
    setIsHovered(false);
  };

  const progressWidth = `${(completedMilestone / totalMilestones) * 100}%`;
  return (
    <div className="milestone-progress-bar">
      <div
        className="milestone-progress-bar__bar"
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
      >
        <div
          className={`milestone-progress-bar__bar--fill milestone-progress-bar__bar--fill-${colorStatus}`}
          style={{ width: progressWidth }}
        />
        <HoverText name={hoverText} position={"right"} isActive={isHovered} />
      </div>
      <div
        className="milestone-progress-bar__subtitle"
        ref={progressWidthSubtitleRef}
        title={isMilestoneNameOverflowing ? t(milestoneName) : ""}
      >
        <Text
          weight={FontWeight.REGULAR}
          size={FontSize.S}
          text={t(milestoneName)}
        />
      </div>
    </div>
  );
};

MilestoneProgressBar.propTypes = {
  colorStatus: PropTypes.oneOf(["behind", "inprogress", "complete"]).isRequired,
  completedMilestone: PropTypes.number.isRequired,
  totalMilestones: PropTypes.number.isRequired,
  milestoneName: PropTypes.string.isRequired
};

export default MilestoneProgressBar;
