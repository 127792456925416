import { useDispatch } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";

export function useGetProjectFoldersAndDocuments() {
  const dispatch = useDispatch();

  const getProjectFoldersAndDocuments = ({ id: projectId }) => {
    dispatch(
      manageProjectDocumentsActions.getProjectFoldersAndDocuments(
        { id: projectId },
        "project"
      )
    );
  };

  return { getProjectFoldersAndDocuments };
}
