import { systemConstants } from "../constants";
import { utilities } from "../helpers";
import { authService } from "../services";

export function handleFileDownload(response) {
  let message =
    systemConstants.support.message ||
    "An unexpected error occurred. Please try again, and if the problem persists contact support";
  if (response && response.status === 401) {
    authService.logout();
    window.location.reload();
  }

  if (!response?.ok) {
    return Promise.reject({
      ...response,
      status: response && response.status,
      success: false,
      message: utilities.getFormattedErrorMessage(message)
    });
  }

  const contentDispositionHeader = response.headers.get("Content-Disposition");
  const matches = contentDispositionHeader.match(/filename="(.*?)"/i);
  const filename = matches[1];
  return response.blob().then(blob => {
    const objectURL = URL.createObjectURL(blob);
    return { blobUrl: objectURL, filename };
  });
}
