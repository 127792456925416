import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const externalLinkValidationService = {
  validateExternalLinkObjectAndGetMoreDetails,
  getErrorMessage
};

function getErrorMessage({ externalObject }) {
  switch (externalObject.type) {
    case systemConstants.externalLinkObjectType.projectNotification:
      return "You don't have access to this message. Please contact the sender if you believe this message was intended for you.";
    case systemConstants.externalLinkObjectType.projectTodo:
      return "You don't have access to this task. Please contact the sender if you believe this task was intended for you.";
    case systemConstants.externalLinkObjectType.projectRequestResponse:
      return "You don't have access to this response. Please contact the sender if you believe this response was intended for you.";
    case systemConstants.externalLinkObjectType.projectRequest:
      return "You don't have access to this request. Please contact the sender if you believe this request was intended for you.";
    case systemConstants.externalLinkObjectType.projectDataRepository:
      return "You don't have access to this project. Please contact the Engagement manager if you believe you should have access.";
    default:
      return "Invalid external link.";
  }
}

function validateExternalLinkObjectAndGetMoreDetails({ externalObject }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(externalObject)
  };
  return fetch(`${baseURL}/api/externalLinkValidation`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
