import React from "react";

import { useTranslation } from "react-i18next";

import { Icon, IconColor } from "@atoms/Icon";

type Props = {
  projectId: string | number;
};
function ViewProjectDashboardButton(props: Readonly<Props>) {
  const { projectId } = props;
  const { t } = useTranslation();

  return (
    <Icon
      name="open_in_new"
      color={IconColor.MUTED}
      onClick={() => window.open(`/projects/${projectId}`)}
      hoverElement={<>{t("common:expandButton.openInNewWindow.hoverText")}</>}
    />
  );
}

export default ViewProjectDashboardButton;
