import React from "react";

import { useTranslation } from "react-i18next";

import Footer from "@components/organisms/Footer";

import "./AuthContainer.scss";

const AuthContainer = props => {
  const getYear = () => {
    const dt = new Date();
    return dt.getFullYear();
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="auth-container">
        <div className="auth-container_form" data-testid="auth-form">
          {props.children}
        </div>

        <div className="auth-container__info">
          <div className="auth-container__info__background" />
          <div className="auth-container__info__overlay" />
          <div className="auth-container__info-box">
            <div className="auth-container__logo-container">
              <img
                data-testid="auth-oneteam-logo"
                className="auth-container__info-logo"
                src="logo-inverse-oneteam.png"
                alt="One Team"
              />
            </div>
            <div className="auth-container__info-box__content">
              <p className="auth-container__info-text">
                {t("common:authentication.authContainer.text1")}
              </p>
              <p className="auth-container__info-text">
                {t("common:authentication.authContainer.text2")}
              </p>
            </div>

            <div className="auth-container__info-box-privacy-policy">
              <i className="material-icons auth-container__info-box-privacy-policy-icon">
                copyright
              </i>
              <span className="auth-container__info-box-privacy-policy-year">
                {getYear()}
              </span>
              <u>
                <a
                  className="auth-container__info-box-privacy-policy-text"
                  href="https://www.oneteam.services/privacy-policy"
                  target="_blank"
                >
                  {t("common:authentication.authContainer.privacyPolicy")}
                </a>
              </u>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AuthContainer;
