export const manageFinalPackageDocumentsConstants = {
  GET_VISUALISATION_FOLDER_REQUEST: "GET_VISUALISATION_FOLDER_REQUEST",
  GET_VISUALISATION_FOLDER_SUCCESS: "GET_VISUALISATION_FOLDER_SUCCESS",
  GET_VISUALISATION_FOLDER_FAILURE: "GET_VISUALISATION_FOLDER_FAILURE",
  UPLOAD_VISUALISATION_FILE_REQUEST: "UPLOAD_VISUALISATION_FILE_REQUEST",
  UPLOAD_VISUALISATION_FILE_SUCCESS: "UPLOAD_VISUALISATION_FILE_SUCCESS",
  UPLOAD_VISUALISATION_FILE_FAILURE: "UPLOAD_VISUALISATION_FILE_FAILURE",
  UPDATE_VISUALISATION_TITLE_REQUEST: "UPDATE_VISUALISATION_TITLE_REQUEST",
  UPDATE_VISUALISATION_TITLE_SUCCESS: "UPDATE_VISUALISATION_TITLE_SUCCESS",
  UPDATE_VISUALISATION_TITLE_FAILURE: "UPDATE_VISUALISATION_TITLE_FAILURE",
  DELETE_VISUALISATION_FILE_REQUEST: "DELETE_VISUALISATION_FILE_REQUEST",
  DELETE_VISUALISATION_FILE_SUCCESS: "DELETE_VISUALISATION_FILE_SUCCESS",
  DELETE_VISUALISATION_FILE_FAILURE: "DELETE_VISUALISATION_FILE_FAILURE",
  RESET_VISUALISATION_ERROR: "RESET_VISUALISATION_ERROR",
  RESET_VISUALISATION: "RESET_VISUALISATION"
};
