import { manageProjectDocumentsConstants } from "../constants";
import { manageProjectDocumentsService } from "../services";

export const manageProjectDocumentsActions = {
  clearError,
  createProjectTag,
  deleteProjectDocument,
  deleteProjectTag,
  resetDeleteProjectTag,
  getAllTags,
  getArchivedProjectDocuments,
  getProjectDocumentRevisions,
  getProjectDocumentsBySearchString,
  getProjectDocumentsByTag,
  getProjectFoldersAndDocuments,
  getProjectRequestFoldersAndDocuments,
  moveProjectDocument,
  reset,
  resetAddProjectTag,
  resetAllTags,
  resetCreatedFolder,
  resetDeletedDocument,
  resetDocumentRevisions,
  resetMovedDocument,
  resetNewUploadedDocument,
  resetRestoredDocument,
  resetRevertedDocument,
  resetSelectedProjectDocument,
  resetUpdatedDocument,
  resetUploadedDocument,
  restoreProjectDocument,
  setSelectedProjectDocument,
  updateProjectDocumentCurrentRevision,
  updateProjectDocumentProperties,
  updateProjectTag,
  uploadNewProjectDocument,
  uploadProjectDocument
};

function getProjectDocumentsByTag({ projectId, tagId }) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .getProjectDocumentsByTag({ projectId, tagId })
      .then(
        documents => {
          dispatch(success(documents));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_REQUEST
    };
  }
  function success(taggedDocuments) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_SUCCESS,
      taggedDocuments
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_BY_TAG_FAILURE,
      error
    };
  }
}

function getAllTags(project) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.getAllTags(project).then(
      tags => {
        dispatch(success(tags));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_ALL_TAGS_REQUEST
    };
  }
  function success(tags) {
    return {
      type: manageProjectDocumentsConstants.GET_ALL_TAGS_SUCCESS,
      tags
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_ALL_TAGS_FAILURE,
      error
    };
  }
}

function createProjectTag(project, tag) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.createProjectTag(project, tag).then(
      tag => {
        dispatch(success(tag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.ADD_PROJECT_TAG_REQUEST
    };
  }
  function success(tag) {
    return {
      type: manageProjectDocumentsConstants.ADD_PROJECT_TAG_SUCCESS,
      tag
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.ADD_PROJECT_TAG_FAILURE,
      error
    };
  }
}

function updateProjectTag(project, tag) {
  return dispatch => {
    dispatch(request());
    manageProjectDocumentsService.updateProjectTag(project, tag).then(
      tag => {
        dispatch(success(tag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_TAG_REQUEST
    };
  }
  function success(tag) {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_TAG_SUCCESS,
      tag
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_TAG_FAILURE,
      error
    };
  }
}

function deleteProjectTag(project, tag, force = false) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.deleteProjectTag(project, tag, force).then(
      tag => {
        dispatch(success(tag));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_TAG_REQUEST
    };
  }
  function success(deletedProjectTag) {
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_TAG_SUCCESS,
      deletedProjectTag
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_TAG_FAILURE,
      error
    };
  }
}

function updateProjectDocumentCurrentRevision(revision) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .updateProjectDocumentCurrentRevision(revision)
      .then(
        updatedObject => {
          dispatch(success(updatedObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_REQUEST
    };
  }
  function success(updatedObject) {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_SUCCESS,
      updatedObject
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_FAILURE,
      error
    };
  }
}

function getProjectDocumentsBySearchString(project, search) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .getProjectDocumentsBySearchString(project, search)
      .then(
        documents => {
          dispatch(success(documents));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_REQUEST
    };
  }
  function success(documents) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_FAILURE,
      error
    };
  }
}

function getArchivedProjectDocuments(project) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.getArchivedProjectDocuments(project).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_REQUEST
    };
  }
  function success(documents) {
    return {
      type: manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_ARCHIVED_PROJECT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function deleteProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.deleteProjectDocument(document).then(
      deletedDocumentObject => {
        dispatch(success(deletedDocumentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(deletedDocumentObject) {
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_SUCCESS,
      deletedDocumentObject
    };
  }
  function failure(error) {
    if (error.message === "Access denied") {
      error.message =
        "You need to be involved with the project to delete files. Add yourself to the project and try again.";
    }
    return {
      type: manageProjectDocumentsConstants.DELETE_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function restoreProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.restoreProjectDocument(document).then(
      restoredDocumentObject => {
        dispatch(success(restoredDocumentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.RESTORE_PROJECT_DOCUMENT_REQUEST
    };
  }

  function success(restoredDocumentObject) {
    return {
      type: manageProjectDocumentsConstants.RESTORE_PROJECT_DOCUMENT_SUCCESS,
      restoredDocumentObject
    };
  }

  function failure(error) {
    if (error.message === "Access denied") {
      error.message =
        "You need to be involved with the project to restore files. Add yourself to the project and try again.";
    }
    return {
      type: manageProjectDocumentsConstants.RESTORE_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function moveProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.moveProjectDocument(document).then(
      movedDocumentObject => {
        dispatch(success(movedDocumentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(movedDocumentObject) {
    return {
      type: manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_SUCCESS,
      movedDocumentObject
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.MOVE_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function getProjectDocumentRevisions(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.getProjectDocumentRevisions(document).then(
      revisions => {
        dispatch(success(revisions));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_REQUEST
    };
  }
  function success(revisions) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_SUCCESS,
      revisions
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_DOCUMENT_REVISIONS_FAILURE,
      error
    };
  }
}

function uploadProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.uploadProjectDocument(document).then(
      uploadedObject => {
        dispatch(success(uploadedObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(uploadedObject) {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_SUCCESS,
      uploadedObject
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function uploadNewProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService.uploadNewProjectDocument(document).then(
      uploadedObject => {
        dispatch(success(uploadedObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_NEW_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(uploadedObject) {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_NEW_PROJECT_DOCUMENT_SUCCESS,
      uploadedObject
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.UPLOAD_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function updateProjectDocumentProperties(document) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .updateProjectDocumentProperties(document)
      .then(
        document => {
          dispatch(success(document));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_REQUEST
    };
  }
  function success(updatedObject) {
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_SUCCESS,
      updatedObject
    };
  }
  function failure(error) {
    if (error.message === "Access denied") {
      error.message =
        "You need to be involved with the project to delete files. Add yourself to the project and try again.";
    }
    return {
      type: manageProjectDocumentsConstants.UPDATE_PROJECT_DOCUMENT_PROPERTIES_FAILURE,
      error
    };
  }
}

function getProjectFoldersAndDocuments(project, folderType = null) {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .getProjectFoldersAndDocuments(project, folderType)
      .then(
        projectFoldersAndDocuments => {
          dispatch(success(projectFoldersAndDocuments));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST
    };
  }
  function success(projectFoldersAndDocuments) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS,
      projectFoldersAndDocuments
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE,
      error
    };
  }
}

function getProjectRequestFoldersAndDocuments(project, folderType = "request") {
  return dispatch => {
    dispatch(request());

    manageProjectDocumentsService
      .getProjectFoldersAndDocuments(project, folderType)
      .then(
        projectFoldersAndDocuments => {
          dispatch(success(projectFoldersAndDocuments));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_REQUEST
    };
  }
  function success(projectFoldersAndDocuments) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_SUCCESS,
      projectFoldersAndDocuments
    };
  }
  function failure(error) {
    return {
      type: manageProjectDocumentsConstants.GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: manageProjectDocumentsConstants.CLEAR_ERROR };
}

function setSelectedProjectDocument(document) {
  return {
    type: manageProjectDocumentsConstants.SET_SELECTED_PROJECT_DOCUMENT,
    document
  };
}

function resetSelectedProjectDocument() {
  return {
    type: manageProjectDocumentsConstants.RESET_SELECTED_PROJECT_DOCUMENT
  };
}

function resetCreatedFolder() {
  return { type: manageProjectDocumentsConstants.RESET_CREATED_FOLDER };
}

function resetUpdatedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_UPDATED_DOCUMENT };
}

function resetUploadedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_UPLOADED_DOCUMENT };
}

function resetNewUploadedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_NEW_UPLOADED_DOCUMENT };
}

function resetDocumentRevisions() {
  return { type: manageProjectDocumentsConstants.RESET_DOCUMENT_REVISIONS };
}

function resetRestoredDocument() {
  return { type: manageProjectDocumentsConstants.RESET_RESTORED_DOCUMENT };
}

function resetDeletedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_DELETED_DOCUMENT };
}

function resetMovedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_MOVED_DOCUMENT };
}

function resetAddProjectTag() {
  return { type: manageProjectDocumentsConstants.RESET_ADD_PROJECT_TAG };
}

function reset() {
  return { type: manageProjectDocumentsConstants.RESET };
}

function resetAllTags() {
  return { type: manageProjectDocumentsConstants.RESET_ALL_TAGS };
}

function resetRevertedDocument() {
  return { type: manageProjectDocumentsConstants.RESET_REVERTED_DOCUMENT };
}

function resetDeleteProjectTag() {
  return { type: manageProjectDocumentsConstants.RESET_DELETE_PROJECT_TAG };
}
