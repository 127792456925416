import React from "react";

import { useTranslation } from "react-i18next";

import { getErrorMessage } from "@app/helpers/error";
import { RTKError } from "@app/types";

import { Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import InlineAlert from "@components/atoms/InlineAlert";
import ModalTemplate from "@components/templates/ModalTemplate";

type Props = {
  roleId?: number;
  error?: RTKError;
  onCancel?: () => void;
  onDelete?: (roleId?: number) => void;
};

const DeleteUserRole = (props: Props) => {
  const { roleId, error } = props;
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    props.onDelete?.(roleId);
  };
  const handleCancel = () => {
    props.onCancel?.();
  };

  const getContent = () => {
    return (
      <Stack>
        <div>{t("admin:ui.manageUserRoles.modal.delete.content")}</div>
        {error && (
          <InlineAlert type="error" message={getErrorMessage(error, t)} />
        )}
      </Stack>
    );
  };

  return (
    <ModalTemplate
      boxClassName="role-deletion"
      title={t("admin:ui.manageUserRoles.modal.delete.title")}
      content={getContent()}
      footer={
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            label={t("common:ui.forms.no.label")}
            onClick={handleCancel}
          />
          <Button
            variant={ButtonVariant.DANGER}
            label={t("common:ui.forms.yes.label")}
            onClick={handleDeleteClick}
          />
        </>
      }
      onClose={props.onCancel}
    />
  );
};

export default DeleteUserRole;
