import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useGetProjectMembersQuery } from "@shared/hooks";
import { projectMemberUtil } from "@shared/services/projectMemberService";

export function useGetProjectMembers(project) {
  const { t } = useTranslation();
  const { data: membersData, isLoading: membersLoading } =
    useGetProjectMembersQuery(project?.id, {
      skip: !project?.id
    });

  const dispatch = useDispatch();

  const members = useMemo(() => {
    if (!membersData) {
      return {
        hostUsers: [],
        clientUsers: []
      };
    }
    return membersData;
  }, [membersData]);

  const isProjectMember = useCallback(
    (user, action, cb, error) => {
      if (
        user?.isHostUser &&
        !members?.hostUsers?.some(member => member.id === user.id)
      ) {
        const actionText =
          action ?? t("common:project.accessRequiredActionFallback");
        const errorMessage = t("common:project.accessRequiredToDoAction", {
          action: actionText
        });
        error?.(errorMessage);
        return false;
      } else {
        cb?.();
        return true;
      }
    },
    [members, t]
  );

  const refetchMembers = () => {
    dispatch(projectMemberUtil.invalidateTags(["ProjectMembers"]));
  };

  return {
    members,
    membersLoading,
    isProjectMember,
    refetchMembers
  };
}
