import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Popup from "@shared/components/popup/Popup";
import { useAuthUser } from "@shared/hooks/useAuthUser.tsx";

import { useInsightsBoardStore } from "@app/stores/useInsightsBoardStore.ts";
import { AccessLevel } from "@app/types/Permission.ts";
import { ResourceName } from "@app/types/Resource.ts";

import { Button, ButtonVariant } from "@atoms/Button";
import { Icon, IconColor } from "@atoms/Icon";

import Form from "@components/atoms/Form";
import Loading from "@components/molecules/Loading/Loading";
import ModalForm from "@components/molecules/ModalForm/ModalForm";
import InsightsBoardSidePanel from "@components/organisms/InsightsBoardSidePanel";
import SelectEngagementTypeDropdown from "@components/organisms/SelectEngagementTypeDropdown";
import InsightsGridViewTemplate from "@components/templates/InsightsGridViewTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import boardStyles from "./InsightsBoardTemplate.module.scss";
import "./InsightsBoardTemplate.scss";

function InsightsBoardTemplate(props) {
  const { t } = useTranslation();
  const {
    insightsBoardId,
    subtitleText,
    insightsCards,
    onEngagementTypeSelected,
    handleUpdate,
    handleSubmit,
    handleSwitchToEditMode,
    error,
    loading,
    handleCancelEditMode
  } = props;
  const [editMode, setEditMode] = useState(false);
  const { user } = useAuthUser();
  const [errorMessage, setErrorMessage] = useState("");
  const [showEditSubtitleModal, setShowEditSubtitleModal] = useState(false);
  const [selectedEngagementType, setSelectedEngagementType] = useState(null);
  const selectedDrilldown = useInsightsBoardStore(state => state.drilldown);
  const clearDrilldown = useInsightsBoardStore(state => state.clearDrilldown);
  const setCurrentEngagementTypeChanged = useInsightsBoardStore(
    state => state.setCurrentEngagementTypeChanged
  );
  const gridRef = useRef(null);
  const rowHeight = +boardStyles["ot-grid-row-height"].match(/\d+/g);

  const scrollToActiveCard = useCallback(() => {
    if (gridRef.current && selectedDrilldown?.insightsCardId) {
      const activeCard = gridRef.current.querySelector(
        `[data-insights-card="${selectedDrilldown.insightsCardId}"]`
      );
      if (activeCard) {
        //Delay scroll to active card to allow time for grid to render new layout
        setTimeout(() => {
          activeCard?.scrollIntoView({ behavior: "smooth" });
        }, 400);
      }
    }
  }, [selectedDrilldown.insightsCardId]);

  useEffect(() => {
    if (selectedDrilldown?.insightsCardId) {
      scrollToActiveCard();
    }
  }, [scrollToActiveCard, selectedDrilldown?.insightsCardId]);

  const insightsGrid = useMemo(() => {
    if (loading) {
      return <Loading message={t("common:loading")} />;
    }
    return (
      <InsightsGridViewTemplate
        cols={3}
        rowHeight={rowHeight}
        layouts={insightsCards?.layouts}
      >
        {insightsCards?.cards}
      </InsightsGridViewTemplate>
    );
  }, [insightsCards?.cards, insightsCards?.layouts, loading, rowHeight, t]);

  const handleEngagementTypeSelected = useCallback(
    engagementType => {
      onEngagementTypeSelected?.(engagementType);
      setSelectedEngagementType(engagementType);
      setCurrentEngagementTypeChanged();
      clearDrilldown();
    },
    [clearDrilldown, onEngagementTypeSelected, setCurrentEngagementTypeChanged]
  );

  const bodyContent = useMemo(() => {
    return (
      <div className="insights-board-template__container">
        <div className="insights-board-template__dropdown">
          <SelectEngagementTypeDropdown
            onChange={handleEngagementTypeSelected}
          />
        </div>
        <div className="insights-board-template__grid" ref={gridRef}>
          {insightsGrid}
        </div>
      </div>
    );
  }, [handleEngagementTypeSelected, insightsGrid]);

  const drilldownSidePanel = useMemo(() => {
    return (
      <InsightsBoardSidePanel
        insightsBoardId={insightsBoardId}
        engagementTypeId={selectedEngagementType?.id}
      />
    );
  }, [insightsBoardId, selectedEngagementType?.id]);

  const enterEditMode = () => {
    setEditMode(true);
    handleSwitchToEditMode?.();
  };

  const cancelEditMode = () => {
    setEditMode(false);
    setErrorMessage("");
    handleCancelEditMode?.();
  };

  const handleSaveBoard = async () => {
    const error = await handleSubmit();
    if (!error) {
      setEditMode(false);
      return;
    }
    setErrorMessage(error);
  };

  const renderPageActions = () => {
    if (!user.checkAccess(ResourceName.INSIGHTS, AccessLevel.UPDATE)) {
      return <></>;
    }
    if (!editMode) {
      return (
        <Button
          label={t("common:insights.actions.switchToEditMode")}
          variant={ButtonVariant.SECONDARY}
          onClick={enterEditMode}
        />
      );
    }
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={cancelEditMode}
        />
        <Button
          label={t("common:ui.forms.save.label")}
          onClick={handleSaveBoard}
        />
      </>
    );
  };

  const handleOpenEditSubtitleModal = () => {
    setShowEditSubtitleModal(true);
  };

  const handleCloseAllModals = () => {
    setShowEditSubtitleModal(false);
  };

  const subtitleActionIcon = useMemo(() => {
    if (!editMode) {
      return <></>;
    }

    return (
      <Icon
        name="edit"
        color={IconColor.ACCENT}
        onClick={handleOpenEditSubtitleModal}
      />
    );
  }, [editMode]);

  const yupSchema = useMemo(() => {
    return yup.object({
      name: yup.string().required(t("common:insights.board.form.name.error"))
    });
  }, [t]);

  const handleUpdateInsightsBoard = data => {
    handleCloseAllModals();
    setErrorMessage("");
    handleUpdate?.(data);
  };

  return (
    <>
      <PageTemplate
        header={{
          subtitle: subtitleText,
          subtitleActionIcon,
          actions: renderPageActions()
        }}
        body={{
          primary: bodyContent
        }}
        sidePanel={{
          open: !isEmpty(selectedDrilldown?.data),
          content: drilldownSidePanel,
          hasSlider: true
        }}
        other={{
          error: error || errorMessage,
          secondaryNavDisabled: true
        }}
      />
      <Popup visibility={showEditSubtitleModal} width="60rem">
        <ModalForm
          submitLabel={t("common:ui.forms.apply.label")}
          cancelLabel={t("common:ui.forms.cancel.label")}
          title={t("common:insights.board.update.label")}
          handleCancel={handleCloseAllModals}
          handleSubmit={handleUpdateInsightsBoard}
          yupSchema={yupSchema}
        >
          <Form.TextField
            label={t("common:insights.board.form.name.label")}
            name="name"
            required={true}
            maxLength={50}
            defaultValue={subtitleText}
          />
        </ModalForm>
      </Popup>
    </>
  );
}

InsightsBoardTemplate.defaultProps = {
  handleSubmit: () => ""
};

InsightsBoardTemplate.propTypes = {
  subtitleText: PropTypes.string,
  insightsCards: PropTypes.object,
  onEngagementTypeSelected: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleSwitchToEditMode: PropTypes.func,
  handleCancelEditMode: PropTypes.func
};

export default InsightsBoardTemplate;
