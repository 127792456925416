import React, { forwardRef, useCallback, useMemo } from "react";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Inline, Stack } from "@fermions";

import {
  InputLabel,
  InputLabelProps,
  InputWidth,
  getInputIdentifier
} from "../InputTemplate";
import "./RadioButtonInput.scss";

interface RadioButtonInputProps extends InputLabelProps {
  value: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  error?: string;
  extraProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  width?: InputWidth;
}

export const RadioButtonInput = forwardRef(
  (
    {
      value,
      onChange,
      label,
      description,
      hideLabel,
      required,
      disabled,
      error,
      extraProps = {},
      width = InputWidth.FILL,
      fontColor
    }: RadioButtonInputProps,
    fwdRef
  ) => {
    const handleChange = useCallback(() => {
      onChange?.(!value);
    }, [value, onChange]);

    const identifier = useMemo(
      () => getInputIdentifier(label, "radio-button-input"),
      [label]
    );

    const labelElement = useMemo(
      () => (
        <InputLabel
          label={label}
          disabled={disabled}
          required={required}
          description={description}
          id={identifier}
          hideLabel={hideLabel}
          fontColor={fontColor}
        />
      ),
      [label, disabled, required, description, identifier, hideLabel, fontColor]
    );

    return (
      <Stack gap="050">
        <Inline
          className={classNames([
            "radio-button-input",
            `radio-button-input--width-${width}`,
            returnStringIfTrue(disabled, "radio-button-input--disabled")
          ])}
          onClick={disabled ? undefined : handleChange}
          data-testid={`test_${label?.replaceAll(" ", "-")}_input`}
        >
          <Box
            className={classNames([
              "radio-button",
              returnStringIfTrue(value, "checked"),
              returnStringIfTrue(disabled, "radio-button--disabled")
            ])}
          >
            <input
              aria-labelledby={identifier}
              className="radio-button__input"
              ref={fwdRef}
              type="checkbox"
              checked={value}
              onChange={handleChange}
              disabled={disabled}
              data-testid={`test_${identifier}`}
              {...extraProps}
            />
            {value && <div className="radio-button__circle" />}
          </Box>
          {labelElement}
        </Inline>
        {error && <span className="input-template__error">{error}</span>}
      </Stack>
    );
  }
);
