import React, { useCallback, useState } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { useCurrentProject } from "@shared/hooks";
import {
  useGetProjectDocuments,
  useGetProjectFoldersAndDocuments,
  useUpdateDocument,
  useUploadDocumentRevision
} from "@shared/hooks";

import { documentActions } from "@app/helpers/documents";
import { convertEntitiesToIds } from "@app/helpers/entity";

import { Button, ButtonVariant } from "@atoms/Button";

import Form from "@components/atoms/Form/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ModalForm from "@components/molecules/ModalForm";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList/UploadFileAttachmentList";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./UploadDocumentRevision.scss";

function getExtension(fileName) {
  const parts = fileName?.split(".") ?? [];
  if (parts.length > 1) {
    return parts.pop();
  }
  return "";
}

const addFilesState = systemConstants.addFiles.state;

const UploadDocumentRevision = props => {
  const { document, onUpload, onCancel, projectFolder, t } = props;
  const extensions = [getExtension(document.name)];
  const { currentProject } = useCurrentProject();
  const entityEnabled = currentProject.configuration.entities?.enabled;
  const documentEntities = document.properties?.entities;
  const { entities: projectEntities } = currentProject;

  const { getProjectDocuments } = useGetProjectDocuments();
  const { getProjectFoldersAndDocuments } = useGetProjectFoldersAndDocuments();
  const { uploadDocumentRevision } = useUploadDocumentRevision(document);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState(
    projectEntities.length === 1 ? projectEntities : documentEntities
  );
  const { updateDocumentProperties } = useUpdateDocument(
    structuredClone(document),
    projectFolder
  );

  const handleDone = useCallback(() => {
    if (uploadedDocument) {
      getProjectFoldersAndDocuments({ id: document.projectId });
      getProjectDocuments(document.projectId);
      onUpload();
    } else {
      onCancel();
    }
  }, [
    document.projectId,
    getProjectDocuments,
    getProjectFoldersAndDocuments,
    onUpload,
    onCancel,
    uploadedDocument
  ]);

  const handleUploadDocument = useCallback(
    data => {
      const isUpdateEntities =
        entityEnabled && !isEqual(documentEntities, selectedEntities);
      if (Object.values(data.files).length !== 1 && !isUpdateEntities) {
        setErrorMessage("common:ui.uploadDocumentRevision.error.noUpdate");
        return;
      }
      if (isUpdateEntities) {
        updateDocumentProperties({
          properties: {
            ...document.properties,
            entities: convertEntitiesToIds(selectedEntities)
          }
        });
      }
      if (Object.values(data.files).length !== 1) {
        handleDone();
        return;
      }

      const candidateFile = Object.values(data.files)[0];
      if (candidateFile.size > systemConstants.project.document.maxSize) {
        setErrorMessage(
          "common:ui.uploadDocumentRevision.error.fileSize.oversize"
        );
      }
      setErrorMessage("");
      setUploadState(addFilesState.upload);
    },
    [
      documentEntities,
      selectedEntities,
      entityEnabled,
      updateDocumentProperties,
      document.properties,
      handleDone
    ]
  );

  const handleUploadComplete = useCallback(
    uploadedFiles => {
      setUploadState(addFilesState.finished);
      if (Object.values(uploadedFiles).length != 1) {
        return;
      }

      const uploadedFile = Object.values(uploadedFiles)[0];
      if (uploadedFile.filePathId) {
        uploadDocumentRevision({
          fileName: uploadedFile.name,
          filePathId: uploadedFile.filePathId
        });
        setUploadedDocument(uploadedFile);
      }
    },
    [uploadDocumentRevision]
  );

  const renderUploadCompleted = () => (
    <ModalTemplate
      boxClassName="document-upload-revision"
      title={t("common:ui.documents.fileUpload.label")}
      content={
        <div className="document-upload-revision__success">
          <InlineAlert
            type="success"
            message={t("common:documents.fileUpload.success")}
          />
          <UploadFileAttachmentList
            attachments={{
              [uploadedDocument.name]: uploadedDocument
            }}
          />
        </div>
      }
      footer={
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            label={t("common:documents.fileUpload.buttons", {
              context: "DONE"
            })}
            onClick={handleDone}
          />
        </>
      }
    />
  );

  const handleSelectEntities = e => {
    setSelectedEntities(
      e?.map(({ name, externalId }) => ({
        name,
        externalId
      }))
    );
  };

  const renderUploadPending = () => (
    <ModalForm
      boxClassName="document-upload-revision"
      title={t("common:documents.documentActions", {
        context: documentActions.uploadDocument
      })}
      handleCancel={props.onCancel}
      handleSubmit={handleUploadDocument}
      submitLabel={t("common:documents.fileUpload.buttons", {
        context: "UPLOAD"
      })}
    >
      {errorMessage && <InlineAlert type="error" message={t(errorMessage)} />}

      <Form.UploadDocuments
        name={"files"}
        supportedDocumentMimes={extensions}
        projectId={document.projectId}
        state={uploadState}
        onUploadsComplete={handleUploadComplete}
        onUploadsFailed={() => {
          setUploadState(addFilesState.finished);
        }}
        maxNumberOfFiles={1}
        disableDragDrop={true}
      />
      {entityEnabled && projectEntities?.length > 1 && (
        <Form.Multiselect
          key="entities"
          name="entities"
          onChange={handleSelectEntities}
          label={t(`requests:requests.configured.fields.entities.label`)}
          defaultValue={selectedEntities?.map(entity => ({
            ...entity,
            value: entity.name
          }))}
          items={projectEntities}
        />
      )}
    </ModalForm>
  );

  return (
    <>{uploadedDocument ? renderUploadCompleted() : renderUploadPending()}</>
  );
};

UploadDocumentRevision.defaultProps = {};

UploadDocumentRevision.propTypes = {
  document: PropTypes.any.isRequired,
  projectFolder: PropTypes.any.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default withTranslation()(UploadDocumentRevision);
