import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";

export function useReorderProjectMembers() {
  const dispatch = useDispatch();
  const [reorderProjectMemberError, setReorderProjectMemberError] = useState();
  const manageProject = useSelector(state => state.manageProject);

  useEffect(() => {
    setReorderProjectMemberError(manageProject.error);
  }, [manageProject.error]);

  const reorderProjectMember = useCallback(
    (project, members) => {
      dispatch(manageProjectActions.reorderProjectMembers(project, members));
    },
    [dispatch]
  );

  return {
    reorderProjectMemberError,
    reorderProjectMember
  };
}
