import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import "./PopupOverlay.scss";

const PopupOverlay = props => {
  const { isVisible, onClose, showClose, isModal, width, height } = props;

  const handleClose = () => {
    handleFreezeScrollPosition(false);
    onClose?.();
  };

  useEffect(() => {
    handleFreezeScrollPosition(isVisible);
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div className="popup-overlay" onClick={handleClose}>
          <div className="popup-overlay__container">
            {showClose && (
              <div className="popup-overlay__header">
                <i className="material-icons close-btn" onClick={handleClose}>
                  close
                </i>
              </div>
            )}
            {isModal ? (
              <div
                className="popup-overlay__body popup-overlay__body--modal"
                style={{
                  height: height,
                  maxWidth: width
                }}
              >
                {props.children}
              </div>
            ) : (
              <div className="popup-overlay__body">{props.children} </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

PopupOverlay.defaultProps = {
  showClose: false,
  isVisible: false,
  isModal: false
};

PopupOverlay.propTypes = {
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  isVisible: PropTypes.bool,
  isModal: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

export default PopupOverlay;
