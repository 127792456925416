import React, { useCallback, useMemo } from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";
import {
  PageInfoItem,
  PaginationType,
  paginationHelper
} from "@app/helpers/pagination";

import { Box } from "@fermions";

import { Icon, IconFillStyle } from "@atoms/Icon";

import OTTooltip from "@components/atoms/OTTooltip";

import "./Pagination.scss";

interface PaginationNumberProps {
  t: unknown;
  page: number;
  isActive?: boolean;
  information: PageInfoItem;
  handlePageUpdate: (page: number) => void;
  type: PaginationType;
}

export const PaginationNumber = ({
  t,
  page,
  isActive,
  information,
  handlePageUpdate,
  type
}: PaginationNumberProps) => {
  const { showTooltip, elementProps } = useTooltip();

  const iconName = useMemo(
    () => paginationHelper.getPageIconName(information, type),
    [information, type]
  );

  const tooltipText = useMemo(() => {
    const lines = paginationHelper.getPageHoverText(t, information, type);
    return lines?.length ? (
      <div className="pagination-number__hover" key="pagination-number-hover">
        {lines.map((text, index) => (
          <span key={index}>{text} </span>
        ))}
      </div>
    ) : null;
  }, [information, t, type]);

  const onClick = useCallback(
    () => handlePageUpdate(page),
    [handlePageUpdate, page]
  );

  return (
    <Box
      key={`pagination-number-page-${page}`}
      className={[
        "pagination-number",
        returnStringIfTrue(isActive, "pagination-number--current")
      ]}
      onClick={onClick}
      alignment="center"
      {...elementProps}
    >
      <Box className="pagination-number__text" alignment="center">
        {page}
      </Box>
      {iconName && (
        <Box className="pagination-number__icon">
          <Icon name={iconName} fillStyle={IconFillStyle.FILLED} />
        </Box>
      )}
      {tooltipText && <OTTooltip visible={showTooltip} content={tooltipText} />}
    </Box>
  );
};
