import { useCallback, useEffect, useRef, useState } from "react";

import { finalPackageService } from "@shared/services/finalPackageService";

export function useGetFinalPackageSignedDocuments() {
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState("");
  const cancelRequest = useRef(false);

  const fetchSignedDocuments = useCallback(
    projectId => {
      if (currentProjectId == projectId && isLoading) return;
      setFetched(false);
      setError("");
      setIsLoading(false);
      setCurrentProjectId(projectId);
      cancelRequest.current = false;
    },
    [currentProjectId, isLoading]
  );

  useEffect(() => {
    cancelRequest.current = false;
    if (!currentProjectId || isLoading || fetched || error) return;

    setIsLoading(true);
    finalPackageService
      .getSignedDocuments(currentProjectId)
      .then(data => {
        if (!cancelRequest.current) {
          setSignedDocuments(data);
          setIsLoading(false);
          setFetched(true);
        }
      })
      .catch(err => {
        if (!cancelRequest.current) {
          setIsLoading(false);
          setError(err);
        }
      });

    return () => {
      cancelRequest.current = true;
    };
  }, [fetched, isLoading, currentProjectId, error]);

  return { error, isLoading, fetched, signedDocuments, fetchSignedDocuments };
}
