import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { useGetSupportedMimesQuery } from "@shared/hooks";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import ImageEditor from "@components/molecules/ImageEditor";
import { InputLabel } from "@components/molecules/inputs/InputTemplate";
import { useFormContext } from "react-hook-form";

import "./ClientLogo.scss";

interface ClientLogoProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logo?: { data: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLogo?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setClientLogoModal?: any;
}

export const ClientLogo = ({
  logo,
  setLogo,
  setClientLogoModal
}: ClientLogoProps) => {
  const { t } = useTranslation();
  const [logoImageVisibility, setLogoImageVisibility] = useState(false);
  const { data: supportedMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.image
  });
  const { trigger } = useFormContext();

  const handleLogoSetOrRemoveClick = useCallback(
    image => {
      setLogo(image ? { data: image } : null);
    },
    [setLogo]
  );

  const handlePopupOutsideClick = useCallback(() => {
    setLogoImageVisibility(false);
  }, []);

  const handleLogoImageSetClick = useCallback(
    base64Image => {
      handleLogoSetOrRemoveClick(base64Image);
      setLogoImageVisibility(false);
      trigger("name");
    },
    [handleLogoSetOrRemoveClick, trigger]
  );

  const handleLogoImageClick = useCallback(() => {
    setLogoImageVisibility(true);
  }, []);

  const handleLogoImageRemoveClick = useCallback(() => {
    handleLogoSetOrRemoveClick(null);
    trigger("name");
  }, [handleLogoSetOrRemoveClick, trigger]);

  useEffect(() => {
    setClientLogoModal(
      <Popup
        visibility={logoImageVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <ImageEditor
          title={t("common:ui.logo.field.set")}
          image={logo?.data}
          supportedMimes={supportedMimes}
          onSet={handleLogoImageSetClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    );
  }, [
    handleLogoImageSetClick,
    handlePopupOutsideClick,
    logo?.data,
    logoImageVisibility,
    setClientLogoModal,
    supportedMimes,
    t
  ]);

  return (
    <Stack className="logo-image-container" gap="075">
      <InputLabel label={t("common:ui.client.field.logo.label")} />
      <Stack className="logo-image" alignment="top-left">
        <Box
          className={[
            "logo-image__content",
            returnStringIfTrue(!logo?.data, "logo-image__content--blank")
          ]}
        >
          {logo?.data && (
            <img
              src={logo?.data}
              alt={t("common:ui.client.field.logo.label")}
              className={`logo-image__image`}
            />
          )}
        </Box>

        <Stack className="logo-image__config" gap="050" alignment="center">
          <Button
            label={t("common:ui.logo.field.change")}
            onClick={handleLogoImageClick}
            variant={ButtonVariant.TEXT_PRIMARY}
            iconName="update"
          />
          {logo?.data && (
            <Button
              label={t("common:ui.logo.field.remove")}
              onClick={handleLogoImageRemoveClick}
              variant={ButtonVariant.TEXT_INVERT}
              iconName="close"
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
