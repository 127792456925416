import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const riskService = createApi({
  reducerPath: "risksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["ProjectRisks"],
  endpoints: builder => ({
    getProjectRisks: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}/risks`,
      providesTags: ["ProjectRisks"]
    }),
    addNoteToRisk: builder.mutation({
      query: ({ note, projectId }) => ({
        url: `/api/projects/${projectId}/risks/${note.riskId}/notes`,
        method: "POST",
        body: note
      }),
      invalidatesTags: ["ProjectRisks"]
    }),
    addRiskToProject: builder.mutation({
      query: ({ risk, projectId }) => ({
        url: `/api/projects/${projectId}/risks`,
        method: "POST",
        body: risk
      }),
      invalidatesTags: ["ProjectRisks"]
    }),
    updateRisk: builder.mutation({
      query: ({ risk, projectId }) => ({
        url: `/api/projects/${projectId}/risks/${risk.id}`,
        method: "PUT",
        body: risk
      }),
      invalidatesTags: ["ProjectRisks"]
    })
  })
});
export const {
  useGetProjectRisksQuery,
  useAddNoteToRiskMutation,
  useAddRiskToProjectMutation,
  useUpdateRiskMutation
} = riskService;
