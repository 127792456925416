import React from "react";

import { CheckboxInput } from "@molecules/inputs";

interface DataTableCheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  indeterminate?: boolean;
  checked?: boolean;
}

export const DataTableCheckbox = React.forwardRef(
  (
    { indeterminate, checked, onChange, ...rest }: DataTableCheckboxProps,
    ref
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const handleOnChange = React.useCallback(
      checked => {
        onChange?.({
          target: {
            value: checked ? "on" : "off",
            checked,
            name: rest.name ?? ""
          }
        });
      },
      [checked, onChange]
    );

    return (
      <CheckboxInput
        ref={resolvedRef}
        {...rest}
        onChange={handleOnChange}
        value={indeterminate ? "indeterminate" : !!checked}
      />
    );
  }
);
