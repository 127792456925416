import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";

import { getWorkflowActionsForQuery } from "@app/helpers/actionItemTypes.ts";
import { getWorkflowStep, workflowStepName } from "@app/helpers/actionItems.js";
import { returnStringIfTrue } from "@app/helpers/componentHelpers";
import { SwimlaneItem } from "@app/types";

import { Inline, Stack } from "@fermions";

import Avatar from "@atoms/Avatar";
import { Icon } from "@atoms/Icon";
import { IconWithText } from "@atoms/IconWithText/IconWithText";
import { Pill, PillSize } from "@atoms/Pill";
import { StatusBar } from "@atoms/StatusBar";

import { ActionDropdown } from "@molecules/ActionDropdown/ActionDropdown";

import { DropdownItemType } from "../DropdownList/DropdownHelper";
import EntitiesListIconCell from "../EntitiesListIconCell";
import "./ActionItemPanel.scss";

export const ActionItemPanel = ({
  item,
  isDragging = false,
  menuItemClickHandler,
  entities: projectEntities
}: {
  item: SwimlaneItem;
  isDragging: boolean;
  menuItemClickHandler: (item: {
    menuItem: DropdownItemType;
    item: SwimlaneItem;
  }) => void;
  entities: Array<{
    name: string;
    externalId: string;
  }>;
}) => {
  const { t } = useTranslation();

  const query = item.data;

  const getTagsToDisplay = tags => {
    const maxTags = 2;
    if (!tags?.length) {
      return;
    }
    if (tags.length <= maxTags) {
      return { displayTags: tags };
    }

    const orderedTags = tags.sort((a, b) => a.length - b.length);
    return {
      displayTags: orderedTags.slice(0, maxTags),
      otherTags: orderedTags.slice(maxTags),
      allTags: orderedTags
    };
  };

  const renderTags = useCallback(
    tags => {
      const tagsDisplay = getTagsToDisplay(tags);
      return (
        tagsDisplay &&
        tagsDisplay.displayTags?.length > 0 && (
          <Inline className="action-item-panel__tags">
            {tagsDisplay.displayTags.map((tag: string) => (
              <Pill key={tag} label={tag} size={PillSize.SMALL} />
            ))}
            {tagsDisplay.otherTags?.length > 0 && (
              <IconWithText
                text={t(
                  "requests:requests.configured.fields.labels.additionalLabel",
                  {
                    numOthers: tagsDisplay.otherTags.length
                  }
                )}
                hoverText={tagsDisplay.allTags.join(" • ")}
                hoverTextPrefix={t(
                  "requests:requests.configured.fields.labels.label"
                )}
              />
            )}
          </Inline>
        )
      );
    },
    [t]
  );

  const renderEntities = useCallback(
    entities => {
      const isSingleEntityProject = projectEntities?.length === 1;
      const relevantEntities = Object.values(entities || {});
      if (relevantEntities.length < 2 && isSingleEntityProject) {
        return;
      }
      return <EntitiesListIconCell entities={relevantEntities} />;
    },
    [projectEntities?.length]
  );

  const renderError = useCallback(
    () => (
      <Icon
        className="flag flag-REJECTED"
        name="warning"
        fillStyle="filled"
        hoverElement={t("requests:requests.configured.status.ERROR.label")}
      />
    ),
    [t]
  );

  const renderFlag = useCallback(
    flag => {
      switch (flag) {
        case "REJECTED":
          return (
            <Icon
              className="flag flag-REJECTED"
              name="cancel"
              fillStyle="filled"
              hoverElement={t("requests:requests.configured.flags_REJECTED")}
            />
          );
        case "APPROVED":
          return (
            <Icon
              className="flag flag-APPROVED"
              name="check_circle"
              fillStyle="filled"
              hoverElement={t("requests:requests.configured.flags_APPROVED")}
            />
          );
      }
    },
    [t]
  );

  const renderActionItemTypeSpecificContent = useCallback(() => {
    switch (query.actionItemType?.configuration?.type) {
      case systemConstants.actionItemTypes.smartForm: {
        if (!query.workflowStep) {
          return <></>;
        }
        return (
          <Pill
            label={workflowStepName({
              t,
              workflowStep: getWorkflowStep({
                actionItemTypeConfig: query.actionItemType?.configuration,
                workflowStep: query.workflowStep
              })
            })}
          />
        );
      }
      case systemConstants.actionItemTypes.websheet:
      default:
        return <></>;
    }
  }, [query.actionItemType?.configuration, query.workflowStep, t]);

  const renderActionDropdown = useCallback(() => {
    if (!query.actions.length) {
      return <></>;
    }
    const isWorkflow = !!query?.actionItemType?.configuration?.workflow;
    const actions = isWorkflow
      ? getWorkflowActionsForQuery({
          actionItemType: query.actionItemType,
          userRoleType: query.userRoleType,
          t
        })
      : query.actions;

    return (
      <ActionDropdown
        data-testid="test__moreActionBtn"
        menuItems={actions}
        onMenuItemClick={(menuItem: DropdownItemType) =>
          menuItemClickHandler({ menuItem, item })
        }
      />
    );
  }, [item, menuItemClickHandler, query.actions]);

  return (
    <Inline
      className={[
        "action-item-panel",
        returnStringIfTrue(isDragging, "action-item-panel--dragging"),
        returnStringIfTrue(item.isLoading, "action-item-panel--loading")
      ]}
      width="100"
      onClick={item.onClick}
    >
      {query.indicator?.key && <StatusBar type={query.indicator?.key} />}
      <Stack className="action-item-panel__content" width="100">
        {renderActionDropdown()}
        <Stack className="action-item-panel__content__container" height="100">
          <Stack
            className={[
              "action-item-panel__top",
              "action-item-panel__content__container"
            ]}
            height="100"
          >
            <Inline
              className="action-item-panel__content__container"
              alignment="left"
            >
              <IconWithText
                iconName="tag"
                text={query.refNo}
                hoverTextPrefix={t("requests:requests.refLabel")}
                className="action-item-panel__refNo"
              />
            </Inline>
            <IconWithText
              className="action-item-panel__description"
              text={query.description}
              hoverTextPrefix={t(
                "requests:requests.configured.fields.description.label"
              )}
            />
            {renderTags(query.tags)}
          </Stack>
          <Stack
            className={[
              "action-item-panel__bottom",
              "action-item-panel__content__container"
            ]}
            style={{ justifyContent: "space-between" }}
          >
            <Inline
              className="action-item-panel__content__container"
              alignment="left"
            >
              {query.requiredBy && (
                <IconWithText
                  iconName="calendar_today"
                  text={query.requiredBy}
                  hoverTextPrefix={t("requests:requests.dueDateLabel")}
                />
              )}
            </Inline>
            <Inline
              className="action-item-panel__content__container"
              alignment="right"
            >
              {renderActionItemTypeSpecificContent()}
              <IconWithText
                text={query.actionItemType?.name}
                iconOnly
                iconName={query.queryTypeIcon}
                filled={false}
                hoverTextPrefix={t("requests:requests.typeLabel")}
              />
              {query.entities && renderEntities(query.entities)}
              {query.status === systemConstants.project.queries.status.error &&
                renderError()}
              {query.flag && renderFlag(query.flag)}
              {query.assignedTo && (
                <Avatar
                  user={query.assignedTo}
                  size="x-small"
                  showHover
                  hoverPrefix={t(
                    "requests:requests.configured.fields.assignedTo.pastTenseLabel"
                  )}
                />
              )}
            </Inline>
          </Stack>
        </Stack>
      </Stack>
    </Inline>
  );
};
