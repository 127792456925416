import * as React from "react";
const SvgDashboardGrid = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 240 240", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 0, y: 0 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 90, y: 0 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 180, y: 0 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 0, y: 90 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 90, y: 90 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 180, y: 90 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 0, y: 180 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 90, y: 180 }), /* @__PURE__ */ React.createElement("rect", { style: {
  fillOpacity: 1
}, width: 60, height: 60, x: 180, y: 180 }));
export default SvgDashboardGrid;
