import React, { KeyboardEvent, LegacyRef } from "react";

import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers";

import { Icon, IconSize } from "@atoms/Icon";

import "./SearchBar.scss";

interface SearchBarProps {
  value?: string;
  onChange: (value: string) => void;
  type?: "light" | "dark";
  placeholder?: string;
  disabled?: boolean;
  dataTestId?: string;
  autoFocus?: boolean;
  border?: "full" | "horizontal" | "bottom" | "none";
}

const SearchBar = React.forwardRef(
  (props: SearchBarProps, fwdRef: LegacyRef<HTMLInputElement>) => {
    const {
      value,
      onChange,
      type = "light",
      placeholder,
      disabled,
      dataTestId,
      autoFocus = true,
      border = "full"
    } = props;
    const { t } = useTranslation();

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    return (
      <div
        className={classNames([
          "search-bar",
          `search-bar--${type}`,
          `search-bar--border-${border}`
        ])}
      >
        <Icon name="search" className={"search-bar__icon"} />
        <input
          data-testid={dataTestId ?? "test-search"}
          className={`search-bar__input search-bar__input--${type}`}
          disabled={disabled}
          placeholder={placeholder ?? t("common:ui.forms.search.label")}
          value={value}
          onChange={event => onChange(event.target.value ?? "")}
          onKeyDown={handleKeyDown}
          ref={fwdRef}
          autoFocus={autoFocus}
        />
        <Icon
          name="close"
          className={classNames([
            "search-bar__clear",
            value?.length > 0
              ? "search-bar__clear--visible"
              : "search-bar__clear--hidden"
          ])}
          onClick={() => onChange("")}
          size={IconSize.S}
        />
      </div>
    );
  }
);

SearchBar.displayName = "SearchBar";

export default SearchBar;
