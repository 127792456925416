import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const newsService = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["NewsItems"],
  endpoints: builder => ({
    getNewsItems: builder.query({
      query: engagementTypeId =>
        `/api/engagementTypes/${engagementTypeId}/news`,
      providesTags: ["NewsItems"]
    }),
    addNewsItem: builder.mutation({
      query: ({ engagementTypeId, newsItem }) => ({
        url: `/api/engagementTypes/${engagementTypeId}/news`,
        method: "POST",
        body: newsItem
      })
    }),
    updateNewsItem: builder.mutation({
      query: ({ engagementTypeId, newsItem, updatedAt }) => ({
        url: `/api/engagementTypes/${engagementTypeId}/news/${newsItem.id}`,
        params: {
          updatedAt
        },
        method: "PUT",
        body: newsItem
      })
    }),
    deleteNewsItem: builder.mutation({
      query: ({ engagementTypeId, newsId, updatedAt }) => ({
        url: `/api/engagementTypes/${engagementTypeId}/news/${newsId}?${new URLSearchParams(
          { updatedAt }
        )}`,
        method: "DELETE"
      })
    })
  })
});

export const {
  useGetNewsItemsQuery,
  useAddNewsItemMutation,
  useUpdateNewsItemMutation,
  useDeleteNewsItemMutation,
  util: newsServiceUtil
} = newsService;
