import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers.js";
import {
  ActionItemType,
  CrudFormMode,
  WorkflowConfig
} from "@app/types/ActionItemType.ts";

import { Button, ButtonSize, ButtonVariant } from "@atoms/Button/Button.tsx";
import { IconSize } from "@atoms/Icon/index.ts";
import Text, { FontSize, FontWeight } from "@atoms/Text/Text.tsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

import { ActionNode } from "./ActionNode.tsx";
import { StepNode } from "./StepNode.tsx";
import "./WorkflowCanvas.scss";
import { useWorkflowCanvas } from "./WorkflowCanvasContext.tsx";
import { WorkflowDivider } from "./WorkflowDivider.tsx";
import { configToCanvas, generateEdgePath } from "./workflowCanvas.ts";

const StartNode = () => {
  const { t } = useTranslation();
  return (
    <Inline
      alignment="center"
      className="workflow-canvas__node workflow-canvas__node--start"
      data-nodeid="_start_" // this has a special nodeId to avoid potential conflicts with other nodes
    >
      <Text
        truncate={true}
        weight={FontWeight.SEMI_BOLD}
        size={FontSize.S}
        text={t("admin:ui.requestTypes.workflow.canvas.start")}
      />
    </Inline>
  );
};

const AddActionButton = () => {
  return (
    <Button
      label="Add Action"
      variant={ButtonVariant.TEXT}
      size={ButtonSize.SMALL}
      iconSize={IconSize.L}
      iconName="add"
    />
  );
};

interface WorkflowCanvasProps {
  workflow: WorkflowConfig;
  crudFormMode: CrudFormMode;
  actionItemType?: ActionItemType;
  showStart?: boolean;
  canSelect?: boolean;
  canAddAction?: boolean;
}

const WorkflowCanvas = (props: WorkflowCanvasProps) => {
  const {
    workflow,
    crudFormMode,
    actionItemType,
    showStart = true,
    canSelect = true,
    canAddAction = false
  } = props;
  const { t } = useTranslation();
  const workflowCanvasRef = useRef<HTMLDivElement>(null);
  const context = useWorkflowCanvas();
  const {
    hoveredNodeId,
    hoveredLaneId,
    selectedLaneId,
    selectedNodeId,
    setHoveredNodeId,
    setHoveredLaneId,
    setSelectedNodeId,
    setSelectedLaneId
  } = context;

  const graph = useMemo(() => {
    const { workflowSteps, workflowActions, workflowEdges } = configToCanvas({
      config: workflow,
      actionItemType,
      t
    });

    const nodeIdsInHoveredLane = new Set();
    const stepName = selectedNodeId?.split("-")[0];
    workflowSteps.forEach(step => {
      step.isHovered = hoveredNodeId === step.id;
      step.isSelected = selectedLaneId === step.id;
      if (hoveredLaneId === step.id) {
        nodeIdsInHoveredLane.add(step.id);
      }
    });
    workflowActions.forEach(action => {
      action.isHovered = hoveredNodeId === action.id;
      action.isSelected = selectedNodeId === action.id;
      if (
        hoveredLaneId === action.sourceWorkflowId ||
        (stepName && stepName === action.sourceWorkflowId)
      ) {
        nodeIdsInHoveredLane.add(action.id);
      }
    });
    workflowEdges.forEach(edge => {
      const isHidden = !nodeIdsInHoveredLane.has(edge.sourceId);
      edge.isAnimated =
        hoveredNodeId === edge.sourceId || selectedNodeId === edge.sourceId;
      edge.zIndex = edge.isAnimated ? 1 : 0;
      edge.isHidden = isHidden ?? false;
    });

    return {
      workflowSteps,
      workflowActions,
      workflowEdges: workflowEdges
        .filter(edge => !edge.isHidden)
        .toSorted((a, b) => (a.zIndex ?? 0) - (b.zIndex ?? 0))
    };
  }, [
    actionItemType,
    hoveredLaneId,
    hoveredNodeId,
    selectedLaneId,
    selectedNodeId,
    t,
    workflow
  ]);

  useLayoutEffect(() => {
    const workflowCanvas = workflowCanvasRef.current;
    if (!workflowCanvas) {
      return;
    }

    graph.workflowEdges.forEach(edge => {
      const sourceNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__nodes .workflow-canvas__node[data-nodeid="${edge.sourceId}"]`
      );
      const targetNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__nodes .workflow-canvas__node[data-nodeid="${edge.targetId}"]`
      );
      const edgeNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__edges .workflow-canvas__edge[data-edgeid="${edge.id}"]`
      );
      if (!edgeNode) {
        return;
      }

      const svgPathPoints = generateEdgePath(
        sourceNode,
        targetNode,
        workflowCanvas
      );
      if (svgPathPoints) {
        edgeNode.setAttribute("points", svgPathPoints);
      }
    });
  }, [graph.workflowEdges]);

  const handleMouseEnterLane = useCallback(
    (_e, workflowStep) => {
      setHoveredLaneId?.(workflowStep.id);
    },
    [setHoveredLaneId]
  );
  const handleMouseLeaveLane = useCallback(() => {
    setHoveredLaneId?.(undefined);
  }, [setHoveredLaneId]);
  const handleMouseEnterNode = useCallback(
    (_e, node) => {
      setHoveredNodeId?.(node.id);
    },
    [setHoveredNodeId]
  );
  const handleMouseLeaveNode = useCallback(() => {
    setHoveredNodeId?.(undefined);
  }, [setHoveredNodeId]);
  const handleClickLane = useCallback(
    (_e, workflowStep) => {
      if (!canSelect) {
        return;
      }
      setSelectedLaneId?.(workflowStep.id);
      setSelectedNodeId(undefined);
    },
    [canSelect, setSelectedLaneId, setSelectedNodeId]
  );
  const handleClickNode = useCallback(
    (_e, node) => {
      if (!canSelect) {
        return;
      }
      setSelectedNodeId?.(node.id);
      setSelectedLaneId(node.sourceWorkflowId);
    },
    [canSelect, setSelectedNodeId, setSelectedLaneId]
  );

  return (
    <div className="workflow-canvas" ref={workflowCanvasRef}>
      <div className="workflow-canvas__layer__nodes">
        <Inline gap="200">
          {/* NB: disabled for now since order of steps are currently non-deterministic */}
          {showStart ? (
            <React.Fragment>
              <Stack
                gap="100"
                className="workflow-canvas__lane"
                alignment="top-center"
              >
                <StartNode />
              </Stack>
              <WorkflowDivider
                isEditable={CrudFormMode.UPDATE === crudFormMode}
                id={"0"}
              />
            </React.Fragment>
          ) : (
            <></>
          )}
          {graph.workflowSteps.map((step, idx) => {
            return (
              <React.Fragment key={step.id}>
                <Stack
                  gap="100"
                  className={classNames([
                    "workflow-canvas__lane",
                    hoveredLaneId === step.id
                      ? "workflow-canvas__lane--hover"
                      : "",
                    selectedLaneId === step.id
                      ? "workflow-canvas__lane--selected"
                      : ""
                  ])}
                  alignment="top-center"
                  onMouseEnter={(e: React.MouseEvent<HTMLDivElement>) =>
                    handleMouseEnterLane(e, step)
                  }
                  onMouseLeave={() => handleMouseLeaveLane()}
                >
                  <StepNode
                    node={step}
                    onMouseEnterNode={handleMouseEnterNode}
                    onMouseLeaveNode={handleMouseLeaveNode}
                    onClickNode={handleClickLane}
                  />
                  <Stack
                    gap="100"
                    alignment="top-center"
                    className="workflow-canvas__lane_actions"
                  >
                    {step.actions?.map(action => {
                      return (
                        <ActionNode
                          key={action.id}
                          node={action}
                          withRules={action.hasOperations}
                          onMouseEnterNode={handleMouseEnterNode}
                          onMouseLeaveNode={handleMouseLeaveNode}
                          onClickNode={handleClickNode}
                          selectedNodeId={selectedNodeId}
                        />
                      );
                    })}
                  </Stack>
                  {[CrudFormMode.CREATE, CrudFormMode.UPDATE].includes(
                    crudFormMode
                  ) &&
                    canAddAction && <AddActionButton />}
                </Stack>
                <WorkflowDivider
                  isEditable={CrudFormMode.UPDATE === crudFormMode}
                  id={(idx + 1).toString()}
                />
              </React.Fragment>
            );
          })}
        </Inline>
      </div>
      <svg className="workflow-canvas__layer__edges">
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
            markerUnits="userSpaceOnUse"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
        </defs>
        {graph.workflowEdges.map(edge => {
          return (
            <polyline
              key={edge.id}
              className={classNames([
                "workflow-canvas__edge",
                edge.isAnimated ? "animated" : ""
              ])}
              data-edgeid={edge.id}
              strokeLinecap="round"
              markerEnd="url(#arrow)"
            />
          );
        })}
      </svg>
    </div>
  );
};

export default WorkflowCanvas;
