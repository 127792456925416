import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonVariant, ButtonSize } from "@atoms/Button";
import { Icon, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import "./DeleteConfirm.scss";

const DeleteConfirm = props => {
  const { onDelete } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {!showConfirm && (
        <Icon
          name={"delete"}
          designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
          fillStyle={IconFillStyle.OUTLINED}
          onClick={() => {
            setShowConfirm(true);
          }}
          className="delete-confirm-icon"
        />
      )}
      {showConfirm && (
        <Box className="delete-confirm">
          <Stack className="delete-confirm__contents" gap="100" padding="200">
            <Box className="delete-confirm--label">
              {t("ui.documents.confirmDelete.label")}
            </Box>
            <Inline gap="100" alignment="left">
              <Button
                variant={ButtonVariant.TEXT}
                label={t("common:ui.forms.cancel.label")}
                size={ButtonSize.SMALL}
                className="delete-confirm--button"
                onClick={() => {
                  setShowConfirm(false);
                }}
              />
              <Button
                variant={ButtonVariant.DANGER}
                label={t("common:ui.forms.delete.label")}
                size={ButtonSize.SMALL}
                className="delete-confirm--button"
                onClick={onDelete}
              />
            </Inline>
          </Stack>
        </Box>
      )}
    </div>
  );
};

DeleteConfirm.propTypes = {
  onDelete: PropTypes.func
};

export default DeleteConfirm;
