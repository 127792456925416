export const finalPackageConstants = {
  GET_FINAL_PACKAGE_PROCESSES_REQUEST: "GET_FINAL_PACKAGE_PROCESSES_REQUEST",
  GET_FINAL_PACKAGE_PROCESSES_SUCCESS: "GET_FINAL_PACKAGE_PROCESSES_SUCCESS",
  GET_FINAL_PACKAGE_PROCESSES_FAILURE: "GET_FINAL_PACKAGE_PROCESSES_FAILURE",

  CREATE_FINAL_PACKAGE_PROCESSES_REQUEST:
    "CREATE_FINAL_PACKAGE_PROCESSES_REQUEST",
  CREATE_FINAL_PACKAGE_PROCESSES_SUCCESS:
    "CREATE_FINAL_PACKAGE_PROCESSES_SUCCESS",
  CREATE_FINAL_PACKAGE_PROCESSES_FAILURE:
    "CREATE_FINAL_PACKAGE_PROCESSES_FAILURE",

  UPDATE_FINAL_PACKAGE_PROCESSES_REQUEST:
    "UPDATE_FINAL_PACKAGE_PROCESSES_REQUEST",
  UPDATE_FINAL_PACKAGE_PROCESSES_SUCCESS:
    "UPDATE_FINAL_PACKAGE_PROCESSES_SUCCESS",
  UPDATE_FINAL_PACKAGE_PROCESSES_FAILURE:
    "UPDATE_FINAL_PACKAGE_PROCESSES_FAILURE",

  DELETE_FINAL_PACKAGE_PROCESSES_REQUEST:
    "DELETE_FINAL_PACKAGE_PROCESSES_REQUEST",
  DELETE_FINAL_PACKAGE_PROCESSES_SUCCESS:
    "DELETE_FINAL_PACKAGE_PROCESSES_SUCCESS",
  DELETE_FINAL_PACKAGE_PROCESSES_FAILURE:
    "DELETE_FINAL_PACKAGE_PROCESSES_FAILURE",

  RESET_GET_FINAL_PACKAGE_PROCESSES: "RESET_GET_FINAL_PACKAGE_PROCESSES",
  RESET_CREATE_FINAL_PACKAGE_PROCESSES: "RESET_CREATE_FINAL_PACKAGE_PROCESSES",

  GET_FINAL_PACKAGE_TIPS_REQUEST: "GET_FINAL_PACKAGE_TIPS_REQUEST",
  GET_FINAL_PACKAGE_TIPS_SUCCESS: "GET_FINAL_PACKAGE_TIPS_SUCCESS",
  GET_FINAL_PACKAGE_TIPS_FAILURE: "GET_FINAL_PACKAGE_TIPS_FAILURE",

  UPDATE_FINAL_PACKAGE_TIP_REQUEST: "UPDATE_FINAL_PACKAGE_TIP_REQUEST",
  UPDATE_FINAL_PACKAGE_TIP_SUCCESS: "UPDATE_FINAL_PACKAGE_TIP_SUCCESS",
  UPDATE_FINAL_PACKAGE_TIP_FAILURE: "UPDATE_FINAL_PACKAGE_TIP_FAILURE",
  RESET_UPDATE_FINAL_PACKAGE_TIP: "RESET_UPDATE_FINAL_PACKAGE_TIP",

  DELETE_FINAL_PACKAGE_TIP_REQUEST: "DELETE_FINAL_PACKAGE_TIP_REQUEST",
  DELETE_FINAL_PACKAGE_TIP_SUCCESS: "DELETE_FINAL_PACKAGE_TIP_SUCCESS",
  DELETE_FINAL_PACKAGE_TIP_FAILURE: "DELETE_FINAL_PACKAGE_TIP_FAILURE",
  RESET_DELETE_FINAL_PACKAGE_TIP: "RESET_DELETE_FINAL_PACKAGE_TIP",

  GET_FINAL_PACKAGE_DOCUMENTS_REQUEST: "GET_FINAL_PACKAGE_DOCUMENTS_REQUEST",
  GET_FINAL_PACKAGE_DOCUMENTS_SUCCESS: "GET_FINAL_PACKAGE_DOCUMENTS_SUCCESS",
  GET_FINAL_PACKAGE_DOCUMENTS_FAILURE: "GET_FINAL_PACKAGE_DOCUMENTS_FAILURE",

  GET_FINALPACKAGE_SHARESTATUS_REQUEST: "GET_FINALPACKAGE_SHARESTATUS_REQUEST",
  GET_FINALPACKAGE_SHARESTATUS_SUCCESS: "GET_FINALPACKAGE_SHARESTATUS_SUCCESS",
  GET_FINALPACKAGE_SHARESTATUS_FAILURE: "GET_FINALPACKAGE_SHARESTATUS_FAILURE",

  SET_FINALPACKAGE_SHARESTATUS_REQUEST: "SET_FINALPACKAGE_SHARESTATUS_REQUEST",
  SET_FINALPACKAGE_SHARESTATUS_SUCCESS: "SET_FINALPACKAGE_SHARESTATUS_SUCCESS",
  SET_FINALPACKAGE_SHARESTATUS_FAILURE: "SET_FINALPACKAGE_SHARESTATUS_FAILURE",

  UPLOAD_FINAL_PACKAGE_DOCUMENT_REQUEST:
    "UPLOAD_FINAL_PACKAGE_DOCUMENT_REQUEST",
  UPLOAD_FINAL_PACKAGE_DOCUMENT_SUCCESS:
    "UPLOAD_FINAL_PACKAGE_DOCUMENT_SUCCESS",
  UPLOAD_FINAL_PACKAGE_DOCUMENT_FAILURE:
    "UPLOAD_FINAL_PACKAGE_DOCUMENT_FAILURE",

  RESET_GET_FINAL_PACKAGE_DOCUMENTS: "RESET_GET_FINAL_PACKAGE_DOCUMENTS",
  RESET: "RESET_FINAL_PACKAGE"
};
