import React, { useCallback } from "react";

import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";

import { authActions } from "../../../actions";
import googleLogo from "../../../assets/images/google-icon.svg";
import { systemConstants } from "../../../constants";
import "./googleAuth.scss";

const GoogleAuth = () => {
  const dispatch = useDispatch();
  const onSuccess = response => {
    dispatch(
      authActions.loginWithExternalAuth({
        authObject: {
          externalAuthProvider:
            systemConstants.externalAuthProviders.google.key,
          externalAuthObject: response
        }
      })
    );
  };
  const onFailure = () => {};

  useGoogleOneTapLogin({
    onSuccess,
    onError: onFailure
  });

  const googleLogin = useGoogleLogin({
    onSuccess,
    onError: onFailure
  });
  const clickHandler = useCallback(() => googleLogin(), [googleLogin]);

  return (
    <div className="google-auth__container">
      <div onClick={clickHandler} className="google-auth__container--button">
        <img
          className="google-auth__container--image"
          src={googleLogo}
          alt="Google"
        />
        <span className="google-auth__container--text">
          Continue with Google
        </span>
      </div>
    </div>
  );
};

export default GoogleAuth;
