import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { useFeatures } from "@shared/hooks/useFeatures.jsx";
import { useHostUserProfile } from "@shared/hooks/useHostUserProfile";
import { useToasts } from "@shared/hooks/useToasts";
import { useGetUserByIdQuery, useUpdateUserMutation } from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";

import UserForm from "@components/organisms/UserForm/UserForm";

const UpdateHostUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data: user, isError } = useGetUserByIdQuery(userId);

  const [
    updateHostUser,
    {
      data: updatedUserData,
      isSuccess: isHostUserUpdated,
      isLoading,
      error: updateError
    }
  ] = useUpdateUserMutation();

  const { showExternalUserDeactivatedToast } = useToasts();

  const {
    roles,
    teamUserFields,
    projectsList,
    error: roleError
  } = useHostUserProfile(userId);

  const error = useMemo(
    () => updateError || roleError,
    [updateError, roleError]
  );

  useEffect(() => {
    if (!userId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [isError, navigate, userId]);

  useEffect(() => {
    if (isHostUserUpdated) {
      if (updatedUserData.toast) {
        const message = t(updatedUserData.toast, {
          firstName: user.firstname,
          lastName: user.lastname
        });
        showExternalUserDeactivatedToast(message);
      } else {
        navigate(routeConstants.manageUsers);
      }
    }
  }, [isHostUserUpdated, navigate]);

  const updateUser = useCallback(
    userProfile => {
      updateHostUser({ user: { ...userProfile, id: userId } });
    },
    [updateHostUser, userId]
  );

  const { isExternalUsersEnabled } = useFeatures();

  const addConfigKey = key =>
    teamUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        path: "role.name",
        type: "dropdown",
        required: true,
        items: roles?.map(role => ({
          ...role,
          name: t(role.name),
          value: role.name
        })),
        translate: true
      };
    }
    return {};
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: projectsList?.filter(p => p.added)
    },
    ...(isExternalUsersEnabled ? [addConfigKey("EXTERNAL_USERNAME")] : []),
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];

  return (
    <UserForm
      title={t("common:ui.manageUsers.updateTitle_team")}
      breadcrumbs={[
        {
          linkTo: routeConstants.manageUsers,
          label: t("admin:ui.navigation.manageUsers.label", {
            context: "HOST"
          })
        }
      ]}
      user={user}
      isExternalUsersEnabled={isExternalUsersEnabled}
      isHostUserUpdated={isHostUserUpdated}
      formConfig={formConfig}
      handleSubmit={updateUser}
      submitLabel={t("common:ui.manageUsers.updateSubmit")}
      loading={isLoading}
      other={{
        error: error ? getErrorMessage(error, t) : ""
      }}
    />
  );
};

export default UpdateHostUser;
