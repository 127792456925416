import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useGetNewsItemsQuery, useLocaleDate, useNews } from "@shared/hooks";
import { useNewsMastheadImage } from "@shared/hooks/useNewsMastheadImage";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { getErrorMessage } from "@app/helpers/error";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";

import Loading from "@components/molecules/Loading/Loading";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import "./News.scss";
import NewsMastheadImage from "./NewsMastheadImage";

function onClick(link) {
  return () => {
    link && window.open(link, "_blank");
  };
}
const baseURL = systemConstants.serverURL;

const News = ({
  engagementTypeId,
  newsConfiguration,
  engagementTypeContextKey
}) => {
  //handles pubsub subscriptions/logic as well
  const { canBeDisplayed } = useNews();
  const {
    data: newsItems,
    isLoading,
    error
  } = useGetNewsItemsQuery(engagementTypeId, {
    skip: !engagementTypeId
  });
  const { t } = useTranslation();
  const { fetchNewsMasthead, newsMasthead } =
    useNewsMastheadImage(engagementTypeId);
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const displayNewsItems = useMemo(
    () => newsItems?.filter(canBeDisplayed) ?? [],
    [canBeDisplayed, newsItems]
  );

  const masthead = useMemo(() => {
    if (!newsMasthead || !newsMasthead?.assetName) {
      return null;
    }
    return {
      imageSrc: `${baseURL}/api/hostBrands/assets/${window.location.host}/${
        newsMasthead?.assetName
      }?v=${newsMasthead?.lastUpdated ?? 0}`,
      targetUrl: newsMasthead?.targetUrl,
      imageAlt: newsMasthead?.imageAlt
    };
  }, [newsMasthead]);

  useEffect(() => {
    fetchNewsMasthead();
  }, [fetchNewsMasthead]);

  const configAndValueCheck = useCallback(
    (key, fields) => {
      return fields[key] && newsConfiguration?.fields.some(f => f.key === key);
    },
    [newsConfiguration?.fields]
  );

  const article = useCallback(
    ({ fields }, id) => {
      return (
        <ContainerWithTooltip
          hideTooltip={!configAndValueCheck("mouseoverText", fields)}
          tooltipContent={fields.mouseoverText}
          key={id}
          className="news-article"
          onClick={onClick(fields.url)}
        >
          <div className="news-article__content">
            {configAndValueCheck("title", fields) && (
              <div className="news-article__title">{fields.title}</div>
            )}
            {configAndValueCheck("teaser", fields) && (
              <div className="news-article__teaser">{fields.teaser}</div>
            )}
            {configAndValueCheck("publicationDate", fields) && (
              <div className="news-article__date">
                {dateFormatter(fields.publicationDate, locale, shortFormat)}
              </div>
            )}
          </div>
        </ContainerWithTooltip>
      );
    },
    [configAndValueCheck, locale, shortFormat]
  );

  const newsBody = () => {
    return (
      <DashboardBoxTemplate
        title={t("common:ui.projects.news.title", {
          context: engagementTypeContextKey
        })}
        boxClassName="news"
      >
        <div className="news-container">
          {masthead?.imageSrc && (
            <NewsMastheadImage
              imageSrc={masthead.imageSrc}
              url={masthead.targetUrl}
              imageAlt={masthead.imageAlt}
            ></NewsMastheadImage>
          )}

          <div className="news-article-listing">
            {displayNewsItems.map(article)}
          </div>
        </div>
      </DashboardBoxTemplate>
    );
  };

  const renderNewsPage = () => {
    if (isLoading) {
      return <Loading message={t("common:ui.projectSummary.loadingNews")} />;
    } else if (error) {
      return <ErrorBox message={getErrorMessage(error, t)} />;
    } else {
      return newsBody();
    }
  };

  return renderNewsPage();
};

export default News;
