import { manageDocumentDownloadsConstants } from "../constants";
import { manageDocumentDownloadsService } from "../services";

export const manageDocumentDownloadsActions = {
  getProjectDocument,
  getClientDocument,
  reset,
  downloadDocumentRevision,
  downloadClientDocumentRevision,
  downloadCurrentDocument,
  resetError
};

function downloadDocumentRevision({
  id,
  name,
  documentRevisionId,
  forceLatest,
  downloadName,
  cb
}) {
  return dispatch => {
    dispatch(request());

    manageDocumentDownloadsService
      .downloadDocumentRevision({
        id,
        name,
        documentRevisionId,
        forceLatest,
        downloadName
      })
      .then(
        document => {
          dispatch(success(document));
          cb?.(document);
        },
        error => {
          dispatch(failure(error, document, id));
        }
      );
  };

  function request() {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST,
      errorDocumentId: -1
    };
  }
  function success(document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
      document,
      errorDocumentId: -1
    };
  }
  function failure(error, document, id) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE,
      error,
      document,
      errorDocumentId: id
    };
  }
}

function downloadClientDocumentRevision(
  { id, clientId, name, documentRevisionId },
  cb
) {
  return dispatch => {
    dispatch(request());

    manageDocumentDownloadsService
      .downloadClientDocumentRevision({
        id,
        clientId,
        name,
        documentRevisionId
      })
      .then(
        document => {
          dispatch(success(document));
          cb?.(document);
        },
        error => {
          dispatch(failure(error, document, id));
        }
      );
  };

  function request() {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST,
      errorDocumentId: -1
    };
  }
  function success(document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
      document,
      errorDocumentId: -1
    };
  }
  function failure(error, document, id) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE,
      error,
      document,
      errorDocumentId: id
    };
  }
}

function downloadCurrentDocument({ id, name }) {
  return dispatch => {
    dispatch(request());

    manageDocumentDownloadsService.downloadCurrentDocument({ id, name }).then(
      document => {
        dispatch(success(document));
      },
      error => {
        dispatch(failure(error, document));
      }
    );
  };

  function request() {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error, document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE,
      error,
      document
    };
  }
}

function getProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageDocumentDownloadsService.getProjectDocument(document).then(
      document => {
        dispatch(success(document));
      },
      error => {
        dispatch(failure(error, document));
      }
    );
  };

  function request() {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error, document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE,
      error,
      document
    };
  }
}

function getClientDocument(document) {
  return dispatch => {
    dispatch(request());

    manageDocumentDownloadsService.getClientDocument(document).then(
      document => {
        dispatch(success(document));
      },
      error => {
        dispatch(failure(error, document));
      }
    );
  };

  function request() {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error, document) {
    return {
      type: manageDocumentDownloadsConstants.DOWNLOAD_DOCUMENT_FAILURE,
      error,
      document
    };
  }
}

function reset() {
  return { type: manageDocumentDownloadsConstants.RESET };
}

function resetError() {
  return { type: manageDocumentDownloadsConstants.RESET_ERROR };
}
