import React from "react";

import PropTypes from "prop-types";

import "./InlineAlert.scss";

const ALERT_TYPES = {
  info: { key: "info", icon: "info" },
  success: { key: "success", icon: "check_circle" },
  warning: { key: "warning", icon: "warning" },
  error: { key: "error", icon: "error" }
};

const InlineAlert = props => {
  const iconForType = type =>
    ALERT_TYPES[type] ? ALERT_TYPES[type].icon : ALERT_TYPES.error.icon;

  return (
    <div className={`ot-inline-alert ot-inline-alert--${props.type}`}>
      <span className="ot-inline-alert__icon tool-tip-label">
        <i className="material-icons">{iconForType(props.type)}</i>
      </span>
      <span className="ot-inline-alert__message">{props.message}</span>
    </div>
  );
};

InlineAlert.defaultProps = {
  type: ALERT_TYPES.error.key
};

InlineAlert.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  type: PropTypes.oneOf([
    ALERT_TYPES.info.key,
    ALERT_TYPES.success.key,
    ALERT_TYPES.warning.key,
    ALERT_TYPES.error.key
  ])
};

export default InlineAlert;
