import React, { useCallback, useMemo } from "react";

import partition from "lodash/partition";
import PropTypes from "prop-types";

import "./Nav.scss";
import NavItem from "./NavItem";

const Nav = props => {
  const {
    navItems,
    subNavLeftPosition,
    setSubNavActive,
    handleNavigate,
    currentPath,
    navigationStyle,
    handleSelectFilter
  } = props;
  const [leftNavItems, rightNavItems] = useMemo(() => {
    return partition(navItems, a => !a.position || a.position !== "right");
  }, [navItems]);

  const navItem = useCallback(
    item => {
      return (
        <NavItem
          key={item.name}
          {...item}
          subNavLeftPosition={subNavLeftPosition}
          setSubNavActive={setSubNavActive}
          handleNavigate={handleNavigate}
          currentPath={currentPath}
          navigationStyle={navigationStyle}
          handleSelectFilter={handleSelectFilter}
        />
      );
    },
    [
      currentPath,
      handleNavigate,
      handleSelectFilter,
      navigationStyle,
      setSubNavActive,
      subNavLeftPosition
    ]
  );

  if (!navItems || navItems.length === 0) {
    return <></>;
  }

  return (
    <div className="nav">
      <div className="nav__left">{leftNavItems.map(navItem)}</div>
      <div className="nav__right">{rightNavItems.map(navItem)}</div>
    </div>
  );
};

Nav.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      isNeverActive: PropTypes.bool,
      link: PropTypes.string,
      state: PropTypes.object
    })
  ),
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  handleNavigate: PropTypes.func,
  currentPath: PropTypes.string,
  groupLabel: PropTypes.string,
  navigationStyle: PropTypes.string
};

export default Nav;
