import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";
import WarningMessage from "@shared/components/warningMessage/WarningMessage";
import { useUpdateRiskMutation } from "@shared/hooks";

import "./riskImpactLikelihood.scss";

const status = {
  vh: "VERY-HIGH",
  h: "HIGH",
  m: "MEDIUM",
  l: "LOW",
  vl: "VERY-LOW"
};

const RiskImpactLikelihood = props => {
  const { t } = useTranslation();
  const [updateRisk] = useUpdateRiskMutation();
  const [risk, setRisk] = useState(props.risk);
  const [statusUpdateWarningVisibility, setStatusUpdateWarningVisibility] =
    useState(false);
  const [updatingRisk, setUpdatingRisk] = useState(null);

  useEffect(() => {
    setRisk(props.risk);
  }, [props.risk]);

  const getIcon = (impactValue, likelihoodValue) => {
    return risk.impact === impactValue &&
      risk.likelihood === likelihoodValue ? (
      <i className={"material-icons risk-impact-icon"}>done</i>
    ) : (
      ""
    );
  };

  const handleImpactLikelihoodUpdate = () => {
    setRisk(updatingRisk);
    updateRisk({ risk: updatingRisk });
    setUpdatingRisk(null);
    setStatusUpdateWarningVisibility(false);
  };

  const handleImpactLikelihoodCancel = () => {
    setUpdatingRisk(null);
    setStatusUpdateWarningVisibility(false);
  };

  const handleClick = (impactValue, likelihoodValue) => {
    if (impactValue !== risk.impact || likelihoodValue !== risk.likelihood) {
      const updatedRisk = {
        ...risk,
        impact: impactValue,
        likelihood: likelihoodValue
      };
      setRisk(updatedRisk);
      updateRisk({ risk: updatedRisk });
    }
  };

  return (
    <>
      <div className="risk-impact-likelihood__container">
        <div className="risk-impact-likelihood__container--impact">
          {t("risks:risks.fields.impact.label")}
        </div>
        <div className="risk-impact-likelihood__container--impact-very-high">
          {t("risks:risks.fields.status.VH.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--impact-high">
          {t("risks:risks.fields.status.H.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--impact-medium">
          {t("risks:risks.fields.status.M.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--impact-low">
          {t("risks:risks.fields.status.L.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--impact-very-low">
          {t("risks:risks.fields.status.VL.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood">
          {t("risks:risks.fields.likelihood.label")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood-very-high">
          {t("risks:risks.fields.status.VH.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood-high">
          {t("risks:risks.fields.status.H.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood-medium">
          {t("risks:risks.fields.status.M.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood-low">
          {t("risks:risks.fields.status.L.shortHandLabel")}
        </div>
        <div className="risk-impact-likelihood__container--likelihood-very-low">
          {t("risks:risks.fields.status.VL.shortHandLabel")}
        </div>

        <div
          onClick={() => handleClick(status.vh, status.vh)}
          className="risk-impact risk-impact-border-top risk-impact-border-right risk-impact-likelihood__container--impact-likelihood-very-high-very-high risk-impact-likelihood-color-red"
        >
          {getIcon(status.vh, status.vh)}
        </div>
        <div
          onClick={() => handleClick(status.vh, status.h)}
          className="risk-impact risk-impact-border-right risk-impact-likelihood__container--impact-likelihood-very-high-high risk-impact-likelihood-color-red"
        >
          {getIcon(status.vh, status.h)}
        </div>
        <div
          onClick={() => handleClick(status.vh, status.m)}
          className="risk-impact risk-impact-border-right risk-impact-likelihood__container--impact-likelihood-very-high-medium risk-impact-likelihood-color-red"
        >
          {getIcon(status.vh, status.m)}
        </div>
        <div
          onClick={() => handleClick(status.vh, status.l)}
          className="risk-impact risk-impact-border-right risk-impact-likelihood__container--impact-likelihood-very-high-low risk-impact-likelihood-color-red"
        >
          {getIcon(status.vh, status.l)}
        </div>
        <div
          onClick={() => handleClick(status.vh, status.vl)}
          className="risk-impact risk-impact-border-right risk-impact-likelihood__container--impact-likelihood-very-high-very-low risk-impact-likelihood-color-red"
        >
          {getIcon(status.vh, status.vl)}
        </div>

        <div
          onClick={() => handleClick(status.h, status.vh)}
          className="risk-impact risk-impact-border-top risk-impact-likelihood__container--impact-likelihood-high-very-high risk-impact-likelihood-color-red"
        >
          {getIcon(status.h, status.vh)}
        </div>
        <div
          onClick={() => handleClick(status.h, status.h)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-high-high risk-impact-likelihood-color-red"
        >
          {getIcon(status.h, status.h)}
        </div>
        <div
          onClick={() => handleClick(status.h, status.m)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-high-medium risk-impact-likelihood-color-amber"
        >
          {getIcon(status.h, status.m)}
        </div>
        <div
          onClick={() => handleClick(status.h, status.l)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-high-low risk-impact-likelihood-color-amber"
        >
          {getIcon(status.h, status.l)}
        </div>
        <div
          onClick={() => handleClick(status.h, status.vl)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-high-very-low risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.h, status.vl)}
        </div>

        <div
          onClick={() => handleClick(status.m, status.vh)}
          className="risk-impact risk-impact-border-top risk-impact-likelihood__container--impact-likelihood-medium-very-high risk-impact-likelihood-color-amber"
        >
          {getIcon(status.m, status.vh)}
        </div>
        <div
          onClick={() => handleClick(status.m, status.h)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-medium-high risk-impact-likelihood-color-amber"
        >
          {getIcon(status.m, status.h)}
        </div>
        <div
          onClick={() => handleClick(status.m, status.m)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-medium-medium risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.m, status.m)}
        </div>
        <div
          onClick={() => handleClick(status.m, status.l)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-medium-low risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.m, status.l)}
        </div>
        <div
          onClick={() => handleClick(status.m, status.vl)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-medium-very-low risk-impact-likelihood-color-green"
        >
          {getIcon(status.m, status.vl)}
        </div>

        <div
          onClick={() => handleClick(status.l, status.vh)}
          className="risk-impact risk-impact-border-top risk-impact-likelihood__container--impact-likelihood-low-very-high risk-impact-likelihood-color-amber"
        >
          {getIcon(status.l, status.vh)}
        </div>
        <div
          onClick={() => handleClick(status.l, status.h)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-low-high risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.l, status.h)}
        </div>
        <div
          onClick={() => handleClick(status.l, status.m)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-low-medium risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.l, status.m)}
        </div>
        <div
          onClick={() => handleClick(status.l, status.l)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-low-low risk-impact-likelihood-color-green"
        >
          {getIcon(status.l, status.l)}
        </div>
        <div
          onClick={() => handleClick(status.l, status.vl)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-low-very-low risk-impact-likelihood-color-green"
        >
          {getIcon(status.l, status.vl)}
        </div>

        <div
          onClick={() => handleClick(status.vl, status.vh)}
          className="risk-impact risk-impact-border-top risk-impact-likelihood__container--impact-likelihood-very-low-very-high risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.vl, status.vh)}
        </div>
        <div
          onClick={() => handleClick(status.vl, status.h)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-very-low-high risk-impact-likelihood-color-yellow"
        >
          {getIcon(status.vl, status.h)}
        </div>
        <div
          onClick={() => handleClick(status.vl, status.m)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-very-low-medium risk-impact-likelihood-color-green"
        >
          {getIcon(status.vl, status.m)}
        </div>
        <div
          onClick={() => handleClick(status.vl, status.l)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-very-low-low risk-impact-likelihood-color-green"
        >
          {getIcon(status.vl, status.l)}
        </div>
        <div
          onClick={() => handleClick(status.vl, status.vl)}
          className="risk-impact risk-impact-likelihood__container--impact-likelihood-very-low-very-low risk-impact-likelihood-color-green"
        >
          {getIcon(status.vl, status.vl)}
        </div>
      </div>

      <Popup
        visibility={statusUpdateWarningVisibility}
        handleOutsideClick={false}
        width="fit-content"
      >
        <WarningMessage
          title={t("risks:ui.action.updateRisk.label")}
          message={t("risks:risks.ui.messages.confirmationMessage")}
          onYes={handleImpactLikelihoodUpdate}
          onCancel={handleImpactLikelihoodCancel}
        />
      </Popup>
    </>
  );
};

export default RiskImpactLikelihood;
