import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import { expectedActionIndicators } from "@app/helpers";

import {
  Pill,
  PillFillStyle,
  PillFontWeight,
  PillSize,
  PillVariant
} from "@atoms/Pill";

import BoxTemplate from "@components/templates/BoxTemplate";

import "./ActionItemsFilterLegend.scss";

export const actionItemsFilters = {
  STATUS: "status",
  DUE_DATE: "dueDate",
  MY_ACTIONS: "myActions",
  CLIENT_TEAM_ACTIONS: "clientTeamActions",
  HOST_TEAM_ACTIONS: "hostTeamActions"
};

const ActionItemsFilterLegend = props => {
  const { t } = useTranslation();
  const {
    handlers,
    enabled,
    labels,
    pillsConfig,
    indicatorUiConfig,
    filterConfig,
    status
  } = props;

  const actionIndicators = indicatorUiConfig || expectedActionIndicators();
  const statusIndicators = filterConfig?.status.filters.map(
    f => actionIndicators[f]
  );
  const dueDateIndicators = filterConfig?.dueDate.filters.map(
    f => actionIndicators[f]
  );

  const statusPillsProps = {
    shape: pillsConfig?.actionItemStatus?.shape,
    activeStyle: pillsConfig?.actionItemStatus?.activeStyle
  };

  const onClickIndicatorHandler = (event, indicator) => {
    event.stopPropagation();
    handlers[actionItemsFilters.STATUS](indicator);
  };

  const onClickDueDateHandler = (event, indicator) => {
    event.stopPropagation();
    handlers[actionItemsFilters.DUE_DATE](indicator);
  };

  const getFilterButtonColor = enabled => {
    return enabled ? PillVariant.ACCENT : PillVariant.TRANSPARENT_DARK;
  };

  const getHostTeamFilterButton = () => (
    <span
      className={"filter-legend__indicator"}
      onClick={handlers[actionItemsFilters.HOST_TEAM_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.HOST_TEAM_ACTIONS]}
        size={PillSize.FILL}
        isFilter
        variant={getFilterButtonColor(
          enabled[actionItemsFilters.HOST_TEAM_ACTIONS]
        )}
        fillStyle={
          enabled[actionItemsFilters.HOST_TEAM_ACTIONS]
            ? PillFillStyle.FILLED
            : PillFillStyle.OUTLINE
        }
        fontWeight={
          enabled[actionItemsFilters.HOST_TEAM_ACTIONS]
            ? PillFontWeight.SEMI_BOLD
            : undefined
        }
      />
    </span>
  );

  const getMyActionsFilterButton = () => (
    <span
      className={"filter-legend__indicator"}
      onClick={handlers[actionItemsFilters.MY_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.MY_ACTIONS]}
        size={PillSize.FILL}
        variant={getFilterButtonColor(enabled[actionItemsFilters.MY_ACTIONS])}
        isFilter
        fillStyle={
          enabled[actionItemsFilters.MY_ACTIONS]
            ? PillFillStyle.FILLED
            : PillFillStyle.OUTLINE
        }
        fontWeight={
          enabled[actionItemsFilters.MY_ACTIONS]
            ? PillFontWeight.SEMI_BOLD
            : undefined
        }
      />
    </span>
  );

  const getClientTeamFilterButton = () => (
    <span
      className={"filter-legend__indicator"}
      onClick={handlers[actionItemsFilters.CLIENT_TEAM_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.CLIENT_TEAM_ACTIONS]}
        size={PillSize.FILL}
        variant={getFilterButtonColor(
          enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]
        )}
        isFilter
        fillStyle={
          enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]
            ? PillFillStyle.FILLED
            : PillFillStyle.OUTLINE
        }
        fontWeight={
          enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]
            ? PillFontWeight.SEMI_BOLD
            : undefined
        }
      />
    </span>
  );

  const getColorScheme = (indicator, selectedStatus) => {
    return indicator.key == selectedStatus &&
      statusPillsProps.activeStyle === systemConstants.pill.type.filled
      ? indicator.key
      : PillVariant.TRANSPARENT_DARK;
  };

  return (
    <BoxTemplate>
      <div className="filter-legend">
        {(labels[actionItemsFilters.MY_ACTIONS] ||
          labels[actionItemsFilters.CLIENT_TEAM_ACTIONS] ||
          labels[actionItemsFilters.HOST_TEAM_ACTIONS]) && (
          <div className="filter-legend__filter">
            <div className="filter-legend__title">
              {t("requests:requests.ui.requestFilterLegend.filterTitle")}
            </div>
            <div className="filter-legend__indicators">
              {labels[actionItemsFilters.MY_ACTIONS] &&
                getMyActionsFilterButton()}
              {labels[actionItemsFilters.CLIENT_TEAM_ACTIONS] &&
                getClientTeamFilterButton()}
              {labels[actionItemsFilters.HOST_TEAM_ACTIONS] &&
                getHostTeamFilterButton()}
            </div>
          </div>
        )}
        {filterConfig.status?.enabled && (
          <div className="filter-legend__legend">
            <div className="filter-legend__title">
              {" "}
              {t("requests:requests.ui.requestFilterLegend.statusTitle")}
            </div>
            <div className="filter-legend__indicators">
              {statusIndicators.map(indicator => (
                <div
                  className="filter-legend__indicator"
                  key={indicator.key}
                  onClick={event => onClickIndicatorHandler(event, indicator)}
                >
                  <Pill
                    label={t("requests:requests.indicators.label", {
                      context: indicator?.key
                    })}
                    size={PillSize.FILL}
                    variant={getColorScheme(indicator, status.status)}
                    isFilter
                    fillStyle={
                      indicator.key == status.status
                        ? PillFillStyle.FILLED
                        : PillFillStyle.OUTLINE
                    }
                    status={status.status}
                    fontWeight={
                      indicator.key == status.status
                        ? PillFontWeight.SEMI_BOLD
                        : undefined
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {filterConfig.dueDate?.enabled && (
          <div className="filter-legend__legend">
            <div className="filter-legend__title">Due Date</div>
            <div className="filter-legend__indicators">
              {dueDateIndicators.map(indicator => (
                <div
                  className="filter-legend__indicator"
                  key={indicator.key}
                  onClick={event => onClickDueDateHandler(event, indicator)}
                >
                  <Pill
                    label={indicator.label}
                    size={PillSize.FILL}
                    variant={getColorScheme(indicator, status.dueDate)}
                    isFilter
                    fillStyle={
                      indicator.key == status.dueDate
                        ? PillFillStyle.FILLED
                        : PillFillStyle.OUTLINE
                    }
                    status={indicator.key}
                    fontWeight={
                      indicator.key == status.dueDate
                        ? PillFontWeight.SEMI_BOLD
                        : undefined
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </BoxTemplate>
  );
};

ActionItemsFilterLegend.propTypes = {
  handlers: PropTypes.shape({
    status: PropTypes.func,
    dueDate: PropTypes.func,
    myActions: PropTypes.func,
    clientTeamActions: PropTypes.func,
    hostTeamActions: PropTypes.func
  }).isRequired,
  enabled: PropTypes.shape({
    myActions: PropTypes.bool.isRequired,
    clientTeamActions: PropTypes.bool.isRequired,
    hostTeamActions: PropTypes.bool.isRequired
  }).isRequired,
  labels: PropTypes.shape({
    myActions: PropTypes.string,
    clientTeamActions: PropTypes.string,
    hostTeamActions: PropTypes.string
  }).isRequired,
  status: PropTypes.shape({
    dueDate: PropTypes.string,
    status: PropTypes.string
  }).isRequired
};

export default ActionItemsFilterLegend;
