import React, { useEffect, useMemo } from "react";

import _ from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  FilterConfig,
  convertFormValuesToFilterState,
  resolveDefaultValue,
  resolveFilterOptions
} from "@shared/helpers/actionItemFilterHelper.ts";

import { Inline, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import "./PageFilter.scss";
import PageFilterForm from "./PageFilterForm.tsx";
import PageFilterFormSecondary from "./PageFilterFormSecondary.tsx";

const FILTER_SELECT_TYPE = systemConstants.requestPageFilter.selectType;
const FILTER_SLOT = systemConstants.requestPageFilter.slot;

interface PageFilterProps {
  dataViewSwitcher: React.ReactNode;
  filtersFromServerConfig: FilterConfig[];
  isLoadingFilters: boolean;
  dashboardFiltersState: Record<string, string | string[]>;
  onResetFilter: () => void;
  isLoading: boolean;
  handleChange: (data: unknown) => void;
  data: unknown[];
  customFormatters: Record<string, (label: string) => string>;
}

const PageFilter = (props: PageFilterProps) => {
  const {
    filtersFromServerConfig = [],
    isLoadingFilters,
    isLoading,
    handleChange,
    dataViewSwitcher,
    data,
    customFormatters: formatters,
    dashboardFiltersState,
    onResetFilter
  } = props;

  const formMethods = useForm();
  const watchFormValues = formMethods.watch();

  const { t } = useTranslation();

  const filtersFromConfig = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const filterData = structuredClone(filtersFromServerConfig);

    const filterWithOptions = filterData.map(filterConfig =>
      resolveFilterOptions({
        filterConfig,
        dataSource: data,
        formatters,
        t
      })
    );

    const result = filterWithOptions.map(filter => {
      const maybePresetValue = dashboardFiltersState?.[filter.key];

      const resolvedSelectedItem = resolveDefaultValue({
        filter,
        defaultValue: maybePresetValue,
        t
      });
      return {
        ...filter,
        ...(resolvedSelectedItem ? { selectedItem: resolvedSelectedItem } : {})
      };
    });

    return result;
  }, [dashboardFiltersState, data, filtersFromServerConfig, formatters, t]);

  const firstLoad = React.useRef(true);

  useEffect(() => {
    // this block is to handle hydration from a stored session. It only need to be triggered once per mount
    if (
      !firstLoad.current ||
      !filtersFromConfig ||
      _.isEmpty(filtersFromConfig)
    ) {
      return;
    }
    // if all the preselected values don't match our dropdown we cannot preset the form
    const allPreselectedValuesExist = filtersFromConfig.every(filterConfig => {
      const preselectedValue = dashboardFiltersState?.[filterConfig.key];
      const defaultValue = filterConfig.selectedItem;
      return !preselectedValue || (preselectedValue && defaultValue);
    });
    if (!allPreselectedValuesExist) {
      return;
    }

    firstLoad.current = false;

    for (const filterConfig of filtersFromConfig) {
      const defaultValue = filterConfig.selectedItem;
      if (filterConfig.type === FILTER_SELECT_TYPE.singleChoice) {
        formMethods.setValue(filterConfig.key, defaultValue);
      }

      if (
        filterConfig.type === FILTER_SELECT_TYPE.multiChoice &&
        (!defaultValue || defaultValue?.length)
      ) {
        formMethods.setValue(filterConfig.key, defaultValue);
      }
    }
  }, [dashboardFiltersState, filtersFromConfig, formMethods]);

  const mainFilters = useMemo(() => {
    return (
      filtersFromConfig
        ?.filter(f => f.slot === FILTER_SLOT.main)
        ?.filter(f => f.options.length) ?? []
    );
  }, [filtersFromConfig]);

  const secondaryFilters = useMemo(() => {
    return (
      filtersFromConfig?.filter(f => f.slot === FILTER_SLOT.secondary) ?? []
    );
  }, [filtersFromConfig]);

  useEffect(() => {
    if (
      isLoading ||
      isLoadingFilters ||
      !data ||
      _.isEmpty(watchFormValues) ||
      firstLoad.current
    ) {
      return;
    }

    const filterState = convertFormValuesToFilterState(watchFormValues);

    if (_.isEqual(filterState, dashboardFiltersState)) {
      return;
    }

    handleChange(filterState);
  }, [
    data,
    watchFormValues,
    handleChange,
    dashboardFiltersState,
    isLoading,
    isLoadingFilters
  ]);

  const onClickResetFilters = e => {
    e.preventDefault();
    e.stopPropagation();

    // NB: must specify all fields
    const filterKeys = filtersFromConfig?.reduce((acc, filter) => {
      if (filter.type === FILTER_SELECT_TYPE.singleChoice) {
        acc[filter.key] = null;
      } else if (filter.type === FILTER_SELECT_TYPE.multiChoice) {
        acc[filter.key] = [];
      }
      return acc;
    }, {});
    formMethods.reset(filterKeys);

    onResetFilter();
  };

  return (
    <Stack width="fill" gap="075">
      <Inline
        style={{ justifyContent: "space-between" }}
        gap="100"
        alignment="center"
      >
        {dataViewSwitcher}
        <Inline alignment="right">
          <Button
            label={t("common:pageFilter.resetFilters")}
            iconSide={"left"}
            iconName={"refresh"}
            variant={ButtonVariant.TEXT}
            onClick={onClickResetFilters}
          ></Button>
        </Inline>
      </Inline>
      <Inline
        style={{
          flexWrap: "wrap",
          gap: "var(--spacing-050) var(--spacing-250)"
        }}
      >
        {/* Main slot is currently dedicated to uiControl */}
        <PageFilterForm filters={mainFilters} formMethods={formMethods} />
      </Inline>

      <Inline gap="150">
        {/* Secondary slot is currently dedicated to pills */}
        <PageFilterFormSecondary
          filters={secondaryFilters}
          formMethods={formMethods}
        />
      </Inline>
    </Stack>
  );
};

export default PageFilter;
