import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const actionItemService = createApi({
  reducerPath: "actionItemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: [
    "Query",
    "QueryResponse",
    "QueryLockedUpdateFields",
    "getProjectActionItems",
    "Fields",
    "CopyRequest",
    "queryItems",
    "Workflow"
  ],
  endpoints: builder => ({
    getProjectActionItems: builder.query({
      query: ({ projectId, copyable = false, targetProjectId, copyFrom }) =>
        `/api/projects/${projectId}/queries?${new URLSearchParams({
          copyable,
          copyFrom,
          targetProjectId
        })}`,
      providesTags: ["Query"]
    }),
    getActionItemsWithFilter: builder.query({
      query: ({ projectId, filters }) => ({
        url: `/api/projects/${projectId}/queries`,
        params: filters
      }),
      providesTags: ["Query"]
    }),
    getToDoListActionItems: builder.query({
      query: () => `/api/queries?for=toDoList`
    }),
    getActionItem: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}`,
      providesTags: ["Query"]
    }),
    updateActionItemField: builder.mutation({
      query: ({ queryId, field, value }) => ({
        url: `/api/queries/${queryId}?singleField=true`,
        method: "PUT",
        body: { field, value }
      }),
      invalidatesTags: ["Query"]
    }),
    getActionItemMetadata: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/metadata`
    }),
    addActionItem: builder.mutation({
      query: actionItem => ({
        url: `/api/queries`,
        method: "POST",
        body: actionItem
      }),
      invalidatesTags: ["Query"]
    }),
    getLockedUpdateFields: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/lockedUpdateFields`,
      providesTags: ["QueryLockedUpdateFields"]
    }),
    getResponses: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/queryResponses`,
      providesTags: ["QueryResponse"]
    }),
    getCopyFromFields: builder.query({
      query: ({ queryIds, projectId }) => ({
        url: `/api/projects/${projectId}/queries/requiredFields`,
        params: { queryIds }
      }),
      providesTags: ["Fields"]
    }),
    copyRequest: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/api/projects/${projectId}/queries/copyFrom`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CopyRequest"]
    }),
    deleteActionItem: builder.mutation({
      query: ({ queryId }) => ({
        url: `/api/queries/${queryId}`,
        method: "DELETE"
      })
    }),
    updateWorkflow: builder.mutation({
      query: ({ queryId, action }) => ({
        url: `/api/queries/${queryId}/workflow/action`,
        method: "POST",
        body: action
      }),
      invalidatesTags: ["Workflow", "Query"]
    }),
    getWorkflowStep: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/workflow/steps`,
      providesTags: ["Workflow"]
    })
  })
});

export const {
  useAddActionItemMutation,
  useGetProjectActionItemsQuery,
  useGetLockedUpdateFieldsQuery,
  useGetToDoListActionItemsQuery,
  useLazyGetActionItemQuery,
  useLazyGetResponsesQuery,
  useGetCopyFromFieldsQuery,
  useCopyRequestMutation,
  useUpdateActionItemFieldMutation,
  useDeleteActionItemMutation,
  useLazyGetActionItemMetadataQuery,
  useLazyGetActionItemsWithFilterQuery,
  useUpdateWorkflowMutation,
  useLazyGetWorkflowStepQuery,
  util: actionItemServiceUtil
} = actionItemService;
