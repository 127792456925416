import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  useAuthUser,
  useCurrentProject,
  useFeatures,
  useGetMyProjects,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useGetProjectMilestonesQuery,
  useLazyGetMenusQuery,
  useShareStatus,
  useUIConfig
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";

import { routeConstants } from "@app/constants/routeConstants";
import { AccessLevel, ResourceName } from "@app/types";

import { Button } from "@atoms/Button";
import { Icon } from "@atoms/Icon";
import { Placeholder, PlaceholderContext } from "@atoms/Placeholder";

import List from "@components/atoms/List/List";
import EntityList from "@components/molecules/EntityList";
import ReminderCircle from "@components/molecules/ReminderCircle";
import ActionItemsBoard from "@components/organisms/ActionItemsBoard";
import AddOrCopyProjectActionItems from "@components/organisms/AddOrCopyProjectActionItems";
import ContactListBox from "@components/organisms/ContactListBox";
import { contactListActions } from "@components/organisms/ContactListBox/ContactListBox";
import MilestoneGraph from "@components/organisms/MilestoneGraph";
import Milestones from "@components/organisms/Milestones/Milestones";
import News from "@components/organisms/News";
import UploadFileBox from "@components/organisms/UploadFileBox";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate/DashboardBoxTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./ProjectSummary.scss";

const ProjectSummary = () => {
  //state
  const { finalPackageShareStatus, setShareStatusProject, getShareStatus } =
    useShareStatus();
  const [project, setProject] = useState(null);
  const [isShowAskHelp, setIsShowAskHelp] = useState(false);

  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal,
    projectAccessModal
  } = useModalContent();

  //hooks
  const [getInteractiveReportMenus, interactiveReportMenusData] =
    useLazyGetMenusQuery();
  const { uiConfig } = useUIConfig();
  const { user } = useAuthUser({ withProfile: false });
  const { myProjects, isMyProjectsLoading, fetchMyProjects } =
    useGetMyProjects();
  const { projectId } = useParams();
  const { currentProject, onChangeCurrentProject, onUpdateCurrentProject } =
    useCurrentProject();
  const { data: milestones, isFetching: isMilestonesFetching } =
    useGetProjectMilestonesQuery(
      { projectId },
      {
        skip: !projectId
      }
    );
  const navigate = useNavigate();
  const { members, isProjectMember } = useGetProjectMembers({ id: projectId });
  const { t } = useTranslation();
  //ref
  const isLoading = useRef(false);
  const { state: projectFromLocationState } = useLocation();
  const {
    data: projectToSelect,
    error: isInvalidProject,
    isLoading: isFetchingProject
  } = useGetProjectByIdQuery(
    { projectId },
    { skip: !projectId || project?.id === projectId }
  );
  const { isEnabled, isInteractiveReportEnabled } = useFeatures();

  const isPreferCurrent = useRef(true);

  // Handle Error in loading project
  useEffect(() => {
    if (isInvalidProject) {
      navigate("/not-found", { replace: true });
    }
  }, [isInvalidProject, navigate]);

  useEffect(() => {
    getShareStatus();
  }, [getShareStatus]);

  useEffect(() => {
    // handles case where we access 'project-summary' with project information in the location.state (e.g click link, project selector)
    // or if the user has done a project swap (e.g. via URL)
    if (projectId) {
      return;
    }
    if (projectFromLocationState?.project?.id) {
      navigate(`/projects/${projectFromLocationState.project.id}`, {
        replace: true
      });
    } else if (currentProject) {
      navigate(`/projects/${currentProject.id}`, { replace: true });
    }
  }, [projectId, currentProject, navigate, projectFromLocationState]);

  //when there is no project and no multiple selection we default to first project so we need the list of projects
  useEffect(() => {
    if (
      !user.isHostUser &&
      !project &&
      !projectId &&
      !currentProject &&
      !isMyProjectsLoading &&
      !isLoading.current &&
      uiConfig
    ) {
      if (!uiConfig.multipleProjectSelection?.enabled) {
        fetchMyProjects({ withDetails: false });
        isLoading.current = true;
      }
    }
  }, [
    project,
    projectId,
    currentProject,
    user,
    fetchMyProjects,
    isMyProjectsLoading,
    uiConfig
  ]);

  // NB: this chunk handles most of the start up logic when loading up this Page
  useEffect(() => {
    if (
      projectId &&
      projectId == project?.id &&
      projectId == currentProject?.id
    ) {
      setIsShowAskHelp(false);
      // we have our project
      return;
    }
    if (isMyProjectsLoading) {
      //wait for projects to load
      return;
    }

    // need to handle special case when multiProjectSelection isn't available - the latest project is chosen.
    if (!user.isHostUser && !uiConfig?.multipleProjectSelection?.enabled) {
      isLoading.current = false;
      if (projectId) {
        return;
      } else if (myProjects?.length > 0) {
        const orderedProjects = utilities.orderProjectsByStartDate(
          structuredClone(myProjects)
        );
        isPreferCurrent.current = true;
        onChangeCurrentProject(orderedProjects[0]);
        setIsShowAskHelp(false);
        navigate(`/projects/${orderedProjects[0].id}`, { replace: true });
        return;
      } else if (!projectId && !currentProject) {
        setIsShowAskHelp(true);
        setProject(null);
        return;
      }
    }
  }, [
    currentProject,
    isMyProjectsLoading,
    myProjects,
    navigate,
    onChangeCurrentProject,
    project?.id,
    projectId,
    uiConfig?.multipleProjectSelection?.enabled,
    user.isHostUser
  ]);

  useEffect(() => {
    if (!projectToSelect) {
      return;
    }
    if (currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    } else {
      onUpdateCurrentProject(projectToSelect);
    }
  }, [
    projectToSelect,
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject
  ]);

  useEffect(() => {
    if (projectId && isInteractiveReportEnabled) {
      getInteractiveReportMenus({ projectId });
    }
  }, [getInteractiveReportMenus, projectId]);

  useEffect(() => {
    if (!currentProject) {
      return;
    }
    setProject(prevProject => {
      if (!isLoading.current) {
        return utilities.getProjectMilestoneData(currentProject);
      }
      if (isMilestonesFetching) {
        return utilities.getProjectMilestoneData(currentProject);
      }
      return prevProject;
    });
  }, [currentProject, isMilestonesFetching]);

  useEffect(() => {
    if (
      isLoading.current &&
      !isMilestonesFetching &&
      !isFetchingProject &&
      !isMyProjectsLoading
    ) {
      isLoading.current = false;
    }
  }, [isLoading, isFetchingProject, isMyProjectsLoading, isMilestonesFetching]);

  useEffect(() => {
    if (project) {
      setShareStatusProject(project);
    }
  }, [project, setShareStatusProject]);

  const getReminderDays = p => {
    if (p.daysForNextMilestone || p.daysForNextMilestone === 0) {
      return `${Math.abs(p.daysForNextMilestone)}`;
    } else {
      return "NO";
    }
  };

  const getMilestoneMessage = p => ({
    top: t("common:ui.projectSummary.milestoneMessage.top", {
      count: Math.abs(p.daysForNextMilestone),
      context: p.milestoneStatus.replace(" ", "_")
    }),
    bottom: t("common:ui.projectSummary.milestoneMessage.bottom", {
      context: p.milestoneStatus.replace(" ", "_")
    }),
    complete: t("common:ui.projectSummary.milestoneMessage.complete")
  });

  const projectError = useCallback(
    msg => {
      handleOpenModal("projectAccess", `${msg}`);
    },
    [handleOpenModal]
  );

  const handleAddActionItems = useCallback(() => {
    isProjectMember(
      user,
      t("requests:requests.ui.projectSummary.accessRequiredActionSendRequest"),
      () => {
        handleOpenModal("actionForm");
      },
      projectError
    );
  }, [handleOpenModal, isProjectMember, projectError, t, user]);

  const handleClickUpdateMilestone = () => {
    isProjectMember(
      user,
      t("common:ui.projectSummary.accessRequiredUpdateMilestone"),
      () => {
        handleOpenModal("updateMilestones");
      },
      projectError
    );
  };

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, projectError);
    },
    [isProjectMember, projectError, user]
  );

  const renderMilestoneBox = () => {
    if (!project) {
      return null;
    }
    return (
      <div className="milestone-box">
        <div className="milestone-box__left">
          <MilestoneGraph
            project={project}
            milestones={milestones ?? []}
            handleClickUpdateMilestone={handleClickUpdateMilestone}
          />
        </div>
        <div className="milestone-box__right">
          <ReminderCircle
            days={getReminderDays(project)}
            message={getMilestoneMessage(project)}
            percentage={project.percentage}
            isCompleted={project.isCompleted}
          />
        </div>
      </div>
    );
  };

  const onAddActionSubmitted = useCallback(() => {
    handleCloseModal();
    navigate(routeConstants.request.requests, { state: { project } });
  }, [handleCloseModal, navigate, project]);

  const getActions = () => {
    if (!project) {
      return <></>;
    }

    return (
      <>
        {getCopyProjectButton()}
        {getFinalPackageButton()}
        {getProjectEditButton()}
      </>
    );
  };

  const getCopyProjectButton = () => {
    if (!project?.isCompleted || !user.isHostUser) {
      return <></>;
    }

    const canCreateProjects =
      user.checkAccess(
        ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
        AccessLevel.CREATE
      ) ||
      (user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      ) &&
        project.isMember);
    if (!canCreateProjects) {
      return <></>;
    }

    return (
      <NavLink
        to={`/projects/${project.id}/copy`}
        state={{ engagement: project.engagement }}
      >
        <Button label={t("common:ui.project.title_copy")} />
      </NavLink>
    );
  };

  const getProjectEditButton = () => {
    if (
      !project ||
      !user.isHostUser ||
      uiConfig.theme.navigationStyle !== "OT1"
    ) {
      return <></>;
    }

    const canCreateProjects =
      user.checkAccess(
        ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
        AccessLevel.CREATE
      ) ||
      (user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      ) &&
        project.isMember);

    if (!canCreateProjects) {
      return <></>;
    }

    return (
      <div className="ot-title__actions__settings">
        <NavLink
          to={`/projects/${project.id}/edit`}
          state={{ engagement: project.engagement }}
        >
          <Icon
            name={"settings"}
            className={"icon-settings"}
            hoverElement={t(
              "common:ui.projectSummary.header.projectSettings.label"
            )}
          />
        </NavLink>
      </div>
    );
  };

  const showFinalPackage = useMemo(
    () => finalPackageShareStatus || user.isHostUser,
    [finalPackageShareStatus, user.isHostUser]
  );

  const onClickFinalPackage = () =>
    navigate(`/projects/${project.id}${routeConstants.finalPackage}`);

  const getFinalPackageButton = () => {
    return (
      project?.configuration?.features?.finalPackage?.enabled && (
        <Button
          iconName={"push_pin"}
          disabled={!showFinalPackage}
          label={project?.configuration?.finalPackage?.name || "Final Package"}
          onClick={onClickFinalPackage}
        />
      )
    );
  };

  const displayUsefulLinks = useCallback(() => {
    const usefulLinks =
      interactiveReportMenusData?.data?.reduce(
        (prev, curr) => [
          ...prev,
          ...curr.pages.filter(({ usefulLink }) => usefulLink)
        ],
        []
      ) ?? [];

    if (usefulLinks?.length === 0) {
      return <div>{t("common:ui.projects.usefulLinks.none")}</div>;
    }

    return (
      <List
        list={usefulLinks?.map(data => {
          return {
            id: data.id,
            name: data.pageName,
            linkTo: `pages/${data?.id}`
          };
        })}
      />
    );
  }, [interactiveReportMenusData?.data]);

  const displayMidSummaryBody = useCallback(() => {
    const { configuration = {} } = currentProject ?? {};

    const isNewsEnabled =
      configuration.news?.enabled &&
      isEnabled(systemConstants.features.manageNews);

    const both = isNewsEnabled && configuration.interactiveReport?.quickLinks;
    const none = !isNewsEnabled && !configuration.interactiveReport?.quickLinks;
    const className = both ? "two" : "one";
    if (none) {
      return (
        <Placeholder
          content={<h2>{t("common:ui.projects.features.none")}</h2>}
          context={PlaceholderContext.SURFACE}
        />
      );
    }

    return (
      <>
        {isNewsEnabled && (
          <div className={className}>
            <News
              engagementTypeId={project?.engagement?.engagementTypeId}
              newsConfiguration={project?.configuration?.news}
              engagementTypeContextKey={project?.configuration?.i18nContextKey}
            />
          </div>
        )}
        {configuration.interactiveReport?.quickLinks && (
          <div className={className}>
            <DashboardBoxTemplate
              className={className}
              title={t("common:ui.projects.usefulLinks.label")}
              boxClassName={"useful-links-box"}
            >
              {displayUsefulLinks()}
            </DashboardBoxTemplate>
          </div>
        )}
      </>
    );
  }, [
    currentProject,
    displayUsefulLinks,
    isEnabled,
    project?.configuration?.news,
    project?.configuration?.i18nContextKey,
    project?.engagement?.engagementTypeId,
    t
  ]);

  const bodyPrimary = useMemo(() => {
    if (!project || !user) {
      return <></>;
    }
    return (
      <>
        <div className="project-summary__body__box">
          <ActionItemsBoard project={project} projectMembers={members} />
          {project?.configuration?.entities?.enabled ? (
            <EntityList entities={project.entities} project={project} />
          ) : (
            <UploadFileBox
              title={t("common:ui.documents.fileUpload.label")}
              project={project}
              boxClassName={"upload-file-box"}
              checkIsMember={true}
              registerModal={registerModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
        <div className="project-summary__body__box">
          {displayMidSummaryBody()}
        </div>
        <div className="project-summary__body__box">
          <ContactListBox
            project={project}
            showClientContacts={user.isHostUser}
            boxClassName={"contact-list-box"}
            handlers={{
              [contactListActions.ADD_ACTION_ITEMS]: handleAddActionItems,
              [contactListActions.HANDLE_REORDER_MEMBERS]: checkMembership(
                t("common:ui.projectSummary.accessRequiredReorderMembers")
              )
            }}
            registerModal={registerModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        </div>
      </>
    );
  }, [
    project,
    user,
    members,
    t,
    registerModal,
    handleOpenModal,
    handleCloseModal,
    displayMidSummaryBody,
    handleAddActionItems,
    checkMembership
  ]);

  const actionFormModal = useMemo(
    () => () => (
      <AddOrCopyProjectActionItems
        project={project}
        onCancel={handleCloseModal}
        onSubmitted={onAddActionSubmitted}
      />
    ),
    [handleCloseModal, onAddActionSubmitted, project]
  );

  const updateMilestonesModal = useMemo(
    () => () => (
      <Popup visibility={true} handleOutsideClick={false} width="60rem">
        <Milestones
          milestones={milestones ?? []}
          project={project}
          onSave={() => {
            handleCloseModal();
          }}
          onCancel={handleCloseModal}
        />
      </Popup>
    ),
    [handleCloseModal, milestones, project]
  );

  useEffect(() => {
    if (project && user) {
      registerModal("projectAccess", projectAccessModal);
      registerModal("actionForm", actionFormModal);
      registerModal("updateMilestones", updateMilestonesModal);
    }
  }, [
    actionFormModal,
    project,
    projectAccessModal,
    registerModal,
    updateMilestonesModal,
    user
  ]);

  const pageTitle = useMemo(() => {
    if (uiConfig?.theme?.designSystem === "tds") {
      return `${t("common:ui.projectSummary.greetingMessage", {
        firstname: user.firstname,
        lastname: user.lastname
      })}`;
    }
    const currentProjectName = currentProject ? `${currentProject?.name} ` : "";
    return `${currentProjectName}${t("common:ui.dashboard.navigation.title")}`;
  }, [
    currentProject,
    t,
    uiConfig?.theme?.designSystem,
    user.firstname,
    user.lastname
  ]);

  return (
    <PageTemplate
      header={{
        title: pageTitle,
        actions: getActions(),
        content: renderMilestoneBox()
      }}
      other={{
        help: isShowAskHelp
          ? t("common:ui.projectSummary.messageNotAddedToProject")
          : null,
        loading: isLoading.current
          ? t("common:ui.projectSummary.loadingProjects")
          : null,
        project: currentProject
      }}
      body={{
        primary: bodyPrimary
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
      classNames={{
        page: "project-summary",
        banner: process.env.REACT_APP_THEME === "ey" ? "client-banner" : ""
      }}
    />
  );
};

export default ProjectSummary;
