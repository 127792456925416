import React, { useCallback, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { getChartValues } from "@app/helpers/chart";

import ToggleButtonGroup from "@components/atoms/ToggleButtonGroup/ToggleButtonGroup";
import AnalyticsTable from "@components/molecules/AnalyticsTable/AnalyticsTable";
import BulletList from "@components/molecules/BulletList";
import InteractiveReportChart from "@components/molecules/InteractiveReportChart";

import "./AnalyticsPanel.scss";

const toggleTypes = {
  graph: "GRAPH",
  table: "TABLE",
  list: "LIST"
};
const AnalyticsPanel = props => {
  const { data, t } = props;
  const displayToggle = data?.enableToggle || false;
  const [table, setTable] = useState();
  const [selectedType, setSelectedType] = useState(data?.defaultDisplay);
  const [contentHeight, setContentHeight] = useState();
  const panelRef = useRef();
  const panelBodyRef = useRef();
  const toggleButtons = [
    {
      type: toggleTypes.graph,
      label: t(
        "interactiveReport:interactiveReport.ui.analyticsPanel.graphToggle"
      )
    },
    {
      type: toggleTypes.table,
      label: t(
        "interactiveReport:interactiveReport.ui.analyticsPanel.tableToggle"
      )
    }
  ];

  useEffect(() => {
    const tableWithRowId = structuredClone(data?.table);
    tableWithRowId?.rows?.forEach((r, id) => (r.id = id));
    setTable(tableWithRowId);
  }, [data?.table]);

  useEffect(() => {
    setContentHeight(
      panelRef.current?.offsetHeight - (displayToggle ? 120 : 90)
    );
  }, [displayToggle]);

  const onClickToggle = useCallback(type => setSelectedType(type), []);

  const renderGraph = () => {
    const { chart } = data;
    if (chart?.type) {
      let chartWithValues = chart;
      if (chart.axis?.tableColumn && table) {
        chartWithValues = getChartValues(chart, table);
      }
      const isHide = toggleTypes.graph !== selectedType;
      const validChart = chartWithValues.chartValues[0].values;
      return (
        <div
          className={`analytics-panel__body__graph ${
            isHide ? "analytics-panel__body--hide" : ""
          }`}
        >
          {validChart && (
            <InteractiveReportChart
              chart={chartWithValues}
              tickMaxLines={2}
              fixedHeight={contentHeight}
              pieRadius={"75%"}
              enableHidden={false}
              chartMargin={{}}
            />
          )}
        </div>
      );
    }
  };

  const renderTable = () => {
    const isHide = toggleTypes.table !== selectedType;
    return (
      <div
        className={`analytics-panel__body__table ${
          isHide ? "analytics-panel__body--hide" : ""
        }`}
      >
        <AnalyticsTable table={table} />
      </div>
    );
  };

  const renderList = () => {
    const isHide = toggleTypes.list !== selectedType;
    return (
      <div
        className={`analytics-panel__body__list ${
          isHide ? "analytics-panel__body--hide" : ""
        }`}
      >
        <BulletList
          list={data.list}
          panelRef={panelRef}
          panelBodyRef={panelBodyRef}
        />
      </div>
    );
  };

  return (
    <div className={`analytics-panel`} ref={panelRef}>
      <div className="analytics-panel__header">
        <div className="analytics-panel__header__title">{data?.name}</div>
        {displayToggle && (
          <ToggleButtonGroup
            onClick={onClickToggle}
            defaultType={data?.defaultDisplay}
            toggleButtons={toggleButtons}
          />
        )}
      </div>
      <div className="analytics-panel__body" ref={panelBodyRef}>
        {table && <>{renderTable()}</>}
        {data?.chart && <>{renderGraph()}</>}
        {data?.list && <>{renderList()}</>}
      </div>
    </div>
  );
};

AnalyticsPanel.propTypes = {
  data: PropTypes.shape({
    defaultDisplay: PropTypes.oneOf(["LIST", "GRAPH", "TABLE"]).isRequired,
    enableToggle: PropTypes.bool,
    name: PropTypes.string,
    chart: PropTypes.object,
    table: PropTypes.object
  })
};

export default withTranslation()(AnalyticsPanel);
