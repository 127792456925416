import { cmsConstants } from "../constants";
import { cmsService } from "../services";

export const cmsActions = {
  getContent,
  getUIConfig
};

function getContent() {
  return dispatch => {
    dispatch(request());

    cmsService.getContent().then(
      content => {
        dispatch(success(content));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: cmsConstants.GET_CONTENT_REQUEST
      };
    }
    function success(content) {
      return {
        type: cmsConstants.GET_CONTENT_SUCCESS,
        content
      };
    }
    function failure(error) {
      return {
        type: cmsConstants.GET_CONTENT_FAILURE,
        error
      };
    }
  };
}

function getUIConfig() {
  return dispatch => {
    dispatch(request());

    cmsService.getUIConfig().then(
      uiConfig => {
        dispatch(success(uiConfig));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: cmsConstants.GET_UI_CONFIG_REQUEST
      };
    }
    function success(uiConfig) {
      return {
        type: cmsConstants.GET_UI_CONFIG_SUCCESS,
        uiConfig
      };
    }
    function failure(error) {
      return {
        type: cmsConstants.GET_UI_CONFIG_FAILURE,
        error
      };
    }
  };
}
