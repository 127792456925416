/** convert an arraybuffer to a string */
const convertArrayBufferToString = buffer => {
  let string = "";
  const length = buffer.byteLength;
  const view = new DataView(buffer, 0, length);
  for (let i = 0; i < length; i++) {
    string += String.fromCharCode(view.getUint8(i, false));
  }
  return string;
};

/** return a base64url encoded string */
const base64urlEncode = str =>
  btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");

/** get a random int inclusive of min and max */
const getRandomInt = (min, max) =>
  min + Math.floor(Math.random() * (max + 1 - min));

function getRandomAlphaNumerical() {
  let char;
  do {
    char = String.fromCharCode(getRandomInt(48, 122));
  } while (!/[a-zA-Z0-9]/.test(char));
  return char;
}

/** generate a  pseudo random string of provided length inclusive of  0-1, A-Z and a-z */
const getRandomString = length =>
  Array.from({ length }, () => getRandomAlphaNumerical()).join("");

/** generate a code verifier and and a code challenge to perform oAuth2
 * verifier is a random string of length 43
 * challenge is the sha256 of the verifier which is base64url encoded
 * verifier will be sent to the backend so that it can be validated with oAuth2 provider
 */
const generateCodeChallengeVerifier = async () => {
  const codeVerifier = getRandomString(43);
  const codeVerifierHash = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  );
  const codeChallenge = base64urlEncode(
    convertArrayBufferToString(codeVerifierHash)
  );
  return { codeChallenge, codeVerifier };
};

export const cryptography = {
  convertArrayBufferToString,
  getRandomInt,
  getRandomAlphaNumerical,
  getRandomString,
  base64urlEncode,
  generateCodeChallengeVerifier
};
