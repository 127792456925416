import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useFeatures } from "@shared/hooks";
import { useGetPermissionsQuery } from "@shared/services/roleService";

import AddRoleForm from "./AddRoleForm/AddRoleForm";

const AddUserRole = () => {
  const { data: permissions } = useGetPermissionsQuery();
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFeaturesLoading && !isEnabled(systemConstants.features.roles)) {
      navigate(routeConstants.notFound);
    }
  }, [isFeaturesLoading, navigate, isEnabled]);

  return (
    <AddRoleForm
      resourcesPermissions={permissions}
      isFeaturesLoading={isFeaturesLoading}
    />
  );
};

export default AddUserRole;
