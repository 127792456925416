import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { Inline } from "@fermions";

import { BorderStyle, DropdownInput } from "@molecules/inputs";

import { DropdownTheme } from "../DropdownList/DropdownHelper";
import { InputWidth } from "../inputs/InputTemplate";
import "./GroupLabels.scss";

const GroupLabels = props => {
  const {
    groupLabel,
    dropdownItems,
    handleSelectFilter,
    styleVariant,
    icon,
    disabled = false,
    navigationStyle
  } = props;

  const handleClickSelection = useCallback(
    selectedLabel => {
      handleSelectFilter?.(selectedLabel);
    },
    [handleSelectFilter]
  );

  return (
    <Inline
      gap="200"
      className={`groupLabels ${
        navigationStyle === "OT1" ? `groupLabels--style-${styleVariant}` : ""
      }`}
    >
      {styleVariant === "iconBox" && icon}
      <DropdownInput
        value={{ name: groupLabel }}
        borderStyle={BorderStyle.NONE}
        items={dropdownItems}
        theme={DropdownTheme.DARK}
        onChange={handleClickSelection}
        disabled={disabled}
        width={InputWidth.SMALL}
        allowUndefined={false}
        showSearch
        closeOnMouseLeave={false}
      />
    </Inline>
  );
};

GroupLabels.defaultProps = {
  styleVariant: "standard"
};

GroupLabels.propTypes = {
  groupLabel: PropTypes.string,
  dropdownItems: PropTypes.array,
  handleSelectFilter: PropTypes.func,
  styleVariant: PropTypes.oneOf(["standard", "iconBox"]),
  navigationStyle: PropTypes.oneOf(["OT1", "OT2"]),
  disabled: PropTypes.bool,
  icon: PropTypes.node, // NB: icon is only visible when styleVariant=iconBox
  isActive: PropTypes.bool
};

export default GroupLabels;
