import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants/systemConstants";
import { useAuthUser, useFeatures } from "@shared/hooks";
import {
  useDeleteRoleMutation,
  useGetRolesQuery
} from "@shared/services/roleService";

import {
  AccessLevel,
  ResourceName,
  Role,
  RoleAction,
  RoleActionFn,
  RoleTableData
} from "@app/types";

import RolesTable from "@components/molecules/RolesTable/RolesTable";
import ManageUserRolesTemplate from "@components/templates/ManageUserRolesTemplate";

type GetRoleActionsFn = ({
  isSystem,
  canEdit,
  canDelete
}: {
  isSystem: boolean;
  canEdit: boolean;
  canDelete: boolean;
}) => RoleAction[];

const ManageUserRoles = () => {
  const navigate = useNavigate();
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { data: roles, isLoading, error } = useGetRolesQuery();
  const [deleteRole, { error: deleteError }] = useDeleteRoleMutation();
  const { user } = useAuthUser();
  const [selectedRoleId, setSelectedRoleId] = useState<number | undefined>(
    undefined
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClickAddRole = () => navigate(routeConstants.admin.addRole);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFeaturesLoading && !isEnabled(systemConstants.features.roles)) {
      navigate(routeConstants.notFound);
    }
  }, [isEnabled, isFeaturesLoading, navigate]);

  const data: RoleTableData = useMemo(() => {
    const hasEditAccess = user.checkAccess(
      ResourceName.ROLES,
      AccessLevel.UPDATE
    );

    const hasDeleteAccess = user.checkAccess(
      ResourceName.ROLES,
      AccessLevel.DELETE
    );

    const getRoleActions: GetRoleActionsFn = ({
      isSystem,
      canEdit,
      canDelete
    }) => {
      const actions: RoleAction[] = [
        {
          name: "View",
          execute: (roleId: number) => navigate(`/admin/roles/${roleId}`)
        }
      ];

      if (!isSystem && canEdit) {
        actions.unshift({
          name: "Edit",
          execute: (roleId: number) => navigate(`/admin/roles/${roleId}/edit`)
        });
      }

      if (!isSystem && canEdit && canDelete) {
        actions.push({
          name: "Delete",
          execute: (roleId: number) => {
            setSelectedRoleId(roleId);
            setShowDeleteModal(true);
          }
        });
      }

      return actions;
    };

    return (
      roles?.map((role: Role) => ({
        ...role,
        name: role.properties?.translatable ? t(role.name) : role.name,
        createdBy: role.createdBy?.name ?? systemConstants.generatedBy.system,
        roleActions: getRoleActions({
          isSystem: !role.createdBy?.name,
          canEdit: hasEditAccess,
          canDelete: hasDeleteAccess
        }).map(action => ({
          ...action,
          name: t(`admin:ui.manageUserRoles.action.${action.name}`)
        }))
      })) ?? []
    );
  }, [roles, user, navigate, t]);

  const handleActionClick: RoleActionFn = ({ menuItem, roleId }) =>
    menuItem.execute(roleId);

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setSelectedRoleId(undefined);
  };
  const handleDeleteClick = (roleId?: number) => {
    if (!roleId) {
      handleCloseModal();
      return;
    }
    deleteRole(roleId).unwrap().then(handleCloseModal);
  };

  return (
    <ManageUserRolesTemplate
      rolesTable={
        <RolesTable data={data} handleActionClick={handleActionClick} />
      }
      handleClickAddRole={handleClickAddRole}
      isLoading={isLoading}
      selectedRoleId={selectedRoleId}
      showDeleteModal={showDeleteModal}
      handleClickDelete={handleDeleteClick}
      handleCloseDeleteModal={handleCloseModal}
      error={error}
      deleteError={deleteError}
      canAddRole={user.checkAccess(ResourceName.ROLES, AccessLevel.CREATE)}
    />
  );
};

export default ManageUserRoles;
