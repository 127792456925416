import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import {
  useAddInsightsBoardMutation,
  useGetAllInsightsBoardQuery
} from "@shared/hooks";

import { routeConstants } from "@app/constants";

import ManageInsightsBoardTemplate from "@components/templates/ManageInsightsBoardTemplate";

function ManageInsightsBoard() {
  const { t } = useTranslation();
  const { data: insightsBoards, error: InsightsBoardError } =
    useGetAllInsightsBoardQuery();
  const [addInsightsBoard] = useAddInsightsBoardMutation();
  const navigate = useNavigate();

  const handleSubmit = async insightsBoard => {
    const payload = {
      level: systemConstants.insight.levels.engagementType,
      ...insightsBoard
    };
    try {
      const data = await addInsightsBoard(payload).unwrap();
      navigate(`${routeConstants.insightsBoard}/${data.id}`);
      return "";
    } catch (error) {
      return error?.data?.key ?? error.data?.message;
    }
  };

  return (
    <ManageInsightsBoardTemplate
      insightsBoards={insightsBoards}
      handleSubmit={handleSubmit}
      error={
        InsightsBoardError?.data?.key
          ? t(InsightsBoardError.data.key)
          : InsightsBoardError?.data?.message
      }
    />
  );
}

export default ManageInsightsBoard;
