import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useFinalPackageProcess(projectId) {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [processItems, setProcessEntries] = useState([]);

  const fetchProcessEntries = useCallback(() => {
    if (!projectId) {
      return;
    }
    dispatch(finalPackageActions.getFinalPackageProcessEntries({ projectId }));

    return () => {
      dispatch(finalPackageActions.resetGetFinalPackageProcessEntries());
    };
  }, [projectId, dispatch]);

  useEffect(fetchProcessEntries, [fetchProcessEntries]);

  useEffect(() => {
    setIsLoading(finalPackage.isFinalPackageProcessLoading);
  }, [finalPackage.isFinalPackageProcessLoading]);

  useEffect(() => {
    setProcessEntries(finalPackage.finalPackageProcessEntries || []);
  }, [finalPackage.finalPackageProcessEntries]);

  useEffect(() => {
    setError(finalPackage.finalPackageProcessError);
  }, [finalPackage.finalPackageProcessError]);

  return {
    processItems,
    fetchProcessEntries,
    isLoading,
    error
  };
}
