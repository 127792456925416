import React, { createRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageArticleActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useGetSupportedMimesQuery } from "@shared/services/documentMimesService";

import ErrorBox from "@shared-components/errorBox/ErrorBox";
import Input from "@shared-components/input/Input";
import Popup from "@shared-components/popup/Popup";

import { Button, ButtonVariant } from "@atoms/Button";

import ImageEditor from "@components/molecules/ImageEditor";
import MainPageTemplate from "@components/templates/MainPageTemplate";

import "./AddOrUpdateArticle.scss";
import { useTranslation } from "react-i18next";

function AddOrUpdateArticle() {
  const { t } = useTranslation();
  const location = useLocation();
  const { manageArticles, authentication } = useSelector(state => state);
  const navigate = useNavigate();
  const { data: supportedMimes } = useGetSupportedMimesQuery({
    type: systemConstants.mimeDocumentType.image
  });
  const titleRef = createRef();
  const authorRef = createRef();
  const descriptionRef = createRef();
  const isUpdate = location.state?.article;
  const article = isUpdate
    ? location.state.article
    : {
        slotId: location.state?.slotId?.toLowerCase(),
        line1: "",
        bannerImage: null
      };
  const [isSaving, setIsSaving] = useState(false);
  const [image, setImage] = useState(article.bannerImage || null);
  const [line1, setLine1] = useState(article.line1);
  const [line2, setLine2] = useState(article.line2);
  const [line3, setLine3] = useState(article.line3);

  const [selectImageVisibility, setSelectImageVisibility] = useState(false);
  const [titleTextFocus, setTitleTextFocus] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(manageArticleActions.resetAddArticle());
      dispatch(manageArticleActions.resetUpdateArticle());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress = event => {
    if (event.key === "Enter" && !titleTextFocus) {
      handleSave();
    }
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  useEffect(() => {
    if (line1 && line1.trim() && image) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [line1, image]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSaving && !!manageArticles.addedArticle) {
      setIsSaving(false);
      navigate(routeConstants.userDashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageArticles.addedArticle]);

  useEffect(() => {
    if (isSaving && !!manageArticles.updatedArticle) {
      setIsSaving(false);
      navigate(routeConstants.userDashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageArticles.updatedArticle]);

  useEffect(() => {
    if (manageArticles.error) {
      setError(manageArticles.error);
      setIsSaving(false);
    }
  }, [manageArticles.error]);

  const handleLine1Change = event => {
    event.stopPropagation();
    setLine1(event.target.value);
    if (manageArticles.error) setError("");
  };

  const handleLine2Change = event => {
    event.stopPropagation();
    setLine2(event.target.value);
    if (manageArticles.error) setError("");
  };

  const handleLine3Change = event => {
    event.stopPropagation();
    setLine3(event.target.value);
    if (manageArticles.error) setError("");
  };

  const handlePopupOutsideClick = () => {
    setSelectImageVisibility(false);
  };

  const handleImageSetClick = base64Image => {
    setImage(base64Image);
    setSelectImageVisibility(false);
    if (manageArticles.error) setError("");
    titleRef.current.focus();
  };

  const handleImageClick = () => {
    setSelectImageVisibility(true);
  };

  const getPlaceholderImage = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366 194">
        <rect width="366" height="194" fill="#cccccc"></rect>
        <text
          x="50%"
          y="55%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily="monospace"
          fontSize="16px"
          fill="#333333"
        >
          Click to select image
        </text>
      </svg>
    );
  };

  const handleTitleTextFocus = () => {
    setTitleTextFocus(true);
  };
  const handleTitleTextBlur = () => {
    setTitleTextFocus(false);
  };

  const handleSave = () => {
    const line4 = Date(Date.now());
    const articleToSave = {
      ...article,
      hostId: authentication.user.hostId,
      slotId: article.slotId || location.state?.slotId?.toLowerCase(),
      line1,
      line2: line2 || "",
      line3: line3 || "",
      line4,
      bannerImage: image
    };
    setIsSaving(true);

    !isUpdate
      ? dispatch(manageArticleActions.addArticle(articleToSave))
      : dispatch(manageArticleActions.editArticle(articleToSave));
  };

  const handleCancel = () => {
    navigate(routeConstants.userDashboard);
  };

  return (
    <MainPageTemplate>
      <div className="add-article">
        <div className="add-article__title">
          <h1>{isUpdate ? "Edit" : "Add"} News Article</h1>
        </div>
        {error && (
          <div className="add-article__error-box">
            <ErrorBox message={error} />
          </div>
        )}
        <div className="add-article__banner">
          <label className="add-article__banner--label">
            Banner image<span>*</span>:
          </label>
          <div
            onClick={handleImageClick}
            className="add-article__banner--image"
          >
            {image ? (
              <img src={image} alt="article banner" />
            ) : (
              getPlaceholderImage()
            )}
          </div>
        </div>

        <div className="add-article__title">
          <label className="add-article__title-label">
            Title<span>*</span>:
          </label>
          <Input
            ref={titleRef}
            error={false}
            placeholder="Title"
            type="text"
            value={line1}
            handleFocus={handleTitleTextFocus}
            handleFocusOut={handleTitleTextBlur}
            handleChange={handleLine1Change}
            maxLength={80}
          ></Input>
        </div>

        <div className="add-article__title">
          <label className="add-article__title-label">
            Article description:
          </label>
          <Input
            ref={descriptionRef}
            error={false}
            placeholder="Article description"
            type="text"
            value={line2}
            handleChange={handleLine2Change}
            maxLength={100}
          ></Input>
        </div>
        <div className="add-article__title">
          <label className="add-article__title-label">Author name:</label>
          <Input
            ref={authorRef}
            error={false}
            placeholder="Author name"
            type="text"
            value={line3}
            handleChange={handleLine3Change}
            maxLength={20}
          ></Input>
        </div>

        <div className="add-article__buttons">
          <div className="add-article__buttons--cancel">
            <Button
              variant={ButtonVariant.SECONDARY}
              label={t("common:ui.forms.cancel.label")}
              onClick={handleCancel}
            />
          </div>
          <div className="add-article__buttons--save">
            <Button
              label={t("common:ui.forms.save.label")}
              onClick={handleSave}
              disabled={!canSave}
            />
          </div>
        </div>
      </div>

      <Popup
        visibility={selectImageVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <ImageEditor
          title="Set Article Banner Image"
          image={(article && article.image) || image}
          cropConfig={{
            x: 0,
            y: 0,
            aspect: 1 / 1,
            width: 100,
            height: 100
          }}
          supportedMimes={supportedMimes}
          onSet={handleImageSetClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    </MainPageTemplate>
  );
}

AddOrUpdateArticle.propTypes = {};

export default AddOrUpdateArticle;
