import React from "react";

import PropTypes from "prop-types";

import "./Shape.scss";
import Cube from "./cube.svg?react";

const Shape = ({ size, color }) => {
  return (
    <div className="shapes">
      <div className={`shapes shapes--${color} shapes--${size}`}>
        <Cube />
      </div>
    </div>
  );
};

Shape.defaultProps = {
  color: "default",
  size: "medium"
};

Shape.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "text"]),
  size: PropTypes.oneOf(["tiny", "small", "medium"])
};

export default Shape;
