import { useCallback, useEffect, useState } from "react";

import { systemConstants } from "../constants";
import { authHeader } from "../helpers";

const baseURL = systemConstants.serverURL;

export function useFetch({ defaultValue }) {
  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchParams, setFetchParams] = useState(undefined);

  const doFetch = useCallback(url => {
    setFetchParams({ url });
  }, []);

  useEffect(() => {
    if (!fetchParams?.url) {
      return;
    }

    let isCurrent = true;

    const doFetch = async () => {
      const res = await fetch(`${baseURL}/api${fetchParams.url}`, {
        method: "GET",
        headers: authHeader()
      });

      const json = await res.json();
      if (isCurrent) {
        setData(json);
      }
    };

    setIsLoading(true);
    doFetch()
      .catch(err => {
        if (isCurrent) {
          setError(err);
        }
      })
      .finally(() => {
        if (isCurrent) {
          setIsLoading(false);
        }
      });

    return () => {
      isCurrent = false;
      setIsLoading(false);
      setError(undefined);
    };
  }, [fetchParams]);

  return { isLoading, data, error, doFetch };
}
