import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { useAuthUser } from "@shared/hooks";
import { useGetCreatableActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import { getQueryFields } from "@app/helpers/actionItemTypes.ts";
import {
  getSelectedQueryType,
  queryTypeDropdownOptions
} from "@app/helpers/actionItems";

import Form from "@components/atoms/Form/Form";

function SelectAddActionItemType(props) {
  const {
    engagementTypeId,
    projectId,
    viewScope,
    enableWebSheet,
    onChangeSelectQueryType,
    enableCopyOption
  } = props;
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const [queryTypes, setQueryTypes] = useState([]);
  const { data: creatableActionItemTypes } =
    useGetCreatableActionItemTypesQuery(
      {
        engagementTypeId,
        projectId,
        viewScope
      },
      { skip: engagementTypeId === undefined }
    );

  useEffect(() => {
    if (user && creatableActionItemTypes) {
      setQueryTypes(
        queryTypeDropdownOptions({
          actionItemTypes: creatableActionItemTypes,
          t,
          excludeWebsheets: !enableWebSheet,
          includeCopyOption: user?.isHostUser && enableCopyOption
        })
      );
    } else {
      setQueryTypes([]);
    }
  }, [user, creatableActionItemTypes, enableWebSheet, t, enableCopyOption]);

  const handleChange = useCallback(
    queryType => {
      const newQueryType = getSelectedQueryType(
        queryType,
        creatableActionItemTypes
      );
      if (newQueryType.isConfigType) {
        onChangeSelectQueryType?.({
          queryType: newQueryType,
          fields: getQueryFields({
            key: queryType.key,
            actionItemTypes: creatableActionItemTypes,
            roleType: user.xRole.type,
            actionType: "create"
          })
        });
      } else {
        onChangeSelectQueryType?.({ queryType: newQueryType, fields: [] });
      }
    },
    [creatableActionItemTypes, onChangeSelectQueryType]
  );

  return (
    <Form.Dropdown
      name={"queryType"}
      label={t(
        "requests:requests.ui.populateRequestForm.labelSelectRequestType",
        {
          requestLongNameSingular: t("requests:requests.longName")
        }
      )}
      required={true}
      items={queryTypes}
      onChange={handleChange}
      allowUndefined={false}
    />
  );
}

SelectAddActionItemType.defaultProps = {
  enableWebSheet: true,
  viewScope: systemConstants.viewScopeType.project,
  enableCopyOption: false
};

SelectAddActionItemType.propTypes = {
  engagementTypeId: PropTypes.number,
  projectId: PropTypes.number,
  viewScope: PropTypes.oneOf(Object.values(systemConstants.viewScopeType)),
  enableWebSheet: PropTypes.bool,
  onChangeSelectQueryType: PropTypes.func,
  enableCopyOption: PropTypes.bool
};

export default SelectAddActionItemType;
