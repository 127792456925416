import React, { useMemo } from "react";

import { Icon } from "@atoms/Icon";

import { DropdownItemType } from "@molecules/DropdownList";

import DropdownFilterIcon from "@components/molecules/DropdownFilterIcon/DropdownFilterIcon";

import "./DataTableDropdownSelectHeader.scss";

interface DataTableDropdownSelectHeaderProps {
  onClick: (e: unknown) => void;
  item?: DropdownItemType;
  readonly label?: string;
  readonly additionalInformation?: string;
  showMenu?: boolean;
  showFilter?: boolean;
  isFilterActive?: boolean;
}

export function DataTableDropdownSelectHeader({
  onClick,
  item,
  label,
  additionalInformation,
  showMenu,
  showFilter,
  isFilterActive
}: DataTableDropdownSelectHeaderProps) {
  function renderIcon() {
    if (showFilter) {
      return <DropdownFilterIcon active={isFilterActive} />;
    }
    return <Icon name={showMenu ? "expand_less" : "expand_more"} />;
  }

  const selectedItem = useMemo(
    () => ({
      value: item?.value,
      name: item?.label ?? item?.name ?? item?.value ?? ""
    }),
    [item]
  );

  return (
    <div
      onClick={onClick}
      id={`table-dropdown-select-${label}`}
      className="data-table-dropdown-select-header"
    >
      {!selectedItem.value && (
        <div className="data-table-dropdown-select-header--label">{label}</div>
      )}
      <div className="data-table-dropdown-select-header--selection">
        <div className="data-table-dropdown-select-header--container">
          <span className="data-table-dropdown-select--selected">
            {selectedItem.name}
          </span>
          {additionalInformation}
        </div>
        {renderIcon()}
      </div>
    </div>
  );
}
