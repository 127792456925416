import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { DropdownInput } from "@molecules/inputs";

const Dropdown = props => {
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    control
  } = useFormContext();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <DropdownInput
            label={props.label}
            items={props.items}
            ref={ref}
            value={getValues(props.name) || { name: "" }}
            description={props.description}
            onChange={newVal => {
              if (newVal && newVal.length === 0) {
                newVal = undefined;
              }
              if (newVal) clearErrors(props.name);
              onChange(newVal);
              props.onChange?.(newVal);
            }}
            onBlur={onBlur}
            disabled={
              props.disabled ||
              (props.disableWhenNoOptions &&
                !props.items?.length &&
                !getValues(props.name))
            }
            required={props.required}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            transformSelected={props.transformSelected}
            showSearch={props.showSearch}
            allowUndefined={props.allowUndefined ?? false}
            placeholder={props.placeholder}
            searchPlaceholder={props.searchPlaceholder}
            sortComparator={props.sortComparator}
            labelPosition={props.labelPosition}
            borderStyle={props.borderStyle}
            hasTooltip={props.hasTooltip}
            width={props.width}
            minWidthOverride={props.minWidthOverride}
            hideLabel={props.hideLabel}
          />
        );
      }}
    />
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ),
  defaultValue: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showSearch: PropTypes.bool,
  allowUndefined: PropTypes.bool,
  disableWhenNoOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  sortComparator: PropTypes.func,
  labelPosition: PropTypes.oneOf(["top", "left"]),
  borderStyle: PropTypes.oneOf(["default", "none"]),
  hasTooltip: PropTypes.bool,
  width: PropTypes.oneOf(["small", "medium", "large", "fill"]),
  minWidthOverride: PropTypes.number,
  hideLabel: PropTypes.bool
};

export default Dropdown;
