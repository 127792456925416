import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useStaticAppStore = create(
  devtools(
    persist(
      set => ({
        requestsDashboard: {},
        setRequestsDashboardViewType: viewType => {
          return set(state => ({
            ...state,
            requestsDashboard: {
              ...state.requestsDashboard,
              viewType
            }
          }));
        }
      }),
      {
        name: "ot-static-app-store"
      }
    ),
    {
      name: "ot-static-app-store",
      enabled: process.env.NODE_ENV === "development"
    }
  )
);
