import React, { useCallback, useEffect, useMemo, useState } from "react";

import { omit } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import wizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import Form from "@components/atoms/Form";
import { LabelPosition } from "@components/molecules/inputs/InputTemplate";

import "./WizardSidePanel.scss";

const WizardSidePanel = ({
  setIsPreCleanseFinished,
  switchCleaningMode,
  wizardEntities,
  data,
  setPreCleanFormData
}) => {
  const { t } = useTranslation();
  const [entitiesAssignedToTabs, setEntitiesAssignedToTabs] = useState({});
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    const defaultRadioValue = wizardUtilities.hasLessTabsThanEntities(
      data,
      wizardEntities
    );
    if (defaultRadioValue) {
      setDefaultValue("No");
    } else {
      setDefaultValue("");
    }
  }, [wizardEntities, data]);

  const handleSubmit = useCallback(
    formData => {
      setPreCleanFormData(formData);
      setIsPreCleanseFinished(true);
    },
    [setIsPreCleanseFinished, setPreCleanFormData]
  );

  const handleCancel = useCallback(() => {
    switchCleaningMode();
    setIsPreCleanseFinished(false);
  }, [switchCleaningMode, setIsPreCleanseFinished]);

  const schema = useMemo(() => {
    const entitySchema = wizardEntities.reduce((obj, entity) => {
      return {
        ...obj,
        [entity.name]: yup.object().when("isEntityInSeparateTabs", {
          is: value => {
            return value === "Yes";
          },
          then: () =>
            yup
              .object()
              .required(
                t(
                  "common:ui.websheet.actions.cleaningWizard.preCleanse.error.requiredTabSelection"
                )
              ),
          otherwise: () => yup.mixed().notRequired()
        })
      };
    }, {});

    return yup.object().shape({
      isEntityInSeparateTabs: yup.string().required().oneOf(["Yes", "No"]),
      ...entitySchema
    });
  }, [wizardEntities, t]);

  const radioItems = useMemo(
    () => [
      {
        value: "Yes",
        label: t(
          "common:ui.websheet.actions.saveCleaningTemplate.popup.submitLabel"
        )
      },
      {
        value: "No",
        label: t(
          "common:ui.websheet.actions.saveCleaningTemplate.popup.cancelLabel"
        )
      }
    ],
    [t]
  );

  const renderTabsNameDropdown = () => {
    const comparer = value => value === "Yes";
    return (
      <div>
        <Form.RadioGroup
          name="isEntityInSeparateTabs"
          label={t(
            "common:ui.websheet.actions.cleaningWizard.preCleanse.entitiesInDifferentTabs"
          )}
          items={radioItems}
          defaultValue={defaultValue}
        />
        <Form.ConditionalField
          fieldName="isEntityInSeparateTabs"
          valueComparer={comparer}
        >
          {wizardEntities.map(entity => {
            const items = data
              .filter(
                item =>
                  !Object.values(entitiesAssignedToTabs).includes(item.sheet)
              )
              .map(item => {
                return { name: item.sheet, id: item.sheet };
              });
            return (
              <Form.Dropdown
                key={entity.name}
                name={entity.name}
                label={entity.name}
                items={items}
                required={true}
                showSearch
                onChange={selectedTab => {
                  setEntitiesAssignedToTabs(prevEntities => {
                    const newEntities = omit(prevEntities, entity.name);
                    return selectedTab
                      ? { ...newEntities, [entity.name]: selectedTab.name }
                      : newEntities;
                  });
                }}
                placeholder={t(
                  "common:ui.websheet.actions.cleaningWizard.preCleanse.selectTab"
                )}
                allowUndefined
                labelPosition={LabelPosition.LEFT}
                disabled={!items.length && !entitiesAssignedToTabs[entity.name]}
              />
            );
          })}
        </Form.ConditionalField>
      </div>
    );
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      customFormClassName={"pre-cleanse__wizard-container"}
      submitLabel={t(
        "common:ui.websheet.actions.cleaningWizard.preCleanse.continue"
      )}
      yupSchema={schema}
      key={defaultValue}
    >
      <div>{renderTabsNameDropdown()}</div>
    </Form>
  );
};

WizardSidePanel.propTypes = {
  setIsPreCleanseFinished: PropTypes.func.isRequired,
  switchCleaningMode: PropTypes.func.isRequired,
  wizardEntities: PropTypes.array,
  data: PropTypes.array,
  setPreCleanFormData: PropTypes.func
};

WizardSidePanel.displayName = "WizardSidePanel";

export default WizardSidePanel;
