import React, { useMemo } from "react";

import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

import { useDataTable } from "@app/hooks/useDataTable";

import { Icon, IconColor, IconSize } from "@atoms/Icon";

import DataTable from "@components/molecules/DataTable";

import "./SplitColumnModalTables.scss";

const SplitColumnModalTables = ({ afterData, beforeData }) => {
  const { createColumn } = useDataTable();
  const { t } = useTranslation();

  const beforeColumns = useMemo(() => {
    return [
      createColumn({
        accessor: "before",
        width: 320,
        disableSortBy: true
      })
    ];
  }, [createColumn]);

  const afterColumns = useMemo(() => {
    if (isNil(afterData[0]?.afterLeft) && isNil(afterData[0]?.afterRight)) {
      return beforeColumns;
    }
    return [
      createColumn({
        accessor: "afterLeft",
        width: 160,
        disableSortBy: true
      }),
      createColumn({
        accessor: "afterRight",
        width: 160,
        disableSortBy: true
      })
    ];
  }, [afterData, beforeColumns, createColumn]);

  return (
    <div className="split-column-modal-tables">
      <DataTable
        title={t(
          "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiter.before.label"
        )}
        columns={beforeColumns}
        hideHeaders={true}
        data={beforeData}
      />
      {
        <Icon
          name="keyboard_arrow_right"
          color={IconColor.ACCENT}
          size={IconSize.L}
        />
      }
      <DataTable
        title={t(
          "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiter.after.label"
        )}
        hideHeaders={true}
        columns={afterColumns}
        data={afterData}
      />
    </div>
  );
};
export default SplitColumnModalTables;
