import React, { useCallback, useEffect, useMemo, useState } from "react";

import lodash from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";

import { ActionDropdown } from "@molecules/ActionDropdown";

import Form from "@components/atoms/Form/Form.jsx";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert.jsx";
import PopupOverlay from "@components/atoms/PopupOverlay/PopupOverlay.jsx";
import DangerModal from "@components/molecules/DangerModal/DangerModal.jsx";
import ModalForm from "@components/molecules/ModalForm/index.js";
import UploadFile from "@components/molecules/UploadFile/UploadFile.jsx";
import NewsMastheadImage from "@components/organisms/News/NewsMastheadImage.jsx";
import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate.jsx";

import "./EditableNewsMasthead.scss";

const menuItemsNames = Object.freeze({
  replaceImage: "EDIT",
  deleteImage: "DELETE"
});

const addFilesState = systemConstants.addFiles.state;
const baseURL = systemConstants.serverURL;

const EditableNewsMasthead = props => {
  const {
    engagementTypeId,
    newsConfiguration,
    deleteImage,
    newsMasthead,
    uploadHooks,
    menuActions
  } = props;
  const { t } = useTranslation();
  const [mastheadImage, setMastheadImage] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showReplaceConfirmation, setShowReplaceConfirmation] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [dropErrorMessage, setDropErrorMessage] = useState(null);
  const [recentlyDroppedFiles, setRecentlyDroppedFiles] = useState([]);
  const [uploadState, setUploadState] = useState(addFilesState.add);

  // NB: these defaults are fallbacks if configuration don't provide them
  const defaultMessageStrings = useMemo(
    () => ({
      title: t("common:ui.newsImage.edit.label"),
      imageUploadControl: {
        label: t("common:ui.newsImage.upload.label"),
        promptToDropFiles: t("common:ui.newsImage.uploadPrompt.label"),
        imageRequiredError: t("common:ui.newsImage.upload.error.required"),
        tooManyFilesError: t("common:ui.newsImage.upload.error.tooManyFiles"),
        invalidFileTypeError: t("common:ui.newsImage.upload.error.invalid")
      },
      deleteConfirmation: {
        title: t("common:ui.newsImage.delete.label"),
        text: t("common:ui.newsImage.delete.confirmation.label")
      },
      replaceConfirmation: {
        title: t("common:ui.newsImage.replace.label"),
        text: t("common:ui.newsImage.replace.confirmation.label")
      }
    }),
    [t]
  );

  const messageStrings = useMemo(() => {
    const mastheadConfig = newsConfiguration?.masthead ?? {};
    return lodash.defaultsDeep({}, mastheadConfig, defaultMessageStrings);
  }, [defaultMessageStrings, newsConfiguration]);

  useEffect(() => {
    if (!newsMasthead?.assetName) {
      setMastheadImage(null);
    } else {
      const imageUrl = `${baseURL}/api/hostBrands/assets/${window.location.host}/${newsMasthead?.assetName}?v=${newsMasthead.lastUpdated}`;
      setMastheadImage(imageUrl);
    }
  }, [newsMasthead]);

  const handleMenuItemClicked = menuItem => {
    switch (menuItem.action) {
      case menuItemsNames.deleteImage: {
        setShowDeleteConfirmation(true);
        break;
      }
      case menuItemsNames.replaceImage: {
        setShowReplaceConfirmation(true);
        setRecentlyDroppedFiles([]);
        setUploadState(addFilesState.add);
        uploadHooks.clearUploadsCompleted();
      }
    }
  };

  const startUploadWorkflow = useCallback(() => {
    setShowUploadImage(true);
  }, []);

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setMastheadImage(null);
    deleteImage({ engagementTypeId });
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleReplaceConfirmation = () => {
    setShowReplaceConfirmation(false);
    startUploadWorkflow();
  };
  const handleCancelReplace = () => {
    setShowReplaceConfirmation(false);
  };

  const handleSubmitImage = () => {
    setUploadState(addFilesState.upload);
  };
  const handleUploadCompletion = useCallback(() => {
    setUploadState(addFilesState.finished);
    uploadHooks.clearUploadsCompleted();
    setShowUploadImage(false);
    setDropErrorMessage(null);
  }, [uploadHooks]);
  const handleUploadFailure = () => {
    setUploadState(addFilesState.add);
  };
  const handleUploadCancel = () => {
    setShowUploadImage(false);
  };

  const handleDropRejection = useCallback(
    filesRejected => {
      const rejectReasons = new Set();
      filesRejected.forEach(f => {
        f.errors.forEach(e => {
          rejectReasons.add(e.code);
        });
      });
      if (rejectReasons.has("too-many-files")) {
        setDropErrorMessage(
          messageStrings.imageUploadControl.tooManyFilesError
        );
      } else if (rejectReasons.has("file-invalid-type")) {
        setDropErrorMessage(
          messageStrings.imageUploadControl.invalidFileTypeError
        );
      }
    },
    [messageStrings]
  );
  const handleDropSuccess = useCallback(
    filesAccepted => {
      if (filesAccepted.length !== 1) {
        return;
      }
      setRecentlyDroppedFiles([filesAccepted[0]]);
      startUploadWorkflow();
    },
    [startUploadWorkflow]
  );

  return (
    <BoxTemplate
      boxClassName="editable-news-image"
      title={messageStrings.title}
      size="medium"
      action={
        mastheadImage && (
          <ActionDropdown
            positionRelative={true}
            menuItems={menuActions}
            onMenuItemClick={handleMenuItemClicked}
          />
        )
      }
    >
      {mastheadImage ? (
        <NewsMastheadImage
          imageAlt="masthead image"
          imageSrc={mastheadImage}
        ></NewsMastheadImage>
      ) : (
        <>
          <UploadFile
            handleDrop={handleDropSuccess}
            maxNumberOfFiles={1}
            supportedDocumentMimes={["png"]}
            dropMessage={messageStrings.imageUploadControl.promptToDropFiles}
            handleRejection={handleDropRejection}
          ></UploadFile>
          {dropErrorMessage && (
            <InlineAlert type="error" message={dropErrorMessage}></InlineAlert>
          )}
        </>
      )}

      <PopupOverlay
        isVisible={showDeleteConfirmation}
        isModal={true}
        showClose={false}
        width={"60rem"}
      >
        <DangerModal
          title={messageStrings.deleteConfirmation.title}
          content={messageStrings.deleteConfirmation.text}
          handleConfirmation={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        ></DangerModal>
      </PopupOverlay>

      <PopupOverlay
        isVisible={showReplaceConfirmation}
        isModal={true}
        showClose={false}
        width={"60rem"}
      >
        <DangerModal
          title={messageStrings.replaceConfirmation.title}
          content={messageStrings.replaceConfirmation.text}
          handleConfirmation={handleReplaceConfirmation}
          onCancel={handleCancelReplace}
        ></DangerModal>
      </PopupOverlay>

      <PopupOverlay
        isVisible={showUploadImage}
        isModal={true}
        showClose={true}
        width={"60rem"}
      >
        <ModalForm
          boxClassName="replace-image"
          title={t("common:ui.newsImage.uploadReplacement.label")}
          submitLabel={t("common:ui.forms.submit.label")}
          cancelLabel={t("common:ui.forms.cancel.label")}
          handleSubmit={handleSubmitImage}
          handleCancel={handleUploadCancel}
          yupSchema={yup.object({
            newsImage: yup
              .object()
              .test(
                "newsImage",
                messageStrings.imageUploadControl.imageRequiredError,
                val => (val ? Object.values(val).length > 0 : false)
              )
          })}
        >
          <Form.UploadImages
            key="newsImage"
            name="newsImage"
            label={messageStrings.imageUploadControl.label}
            supportedDocumentMimesMessage={
              messageStrings.imageUploadControl.invalidFileTypeError
            }
            dropMessage={messageStrings.imageUploadControl.promptToDropFiles}
            maxNumberOfFilesError={
              messageStrings.imageUploadControl.tooManyFilesError
            }
            maxNumberOfFiles={1}
            initialAddFiles={recentlyDroppedFiles}
            uploadHooks={uploadHooks}
            engagementTypeId={engagementTypeId}
            state={uploadState}
            onUploadsComplete={handleUploadCompletion}
            onUploadsFailed={handleUploadFailure}
          ></Form.UploadImages>
        </ModalForm>
      </PopupOverlay>
    </BoxTemplate>
  );
};

EditableNewsMasthead.defaultProps = {};

EditableNewsMasthead.propTypes = {
  engagementTypeId: PropTypes.number.isRequired,
  newsConfiguration: PropTypes.shape({
    masthead: PropTypes.object
  }),
  deleteImage: PropTypes.func,
  newsMasthead: PropTypes.shape({
    assetName: PropTypes.string,
    lastUpdated: PropTypes.number
  }).isRequired,
  uploadHooks: PropTypes.shape({
    uploadImageFiles: PropTypes.func,
    isUploading: PropTypes.bool,
    uploadsFailed: PropTypes.bool,
    uploadsCompleted: PropTypes.bool,
    uploadError: PropTypes.any,
    getUploadFileState: PropTypes.func,
    clearUploadsCompleted: PropTypes.func
  }).isRequired,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default EditableNewsMasthead;
