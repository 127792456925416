import { manageExcelDocumentConstants } from "../constants";

const initialState = {
  download: {
    loading: false,
    downloaded: null,
    error: ""
  },
  update: {
    loading: false,
    success: false,
    error: ""
  },
  lock: {
    loading: false,
    fileLock: null,
    error: ""
  }
};

export function manageExcelDocument(state = initialState, action = {}) {
  switch (action.type) {
    case manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_REQUEST:
      return {
        ...state,
        previousDownloaded: state.download.downloaded,
        download: {
          ...state.download,
          downloaded: null,
          selectedTabIndex: null,
          error: "",
          loading: true
        }
      };
    case manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_SUCCESS:
      if (action.tab) {
        //when it is a tab, we need to update the json object
        const tabIndex = state.previousDownloaded.json.findIndex(
          s => s.sheet === action.tab
        );
        const newState = structuredClone(state);
        newState.download.downloaded = newState.previousDownloaded;
        delete newState.previousDownloaded;
        newState.download.downloaded.json[tabIndex] = action.document.json[0];
        newState.download.loading = false;
        newState.download.error = "";
        newState.download.selectedTabIndex = tabIndex;
        return newState;
      }
      return {
        ...state,
        download: {
          ...state.download,
          loading: false,
          downloaded: action.document,
          selectedTabIndex: null,
          error: ""
        }
      };
    case manageExcelDocumentConstants.DOWNLOAD_EXCEL_DOCUMENT_FAILURE:
      return {
        ...state,
        download: {
          ...state.download,
          loading: false,
          downloaded: null,
          selectedTabIndex: null,
          error: action.error.key ?? action.error.message
        }
      };
    case manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_REQUEST:
    case manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_REQUEST:
      return {
        ...state,
        lock: {
          ...state.lock,
          loading: true
        },
        update: {
          ...state.update,
          success: false
        }
      };
    case manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_SUCCESS:
    case manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_SUCCESS:
      return {
        ...state,
        lock: {
          ...state.lock,
          loading: false,
          fileLock: action.lock,
          error: ""
        }
      };
    case manageExcelDocumentConstants.ACQUIRE_LOCK_EXCEL_DOCUMENT_FAILURE:
    case manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_FAILURE:
    case manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_FAILURE:
      return {
        ...state,
        lock: {
          ...state.lock,
          loading: false,
          fileLock: null,
          error: action.error.key ?? action.error.message
        }
      };
    case manageExcelDocumentConstants.REFRESH_LOCK_EXCEL_DOCUMENT_REQUEST:
      return {
        ...state,
        lock: {
          ...state.lock,
          loading: true
        }
      };
    case manageExcelDocumentConstants.RELEASE_LOCK_EXCEL_DOCUMENT_SUCCESS:
      return {
        ...state,
        lock: {
          ...state.lock,
          loading: false,
          fileLock: null,
          error: ""
        }
      };
    case manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          success: false,
          error: ""
        }
      };
    case manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
          error: ""
        }
      };
    case manageExcelDocumentConstants.UPDATE_EXCEL_DOCUMENT_FAILURE:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          error: action.error.key ?? action.error.message
        }
      };
    case manageExcelDocumentConstants.RESET_DOWNLOAD_EXCEL_DOCUMENT_DOWNLOAD:
      return {
        ...state,
        download: {
          ...initialState.download
        }
      };
    default:
      return state;
  }
}
