import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { Switchers } from "@molecules/DataViewSwitcher";

export const useAppStore = create(
  devtools(
    persist(
      set => ({
        projectsDashboard: {
          viewType: Switchers.GRID
        },
        staticState: {
          requestsDashboard: {}
        },
        requestsDashboardFilters: {},
        setProjectsDashboardViewType: viewType =>
          set(state => ({
            ...state,
            projectsDashboard: {
              ...state.projectsDashboard,
              viewType
            }
          })),

        setRequestsDashboardViewType: viewType => {
          return set(state => ({
            ...state,
            staticState: {
              ...state.staticState,
              requestsDashboard: {
                ...state.staticState.requestsDashboard,
                viewType
              }
            }
          }));
        },

        setRequestsDashboardFilters: (projectId, filters) =>
          set(state => ({
            ...state,
            requestsDashboardFilters: {
              [projectId]: filters
                ? {
                    ...state.requestsDashboardFilters[projectId],
                    filters
                  }
                : undefined
            }
          })),

        clearOtherRequestsDashboardFilters: projectId => {
          if (!projectId) {
            return;
          }
          set(state => ({
            ...state,
            requestsDashboardFilters: {
              [projectId]: {
                ...state.requestsDashboardFilters?.[projectId]
              }
            }
          }));
        }
      }),
      {
        name: "ot-app-store"
      }
    ),
    { name: "ot-app-store", enabled: process.env.NODE_ENV === "development" }
  )
);
