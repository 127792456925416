import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";
import { systemConstants } from "@shared/constants/systemConstants";

import usePubSub from "./usePubSub";

export function useShareStatus(project) {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);
  const [finalPackageShareStatus, setFinalPackageShareStatus] = useState(
    finalPackage.finalPackageShareStatus
  );
  const [
    isFinalPackageShareStatusInitialised,
    setFinalPackageShareStatusInitialised
  ] = useState(finalPackage.isFinalPackageShareStatusInitialised);
  const [finalPackageShareStatusError, setFinalPackageShareStatusError] =
    useState(finalPackage.finalPackageShareStatusError);
  const [currentProject, setShareStatusProject] = useState(project);
  const refreshProjectShareStatus = usePubSub();

  const subscribeProjectShareStatus = useCallback(() => {
    refreshProjectShareStatus.subscribe(
      systemConstants.project.events.projects.refreshProjectShareStatus
    );
  }, [refreshProjectShareStatus]);

  const getShareStatus = useCallback(() => {
    if (
      currentProject?.id &&
      currentProject?.configuration?.features?.finalPackage?.enabled
    ) {
      dispatch(
        finalPackageActions.getFinalPackageShareStatus(currentProject.id)
      );
    }
  }, [currentProject, dispatch]);

  useEffect(() => {
    if (
      currentProject?.id &&
      refreshProjectShareStatus.value?.projectId === currentProject?.id
    ) {
      setFinalPackageShareStatus(refreshProjectShareStatus.value.message);
      getShareStatus(); // we need that to update the redux state as well
    }
  }, [
    refreshProjectShareStatus.value,
    setFinalPackageShareStatus,
    getShareStatus,
    currentProject?.id
  ]);

  const setShareStatus = useCallback(
    shareStatus => {
      if (currentProject?.id) {
        dispatch(
          finalPackageActions.setFinalPackageShareStatus({
            shareStatus,
            projectId: currentProject?.id
          })
        );
      }
    },
    [currentProject?.id, dispatch]
  );

  useEffect(() => {
    setFinalPackageShareStatus(finalPackage.finalPackageShareStatus);
    setFinalPackageShareStatusInitialised(
      finalPackage.isFinalPackageShareStatusInitialised
    );
    setFinalPackageShareStatusError(finalPackage.finalPackageShareStatusError);
  }, [finalPackage]);

  return {
    finalPackageShareStatusError,
    finalPackageShareStatus,
    isFinalPackageShareStatusInitialised,
    subscribeProjectShareStatus,
    getShareStatus,
    setShareStatus,
    setShareStatusProject
  };
}
