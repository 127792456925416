import React, { PropsWithChildren, useMemo } from "react";

import { useWindowSize } from "@shared/hooks";

import { Inline, Stack } from "@fermions";

import "./InputContainer.scss";

interface InputContainerProps {
  maxColumns?: number;
  isInlined?: boolean;
  overrideMinWidths?: {
    [700]?: string;
    [900]?: string;
    [1200]?: string;
    ["default"]: string;
  };
}

export const InputContainer = ({
  maxColumns = 3,
  overrideMinWidths,
  children,
  isInlined = true
}: PropsWithChildren<InputContainerProps>) => {
  const { width: windowWidth } = useWindowSize();

  const minWidth = useMemo(() => {
    if (windowWidth < 700) {
      return overrideMinWidths?.[700] ?? "100%";
    }
    if (windowWidth < 900) {
      return overrideMinWidths?.[900] ?? `${100 / 2 - 1}%`;
    }
    if (windowWidth < 1200) {
      return overrideMinWidths?.[1200] ?? `${100 / (maxColumns - 1) - 1}%`;
    }
    return overrideMinWidths?.["default"] ?? `${100 / maxColumns - 1}%`;
  }, [maxColumns, overrideMinWidths, windowWidth]);
  return isInlined ? (
    <Inline
      className="input-container"
      style={{
        gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}, ${"1fr"}))`,
        alignItems: "baseline"
      }}
    >
      {children}
    </Inline>
  ) : (
    <Stack>{children}</Stack>
  );
};
