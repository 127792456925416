import { useEffect, useRef } from "react";

import { useDispatch } from "react-redux";

import { systemConstants } from "@shared/constants/systemConstants";
import { utilities } from "@shared/helpers";
import usePubSub from "@shared/hooks/usePubSub";
import {
  newsServiceUtil,
  useAddNewsItemMutation,
  useDeleteNewsItemMutation,
  useUpdateNewsItemMutation
} from "@shared/services/newsService";

function formatNewsItemFields(newsItemFields) {
  return {
    ...newsItemFields,
    publicationDate: utilities
      .endOfDay(newsItemFields.publicationDate)
      .toISOString()
      .substring(0, 10)
  };
}

export function useNews() {
  const [addNewsItemQuery, { isLoading: isAdding }] = useAddNewsItemMutation();
  const [updateNewsItemQuery, { isLoading: isUpdating }] =
    useUpdateNewsItemMutation();
  const [deleteNewsItemQuery, { isLoading: isDeleting }] =
    useDeleteNewsItemMutation();

  const dispatch = useDispatch();

  const refreshNewsState = usePubSub();
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      refreshNewsState.subscribe(
        systemConstants.engagementType.events.refreshNewsItem
      );
      didMount.current = true;
    }
  }, [refreshNewsState]);

  useEffect(() => {
    if (refreshNewsState.value?.type === "news") {
      dispatch(newsServiceUtil.invalidateTags(["NewsItems"]));
    }
  }, [dispatch, refreshNewsState.value]);

  const addNewsItem = async ({ fields, engagementTypeId, updatedAt }) => {
    return addNewsItemQuery({
      engagementTypeId,
      newsItem: {
        fields: formatNewsItemFields(fields),
        engagementTypeId
      },
      updatedAt
    }).unwrap();
  };

  const updateNewsItem = async ({
    currentNewsItem,
    fields,
    engagementTypeId,
    updatedAt
  }) => {
    if (!currentNewsItem) {
      return;
    }
    return updateNewsItemQuery({
      engagementTypeId,
      newsItem: {
        fields: formatNewsItemFields(fields),
        id: currentNewsItem.id,
        engagementTypeId
      },
      updatedAt
    }).unwrap();
  };

  const deleteNewsItem = async ({
    engagementTypeId,
    currentNewsItem,
    updatedAt
  }) => {
    if (!currentNewsItem) {
      return;
    }
    return deleteNewsItemQuery({
      engagementTypeId,
      newsId: currentNewsItem.id,
      updatedAt
    }).unwrap();
  };

  const canBeDisplayed = newsItem =>
    new Date(newsItem.fields.publicationDate) <=
    new Date(new Date().setHours(23, 59, 59, 59));

  return {
    canBeDisplayed,
    addNewsItem,
    updateNewsItem,
    deleteNewsItem,
    isNewsAlterLoading: isDeleting || isUpdating || isAdding
  };
}
