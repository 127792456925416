import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";

import { getErrorMessage } from "@app/helpers/error";
import { RTKError } from "@app/types";

import { Button } from "@atoms/Button";

import DeleteUserRole from "@components/organisms/DeleteUserRole";
import PageTemplate from "@components/templates/PageTemplate";

import "./ManageUserRolesTemplate.scss";

type Props = {
  rolesTable: JSX.Element;
  canAddRole: boolean;
  handleClickAddRole: () => void;
  isLoading: boolean;
  selectedRoleId?: number;
  showDeleteModal: boolean;
  handleClickDelete: (roleId?: number) => void;
  handleCloseDeleteModal: () => void;
  error: RTKError;
  deleteError: RTKError;
};

const ManageUserRolesTemplate = (props: Props) => {
  const { t } = useTranslation();
  const {
    rolesTable,
    canAddRole,
    handleClickAddRole,
    isLoading,
    showDeleteModal,
    selectedRoleId,
    handleClickDelete,
    handleCloseDeleteModal,
    error,
    deleteError
  } = props;

  const headingActions = () => {
    if (!canAddRole) {
      return <></>;
    }
    return (
      <div>
        <Button
          onClick={handleClickAddRole}
          iconName="add"
          label={t("admin:ui.manageUserRoles.action.addRole")}
        />
      </div>
    );
  };

  const bodyContent = useMemo(() => {
    return <div className="user-role-permissions__body">{rolesTable}</div>;
  }, [rolesTable]);

  return (
    <>
      <PageTemplate
        header={{
          title: t("admin:ui.manageUserRoles.title"),
          actions: headingActions(),
          alwaysShowBreadcrumbs: true
        }}
        body={{
          primary: bodyContent
        }}
        other={{
          loading: isLoading ? t("common:loading") : null,
          error: getErrorMessage(error, t)
        }}
      />
      {showDeleteModal && selectedRoleId && (
        <Popup visibility={showDeleteModal} width="60rem">
          <DeleteUserRole
            roleId={selectedRoleId}
            error={deleteError}
            onCancel={handleCloseDeleteModal}
            onDelete={handleClickDelete}
          />
        </Popup>
      )}
    </>
  );
};

export default ManageUserRolesTemplate;
