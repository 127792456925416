import websheetUtilities from "../websheetUtilities";

/**
 * Executes the formula(s) and returns the result(s)
 * @param {*} formulae array of formulae to evaluate
 * @param {*} hyperFormulaRef reference to the hyperformula instance
 * @param {*} currentSheetId HOT sheet id from where the run the formula
 * @returns array of results
 */
const getSource = (formulae, hyperFormulaRef, currentSheetId = 0) => {
  const hf = hyperFormulaRef.current;
  const results = formulae.map(
    f => hf?.calculateFormula(`=${f}`, currentSheetId) ?? formulae
  );
  return results;
};

const makeRule = (operator, overrides) => ({
  operator,
  getSource,
  evaluate: (_, [source]) => source,
  ...overrides
});

const cellIs = [
  makeRule("greaterThanOrEqual", {
    evaluate: (value, [source]) => value >= source
  }),
  makeRule("greaterThan", {
    evaluate: (value, [source]) => value > source
  }),
  makeRule("lessThanOrEqual", {
    evaluate: (value, [source]) => value <= source
  }),
  makeRule("lessThan", {
    evaluate: (value, [source]) => value < source
  }),
  makeRule("equal", {
    evaluate: (value, [source]) => value == source
  }),
  makeRule("notEqual", {
    evaluate: (value, [source]) => value != source
  }),
  makeRule("between", {
    evaluate: (value, [low, high]) => low <= value && value <= high
  }),
  makeRule("notBetween", {
    evaluate: (value, [low, high]) => !(low <= value && value <= high)
  })
];

const containsText = [
  makeRule("containsText"),
  makeRule("containsBlanks"),
  makeRule("notContainsBlanks")
];

const notContainsText = [makeRule("notContains")];
const beginsWith = [makeRule("beginsWith")];
const endsWith = [makeRule("endsWith")];

const timePeriod = [
  makeRule("today", {
    getSource: (_, hyperFormulaRef) => {
      return getSource(["TODAY()"], hyperFormulaRef);
    },
    evaluate: (value, [source]) => value === source
  }),
  makeRule("yesterday", {
    getSource: (_, hyperFormulaRef) => {
      return getSource(["TODAY() - 1"], hyperFormulaRef);
    },
    evaluate: (value, [source]) => value === source
  }),
  makeRule("tomorrow", {
    getSource: (_, hyperFormulaRef) => {
      return getSource(["TODAY() + 1"], hyperFormulaRef);
    },
    evaluate: (value, [source]) => value === source
  })
  // others not yet supported
  // last7Days
  // thisWeek
  // lastWeek
  // nextWeek
  // thisMonth
  // lastMonth
  // nextMonth
];

const expression = [
  {
    getSource: getSource,
    evaluate: (value, [source]) => source
  }
];

const uniqueValues = [
  {
    getSource: (cellAddress, hyperFormulaRef, hfSheetId) => {
      const { start, end } = cellAddress;
      const rangeStart = {
        sheet: hfSheetId,
        col: start.col,
        row: start.row
      };
      const rangeEnd = {
        sheet: hfSheetId,
        col: end.col,
        row: end.row
      };
      const rangeValues = hyperFormulaRef.current.getRangeValues({
        start: rangeStart,
        end: rangeEnd
      });
      return [rangeValues.flat()];
    },
    evaluate: (value, [source]) => {
      const stringValues = source.map(v => v.toString());
      return stringValues.filter(v => v === value.toString()).length === 1;
    }
  }
];

const duplicateValues = [
  {
    getSource: (cellAddress, hyperFormulaRef, hfSheetId) => {
      const { start, end } = cellAddress;
      const rangeStart = {
        sheet: hfSheetId,
        col: start.col,
        row: start.row
      };
      const rangeEnd = {
        sheet: hfSheetId,
        col: end.col,
        row: end.row
      };
      const rangeValues = hyperFormulaRef.current.getRangeValues({
        start: rangeStart,
        end: rangeEnd
      });
      return [rangeValues.flat()];
    },
    evaluate: (value, [source]) => {
      const stringValues = source.map(v => v.toString());
      return stringValues.filter(v => v === value.toString()).length > 1;
    }
  }
];

const top10 = [
  makeRule("top10", {
    getSource: (cellAddress, hyperFormulaRef, hfSheetId, cellMeta) => {
      const { start, end } = cellAddress;
      const rangeStart = { sheet: hfSheetId, col: start.col, row: start.row };
      const rangeEnd = { sheet: hfSheetId, col: end.col, row: end.row };

      const rangeSerialized = hyperFormulaRef.current.getRangeSerialized({
        start: rangeStart,
        end: rangeEnd
      });

      const numericValues = rangeSerialized
        .flat()
        .map(value => {
          if (Date.parse(value) && cellMeta.type === "date") {
            return Math.round(
              websheetUtilities.dateToSerialNumber(new Date(value))
            );
          } else if (typeof value === "string") {
            return parseFloat(value);
          }
          return value;
        })
        .filter(v => !isNaN(v));
      const sortedValues = numericValues.sort((a, b) => b - a);
      return [sortedValues];
    },
    evaluate: (value, [sortedValues], ruleOptions, cellMeta) => {
      if (isNaN(parseFloat(value))) {
        return false;
      }
      const { rank } = ruleOptions;
      const isBottom = ruleOptions?.bottom === true;

      const formattedValues = (() => {
        if (cellMeta.type !== "date") {
          return sortedValues;
        }
        // for dates, 'lowest' is oldest'
        return sortedValues.reverse().map(v => {
          if (typeof v === "string") {
            return websheetUtilities.dateToSerialNumber(new Date(v));
          }
          return v;
        });
      })();

      const relevantValues = isBottom
        ? formattedValues.slice(-rank)
        : formattedValues.slice(0, rank);

      return relevantValues.includes(parseFloat(value));
    }
  })
];

const aboveAverage = [
  {
    getSource: (cellAddress, hyperFormulaRef, hfSheetId) => {
      const { start, end } = cellAddress;
      const rangeStart = { sheet: hfSheetId, col: start.col, row: start.row };
      const rangeEnd = { sheet: hfSheetId, col: end.col, row: end.row };
      const rangeValues = hyperFormulaRef.current.getRangeValues({
        start: rangeStart,
        end: rangeEnd
      });

      const numericValues = rangeValues
        .flat()
        .filter(v => !isNaN(parseFloat(v)));
      const average =
        numericValues.reduce((acc, val) => acc + val, 0) / numericValues.length;

      return [average];
    },
    evaluate: (value, [average], ruleOptions) => {
      if (isNaN(parseFloat(value))) {
        return false;
      }

      const isAboveAverage = ruleOptions.aboveAverage !== false;
      return isAboveAverage ? value > average : value < average;
    }
  }
];

const websheetRules = {
  cellIs,
  containsText,
  notContainsText,
  beginsWith,
  endsWith,
  expression,
  uniqueValues,
  duplicateValues,
  timePeriod,
  top10,
  aboveAverage
};

export const getRuleOperator = (type, options) => {
  if (type === "timePeriod") {
    const { timePeriod } = options;
    const ruleOperator = websheetRules[type].find(
      r => r.operator === timePeriod
    );
    return ruleOperator;
  }

  if (
    [
      "uniqueValues",
      "duplicateValues",
      "expression",
      "top10",
      "aboveAverage"
    ].includes(type)
  ) {
    return websheetRules[type][0];
  }

  if (
    [
      "cellIs",
      "containsText",
      "notContainsText",
      "beginsWith",
      "endsWith"
    ].includes(type)
  ) {
    const { operator } = options;
    const ruleOperator = websheetRules[type].find(r => r.operator === operator);
    return ruleOperator;
  }

  return null;
};

/**
 * @param {*} value value of the cell
 * @param {string} cellType (expected) formatting/data type of the cell
 * @returns For dates this will convert them into the serial number format used by HyperFormula
 */
function getFormattedValueForCell(value, cellType) {
  if (cellType === "date") {
    if (typeof value === "number") {
      return value;
    } else {
      return websheetUtilities.dateToSerialNumber(new Date(value));
    }
  }
  return value;
}

/**
 *
 * @param {object} options
 * @param {string} options.ref A1 reference specifying (the range of) cells which the rule applies to
 * @param {object} options.rule The rule object
 * @param {string} options.rule.type The type of the rule
 * @param {string} options.rule.formulae The formulae of the rule
 * @param {*} options.cellValue The value of the cell
 * @param {object} options.cellMeta The metadata of the cell
 * @param {string} options.cellMeta.hyperFormulaRef reference to the hyperformula instance
 * @param {string} options.cellMeta.hfSheetId The hyperformula sheetId the cell resides in
 * @param {string} options.cellMeta.type The type of the cell
 * @param {number} options.rowIdx The row index of the cell
 * @param {number} options.colIdx The column index of the cell
 * @returns TRUE if the rule condition is satisfied, FALSE otherwise
 */
export const isRuleConditionSatisfied = ({
  ref,
  rule,
  cellValue,
  cellMeta,
  rowIdx,
  colIdx
}) => {
  const hfSheetId = cellMeta.hfSheetId;
  const { type, formulae, ...ruleOptions } = rule;
  const ruleOperator = getRuleOperator(type, ruleOptions);
  if (!ruleOperator) {
    return false;
  }

  const formattedValue = getFormattedValueForCell(cellValue, cellMeta.type);

  if (type === "timePeriod") {
    const source = ruleOperator.getSource?.(
      null,
      cellMeta.hyperFormulaRef,
      hfSheetId
    );
    const result = ruleOperator.evaluate(formattedValue, source);
    return result;
  }

  if (
    ["uniqueValues", "duplicateValues", "top10", "aboveAverage"].includes(type)
  ) {
    const cellAddress = getCellRangeFromString(
      ref,
      cellMeta.hyperFormulaRef,
      hfSheetId
    );
    const source = ruleOperator.getSource?.(
      cellAddress,
      cellMeta.hyperFormulaRef,
      hfSheetId,
      cellMeta
    );
    const result = ruleOperator.evaluate(
      formattedValue,
      source,
      ruleOptions,
      cellMeta
    );
    return result;
  }

  // formulae string contains reference in A1 format to top-left corner of the ref
  // need to transform that reference into the A1 format of the current cell
  const formulaeInTarget = mapFormulaCellReferencesToCurrentCell(
    formulae,
    ref,
    { colIdx, rowIdx },
    hfSheetId,
    cellMeta.hyperFormulaRef
  );

  const source = ruleOperator.getSource?.(
    formulaeInTarget,
    cellMeta.hyperFormulaRef,
    hfSheetId
  );

  const result = ruleOperator.evaluate(formattedValue, source);
  return result;
};

export const isCellCoveredByRange = (
  rowCol,
  rangeOrAddress,
  hyperFormulaRef,
  hfSheetId
) => {
  const cellRange = getCellRangeFromString(
    rangeOrAddress,
    hyperFormulaRef,
    hfSheetId
  );
  if (!cellRange) {
    return false;
  }
  const { start, end } = cellRange;
  return (
    start.row <= rowCol.row &&
    rowCol.row <= end.row &&
    start.col <= rowCol.col &&
    rowCol.col <= end.col
  );
};

function getCellRangeFromString(rangeString, hyperFormulaRef, hfSheetId) {
  try {
    const r = hyperFormulaRef.current.simpleCellRangeFromString(
      rangeString,
      hfSheetId
    );
    if (r) {
      return r;
    }
  } catch (e) {}

  try {
    const c = hyperFormulaRef.current.simpleCellAddressFromString(
      rangeString,
      hfSheetId
    );
    if (c) {
      return {
        start: c,
        end: c
      };
    }
  } catch (e) {}

  return null;
}

function mapFormulaCellReferencesToCurrentCell(
  formulae,
  cellRange,
  { colIdx, rowIdx },
  hfSheetId,
  hyperFormulaRef
) {
  const refCell = getCellRangeFromString(cellRange, hyperFormulaRef, hfSheetId);
  const refCellAddress = {
    sheet: hfSheetId,
    col: refCell.start.col,
    row: refCell.start.row
  };
  const refCellA1 = hyperFormulaRef.current.simpleCellAddressToString(
    refCellAddress,
    hfSheetId
  );

  const targetCellAddress = { sheet: hfSheetId, col: colIdx, row: rowIdx };
  const targetCellA1 = hyperFormulaRef.current.simpleCellAddressToString(
    targetCellAddress,
    hfSheetId
  );

  const updatedFormulae = formulae.map(f =>
    f.replaceAll(refCellA1, targetCellA1)
  );

  return updatedFormulae;
}
