import React, { useCallback } from "react";

import { classNames } from "@app/helpers/componentHelpers.js";

import { Icon, IconFillStyle } from "@atoms/Icon/index.ts";

import { useWorkflowCanvas } from "./WorkflowCanvasContext.tsx";
import {
  addWorkflowStep,
  createWorkflowStep,
  generateNodeKey
} from "./workflowCanvas.ts";

interface WorkflowDividerProps {
  id: string;
  isEditable: boolean;
}

export const WorkflowDivider = (props: WorkflowDividerProps) => {
  const context = useWorkflowCanvas();
  const {
    hoveredDividerId,
    setHoveredDividerId,
    workflowConfig,
    setWorkflowConfig,
    setSelectedLaneId,
    setSelectedNodeId
  } = context;
  const { id, isEditable } = props;
  const isHovered = isEditable && id === hoveredDividerId;

  const handleMouseEnterDivider = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setHoveredDividerId(id);
    },
    [setHoveredDividerId, id]
  );

  const handleMouseLeaveDivider = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setHoveredDividerId(undefined);
    },
    [setHoveredDividerId]
  );
  const handleCreateNewStep = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.preventDefault();

      if (!workflowConfig) {
        return;
      }
      const newKey = generateNodeKey();
      const newWorkflowStep = createWorkflowStep({ key: newKey });

      const updatedWorkflow = addWorkflowStep({
        workflowConfig,
        workflowStep: newWorkflowStep,
        ordinal: parseInt(id)
      });
      setSelectedLaneId?.(newKey);
      setSelectedNodeId(undefined);
      setWorkflowConfig({ ...updatedWorkflow });
    },
    [
      workflowConfig,
      setWorkflowConfig,
      setSelectedNodeId,
      setSelectedLaneId,
      id
    ]
  );

  return (
    <div
      className={classNames(["workflow-canvas__divider"])}
      onMouseEnter={(e: React.MouseEvent<HTMLDivElement>) =>
        handleMouseEnterDivider(e)
      }
      onMouseLeave={(e: React.MouseEvent<HTMLDivElement>) =>
        handleMouseLeaveDivider(e)
      }
      role="none"
    >
      <svg
        className={classNames([
          "divider__vertical-line",
          isHovered ? "divider__vertical-line--hovered " : ""
        ])}
        preserveAspectRatio="none"
      >
        <line x1="0" y1="0" x2="0" y2="100%" />
      </svg>
      {isEditable && (
        <Icon
          name="add_circle"
          fillStyle={IconFillStyle.FILLED}
          className={classNames([
            "divider__plus-icon",
            isHovered ? "divider__plus-icon--hovered" : ""
          ])}
          onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
            handleCreateNewStep(e)
          }
        />
      )}
    </div>
  );
};
