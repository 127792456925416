import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useGetEngagementTypeByIdQuery,
  useGetNewsItemsQuery,
  useNews
} from "@shared/hooks";
import { useNewsMastheadImage } from "@shared/hooks/useNewsMastheadImage";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import Popup from "@shared-components/popup/Popup";

import { AccessLevel, ResourceName } from "@app/types";

import { Button, ButtonVariant } from "@atoms/Button";

import Form from "@components/atoms/Form/Form";
import ModalForm from "@components/molecules/ModalForm";
import EditableNews from "@components/organisms/EditableNews/EditableNews";
import EditableNewsMasthead from "@components/organisms/EditableNewsMasthead/EditableNewsMasthead";
import SelectEngagementTypeDropdown from "@components/organisms/SelectEngagementTypeDropdown/SelectEngagementTypeDropdown";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./ManageNews.scss";

const ManageNews = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentEngagementTypeId, setCurrentEngagementTypeId] = useState(null);
  const [currentNewsItemUpdatedAt, setCurrentNewsItemUpdatedAt] = useState();
  const { t } = useTranslation();
  const { data: features, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const {
    data: newsItems,
    isLoading,
    error: fetchError
  } = useGetNewsItemsQuery(currentEngagementTypeId, {
    skip: !currentEngagementTypeId
  });

  const { user } = useAuthUser();

  const {
    canBeDisplayed,
    updateNewsItem,
    addNewsItem,
    deleteNewsItem,
    isNewsAlterLoading
  } = useNews();

  const { data: currentEngagementType } = useGetEngagementTypeByIdQuery(
    currentEngagementTypeId,
    { skip: !currentEngagementTypeId }
  );

  const {
    deleteNewsMastheadImage,
    fetchNewsMasthead,
    newsMasthead,
    uploadControlHooks
  } = useNewsMastheadImage(currentEngagementTypeId);
  const navigate = useNavigate();

  const newsConfiguration = useMemo(
    () => currentEngagementType?.configuration?.news,
    [currentEngagementType]
  );

  useEffect(() => {
    if (!features) {
      return;
    }
    if (!features[systemConstants.features.manageNews]) {
      navigate("/");
    }
  }, [features, navigate]);

  useEffect(() => {
    fetchNewsMasthead();
  }, [fetchNewsMasthead]);

  const [currentNewsItem, setCurrentNewsItem] = useState(null);
  const [showModifiedError, setShowModifiedError] = useState(false);

  const handleMenuActionClick = ({ item, action }) => {
    setCurrentNewsItemUpdatedAt(item.updatedAt);
    setCurrentNewsItem(item);
    if (action === "EDIT") {
      setShowEditModal(true);
    } else if (action === "DELETE") {
      setShowDeleteModal(true);
    }
  };

  const handleModification = data => {
    if (data?.itemHasBeenModified) {
      setShowModifiedError(true);
    }
    handleCloseModal();
  };

  const handleAdd = async fields => {
    const result = await addNewsItem({
      engagementTypeId: currentEngagementTypeId,
      fields,
      updatedAt: currentNewsItemUpdatedAt
    });
    handleModification(result);
  };

  const handleUpdate = async fields => {
    const result = await updateNewsItem({
      currentNewsItem,
      fields,
      engagementTypeId: currentEngagementTypeId,
      updatedAt: currentNewsItemUpdatedAt
    });
    handleModification(result);
  };

  const handleDelete = async () => {
    const result = await deleteNewsItem({
      engagementTypeId: currentEngagementTypeId,
      currentNewsItem,
      updatedAt: currentNewsItemUpdatedAt
    });
    handleModification(result);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setShowDeleteModal(false);
    setShowEditModal(false);
    setCurrentNewsItem(null);
  };

  const getLabel = useCallback(
    key => t(`common:ui.news.form.${key}.label`),
    [t]
  );

  const yupSchema = useMemo(() => {
    const schema = {};
    const requiredFields = newsConfiguration?.fields?.filter(
      f => f.required === true
    );

    requiredFields?.forEach(field => {
      if (field.type === "date") {
        schema[field.key] = yup
          .date()
          .nullable()
          .required(
            t("common:ui.forms.required.message", {
              label: getLabel(field.key)
            })
          );
      } else {
        schema[field.key] = yup.string().required(
          t("common:ui.forms.required.message", {
            label: getLabel(field.key)
          })
        );
      }
    });

    return yup.object(schema);
  }, [getLabel, newsConfiguration?.fields, t]);

  const renderForm = useCallback(
    newsItem => {
      return newsConfiguration?.fields?.map(field => {
        switch (field.type) {
          case "text":
            return (
              <Form.TextField
                name={field.key}
                defaultValue={newsItem?.fields[field.key] ?? ""}
                label={getLabel(field.key)}
                required={field.required === true}
                key={field.key}
                maxLength={100}
              />
            );
          case "textarea":
            return (
              <Form.TextArea
                name={field.key}
                label={getLabel(field.key)}
                required={field.required === true}
                defaultValue={newsItem?.fields[field.key] ?? ""}
                key={field.key}
                maxLength={1000}
              />
            );
          case "date":
            const defaultDate = newsItem?.fields[field.key] ?? false;
            return (
              <Form.DateField
                name={field.key}
                label={getLabel(field.key)}
                defaultValue={defaultDate ? new Date(defaultDate) : undefined}
                required={field.required === true}
                key={field.key}
              />
            );
        }
      });
    },
    [getLabel, newsConfiguration?.fields]
  );

  const handleCloseModifiedPopup = () => {
    setShowModifiedError(false);
  };

  const renderCreateButton = () => {
    if (
      !user.checkAccess(ResourceName.MANAGE_NEWS_AND_IMAGE, AccessLevel.CREATE)
    ) {
      return <></>;
    }
    return (
      <Button
        label={t("common:ui.news.create.label")}
        onClick={() => setShowAddModal(true)}
        iconName="add"
      />
    );
  };

  const getMenuActions = () => {
    const actions = [];
    if (
      user.checkAccess(ResourceName.MANAGE_NEWS_AND_IMAGE, AccessLevel.UPDATE)
    ) {
      actions.push({
        name: t("common:ui.forms.edit.label"),
        action: "EDIT"
      });
    }
    if (
      user.checkAccess(ResourceName.MANAGE_NEWS_AND_IMAGE, AccessLevel.DELETE)
    ) {
      actions.push({
        name: t("common:ui.forms.delete.label"),
        action: "DELETE"
      });
    }
    return actions;
  };

  const renderSecondaryContent = () => {
    return (
      <div className="manage-news__right">
        <EditableNews
          isLoading={isLoading}
          error={fetchError}
          canBeDisplayed={canBeDisplayed}
          newsItems={newsItems}
          newsConfiguration={newsConfiguration}
          handleMenuActionClick={handleMenuActionClick}
          menuActions={getMenuActions()}
        ></EditableNews>
      </div>
    );
  };

  const renderPrimaryContent = () => {
    if (
      !user.checkAccess(ResourceName.MANAGE_NEWS_AND_IMAGE, AccessLevel.UPDATE)
    ) {
      return <></>;
    }
    return (
      <>
        <div className="manage-news__left">
          {t("common:ui.news.explanatory.label", {
            context: currentEngagementType?.configuration?.i18nContextKey
          })}
          {currentEngagementTypeId && (
            <EditableNewsMasthead
              engagementTypeId={currentEngagementTypeId}
              newsConfiguration={newsConfiguration}
              deleteImage={deleteNewsMastheadImage}
              newsMasthead={newsMasthead}
              uploadHooks={uploadControlHooks}
              menuActions={getMenuActions()}
            ></EditableNewsMasthead>
          )}
        </div>
      </>
    );
  };

  const handleEngagementTypeChange = engagementType => {
    setCurrentEngagementTypeId(engagementType.id);
  };

  const renderBodyHeader = () => {
    return (
      <div className="manage-news__header--dropdown">
        <SelectEngagementTypeDropdown
          onChange={handleEngagementTypeChange}
          disablePreselectDefault={false}
          hideIfOne={true}
        />
      </div>
    );
  };

  return (
    <>
      <PageTemplate
        header={{
          title: t("ui.sideNavigation.admin.manageNews"),
          actions: renderCreateButton(),
          alwaysShowBreadcrumbs: true
        }}
        body={{
          header: renderBodyHeader(),
          primary: renderPrimaryContent(),
          secondary: renderSecondaryContent(),
          secondaryMinWidth: "40rem",
          secondaryOnRight: true
        }}
        other={{
          loading: isFeaturesLoading ? t("common:loading") : null
        }}
      />

      <Popup
        visibility={showEditModal}
        handleOutsideClick={false}
        width="60rem"
      >
        <ModalForm
          boxClassName=""
          title={t("common:ui.news.edit.label")}
          handleCancel={handleCloseModal}
          handleSubmit={handleUpdate}
          submitLabel={t("common:ui.forms.update.label")}
          yupSchema={yupSchema}
          disabled={isNewsAlterLoading}
        >
          {renderForm(currentNewsItem)}
        </ModalForm>
      </Popup>
      <Popup visibility={showAddModal} handleOutsideClick={false} width="60rem">
        <ModalForm
          boxClassName=""
          title={t("common:ui.news.create.label")}
          handleCancel={handleCloseModal}
          handleSubmit={handleAdd}
          submitLabel={t("common:ui.forms.submit.label")}
          yupSchema={yupSchema}
          disabled={isNewsAlterLoading}
        >
          {renderForm()}
        </ModalForm>
      </Popup>
      <Popup
        visibility={showDeleteModal}
        handleOutsideClick={false}
        width="60rem"
      >
        <ModalTemplate
          boxClassName=""
          title={t("common:ui.news.delete.label", {
            context: currentEngagementType?.configuration?.i18nContextKey
          })}
          content={t("common:ui.news.delete.confirmation.label", {
            context: currentEngagementType?.configuration?.i18nContextKey
          })}
          footer={
            <>
              <Button
                variant={ButtonVariant.DANGER}
                label={t("common:ui.forms.yes.label")}
                onClick={handleDelete}
                disabled={isNewsAlterLoading}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                label={t("common:ui.forms.no.label")}
                onClick={handleCloseModal}
                disabled={isNewsAlterLoading}
              />
            </>
          }
          onClose={handleCloseModal}
        />
      </Popup>
      <Popup
        visibility={showModifiedError}
        handleOutsideClick={false}
        width="60rem"
      >
        <ModalTemplate
          boxClassName=""
          title={t("common:ui.news.notPermitted.label")}
          content={t("common:ui.news.modified.message")}
          footer={
            <>
              <Button
                label={t("common:ui.forms.close.label")}
                onClick={handleCloseModifiedPopup}
              />
            </>
          }
          onClose={handleCloseModifiedPopup}
        />
      </Popup>
    </>
  );
};

export default ManageNews;
