import { useCallback, useEffect, useRef, useState } from "react";

import {
  manageDocumentDownloadsService,
  manageFinalPackageDocumentService
} from "@shared/services";

export function useDownloadVisualisationWorkbook() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const cancelReq = useRef();

  useEffect(() => {
    return () => {
      cancelReq.current = true;
    };
  }, []);

  const downloadVisualisationWorkbook = useCallback(projectId => {
    setDownloadError(false);
    setIsDownloaded(false);
    setIsDownloading(true);
    cancelReq.current = false;
    manageFinalPackageDocumentService
      .downloadVisualisationWorkbook({ projectId })
      .then(document => {
        if (document?.filename && document?.blobUrl) {
          manageDocumentDownloadsService.downloadBlob(
            document.filename,
            document.blobUrl
          );
        } else {
          throw new Error("Download failed.");
        }
        if (!cancelReq.current) {
          setIsDownloaded(true);
          setIsDownloading(false);
          setDownloadError(false);
        }
      })
      .catch(err => {
        if (!cancelReq.current) {
          setIsDownloaded(false);
          setIsDownloading(false);
          setDownloadError(err?.message);
        }
      });
  }, []);

  return {
    downloadVisualisationWorkbook,
    isDownloaded,
    isDownloading,
    downloadError
  };
}
