import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementsActions } from "@shared/actions/manageClientEngagementsActions";

export function useEngagementMembers() {
  const dispatch = useDispatch();
  const [engagementMembers, setEngagementMembers] = useState([]);
  const [membersToAdd, setMembersToadd] = useState({});
  const [addedMember, setAddedMember] = useState();
  const [isMemberLoading, setIsMemberLoading] = useState(false);
  const [membersError, setMembersError] = useState();
  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );

  useEffect(() => {
    setEngagementMembers(manageClientEngagements.members);
  }, [manageClientEngagements.members]);

  useEffect(() => {
    setMembersToadd(manageClientEngagements.membersToAdd);
  }, [manageClientEngagements.membersToAdd]);

  useEffect(() => {
    setAddedMember(manageClientEngagements.addedMember);
  }, [manageClientEngagements.addedMember]);

  useEffect(() => {
    if (manageClientEngagements.removedMember) {
      dispatch(manageClientEngagementsActions.resetRemovedMember());
    }
  }, [dispatch, manageClientEngagements.removedMember]);

  useEffect(() => {
    setIsMemberLoading(manageClientEngagements.getMembersLoading);
  }, [manageClientEngagements.getMembersLoading]);

  useEffect(() => {
    setMembersError(manageClientEngagements.error);
  }, [manageClientEngagements.error]);

  const getMembers = useCallback(
    engagement => {
      dispatch(manageClientEngagementsActions.getMembersToAdd(engagement));
      dispatch(manageClientEngagementsActions.getMembers(engagement));
    },
    [dispatch]
  );

  const addMember = useCallback(
    (engagement, user) => {
      dispatch(
        manageClientEngagementsActions.addMember({ engagement, member: user })
      );
    },
    [dispatch]
  );

  const resetAddedMember = useCallback(() => {
    dispatch(manageClientEngagementsActions.resetAddedMember());
  }, [dispatch]);

  return {
    engagementMembers,
    membersToAdd,
    addedMember,
    isMemberLoading,
    membersError,
    getMembers,
    addMember,
    resetAddedMember
  };
}
