import React from "react";

import { useTooltip } from "@shared/hooks/useTooltip.jsx";

import OTTooltip from "@atoms/OTTooltip/OTTooltip.tsx";
import { Text, TextProps } from "@atoms/Text/Text.tsx";

const TextToolTip = (props: TextProps) => {
  const { text, className, maxLines, truncate, color } = props;
  const { elementProps, showTooltip } = useTooltip();
  return (
    <div {...elementProps}>
      <Text
        text={text}
        maxLines={maxLines}
        truncate={truncate}
        className={className}
        color={color}
      />
      {showTooltip && <OTTooltip content={text} visible={showTooltip} />}
    </div>
  );
};

export default TextToolTip;
