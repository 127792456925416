import React, { forwardRef, useCallback, useMemo } from "react";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Inline, Stack } from "@fermions";

import { Icon } from "@atoms/Icon";

import {
  InputLabel,
  InputLabelProps,
  InputWidth,
  getInputIdentifier
} from "../InputTemplate";
import "./CheckboxInput.scss";

export interface CheckboxInputProps extends InputLabelProps {
  value: boolean | "indeterminate";
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  error?: string;
  extraProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  width?: InputWidth;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
}

export const CheckboxInput = forwardRef(
  (
    {
      value,
      onChange,
      label,
      description,
      hideLabel,
      required,
      disabled,
      error,
      extraProps = {},
      width = InputWidth.FILL,
      onBlur,
      className,
      fontColor
    }: CheckboxInputProps,
    fwdRef
  ) => {
    const handleChange = useCallback(
      event => {
        event.stopPropagation();
        event.preventDefault();
        onChange?.(value === "indeterminate" ? true : !value);
      },
      [value, onChange]
    );

    const identifier = useMemo(
      () => getInputIdentifier(label, "checkbox-input"),
      [label]
    );

    const labelElement = useMemo(
      () => (
        <InputLabel
          label={label}
          disabled={disabled}
          required={required}
          description={description}
          id={identifier}
          hideLabel={hideLabel}
          fontColor={fontColor}
        />
      ),
      [label, disabled, required, description, identifier, hideLabel]
    );

    return (
      <Stack gap="050" width="fit">
        <Inline
          width="fit"
          className={classNames([
            "checkbox-input",
            `checkbox-input--width-${width}`,
            returnStringIfTrue(disabled, "disabled"),
            className ?? ""
          ])}
          onClick={disabled ? undefined : handleChange}
          data-testid={`test_${label?.replaceAll(" ", "-")}_input`}
        >
          <Box
            className={classNames([
              "checkbox",
              returnStringIfTrue(value, "checked"),
              returnStringIfTrue(disabled, "disabled")
            ])}
          >
            <input
              aria-labelledby={identifier}
              className="checkbox__input"
              ref={fwdRef}
              type="checkbox"
              checked={value === "indeterminate" ? false : value}
              onChange={handleChange}
              disabled={disabled}
              data-testid={`test_${identifier}`}
              onBlur={onBlur}
              {...extraProps}
            />
            {value === true && <Icon name="check" />}
            {value === "indeterminate" && (
              <Icon name="check_indeterminate_small" />
            )}
          </Box>
          {labelElement}
        </Inline>
        {error && <span className="input-template__error">{error}</span>}
      </Stack>
    );
  }
);
