import { manageArticleConstants } from "../constants";
import { systemConstants } from "../constants";
import { manageArticlesService } from "../services";

export const manageArticleActions = {
  getArticles,
  addArticle,
  editArticle,
  deleteArticle,
  resetUpdateArticle,
  resetAddArticle,
  resetDeleteArticle
};

function getArticles(user) {
  return dispatch => {
    dispatch(request());

    manageArticlesService.getArticles(user).then(
      articles => {
        dispatch(success(articles));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: manageArticleConstants.GET_ARTICLES_REQUEST
      };
    }
    function success(articles) {
      return {
        type: manageArticleConstants.GET_ARTICLES_SUCCESS,
        articles
      };
    }
    function failure(error) {
      return {
        type: manageArticleConstants.GET_ARTICLES_FAILURE,
        error
      };
    }
  };
}

function addArticle(article) {
  return dispatch => {
    dispatch(request());

    manageArticlesService.addArticle(article).then(
      article => {
        dispatch(success(article));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: manageArticleConstants.ADD_ARTICLE_REQUEST
      };
    }
    function success(article) {
      return {
        type: manageArticleConstants.ADD_ARTICLE_SUCCESS,
        article
      };
    }
    function failure(error) {
      return {
        type: manageArticleConstants.ADD_ARTICLE_FAILURE,
        error
      };
    }
  };
}

function editArticle(article) {
  return dispatch => {
    dispatch(request());

    manageArticlesService.updateArticle(article).then(
      article => {
        dispatch(success(article));
      },
      error => {
        dispatch(failure(error));
      }
    );

    function request() {
      return {
        type: manageArticleConstants.EDIT_ARTICLE_REQUEST
      };
    }
    function success(article) {
      return {
        type: manageArticleConstants.EDIT_ARTICLE_SUCCESS,
        article
      };
    }
    function failure(error) {
      return {
        type: manageArticleConstants.EDIT_ARTICLE_FAILURE,
        error
      };
    }
  };
}

function deleteArticle(article) {
  return dispatch => {
    dispatch(request());

    manageArticlesService
      .updateArticle({
        ...article,
        status: systemConstants.article.status.inactive
      })
      .then(
        article => {
          dispatch(success(article));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request() {
      return {
        type: manageArticleConstants.DELETE_ARTICLE_REQUEST
      };
    }
    function success(article) {
      return {
        type: manageArticleConstants.DELETE_ARTICLE_SUCCESS,
        article
      };
    }
    function failure(error) {
      return {
        type: manageArticleConstants.DELETE_ARTICLE_FAILURE,
        error
      };
    }
  };
}

function resetDeleteArticle() {
  return { type: manageArticleConstants.RESET_DELETE_ARTICLE };
}

function resetUpdateArticle() {
  return { type: manageArticleConstants.RESET_UPDATE_ARTICLE };
}

function resetAddArticle() {
  return { type: manageArticleConstants.RESET_ADD_ARTICLE };
}
