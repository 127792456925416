import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants.js";

import { useAuthUser } from "@shared/hooks/useAuthUser.tsx";
import {
  useGetActionItemTypeQuery,
  useGetActionItemTypesFormOptionsQuery
} from "@shared/services/actionItemTypesService.js";

import { CrudFormMode } from "@app/types/ActionItemType.ts";
import { AccessLevel } from "@app/types/Permission.ts";
import { ResourceName } from "@app/types/Resource.ts";

import { Button } from "@atoms/Button/Button.tsx";
import { ButtonVariant } from "@atoms/Button/index.ts";

import { MessageBox } from "@molecules/MessageBox";

import FormPageTemplate from "@templates/FormPageTemplate/FormPageTemplate.jsx";

import { Inline } from "@components/fermions/index.tsx";

import ActionItemTypeForm from "./ActionItemTypeForm.tsx";
import { displayName } from "./actionItemType.tsx";

const ViewActionItemType = () => {
  const { actionItemTypeId } = useParams();
  const { data: actionItemType, isLoading: isAitLoading } =
    useGetActionItemTypeQuery(
      { actionItemTypeId },
      { skip: !actionItemTypeId }
    );
  const { data: formOptions, isLoading: isOptionsLoading } =
    useGetActionItemTypesFormOptionsQuery({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuthUser();
  const hasEditAccess = user.checkAccess(
    ResourceName.REQUEST_TYPES,
    AccessLevel.UPDATE
  );

  const isLoading = useMemo(
    () => isAitLoading || isOptionsLoading,
    [isAitLoading, isOptionsLoading]
  );

  useEffect(() => {
    if (!actionItemTypeId) {
      navigate(routeConstants.notFound);
    }
  }, [navigate, actionItemTypeId]);

  const editButton = useMemo(() => {
    if (!actionItemType || !hasEditAccess) {
      return <></>;
    }
    if (!actionItemType?.configuration?.isUserManaged) {
      return (
        <MessageBox
          message={t("admin:ui.updateRequestType.cannotUpdate.message")}
        />
      );
    }
    return (
      <Button
        variant={ButtonVariant.SECONDARY}
        iconName="edit"
        label={t("common:ui.forms.edit.label")}
        onClick={() =>
          navigate(`/admin/request-types/${actionItemType.id}/edit`)
        }
      />
    );
  }, [hasEditAccess, navigate, actionItemType, t]);

  const renderPageActions = () => {
    return (
      <Inline gap="300" alignment="right">
        <Button
          variant={ButtonVariant.TEXT}
          label={t("common:ui.forms.close.label")}
          onClick={() => navigate(routeConstants.admin.manageRequestTypes)}
        />
        {editButton}
      </Inline>
    );
  };

  const aitDisplayName = useMemo(
    () => displayName(actionItemType, t),
    [actionItemType, t]
  );

  return (
    <FormPageTemplate
      title={t("admin:ui.viewRequestType.title", {
        name: aitDisplayName
      })}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageRequestTypes,
          label: t("admin:ui.manageRequestTypes.title")
        }
      ]}
      sticky
      form={{
        handleSubmit: () => {},
        contents: (
          <ActionItemTypeForm
            actionItemType={actionItemType}
            formOptions={formOptions}
            crudFormMode={CrudFormMode.READ}
          />
        ),
        renderPageActions
      }}
      isLoading={isLoading}
      other={{}}
      renderRawForm
    />
  );
};

export default ViewActionItemType;
