import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { MultiSelectInput } from "@molecules/inputs";

const Multiselect = props => {
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    control
  } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if ((!currentValue || currentValue?.length === 0) && props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: props.required }}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <MultiSelectInput
            label={props.label}
            emptyLabel={props.emptyLabel}
            description={props.description}
            items={props.items}
            ref={ref}
            showSearch={props.showSearch}
            showSelectAll={props.showSelectAll}
            selectedValues={getValues(props.name) || []}
            disabled={props.disabled}
            onChange={newVal => {
              if (newVal && newVal.length === 0) {
                newVal = undefined;
              }
              clearErrors(props.name);
              onChange(newVal);
              if (props.onChange) {
                props.onChange(newVal);
              }
            }}
            onBlur={onBlur}
            required={props.required}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            placeholder={props.placeholder}
            sortComparator={props.sortComparator}
            borderStyle={props.borderStyle}
            width={props.width}
            allowUndefined={props.allowUndefined}
            className={props.className}
          />
        );
      }}
    />
  );
};

Multiselect.propTypes = {
  label: PropTypes.string,
  emptyLabel: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ),
  onChange: PropTypes.func,
  sortComparator: PropTypes.func,
  borderStyle: PropTypes.string,
  width: PropTypes.string,
  allowUndefined: PropTypes.bool,
  className: PropTypes.string,
  showSelectAll: PropTypes.bool,
  showSearch: PropTypes.bool
};

export default Multiselect;
