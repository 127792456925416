import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useGetAuditByEmailQuery } from "@shared/services/auditService";

import AuditRecords from "@shared-components/pages/host/manageAudits/AuditRecords";

import { getErrorMessage } from "@app/helpers/error";

import Form from "@components/atoms/Form";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./ManageAudits.scss";

const ManageAudits = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const {
    data: audits,
    isSuccess,
    isLoading,
    error
  } = useGetAuditByEmailQuery(email, {
    skip: !email
  });

  const getBodyContent = useCallback(() => {
    if (error) {
      return <></>;
    }
    if (!audits?.length) {
      if (!isSuccess) {
        return <></>;
      }
      return <>{t("common:ui.auditReport.body.noRecords")}</>;
    }
    return (
      <div className="manage-audits__container--body-records">
        <div className="manage-audits__container--title">
          {t("common:ui.auditReport.body.reportFor", { email })}
        </div>
        {audits.map(record => (
          <AuditRecords key={record.table} data={record} reportFor={email} />
        ))}
      </div>
    );
  }, [audits, t, isSuccess, email, error]);

  const yupSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t("common:ui.auditReport.form.email.invalid"))
          .required(t("common:ui.auditReport.form.email.empty"))
      }),
    [t]
  );

  const handleSubmit = (data: { email: string }) => setEmail(data.email);

  return (
    <PageTemplate
      header={{
        title: t("ui.sideNavigation.admin.auditReport"),
        content: (
          <Form
            handleSubmit={handleSubmit}
            submitLabel={t("common:ui.auditReport.form.submitLabel")}
            yupSchema={yupSchema}
            disabled={isLoading}
            customFormClassName="manage-audits__container--body-input-fields"
          >
            <Form.TextField
              name="email"
              label={t("common:ui.auditReport.form.email.label")}
              required={true}
              maxLength={255}
            />
          </Form>
        ),
        alwaysShowBreadcrumbs: true
      }}
      body={{
        primary: getBodyContent()
      }}
      other={{
        error: getErrorMessage(error, t),
        loading: isLoading ? t("common:loading") : undefined
      }}
    />
  );
};

export default ManageAudits;
