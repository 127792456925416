import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";
import { checkFilter } from "@shared/features/interactiveReport/interactiveReportSlice";
import { deepCompare } from "@shared/helpers/deepCompare.ts";

import { useProjectSession } from "@app/hooks";
import { useAppStore } from "@app/useAppStore.js";

export function useCurrentProject() {
  const manageProject = useSelector(state => state.manageProject);
  const [currentProject, setCurrentProject] = useState(
    structuredClone(manageProject.currentProject)
  );
  const { fetchProjectSession, changeProjectSession, resetProjectSession } =
    useProjectSession();
  const dispatch = useDispatch();
  const clearOtherRequestsDashboardFilters = useAppStore(
    state => state.clearOtherRequestsDashboardFilters
  );

  const onChangeCurrentProject = useCallback(
    project => {
      dispatch(manageProjectActions.setCurrentProject(project));
      dispatch(checkFilter({ project }));
      clearOtherRequestsDashboardFilters(project?.id);
      changeProjectSession(project);
    },
    [changeProjectSession, dispatch, clearOtherRequestsDashboardFilters]
  );

  const onUpdateCurrentProject = useCallback(
    project => {
      dispatch(manageProjectActions.setCurrentProject(project));
    },
    [dispatch]
  );

  useEffect(() => {
    const savedProject = fetchProjectSession();
    if (savedProject && !currentProject) {
      onChangeCurrentProject(savedProject);
    }
  }, [currentProject, fetchProjectSession, onChangeCurrentProject]);

  useEffect(() => {
    setCurrentProject(prev => {
      if (!deepCompare(manageProject.currentProject, prev)) {
        changeProjectSession(structuredClone(manageProject.currentProject));
        return structuredClone(manageProject.currentProject);
      }
      return prev;
    });
  }, [manageProject.currentProject, changeProjectSession]);

  const resetCurrentProject = useCallback(() => {
    dispatch(manageProjectActions.resetCurrentProject());
    resetProjectSession();
  }, [dispatch, resetProjectSession]);

  useEffect(() => {
    return () => {
      resetCurrentProject();
    };
  }, [resetCurrentProject]);

  return {
    currentProject,
    onChangeCurrentProject,
    onUpdateCurrentProject,
    resetCurrentProject
  };
}
