import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions";
import { manageDocumentUploadsActions } from "@shared/actions/manageDocumentUploadsActions";

export function useUploadDocumentRevision(document) {
  const dispatch = useDispatch();

  const uploadDocumentRevision = useCallback(
    ({ fileName, filePathId }) => {
      dispatch(
        manageDocumentUploadsActions.clearUploadedDocument({
          name: document.name,
          projectId: document.projectId
        })
      );
      dispatch(
        manageProjectDocumentsActions.uploadProjectDocument({
          ...document,
          name: fileName,
          preserveName: true,
          documentPathId: filePathId
        })
      );
    },
    [dispatch, document]
  );

  return {
    uploadDocumentRevision
  };
}
