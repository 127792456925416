import React, { useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useCurrentProject, useGetProjectByIdQuery } from "@shared/hooks";

import { routeConstants } from "@app/constants";

import ActionItems from "@components/organisms/ActionItems";

import "./ManageActionItems.scss";

const ManageActionItems = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProject, onChangeCurrentProject } = useCurrentProject();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );

  useEffect(() => {
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, currentProject, onChangeCurrentProject]);

  useEffect(() => {
    if (!projectId && currentProject) {
      navigate(`/projects/${currentProject.id}/queries`, { replace: true });
    }
  }, [navigate, currentProject, projectId]);

  useEffect(() => {
    if (isInvalidProject || (!projectId && !currentProject)) {
      navigate(routeConstants.login);
    }
  }, [isInvalidProject, navigate, projectId, currentProject]);

  return (
    <ActionItems project={currentProject} filters={location.state?.filters} />
  );
};

export default ManageActionItems;
