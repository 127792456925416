import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectId: null,
  requestFilter: {
    status: null,
    dueDate: null
  },
  userFilterType: 1,
  assignedToUsers: [],
  tableFilters: {
    queryType: undefined,
    assignedTo: [],
    labels: undefined,
    createdBy: []
  }
};

export const actionItemSlice = createSlice({
  name: "actionItem",
  initialState,
  reducers: {
    saveRequestFilter: (state, action) => {
      const selectedFilter = action.payload?.filters;
      state.requestFilter = structuredClone(selectedFilter);
    },
    saveUserFilterType: (state, action) => {
      const selectedFilter = action.payload?.filter;
      state.userFilterType = structuredClone(selectedFilter);
    },
    saveAssignedToUsers: (state, action) => {
      const users = action.payload?.users;
      state.assignedToUsers = structuredClone(users);
    },
    saveTableFilters: (state, action) => {
      const { filters, key } = action.payload;
      state.tableFilters[key] = filters;
    },
    resetTableFilter: state => {
      state.tableFilters = {
        queryType: undefined,
        assignedTo: [],
        labels: undefined,
        createdBy: []
      };
    },
    changeProject: (state, action) => {
      const payloadProjectId = action.payload?.projectId;
      if (payloadProjectId && payloadProjectId !== state.projectId) {
        return { ...initialState, projectId: payloadProjectId };
      }
    }
  }
});

export const {
  changeProject,
  saveRequestFilter,
  saveUserFilterType,
  saveAssignedToUsers,
  saveTableFilters,
  resetTableFilter
} = actionItemSlice.actions;

export default actionItemSlice.reducer;
