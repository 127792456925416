import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks";

import { ActionDropdown } from "@molecules/ActionDropdown";

import "./EditableNewsItem.scss";

const EditableNewsItem = props => {
  const { item, handleMenuActionClick, menuActions, newsConfiguration } = props;
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const { t } = useTranslation();
  const publicationDate = date => dateFormatter(date, locale, shortFormat);

  const renderNewsKeys = (key, refNo) => {
    if (
      !["title", "teaser", "publicationDate"].includes(key) &&
      newsConfiguration?.fields.some(f => f.key === key)
    ) {
      return (
        <div
          className="editable-item--text"
          key={refNo}
          role={`news-item-${key}`}
        >
          <u>{t(`common:ui.news.form.${key}.label`)}</u>: {item[key]}
        </div>
      );
    }
  };

  return (
    <div className="editable-item" role="news-item">
      <div>
        {item["title"] && (
          <div
            className="editable-item--text editable-item__title"
            role="news-item-title"
          >
            {item["title"]}
          </div>
        )}
        {item["teaser"] && (
          <div className="editable-item--text" role="news-item-teaser">
            {item["teaser"]}
          </div>
        )}
        {item["publicationDate"] && (
          <div
            className="editable-item--text editable-item__publication-date"
            role="news-item-publication-date"
          >
            {publicationDate(item["publicationDate"])}
          </div>
        )}
        {Object.keys(item).map(renderNewsKeys)}
      </div>
      <div className="editable-item__menu">
        <ActionDropdown
          positionRelative={true}
          menuItems={menuActions}
          onMenuItemClick={handleMenuActionClick}
        />
      </div>
    </div>
  );
};

EditableNewsItem.propTypes = {
  item: PropTypes.object,
  handleMenuActionClick: PropTypes.func,
  menuActions: PropTypes.array,
  newsConfiguration: PropTypes.object
};

export default EditableNewsItem;
