import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Popup from "@shared-components/popup/Popup";

import { Button, ButtonVariant } from "@atoms/Button";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";
import { CLEANING_PROCESS_NEW_KEY } from "@components/organisms/WebSheetDocument/WebSheetDocument";

export const CleanseProcessModal = props => {
  const { visibility, handleClose, title, savedProcess, handleNext } = props;
  const { t } = useTranslation();

  const multiSelectItems = savedProcess.map(sp => ({
    ...sp,
    value: sp.id,
    order: 1
  }));

  const yupSchema = yup.object({
    savedProcess: yup
      .object()
      .required(
        t(
          "common:ui.websheet.actions.cleaningWizard.savedProcessModal.validationError.empty"
        )
      )
  });

  const handleSubmit = useCallback(
    formData => {
      handleNext({
        ...formData,
        isNew: formData.savedProcess.value === CLEANING_PROCESS_NEW_KEY
      });
    },
    [handleNext]
  );

  const onClickNew = useCallback(() => {
    handleNext({
      savedProcess: {},
      isNew: true
    });
  }, [handleNext]);

  const defaultSortFn = (a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  const orderedComparator = (a, b) => a.order - b.order || defaultSortFn(a, b);

  return (
    <Popup visibility={visibility} width="50rem">
      <ModalForm
        boxClassName="cleanse-process-modal"
        title={title}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        submitLabel={t("common:ui.forms.next.label")}
        yupSchema={yupSchema}
      >
        <Form.Dropdown
          name={"savedProcess"}
          label={t(
            "common:ui.websheet.action.cleaningWizard.savedProcessModal.processSelectionLabel"
          )}
          required={true}
          items={multiSelectItems}
          showSearch={false}
          sortComparator={orderedComparator}
          placeholder={t(
            "common:ui.websheet.actions.cleaningWizard.savedProcessModal.dropdown.text"
          )}
        />
        <Button
          variant={ButtonVariant.TEXT}
          label={t(
            "common:ui.websheet.action.cleaningWizard.savedProcessModal.text"
          )}
          onClick={onClickNew}
        />
      </ModalForm>
    </Popup>
  );
};

CleanseProcessModal.defaultProps = {
  savedProcess: [],
  visibility: true
};

CleanseProcessModal.propTypes = {
  savedProcess: PropTypes.array
};

export default CleanseProcessModal;
