import { jwtDecode } from "jwt-decode";

import { routeConstants } from "@constants";

import { authConstants, systemConstants } from "../constants";

const token = localStorage.getItem("token");
const savedPath = JSON.parse(sessionStorage.getItem("lastKnownPath"));
const lastKnownPath = savedPath !== routeConstants.notFound ? savedPath : null;
const documentEditInfo = JSON.parse(sessionStorage.getItem("documentEditInfo"));

const initialState = token
  ? {
      loading: false,
      loggedIn: true,
      user: jwtDecode(token),
      token: token,
      error: "",
      verified: false,
      lastKnownPath,
      message: "",
      forgotPassWord: false,
      resetPassWord: false,
      verifyingToken: false,
      verificationDone: false,
      resetPasswordTokenVerified: false,
      qrCode: "",
      mfaActivated: false,
      mfaDisabled: false,
      emailSentToDisableMFA: false,
      externalLoginFailed: false,
      host: null,
      documentEditInfo
    }
  : {
      loading: false,
      loggedIn: false,
      user: {},
      token: null,
      error: "",
      verified: false,
      lastKnownPath: false,
      message: "",
      forgotPassWord: false,
      resetPassWord: false,
      verifyingToken: false,
      verificationDone: false,
      resetPasswordTokenVerified: false,
      qrCode: "",
      mfaActivated: false,
      mfaTokenVerified: false,
      mfaDisabled: false,
      emailSentToDisableMFA: false,
      externalLoginFailed: false,
      host: null
    };

export function authentication(state = initialState, action = {}) {
  switch (action.type) {
    case authConstants.GET_HOST_WITH_BRAND_DETAILS_REQUEST:
    case authConstants.GET_QR_CODE_REQUEST:
    case authConstants.LOGIN_REQUEST:
    case authConstants.VERIFY_MFA_TOKEN_REQUEST:
    case authConstants.TOKEN_VERIFY_REQUEST:
    case authConstants.EMAIL_MFA_VERIFICATION_TOKEN_REQUEST:
    case authConstants.DISABLE_MFA_REQUEST:
    case authConstants.ACTIVATE_MFA_TOKEN_REQUEST:
    case authConstants.LOGIN_WITH_EXTENAL_AUTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_HOST_WITH_BRAND_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        host: action.hostWithBrandDetails,
        error: ""
      };
    case authConstants.GET_HOST_WITH_BRAND_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        host: null
      };
    case authConstants.GET_QR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        qrCode: action.qrCode.image,
        error: ""
      };
    case authConstants.GET_QR_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        qrCode: "",
        error: action.error.key ?? action.error.message
      };
    case authConstants.VERIFY_RESET_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        verifyingToken: true,
        verificationDone: false
      };
    case authConstants.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        verifyingToken: false,
        verificationDone: true,
        error: "",
        resetPasswordTokenVerified: action.verified
      };
    case authConstants.VERIFY_RESET_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case authConstants.EMAIL_MFA_VERIFICATION_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        emailSentToDisableMFA: action.emailSentObject.emailSent,
        error: ""
      };
    case authConstants.EMAIL_MFA_VERIFICATION_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        emailSentToDisableMFA: false,
        error: action.error.key ?? action.error.message
      };

    case authConstants.ACTIVATE_MFA_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        mfaActivated: action.activated,
        error: ""
      };
    case authConstants.ACTIVATE_MFA_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        mfaActivated: false,
        error: action.error.key ?? action.error.message
      };

    case authConstants.DISABLE_MFA_SUCCESS:
      return {
        ...state,
        loading: false,
        mfaDisabled: action.disabledObject.disabled,
        error: ""
      };
    case authConstants.DISABLE_MFA_FAILURE:
      return {
        ...state,
        loading: false,
        mfaDisabled: false,
        error: action.error.key ?? action.error.message
      };

    case authConstants.VERIFY_MFA_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        mfaTokenVerified: action.verified,
        error: ""
      };
    case authConstants.VERIFY_MFA_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        mfaTokenVerified: false,
        error: action.error.message
      };

    case authConstants.LOGIN_SUCCESS:
      systemConstants.support = {
        email: action.user.support && action.user.support.email,
        phone: action.user.support && action.user.support.phone,
        message: action.user.support && action.user.support.message
      };
      return {
        ...state,
        loading: false,
        loggedIn: true,
        verified: true,
        user: action.user,
        token: action.token,
        error: "",
        message: "",
        forgotPassWord: false
      };
    case authConstants.LOGIN_FAILURE:
      systemConstants.support = {
        email: "",
        phone: "",
        message: ""
      };
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        token: null,
        error: action.error,
        message: "",
        forgotPassWord: false
      };

    case authConstants.LOGIN_WITH_EXTENAL_AUTH_SUCCESS:
      systemConstants.support = {
        email: action.user.support && action.user.support.email,
        phone: action.user.support && action.user.support.phone,
        message: action.user.support && action.user.support.message
      };
      return {
        ...state,
        loading: false,
        loggedIn: true,
        verified: true,
        user: action.user,
        token: action.token,
        error: "",
        message: "",
        forgotPassWord: false,
        externalLoginFailed: false
      };
    case authConstants.LOGIN_WITH_EXTENAL_AUTH_FAILURE:
      systemConstants.support = {
        email: "",
        phone: "",
        message: ""
      };
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        error: action.error,
        message: "",
        forgotPassWord: false,
        externalLoginFailed: true
      };
    case authConstants.LOGOUT:
      systemConstants.support = {
        email: "",
        phone: "",
        message: ""
      };
      return {
        loading: false,
        loggedIn: false,
        user: {},
        token: null,
        error: "",
        verified: false,
        message: "",
        forgotPassWord: false,
        externalLoginFailed: false
      };

    case authConstants.TOKEN_VERIFY_SUCCESS:
      systemConstants.support = {
        email: state.user.support && state.user.support.email,
        phone: state.user.support && state.user.support.phone,
        message: state.user.support && state.user.support.message
      };
      return {
        ...state,
        verified: true,
        loggedIn: true,
        loading: false,
        error: ""
      };
    case authConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };

    case authConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        forgotPassWord: false
      };
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        token: null,
        error: "",
        message: action,
        forgotPassWord: true
      };
    case authConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        token: null,
        error: action.error,
        message: action.error,
        forgotPassWord: false
      };
    case authConstants.RESET_PASSWORD_REQUEST:
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        token: null,
        error: action.error,
        message: action.error,
        forgotPassWord: false,
        resetPassWord: false
      };
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        verified: false,
        user: {},
        token: null,
        error: action.error,
        message: action.error,
        forgotPassWord: false,
        resetPassWord: true
      };
    case authConstants.RESET_FORGOT_PASSWORD:
      return {
        ...state,
        loading: false,
        error: "",
        forgotPassWord: false
      };

    case authConstants.RESET_MFA_ACTIVATED:
      return {
        ...state,
        loading: false,
        error: "",
        mfaActivated: false
      };
    case authConstants.RESET_MFA_DISABLED:
      return {
        ...state,
        loading: false,
        error: "",
        mfaDisabled: false
      };
    case authConstants.RESET_EMAIL_SENT_FOR_MFA_VERIFICATION_TOKEN:
      return {
        ...state,
        loading: false,
        error: "",
        emailSentToDisableMFA: false
      };
    case authConstants.RESET_MFA_TOKEN_VERIFICATION:
      return {
        ...state,
        loading: false,
        error: "",
        mfaTokenVerified: false
      };
    case authConstants.RESET_RESET_PASSWORD_TOKEN_VERIFICATION:
      return {
        ...state,
        error: "",
        verifyingToken: false,
        verificationDone: false,
        resetPasswordTokenVerified: false
      };
    case authConstants.RESET_EXTERNAL_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: "",
        externalLoginFailed: false
      };
    case authConstants.RESET_HOST:
      return {
        ...state,
        loading: false,
        error: "",
        host: null
      };
    case authConstants.RESET_LAST_KNOWN_PATH:
      return {
        ...state,
        lastKnownPath: null
      };
    default:
      return state;
  }
}
