import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleFileDownload, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageFinalPackageDocumentService = {
  getVisualisationFiles,
  updateVisualisationTitle,
  deleteVisualisationFile,
  downloadVisualisationWorkbook
};

function getVisualisationFiles({ projectId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/visualisation/folder`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateVisualisationTitle({ projectId, documentId, name }) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      documentId,
      name
    })
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/visualisation/title`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteVisualisationFile({ projectId, document }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/visualisation`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function downloadVisualisationWorkbook({ projectId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/visualisation/download`,
    requestOptions
  )
    .then(handleFileDownload)
    .catch(handleErrorResponse);
}
