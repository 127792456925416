import { manageFinalPackageDocumentsConstants } from "../constants";
import {
  manageDocumentUploadsService,
  manageFinalPackageDocumentService
} from "../services";

export const manageFinalPackageDocumentsAction = {
  getVisualisationFiles,
  uploadVisualisationFile,
  updateVisualisationTitle,
  deleteVisualisationFile,
  resetVisualisationFolder,
  resetVisualisationError
};

function getVisualisationFiles({ projectId }) {
  return dispatch => {
    dispatch(request());

    manageFinalPackageDocumentService.getVisualisationFiles({ projectId }).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_REQUEST
    };
  }
  function success(result) {
    return {
      type: manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_SUCCESS,
      result
    };
  }
  function failure(error) {
    return {
      type: manageFinalPackageDocumentsConstants.GET_VISUALISATION_FOLDER_FAILURE,
      error
    };
  }
}

function uploadVisualisationFile({ file, projectId, positionNum }) {
  return dispatch => {
    dispatch(request());
    manageDocumentUploadsService
      .uploadVisualisationFile({
        dispatch,
        file,
        projectId,
        positionNum
      })
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_REQUEST
    };
  }
  function success(result) {
    return {
      type: manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_SUCCESS,
      result
    };
  }
  function failure(error) {
    return {
      type: manageFinalPackageDocumentsConstants.UPLOAD_VISUALISATION_FILE_FAILURE,
      error
    };
  }
}

function updateVisualisationTitle({ documentId, name, projectId }) {
  return dispatch => {
    dispatch(request());
    manageFinalPackageDocumentService
      .updateVisualisationTitle({ projectId, documentId, name })
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_REQUEST
    };
  }
  function success(result) {
    return {
      type: manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_SUCCESS,
      result
    };
  }
  function failure(error) {
    return {
      type: manageFinalPackageDocumentsConstants.UPDATE_VISUALISATION_TITLE_FAILURE,
      error
    };
  }
}

function deleteVisualisationFile({ projectId, document }) {
  return dispatch => {
    dispatch(request());

    manageFinalPackageDocumentService
      .deleteVisualisationFile({ projectId, document })
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_REQUEST
    };
  }
  function success(result) {
    return {
      type: manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_SUCCESS,
      result
    };
  }
  function failure(error) {
    return {
      type: manageFinalPackageDocumentsConstants.DELETE_VISUALISATION_FILE_FAILURE,
      error
    };
  }
}

function resetVisualisationError() {
  return {
    type: manageFinalPackageDocumentsConstants.RESET_VISUALISATION_ERROR
  };
}

function resetVisualisationFolder() {
  return { type: manageFinalPackageDocumentsConstants.RESET_VISUALISATION };
}
