import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import {
  useAddRiskToProjectMutation,
  useUpdateRiskMutation
} from "@shared/hooks";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";

import "./addOrUpdateRisk.scss";

const baseStatus = [
  {
    id: 1,
    i18nKey: "risks:risks.fields.status.VERY-LOW.label",
    key: "VERY-LOW"
  },
  { id: 2, i18nKey: "risks:risks.fields.status.LOW.label", key: "LOW" },
  { id: 3, i18nKey: "risks:risks.fields.status.MEDIUM.label", key: "MEDIUM" },
  { id: 4, i18nKey: "risks:risks.fields.status.HIGH.label", key: "HIGH" },
  {
    id: 5,
    i18nKey: "risks:risks.fields.status.VERY-HIGH.label",
    key: "VERY-HIGH"
  }
];

const actionStatus = {
  add: "add",
  update: "update"
};

const riskStatus = Object.keys(systemConstants.project.risk.status).map(
  key => ({
    id: systemConstants.project.risk.status[key],
    name: systemConstants.project.risk.status[key],
    i18nKey: `risks:risks.fields.status.${systemConstants.project.risk.status[key]}.label`
  })
);

const resolutionOptions = Object.keys(
  systemConstants.project.risk.resolution
).map(key => ({
  id: systemConstants.project.risk.resolution[key],
  name: systemConstants.project.risk.resolution[key],
  i18nKey: `risks:risks.fields.resolution.status.${systemConstants.project.risk.resolution[key]}.label`
}));

const getUsers = members => {
  const hostUsers = members.hostUsers || [];
  const clientUsers = members.clientUsers || [];
  return hostUsers.concat(clientUsers);
};

const AddOrUpdateRisk = props => {
  const { t } = useTranslation();
  const [addRiskToProject] = useAddRiskToProjectMutation();
  const [updateRisk] = useUpdateRiskMutation();
  const [action, setAction] = useState(props.action || actionStatus.add);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    setAction(props.action || actionStatus.add);
  }, [props.action]);

  useEffect(() => {
    const projectMembers = getUsers(props.members);
    setUsers(structuredClone(projectMembers));
  }, [props.members]);

  const formatFormData = ({ formData }) => {
    const resolutionUpdated =
      formData.resolution?.id !== props.risk?.riskResolution?.resolution ||
      formData.reason !== props.risk?.riskResolution?.resolutionReason;

    const formattedFormData = {
      ...formData,
      impact: formData.impact.value,
      likelihood: formData.likelihood.value,
      owner: {
        ...formData.owner,
        id: formData.owner.id || formData.owner.value
      },
      projectId: props.project.id,
      id: props?.risk?.id,
      status: formData.status?.id,
      riskResolution: {
        resolution: formData.resolution?.id,
        resolutionReason: formData.reason
      },
      resolutionUpdated
    };
    return formattedFormData;
  };

  const handleActionClick = formData => {
    const risk = formatFormData({ formData });
    if (action === actionStatus.add) {
      addRiskToProject({ risk, projectId: props.project.id });
      props.onAdd();
    } else {
      updateRisk({ risk, projectId: props.project.id });
      props.onUpdate();
    }
  };

  const yupSchema = yup.object().shape({
    title: yup.string().required(t("risks:risks.fields.title.errorMsg")),
    description: yup
      .string()
      .required(t("risks:risks.fields.description.errorMsg")),
    impact: yup.object().required(t("risks:risks.fields.impact.errorMsg")),
    likelihood: yup
      .object()
      .required(t("risks:risks.fields.likelihood.errorMsg")),
    owner: yup.object().required(t("risks:risks.fields.assignTo.errorMsg")),
    status: yup
      .object()
      .test(
        "conditional-required",
        t("risks:risks.fields.status.errorMsg"),
        (value, context) => {
          const {
            options: {
              context: { action }
            }
          } = context;
          if (action === actionStatus.update) {
            return value !== undefined && value !== "";
          }
          return true;
        }
      ),
    resolution: yup.object().when("status.id", {
      is: value => value === "CLOSED",
      then: () =>
        yup.object().required(t("risks:risks.fields.resolution.errorMsg")),
      otherwise: () => yup.object().notRequired()
    }),
    reason: yup.string().when("status.id", {
      is: value => value === "CLOSED",
      then: () =>
        yup.string().required(t("risks:risks.fields.reason.errorMsg")),
      otherwise: () => yup.string().notRequired()
    })
  });

  const renderConditionalFields = () => {
    const valueComparer = value => value && value.id === "CLOSED";
    return (
      <Form.ConditionalField fieldName="status" valueComparer={valueComparer}>
        <Form.Dropdown
          name={"resolution"}
          label={t("risks:risks.fields.resolution.label")}
          required={true}
          items={translatedResolutionOptions}
          defaultValue={
            props.risk?.riskResolution
              ? {
                  id: props.risk?.riskResolution.resolution,
                  name: t(
                    `risks:risks.fields.resolution.status.${props.risk?.riskResolution.resolution}.label`
                  )
                }
              : ""
          }
        />
        <Form.TextArea
          name={"reason"}
          label={t("risks:risks.fields.reason.label")}
          required={true}
          defaultValue={props.risk?.riskResolution?.resolutionReason || ""}
        />
      </Form.ConditionalField>
    );
  };

  const translatedStatus = useMemo(() => {
    return baseStatus.map(item => ({
      ...item,
      name: t(item.i18nKey),
      value: item.key
    }));
  }, [t]);

  const translatedRiskStatus = useMemo(() => {
    return riskStatus.map(item => ({
      ...item,
      name: t(item.i18nKey)
    }));
  }, [t]);

  const translatedResolutionOptions = useMemo(() => {
    return resolutionOptions.map(item => ({
      ...item,
      name: t(item.i18nKey)
    }));
  }, [t]);

  const getRiskActionLabel = () => {
    if (action === actionStatus.add) {
      return t("risks:ui.action.addRisk.label");
    }
    return t("risks:ui.action.updateRisk.label");
  };

  return (
    <ModalForm
      boxClassName="add-risk__main"
      title={getRiskActionLabel()}
      handleCancel={props.onCancel}
      handleSubmit={handleActionClick}
      yupSchema={yupSchema}
      submitLabel={getRiskActionLabel()}
      context={{ action }}
    >
      <Form.TextField
        name={"title"}
        label={t("risks:risks.fields.title.label")}
        required={true}
        placeholder={t("risks:risks.fields.title.placeholder")}
        defaultValue={props.risk?.title || ""}
      />
      <Form.TextArea
        label={t("risks:risks.fields.description.label")}
        name={"description"}
        required={true}
        defaultValue={props?.risk?.description || ""}
      />
      <Form.Dropdown
        name={"impact"}
        label={t("risks:risks.fields.impact.label")}
        required={true}
        items={translatedStatus}
        showSearch={false}
        sortComparator={(a, b) => a.id - b.id}
        defaultValue={
          translatedStatus.find(item => item.key === props?.risk?.impact) ||
          translatedStatus[2]
        }
      />
      <Form.Dropdown
        name={"likelihood"}
        label={t("risks:risks.fields.likelihood.label")}
        required={true}
        items={translatedStatus}
        showSearch={false}
        sortComparator={(a, b) => a.id - b.id}
        defaultValue={
          translatedStatus.find(item => item.key === props?.risk?.likelihood) ||
          translatedStatus[2]
        }
      />
      <Form.Dropdown
        name={"owner"}
        label={t("risks:risks.fields.assignTo.label")}
        required={true}
        items={users
          .map(user => {
            if (!user.updated) {
              user.name = `${user.name} (${user.memberOf.name})`;
              user.updated = true;
            }
            return user;
          })
          .sort((a, b) => {
            if (a.firstname > b.firstname) {
              return 1;
            } else {
              return -1;
            }
          })}
        showSearch={true}
        sortComparator={(a, b) => a.firstname - b.firstname}
        defaultValue={props?.risk?.owner || ""}
      />
      {action === actionStatus.update && (
        <Form.Dropdown
          name={"status"}
          label={t("risks:risks.fields.status.label")}
          required={true}
          items={translatedRiskStatus}
          showSearch={false}
          sortComparator={(a, b) => a.id.localeCompare(b.id)}
          defaultValue={
            props?.risk?.status
              ? {
                  id: props?.risk?.status,
                  name: t(
                    `risks:risks.fields.status.${props?.risk?.status}.label`
                  )
                }
              : translatedRiskStatus[0]
          }
        />
      )}
      {renderConditionalFields()}
    </ModalForm>
  );
};

export default AddOrUpdateRisk;
