import { finalPackageConstants } from "../constants";

const initialState = {
  isFinalPackageTipsLoading: false,
  finalPackageTipsLoaded: false,
  finalPackageTips: [],
  finalPackageTipsLoadingError: "",

  isFinalPackageTipUpdating: false,
  finalPackageTipUpdated: false,
  finalPackageTipUpdateError: "",

  isFinalPackageTipDeleting: false,
  finalPackageTipDeleted: false,
  finalPackageTipDeleteError: "",

  finalPackageShareStatus: false,
  isFinalPackageShareStatusInitialised: false,
  isFinalPackageShareStatusLoading: false,
  finalPackageShareStatusError: "",

  documentsLoading: false,
  documents: [],
  uploading: false,
  uploadedDocuments: [],
  revisionsLoading: false,
  documentRevisions: [],
  finalPackageFolder: {},
  error: "",

  isFinalPackageProcessLoading: false,
  finalPackageProcessEntries: [],
  finalPackageProcessLoaded: false,
  finalPackageProcessError: "",

  isFinalPackageProcessCreating: false,
  finalPackageProcessCreated: false,
  finalPackageProcessCreateError: "",

  isFinalPackageProcessDeleting: false,
  finalPackageProcessDeleted: false,
  finalPackageProcessDeleteError: ""
};

export function finalPackage(state = initialState, action = {}) {
  switch (action.type) {
    case finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_REQUEST:
      return {
        ...state,
        isFinalPackageProcessLoading: true,
        finalPackageProcessLoaded: false,
        finalPackageProcessError: ""
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_SUCCESS:
      return {
        ...state,
        isFinalPackageProcessLoading: false,
        finalPackageProcessEntries: action.finalPackageProcessEntries ?? [],
        finalPackageProcessLoaded: true
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_PROCESSES_FAILURE:
      return {
        ...state,
        isFinalPackageProcessLoading: false,
        finalPackageProcessLoaded: false,
        finalPackageProcessError: action.error?.message
      };
    case finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_REQUEST:
      return {
        ...state,
        isFinalPackageProcessCreating: true,
        finalPackageProcessCreated: false,
        finalPackageProcessCreateError: ""
      };
    case finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_SUCCESS:
      return {
        ...state,
        isFinalPackageProcessCreating: false,
        finalPackageProcessCreated: true
      };
    case finalPackageConstants.CREATE_FINAL_PACKAGE_PROCESSES_FAILURE:
      return {
        ...state,
        isFinalPackageProcessCreating: false,
        finalPackageProcessCreated: false,
        finalPackageProcessCreateError: action.error?.message
      };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_PROCESSES_REQUEST:
      return { ...state };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_PROCESSES_SUCCESS:
      return { ...state };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_PROCESSES_FAILURE:
      return { ...state };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_REQUEST:
      return {
        ...state,
        isFinalPackageProcessDeleting: true,
        finalPackageProcessDeleted: false,
        finalPackageProcessDeleteError: ""
      };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_SUCCESS:
      return {
        ...state,
        isFinalPackageProcessDeleting: false,
        finalPackageProcessDeleted: true
      };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_PROCESSES_FAILURE:
      return {
        ...state,
        isFinalPackageProcessDeleting: false,
        finalPackageProcessDeleted: false,
        finalPackageProcessDeleteError: action.error?.message
      };
    case finalPackageConstants.RESET_GET_FINAL_PACKAGE_PROCESSES:
      return {
        ...state,
        isFinalPackageProcessLoading: false,
        finalPackageProcessEntries: [],
        finalPackageProcessLoaded: false,
        finalPackageProcessError: ""
      };
    case finalPackageConstants.RESET_CREATE_FINAL_PACKAGE_PROCESSES:
      return {
        ...state,
        isFinalPackageProcessCreating: false,
        finalPackageProcessCreated: false,
        finalPackageProcessCreateError: ""
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_TIPS_REQUEST:
      return { ...state, isFinalPackageTipsLoading: true };
    case finalPackageConstants.GET_FINAL_PACKAGE_TIPS_SUCCESS:
      return {
        ...state,
        isFinalPackageTipsLoading: false,
        finalPackageTipsLoaded: true,
        finalPackageTips: action.finalPackageTips || []
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_TIPS_FAILURE:
      return {
        ...state,
        isFinalPackageTipsLoading: false,
        finalPackageTipsLoaded: false,
        finalPackageTipsLoadingError: action.error?.message
      };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_REQUEST:
      return {
        ...state,
        isFinalPackageTipUpdating: true,
        finalPackageTipUpdated: false
      };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_SUCCESS:
      return {
        ...state,
        isFinalPackageTipUpdating: false,
        finalPackageTipUpdated: true
      };
    case finalPackageConstants.UPDATE_FINAL_PACKAGE_TIP_FAILURE:
      return {
        ...state,
        finalPackageTipUpdateError: action.error?.message,
        isFinalPackageTipUpdating: false,
        finalPackageTipUpdated: false
      };
    case finalPackageConstants.RESET_UPDATE_FINAL_PACKAGE_TIP:
      return {
        ...state,
        isFinalPackageTipUpdating: false,
        finalPackageTipUpdated: false,
        finalPackageTipUpdateError: ""
      };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_REQUEST:
      return {
        ...state,
        isFinalPackageTipDeleting: true,
        finalPackageTipDeleted: false
      };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_SUCCESS:
      return {
        ...state,
        isFinalPackageTipDeleting: false,
        finalPackageTipDeleted: true
      };
    case finalPackageConstants.DELETE_FINAL_PACKAGE_TIP_FAILURE:
      return {
        ...state,
        finalPackageTipDeleteError: action.error?.message,
        isFinalPackageTipDeleting: false,
        finalPackageTipDeleted: false
      };
    case finalPackageConstants.RESET_DELETE_FINAL_PACKAGE_TIP:
      return {
        ...state,
        isFinalPackageTipDeleting: false,
        finalPackageTipDeleted: false,
        finalPackageTipDeleteError: ""
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_REQUEST:
      return {
        ...state,
        documentsLoading: true,
        error: ""
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        documents: action.documents,
        finalPackageFolder: action.finalPackageFolder,
        error: ""
      };
    case finalPackageConstants.GET_FINAL_PACKAGE_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        error: action.error
      };
    case finalPackageConstants.RESET_GET_FINAL_PACKAGE_DOCUMENTS:
      return {
        ...state,
        documentsLoading: false,
        documents: [],
        finalPackageFolder: {},
        error: ""
      };
    case finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_REQUEST:
    case finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_REQUEST:
      return {
        ...state,
        isFinalPackageShareStatusLoading: true
      };
    case finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_SUCCESS:
    case finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_SUCCESS:
      return {
        ...state,
        finalPackageShareStatus: action.shareStatus.shareStatus,
        isFinalPackageShareStatusLoading: false,
        isFinalPackageShareStatusInitialised: true
      };
    case finalPackageConstants.GET_FINALPACKAGE_SHARESTATUS_FAILURE:
    case finalPackageConstants.SET_FINALPACKAGE_SHARESTATUS_FAILURE:
      return {
        ...state,
        finalPackageShareStatus: false,
        isFinalPackageShareStatusLoading: false,
        finalPackageShareStatusError: action.error.key ?? action.error.message
      };
    case finalPackageConstants.RESET:
      return { ...initialState };
    default:
      return state;
  }
}
