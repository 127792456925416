import { useCallback, useRef } from "react";

export function useScrollSync() {
  const elementsWithHandlers = useRef([]); // [{ element, handler }, ...]

  const syncElements = useCallback(elements => {
    function getScrollPositions() {
      return elements.map(el => ({
        scrollLeft: el.scrollLeft,
        scrollTop: el.scrollTop
      }));
    }

    let scrollPositions = getScrollPositions();
    const handler = () => {
      let currentPositions = getScrollPositions();

      for (let [i, position] of currentPositions.entries()) {
        for (let dim of ["scrollLeft", "scrollTop"]) {
          if (position[dim] != scrollPositions[i][dim]) {
            for (let element of elements) {
              element[dim] = position[dim];
            }
          }
        }
      }

      scrollPositions = currentPositions;
    };

    elements.forEach(el => {
      el.addEventListener("scroll", handler);
      elementsWithHandlers.current?.push({ element: el, handler });
    });
  }, []);

  const unsyncElements = useCallback(() => {
    elementsWithHandlers.current?.forEach(({ element, handler }) => {
      element.removeEventListener("scroll", handler);
    });
    elementsWithHandlers.current = [];
  }, []);

  return {
    syncElements,
    unsyncElements
  };
}
