export const manageHostUsersConstants = {
  CLEAR_ERROR: "CLEAR_ERROR_HOST_USERS",
  RESET: "RESET_HOST_USERS",

  GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST",
  GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS",
  GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE",

  GET_ENGAGEMENT_TYPES_REQUEST: "GET_ENGAGEMENT_TYPES_REQUEST",
  GET_ENGAGEMENT_TYPES_SUCCESS: "GET_ENGAGEMENT_TYPES_SUCCESS",
  GET_ENGAGEMENT_TYPES_FAILURE: "GET_ENGAGEMENT_TYPES_FAILURE",

  CREATE_TAG_REQUEST: "CREATE_TAG_REQUEST",
  CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILURE: "CREATE_TAG_FAILURE",

  UPDATE_TAG_REQUEST: "UPDATE_TAG_REQUEST",
  UPDATE_TAG_SUCCESS: "UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAILURE: "UPDATE_TAG_FAILURE",

  DELETE_TAG_REQUEST: "DELETE_TAG_REQUEST",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE"
};
