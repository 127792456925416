import React, { useCallback, useMemo } from "react";

import { Trans } from "react-i18next";

import websheetWizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import RowSelectionWizard, { ROW_SELECTION_TYPE } from "./RowSelectionWizard";

export function useUnnecessaryRowSelectionWizard() {
  const { ref, callbackRef } = useCallbackRef();

  const getStepDescription = useCallback(() => {
    return (
      <>
        <Trans
          i18nKey="common:ui.websheet.actions.cleaningWizard.step.selectUnnecessaryRows.description"
          shouldUnescape={true}
          components={{ underline: <u /> }}
        />
      </>
    );
  }, []);

  const getWizard = useCallback(
    commonWizardProps => {
      return (
        <RowSelectionWizard
          {...commonWizardProps}
          ref={callbackRef}
          firstRowHeader={true}
          rowSelectionType={ROW_SELECTION_TYPE.MULTIPLE}
        />
      );
    },
    [callbackRef]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  const postStep = useCallback((incomingData, context) => {
    const processedSheetName = incomingData.websheet[0].sheet;
    context.setProcessedSheets(prev => [...prev, processedSheetName]);

    const { aggregatedData } = context;
    aggregatedData.current = websheetWizardUtilities.prepareDataForAggregate(
      incomingData,
      aggregatedData.current
    );
    return aggregatedData.current;
  }, []);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission,
    aggregate: true,
    postStep
  };
}
