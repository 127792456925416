function stripIdFromFileName({
  fileName,
  id
}: {
  fileName: string;
  id: number;
}) {
  return fileName.replace(new RegExp(`-${id}(\.[^\.]+)$`), "$1");
}

export default { stripIdFromFileName };
