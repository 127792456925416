import React, { useEffect, useRef, useState } from "react";

import { createPortal } from "react-dom";

import "./OTTooltip.scss";

interface OTTooltipProps {
  content: unknown;
  visible: boolean;
}

const OTTooltip = ({ content, visible }: OTTooltipProps) => {
  const [style, setStyle] = useState({});
  const isMounted = useRef(false);
  const tooltipRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    function handleMouseMoveEvent(event: MouseEvent) {
      handleMouseMove(event, setStyle, visible, tooltipRef);
    }
    if (isMounted.current) {
      if (visible) {
        window.addEventListener("mousemove", handleMouseMoveEvent);
      } else {
        window.removeEventListener("mousemove", handleMouseMoveEvent);
      }

      return () => {
        window.removeEventListener("mousemove", handleMouseMoveEvent);
      };
    }
  }, [visible]);

  return visible ? (
    // portal to render the tooltip outside of the parent component
    createPortal(
      <div className="ot-tooltip" style={{ ...style }} ref={tooltipRef}>
        {content}
      </div>,
      document.body
    )
  ) : (
    <></>
  );
};

function handleMouseMove(
  event: unknown,
  setStyleState: unknown,
  visible = false,
  tooltipRef?: React.MutableRefObject<HTMLDivElement>
) {
  let eventDoc, doc, body;

  if (!visible) {
    setStyleState({
      display: "none",
      visibility: "hidden",
      position: "absolute",
      top: "0",
      opacity: 0
    });
    return;
  }

  // If pageX/Y aren't available and clientX/Y are,
  if (event.pageX == null && event.clientX != null) {
    eventDoc = event.target ? event.target.ownerDocument : document;
    doc = eventDoc.documentElement;
    body = eventDoc.body;

    const scrollLeft = doc?.scrollLeft || body?.scrollLeft || 0;
    const clientLeft = doc?.clientLeft || body?.clientLeft || 0;
    const scrollTop = doc?.scrollTop || body?.scrollTop || 0;
    const clientTop = doc?.clientTop || body?.clientTop || 0;
    event.pageX = event.clientX + scrollLeft - clientLeft;
    event.pageY = event.clientY + scrollTop - clientTop;
  }

  let left = event.pageX + 8;
  let top = event.pageY + 12;
  if (left + tooltipRef?.current?.clientWidth > window.innerWidth) {
    left = window.innerWidth - tooltipRef?.current?.clientWidth - 8;
  }
  if (top + tooltipRef?.current?.clientHeight > window.innerHeight) {
    top = window.innerHeight - tooltipRef?.current?.clientHeight - 8;
  }

  setStyleState?.({
    position: "fixed",
    display: "flex",
    visibility: "visible",
    opacity: 1,
    left,
    top
  });
}
export default OTTooltip;
