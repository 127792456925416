import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";
import { useAuthUser } from "@shared/hooks/useAuthUser.tsx";

import { AccessLevel } from "@app/types/Permission.ts";
import { ResourceName } from "@app/types/Resource.ts";

import { Button } from "@atoms/Button";

import AddInsightsBoard from "@components/organisms/AddInsightsBoard/AddInsightsBoard";
import InsightsBoardTable from "@components/organisms/InsightsBoardTable";

import PageTemplate from "../PageTemplate/PageTemplate";

const ManageInsightsBoardTemplate = props => {
  const { insightsBoards, error } = props;
  const { user } = useAuthUser();
  const { t } = useTranslation();
  const [showCreateInsightBoardModal, setShowCreateInsightBoardModal] =
    useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const insightsBoardTable = useMemo(() => {
    return <InsightsBoardTable insightsBoards={insightsBoards} />;
  }, [insightsBoards]);

  const pageActions = useMemo(() => {
    if (!user.checkAccess(ResourceName.INSIGHTS, AccessLevel.CREATE)) {
      return <></>;
    }
    return (
      <Button
        label={t("common:insights.board.create.label")}
        onClick={() => setShowCreateInsightBoardModal(true)}
      />
    );
  }, [t, user]);

  const handleSubmit = async insightsBoard => {
    const error = await props.handleSubmit(insightsBoard);
    if (!error) {
      handleClose();
    } else {
      setFormErrorMessage(error);
    }
  };

  const handleClose = () => {
    setShowCreateInsightBoardModal(false);
    setFormErrorMessage("");
  };

  return (
    <>
      <PageTemplate
        header={{
          title: t("common:insights.board.page.title_other"),
          actions: pageActions
        }}
        body={{
          primary: insightsBoardTable
        }}
        other={{
          error,
          secondaryNavDisabled: true
        }}
      />
      <Popup visibility={showCreateInsightBoardModal} width="60rem">
        <AddInsightsBoard
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          errorMessage={formErrorMessage}
        />
      </Popup>
    </>
  );
};

export default ManageInsightsBoardTemplate;
