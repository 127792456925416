import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import { useGetAiCleaningAssistantQuery } from "@shared/hooks";

import WizardWebsheet from "../../WizardWebsheet";

const AiWizard = React.forwardRef((props, fwdRef) => {
  const {
    onSheetLoading,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged,
    defaultSheetName,
    documentId,
    documentRevisionId,
    extra
  } = props;

  const { data: aiData, isFetching } = useGetAiCleaningAssistantQuery(
    {
      documentId,
      documentRevisionId,
      extra
    },
    { skip: !documentId || !documentRevisionId }
  );

  const hotTableComponent = useRef(null);
  const [currentSheetName, setCurrentSheetName] = useState(defaultSheetName);

  // localData is what's used to render the table
  const [localData, setLocalData] = useState();

  useEffect(() => {
    if (aiData) {
      setLocalData(aiData);
      setCurrentSheetName(aiData?.[0]?.sheet);
    }
  }, [aiData]);

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const onCurrentSheetNameChanged = useCallback(
    sheetName => {
      setCurrentSheetName(sheetName);
      parentOnCurrentSheetNameChanged?.(sheetName);
    },
    [parentOnCurrentSheetNameChanged]
  );

  const canSubmit = useCallback(() => {
    return true;
  }, []);

  const getDataForSubmission = useCallback(() => {
    const result = localData;
    // output websheet data; should be same structure
    // savedState is any information that is required to 'playback' this operation (probably don't need it!)

    return {
      websheet: result,
      savedState: {}
    };
  }, [localData]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit
    }),
    [getDataForSubmission, canSubmit]
  );

  return (
    <WizardWebsheet
      loading={isFetching}
      currentSheetName={currentSheetName}
      data={localData}
      firstRowHeader={true}
      rowHeaders={true}
      // afterGetRowHeader={afterGetRowHeader}
      hotTableComponent={setHotTableComponent}
      onSheetLoading={onSheetLoading}
      onCurrentSheetNameChanged={onCurrentSheetNameChanged}
    />
  );
});

AiWizard.displayName = "AiWizard";

export default AiWizard;
