import { useCallback, useEffect, useRef, useState } from "react";

import { finalPackageService } from "@shared/services/finalPackageService";

export function useUpdateFinalPackageProcess() {
  const [updated, setUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState("");
  const [updateEntry, setUpdateEntry] = useState(null);
  const cancelRequest = useRef(false);

  useEffect(() => {
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const updateProcess = useCallback(
    entry => {
      if (isUpdating && entry?.id === updateEntry?.id) return;
      setUpdated(false);
      setError("");
      setUpdateEntry(entry);
      cancelRequest.current = false;
    },
    [isUpdating, updateEntry?.id]
  );

  useEffect(() => {
    cancelRequest.current = false;
    if (!updateEntry?.id || isUpdating || updated || error) return;
    setIsUpdating(true);
    finalPackageService
      .updateFinalPackageProcessEntry(updateEntry)
      .then(() => {
        if (!cancelRequest.current) {
          setUpdated(true);
          setIsUpdating(false);
        }
      })
      .catch(err => {
        if (!cancelRequest.current) {
          setIsUpdating(false);
          setError(err);
        }
      });
    return () => {
      cancelRequest.current = true;
    };
  }, [error, isUpdating, updateEntry, updated]);

  return { updated, isUpdating, error, setError, updateProcess };
}
