import { useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { manageProjectDocumentsActions } from "@shared/actions/manageProjectDocumentsActions";
import { systemConstants } from "@shared/constants";

const documentStatus = systemConstants.project.document.status;

export function useUpdateDocument(document, projectFolder) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const updateDocumentProperties = useCallback(
    ({ name, status, properties }) => {
      if (
        (!name && !properties) ||
        projectFolder.documents.some(
          doc => doc.name === name && doc.id !== document.id
        )
      ) {
        setError(true);
        return false;
      }

      setError(false);
      const updatedName = name ?? document.name;
      const updatedProperties = properties ?? document.properties;
      const updatedStatus =
        status === documentStatus.none
          ? systemConstants.document_status_uploaded
          : status;
      dispatch(
        manageProjectDocumentsActions.updateProjectDocumentProperties({
          ...document,
          name: updatedName,
          properties: updatedProperties,
          status: updatedStatus
        })
      );
      return true;
    },
    [dispatch, document, projectFolder]
  );

  return {
    error,
    updateDocumentProperties
  };
}
