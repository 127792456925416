import React from "react";

import PropTypes from "prop-types";

import { Inline } from "@fermions";

import { Icon } from "@atoms/Icon";

import BoxTemplate from "@components/templates/BoxTemplate";

import "./InfoMessageBox.scss";

const InfoMessageBox = props => {
  const { iconProps, message } = props;
  return (
    <BoxTemplate className="info-message-box">
      <Inline
        className="info-message"
        padding="100"
        width="100"
        alignment="center"
        gap="050"
      >
        <Icon {...iconProps} />
        {message}
      </Inline>
    </BoxTemplate>
  );
};

InfoMessageBox.propTypes = {
  iconProps: PropTypes.shape({
    ...Icon.propTypes
  }).isRequired,
  message: PropTypes.string
};

export default InfoMessageBox;
