import React from "react";

import defaultUserAvatar from "../../assets/images/defaultUserAvatar.png";
import "./avatar.scss";

const Avatar = props => {
  return (
    <div className="avatar__container">
      <img
        style={{ width: props.width, height: props.height }}
        src={
          (props.user.avatar && props.user.avatar.image) || defaultUserAvatar
        }
        alt={props.user.name}
        className="avatar__image"
      />
    </div>
  );
};

export default Avatar;
