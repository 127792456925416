import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useDownloadFile } from "@shared/hooks/useDownloadFile";

// Makes downloading files easier by wrapping the anchor clicking logic within this component
// Download will begin as soon as this component is rendered.
// onError: called whenever an error occurs prior to the file being received
// onDownloading: called when the data from the file has been received and downloading as started
const FileDownloader = props => {
  const { downloadParams, onError, onDownloading } = props;
  const [sequenceId, setSequenceId] = useState(0);
  const { anchorRef, url, filename, download } = useDownloadFile({
    onError,
    onDownloading
  });

  useEffect(() => {
    if (downloadParams && sequenceId !== downloadParams.sequenceId) {
      setSequenceId(downloadParams.sequenceId);
      download(downloadParams);
    }
  }, [download, downloadParams, sequenceId]);

  return (
    <>
      <a
        className="file-downloader"
        href={url}
        download={filename}
        ref={anchorRef}
      ></a>
    </>
  );
};

FileDownloader.propTypes = {
  downloadParams: PropTypes.shape({
    apiUrl: PropTypes.string.isRequired,
    method: PropTypes.oneOf(["POST"]),
    data: PropTypes.object,
    sequenceId: PropTypes.number.isRequired
  }).isRequired,
  onError: PropTypes.func,
  onDownloading: PropTypes.func
};

export default FileDownloader;
