import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import { clientProjectsService } from "./clientProjectsService";
import { clientService } from "./clientService";
import prepareHeaders from "./rtkUtilities";

const onProjectMutated = async ({ dispatch, queryFulfilled }) => {
  try {
    await queryFulfilled;
    dispatch(
      clientProjectsService.util.invalidateTags(["ClientProjectForMenu"])
    );
    dispatch(clientService.util.invalidateTags(["ClientForMenu"]));
  } catch (e) {}
};

export const projectService = createApi({
  reducerPath: "projectServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: [
    "Project",
    "DetailedProject",
    "ClientProject",
    "ProjectManagers",
    "ProjectMilestones",
    "ProjectMilestoneComment",
    "ClientProjectsListByEngagement"
  ],
  endpoints: builder => ({
    getClientProjectsWithSameEngagement: builder.query({
      query: ({ projectId }) => ({
        url: "/api/projects",
        params: {
          for: "otherSimilarProjects",
          projectId
        }
      }),
      providesTags: ["ClientProjectsListByEngagement"]
    }),
    getMyProjects: builder.query({
      query: ({ withDetails }) =>
        `/api/users/me/projects?${new URLSearchParams({ withDetails })}`,
      providesTags: (result, _, { withDetails }) =>
        result
          ? [
              "Project",
              "DetailedProject",
              { type: "DetailedProject", id: "LIST" },
              { type: "Project", id: "LIST" },
              ...result.data.map(({ id }) => ({
                type: withDetails ? "DetailedProject" : "Project",
                id
              }))
            ]
          : [{ type: "Project", id: "LIST" }]
    }),
    getProjectById: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}`,
      providesTags: (_, __, { projectId }) => [
        { type: "Project", id: projectId }
      ]
    }),
    getProjectForSelection: builder.query({
      query: ({ userId }) => ({
        url: "/api/projects",
        params: {
          for: "projectSelection",
          ...(userId ? { targetUserId: userId } : {})
        }
      }),
      providesTags: [{ type: "Project", id: "LIST" }]
    }),
    getProjectForSelectionWithAllStatuses: builder.query({
      query: () => ({
        url: "/api/projects",
        params: {
          for: "projectSelection",
          allStatuses: true
        }
      }),
      providesTags: [{ type: "Project", id: "LIST" }]
    }),
    getProjectMilestones: builder.query({
      query: ({ projectId, onlyEngagementTypeMilestones }) =>
        onlyEngagementTypeMilestones
          ? `/api/projects/${projectId}/milestones?type=engagementType`
          : `/api/projects/${projectId}/milestones`,
      providesTags: ["ProjectMilestones"]
    }),
    getProjectMilestoneComment: builder.query({
      query: ({ milestoneId, projectId }) =>
        `/api/projects/${projectId}/milestones/${milestoneId}/comment`,
      providesTags: ["ProjectMilestoneComment"]
    }),
    copyProject: builder.mutation({
      query: ({ payload }) => ({
        url: "/api/projects/copyProject",
        method: "POST",
        body: payload
      }),
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        "ClientProjectsListByEngagement"
      ]
    }),
    getProjectManagers: builder.query({
      query: () => `/api/users?filter=projectManagers`,
      providesTags: ["ProjectManagers"]
    }),
    addProject: builder.mutation({
      query: ({ project, options }) => ({
        url: "/api/projects",
        params: {
          fillUsers: options?.fillUsers,
          templateId: options?.templateId
        },
        method: "POST",
        body: project
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        { type: "DetailedProject", id: "LIST" },
        "ClientProjectsListByEngagement"
      ]
    }),
    updateProject: builder.mutation({
      query: ({ project }) => ({
        url: `/api/projects/${project.id}`,
        method: "PUT",
        body: project
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: (_, __, { project }) =>
        project?.id
          ? [
              { type: "DetailedProject", id: project?.id },
              { type: "Project", id: project?.id },
              "ClientProjectsListByEngagement"
            ]
          : []
    }),
    upsertProjectMilestones: builder.mutation({
      query: ({ projectId, milestones }) => ({
        url: `/api/projects/${projectId}/milestones`,
        method: "POST",
        body: { milestones }
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: "Project", id: projectId },
        "ProjectMilestones",
        "ProjectMilestoneComment"
      ]
    }),
    upsertProjectMilestoneComment: builder.mutation({
      query: ({ projectId, milestoneId, comment }) => ({
        url: `/api/projects/${projectId}/milestones/${milestoneId}/comment`,
        method: "POST",
        body: { comment }
      }),
      invalidatesTags: ["ProjectMilestoneComment"]
    }),
    deleteProject: builder.mutation({
      query: ({ projectId }) => ({
        url: `/api/projects/${projectId}`,
        method: "DELETE"
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: ["ClientProjectsListByEngagement"]
    })
  })
});

export const {
  useGetProjectMilestonesQuery,
  useGetProjectMilestoneCommentQuery,
  useGetClientProjectsWithSameEngagementQuery,
  useGetMyProjectsQuery,
  useGetProjectByIdQuery,
  useGetProjectForSelectionQuery,
  useGetProjectForSelectionWithAllStatusesQuery,
  useCopyProjectMutation,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useUpsertProjectMilestonesMutation,
  useUpsertProjectMilestoneCommentMutation,
  util: projectServiceUtil,
  useGetProjectManagersQuery,
  useDeleteProjectMutation
} = projectService;
