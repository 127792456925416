import React from "react";

import PropTypes from "prop-types";

import "./PieChartToolTip.scss";

const PieChartToolTip = props => {
  const {
    active,
    payload,
    isSecondaryStyle,
    formatHoverText,
    dataKey,
    dataType
  } = props;

  const getSecondaryClass = () => {
    if (isSecondaryStyle) {
      return "ot-pie-chart-tooltip--secondary";
    }
    return "";
  };

  if (!active || !payload?.length) {
    return null;
  }
  return (
    <>
      {payload.map((p, i) => {
        const value = formatHoverText(
          p.payload[dataKey],
          dataType,
          p.payload.info
        );
        return (
          <div
            className={
              "custom-tooltip ot-pie-chart-tooltip " + getSecondaryClass()
            }
            key={p.name + i}
          >
            <p className="label ot-pie-chart-tooltip__text">{`${p.payload.label} : ${value}`}</p>
          </div>
        );
      })}
    </>
  );
};

PieChartToolTip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  isSecondaryStyle: PropTypes.bool
};

export default PieChartToolTip;
