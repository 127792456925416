import React, { useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";

import "./Text.scss";

export enum FontColor {
  INHERIT = "inherit",
  PRIMARY = "primary", // text
  SECONDARY = "secondary", // sub-text
  TERTIARY = "tertiary", // description
  DISABLED = "disabled",
  INVERTED = "inverted", // invert color of text
  INVERTED_DISABLED = "inverted-disabled"
}

export enum FontSize {
  INHERIT = "inherit",
  XXS = "xxs", // 8px
  XS = "xs", // 10px
  S = "s", // 12px
  M = "m", // 14px -- common
  L = "l", // 16px
  XL = "xl", // 20px
  X2L = "2xl", // 24px
  X3L = "3xl", // 29px
  X4L = "4xl" // 35px
}

export enum FontWeight {
  INHERIT = "inherit",
  LIGHT = "light",
  REGULAR = "regular",
  MEDIUM = "medium",
  SEMI_BOLD = "semi-bold",
  BOLD = "bold"
}

export interface TextProps {
  text: string;
  color?: FontColor;
  size?: FontSize;
  weight?: FontWeight;
  decoration?: "line-through" | "underline";
  truncate?: boolean;
  maxWidth?: string;
  maxLines?: number;
  className?: string;
}

export const Text = ({
  text,
  color = FontColor.INHERIT,
  size = FontSize.INHERIT,
  weight = FontWeight.REGULAR,
  decoration,
  truncate,
  maxWidth,
  maxLines,
  className
}: TextProps) => {
  const textStyle = useMemo(() => {
    const style: React.CSSProperties = {};

    if (truncate) {
      style.textOverflow = "ellipsis";
      style.maxWidth = `1000px`;
      style.display = "inline-block";
      style.whiteSpace = "nowrap";
      style.overflow = "hidden";
    }

    if (maxWidth) {
      style.maxWidth = maxWidth;
    }

    if (maxLines) {
      style.WebkitLineClamp = maxLines;
      style.display = "-webkit-box";
      style.WebkitBoxOrient = "vertical";
      style.whiteSpace = "inherit";
      style.overflow = "hidden";
      style.textOverflow = "ellipsis";
    }

    return style;
  }, [maxLines, maxWidth, truncate]);

  return (
    <p
      className={classNames([
        className ?? "",
        "ot-text",
        `ot-text--color-${color}`,
        `ot-text--size-${size}`,
        `ot-text--weight-${weight}`,
        `ot-text--decoration-${decoration}`
      ])}
      style={textStyle}
    >
      {text}
    </p>
  );
};

export default Text;
