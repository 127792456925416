import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import { Button, ButtonVariant } from "@atoms/Button";
import { Icon, IconColor } from "@atoms/Icon";
import { FontWeight, Text } from "@atoms/Text";

import ReplayWizard from "./ReplayWizard";
import "./ReplayWizard.scss";

export function useReplayWizard({ templateHeaders, onSelectCleanseProcess }) {
  const { t } = useTranslation();
  const { ref, callbackRef } = useCallbackRef();

  const getStepDescription = useCallback(() => {
    return (
      <div className="replay-wizard__description-hint">
        <Text
          text={t(
            "common:ui.websheet.actions.cleaningWizard.step.replay.description"
          )}
          weight={FontWeight.BOLD}
        />
        <Icon
          name="info"
          color={IconColor.ACCENT}
          hoverElement={t(
            "common:ui.websheet.actions.cleaningWizard.step.replay.hint"
          )}
        />
      </div>
    );
  }, [t]);

  const getStepButtons = useCallback(
    ({ onNext, disableProgression }) => {
      return (
        <>
          <Button
            variant={ButtonVariant.TEXT}
            iconName="auto_awesome"
            label={t("common:ui.forms.selectCleanse.label")}
            onClick={onSelectCleanseProcess}
          />
          <Button
            label={t("common:ui.forms.confirm.label")}
            disabled={disableProgression}
            onClick={onNext}
          />
        </>
      );
    },
    [t, onSelectCleanseProcess]
  );

  const getWizard = useCallback(
    commonWizardProps => {
      return (
        <ReplayWizard
          {...commonWizardProps}
          ref={callbackRef}
          templateHeaders={templateHeaders}
        />
      );
    },
    [callbackRef, templateHeaders]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: true
    };
  }, []);

  const shouldEnterStep = useCallback(() => true, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  return {
    getStepDescription,
    getWizard,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission,
    getStepButtons
  };
}
