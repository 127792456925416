import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useAuthUser } from "@shared/hooks/useAuthUser";

import { AccessLevel, ResourceName } from "@app/types";

import { Icon, IconColor, IconFillStyle } from "@atoms/Icon";

import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import "./EntityList.scss";

/**
 * Component for showing List of entities.
 *
 * @param   {Object} props
 * @param   {Object[]}  props.entities the entities
 * @param   {string}  props.entities[].name entity name
 * @param   {string}  props.entities[].externalId entity externalId
 * @param   {{id: number, engagement: Object}}  props.project the project
 * @component
 * @example
 * const project = ...;
 * return (
 *   <EntityList entities={project.entities} projectId={project.id}/>
 * )
 */
const EntityList = ({ entities, project }) => {
  const { t } = useTranslation();
  const { user: authUser } = useAuthUser();

  const headerAction = () => {
    if (!authUser.isHostUser) {
      return <></>;
    }
    const canEditProject =
      authUser.checkAccess(
        ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
        AccessLevel.UPDATE
      ) ||
      (authUser.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.UPDATE
      ) &&
        project.isMember);

    if (!canEditProject) {
      return <></>;
    }

    return (
      <NavLink
        to={`/projects/${project.id}/edit`}
        state={{ engagement: project.engagement }}
      >
        <Icon
          name="edit"
          fillStyle={IconFillStyle.FILLED}
          color={IconColor.PRIMARY}
        />
      </NavLink>
    );
  };

  return (
    <DashboardBoxTemplate
      title={t("common:ui.projects.entities.label")}
      boxClassName={"entity-list-box"}
      action={headerAction()}
    >
      <ul>
        {entities?.map(({ name, externalId }) => (
          <li key={externalId}>{name}</li>
        ))}
      </ul>
    </DashboardBoxTemplate>
  );
};

EntityList.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      externalId: PropTypes.string.isRequired
    })
  ).isRequired,
  project: PropTypes.object.isRequired
};

export default EntityList;
