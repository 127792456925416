import React from "react";

import Popup from "@shared-components/popup/Popup";

import "./ProjectsTableTemplate.scss";

const ProjectsTableTemplate = props => {
  const {
    data,
    isMobile,
    showRiskFlag,
    renderDataRow,
    renderWarningModal,
    isBelongHost,
    tableConstants,
    projectDeactiveWarningVisibility,
    isProjectManagerFieldEnabled
  } = props;
  return (
    <>
      <table className="projects-table">
        <tbody className="projects-table__body">
          {!isMobile ? (
            <tr className="projects-table__row projects-table__row--header">
              <th className="projects-table__head">{tableConstants.mail}</th>
              {showRiskFlag && (
                <th
                  data-test="risks-column-header"
                  className="projects-table__head"
                >
                  {tableConstants.risks}
                </th>
              )}
              <th className="projects-table__head">{tableConstants.name}</th>
              <th className="projects-table__head">{tableConstants.status}</th>
              <th className="projects-table__head">
                {tableConstants.upcomingMilestone}
              </th>
              {isProjectManagerFieldEnabled && (
                <th className="projects-table__head">
                  {tableConstants.projectManager}
                </th>
              )}
              <th className="projects-table__head"></th>
              {isBelongHost ? <th className="projects-table__head"></th> : ""}
            </tr>
          ) : (
            <tr className="projects-table__row">
              <th className="projects-table__head"></th>
              <th className="projects-table__head">{tableConstants.name}</th>
              <th className="projects-table__head">{tableConstants.status}</th>
              {isBelongHost ? <th className="projects-table__head"></th> : null}
            </tr>
          )}
          {data.map(row => renderDataRow(row))}
        </tbody>
      </table>
      <Popup
        visibility={projectDeactiveWarningVisibility}
        handleOutsideClick={false}
        width="50rem"
      >
        {renderWarningModal()}
      </Popup>
    </>
  );
};

export default ProjectsTableTemplate;
