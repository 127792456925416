import { isClosed, isOverdue } from "@app/helpers";
import { isActive } from "@app/helpers/actionItems";
import { AuthUser, ProjectMembers, Query, User } from "@app/types";

type TeamMembers = {
  [key: string]: User;
};

type PerspectiveAndDueDateStatsArg = {
  queries: Query[];
  authUser: AuthUser;
  isByDate: boolean;
  projectMembers?: ProjectMembers;
};

type PerspectiveAndDueDateStatsReturn = {
  myOpenRequest?: number;
  myRespondedRequest?: number;
  myUpcomingRequests?: number;
  myOverdueRequests?: number;
  myActiveRequests?: number;
  myResolvedRequests?: number;
  myTeamOpenRequest?: number;
  myTeamRespondedRequest?: number;
  myTeamUpcomingRequests?: number;
  myTeamOverdueRequests?: number;
  myTeamActiveRequests?: number;
  myTeamResolvedRequests?: number;
};

const getTeamMembers = ({
  projectMembers,
  authUser
}: {
  projectMembers?: ProjectMembers;
  authUser: AuthUser;
}) => {
  const myTeamMembers = {} as TeamMembers;
  if (authUser.isClientUser) {
    projectMembers?.clientUsers?.forEach(u => {
      myTeamMembers[u.id] = u;
    });
  } else if (authUser.isHostUser) {
    projectMembers?.hostUsers?.forEach(u => {
      myTeamMembers[u.id] = u;
    });
  }
  return myTeamMembers;
};

export function getPerspectiveAndDueDateStats({
  queries,
  authUser,
  isByDate,
  projectMembers
}: PerspectiveAndDueDateStatsArg): PerspectiveAndDueDateStatsReturn {
  const teamMembers = getTeamMembers({ projectMembers, authUser });
  if (!queries?.length || !Object.values(teamMembers).length) {
    return {
      myOpenRequest: 0,
      myRespondedRequest: 0,
      myUpcomingRequests: 0,
      myOverdueRequests: 0,
      myActiveRequests: 0,
      myResolvedRequests: 0,
      myTeamOpenRequest: 0,
      myTeamRespondedRequest: 0,
      myTeamUpcomingRequests: 0,
      myTeamOverdueRequests: 0,
      myTeamActiveRequests: 0,
      myTeamResolvedRequests: 0
    };
  }
  const myAssigned = queries
    .filter(q => q.assignedTo)
    .filter(q => q.assignedTo.id === authUser.id);
  const teamAssigned = queries
    .filter(q => q.assignedTo)
    .filter(q => teamMembers[q.assignedTo.id]);
  if (isByDate) {
    const now = new Date();
    const myUpcoming = myAssigned.filter(
      q => !isClosed(q) && !isOverdue(q, now)
    );
    const myOverdue = myAssigned.filter(q => !isClosed(q) && isOverdue(q, now));
    const teamUpcoming = teamAssigned.filter(
      q => !isClosed(q) && !isOverdue(q, now)
    );
    const teamOverdue = teamAssigned.filter(
      q => !isClosed(q) && isOverdue(q, now)
    );
    return {
      myUpcomingRequests: myUpcoming.length,
      myOverdueRequests: myOverdue.length,
      myTeamUpcomingRequests: teamUpcoming.length,
      myTeamOverdueRequests: teamOverdue.length
    };
  }
  const myActive = myAssigned.filter(q => isActive(q));
  const myResolved = myAssigned.filter(q => isClosed(q));
  const teamActive = teamAssigned.filter(q => isActive(q));
  const teamResolved = teamAssigned.filter(q => isClosed(q));

  const myOpen = myAssigned.filter(({ status }) => status === "OPEN");
  const myResponded = myAssigned.filter(({ status }) => status === "RESPONDED");

  const teamOpen = teamAssigned.filter(({ status }) => status === "OPEN");
  const teamResponded = teamAssigned.filter(
    ({ status }) => status === "RESPONDED"
  );
  return {
    myActiveRequests: myActive.length,
    myOpenRequest: myOpen.length,
    myRespondedRequest: myResponded.length,
    myResolvedRequests: myResolved.length,
    myTeamActiveRequests: teamActive.length,
    myTeamResolvedRequests: teamResolved.length,
    myTeamOpenRequest: teamOpen.length,
    myTeamRespondedRequest: teamResponded.length
  };
}

export const enum ToggleType {
  MY_REQUESTS = "MYREQUESTS",
  TEAM_REQUESTS = "TEAMREQUESTS"
}

export function getQueryNumber({
  queryItems,
  selectedType,
  indicator,
  isByDate
}: {
  queryItems: PerspectiveAndDueDateStatsReturn;
  selectedType: string;
  indicator: string;
  isByDate: boolean;
}) {
  const isMyRequests = selectedType === ToggleType.MY_REQUESTS;
  const isTeamRequests = selectedType === ToggleType.TEAM_REQUESTS;
  const {
    myUpcomingRequests,
    myOverdueRequests,
    myActiveRequests,
    myOpenRequest,
    myRespondedRequest,
    myResolvedRequests,
    myTeamUpcomingRequests,
    myTeamOverdueRequests,
    myTeamActiveRequests,
    myTeamResolvedRequests,
    myTeamOpenRequest,
    myTeamRespondedRequest
  } = queryItems;

  if (isByDate) {
    if (isMyRequests) {
      return indicator === "UPCOMING" ? myUpcomingRequests : myOverdueRequests;
    }
    return indicator === "UPCOMING"
      ? myTeamUpcomingRequests
      : myTeamOverdueRequests;
  }
  if (isMyRequests) {
    switch (indicator) {
      case "OPEN":
        return myOpenRequest;
      case "RESPONDED":
        return myRespondedRequest;
      case "ACTIVE":
        return myActiveRequests;
      case "RESOLVED":
        return myResolvedRequests;
      default:
        return 0;
    }
  }
  if (isTeamRequests) {
    switch (indicator) {
      case "OPEN":
        return myTeamOpenRequest;
      case "RESPONDED":
        return myTeamRespondedRequest;
      case "ACTIVE":
        return myTeamActiveRequests;
      case "RESOLVED":
        return myTeamResolvedRequests;
      default:
        return 0;
    }
  }
  return 0;
}
