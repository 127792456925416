import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import DropdownMenu from "@shared-components/dropdown/DropdownMenu";
import { utilities } from "@shared/helpers";
import { Page, Pagination } from "#src/types";
import { Icon, IconSize } from "@atoms/Icon/Icon.tsx";
import "./DropdownPagination.scss";

type Props = {
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
};

const DropdownPagination = (props: Props) => {
  const { pagination, setPagination } = props;

  const pages = useMemo(
    () => pagination.pages.map((page: Page) => ({ name: page.name })),
    [pagination]
  );

  const currentPage = useMemo(() => pagination.currentPage, [pagination]);
  const currentPageIndex = pagination.currentPageIndex;
  const { t } = useTranslation();

  const handlePageSelection = (pageName: string) => {
    utilities.handlePageSelection(pageName, pagination, setPagination);
  };

  const handlePageCountIncrement = () => {
    utilities.handlePageCountIncrement(pagination, setPagination);
  };

  const handlePageCountDecrement = () => {
    utilities.handlePageCountDecrement(pagination, setPagination);
  };

  return (
    <div className="data-pagination">
      <div className="data-pagination__dropdown">
        {currentPageIndex >= 0
          ? t("common:ui.pagination.pageNumber", {
              pageNumber: currentPageIndex + 1
            })
          : currentPage}
        <DropdownMenu
          boxWidth="small"
          type="text"
          label={
            <Icon
              className="data-pagination__dropdown data-pagination__dropdown-icon"
              name="arrow_drop_down"
              size={IconSize.L}
            />
          }
          menuItems={pages}
          handleMenuItemClick={handlePageSelection}
        />
      </div>
      <Icon
        onClick={handlePageCountDecrement}
        className="data-pagination--nav"
        name="chevron_left"
      />

      <Icon
        onClick={handlePageCountIncrement}
        className="data-pagination--nav"
        name="chevron_right"
      />
    </div>
  );
};

export default DropdownPagination;
