import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ions from "@ions";

import { Button, ButtonVariant } from "@atoms/Button";

import { DataViewSwitcher, Switchers } from "@molecules/DataViewSwitcher";

import ClientTodoList from "@components/organisms/ClientTodoList";
import PageTemplate from "@components/templates/PageTemplate";

import CreateProjectActionButton from "@pages/ProjectsDashboard/CreateProjectActionButton/CreateProjectActionButton";

import "./ProjectsDashboardTemplate.scss";

function ProjectsDashboardTemplate(props) {
  const { t } = useTranslation();
  const {
    projectsPanelView,
    viewType,
    projectsTableView,
    filtersView,
    onChangeViewType,
    showNoResultsFoundMessage,
    enableCreateProject,
    modal,
    toDoListEnabled,
    toDoListQueries
  } = props;

  const [toDoListOpen, setToDoListOpen] = useState(false);

  const isMountedRef = React.useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMountedRef.current && toDoListQueries?.length) {
      setToDoListOpen(true);
    }
  }, [toDoListQueries?.length]);

  const openToDoListButton =
    isMountedRef.current && toDoListEnabled && !toDoListOpen ? (
      <Button
        variant={ButtonVariant.TEXT}
        label={t("requests:requests.ui.clientTodoList.viewButton")}
        onClick={() => setToDoListOpen(true)}
      />
    ) : null;
  const createProjectButton = enableCreateProject ? (
    <CreateProjectActionButton />
  ) : null;

  const projectsBody = useMemo(() => {
    if (showNoResultsFoundMessage) {
      return (
        <div className="ot-projects-dashboard-template__no-results-msg">
          {t("common:ui.dashboard.noProjects.label")}
        </div>
      );
    }

    if (viewType === "grid") {
      return projectsPanelView;
    }
    return projectsTableView;
  }, [
    projectsPanelView,
    projectsTableView,
    showNoResultsFoundMessage,
    t,
    viewType
  ]);

  return (
    <PageTemplate
      header={{
        title: t("common:ui.dashboard.title"),
        actions: (
          <>
            {openToDoListButton}
            {createProjectButton}
          </>
        ),
        content: (
          <div className="ot-projects-dashboard-template">
            <div className="ot-projects-dashboard-template__top-bar">
              <DataViewSwitcher
                onChangeView={onChangeViewType}
                switchersToEnable={{
                  selectedView: viewType,
                  enabledSwitchers: [Switchers.GRID, Switchers.TABLE]
                }}
              />
              {filtersView}
            </div>
            {projectsBody}
          </div>
        ),
        context: ions.context?.clientDashboard?.pageTitle
      }}
      other={{ secondaryNavDisabled: true }}
      modal={modal}
      sidePanel={
        toDoListEnabled
          ? {
              open: toDoListOpen,
              content: (
                <ClientTodoList
                  queries={toDoListQueries ?? []}
                  onClose={() => setToDoListOpen(false)}
                  t={t}
                />
              )
            }
          : {}
      }
    />
  );
}

ProjectsDashboardTemplate.propTypes = {
  filtersView: PropTypes.element,
  projectsPanelView: PropTypes.element,
  projectsTableView: PropTypes.element,
  viewType: PropTypes.any,
  showNoResultsFoundMessage: PropTypes.bool,
  onChangeViewType: PropTypes.any,
  enableCreateProject: PropTypes.bool,
  modal: PropTypes.shape({
    open: PropTypes.bool,
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  }),
  toDoListEnabled: PropTypes.bool,
  toDoListQueries: PropTypes.any
};

export default ProjectsDashboardTemplate;
