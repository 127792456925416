import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";

import { classNames } from "@app/helpers/componentHelpers";

import { Icon, IconSize } from "@atoms/Icon";

import { DropdownItemType } from "@components/molecules/DropdownList/DropdownHelper";

import {
  DropdownList,
  DropdownListProps
} from "../../DropdownList/DropdownList";
import {
  InputLabel,
  InputLabelProps,
  InputTemplate,
  InputTemplateProps,
  InputType,
  InputWrapper,
  InputWrapperProps,
  getInputIdentifier
} from "../InputTemplate";

interface DropdownInputProps
  extends InputTemplateProps,
    InputLabelProps,
    InputWrapperProps,
    DropdownListProps {
  value?: DropdownItemType;
  onChange?: (value: DropdownItemType) => void;
  transformSelected?: (value: DropdownItemType) => string;
}

export const DropdownInput = forwardRef(
  (
    {
      items,
      showSearch,
      searchPlaceholder,
      className,
      minWidthOverride,
      value,
      onChange,
      label,
      isLeftLabel,
      required,
      description,
      hideLabel,
      disabled,
      placeholder,
      error,
      borderStyle,
      allowUndefined,
      onBlur,
      extraInputProps,
      sortComparator,
      transformSelected,
      width,
      closeOnMouseLeave,
      fontColor,
      context
    }: DropdownInputProps,
    fwdRef
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const currentValue = useMemo(() => {
      const defaultValue = value?.name ?? "";
      return transformSelected?.(value) ?? defaultValue;
    }, [value, transformSelected]);

    const currentPills = useMemo(() => {
      return items?.find(item => item.value === value?.value)?.pills;
    }, [items, value?.value]);

    const setSelectedValues = useCallback(
      (values = []) => {
        setIsOpen(false);
        onChange?.(values?.[0]);
      },
      [setIsOpen, onChange]
    );

    const dropdownRef = useRef();

    const identifier = useMemo(
      () => getInputIdentifier(label, "dropdown-input"),
      [label]
    );

    const labelElement = useMemo(
      () => (
        <InputLabel
          id={identifier}
          label={label}
          disabled={disabled}
          required={required}
          description={description}
          hideLabel={hideLabel}
          fontColor={fontColor}
        />
      ),
      [label, disabled, required, description, identifier, hideLabel, fontColor]
    );

    const dropdownIcon = useMemo(
      () => (
        <Icon
          name="expand_more"
          size={IconSize.S}
          className={classNames([
            "input-wrapper__icon",
            "input-wrapper__icon--rotating",
            `${isOpen}`
          ])}
        />
      ),
      [isOpen]
    );

    const openElement = useMemo(
      () => (
        <DropdownList
          isOpen={isOpen}
          selectedValues={value ? [value] : []}
          setSelectedValues={setSelectedValues}
          items={items}
          parentRef={dropdownRef}
          showSearch={showSearch}
          searchPlaceholder={searchPlaceholder}
          className={isOpen && !disabled ? "visible" : "hidden"}
          minWidthOverride={minWidthOverride}
          sortComparator={sortComparator}
          theme={context}
        />
      ),
      [
        isOpen,
        value,
        setSelectedValues,
        items,
        showSearch,
        searchPlaceholder,
        disabled,
        minWidthOverride,
        sortComparator,
        context
      ]
    );

    const inputWrapperElement = useMemo(
      () => (
        <InputWrapper
          ref={fwdRef}
          current={currentValue}
          setCurrent={setSelectedValues}
          currentPills={currentPills}
          placeholder={placeholder}
          disabled={disabled}
          type={InputType.DROPDOWN}
          isOpen={isOpen}
          clearValue={setSelectedValues}
          error={error}
          onClick={() => setIsOpen(current => !current)}
          rightIcons={dropdownIcon}
          borderStyle={borderStyle}
          allowUndefined={allowUndefined}
          onBlur={onBlur}
          id={identifier}
          label={label}
          extraInputProps={extraInputProps}
          context={context}
        />
      ),
      [
        fwdRef,
        currentValue,
        setSelectedValues,
        currentPills,
        placeholder,
        disabled,
        isOpen,
        error,
        dropdownIcon,
        borderStyle,
        allowUndefined,
        onBlur,
        identifier,
        label,
        extraInputProps,
        context
      ]
    );

    return (
      <InputTemplate
        className={classNames(["dropdown-input", className ?? ""])}
        ref={dropdownRef}
        labelElement={labelElement}
        isLeftLabel={isLeftLabel}
        inputWrapperElement={inputWrapperElement}
        openElement={openElement}
        setIsOpen={setIsOpen}
        error={error}
        borderStyle={borderStyle}
        testId="test-dropdown-input"
        width={width}
        closeOnMouseLeave={!isOpen ? false : closeOnMouseLeave}
      />
    );
  }
);
