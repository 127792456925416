import React from "react";

import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import "./ClickableMessage.scss";

const ClickableMessage = props => {
  const { onClick, i18nKey } = props;
  return (
    <div className="clickable-message">
      <Trans
        i18nKey={i18nKey}
        components={{
          add: <span onClick={onClick} className="clickable-message__link" />
        }}
      />
    </div>
  );
};

ClickableMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
  i18nKey: PropTypes.string.isRequired //{ i18nKey: "Lorem Ipsum doler <add>sit amet</add>." }
};

export default ClickableMessage;
