import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";
import { useInsightsBoardStore } from "@app/stores/useInsightsBoardStore.ts";

import ExpandButton from "@components/atoms/ExpandButton";
import ViewActionItemButton from "@components/molecules/ViewActionItemButton";

import "./InsightsCardTableCell.scss";
import ViewProjectDashboardButton from "./ViewProjectDashboardButton";

function InsightsCardTableCell(props) {
  const { cell } = props;

  const selectedDrilldown = useInsightsBoardStore(state => state.drilldown);

  const contentClassNames = useMemo(() => {
    const classes = ["ot-insights-card-table-cell"];
    if (cell?.value?.isHeaderCell) {
      classes.push("ot-insights-card-table-cell--header-level-cell");
    }
    if (cell?.value?.drilldown?.enabled) {
      classes.push("ot-insights-card-table-cell--drilldown");
    }
    if (cell?.value?.action) {
      classes.push("ot-insights-card-table-cell--action");
    }
    return classNames(classes);
  }, [
    cell?.value?.action,
    cell?.value?.drilldown?.enabled,
    cell?.value?.isHeaderCell
  ]);

  const handleDrilldownClick = useCallback(() => {
    cell?.value?.drilldownClickHandler?.({
      rowId: cell?.row?.id,
      columnId: cell?.value?.key,
      drilldown: cell?.value?.drilldown
    });
  }, [cell]);

  const isActive = useMemo(() => {
    return (
      cell?.value?.insightsCardId != null &&
      selectedDrilldown?.insightsCardId == cell?.value?.insightsCardId &&
      selectedDrilldown?.rowId === cell?.row?.id &&
      selectedDrilldown?.columnId === cell?.value?.key
    );
  }, [cell, selectedDrilldown]);

  const isNumber = value => !isNaN(value);

  const drilldownButton = useMemo(() => {
    if (!cell?.value?.drilldown?.enabled) {
      return <></>;
    }

    // default is only show drilldown button if the value is greater than 0 (ONE-3812)
    const isVisible =
      cell?.value?.drilldown?.alwaysVisible ||
      (isNumber(cell?.value?.content) && +cell?.value?.content > 0) ||
      !isNumber(cell?.value?.content);
    return (
      <div className="ot-insights-card-table-cell__drilldown-btn">
        <ExpandButton
          id={cell?.row?.id}
          type="drilldown"
          onClick={handleDrilldownClick}
          isHidden={!isVisible}
          isActive={isActive}
        />
      </div>
    );
  }, [
    cell?.row?.id,
    cell.value?.content,
    cell.value?.drilldown?.enabled,
    cell.value?.drilldown?.alwaysVisible,
    handleDrilldownClick,
    isActive
  ]);

  const getActionContent = useCallback(() => {
    const row = cell?.row?.original;
    if (cell?.value?.action === "openRequest") {
      return (
        <ViewActionItemButton
          iconType="fact_check"
          actionItemType={row?.actionItemType?.content}
          queryId={row?.queryId?.content}
          queryType={row?.queryType?.content}
          projectId={row?.projectId?.content}
        />
      );
    }
    if (cell?.value?.action === "openProject") {
      return <ViewProjectDashboardButton projectId={row?.projectId?.content} />;
    }
    throw new Error(`Unknown action ${cell?.action}`);
  }, [cell]);

  const getCellContent = useCallback(() => {
    if (cell?.value?.action) {
      return getActionContent();
    }
    if (cell?.value?.isMergeCell) {
      return <></>;
    }
    return (
      <>
        {cell?.value?.content}
        {drilldownButton}
      </>
    );
  }, [cell, drilldownButton, getActionContent]);

  return <div className={contentClassNames}>{getCellContent()}</div>;
}

InsightsCardTableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.object.isRequired
  }).isRequired
};

export default InsightsCardTableCell;
