import { useMemo, useState } from "react";

import { utilities } from "../helpers";

// Throttle state updates to at most once every 'waitMs'.
// setState is invoked on the leading edge. Then subsequent calls are ingnored until the first call after waitMs
export const useThrottledState = (initialState, waitMs = 1000) => {
  const [state, setState] = useState(initialState);

  const throttle = useMemo(
    () =>
      utilities.throttle(theProp => {
        setState(theProp);
      }, waitMs),
    [waitMs]
  );

  const setThrottledState = useMemo(() => val => throttle(val), [throttle]);
  return [state, setThrottledState];
};
