import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export interface ProjectTemplateType {
  id: number;
  name: string;
  engagementType: {
    id: number;
    name: string;
  };
  documentCount: number;
  milestoneCount: number;
  queryCount: number;
  duration: number;
}

export const projectTemplateService = createApi({
  reducerPath: "projectTemplateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["ProjectTemplate"],
  endpoints: builder => ({
    getAllProjectTemplate: builder.query<Array<ProjectTemplateType>, void>({
      query: () => "/api/projectTemplates",
      providesTags: ["ProjectTemplate"]
    }),
    getProjectTemplateById: builder.query({
      query: ({ projectTemplateId }) =>
        `/api/projectTemplates/${projectTemplateId}`,
      providesTags: (_, __, { projectTemplateId }) => [
        { type: "ProjectTemplate", id: projectTemplateId }
      ]
    })
  })
});

export const { useGetAllProjectTemplateQuery, useGetProjectTemplateByIdQuery } =
  projectTemplateService;
