import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { manageProjectQueriesActions } from "@shared/actions";
import { useDeleteActionItemMutation, useToasts } from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";

import ModalContentTemplate from "@components/templates/ModalContentTemplate";

import "./DraftQueryDeleteModal.scss";

interface DraftQueryDeleteModalProps {
  query: {
    id: number;
    project: { id: number };
    query: string;
  };
  handleCloseModal: () => void;
}

const DraftQueryDeleteModal = ({
  query,
  handleCloseModal
}: DraftQueryDeleteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteQueryItem] = useDeleteActionItemMutation();

  const { showQueryDeleteToast, showQueryDeleteFailedToast } = useToasts();

  const handleDelete = useCallback(async () => {
    await deleteQueryItem({ queryId: query.id })
      .unwrap()
      .then(() => {
        showQueryDeleteToast();
        dispatch(manageProjectQueriesActions.getProjectQueries(query.project));
      })
      .catch(err => {
        showQueryDeleteFailedToast(getErrorMessage(err, t));
      })
      .finally(() => {
        handleCloseModal();
      });
  }, [
    deleteQueryItem,
    dispatch,
    handleCloseModal,
    query.id,
    query.project,
    showQueryDeleteFailedToast,
    showQueryDeleteToast,
    t
  ]);

  return (
    <ModalContentTemplate
      header={{
        title: t("requests:requests.ui.query.draft.delete.title")
      }}
      body={{
        message: t("requests:requests.ui.query.draft.delete.warning")
      }}
      footer={{
        submitText: t("common:ui.forms.yes.label"),
        handleSubmit: handleDelete,
        disableSubmit: !query?.id,
        cancelText: t("common:ui.forms.no.label"),
        handleCancel: handleCloseModal
      }}
      modalClassName="draft-query-delete-modal"
    />
  );
};

export default DraftQueryDeleteModal;
