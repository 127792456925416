import React, { useEffect, useMemo, useState } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { Inline, Stack } from "@fermions";

import { RadioButtonInput } from "@molecules/inputs";

import {
  InputLabel,
  InputTemplate
} from "@components/molecules/inputs/InputTemplate";

import "./RadioGroup.scss";

const RadioGroup = props => {
  const {
    formState: { errors },
    control,
    getValues,
    setValue
  } = useFormContext();

  const [error, setError] = useState();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue || (currentValue.length === 0 && props.defaultValue)) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  useEffect(() => {
    const error = get(errors, props.name?.split("."))?.message ?? "";
    setError(error);
  }, [errors, props.name]);

  const labelElement = useMemo(
    () =>
      props.label ? (
        <InputLabel
          label={props.label}
          required={props.required}
          disabled={props.disabled}
          description={props.description}
        />
      ) : (
        <></>
      ),
    [props.description, props.disabled, props.label, props.required]
  );
  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <InputTemplate
            className="radio-group__container"
            labelElement={labelElement}
            width="auto"
            inputWrapperElement={
              <Stack className="radio-group" gap="075">
                <Inline
                  className={`radio-group__buttons`}
                  gap="250"
                  contentsWidth="auto"
                  alignment="left"
                >
                  {props.items.map((item, key) => (
                    <React.Fragment key={key}>
                      <RadioButtonInput
                        disabled={props.disabled}
                        ref={ref}
                        value={getValues(props.name) === item.value}
                        name={props.name}
                        onChange={checked => {
                          onChange?.({
                            target: {
                              value: item.value,
                              checked,
                              name: props.name ?? ""
                            }
                          });
                        }}
                        label={item.label}
                        extraProps={{
                          onBlur
                        }}
                      />
                    </React.Fragment>
                  ))}
                </Inline>
              </Stack>
            }
            error={error}
          />
        );
      }}
    />
  );
};

RadioGroup.defaultProps = {
  className: ""
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
};

export default RadioGroup;
