import React from "react";

import PropTypes from "prop-types";

import { Icon, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import "./RadioButton.scss";

// TODO: remove - replace with RadioButtonInput from @molecules/inputs

const RadioButton = React.forwardRef((props, fwdRef) => {
  const renderRadioIcon = () => {
    return (
      <Icon
        name={props.checked ? "radio_button_checked" : "radio_button_unchecked"}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        fillStyle={IconFillStyle.OUTLINED}
        className={`radio-button-icon ${
          props.disabled ? "radio-button-icon--disabled" : ""
        }`}
      />
    );
  };
  return (
    <label
      className={`radio-button ${
        props.disabled ? "radio-button--disabled" : ""
      }`}
    >
      <input
        className="radio-button_input"
        data-testid={props.testId}
        type="radio"
        disabled={props.disabled}
        ref={fwdRef}
        onClick={props.onClick}
        value={props.value}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        role="button-toggle"
      />

      <div className="radio-button_checkmark__container">
        {renderRadioIcon()}
      </div>
      <div
        className={`radio-button_label${props.disabled ? "--disabled" : ""}`}
      >
        {props.label}
      </div>
    </label>
  );
});

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  key: PropTypes.any,
  testId: PropTypes.string
};

export default RadioButton;
