import { externalLinkValidationConstants } from "../constants";
import { externalLinkValidationService } from "../services";

export const externalLinkValidationActions = {
  validateExternalLinkObject,
  clearError,
  reset
};

function validateExternalLinkObject({ externalObject }) {
  return dispatch => {
    dispatch(request());

    externalLinkValidationService
      .validateExternalLinkObjectAndGetMoreDetails({ externalObject })
      .then(
        validationObject => {
          dispatch(success(validationObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_REQUEST
    };
  }

  function success(validationObject) {
    return {
      type: externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_SUCCESS,
      validationObject
    };
  }

  function failure(error) {
    return {
      type: externalLinkValidationConstants.VALIDATE_EXTERNAL_LINK_OBJECT_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: externalLinkValidationConstants.CLEAR_ERROR };
}

function reset() {
  return {
    type: externalLinkValidationConstants.RESET_EXTERNAL_LINK_OBJECT_STORE
  };
}
