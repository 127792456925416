import React from "react";

import { useMenuActions } from "@shared/hooks/useMenuActions";

import { Box } from "@fermions";

import { Icon } from "@atoms/Icon";

import { DropdownList } from "@molecules/DropdownList";

import {
  DropdownCalculationType,
  DropdownItemType,
  Positioning
} from "../DropdownList/DropdownHelper";

interface ActionDropdownProps {
  menuItems: DropdownItemType[];
  onMenuItemClick: (item: DropdownItemType) => void;
  disableFixedPositionCalculation?: boolean;
  positionRelative?: boolean;
  className?: string;
}

export const ActionDropdown = ({
  menuItems = [],
  onMenuItemClick,
  disableFixedPositionCalculation,
  positionRelative,
  className
}: ActionDropdownProps) => {
  const {
    menuItemsTransformed,
    menuItemClickHandler,
    handleClick,
    isOpen,
    ref,
    dropDownRef: actionDropdownRef
  } = useMenuActions({ menuItems, onMenuItemClick });

  return menuItemsTransformed?.length > 0 ? (
    <Box
      ref={ref}
      className={[
        "action-dropdown",
        `action-dropdown-position--${
          disableFixedPositionCalculation || positionRelative
            ? "relative"
            : "absolute"
        }`,
        className ?? ""
      ]}
      onClick={handleClick}
      data-testid="test-action-dropdown"
      style={
        isOpen
          ? {
              visibility: "visible"
            }
          : {}
      }
    >
      <Box
        data-testid="test__menu"
        ref={actionDropdownRef}
        className="action-dropdown__dots"
        style={{
          cursor: "pointer"
        }}
      >
        <Icon name="more_vert" />
      </Box>
      <DropdownList
        parentRef={actionDropdownRef}
        calculation={
          disableFixedPositionCalculation
            ? DropdownCalculationType.RELATIVE
            : DropdownCalculationType.FIXED
        }
        isOpen={isOpen}
        items={menuItemsTransformed}
        onClickItem={menuItemClickHandler}
        positioning={Positioning.BOTTOM_LEFT}
        showSearch={false}
        sortComparator={() => 0}
      />
    </Box>
  ) : (
    <></>
  );
};
