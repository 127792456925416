import React, { useCallback, useEffect, useState } from "react";

import { useVisualisation } from "@shared/hooks";

import { Button, ButtonVariant } from "@atoms/Button";

import InlineAlert from "@components/atoms/InlineAlert";
import PopupOverlay from "@components/atoms/PopupOverlay";
import TextInput from "@components/atoms/TextInput";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./VisualisationTitle.scss";

const VisualisationTitle = props => {
  const {
    user,
    existingTitle,
    updateTitle,
    handleSubmit,
    handleChange,
    isUploadDisabled,
    handleUploadPermission
  } = props;
  const {
    visualisationError,
    resetVisualisationError,
    updateVisualisationSuccess
  } = useVisualisation();
  const [isEdit, setIsEdit] = useState(false);
  const inputFocusRef = React.createRef();

  useEffect(() => {
    if (updateVisualisationSuccess) {
      setIsEdit(false);
    }
  }, [updateVisualisationSuccess]);

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
  }, [inputFocusRef]);

  const handleEdit = () => {
    if (isUploadDisabled) {
      handleUploadPermission();
    } else {
      setIsEdit(true);
    }
  };

  const onChange = event => {
    const name = event.target.value;
    handleChange(name);
  };

  const onSubmit = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    },
    [handleSubmit]
  );

  const handleCancel = () => {
    setIsEdit(false);
    handleChange(existingTitle.name);
    resetVisualisationError();
  };

  const renderEditTitleModal = () => {
    return (
      <ModalTemplate
        boxClassName="tag-editor"
        title={"Edit Title"}
        onClose={handleCancel}
        content={
          <>
            {visualisationError && (
              <div className="tag-editor__error-box">
                <InlineAlert type="error" message={visualisationError} />
              </div>
            )}

            <TextInput
              label="Name"
              required={false}
              value={updateTitle?.name || ""}
              onChange={onChange}
              ref={inputFocusRef}
              maxLength={50}
            />
          </>
        }
        footer={
          <>
            <Button
              variant={ButtonVariant.SECONDARY}
              label="Cancel"
              onClick={handleCancel}
            />
            <Button label="Add" onClick={onSubmit} />
          </>
        }
      />
    );
  };

  return (
    <div className="visualisation-title">
      {updateTitle?.id && !updateTitle?.name && user.isHostUser ? (
        <span
          onClick={handleEdit}
          className="visualisation-title__label visualisation-title__label__text visualisation-title__label__text--default"
          data-testid="test-visualisation-default-title"
        >
          Click to edit visualisation title
        </span>
      ) : (
        <>
          <div className="visualisation-title__label">
            <span
              className="visualisation-title__label__text"
              data-testid="test-visualisation-title"
            >
              {updateTitle?.name}
            </span>
            {updateTitle?.name && user.isHostUser && (
              <i className="material-icons edit-icon" onClick={handleEdit}>
                edit
              </i>
            )}
          </div>
        </>
      )}
      <PopupOverlay
        isVisible={isEdit}
        isModal={true}
        onClose={handleCancel}
        showClose={false}
        width={"60rem"}
      >
        {renderEditTitleModal()}
      </PopupOverlay>
    </div>
  );
};

export default VisualisationTitle;
