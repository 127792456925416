import { useCallback, useEffect, useState } from "react";

import {
  useGetQuestionDeletedAnswersQuery,
  useRemoveDeletedAnswerMutation,
  useRestoreDeletedAnswerMutation
} from "@shared/hooks";

export function useUpdateDeleteAnswers(
  queryId,
  questionId,
  isDocumentQuestion
) {
  const [error, setError] = useState("");
  const [restoreDeletedAnswer, { error: restoreDeletedAnswerError }] =
    useRestoreDeletedAnswerMutation();
  const [removeDeletedAnswer, { error: removeDeletedAnswerError }] =
    useRemoveDeletedAnswerMutation();

  const { data: deletedAnswers } = useGetQuestionDeletedAnswersQuery(
    {
      queryId,
      questionId
    },
    { skip: (!queryId && !questionId) || !isDocumentQuestion }
  );

  useEffect(() => {
    const err = restoreDeletedAnswerError || removeDeletedAnswerError;
    setError(err?.data?.key ?? err?.data?.message ?? err?.error ?? "");
  }, [restoreDeletedAnswerError, removeDeletedAnswerError]);

  const removeDeleted = useCallback(
    documentId => {
      removeDeletedAnswer({ documentId, queryId, questionId });
    },
    [queryId, questionId, removeDeletedAnswer]
  );

  const restoreDeleted = documentId => {
    restoreDeletedAnswer({ documentId, queryId, questionId });
  };

  return {
    error,
    removeDeleted,
    restoreDeleted,
    deletedAnswers
  };
}
