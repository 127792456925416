import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageClientTagsActions } from "@shared/actions";

import { Button, ButtonVariant } from "@atoms/Button";

import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import TextInput from "@components/atoms/TextInput/TextInput";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./AddOrUpdateClientTag.scss";

const AddTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const client = location.state.client;
  const manageClientTags = useSelector(state => state.manageClientTags);
  const [tag, setTag] = useState(
    location.state.tag || {
      name: ""
    }
  );

  const { t } = useTranslation();
  const isUpdate = !!location.state.tag;
  const [, setSubmit] = useState(false);
  const inputFocusRef = React.createRef();
  const [error, setError] = useState("");

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocusRef.current]);

  useEffect(() => {
    return () => {
      dispatch(manageClientTagsActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manageClientTags.addedClientTag) {
      navigate(routeConstants.manageClientTags, {
        state: client
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageClientTags.addedClientTag]);

  useEffect(() => {
    if (manageClientTags.updatedClientTag) {
      navigate(routeConstants.manageClientTags, {
        state: client
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageClientTags.updatedClientTag]);

  const handleKeyPress = event => {
    if (event.key === "Escape") {
      navigate(routeConstants.manageClientTags, {
        state: client
      });
    }
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    if (manageClientTags.error) {
      setError(manageClientTags.error);
    }
  }, [manageClientTags.error]);

  const handleNameChange = event => {
    setError("");
    const name = event.target.value;
    setTag({ ...tag, name });
  };

  const handleKeyDown = event => {
    event.stopPropagation();
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setSubmit(true);
    if (tag.name && tag.name.trim().length) {
      const tagToSubmit = {
        ...tag,
        clientId: tag.clientId || client.id,
        name: tag.name && tag.name.trim()
      };
      isUpdate
        ? dispatch(manageClientTagsActions.updateClientTag(client, tagToSubmit))
        : dispatch(
            manageClientTagsActions.createClientTag(client, tagToSubmit)
          );
    } else {
      setError("Please enter tag name");
    }
  };

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          },
          {
            linkTo: {
              pathname: routeConstants.manageClientTags,
              state: client
            },
            label:
              client &&
              t("common:ui.manageClientTags.titleWithClientName", {
                clientName: client.name
              })
          }
        ]}
        title={
          isUpdate
            ? t("common:ui.updateTag.title")
            : t("common:ui.addTag.title")
        }
      >
        <div className="add-client-tag__main">
          {error && (
            <div className="add-client-tag__error-box">
              <InlineAlert type="error" message={error} />
            </div>
          )}

          <div className="add-client-tag__container">
            <div className="add-client-tag__input-container">
              <TextInput
                variant={ButtonVariant.TEXT}
                label="Tag name"
                required={true}
                onChange={handleNameChange}
                handleKeyDown={handleKeyDown}
                value={tag.name || ""}
                ref={inputFocusRef}
                maxLength={50}
              />
            </div>

            <div className="add-client-tag__button">
              <Link
                className="btn btn-link add-client-tag__button--cancel"
                to={routeConstants.manageClientTags}
                state={{ client }}
              >
                {t("common:ui.updateTag.cancelLabel")}
              </Link>
              <Button
                className="add-client-tag__button--submit"
                disabled={
                  !(tag.name && tag.name.trim()) ||
                  (isUpdate && tag.name.trim() === location.state.tag.name) ||
                  (!isUpdate && manageClientTags.addClientTagLoading) ||
                  (isUpdate && manageClientTags.updateClientTagLoading)
                }
                label={
                  isUpdate
                    ? t("common:ui.updateTag.submitLabel")
                    : t("common:ui.addTag.submitLabel")
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default AddTag;
