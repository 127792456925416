import React, { useCallback, useEffect, useMemo, useState } from "react";

import { systemConstants } from "@shared/constants/systemConstants";
import { useAuthUser, useProjectQueries } from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import FileItem from "@components/molecules/FileItem";
import Loading from "@components/molecules/Loading";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";

import "./FinalWorkpaperPanel.scss";

const finalWorkpaperActions = {
  reject: "Reject"
};

const FinalWorkpaperPanel = props => {
  const { project, isProjectMember, handleClickReject } = props;
  const { user } = useAuthUser();
  const [draftQueries, setDraftQueries] = useState([]);
  const queryType = useMemo(() => ["DRAFT_WORKPAPER"], []);
  const { queries, isLoading, error } = useProjectQueries(project, queryType);
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");
  useEffect(() => {
    setDraftQueries(
      queries
        .filter(q => q.flag == systemConstants.project.queries.flags.approved)
        .sort((d1, d2) => {
          const ordering = d1.description
            .toLocaleLowerCase()
            .localeCompare(d2.description.toLocaleLowerCase());
          return ordering < 0 ? -1 : ordering > 0 ? 1 : 0;
        })
    );
  }, [queries]);

  const handleFileClick = useCallback(
    queryId => {
      const query = draftQueries.find(q => q.id == queryId);
      window.open(`/projects/${project.id}/websheets/${query.id}`, "_blank");
    },
    [draftQueries, project]
  );

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setShowProjectAccessModal(true);
      });
    },
    [isProjectMember, user]
  );
  const handleReject = useCallback(
    action => {
      checkMembership("reject this final workpaper")(() => {
        if (action.name == finalWorkpaperActions.reject) {
          handleClickReject(queries.find(q => q.id === action.id));
        }
      });
    },
    [queries, handleClickReject, checkMembership]
  );

  const handleCloseModal = useCallback(() => {
    setShowProjectAccessModal(false);
    setMembershipErrorMessage("");
  }, []);
  return (
    <div className="finalworkpaper-panel">
      <div className="finalworkpaper-panel__title">Final Workpapers</div>
      <div className="finalworkpaper-panel__body">
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorBox message={error} />
        ) : (
          draftQueries.map((query, key) => (
            <div key={key} className="finalworkpaper-panel__action">
              <FileItem
                key={key}
                itemName={query.description}
                itemId={query.id}
                menuItems={[{ name: "Reject", id: query.id }]}
                onMenuItemClick={handleReject}
                menuVisibility={user?.isHostUser}
                onFileClick={handleFileClick}
              />
            </div>
          ))
        )}
        <ProjectAccessModal
          visibility={showProjectAccessModal}
          message={membershipErrorMessage}
          handleClose={handleCloseModal}
          handleOutsideClick={handleCloseModal}
        />
      </div>
    </div>
  );
};

export default FinalWorkpaperPanel;
