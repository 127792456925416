import React from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Popup from "@shared-components/popup/Popup";

import MessageModal from "@components/molecules/MessageModal/MessageModal";

const ProjectAccessModal = props => {
  const {
    visibility,
    handleClose,
    message,
    title,
    handleOutsideClick = false,
    t
  } = props;
  return (
    <Popup
      visibility={visibility}
      handleOutsideClick={handleOutsideClick}
      width="50rem"
    >
      <MessageModal
        title={title || t("common:project.accessRequiredModalTitle")}
        message={message}
        onClickClose={handleClose}
      />
    </Popup>
  );
};

ProjectAccessModal.defaultProps = {
  visibility: false
};

ProjectAccessModal.propTypes = {
  visibility: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default withTranslation()(ProjectAccessModal);
