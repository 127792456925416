export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGIN_WITH_EXTENAL_AUTH_REQUEST: "LOGIN_WITH_EXTENAL_AUTH_REQUEST",
  LOGIN_WITH_EXTENAL_AUTH_SUCCESS: "LOGIN_WITH_EXTENAL_AUTH_SUCCESS",
  LOGIN_WITH_EXTENAL_AUTH_FAILURE: "LOGIN_WITH_EXTENAL_AUTH_FAILURE",

  RESET_EXTERNAL_LOGIN_FAILED: "RESET_EXTERNAL_LOGIN_FAILED",

  GET_QR_CODE_REQUEST: "GET_QR_CODE_REQUEST",
  GET_QR_CODE_SUCCESS: "GET_QR_CODE_SUCCESS",
  GET_QR_CODE_FAILURE: "GET_QR_CODE_FAILURE",

  ACTIVATE_MFA_TOKEN_REQUEST: "ACTIVATE_MFA_TOKEN_REQUEST",
  ACTIVATE_MFA_TOKEN_SUCCESS: "ACTIVATE_MFA_TOKEN_SUCCESS",
  ACTIVATE_MFA_TOKEN_FAILURE: "ACTIVATE_MFA_TOKEN_FAILURE",

  DISABLE_MFA_REQUEST: "DISABLE_MFA_REQUEST",
  DISABLE_MFA_SUCCESS: "DISABLE_MFA_SUCCESS",
  DISABLE_MFA_FAILURE: "DISABLE_MFA_FAILURE",

  EMAIL_MFA_VERIFICATION_TOKEN_REQUEST: "EMAIL_MFA_VERIFICATION_TOKEN_REQUEST",
  EMAIL_MFA_VERIFICATION_TOKEN_SUCCESS: "EMAIL_MFA_VERIFICATION_TOKEN_SUCCESS",
  EMAIL_MFA_VERIFICATION_TOKEN_FAILURE: "EMAIL_MFA_VERIFICATION_TOKEN_FAILURE",

  VERIFY_MFA_TOKEN_REQUEST: "VERIFY_MFA_TOKEN_REQUEST",
  VERIFY_MFA_TOKEN_SUCCESS: "VERIFY_MFA_TOKEN_SUCCESS",
  VERIFY_MFA_TOKEN_FAILURE: "VERIFY_MFA_TOKEN_FAILURE",

  LOGOUT: "LOGOUT",

  TOKEN_VERIFY_REQUEST: "TOKEN_VERIFY_REQUEST",
  TOKEN_VERIFY_SUCCESS: "TOKEN_VERIFY_SUCCESS",

  CLEAR_ERROR: "AUTH_CLEAR_ERROR",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",
  RESET_MFA_ACTIVATED: "RESET_MFA_ACTIVATED",
  RESET_MFA_DISABLED: "RESET_MFA_DISABLED",
  RESET_EMAIL_SENT_FOR_MFA_VERIFICATION_TOKEN:
    "RESET_EMAIL_SENT_FOR_MFA_DEACTIVATION_TOKEN",
  RESET_MFA_TOKEN_VERIFICATION: "RESET_MFA__TOKEN_VERFICATION",

  VERIFY_RESET_PASSWORD_TOKEN_REQUEST: "VERIFY_RESET_PASSWORD_TOKEN_REQUEST",
  VERIFY_RESET_PASSWORD_TOKEN_SUCCESS: "VERIFY_RESET_PASSWORD_TOKEN_SUCCESS",
  VERIFY_RESET_PASSWORD_TOKEN_FAILURE: "VERIFY_RESET_PASSWORD_TOKEN_FAILURE",
  RESET_RESET_PASSWORD_TOKEN_VERIFICATION:
    "RESET_RESET_PASSWORD_TOKEN_VERIFICATION",

  GET_HOST_WITH_BRAND_DETAILS_REQUEST: "GET_HOST_WITH_BRAND_DETAILS_REQUEST",
  GET_HOST_WITH_BRAND_DETAILS_SUCCESS: "GET_HOST_WITH_BRAND_DETAILS_SUCCESS",
  GET_HOST_WITH_BRAND_DETAILS_FAILURE: "GET_HOST_WITH_BRAND_DETAILS_FAILURE",

  RESET_HOST: "RESET_HOST",
  RESET_LAST_KNOWN_PATH: "RESET_LAST_KNOWN_PATH"
};
