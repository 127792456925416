import React, { useEffect, useMemo, useState } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { useUIConfig } from "@shared/hooks";

import { expectedQueryTypes, getActionItemTypeDisplayName } from "@app/helpers";

import ClientTodoListTemplate from "@components/templates/ClientTodoListTemplate";

const ClientTodoList = props => {
  const { queries = [], onClose, t } = props;
  const { uiConfig } = useUIConfig();
  const [selectedQueryTypes, setSelectedQueryTypes] = useState();
  const [enableFilter, setEnableFilter] = useState(false);
  const [pillConfig, setPillConfig] = useState();

  const actionItemTypes = useMemo(
    () =>
      _.uniqBy(
        queries.map(query => query.queryType).filter(query => !!query),
        queryType => queryType?.configuration?.key
      ),
    [queries]
  );

  const { allQueryTypeKeys } = useMemo(
    () => expectedQueryTypes(actionItemTypes, t),
    [actionItemTypes, t]
  );

  const filteredQueries = useMemo(() => {
    const isValidQueryType = query =>
      allQueryTypeKeys.includes(query.queryType?.configuration?.key);

    if (!selectedQueryTypes) {
      return queries.filter(isValidQueryType);
    }
    const selectedQueryTypeKeys = selectedQueryTypes.map(
      queryType => queryType.key
    );
    return queries.filter(
      query =>
        isValidQueryType(query) &&
        selectedQueryTypeKeys.includes(query.queryType?.configuration?.key)
    );
  }, [allQueryTypeKeys, queries, selectedQueryTypes]);

  useEffect(() => {
    if (uiConfig.todoList?.filter) {
      setEnableFilter(uiConfig.todoList.filter.enabled);
    }
  }, [uiConfig]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  const filterItems = useMemo(() => {
    if (!actionItemTypes?.length || !enableFilter) {
      return;
    }
    return actionItemTypes
      .filter(a => !a.configuration?.statuses?.NOTE)
      .map(a => ({
        key: a.configuration?.key,
        name: getActionItemTypeDisplayName(
          a.configuration?.useCustomName,
          a.name,
          t("requests:requests.configured.name", {
            context: a.configuration.key
          })
        ),
        value: a.configuration?.key
      }));
  }, [actionItemTypes, enableFilter, t]);

  const onFiltersSelected = items => {
    if (items.length) {
      setSelectedQueryTypes(items);
    } else {
      setSelectedQueryTypes(null);
    }
  };

  return (
    <ClientTodoListTemplate
      title={t("requests:requests.ui.clientTodoList.title")}
      actionItemTypes={actionItemTypes}
      pillConfig={pillConfig}
      queries={filteredQueries}
      filterItems={filterItems}
      enableFilter={enableFilter}
      onFiltersSelected={onFiltersSelected}
      onClose={onClose}
    />
  );
};

ClientTodoList.defaultProps = {
  queries: []
};

ClientTodoList.propTypes = {
  queries: PropTypes.arrayOf(PropTypes.any),
  onClose: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(ClientTodoList);
