import * as _ from "lodash";

// Types of pagination display
export enum PaginationType {
  NONE = "none",
  COMPLETION_STATUS = "completionStatus"
}

export type PageInfoItem = {
  name: string;
  completeCount: number;
  totalCount: number;
};

export type PageInfo = { [page: number]: PageInfoItem };

/**
 *
 * Gets a list of the visible page numbers around the current page
 */
const getVisiblePagesAroundCurrent = (
  currentPage: number,
  totalPages: number,
  maxNumberOfVisiblePages: number
): number[] => {
  const firstAndLastPageNumberLength = 2;
  // Ensure odd number
  const maxVisiblePages =
    maxNumberOfVisiblePages % 2 === 0
      ? maxNumberOfVisiblePages + 1
      : maxNumberOfVisiblePages;
  const numbersEitherSide =
    (maxVisiblePages - firstAndLastPageNumberLength - 1) / 2; // numbers either side of current
  // First pages
  if (currentPage <= numbersEitherSide + 1) {
    return _.range(1, maxVisiblePages);
    // Last pages
  } else if (currentPage > totalPages - (numbersEitherSide + 1)) {
    return _.range(totalPages - maxVisiblePages + 2, totalPages + 1);
    // Middle pages
  } else {
    return _.range(
      currentPage - numbersEitherSide,
      currentPage + numbersEitherSide + 1
    );
  }
};

/**
 *
 * Get visible pages (to handle when there is a max number of visible pages)
 * e.g. when there are 30 pages total and max visible is 9
 */
const getVisiblePages = (
  currentPage: number,
  totalPages: number,
  maxNumberOfVisiblePages: number
): number[] => {
  if (!maxNumberOfVisiblePages) {
    return _.range(1, totalPages + 1);
  }

  const firstAndLastPageNumber = [1, totalPages];
  return [
    ...new Set([
      ...firstAndLastPageNumber,
      ...getVisiblePagesAroundCurrent(
        currentPage,
        totalPages,
        maxNumberOfVisiblePages
      )
    ])
  ].sort((a, b) => a - b);
};

/**
 *
 * Checks if page is complete
 */
const isPageComplete = (information: PageInfoItem): boolean =>
  !!information &&
  !!information.totalCount &&
  information.completeCount === information.totalCount;

/**
 *
 * Get a list of the completed page numbers
 */
const getCompletedPages = (pageInfo: PageInfo): number[] =>
  Object.entries(pageInfo)
    .filter(([, information]) => isPageComplete(information))
    .map(([key]) => +key);

/**
 *
 *
 */
const getPaginationMessage = (
  t,
  pageInfo: PageInfo,
  type: PaginationType,
  totalPages: number
): string => {
  if (type === PaginationType.COMPLETION_STATUS && totalPages) {
    const completedPages = getCompletedPages(pageInfo);
    if (!completedPages.length) {
      return "";
    }
    return t("common:ui.pagination.sectionsCompleted", {
      complete: completedPages.length,
      total: totalPages
    });
  }

  return "";
};

/**
 *
 * Get page number icon name if needed
 */
const getPageIconName = (
  information: PageInfoItem,
  type: PaginationType
): string | null => {
  if (type === PaginationType.COMPLETION_STATUS) {
    return isPageComplete(information) ? "check" : null;
  }
  return null;
};

/**
 *
 * Get page number hover text if needed
 */
const getPageHoverText = (
  t,
  information: PageInfoItem,
  type: PaginationType
): string[] => {
  const lines = information?.name ? [information?.name] : [];
  if (type === PaginationType.COMPLETION_STATUS) {
    if (!information?.totalCount) {
      return lines;
    }
    const complete = t("ui.pagination.complete", {
      complete: information.completeCount || 0,
      total: information.totalCount || 0
    });
    return [...lines, complete];
  }

  return lines;
};

export const paginationHelper = {
  getPaginationMessage,
  getPageIconName,
  getPageHoverText,
  getVisiblePages,
  forTest: {
    getVisiblePagesAroundCurrent,
    isPageComplete,
    getCompletedPages
  }
};
