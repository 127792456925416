import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ResourcePermission, Role, RoleGet, RolePost } from "@app/types";

import { userService } from ".";
import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const rolesService = createApi({
  reducerPath: "rolesServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["Roles", "Permissions", "Role"],
  endpoints: builder => ({
    getRoles: builder.query<Role[], void>({
      query: () => "/api/roles",
      providesTags: ["Roles"]
    }),
    getHostRoles: builder.query<Role[], void>({
      query: () => "/api/roles?type=HOST",
      providesTags: ["Roles"]
    }),
    getClientRoles: builder.query<Role[], void>({
      query: () => "/api/roles?type=CLIENT",
      providesTags: ["Roles"]
    }),
    getRoleById: builder.query<RoleGet, string | number>({
      query: id => `/api/roles/${id}`,
      providesTags: ["Role"]
    }),
    getPermissions: builder.query<ResourcePermission[], void>({
      query: () => "/api/roles/permissions",
      providesTags: ["Permissions"]
    }),
    addRole: builder.mutation<void, RolePost>({
      query: role => ({
        url: "/api/roles",
        method: "POST",
        body: role
      }),
      invalidatesTags: ["Roles", "Role"]
    }),
    updateRole: builder.mutation<void, RolePost>({
      query: role => ({
        url: `/api/roles/${role.id}`,
        method: "PUT",
        body: role
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(userService.util.invalidateTags(["Permission"]));
      },
      invalidatesTags: ["Roles", "Role"]
    }),
    deleteRole: builder.mutation<RoleGet, number>({
      query: roleId => ({
        url: `/api/roles/${roleId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Roles", "Role"]
    })
  })
});

export const {
  useGetRolesQuery,
  useGetHostRolesQuery,
  useGetClientRolesQuery,
  useGetRoleByIdQuery,
  useGetPermissionsQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation
} = rolesService;
