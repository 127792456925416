import React from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { classNames } from "@app/helpers/componentHelpers";

import { Icon, IconFillStyle } from "@atoms/Icon";

import OTTooltip from "../OTTooltip";
import "./NavSectionDivider.scss";

interface NavSectionDividerProps {
  nav?: "top" | "secondary";
  item?: { name: string; icon: { name: string } };
  isExpanded?: boolean;
}

const NavSectionDivider = ({
  nav = "secondary",
  item,
  isExpanded
}: NavSectionDividerProps) => {
  const { elementProps, showTooltip } = useTooltip();

  return item && nav === "secondary" ? (
    <div
      className="nav-section-divider"
      key={item?.name}
      title={item?.name}
      {...elementProps}
    >
      {(isExpanded || item.icon?.name) && (
        <>
          <div className="nav-section-divider__divider--first" />

          <div className="nav-section-divider__title">
            {item.icon && (
              <Icon name={item.icon.name} fillStyle={IconFillStyle.FILLED} />
            )}
            {isExpanded && (
              <div className="nav-section-divider__title__text">
                {item.name}
              </div>
            )}
          </div>
        </>
      )}
      <div className="nav-section-divider__divider" />
      {!isExpanded && <OTTooltip visible={showTooltip} content={item.name} />}
    </div>
  ) : (
    <div className={classNames(["nav-section-divider", nav])}>
      <div className={classNames(["nav-section-divider__divider", nav])} />
    </div>
  );
};

export default NavSectionDivider;
