import { useCallback, useState } from "react";

export function useProjectSession() {
  const [projectSession, setProjectSession] = useState(null);
  const sessionKey = "lastProject";

  const resetProjectSession = useCallback(() => {
    sessionStorage.setItem(sessionKey, "");
    setProjectSession(null);
  }, []);

  const fetchProjectSession = useCallback(() => {
    const lastProject = sessionStorage.getItem(sessionKey);
    if (lastProject) {
      try {
        const projectObj = JSON.parse(lastProject);
        setProjectSession(projectObj);
        return projectObj;
      } catch (error) {
        resetProjectSession();
        return null;
      }
    } else {
      return null;
    }
  }, [resetProjectSession]);

  const changeProjectSession = useCallback(project => {
    if (project) {
      sessionStorage.setItem(sessionKey, JSON.stringify(project));
      setProjectSession(project);
    }
  }, []);

  return {
    projectSession,
    fetchProjectSession,
    changeProjectSession,
    resetProjectSession
  };
}
