import React, { useEffect, useState } from "react";

import { orderBy } from "lodash";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";
import { manageAuditService } from "@shared/services/manageAuditService";

import Popup from "@shared-components/popup/Popup";

import { formatAuditHistory } from "@app/helpers/auditHistory";

import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./WorkpaperAudits.scss";

const WorkpaperAudits = props => {
  const { queryId, handleClose } = props;
  const [workpaperAudits, setWorkpaperAudits] = useState(null);
  const {
    locale,
    options: { longFormat }
  } = useLocaleDate();
  const fetchAudits = async queryId => {
    if (queryId) {
      try {
        const audits =
          await manageAuditService.getAuditRecordsByQueryId(queryId);
        setWorkpaperAudits(audits);
      } catch (err) {
        //eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchAudits(queryId);
  }, [queryId]);

  const renderChanges = orderedAudits =>
    orderedAudits.map((_, i) =>
      formatAuditHistory(orderedAudits[i], orderedAudits[i + 1])
    );

  const renderDate = date => {
    const time = new Intl.DateTimeFormat("en-AU", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Australia/Sydney",
      timeZoneName: "short"
    }).format(new Date(date));
    return `${dateFormatter(date, locale, longFormat)} at ${time}`;
  };

  const renderAuditItem = () => {
    const orderedAudits = orderBy(workpaperAudits, "datetime", "desc");

    const changes = renderChanges(orderedAudits);
    return orderedAudits.map((audit, key) => {
      if (changes[key].length > 0) {
        return (
          <tr key={key} className="workpaper-audits__row">
            <td className="workpaper-audits__date">
              {renderDate(audit.createdAt)}
            </td>
            <td className="workpaper-audits__user">{audit.user}</td>
            <td className="workpaper-audits__change">
              {changes[key].map((val, id) => (
                <div
                  className={
                    id !== changes[key].length - 1 ? "change-item" : ""
                  }
                  key={id}
                >
                  {val}
                </div>
              ))}
            </td>
          </tr>
        );
      }
    });
  };

  return (
    <Popup visibility={!!queryId} handleOutsideClick={false} width="50rem">
      <ModalTemplate
        title="Audit History"
        boxClassName="modalBox"
        content={
          <div className="workpaper-audits">
            <table className="table workpaper-audits__table">
              <tbody>{renderAuditItem()}</tbody>
            </table>
          </div>
        }
        onClose={handleClose}
      />
    </Popup>
  );
};

export default WorkpaperAudits;
