import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

// TODO: put the tokenService endpoint into authService when all auth services are migrated to RTK
export const tokenService = createApi({
  reducerPath: "tokenApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["getClientAccessToken"],
  endpoints: builder => ({
    getClientAccessToken: builder.query({
      query: () => ({
        url: `/api/auth/clientAccessToken`,
        method: "GET"
      }),
      providesTags: ["getClientAccessToken"]
    })
  })
});

export const { useLazyGetClientAccessTokenQuery } = tokenService;
