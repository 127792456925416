import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  manageClientEngagementProjectsActions,
  manageClientEngagementsActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import { useAuthUser } from "@shared/hooks";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import ProjectTimeline from "@shared-components/projectTimeline/ProjectTimeline";

import { routeConstants } from "@app/constants";
import { AccessLevel, ResourceName } from "@app/types";

import { Button } from "@atoms/Button";

import DropdownPagination from "@components/molecules/DropdownPagination";
import ProjectsTable from "@components/organisms/ProjectsTable";
import SearchTextInput from "@components/organisms/SearchTextInput";

import "./ManageClientEngagementProjects.scss";

const ManageClientEngagementProjects = props => {
  const { width: windowWidth } = useWindowSize();
  const [engagement, setEngagement] = useState(props.engagement);
  const { user } = useAuthUser();
  const manageClientEngagementProjects = useSelector(
    state => state.manageClientEngagementProjects
  );
  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({ string: "", projects: [] });
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const [toggleSwitch] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const { isKeywordInclude } = utilities;
  const { t } = useTranslation();

  useEffect(() => {
    setEngagement(props.engagement);
  }, [props.engagement]);

  useEffect(() => {
    dispatch(
      manageClientEngagementProjectsActions.getClientEngagementProjects(
        engagement
      )
    );
    dispatch(manageClientEngagementsActions.getMembers(engagement));
    return () => {
      dispatch(manageClientEngagementProjectsActions.reset());
      dispatch(manageClientEngagementsActions.resetMembers());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsMember(
      manageClientEngagements.members.some(member => member.id === user.id)
    );
  }, [manageClientEngagements.members, user]);

  useEffect(() => {
    if (manageClientEngagementProjects.projects?.length) {
      setFilter(prevState => ({
        ...prevState,
        projects: structuredClone(manageClientEngagementProjects.projects).sort(
          (a, b) => {
            let result = 1;
            if (a.status === b.status) {
              if (a.upcomingMilestone && b.upcomingMilestone) {
                if (a.upcomingMilestone.hours > b.upcomingMilestone.hours) {
                  result = 1;
                } else {
                  result = -1;
                }
              } else if (!b.upcomingMilestone) {
                result = -1;
              } else {
                result = 0;
              }
            } else {
              if (a.status === systemConstants.project.status.behind) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.onTrack &&
                (b.status === systemConstants.project.status.upcoming ||
                  b.status === systemConstants.project.status.completed ||
                  b.status === systemConstants.project.status.archived)
              ) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.upcoming &&
                (b.status === systemConstants.project.status.completed ||
                  b.status === systemConstants.project.status.archived)
              ) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.completed &&
                b.status === systemConstants.project.status.archived
              ) {
                result = -1;
              }
            }
            return result;
          }
        )
      }));
    }
  }, [manageClientEngagementProjects.projects]);

  useEffect(() => {
    dispatch(
      manageClientEngagementProjectsActions.getClientEngagementProjects(
        engagement
      )
    );
    dispatch(
      manageClientEngagementProjectsActions.clearUpdateClientEngagementProjectFlag()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageClientEngagementProjects.updatedProject]);

  useEffect(() => {
    if (filter.string) {
      utilities.createPagination({
        list: toggleSwitch
          ? filter.projects
          : filter.projects.filter(
              projectObject =>
                projectObject.status !== systemConstants.project.status.archived
            ),
        pagination,
        setPagination
      });
    } else {
      utilities.createPagination({
        list: toggleSwitch
          ? structuredClone(manageClientEngagementProjects.projects)
          : structuredClone(manageClientEngagementProjects.projects).filter(
              projectObject =>
                projectObject.status !== systemConstants.project.status.archived
            ),
        pagination,
        setPagination
      });
    }
    // eslint-disable-next-line
  }, [filter.projects, toggleSwitch]);

  useEffect(() => {
    if (pagination.pages.length) {
      setProjects(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setProjects([]);
    }
  }, [pagination]);

  useEffect(() => {
    dispatch(manageClientEngagementsActions.getMembers(engagement));
    return () => {
      dispatch(manageClientEngagementsActions.resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagement]);

  const handleAddProject = () => {
    navigate(`${routeConstants.engagements}/${engagement.id}/create-project`);
  };

  const handleFilterChange = useCallback(
    searchInput => {
      if (!searchInput) {
        setFilter(prevState => ({
          ...prevState,
          string: "",
          projects: structuredClone(manageClientEngagementProjects.projects)
        }));
      } else {
        const filteredProjects = structuredClone(
          manageClientEngagementProjects.projects
        ).filter(project => isKeywordInclude(project.name, searchInput));
        setFilter(prevState => ({
          ...prevState,
          string: searchInput,
          projects: filteredProjects
        }));
      }
    },
    [manageClientEngagementProjects, isKeywordInclude]
  );

  const getProjects = () => {
    if (toggleSwitch) {
      return projects;
    } else {
      return projects.filter(
        projectObject =>
          projectObject.status !== systemConstants.project.status.archived
      );
    }
  };

  const showAddProjectButton = () => {
    if (
      user.checkAccess(
        ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
        AccessLevel.CREATE
      )
    ) {
      return true;
    }

    return (
      user.checkAccess(
        ResourceName.PROJECTS_WITH_MEMBERSHIP,
        AccessLevel.CREATE
      ) && isMember
    );
  };

  const getHeader = () => {
    if (windowWidth > systemConstants.mediaBreakpoints.tabPort) {
      return (
        <div className="client-engagement-projects__header">
          <div className="client-engagement-projects__search-container">
            <div className="client-engagement-projects__search-container--box">
              <SearchTextInput
                label={t("common:ui.engagement.searchProjectLabel")}
                handleChange={handleFilterChange}
              />
            </div>
          </div>

          {showAddProjectButton() && (
            <div>
              {/*  */}
              <Button
                iconName="add"
                label={t("common:ui.project.title_add")}
                onClick={handleAddProject}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <>
          {showAddProjectButton() && (
            <div className="client-engagement-projects__header-add-button">
              {/*  */}
              <Button
                iconName="add"
                label={t("common:ui.project.title_add")}
                onClick={handleAddProject}
              />
            </div>
          )}
          <div className="client-engagement-projects__header">
            <div className="client-engagement-projects__search-container">
              <div className="client-engagement-projects__search-container--box">
                <SearchTextInput
                  label={t("common:ui.engagement.searchProjectLabel")}
                  handleChange={handleFilterChange}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="client-engagement-projects__container">
      {getHeader()}

      {projects.length ? (
        <>
          {windowWidth > systemConstants.mediaBreakpoints.tabPort ? (
            <>
              <h3>{t("common:ui.engagement.projectTimeline.title")}</h3>
              <div className="client-engagement-projects__project-timeline-box">
                <ProjectTimeline
                  projects={projects}
                  engagement={engagement}
                  defaultTimeStart={moment
                    .min(projects.map(project => moment(project.startDate)))
                    .add(-1, "day")}
                  defaultTimeEnd={moment
                    .max(
                      projects.map(project =>
                        moment(project.plannedCompletionDate)
                      )
                    )
                    .add(1, "day")}
                />
              </div>

              <div className="client-engagement-projects__project-table-header-box">
                <h3>{t("common:ui.engagement.projects.title")}</h3>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="client-engagement-projects__table">
            <ProjectsTable engagement={engagement} data={getProjects()} />
          </div>

          {pagination.pages.length ? (
            <DropdownPagination
              pagination={pagination}
              setPagination={setPagination}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
ManageClientEngagementProjects.propTypes = {
  engagement: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default ManageClientEngagementProjects;
