import { systemConstants } from "@shared/constants/systemConstants";

const documentStatus = systemConstants.project.document.status;

export const documentActions = Object.freeze({
  editTags: "editTags",
  editDocument: "editDocument",
  updateDocumentProperties: "updateDocumentProperties",
  uploadDocument: "uploadDocument",
  reviewRevisionHistory: "reviewRevisionHistory",
  deleteDocument: "deleteDocument",
  restoreDocument: "restoreDocument",
  downloadDocument: "downloadDocument",
  createDocumentQuery: "createDocumentQuery",
  viewFolder: "viewFolder",
  viewInRequest: "viewInRequest"
});

export const isExcel = documentName => {
  return !!documentName.match(/\.xlsx$/);
};

export const filterInactiveDocuments = documents => {
  return documents.filter(
    attachment =>
      attachment.status !== documentStatus.archived &&
      attachment.status !== documentStatus.deleted
  );
};

export const removeDuplicateAndArchivedDocuments = documents => {
  const uniqDocuments = filterInactiveDocuments(documents).filter(
    (attachment, i, self) =>
      i ===
      self.findIndex(selfAttachment => selfAttachment.id === attachment.id)
  );
  return uniqDocuments;
};
