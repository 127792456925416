import { getAnswerValuesForQuestion } from "../../structure";

/**
 * This strategy is used for questions that have a parent question
 * The parent question must have been answered with the dependent answer for the question to be visible
 * @return {Object} Object containing the strategy functions
 */
export const simpleParentStrategy = () => {
  /**
   * Gets answers where dependency is satisfied
   * @param {Object} question
   * @param {Object} answers
   * @return {Object[]}
   */
  const answersWithDependencySatisfied = (question, answers) => {
    const parentAnswers = getAnswerValuesForQuestion(
      question.parentQuestionId,
      answers
    );
    return (
      parentAnswers?.filter(
        answer => answer.value === question.dependentAnswer
      ) || []
    );
  };

  /**
   *
   * Gets the question's relevant entities (where there is a parent ID)
   * @param {Object} question
   * @param {Object} answers
   * @return {Object}
   */
  const getQuestionRelevantEntities = (question, answers) => {
    if (!question?.parentQuestionId) {
      return { relevantEntities: null };
    }

    let relevantEntitiesSet = new Set();
    const relevantAnswers = answersWithDependencySatisfied(question, answers);
    relevantAnswers?.forEach(answer => {
      answer.entities?.forEach(entityId => {
        relevantEntitiesSet.add(entityId);
      });
    });

    // need special case for when the parent answer is a global answer
    // have answers and no entities = globally answered
    const isAnsweredGlobally =
      relevantAnswers?.length > 0 && relevantEntitiesSet.size === 0;
    if (isAnsweredGlobally) {
      return { relevantEntities: null };
    }

    return { relevantEntities: [...relevantEntitiesSet] };
  };

  /**
   * Checks if the question is visible based on the current answers
   * @param {Object} question Question to check the visibility of
   * @param {Object} answers
   * @return {boolean} TRUE if there are fulfilled answers to the Question's parent, OR if there is no parent
   */
  const isQuestionVisible = (question, answers) => {
    if (question.parentQuestionId) {
      return answersWithDependencySatisfied(question, answers).length > 0;
    }
    return true;
  };

  /**
   * Checks if the question is mandatory based on its configuration
   * @returns TRUE if the question has been configured to be mandatory, FALSE otherwise
   */
  const isQuestionMandatory = question => {
    return question.isMandatory ?? false;
  };

  return {
    getQuestionRelevantEntities,
    isQuestionVisible,
    isQuestionMandatory,
    forTest: {
      answersWithDependencySatisfied
    }
  };
};
