import React, { useEffect, useState } from "react";

import Input from "../input/Input";
import "./documentNameChange.scss";
import { Button, ButtonVariant } from "@atoms/Button";
import { useTranslation } from "react-i18next";

const DocumentNameChange = props => {
  const { t } = useTranslation();
  const [document, setDocument] = useState(props.document);
  const [name, setName] = useState(props.document.name);
  const [error, setError] = useState();

  const handleChange = event => {
    event.stopPropagation();
    setName(event.target.value);
  };

  useEffect(() => {
    setDocument(props.document);
  }, [props.document]);

  const handleRename = () => {
    if (!name || name === document.name) {
      setError(true);
    } else {
      setError(false);
      props.onRename(name);
    }
  };

  return (
    <div className="document-name-change">
      <div className="document-name-change__container">
        <div className="document-name-change__container-header">
          {props.message ? <div>{props.message}</div> : ""}
        </div>

        <div className="document-name-change__container-body">
          <div className="document-name-change__container-body-name">
            <span className="document-name-change__container-body-name-label">
              Name
            </span>
            <Input
              error={error}
              value={name}
              placeholder="Please enter the document name"
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="document-name-change__button">
        <div className="document-name-change__button--cancel">
          <Button
            variant={ButtonVariant.SECONDARY}
            label={t("common:ui.forms.cancel.label")}
            onClick={props.onCancel}
          />
        </div>
        <div className="document-name-change__button--rename">
          <Button
            label={t("common:ui.forms.rename.label")}
            onClick={handleRename}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentNameChange;
