import { StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface CalendarOpenSlice {
  openedCalendar: string;
  setOpenedCalendar: (openingId: CalendarOpenSlice["openedCalendar"]) => void;
}

const createCalendarOpenSlice: StateCreator<CalendarOpenSlice> = set => ({
  openedCalendar: "",
  setOpenedCalendar: openedCalendar =>
    set(() => ({
      openedCalendar
    }))
});

export const useDatePickerStore = create<CalendarOpenSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createCalendarOpenSlice(...a)
      }),
      {
        name: "ot-app-store"
      }
    ),
    { name: "ot-app-store", enabled: process.env.NODE_ENV === "development" }
  )
);
