import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { validateForm } from "@app/helpers/userForm";
import { useGoBack } from "@app/hooks";

import { Inline, Stack } from "@fermions";

import UserProfileImage from "@components/organisms/UserProfileImage";
import FormPageTemplate from "@components/templates/FormPageTemplate";

import "./UserForm.scss";
import UserFormContents from "./UserFormContents";

const UserForm = props => {
  const {
    title,
    breadcrumbs,
    formConfig,
    user,
    isExternalUsersEnabled,
    isHostUserUpdated,
    submitLabel,
    loading,
    other,
    moreContents
  } = props;

  const { t } = useTranslation();
  const goBack = useGoBack();
  const [avatar, setAvatar] = useState(user?.avatar);
  const handleProfileImageSetOrRemoveClick = image => {
    setAvatar(image ? { image } : null);
  };

  const yupSchema = useMemo(() => {
    const schema = {
      properties: yup.object().shape({
        notification: yup.object().shape({
          email: yup
            .string()
            .required("common:user.configured.fields.notification.errorMessage")
        })
      })
    };

    (formConfig || []).forEach(field => {
      if (field.type === "custom") {
        field.fields.forEach(f => {
          validateForm({ field: f, schema, i18nText: t });
        });
      } else {
        validateForm({ field, schema, i18nText: t });
      }
    });
    return yup.object(schema);
  }, [formConfig, t]);

  const handleSubmit = profile => {
    const userProfile = structuredClone(profile);
    if (user?.id) {
      userProfile.id = user.id;
    }
    if (avatar !== false) {
      userProfile.avatar = avatar;
    }
    userProfile.properties = {
      ...(user?.properties ?? {}),
      ...userProfile.properties
    };
    const countryCodes = ["businessCountryCode", "mobileCountryCode"];
    countryCodes.forEach(codeKey => {
      const value = userProfile[codeKey];
      if (value) {
        userProfile[codeKey] = value.code;
      }
    });
    userProfile.roleId = userProfile.role?.id;
    props.handleSubmit(userProfile);
  };

  const [userProfileModal, setUserProfileModal] = useState(<></>);

  const renderUserProfile = useMemo(
    () => (
      <UserProfileImage
        avatar={avatar}
        profileImageSetClick={handleProfileImageSetOrRemoveClick}
        name={user?.name ?? ""}
        setUserProfileModal={setUserProfileModal}
      />
    ),
    [avatar, user?.name]
  );

  return (
    <>
      <FormPageTemplate
        title={title}
        breadcrumbs={breadcrumbs}
        alwaysShowBreadcrumbs
        sticky
        form={{
          key: user?.id,
          handleSubmit: handleSubmit,
          submitLabel: submitLabel,
          yupSchema: yupSchema,
          contents: (
            <Stack gap="300" className="user-form-contents">
              {moreContents ?? <></>}
              <Inline gap="300">
                {renderUserProfile}
                <UserFormContents
                  moreContents={moreContents}
                  setUserProfileModal={setUserProfileModal}
                  formConfig={formConfig}
                  user={user}
                  isExternalUsersEnabled={isExternalUsersEnabled}
                  isHostUserUpdated={isHostUserUpdated}
                />
              </Inline>
            </Stack>
          ),
          handleCancel: goBack
        }}
        isLoading={loading}
        other={other}
      />
      {userProfileModal}
    </>
  );
};

UserForm.propTypes = {
  formConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      type: PropTypes.string
    })
  ),
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  user: PropTypes.object,
  title: PropTypes.string.isRequired,
  isHostUserUpdated: PropTypes.bool
};

export default UserForm;
