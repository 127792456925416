import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Button, ButtonVariant } from "@atoms/Button";

import InlineAlert from "@components/atoms/InlineAlert";
import TextInput from "@components/atoms/TextInput";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./TagEditor.scss";

const EDIT_TYPES = {
  ADD: {
    title: "common:ui.label.add",
    button: {
      label: "common:ui.forms.add.label",
      variant: "primary"
    },
    enable: tag => tag.name
  },
  EDIT: {
    title: "common:ui.label.edit",
    button: {
      label: "common:ui.forms.update.label",
      variant: "primary"
    },
    enable: (tag, existing) => tag.name && tag.name != existing.name
  },
  DELETE: {
    title: "common:ui.label.confirmDelete",
    button: {
      label: "common:ui.forms.yes.label",
      variant: "danger"
    },
    enable: () => true
  }
};

const TagEditor = ({
  existingTag,
  editType,
  handleSubmit,
  handleDismiss,
  message,
  t
}) => {
  const [tag, setTag] = useState(
    existingTag ? { id: existingTag.id, name: existingTag.name } : { name: "" }
  );
  const inputFocusRef = React.createRef();

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
  }, [inputFocusRef]);

  const onSubmit = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      const tagToBeUpdated = {
        ...existingTag,
        name: tag.name.trim()
      };
      handleSubmit(tagToBeUpdated);
    },
    [handleSubmit, existingTag, tag]
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Escape") {
        event.preventDefault();
        handleDismiss();
      }
      if (event.key === "Enter") {
        event.preventDefault();
        onSubmit(event);
      }
    },
    [handleDismiss, onSubmit]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleNameChange = event => {
    const name = event.target.value;
    setTag({ ...tag, name });
  };

  return (
    <>
      <ModalTemplate
        boxClassName="tag-editor"
        title={t(EDIT_TYPES[editType].title)}
        onClose={handleDismiss}
        content={
          <>
            {message && (
              <div className="tag-editor__error-box">
                <InlineAlert type="error" message={message} />
              </div>
            )}
            {editType !== "DELETE" && (
              <TextInput
                label="Name"
                required={true}
                value={tag.name || ""}
                onChange={handleNameChange}
                ref={inputFocusRef}
                maxLength={50}
              />
            )}
          </>
        }
        footer={
          <>
            <Button
              variant={EDIT_TYPES[editType].button.variant}
              label={t(EDIT_TYPES[editType].button.label)}
              disabled={!EDIT_TYPES[editType].enable(tag, existingTag)}
              onClick={onSubmit}
            />
            <Button
              variant={ButtonVariant.SECONDARY}
              label={t("common:ui.forms.no.label")}
              onClick={handleDismiss}
            />
          </>
        }
      />
    </>
  );
};

TagEditor.propTypes = {
  existingTag: PropTypes.object,
  editType: PropTypes.oneOf(["ADD", "EDIT", "DELETE"]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default withTranslation()(TagEditor);
