import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "use-onclickoutside";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import "./popup.scss";

const Popup = props => {
  const [popupOpened, setPopupOpened] = useState(false);
  const popupRef = useRef(null);
  const [popupVisibility, setPopupVisibility] = useState(false);

  useOnClickOutside(popupRef, () => {
    if (props.handleOutsideClick) {
      handleFreezeScrollPosition(false);
      setPopupVisibility(false);
      props.handleOutsideClick();
    }
  });

  useEffect(() => {
    let maybeTimerToClear = null;
    if (props.visibility) {
      setPopupOpened(true);
      setPopupVisibility(props.visibility);
    } else {
      if (popupOpened) {
        setPopupOpened(false);
        maybeTimerToClear = setTimeout(() => {
          setPopupVisibility(props.visibility);
        }, 300);
      } else {
        setPopupVisibility(props.visibility);
      }
    }

    return () => {
      if (maybeTimerToClear) {
        clearTimeout(maybeTimerToClear);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visibility]);

  const preventEventPropagation = event => {
    // we want interaction events from within the popup stay in the popup and not leak out to parents
    event.stopPropagation();
  };

  return (
    <>
      {popupVisibility && (
        <div
          className={
            "popup__container " +
            (!popupOpened ? " popup__container-close-animation" : "")
          }
          onScroll={preventEventPropagation}
        >
          <div
            ref={popupRef}
            className="popup__body"
            style={{
              height: props.height,
              maxWidth: props.width
            }}
          >
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
