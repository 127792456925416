import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { authActions } from "@shared/actions";
import { useStaticAssets } from "@shared/hooks";

import "./AccountNotFound.scss";

const AccountNotFound = () => {
  const location = useLocation();
  const authentication = useSelector(state => state.authentication);
  const [prevRouteObject, setPrevRouteObject] = useState(location.state);
  const navigate = useNavigate();
  const [hostObject, sethostObject] = useState(null);
  const [error, setError] = useState(authentication.error?.message);
  const dispatch = useDispatch();
  const { getUrl } = useStaticAssets();

  useEffect(() => {
    if (authentication.error?.message) {
      setError(authentication.error?.message);
    }
  }, [authentication.error?.message]);

  useEffect(() => {
    if (!authentication.host) {
      dispatch(authActions.getHostWithBrandDetails());
    }
    setPrevRouteObject(location.state);
  }, [location.state, dispatch, authentication.host]);

  const handleClick = () => {
    if (
      prevRouteObject.prevRoute === routeConstants.signup &&
      prevRouteObject.token
    ) {
      navigate(`${routeConstants.signup}?token=${prevRouteObject.token}`);
    } else {
      navigate(routeConstants.login);
    }
  };

  useEffect(() => {
    sethostObject(authentication.host);
  }, [authentication.host]);

  useEffect(() => {
    dispatch(authActions.resetExternalLoginFailed());
  }, [dispatch]);

  return (
    <div className="account-not-found--container">
      {hostObject && (
        <div className="account-not-found--logo-container">
          <img
            src={getUrl("authLogo.png")}
            alt="Logo"
            className="account-not-found--logo"
          />
        </div>
      )}
      <div className="account-not-found--content">
        <div className="account-not-found--host-header">No account found</div>

        <i className="material-icons account-not-found--container--body-icon">
          warning
        </i>

        {prevRouteObject.prevRoute === routeConstants.signup &&
        prevRouteObject.token ? (
          <div className="account-not-found--content-message">
            The Google account you selected has not been authorised to access
            OneTeam. Either ask the person who created your account to use your
            Google email, or{" "}
            <span
              className="account-not-found__container--body-link-text"
              onClick={handleClick}
            >
              sign up with a OneTeam account and password.
            </span>
          </div>
        ) : (
          <div className="account-not-found--content-message">
            {error}. <br />
            <br />
            Check that you selected the right account, or contact the person
            that created the {hostObject?.name} account for you.
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountNotFound;
