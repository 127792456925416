import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "@shared/constants/systemConstants";
import prepareHeaders from "@shared/services/rtkUtilities.js";

export const actionItemTypesService = createApi({
  reducerPath: "actionItemTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: [
    "ActionItemTypes",
    "AllActionItemTypes",
    "CreatableActionItemType",
    "UpdateableActionItemType",
    "ActionItemType",
    "ActionItemTypeFormOptions"
  ],
  endpoints: builder => ({
    getCreatableActionItemTypes: builder.query({
      query: ({ viewScope, projectId }) => ({
        url: `/api/projects/${projectId}/actionItemTypes`,
        params: {
          canCreate: true,
          from: viewScope ?? ""
        }
      }),
      providesTags: ["CreatableActionItemType"]
    }),
    getActionItemTypes: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}/actionItemTypes`,
      providesTags: ["ActionItemTypes"]
    }),
    getAllActionItemTypes: builder.query({
      query: () => `/api/actionItemTypes`,
      providesTags: ["AllActionItemTypes"]
    }),
    getActionItemType: builder.query({
      query: ({ actionItemTypeId }) =>
        `/api/actionItemTypes/${actionItemTypeId}`,
      providesTags: ["ActionItemType"]
    }),
    getActionItemTypesFormOptions: builder.query({
      query: () => `/api/actionItemTypes/formOptions`,
      providesTags: ["ActionItemTypeFormOptions"]
    }),
    createActionItemType: builder.mutation({
      query: ({ actionItemType }) => ({
        url: `/api/actionItemTypes`,
        method: "POST",
        body: actionItemType
      }),
      invalidatesTags: [
        { type: "AllActionItemTypes" },
        { type: "CreatableActionItemType" },
        { type: "ActionItemTypes" }
      ]
    }),
    updateActionItemType: builder.mutation({
      query: ({ actionItemType }) => ({
        url: `/api/actionItemTypes/${actionItemType.id}`,
        method: "PUT",
        body: actionItemType
      }),
      invalidatesTags: [
        "AllActionItemTypes",
        "UpdateableActionItemType",
        "ActionItemTypes",
        "ActionItemType"
      ]
    })
  })
});

export const {
  useGetActionItemTypeQuery,
  useGetActionItemTypesQuery,
  useLazyGetAllActionItemTypesQuery,
  useGetCreatableActionItemTypesQuery,
  useGetActionItemTypesFormOptionsQuery,
  useCreateActionItemTypeMutation,
  useUpdateActionItemTypeMutation
} = actionItemTypesService;
