import React from "react";

import PropTypes from "prop-types";

import BoxTemplate from "../BoxTemplate";
import "./DashboardBoxTemplate.scss";

const DashboardBoxTemplate = props => {
  const { title, subtext, action, boxClassName } = props;
  return (
    <BoxTemplate
      boxClassName="dashboard-card-template"
      additionalClassNames={[boxClassName]}
      title={title}
      subtext={subtext}
      action={action}
    >
      <div
        className={`dashboard-card-template__content ${boxClassName}__content`}
      >
        {props.children}
      </div>
    </BoxTemplate>
  );
};

DashboardBoxTemplate.defaultProps = {};

DashboardBoxTemplate.propTypes = {
  boxClassName: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtext: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
  getHeaderHeight: PropTypes.func
};

export default DashboardBoxTemplate;
