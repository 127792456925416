import { manageQueryResponsesConstants } from "../constants";
import { manageQueryResponsesService } from "../services";

export const manageQueryResponsesActions = {
  getQueryResponses,
  addQueryResponse,
  updateQueryResponse,
  clearIsAdded,
  clearIsUpdated,
  clearError,
  reset
};

function getQueryResponses(queryId) {
  return dispatch => {
    dispatch(request());

    manageQueryResponsesService.getQueryResponses(queryId).then(
      queryResponses => {
        dispatch(success(queryId, queryResponses));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageQueryResponsesConstants.GET_QUERY_RESPONSES_REQUEST
    };
  }
  function success(queryId, queryResponses) {
    return {
      type: manageQueryResponsesConstants.GET_QUERY_RESPONSES_SUCCESS,
      queryId,
      queryResponses
    };
  }
  function failure(error) {
    return {
      type: manageQueryResponsesConstants.GET_QUERY_RESPONSES_FAILURE,
      error
    };
  }
}

function addQueryResponse(queryResponse) {
  return dispatch => {
    dispatch(request());

    manageQueryResponsesService.addQueryResponse(queryResponse).then(
      isAdded => {
        dispatch(success(isAdded));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageQueryResponsesConstants.ADD_QUERY_RESPONSE_REQUEST
    };
  }
  function success(isAdded) {
    return {
      type: manageQueryResponsesConstants.ADD_QUERY_RESPONSE_SUCCESS,
      isAdded
    };
  }
  function failure(error) {
    return {
      type: manageQueryResponsesConstants.ADD_QUERY_RESPONSE_FAILURE,
      error
    };
  }
}

function updateQueryResponse(queryResponse) {
  return dispatch => {
    dispatch(request());

    manageQueryResponsesService.updateQueryResponse(queryResponse).then(
      isUpdated => {
        dispatch(success(isUpdated));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_REQUEST
    };
  }
  function success(isUpdated) {
    return {
      type: manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_SUCCESS,
      isUpdated
    };
  }
  function failure(error) {
    return {
      type: manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_FAILURE,
      error
    };
  }
}

function clearIsUpdated() {
  return { type: manageQueryResponsesConstants.CLEAR_IS_UPDATED };
}

function clearIsAdded() {
  return { type: manageQueryResponsesConstants.CLEAR_IS_ADDED };
}

function clearError() {
  return { type: manageQueryResponsesConstants.CLEAR_ERROR };
}

function reset() {
  return { type: manageQueryResponsesConstants.RESET };
}
