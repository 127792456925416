import { maxBy, uniqBy } from "lodash";

import websheetWizardUtilities from "../websheetWizardUtilities";

const canonicalString = str => str.normalize().toLocaleLowerCase();

const suggestHeadersForSheetData = (
  sheetData,
  templateHeaders,
  maxRowsToConsider
) => {
  const relevantRowsOfData = sheetData.data.slice(0, maxRowsToConsider);

  const normalisedTemplateHeaders = templateHeaders.map(t =>
    canonicalString(t.name)
  );

  const denormaliseTemplateHeaders = (() => {
    const reverseMap = templateHeaders.reduce(
      (acc, th) => ({
        ...acc,
        [canonicalString(th.name)]: th
      }),
      {}
    );

    return normalised => reverseMap[normalised];
  })();

  const normalisedAlternateNames = {};

  templateHeaders
    .filter(th => th.alternateNames?.length)
    .forEach(th => {
      th.alternateNames.forEach((name, index) => {
        normalisedAlternateNames[canonicalString(name)] = {
          name: canonicalString(th.name),
          index
        };
      });
    });

  const getMatchedAlternateNames = (originRow, matchTemplateHeaders) => {
    let matchedAlternateNamesWithOrder = {};

    originRow.forEach(({ templateHeader, value }, colIndex) => {
      if (templateHeader) {
        return;
      }
      const { name: originHeaderName } = normalisedAlternateNames[value] ?? {};

      if (!(originHeaderName && matchTemplateHeaders.has(originHeaderName))) {
        return;
      }

      const namesOrder = normalisedAlternateNames[value].index;

      const existingMatch = matchedAlternateNamesWithOrder[originHeaderName];

      if (!existingMatch?.value) {
        matchedAlternateNamesWithOrder[originHeaderName] = {
          namesOrder,
          colIndex,
          value
        };
        return;
      }

      if (namesOrder < existingMatch?.namesOrder) {
        matchedAlternateNamesWithOrder[originHeaderName] = {
          namesOrder,
          colIndex,
          value
        };
      }
    });

    const matchedAlternateNames = {};
    Object.keys(matchedAlternateNamesWithOrder).forEach(name => {
      const index = matchedAlternateNamesWithOrder[name].colIndex;
      matchedAlternateNames[index] = name;
    });

    return matchedAlternateNames;
  };

  const rowToBestMatchedHeaders = row => {
    const matchTemplateHeaders = new Set(normalisedTemplateHeaders);

    const originRow = row.map(cellData => {
      const value = canonicalString((cellData ?? "").toString());
      if (matchTemplateHeaders.delete(value)) {
        return {
          templateHeader: value,
          value
        };
      }
      return {
        templateHeader: null,
        value
      };
    });

    const matchedAlternateNames = getMatchedAlternateNames(
      originRow,
      matchTemplateHeaders
    );

    const result = originRow.map(({ templateHeader, value }, colIndex) => {
      if (templateHeader) {
        return value;
      }
      if (matchedAlternateNames[colIndex]) {
        return matchedAlternateNames[colIndex];
      }
      return null;
    });

    return result;
  };

  const calculateRowScore = row => {
    return rowToBestMatchedHeaders(row)
      .map(r => (r ? 1 : 0))
      .reduce((acc, s) => acc + s, 0);
  };

  const rowsWithScore = relevantRowsOfData
    .map(row => ({
      row,
      score: calculateRowScore(row)
    }))
    .filter(r => r.score !== 0);

  // No rows have any cells which matched any header so bail
  if (rowsWithScore.length === 0) {
    return null;
  }

  // NB: 'bestRow' might be shared equally but if the order is the same for them all its still 'best'
  const best = maxBy(rowsWithScore, ({ score }) => score);
  const bestScore = best.score;
  let bestRow = best.row;

  // Multiple rows are candidates but if rows suggest different combination of headers then we cannot offer a suggestion
  const equalBest = rowsWithScore.filter(r => r.score === bestScore);
  if (equalBest.length > 1) {
    const calculateRowHash = row => {
      return rowToBestMatchedHeaders(row)
        .map(cellValue =>
          normalisedTemplateHeaders.findIndex(th => th === cellValue)
        )
        .join("_");
    };

    const uniqueSuggestions = uniqBy(equalBest, ({ row }) =>
      calculateRowHash(row)
    );
    if (uniqueSuggestions.length !== 1) {
      return null;
    }
  }

  const templateHeaderSuggestions = rowToBestMatchedHeaders(bestRow)
    .map((headerName, columnIndex) => ({
      templateHeader: denormaliseTemplateHeaders(headerName),
      columnIndex
    }))
    .filter(({ templateHeader }) => templateHeader)
    .sort(websheetWizardUtilities.headerSortComparator);
  return {
    score: bestScore,
    headers: templateHeaderSuggestions
  };
};

/**
 * @typedef HeaderSuggestion
 * @type {Object}
 * @property {Object.<string, *>} templateHeader
 * @property {number} columnIndex
 */

/**
 * @typedef SheetHeaderSuggestionResult
 * @type {Object}
 * @property {string} sheetName
 * @property {number} sheetIndex
 * @property {Array<HeaderSuggestion>} headers
 * @property {number} score
 */

/**
 * @param {*} websheetData
 * @param {*} templateHeaders
 * @param {number} maxRowsToConsider
 * @param {Array<string>} sheetsToIgnore List of Sheet Names to ignore when making suggestions
 * @returns {SheetHeaderSuggestionResult|null} Suggestions or NULL if no suggestion could be made due to equal likelihood or no matches
 */
const suggestSheetAndHeadersForWebsheet = (
  websheetData,
  templateHeaders,
  maxRowsToConsider,
  sheetsToIgnore = []
) => {
  const sheetsToIgnoreSet = new Set(sheetsToIgnore);
  const headerSuggestions = websheetData
    .map((sheetData, sheetIndex) => ({ sheetData, sheetIndex }))
    .filter(({ sheetData }) => {
      return !sheetsToIgnoreSet.has(sheetData.sheet);
    })
    .map(({ sheetData, sheetIndex }) => {
      const suggestions = suggestHeadersForSheetData(
        sheetData,
        templateHeaders,
        maxRowsToConsider
      );
      return {
        sheetName: sheetData.sheet,
        sheetIndex,
        headers: suggestions?.headers ?? null,
        score: suggestions?.score ?? 0
      };
    })
    .filter(s => s.score);

  // No good suggestions so just bail here
  if (headerSuggestions.length === 0) {
    return null;
  }

  // find the best from all the sheets
  const best = maxBy(headerSuggestions, ({ score }) => score);
  const bestScore = best.score;

  // Multiple sheets have same highest score, thus cannot offer suggestion
  const equalBest = headerSuggestions.filter(r => r.score === bestScore);
  if (equalBest.length !== 1) {
    return null;
  }

  return best;
};

// may need to do a dry-run because the whole websheet data is needed
// i.e. do the dry-run to know the sheet that will be selected
// once we know that let the component fetch the whole data for it if it need to
// splice it into the partial websheet
// do the proper run

export default {
  suggest: suggestSheetAndHeadersForWebsheet
};
