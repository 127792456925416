import React, { useMemo } from "react";

import PropTypes from "prop-types";

import "./NewsMastheadImage.scss";

const NewsMastheadImage = props => {
  const MaybeClickableWrapper = useMemo(
    () =>
      ({ children }) => {
        if (props.url) {
          return (
            <a
              href={props.url}
              title={props.imageAlt}
              target="_blank"
              className="news-masthead-image"
            >
              {children}
            </a>
          );
        }
        return (
          <div title={props.imageAlt} className="news-masthead-image">
            {children}
          </div>
        );
      },
    [props.imageAlt, props.url]
  );

  return (
    <MaybeClickableWrapper>
      <img
        src={props.imageSrc}
        alt={props.imageAlt}
        className="news-masthead-image__image"
      ></img>
      {props.children}
    </MaybeClickableWrapper>
  );
};

NewsMastheadImage.defaultProps = {
  imageAlt: ""
};

NewsMastheadImage.propTypes = {
  url: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string
};

export default NewsMastheadImage;
