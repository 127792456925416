import React, { useCallback, useEffect } from "react";

import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { formattedCodes } from "@shared/helpers";

import { Stack } from "@fermions";

import Form from "@components/atoms/Form";

const UserFormContents = props => {
  const { formConfig, user, isExternalUsersEnabled, isHostUserUpdated } = props;
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!user?.externalUser && isExternalUsersEnabled && isHostUserUpdated) {
      setValue("externalUser.username", "");
    }
  }, [user?.externalUser]);

  const renderForm = useCallback(
    field => {
      const label =
        field.key && t(`common:user.configured.fields.${field.key}.label`);
      switch (field?.type) {
        case "text":
        case "number":
          return (
            <Form.TextField
              key={field.key}
              name={field.path ?? field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              defaultValue={get(user, field.path ?? field.key) ?? ""}
            />
          );
        case "code":
          const value =
            get(user, field.key) ?? systemConstants.defaultCountryCode;
          return (
            <Form.Dropdown
              defaultValue={{
                id: 0,
                name: value,
                code: value
              }}
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              items={formattedCodes.filter(
                codeObject => codeObject.code === value
              )}
              transformSelected={value => value.code}
            />
          );
        case "dropdown":
          const userValue = get(user, field.path ?? field.key);
          const defaultValue = field.items?.find(
            item => item.value === userValue
          );
          return (
            <Form.Dropdown
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              defaultValue={defaultValue}
              disabled={field.disabled ?? false}
              items={field.items}
            />
          );
        case "radio":
          const items = field.items.map(item => ({
            value: item,
            label: t(`common:user.configured.fields.${field.key}.${item}.label`)
          }));
          return (
            <Form.RadioGroup
              defaultValue={get(user, field.path, field.defaultValue)}
              name={field.path ?? field.key}
              label={
                field.key &&
                t(`common:user.configured.fields.${field.key}.label`)
              }
              required={false}
              disabled={false}
              items={items}
            />
          );
        case "multiselect":
          return (
            <Form.Multiselect
              label={label}
              key={field.key}
              name={field.key}
              required={field.required}
              disabled={field.disabled ?? false}
              items={field.items ?? []}
              defaultValue={field.defaultValue}
              placeholder={field.placeholder}
            />
          );
        default:
          return <></>;
      }
    },
    [t, user]
  );

  return (
    <Stack gap="300" width="100">
      {formConfig.map(field => {
        if (field.type === "custom") {
          return (
            <React.Fragment key={field.key}>
              {field.showLabel && (
                <div className="user-form__label">
                  {field.key &&
                    t(`common:user.configured.fields.${field.key}.label`)}
                </div>
              )}
              <div className="user-form__inline" key={field.key}>
                {field.fields?.map(renderForm)}
              </div>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={field.key}>{renderForm(field)}</React.Fragment>
        );
      })}
    </Stack>
  );
};

export default UserFormContents;
