import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@atoms/Button";
import { IconSize } from "@atoms/Icon";

const CreateProjectActionButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/create-project");
  }, [navigate]);

  return (
    <Button
      label={t("common:ui.project.title_create")}
      iconName="add"
      iconSize={IconSize.S}
      onClick={handleClick}
    />
  );
};

export default CreateProjectActionButton;
