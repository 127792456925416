import React from "react";

import PropTypes from "prop-types";

import "./LogoAvatar.scss";

export const LogoAvatar = props => {
  const { image, shape } = props;

  return (
    <>
      {image && (
        <div className="oneteam-logo-avatar">
          {
            <img
              className={`oneteam-logo-avatar--image oneteam-logo-avatar--image__${shape}`}
              src={image}
              alt="logo"
            />
          }
        </div>
      )}
    </>
  );
};

LogoAvatar.defaultProps = {
  shape: "square"
};

LogoAvatar.propTypes = {
  image: PropTypes.string,
  shape: PropTypes.oneOf(["circle", "square"])
};

export default LogoAvatar;
