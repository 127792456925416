import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers.js";
import {
  ActionItemType,
  ActionItemTypeWorkflow,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import { Button } from "@atoms/Button/Button.tsx";
import { ButtonVariant } from "@atoms/Button/index.ts";

import PageHeader from "@templates/PageTemplate/PageHeader/PageHeader.tsx";

import { Box, Inline, Stack } from "@components/fermions/index.tsx";

import WorkflowCanvas from "../WorkflowCanvas/WorkflowCanvas.tsx";
import { useWorkflowCanvas } from "../WorkflowCanvas/WorkflowCanvasContext.tsx";
import { displayName } from "../actionItemType.tsx";
import WorkflowSidebar from "./WorkflowSidebar.tsx";

interface WorkflowBuilderProps {
  onWorkflowUpdate: (workflow: unknown) => void;
  onWorkflowCloseDialog: () => void;
  workflow: ActionItemTypeWorkflow;
  crudFormMode: CrudFormMode;
  actionItemType: ActionItemType;
}

const WorkflowBuilder = (props: WorkflowBuilderProps) => {
  const { t } = useTranslation();
  const {
    onWorkflowUpdate,
    onWorkflowCloseDialog,
    workflow,
    crudFormMode,
    actionItemType
  } = props;
  const context = useWorkflowCanvas();
  const {
    selectedLaneId,
    setSelectedLaneId,
    setSelectedNodeId,
    workflowConfig,
    setWorkflowConfig
  } = context;
  const [hasNoLocalChanges, setHasNoLocalChanges] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    setWorkflowConfig({
      ...workflow
    });
  }, [workflow, setWorkflowConfig]);

  useEffect(() => {
    if (!workflow || !workflowConfig) {
      setHasNoLocalChanges(false);
    }
    const workflowHasNoChanges = isEqual(workflow, workflowConfig);
    setHasNoLocalChanges(workflowHasNoChanges);
  }, [workflow, workflowConfig]);

  useEffect(() => {
    setIsSidebarOpen(!!selectedLaneId);
  }, [selectedLaneId]);

  const handleCloseSidebar = useCallback(() => {
    setSelectedLaneId?.();
    setSelectedNodeId?.();
    setIsSidebarOpen(false);
  }, [setSelectedLaneId, setSelectedNodeId]);

  const resetAll = useCallback(() => {
    setSelectedLaneId?.();
    setSelectedNodeId?.();
    setIsSidebarOpen(false);
  }, [setSelectedLaneId, setSelectedNodeId]);

  const handleCloseBuilder = useCallback(() => {
    resetAll();
    onWorkflowCloseDialog();
  }, [resetAll, onWorkflowCloseDialog]);

  const aitDisplayName = useMemo(
    () => displayName(actionItemType, t),
    [actionItemType, t]
  );

  const handleUpdate = useCallback(() => {
    resetAll();
    onWorkflowUpdate(workflowConfig);
  }, [resetAll, onWorkflowUpdate, workflowConfig]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });
  return (
    <div
      className={classNames([
        "dialog-page",
        isSidebarOpen ? "dialog-page--with-sidebar" : ""
      ])}
    >
      <div className="dialog-page__content">
        <div className="dialog-page__header">
          <PageHeader
            header={{
              title: t("admin:ui.requestTypes.workflow.configure.title", {
                name: aitDisplayName
              }),
              actions: (
                <Inline wrap gap="200" alignment="right">
                  {CrudFormMode.UPDATE === crudFormMode ? (
                    <>
                      {hasNoLocalChanges ? (
                        <Button
                          label={t("common:ui.forms.cancel.label")}
                          variant={ButtonVariant.TEXT}
                          onClick={handleCloseBuilder}
                        />
                      ) : (
                        <Button
                          label={t(
                            "admin:ui.requestTypes.workflow.discard.label"
                          )}
                          variant={ButtonVariant.TEXT}
                          onClick={handleCloseBuilder}
                        />
                      )}
                      <Button
                        label={t(
                          "admin:ui.requestTypes.workflow.updateWorkflow.label"
                        )}
                        onClick={handleUpdate}
                        disabled={hasNoLocalChanges}
                      />
                    </>
                  ) : (
                    <Button
                      label={t("admin:ui.requestTypes.workflow.close.label")}
                      onClick={handleCloseBuilder}
                    />
                  )}
                </Inline>
              )
            }}
          />
        </div>
        <div className="dialog-page__body">
          {workflowConfig && (
            <div>
              <WorkflowCanvas
                workflow={workflowConfig}
                crudFormMode={crudFormMode}
                actionItemType={actionItemType}
              />
            </div>
          )}
        </div>
      </div>
      <div className="dialog-page__sidebar">
        <Box padding="200" height="100">
          <Stack gap="100" height="100">
            <WorkflowSidebar
              onClose={handleCloseSidebar}
              crudFormMode={crudFormMode}
            />
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default WorkflowBuilder;
