import React, { useCallback, useMemo } from "react";

import "./AnalyticsDashboard.scss";

const AnalyticsDashboard = ({ data, component }) => {
  const memoizedData = useMemo(() => data, [data]);
  const { gridColumns, panels } = memoizedData;

  const getGrids = useCallback(
    () =>
      panels?.map((panel, index) => {
        const { gridArea, data } = panel;
        return (
          data && (
            <div
              style={{ gridArea }}
              key={index}
              className="analytics-dashboard__grid"
              role="grids"
            >
              {component?.(data)}
            </div>
          )
        );
      }),
    [panels, component]
  );

  return (
    <div
      style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
      className="analytics-dashboard"
    >
      {getGrids()}
    </div>
  );
};

export default React.memo(AnalyticsDashboard);
