import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { useUpsertProjectMilestonesMutation } from "@shared/hooks";

import MilestonesForm from "@components/organisms/MilestonesForm";

const Milestones = props => {
  const { i18n } = useTranslation();
  const [upsertProjectMilestones] = useUpsertProjectMilestonesMutation();
  const engagementTypeConfig = props.project?.configuration;

  const handleSubmit = updatedMilestones => {
    const oldMilestones = structuredClone(props.milestones);
    const updatedProject = {
      id: props.project?.id,
      status: props.project?.status
    };

    const formattedMilestones = updatedMilestones.map(milestone => {
      const oldMilestone = oldMilestones.find(m => m.id === milestone.id);
      return milestoneUtilities.formatMilestone({
        oldMilestone,
        milestone,
        contextKey: engagementTypeConfig?.i18nContextKey,
        i18n
      });
    });

    const relevantMilestones = milestoneUtilities.buildMilestoneDifferences(
      oldMilestones,
      formattedMilestones
    );

    upsertProjectMilestones({
      milestones: relevantMilestones,
      projectId: updatedProject.id
    });
    props.onSave();
  };

  return (
    <MilestonesForm
      milestones={props.milestones}
      projectId={props.project?.id}
      projectStatus={props.project?.status}
      handleCancel={props.onCancel}
      handleSubmit={handleSubmit}
      milestoneConfig={engagementTypeConfig?.milestones}
    />
  );
};

Milestones.propTypes = {
  milestones: PropTypes.array,
  project: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default Milestones;
