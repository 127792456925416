import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers";
import { smartFormHelper, smartFormResponseType } from "@app/helpers/smartForm";
import { useSmartFormResponseStore } from "@app/stores/useSmartFormStore.ts";

import { Icon, IconSize } from "@atoms/Icon";

import "./SmartFormTextArea.scss";

const SmartFormTextArea = props => {
  const {
    enableGlobalOpenState,
    value,
    onAnswerSupplied,
    questionId,
    disabled,
    responseType = smartFormResponseType.TEXT,
    maxLength,
    isLineBreaksAllowed = true,
    min,
    max
  } = props;
  const [isInvalidNumber, setIsInvalidNumber] = useState(false);
  const globalQuestionId = useSmartFormResponseStore(
    state => state.smartFormResponse.questionId
  );
  const globalResponse = useSmartFormResponseStore(
    state => state.smartFormResponse.response
  );
  const globalCursor = useSmartFormResponseStore(
    state => state.smartFormResponse.cursor
  );
  const setActiveQuestion = useSmartFormResponseStore(
    state => state.setActiveQuestion
  );
  const clearActiveQuestion = useSmartFormResponseStore(
    state => state.clearActiveQuestion
  );

  const formFieldName = `sm-q-${questionId}`;
  const { t } = useTranslation();
  const {
    getValues,
    setValue,
    register,
    getFieldState,
    resetField,
    formState,
    unregister,
    watch
  } = useFormContext();

  const getRegisterOptions = () => {
    const formatValue = v => {
      if (responseType === smartFormResponseType.TEXT) {
        if (!isLineBreaksAllowed) {
          return smartFormHelper.removeLineBreaks(v);
        }
      } else if (responseType === smartFormResponseType.NUMBER) {
        return smartFormHelper.getValidTypingSmartFormNumber(v);
      }

      return v;
    };

    const result = { value };
    result.onChange = e => {
      const formattedValue = formatValue(e.target.value);
      setValue(formFieldName, formattedValue);
    };

    return result;
  };

  const formFieldProperties = register(formFieldName, getRegisterOptions());
  const watchInput = watch(formFieldName);

  const hasChanges = useMemo(() => {
    return !disabled && getFieldState(formFieldName, formState)?.isDirty;
  }, [formFieldName, formState, getFieldState, disabled]);

  useEffect(
    () => () => {
      unregister(formFieldName);
    },
    [formFieldName, unregister]
  );

  useEffect(() => {
    if (enableGlobalOpenState && questionId === globalQuestionId) {
      setValue(formFieldName, globalResponse, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
      const inputFormRef = document.getElementById(`${formFieldName}_textarea`);
      inputFormRef?.focus();
      inputFormRef?.setSelectionRange?.(
        globalCursor.selectionStart,
        globalCursor.selectionEnd,
        globalCursor.selectionDirection
      );
    }
  }, [
    enableGlobalOpenState,
    formFieldName,
    questionId,
    setValue,
    globalQuestionId,
    globalResponse,
    globalCursor.selectionStart,
    globalCursor.selectionEnd,
    globalCursor.selectionDirection
  ]);

  useEffect(() => {
    const inputFormRef = document.getElementById(`${formFieldName}_textarea`);
    if (hasChanges && inputFormRef) {
      const ctl = inputFormRef;
      setActiveQuestion(questionId, watchInput, {
        selectionStart: ctl.selectionStart ?? -1,
        selectionEnd: ctl.selectionEnd ?? -1,
        selectionDirection: ctl.selectionDirection ?? "none"
      });
    }
  }, [formFieldName, hasChanges, questionId, setActiveQuestion, watchInput]);

  // To stop the user being able to scroll when focus is on text area
  useEffect(() => {
    const bodyContent = document.getElementById("page-template__body__content");
    if (bodyContent) {
      bodyContent.style.overflowY = hasChanges ? "hidden" : "";
    }
  }, [hasChanges]);

  // When component unmounts, ensure page template body content is scrollable
  useEffect(() => {
    return () => {
      const bodyContent = document.getElementById(
        "page-template__body__content"
      );
      if (bodyContent) {
        bodyContent.style.overflowY = "";
      }
    };
  }, []);

  const handleCancel = useCallback(() => {
    setIsInvalidNumber(false);
    resetField(formFieldName, {
      defaultValue: value
    });
    clearActiveQuestion();
  }, [clearActiveQuestion, formFieldName, resetField, value]);

  const handleSave = useCallback(() => {
    const valueToSave = getValues(formFieldName);
    if (
      responseType === smartFormResponseType.NUMBER &&
      (!smartFormHelper.isValidSmartFormNumber(valueToSave) ||
        !smartFormHelper.isNumberInRange({
          value: valueToSave,
          min,
          max
        }))
    ) {
      setIsInvalidNumber(true);
      return;
    }
    resetField(formFieldName, {
      defaultValue: valueToSave
    });

    onAnswerSupplied(questionId, valueToSave);
    setIsInvalidNumber(false);
    clearActiveQuestion();
  }, [
    getValues,
    formFieldName,
    responseType,
    resetField,
    onAnswerSupplied,
    questionId,
    min,
    max,
    clearActiveQuestion
  ]);

  const placeholder = useMemo(() => {
    if (disabled) {
      return "";
    }
    if (responseType === smartFormResponseType.TEXT) {
      return t("requests:requests.ui.smartForm.responseType.text.placeholder");
    } else if (responseType === smartFormResponseType.NUMBER) {
      let numberPlaceholder = t(
        "requests:requests.ui.smartForm.responseType.number.placeholder"
      );
      if (min || max) {
        const minText = min
          ? `${t(
              "requests:requests.ui.smartForm.responseType.number.placeholderWithMin",
              { min }
            )}`
          : "";
        const maxText = max
          ? `${t(
              "requests:requests.ui.smartForm.responseType.number.placeholderWithMax",
              { max }
            )}`
          : "";
        const conjunction = min && max ? ", " : "";
        numberPlaceholder = `${minText}${conjunction}${maxText}`;
      }
      return numberPlaceholder;
    }
  }, [disabled, t, responseType, min, max]);

  const getTextAreaClassNames = () => {
    return classNames([
      "smart-form-textarea",
      hasChanges ? "smart-form-textarea--has-changes unsaved-question" : null,
      disabled ? "smart-form-textarea--disabled" : null,
      isInvalidNumber && hasChanges ? "smart-form-textarea--invalid" : null
    ]);
  };

  const getError = () => {
    let errorMessage = "";

    if (isInvalidNumber) {
      if (min !== undefined && max !== undefined) {
        errorMessage = t(
          "requests:requests.ui.smartForm.modal.errorMessage.valueOutOfRange",
          {
            min,
            max
          }
        );
      } else if (min !== undefined) {
        errorMessage = t(
          "requests:requests.ui.smartForm.modal.errorMessage.valueTooLow",
          {
            min
          }
        );
      } else if (max !== undefined) {
        errorMessage = t(
          "requests:requests.ui.smartForm.modal.errorMessage.valueTooHigh",
          {
            max
          }
        );
      }

      return (
        <div className="smart-form-textarea--error-message">
          <i className="smart-form-textarea--error-message-icon material-icons">
            error
          </i>
          {errorMessage}
        </div>
      );
    }

    return <></>;
  };

  return disabled ? (
    <div className={"smart-form-textarea--disabled"}>{value}</div>
  ) : (
    <>
      <div
        className={`${
          hasChanges ? " smart-form-textarea--focus-container" : ""
        }`}
      ></div>
      <div className="smart-form-textarea--wrapper">
        <div className={getTextAreaClassNames()}>
          <textarea
            id={`${formFieldName}_textarea`}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={maxLength}
            disabled={disabled}
            className={
              responseType === smartFormResponseType.NUMBER
                ? "smart-form-textarea__number"
                : "smart-form-textarea__text"
            }
            {...formFieldProperties}
          ></textarea>
          {hasChanges ? (
            <div
              className="smart-form-textarea__actions"
              data-testid="testSmartFormTextareaActions"
            >
              <div
                className="smart-form-textarea__actions__icon smart-form-textarea__actions__icon--save"
                data-testid="testSmartFormTextareaSave"
              >
                <Icon name="check" size={IconSize.S} onClick={handleSave} />
              </div>
              <div
                className="smart-form-textarea__actions__icon smart-form-textarea__actions__icon--save"
                data-testid="testSmartFormTextareaCancel"
              >
                <Icon name="close" size={IconSize.S} onClick={handleCancel} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {getError()}
      </div>
    </>
  );
};

SmartFormTextArea.propTypes = {
  value: PropTypes.string,
  questionId: PropTypes.number,
  onAnswerSupplied: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  responseType: PropTypes.oneOf([
    "boolean",
    "text",
    "singleChoice",
    "date",
    "number",
    "document",
    "websheet"
  ]),
  maxLength: PropTypes.number,
  isLineBreaksAllowed: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  enableGlobalOpenState: PropTypes.bool
};

export default SmartFormTextArea;
