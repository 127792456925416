import { TFunction } from "i18next";

import { ActionItemType } from "@app/types/ActionItemType.ts";

import { SortingFilter } from "@pages/ManageRequestTypes/ManageRequestTypes.tsx";
import { displayName } from "@pages/ViewActionItemType/actionItemType.tsx";

export function stringInsensitiveLocaleCompare(
  a?: string,
  b?: string,
  sortOrder: string = "asc"
) {
  const lowerA = a?.toLocaleLowerCase();
  const lowerB = b?.toLocaleLowerCase();
  return sortOrder === "asc"
    ? lowerA?.localeCompare(lowerB)
    : lowerB?.localeCompare(lowerA);
}

export function sortByOrderAndType(
  { colName, sortOrder }: SortingFilter,
  a: ActionItemType,
  b: ActionItemType,
  t: TFunction<"translation">
) {
  const aVal = a[colName];
  const bVal = b[colName];
  if (colName === "year") {
    if (aVal === null && bVal === null) {
      return 0;
    }
    if (aVal === null) {
      return 1;
    }
    if (bVal === null) {
      return -1;
    }
    return sortOrder === "asc"
      ? Number(aVal) - Number(bVal)
      : Number(bVal) - Number(aVal);
  }
  if (colName === "name") {
    const customAVal = displayName(a, t);
    const customBVal = displayName(b, t);
    return stringInsensitiveLocaleCompare(customAVal, customBVal, sortOrder);
  }
  return stringInsensitiveLocaleCompare(aVal, bVal, sortOrder);
}
