import React from "react";

import PropTypes from "prop-types";

import "./Loading.scss";

const Loading = props => {
  const { message = "", type, minHeight } = props;
  const inlineStyle = {};
  if (minHeight) {
    inlineStyle.style = {
      minHeight: minHeight
    };
  }
  return (
    <div className="loading" {...inlineStyle}>
      <div className="loading__container">
        <div className="loader" />
      </div>
      {message && (
        <h2 className={`loading__text loading__text--${type}`}>{message}</h2>
      )}
    </div>
  );
};

Loading.defaultProps = {
  type: "primary"
};

Loading.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary"]),
  minHeight: PropTypes.string
};

export default Loading;
