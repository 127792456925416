import { AccessLevel, ResourceName, myPermissionGet } from "@app/types";

type User = { hostId: number };

function doesBelongToHost(user: User) {
  return !!user.hostId;
}

function doesBelongToClient(user: User) {
  return !user.hostId;
}

type Group = "all" | "client" | "host";
type UserGroup = Group | Group[];
function doesBelongToUserGroup(userGroup: UserGroup, user: User) {
  const isGroupMember = (group: Group) => {
    switch (group?.toLowerCase()) {
      case "all":
        return true;
      case "client":
        return doesBelongToClient(user);
      case "host":
        return doesBelongToHost(user);
      default:
        return false;
    }
  };
  if (Array.isArray(userGroup)) {
    return userGroup.some(g => isGroupMember(g));
  } else {
    return isGroupMember(userGroup);
  }
}

function checkAccess(
  permissions: myPermissionGet,
  resource: ResourceName,
  accessLevel: AccessLevel
) {
  if (!resource || !accessLevel) {
    return true;
  }
  return !!permissions?.[resource]?.includes(accessLevel);
}

export const loggedUser = {
  checkAccess,
  doesBelongToHost,
  doesBelongToClient,
  doesBelongToUserGroup
};
