import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Inline, Stack } from "@fermions";

import { InputContainer } from "@templates/InputContainer";

import Form from "@components/atoms/Form";
import BoxTemplate from "@components/templates/BoxTemplate";

import { ClientLogo } from "./ClientLogo/ClientLogo";

interface ClientFormProps {
  client?: unknown;
  setClientLogoModal: (modal: unknown) => void;
  logo?: unknown;
  setLogo: (logo: unknown) => void;
}

export const ClientForm = ({
  client,
  setClientLogoModal,
  logo,
  setLogo
}: ClientFormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (client?.logo) {
      setLogo(client?.logo);
    }
  }, [client?.logo, setLogo]);

  return (
    <BoxTemplate>
      <Inline gap="500">
        <ClientLogo
          logo={logo}
          setClientLogoModal={setClientLogoModal}
          setLogo={setLogo}
        />
        <Stack gap="300" width="100">
          <Form.TextField
            name="name"
            label={t("common:ui.client.field.name")}
            maxLength={50}
            required
            defaultValue={client?.name}
          />
          <InputContainer>
            <Form.TextField
              name="acn"
              label={t("common:ui.client.field.acn")}
              maxLength={11}
              defaultValue={client?.acn}
            />
            <Form.TextField
              name="abn"
              label={t("common:ui.client.field.abn")}
              maxLength={14}
              defaultValue={client?.abn}
            />
          </InputContainer>
        </Stack>
      </Inline>
    </BoxTemplate>
  );
};
