import React from "react";

import PropTypes from "prop-types";

import FileAttachmentItem from "../FileAttachmentItem/FileAttachmentItem";
import "./UploadFileAttachmentList.scss";

function UploadFileAttachmentList(props) {
  return (
    <>
      {!props.isHide && (
        <div
          className={`upload-file-attachment-list upload-file-attachment-list--columns-${props.columnMode}`}
        >
          {props.attachments &&
            Object.keys(props.attachments).length > 0 &&
            Object.keys(props.attachments)
              .filter(filename => !props.attachments[filename].isDeleted)
              .map((filename, index) => {
                const attachment = props.attachments[filename];
                return (
                  <div key={index}>
                    <FileAttachmentItem
                      file={attachment}
                      onDelete={() => {
                        if (props.onDelete) {
                          props.onDelete(filename, attachment);
                        }
                      }}
                      disabled={props.disabled}
                    />
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
}

UploadFileAttachmentList.defaultProps = {
  isHide: false,
  disabled: false,
  columnMode: "single"
};

UploadFileAttachmentList.propTypes = {
  attachments: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      filePathId: PropTypes.number,
      projectId: PropTypes.number,
      size: PropTypes.number, // Bytes
      status: PropTypes.string,
      uploadProgress: PropTypes.number //[0,100]
    })
  ).isRequired,
  onDelete: PropTypes.func,
  isHide: PropTypes.bool,
  disabled: PropTypes.bool,
  columnMode: PropTypes.oneOf(["single", "flex"])
};

export default UploadFileAttachmentList;
