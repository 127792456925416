import { manageProjectOCRConstants } from "../constants";

const initialState = {
  loading: false,
  ocrRequests: [],
  createdOCR: false,
  updatedOCR: false,
  ocrRequestImage: null,
  ocrRequestWithExtractedData: null,
  error: ""
};

export function manageProjectOCRs(state = initialState, action = {}) {
  switch (action.type) {
    case manageProjectOCRConstants.GET_OCR_REQUEST_WITH_EXTRACTED_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectOCRConstants.GET_OCR_REQUEST_WITH_EXTRACTED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrRequestWithExtractedData: action.ocrRequestWithExtractedData,
        error: ""
      };
    case manageProjectOCRConstants.GET_OCR_REQUEST_WITH_EXTRACTED_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        ocrRequestWithExtractedData: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectOCRConstants.GET_PROJECT_OCRS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectOCRConstants.GET_PROJECT_OCRS_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrRequests: action.ocrs,
        error: ""
      };
    case manageProjectOCRConstants.GET_PROJECT_OCRS_FAILURE:
      return {
        ...state,
        loading: false,
        ocrRequests: [],
        error: action.error.key ?? action.error.message
      };
    case manageProjectOCRConstants.GET_PROJECT_OCR_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectOCRConstants.GET_PROJECT_OCR_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrRequestImage: action.image,
        error: ""
      };
    case manageProjectOCRConstants.GET_PROJECT_OCR_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        ocrRequestImage: null,
        error: action.error.key ?? action.error.message
      };
    case manageProjectOCRConstants.CREATE_OCR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectOCRConstants.CREATE_OCR_SUCCESS:
      return {
        ...state,
        loading: false,
        createdOCR: action.createdOCR,
        error: ""
      };
    case manageProjectOCRConstants.CREATE_OCR_FAILURE:
      return {
        ...state,
        loading: false,
        createdOCR: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectOCRConstants.UPDATE_OCR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProjectOCRConstants.UPDATE_OCR_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedOCR: action.updatedOCR,
        error: ""
      };
    case manageProjectOCRConstants.UPDATE_OCR_FAILURE:
      return {
        ...state,
        loading: false,
        updatedOCR: false,
        error: action.error.key ?? action.error.message
      };
    case manageProjectOCRConstants.CLEAR_CREATED_OCR:
      return {
        ...state,
        createdOCR: false
      };
    case manageProjectOCRConstants.CLEAR_UPDATED_OCR:
      return {
        ...state,
        updatedOCR: false
      };
    case manageProjectOCRConstants.CLEAR_OCR_REQUEST_IMAGE:
      return {
        ...state,
        ocrRequestImage: null
      };
    case manageProjectOCRConstants.CLEAR_OCR_REQUEST_WITH_EXTRACTED_DATA:
      return {
        ...state,
        ocrRequestWithExtractedData: null
      };
    case manageProjectOCRConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageProjectOCRConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
