import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isEmpty } from "lodash";

import { systemConstants } from "../constants";

export const interactiveReportService = createApi({
  reducerPath: "interactiveReportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Menus", "Page", "Comments", "CommentsFilters", "JsonGet"],
  endpoints: builder => ({
    getMenus: builder.query({
      query: ({ projectId }) =>
        `/api/projects/${projectId}/interactiveReport/menus`,
      providesTags: ["Menus"]
    }),
    getPage: builder.query({
      query: ({ projectId, pageId, revision }) => {
        const revisionPath = revision ? `/revision/${revision}` : "";
        return `/api/projects/${projectId}/interactiveReport/pages/${pageId}${revisionPath}`;
      },
      providesTags: ["Page"]
    }),
    getComments: builder.query({
      query: ({ projectId, pageId, revision }) => {
        const revisionPath = revision ? `/revision/${revision}` : "";
        return `/api/projects/${projectId}/queries/pages/${pageId}${revisionPath}`;
      },
      providesTags: ["Comments"]
    }),
    getCommentsFilters: builder.query({
      query: ({ projectId }) =>
        `/api/projects/${projectId}/interactiveReport/commentFilters`,
      providesTags: ["CommentsFilters"]
    }),
    getReportJSONFile: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}/interactiveReport`,
      providesTags: ["JsonGet"],
      transformResponse: res =>
        !isEmpty(res) ? JSON.stringify(res, null, 2) : null
    }),
    saveReportJSONFile: builder.mutation({
      query: ({ projectId, jsonContent }) => ({
        url: `/api/projects/${projectId}/interactiveReport`,
        method: "POST",
        body: jsonContent
      }),
      invalidatesTags: ["JsonGet"]
    }),

    onGenerateReport: builder.mutation({
      query: ({ projectId, prompt, jsonContent }) => ({
        url: `/api/projects/${projectId}/interactiveReport/ai-reporting`,
        method: "POST",
        body: { prompt, jsonContent }
      }),
      invalidatesTags: ["JsonGet"],
      transformResponse: res =>
        res?.report?.length ? JSON.stringify(res, null, 2) : ""
    })
  })
});

export const {
  useGetMenusQuery,
  useLazyGetMenusQuery,
  useLazyGetPageQuery,
  useLazyGetCommentsQuery,
  useLazyGetCommentsFiltersQuery,
  useGetReportJSONFileQuery,
  useSaveReportJSONFileMutation,
  useOnGenerateReportMutation,
  util
} = interactiveReportService;
