import { AccessLevel, ResourceName, RoleType } from "@app/types";

import { useAuthUser } from ".";

function useAccessControl() {
  const { user } = useAuthUser();

  const filterByPermissionFn = (item: {
    permission?: {
      resource: ResourceName;
      accessLevel: AccessLevel;
      type: RoleType;
    };
    hostOnly?: boolean;
    clientOnly?: boolean;
  }) => {
    if (
      (item.hostOnly && !user.isHostUser) ||
      (item.clientOnly && user.isHostUser)
    ) {
      return false;
    }
    const { resource, accessLevel, type } = item.permission ?? {};
    const userType = user.xRole?.type;
    if (type && userType !== type) {
      return false;
    }

    return user.checkAccess(resource, accessLevel);
  };

  return {
    filterByPermissionFn
  };
}

export default useAccessControl;
