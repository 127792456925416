import * as yup from "yup";

export function validateForm({ field, schema, i18nText }) {
  let base;
  switch (field.type) {
    case "number":
    case "text":
      base = yup.string();
      break;
    case "dropdown":
      base = yup.object();
      break;
  }
  if (!base) {
    return;
  }
  if (field.key === "email") {
    base = base.email(
      i18nText(`common:user.configured.fields.${field.key}.invalidEmail`)
    );
  }
  if (field.required) {
    schema[field.key] = base.required(
      i18nText(`common:user.configured.fields.${field.key}.errorMessage`)
    );
  } else {
    schema[field.key] = base;
  }

  if (field.type === "number") {
    schema[field.key] = schema[field.key].matches(
      /^\d*$/,
      i18nText(`common:user.configured.fields.${field.key}.errorMessage.number`)
    );
  }
}
