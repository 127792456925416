import { useEffect, useState } from "react";

import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";

import { toggleChartHidden } from "@shared/features/interactiveReport/interactiveReportSlice";

export const useInteractiveReportCharts = () => {
  const [hiddenCharts, setHiddenCharts] = useState({});
  const dispatch = useDispatch();
  const interactiveReport = useSelector(state => state.interactiveReport);

  useEffect(() => {
    setHiddenCharts(interactiveReport.hiddenCharts);
  }, [interactiveReport.hiddenCharts]);

  const toggleChart = (pageId, chartId) => {
    dispatch(toggleChartHidden({ pageId, chartId }));
  };

  const downloadChart = ({ fileName, elementId }) => {
    const chart = document.getElementById(elementId);
    html2canvas(chart, {
      width: chart.offsetWidth,
      height: chart.offsetHeight
    }).then(canvas => {
      const png = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = `${fileName}.png`;
      link.href = png;
      link.click();
    });
  };

  return {
    hiddenCharts,
    toggleChart,
    downloadChart
  };
};
