import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";

import { AccessLevel, Permission, Resource, RoleMode } from "@app/types";

import ResourceRowTemplate from "./ResourceRowTemplate";

type Props = {
  resource: Resource;
  hostMode: boolean;
  accessLevels: AccessLevel[];
  className: string;
  cellWidth: string;
  defaultChecked?: number[];
  disabled: boolean;
  mode: RoleMode;
};

function ResourceRow(props: Readonly<Props>) {
  const { setValue } = useFormContext();
  const {
    resource,
    hostMode,
    accessLevels,
    className,
    cellWidth,
    defaultChecked,
    disabled,
    mode
  } = props;

  const [checkedPermissions, setCheckedPermissions] = useState<Permission[]>(
    []
  );

  useEffect(() => {
    setValue(
      `resources.${resource.name}`,
      checkedPermissions.map(p => ({
        id: p.id
      }))
    );
  }, [checkedPermissions, resource.name, setValue]);

  useEffect(() => {
    if (defaultChecked) {
      setCheckedPermissions(
        resource.permissions.filter(p => defaultChecked.includes(p.id))
      );
    }
  }, [defaultChecked, resource.permissions]);

  useEffect(() => {
    if (!hostMode) {
      //Remove checked permissions which are disabled
      setCheckedPermissions(prev => prev.filter(p => !p.hostOnly));
    }
  }, [hostMode]);

  const permissionLevelMap = useMemo(
    (): { [key in AccessLevel]?: Permission } =>
      resource.permissions.reduce(
        (acc, p) => ({ ...acc, [p.accessLevel]: p }),
        {}
      ),
    [resource.permissions]
  );

  const handleChange = (newVal: boolean, name: string) => {
    const accessLevelIndex = accessLevels.indexOf(name as AccessLevel);
    if (accessLevelIndex === -1) {
      return;
    }
    const endValue = accessLevelIndex + (newVal ? 1 : 0);

    const resourceLevels = Object.keys(permissionLevelMap).sort(
      (a, b) =>
        accessLevels.findIndex(level => level === a) -
        accessLevels.findIndex(level => level === b)
    ) as AccessLevel[];

    const checkedLevels = resourceLevels.slice(0, endValue);
    setCheckedPermissions(
      checkedLevels.map(level => permissionLevelMap[level]!)
    );
  };

  const getPermissionData = useCallback(
    (accessLevel: AccessLevel) => {
      const permission = resource.permissions.find(
        p => p.accessLevel === accessLevel
      );
      const key = `${permission?.id}${accessLevel}`;
      const isChecked =
        permission?.alwaysAllowed ||
        checkedPermissions.some(p => p.accessLevel === accessLevel);
      const isDisabled = permission?.alwaysAllowed;
      const isVisible = !!permission && (hostMode || !permission.hostOnly);
      return {
        key,
        isDisabled,
        isChecked,
        isVisible
      };
    },
    [checkedPermissions, hostMode, resource.permissions]
  );

  return (
    <>
      {accessLevels.map(accessLevel => {
        const { key, isDisabled, isChecked, isVisible } =
          getPermissionData(accessLevel);
        return (
          <ResourceRowTemplate
            visible={isVisible}
            key={key}
            disabled={isDisabled || disabled}
            value={isChecked}
            onChange={handleChange}
            width={cellWidth}
            className={className}
            name={accessLevel}
            mode={mode}
          />
        );
      })}
    </>
  );
}

ResourceRow.defaultProps = {
  disabled: false
};

export default ResourceRow;
