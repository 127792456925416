import React from "react";

import "./KanbanDropzone.scss";

interface DropzoneRegions {
  primaryLabel: string;
  secondaryLabel?: string;
  styles?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function KanbanDropzone(
  DropzoneRegions: Readonly<DropzoneRegions>
) {
  return (
    <div
      className="kanban-dropzone"
      role="presentation"
      style={DropzoneRegions.styles}
      onMouseEnter={DropzoneRegions.onMouseEnter}
      onMouseLeave={DropzoneRegions.onMouseLeave}
    >
      {!DropzoneRegions.secondaryLabel && DropzoneRegions.primaryLabel}
      {DropzoneRegions.secondaryLabel && (
        <>
          <div className="kanban-dropzone__primary">
            {DropzoneRegions.primaryLabel}
          </div>
          <div className="kanban-dropzone__secondary">
            {DropzoneRegions.secondaryLabel}
          </div>
        </>
      )}
    </div>
  );
}
