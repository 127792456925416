import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  resetColumnFilters,
  resetPageAndReviewFilters,
  resetPageFilter,
  saveDrilldownFilter,
  savePageFilter,
  toggleColumnFilter,
  toggleReviewFilter
} from "@shared/features/interactiveReport/interactiveReportSlice";

export function useInteractiveReportFilters() {
  const dispatch = useDispatch();
  const interactiveReport = useSelector(state => state.interactiveReport);
  const [filterKey, setFilterKey] = useState(null);
  const [activeFilter, setActiveFilter] = useState({});
  const [activeReview, setActiveReview] = useState([]);
  const [drilldownFilterKey, setDrilldownFilterKey] = useState({});
  const [activeDrilldownFilters, setActiveDrilldownFilters] = useState();
  const [activeColumns, setActiveColumns] = useState();

  const resetFilter = useCallback(
    ({ projectId, pageId }) => {
      dispatch(resetPageFilter({ projectId, pageId }));
    },
    [dispatch]
  );

  const resetAllFilters = useCallback(() => {
    dispatch(resetPageAndReviewFilters());
    dispatch(resetColumnFilters());
  }, [dispatch]);

  const setFilter = useCallback(
    ({ projectId, pageId, filter }) => {
      dispatch(savePageFilter({ projectId, pageId, filter }));
    },
    [dispatch]
  );

  const setDrilldownFilter = useCallback(
    ({ projectId, pageId, selectedRowId, filter }) => {
      dispatch(
        saveDrilldownFilter({ projectId, pageId, selectedRowId, filter })
      );
    },
    [dispatch]
  );

  const getFilter = useCallback(
    ({ projectId, pageId }) => {
      return interactiveReport.pageFilters[`${projectId}-${pageId}`] ?? {};
    },
    [interactiveReport.pageFilters]
  );

  const getDrilldownFilter = useCallback(
    ({ projectId, pageId, selectedRowId }) => {
      const drilldownFilters =
        interactiveReport.drilldownFilters[
          `${projectId}-${pageId}-${selectedRowId}`
        ] ?? null;
      return drilldownFilters;
    },
    [interactiveReport.drilldownFilters]
  );

  useEffect(() => {
    if (filterKey) {
      const key = `${filterKey?.projectId}-${filterKey?.pageId}`;
      setActiveReview(interactiveReport.reviewFilters[key] ?? []);
      setActiveColumns(interactiveReport.columnFilters[key] ?? []);
    }
  }, [
    interactiveReport.reviewFilters,
    filterKey,
    interactiveReport.columnFilters
  ]);

  const toggleReview = useCallback(
    rowDetails => {
      dispatch(toggleReviewFilter(rowDetails));
    },
    [dispatch]
  );

  const toggleColumns = useCallback(
    columnDetails => {
      dispatch(toggleColumnFilter(columnDetails));
    },
    [dispatch]
  );

  const setActiveFilterKey = useCallback(({ projectId, pageId }) => {
    if (pageId === undefined || projectId === undefined) {
      setFilterKey(null);
    }
    setFilterKey({ projectId, pageId });
  }, []);

  const setActiveDrilldownFilterKey = useCallback(
    ({ projectId, pageId, selectedRowId }) => {
      if (
        pageId === undefined ||
        projectId === undefined ||
        selectedRowId === undefined
      ) {
        setDrilldownFilterKey(null);
      } else {
        setDrilldownFilterKey({ projectId, pageId, selectedRowId });
      }
    },
    []
  );

  useEffect(() => {
    if (filterKey) {
      setActiveFilter(getFilter(filterKey));
    } else {
      setActiveFilter({});
    }
  }, [filterKey, getFilter]);

  useEffect(() => {
    if (drilldownFilterKey) {
      setActiveDrilldownFilters(getDrilldownFilter(drilldownFilterKey));
    } else {
      setActiveDrilldownFilters(null);
    }
  }, [drilldownFilterKey, getDrilldownFilter]);

  return {
    resetFilter,
    resetAllFilters,
    getFilter,
    setFilter,
    activeFilter,
    setActiveFilterKey,
    activeReview,
    toggleReview,
    activeDrilldownFilters,
    setActiveDrilldownFilterKey,
    setDrilldownFilter,
    activeColumns,
    toggleColumns
  };
}
