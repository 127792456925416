import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import axios from "axios";

import { systemConstants } from "@shared/constants/systemConstants";
import { handleResponse } from "@shared/services/handleResponse";

import { authHeader } from "../helpers";
import usePubSub from "./usePubSub";

const baseURL = systemConstants.serverURL;
const attachedFileStates = systemConstants.addFiles.attachedFile.state;

export function useNewsMastheadImage(engagementTypeId) {
  const [filesStates, setFilesStates] = useState({}); // id:{}
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState();
  const [newsMasthead, setNewsMasthead] = useState({});
  const refreshNewsMasthead = usePubSub();
  const didMount = useRef(false);

  useEffect(() => {
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      refreshNewsMasthead.subscribe(
        systemConstants.engagementType.events.refreshNewsItem
      );
      didMount.current = true;
    }
  }, [refreshNewsMasthead]);

  useEffect(() => {
    if (
      refreshNewsMasthead.value?.type === "news-masthead" &&
      refreshNewsMasthead.value.engagementTypeId === engagementTypeId
    ) {
      if (!didMount.current) {
        return;
      }
      setNewsMasthead(refreshNewsMasthead.value.masthead);
    }
  }, [refreshNewsMasthead.value, engagementTypeId]);

  const uploadsFailed = useMemo(
    () =>
      Object.values(filesStates).length > 0 &&
      Object.values(filesStates).some(
        f => f.state === attachedFileStates.uploadFailed
      ),
    [filesStates]
  );

  const uploadsCompleted = useMemo(
    () =>
      Object.values(filesStates).length > 0 &&
      Object.values(filesStates).every(
        f => f.state === attachedFileStates.uploaded
      ),
    [filesStates]
  );

  useEffect(() => {
    if (uploadsFailed || uploadsCompleted) {
      setIsUploading(false);
    }
  }, [uploadsFailed, uploadsCompleted]);

  const uploadError = useMemo(
    () => Object.values(filesStates).find(f => f.uploadError),
    [filesStates]
  );

  const getUploadFileState = useCallback(
    filename => {
      const fileState = filesStates[filename];
      if (!fileState) {
        return null;
      }

      return {
        name: filename,
        state: fileState.state,
        filePathId: fileState.filePathId,
        uploadProgress: fileState.uploadProgress,
        uploadError: fileState.uploadError
      };
    },
    [filesStates]
  );

  const clearUploadsCompleted = useCallback(() => {
    setFilesStates({});
    setIsUploading(false);
  }, []);

  // @param files - structure is based on useFileUploadManagement
  const uploadImageFiles = useCallback(
    files => {
      if (isUploading) {
        return;
      }
      if (!files || Object.keys(files)?.length === 0) {
        return;
      }
      if (!engagementTypeId) {
        return;
      }
      clearUploadsCompleted();
      setIsUploading(true);
      Object.values(files).forEach(f => {
        uploadFile({ file: f, engagementTypeId });
      });
    },
    [clearUploadsCompleted, isUploading, engagementTypeId]
  );

  const uploadFile = ({ file, engagementTypeId: id }) => {
    setFilesStates(prevState => ({
      ...prevState,
      [file.name]: {
        uploadProgress: 0,
        state: attachedFileStates.uploading
      }
    }));

    const formData = new FormData();
    formData.append("engagementTypeId", id);
    formData.append("file", file);

    const source = axios.CancelToken.source();
    return axios
      .post(
        `${baseURL}/api/engagementTypes/${id}/news/masthead/image`,
        formData,
        {
          headers: authHeader({ documentUpload: true }),
          onUploadProgress: progress => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);

            if (!didMount.current) {
              return;
            }

            setFilesStates(prevState => ({
              ...prevState,
              [file.name]: {
                ...prevState?.[file.name],
                uploadProgress: percentageProgress,
                state: attachedFileStates.uploading,
                reqSource: source
              }
            }));
          },
          cancelToken: source.token
        }
      )
      .then(({ data }) => {
        if (!didMount.current) {
          return;
        }
        setFilesStates(prevState => ({
          ...prevState,
          [file.name]: {
            ...prevState?.[file.name],
            uploadProgress: 100,
            state: attachedFileStates.uploaded
          }
        }));
        setNewsMasthead(data);
      })
      .catch(error => {
        if (!didMount.current) {
          return;
        }
        let errorMessage = error.message;
        setFilesStates(prevState => ({
          ...prevState,
          [file.name]: {
            ...prevState?.[file.name],
            state: attachedFileStates.uploadFailed,
            uploadError: errorMessage
          }
        }));
      });
  };

  const handleUpdatedMastheadData = data => {
    if (didMount.current) {
      setNewsMasthead(data);
    }
  };

  const deleteNewsMastheadImage = useCallback(() => {
    setIsDeleting(true);
    setDeleteError(null);
    const requestOptions = {
      method: "DELETE",
      headers: authHeader()
    };
    return fetch(
      `${baseURL}/api/engagementTypes/${engagementTypeId}/news/masthead/image`,
      requestOptions
    )
      .then(handleResponse)
      .then(handleUpdatedMastheadData)
      .catch(e => {
        if (didMount.current) {
          setDeleteError(e.message);
        }
      })
      .finally(() => {
        if (didMount.current) {
          setIsDeleting(false);
        }
      });
  }, [engagementTypeId]);

  const fetchNewsMasthead = useCallback(() => {
    if (!engagementTypeId) {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: authHeader()
    };
    return fetch(
      `${baseURL}/api/engagementTypes/${engagementTypeId}/news/masthead`,
      requestOptions
    )
      .then(handleResponse)
      .then(handleUpdatedMastheadData)
      .catch(() => {
        if (didMount.current) {
          setNewsMasthead({});
        }
      });
  }, [engagementTypeId]);

  return {
    uploadControlHooks: {
      uploadImageFiles,
      isUploading,
      uploadsFailed,
      uploadsCompleted,
      uploadError,
      getUploadFileState,
      clearUploadsCompleted
    },
    deleteNewsMastheadImage,
    isDeleting,
    deleteError,
    fetchNewsMasthead,
    newsMasthead,
    forTest: {
      setFilesStates,
      didMount
    }
  };
}
