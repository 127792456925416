export const manageProjectOCRConstants = {
  GET_PROJECT_OCRS_REQUEST: "GET_PROJECT_OCRS_REQUEST",
  GET_PROJECT_OCRS_SUCCESS: "GET_PROJECT_OCRS_SUCCESS",
  GET_PROJECT_OCRS_FAILURE: "GET_PROJECT_OCRS_FAILURE",

  GET_OCR_REQUEST_WITH_EXTRACTED_DATA_REQUEST:
    "GET_OCR_REQUEST_WITH_EXTRACTED_DATA_REQUEST",
  GET_OCR_REQUEST_WITH_EXTRACTED_DATA_SUCCESS:
    "GET_OCR_REQUEST_WITH_EXTRACTED_DATA_SUCCESS",
  GET_OCR_REQUEST_WITH_EXTRACTED_DATA_FAILURE:
    "GET_OCR_REQUEST_WITH_EXTRACTED_DATA_FAILURE",

  GET_PROJECT_OCR_IMAGE_REQUEST: "GET_PROJECT_OCR_IMAGE_REQUEST",
  GET_PROJECT_OCR_IMAGE_SUCCESS: "GET_PROJECT_OCR_IMAGE_SUCCESS",
  GET_PROJECT_OCR_IMAGE_FAILURE: "GET_PROJECT_OCR_IMAGE_FAILURE",

  UPDATE_OCR_REQUEST: "UPDATE_OCR_REQUEST",
  UPDATE_OCR_SUCCESS: "UPDATE_OCR_SUCCESS",
  UPDATE_OCR_FAILURE: "UPDATE_OCR_FAILURE",

  CREATE_OCR_REQUEST: "CREATE_OCR_REQUEST",
  CREATE_OCR_SUCCESS: "CREATE_OCR_SUCCESS",
  CREATE_OCR_FAILURE: "CREATE_OCR_FAILURE",

  CLEAR_CREATED_OCR: "CLEAR_CREATED_OCR",
  CLEAR_OCR_REQUEST_WITH_EXTRACTED_DATA:
    "CLEAR_OCR_REQUEST_WITH_EXTRACTED_DATA",
  CLEAR_OCR_REQUEST_IMAGE: "CLEAR_OCR_REQUEST_IMAGE",
  CLEAR_UPDATED_OCR: "CLEAR_UPDATED_OCR",
  CLEAR_ERROR: "CLEAR_ERROR_PROJECT_OCRS",
  RESET: "RESET_PROJECT_OCRS"
};
