import { systemConstants } from "../constants";
import { utilities } from "../helpers";
import { authService } from "../services";

export function handleResponse(response) {
  let message =
    systemConstants.support.message ||
    "An unexpected error occurred. Please try again, and if the problem persists contact support";
  if (response && response.status === 401) {
    authService.logout();
    window.location.reload();
  }

  if (response && response.text) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = {
          message
        };

        if (data && data.message) {
          error.message = data.message;
        }
        return Promise.reject({
          ...data,
          status: response?.status,
          success: false,
          message: error.message
        });
      }
      return data;
    });
  } else {
    return Promise.reject({
      ...response,
      status: response?.status,
      success: false,
      message: utilities.getFormattedErrorMessage(message)
    });
  }
}
