import { TFunction } from "i18next";

import {
  ActionItemType,
  ActionItemTypeField
} from "@app/types/ActionItemType.ts";

const ALL = "ALL";
const REQUESTOR = "REQUESTOR";
export type QueryFieldsProps = {
  key: string;
  actionItemTypes: ActionItemType[];
  roleType: string | string[];
  actionType: "create" | "update";
};

const filterAvailableAccess =
  (roleType: string | string[]) => (fieldObj: ActionItemTypeField) => {
    const roles = [roleType].flat();
    const availableTo = [fieldObj.availableTo].flat();
    return roles.some(
      role => availableTo.includes(role) || availableTo.includes(ALL)
    );
  };

const withEditPropVal =
  (roleType: string | string[]) => (fieldObj: ActionItemTypeField) => {
    const roles = [roleType].flat();
    const updatableBy = [fieldObj.updatableBy].flat();
    const isEditable = roles.some(
      role => updatableBy.includes(role) || updatableBy.includes(ALL)
    );
    return { ...fieldObj, disabled: !isEditable };
  };

const withRequiredPropVal = () => (fieldObj: ActionItemTypeField) => {
  const isRequired = fieldObj.required;
  return {
    ...fieldObj,
    required: isRequired
  };
};

const withCreatePropVal =
  (roleType: string | string[]) => (fieldObj: ActionItemTypeField) => {
    const roles = [roleType].flat();
    const creatableBy = [fieldObj.creatableBy].flat();
    const isCreatable = roles.some(
      role => creatableBy.includes(role) || creatableBy.includes(ALL)
    );
    return {
      ...fieldObj,
      disabled: !isCreatable
    };
  };
export const getQueryFields = ({
  key,
  actionItemTypes,
  roleType,
  actionType
}: QueryFieldsProps) => {
  const activeItemType = actionItemTypes?.find(
    actionItemType => actionItemType.configuration.key == key
  );
  if (!activeItemType?.configuration?.fields) {
    return [];
  }
  if (roleType && activeItemType.configuration?.fields.length) {
    if (actionType === "update") {
      return activeItemType?.configuration?.fields
        .filter(filterAvailableAccess(roleType))
        .map(withRequiredPropVal())
        .map(withEditPropVal(roleType));
    } else if (actionType === "create") {
      return activeItemType.configuration?.fields
        .filter(filterAvailableAccess(roleType))
        .map(withRequiredPropVal())
        .map(withCreatePropVal(roleType));
    }
  }
  return activeItemType.configuration?.fields;
};

export function getWorkflowActionsForQuery({
  actionItemType,
  userRoleType,
  t
}: {
  actionItemType: ActionItemType;
  userRoleType: string[] | string;
  t: TFunction;
}) {
  const actions = actionItemType.configuration.menus
    .find(m => m.name === "ACTIONS")
    ?.actions?.filter(a => a.action === "VIEW" || a.action === "EDIT");
  return actions
    ?.filter(a => {
      const availableTo = [a.availableTo].flat();
      return (
        availableTo.some(r => userRoleType.includes(r)) ||
        a.availableTo.includes(ALL)
      );
    })
    .map(a => ({
      name: t(`requests:requests.configured.menu.actions.${a.action}.label`, {
        context: actionItemType.configuration.key
      }),
      action: a.action
    }));
}

export function getFieldPermission({
  fields,
  key,
  userRoleType,
  isUserOnRequestorTeam
}: {
  fields: ActionItemTypeField[];
  key: string;
  userRoleType: string;
  isUserOnRequestorTeam?: boolean;
}) {
  const field = fields?.find(field => field.key === key);
  if (!field) {
    return { isVisible: false, isEditable: false };
  }

  const group = [userRoleType, isUserOnRequestorTeam ? REQUESTOR : ""];

  return getIndividualFieldPermission({ field, userRoleType: group });
}

export function getIndividualFieldPermission({
  field,
  userRoleType
}: {
  field: ActionItemTypeField;
  userRoleType: string | string[];
}) {
  const userRoles = [userRoleType, "ALL"].flat().filter(c => c);

  const availableTo = [field.availableTo].flat();
  const creatableBy = [field.creatableBy].flat();
  const updatableBy = [field.updatableBy].flat();

  const isVisible = availableTo.some(role => userRoles.includes(role));
  const isCreatable =
    isVisible && creatableBy.some(role => userRoles.includes(role));
  const isEditable =
    isVisible && updatableBy.some(role => userRoles.includes(role));

  return {
    isVisible,
    isEditable,
    isCreatable
  };
}
