import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  editJSON,
  isEditing,
  isGenerated
} from "@shared/features/interactiveReport/interactiveReportSlice";
import { useOnGenerateReportMutation } from "@shared/hooks";

export function useAiReporting(projectId, onSaveJsonEditor) {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [prompt, setPrompt] = useState();

  const [
    generateReport,
    {
      data: responseJsonContent,
      error: errorOnGenerateReport,
      isLoading: isGenerateReportLoading
    }
  ] = useOnGenerateReportMutation();

  const onGenerated = useSelector(
    state => state.interactiveReportJsonEditor?.[projectId]?.isGenerated
  );

  useEffect(() => {
    if (responseJsonContent) {
      dispatch(
        editJSON({
          projectId: projectId,
          jsonContent: responseJsonContent
        })
      );
      dispatch(isEditing({ projectId, isEditing: true }));
      dispatch(isGenerated({ projectId, isGenerated: true }));
    }
  }, [responseJsonContent, dispatch, projectId]);

  useEffect(() => {
    const errorData = errorOnGenerateReport?.data;

    if (errorData?.key) {
      setError({
        message: errorData.key
      });
    }
  }, [errorOnGenerateReport, setError]);

  const onCancel = () => {
    dispatch(
      isEditing({
        projectId,
        isEditing: false
      })
    );
    dispatch(editJSON({ projectId, jsonContent: null }));
    setPrompt("");
    dispatch(isGenerated({ projectId, isGenerated: false }));
  };

  const onApply = () => {
    onSaveJsonEditor();
    setPrompt("");
    dispatch(isGenerated({ projectId, isGenerated: false }));
  };

  return {
    onGenerated,
    generateReport,
    onCancel,
    onApply,
    error,
    setError,
    prompt,
    setPrompt,
    isGenerateReportLoading
  };
}
