import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import { useDataTable } from "@app/hooks";

import { Inline } from "@fermions";

import { Button, ButtonVariant, ButtonSize } from "@atoms/Button";
import { Pill } from "@atoms/Pill";
import { Text } from "@atoms/Text";

import DataTable from "@components/molecules/DataTable";

const statusesToIndicatorColorScheme = {
  [systemConstants.client.status.active]: "success",
  [systemConstants.client.status.inactive]: "disabled"
};

const actions = {
  manageEntities: {
    key: "manageEntities",
    label: "common:ui.manageClients.menu.manageEntities"
  },
  updateClient: {
    key: "updateClient",
    label: "common:ui.manageClients.menu.updateClient"
  },
  manageUsers: {
    key: "manageUsers",
    label: "common:ui.manageClients.menu.manageUsers"
  }
};

const ClientDataTable = props => {
  const {
    clients,
    onHandleManageEntities,
    onHandleUpdateClient,
    onHandleManageClientUsers,
    canViewUser,
    canUpdateClient
  } = props;

  const {
    findItem,
    createColumnForStatus,
    createColumn,
    createColumnForSingleAction,
    createColumnForDropdownMenu
  } = useDataTable(clients);
  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });
  const { t } = useTranslation();

  const handleAction = useCallback(
    (actionKey, clientId) => {
      const client = findItem(clientId);
      setRowDisplay({ rowId: -1, display: false });
      if (actionKey === actions.manageEntities.key) {
        onHandleManageEntities(client);
      } else if (actionKey === actions.updateClient.key) {
        onHandleUpdateClient(client);
      } else if (actionKey === actions.manageUsers.key) {
        onHandleManageClientUsers(client);
      }
    },
    [
      findItem,
      onHandleManageEntities,
      onHandleUpdateClient,
      onHandleManageClientUsers
    ]
  );

  const singleActionHandler = useCallback(
    actionKey =>
      ({ cell }) => {
        const clientId = cell.row.original.id;
        handleAction(actionKey, clientId);
      },
    [handleAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const clientId = cell.row.original.id;
      const actionKey = menuItem.key;
      handleAction(actionKey, clientId);
    },
    [handleAction]
  );

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const columns = useMemo(
    () => [
      createColumnForStatus({
        Header: t("common:ui.client.field.active"),
        accessor: "statusIndicatorColorScheme",
        width: 60,
        fixedWidth: true
      }),
      createColumn({
        Header: t("common:ui.client.field.name"),
        accessor: "name",
        width: 250,
        Cell: ({ value }) => <Text text={value} truncate />
      }),
      createColumn({
        Header: t("common:ui.client.field.acn"),
        accessor: "acn",
        width: 70
      }),
      createColumn({
        Header: t("common:ui.client.field.abn"),
        accessor: "abn",
        width: 70
      }),
      createColumnForSingleAction({
        Header: t("roles:ui.role.resource_ClientUsers"),
        accessor: "action",
        className: "manage-users",
        width: 170,
        fixedWidth: true,
        onClickHandler: singleActionHandler(actions.manageUsers.key),
        isHidden: !canViewUser
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        width: 30,
        onClickHandler: dropdownMenuActionHandler,
        displayCell: cellVisibilityHandler
      })
    ],
    [
      createColumnForStatus,
      createColumn,
      t,
      createColumnForSingleAction,
      singleActionHandler,
      canViewUser,
      createColumnForDropdownMenu,
      dropdownMenuActionHandler,
      cellVisibilityHandler
    ]
  );

  const data = useMemo(
    () =>
      clients.map(item => {
        const statusIndicatorColorScheme =
          statusesToIndicatorColorScheme[item.status] || "dark";

        const getActionsMenu = () => {
          if (canUpdateClient) {
            return [
              {
                key: actions.updateClient.key,
                label: t(actions.updateClient.label)
              }
            ];
          }
          return [];
        };

        return {
          id: item.id,
          statusIndicatorColorScheme,
          name: item.name,
          acn: item.acn || "-",
          abn: item.abn || "-",
          action: (
            <Inline gap="100" alignment="left">
              <Pill label={item.usersCount} />
              <Button
                iconName="people"
                variant={ButtonVariant.TEXT}
                size={ButtonSize.SMALL}
                label={t(actions.manageUsers.label)}
              />
            </Inline>
          ),
          actionsMenu: getActionsMenu()
        };
      }),
    [canUpdateClient, clients, t]
  );

  return (
    <DataTable
      className="clients-table"
      columns={columns}
      data={data}
      onMouseEnterRow={rowId => setRowDisplay({ rowId, display: true })}
      onMouseLeaveRow={rowId => setRowDisplay({ rowId, display: false })}
      onRowClick={rowId => handleAction(actions.updateClient.key, rowId)}
    />
  );
};

ClientDataTable.defaultProps = {};

ClientDataTable.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      abn: PropTypes.string,
      acn: PropTypes.string,
      hostId: PropTypes.number,
      id: PropTypes.number,
      logo: PropTypes.shape({
        data: PropTypes.string
      }),
      name: PropTypes.string,
      status: PropTypes.string,
      usersCount: PropTypes.number
    })
  ).isRequired,
  onHandleManageEntities: PropTypes.func,
  onHandleUpdateClient: PropTypes.func,
  onHandleManageClientUsers: PropTypes.func,
  canViewUser: PropTypes.bool,
  canUpdateClient: PropTypes.bool
};

export default ClientDataTable;
