import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { systemConstants } from "@shared/constants/index.js";

import { Button, ButtonVariant, ButtonSize } from "@atoms/Button";

const UploadFileButton = props => {
  const {
    onFilesRejected,
    openFileSelection,
    maxFiles,
    onFilesSelected,
    originalFile,
    onReplaceFile,
    label
  } = props;

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length) {
        onFilesSelected(acceptedFiles[0], originalFile);
      }
    },
    [onFilesSelected, originalFile]
  );

  const supportedMimes = useMemo(() => {
    const list = props.supportedMimes
      ? props.supportedMimes
      : systemConstants.mimes.document;

    return list.map(mime => `.${mime}`).join();
  }, [props.supportedMimes]);

  const onRejected = useCallback(
    fileRejections => {
      onFilesRejected?.(fileRejections[0].errors);
    },
    [onFilesRejected]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected: onRejected,
    accept: supportedMimes,
    maxFiles: maxFiles || 0
  });

  // remove the 'multiple' attribute to limit selection in the file selector dialog
  const inputProps = useMemo(() => {
    const c = { ...getInputProps() };
    if (maxFiles === 1) {
      delete c["multiple"];
    }
    return c;
  }, [getInputProps, maxFiles]);

  useEffect(() => {
    if (openFileSelection) {
      open();
    }
  }, [openFileSelection, open]);

  return (
    <div
      className={`drag-n-drop ${props.className ? props.className : ""} ${
        props.error ? "drag-n-drop--error" : ""
      }`}
      style={{
        padding: "0px"
      }}
      {...getRootProps()}
    >
      <input className="drag-n-drop__input" {...inputProps} />
      <div className="file-attachment-item__replace-button">
        <Button
          label={label}
          onClick={onReplaceFile}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.SMALL}
        />
      </div>
    </div>
  );
};

UploadFileButton.propTypes = {
  label: PropTypes.string.isRequired,
  supportedMimes: PropTypes.arrayOf(PropTypes.string),
  onFilesSelected: PropTypes.func.isRequired,
  onReplaceFile: PropTypes.func,
  onFilesRejected: PropTypes.func,
  maxFiles: PropTypes.number,
  openFileSelection: PropTypes.bool,
  originalFile: PropTypes.object
};

export default UploadFileButton;
