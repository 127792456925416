import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorBox from "@shared/components/errorBox/ErrorBox";

import { getErrorMessage } from "@app/helpers/error";

import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import EditableNewsItem from "@components/molecules/EditableNewsItem/EditableNewsItem";
import Loading from "@components/molecules/Loading";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate/DashboardBoxTemplate";

import "./EditableNews.scss";

const EditableNews = props => {
  const {
    newsItems,
    canBeDisplayed,
    handleMenuActionClick,
    newsConfiguration,
    isLoading,
    error,
    menuActions
  } = props;

  const { t } = useTranslation();
  return (
    <DashboardBoxTemplate
      title={t("common:ui.news.edit.label_other")}
      boxClassName="edit-news--box"
    >
      {isLoading ? (
        <Loading message={t("common:loading")} />
      ) : (
        <>
          {error && <ErrorBox message={getErrorMessage(error, t)} />}
          {newsItems?.map((item, key) => (
            <div
              className={`edit-news--box__content-item${
                canBeDisplayed(item) ? "" : "--unpublished"
              }`}
              key={key}
            >
              <Pill
                label={
                  canBeDisplayed(item)
                    ? t("common:ui.news.published.label")
                    : t("common:ui.news.unpublished.label")
                }
                variant={
                  canBeDisplayed(item)
                    ? PillVariant.SUCCESS
                    : PillVariant.WARNING
                }
                fillStyle={PillFillStyle.FILLED}
              />
              <EditableNewsItem
                item={item.fields}
                handleMenuActionClick={handleMenuActionClick}
                menuActions={menuActions.map(a => ({ ...a, item }))}
                newsConfiguration={newsConfiguration}
              ></EditableNewsItem>
            </div>
          ))}
        </>
      )}
    </DashboardBoxTemplate>
  );
};

EditableNews.propTypes = {
  newsItems: PropTypes.arrayOf(PropTypes.object),
  handleMenuActionClick: PropTypes.func,
  newsConfiguration: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  canBeDisplayed: PropTypes.func,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      action: PropTypes.func
    })
  )
};

export default EditableNews;
