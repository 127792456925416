import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import { documentService } from "./documentService";
import prepareHeaders from "./rtkUtilities";

export const excelDocumentService = createApi({
  reducerPath: "excelDocumentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["excelDocument"],
  endpoints: builder => ({
    updateExcelDocument: builder.mutation({
      query: ({
        documentId,
        documentRevisionId,
        workbookData,
        revisionProperties,
        additional = {}
      }) => ({
        url: `/api/documents/${documentId}/revisions/${documentRevisionId}/websheet`,
        method: "POST",
        body: { workbookData, revisionProperties, ...additional }
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        try {
          const result = await queryFulfilled;
          request?.cb?.(result?.data);
          dispatch(
            documentService.util.invalidateTags([
              { type: "documentRevisions", id: request?.documentId }
            ])
          );
        } catch (e) {}
      },
      invalidatesTags: ["excelDocument"]
    }),
    getExcelDocumentAsJSON: builder.query({
      query: ({ documentId, documentRevisionId }) => ({
        url: `/api/documents/${documentId}/revisions/${documentRevisionId}/websheet`,
        method: "GET"
      }),
      providesTags: ["excelDocument"]
    }),
    getLockForExcelDocument: builder.mutation({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}/lock`,
        method: "POST"
      }),
      invalidatesTags: ["excelDocument"]
    }),
    refreshLockForExcelDocument: builder.mutation({
      query: ({ documentId, fileLockId }) => ({
        url: `/api/documents/${documentId}/lock/${fileLockId}`,
        method: "PUT"
      }),
      invalidatesTags: ["excelDocument"]
    }),
    finishEditSession: builder.mutation({
      query: ({ documentId, fileLockId }) => ({
        url: `/api/documents/${documentId}/lock/${fileLockId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["excelDocument"]
    })
  })
});

export const {
  useGetExcelDocumentAsJSONQuery,
  useGetLockForExcelDocumentMutation,
  useRefreshLockForExcelDocumentMutation,
  useFinishEditSessionMutation,
  useUpdateExcelDocumentMutation
} = excelDocumentService;
