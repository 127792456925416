import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import {
  useAuthUser,
  useCurrentProject,
  useGetClientFromParams,
  useGetProjectByIdQuery,
  useGetProjectMembers,
  useInteractiveReportFilters,
  useLazyGetCommentsFiltersQuery,
  useLazyGetCommentsQuery,
  useLazyGetMenusQuery,
  useLazyGetPageQuery,
  useSecondaryNav
} from "@shared/hooks";
import { useHandlePDF } from "@shared/hooks/useHandlePDF";
import usePubSub from "@shared/hooks/usePubSub";
import { util } from "@shared/services/interactiveReportService";

import { getChartValues } from "@app/helpers/chart";

import { Button, ButtonVariant } from "@atoms/Button";

import PageOverlay from "@components/atoms/PageOverlay";
import PopupOverlay from "@components/atoms/PopupOverlay";
import AnalyticsPanel from "@components/molecules/AnalyticsPanel";
import CommentModal from "@components/molecules/CommentModal";
import DrilldownFilter from "@components/molecules/DrilldownFilter";
import InteractiveReportChart from "@components/molecules/InteractiveReportChart";
import InteractiveReportFilterControls from "@components/molecules/InteractiveReportFilterControls";
import InteractiveReportFilterModal from "@components/molecules/InteractiveReportFilterModal";
import InteractiveReportTable from "@components/molecules/InteractiveReportTable";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import CommentThreadModal from "@components/organisms/CommentThreadModal/CommentThreadModal";
import FileDownloader from "@components/organisms/FileDownloader";
import AnalyticsDashboard from "@components/templates/AnalyticsDashboard";
import InteractiveReportDataTemplate from "@components/templates/InteractiveReportDataTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";
import SidePanelContentTemplate from "@components/templates/SidePanelContentTemplate";

export function attachComments(rows, comments) {
  comments?.forEach(comment => {
    if (!comment?.source?.path) return;
    const path = comment.source.path;

    const [mainRow, drillDownRow] = path;
    if (mainRow && drillDownRow) {
      const rowWithDrillDown = rows?.find(r => r.displayName === mainRow);
      if (!rowWithDrillDown) {
        return;
      }
      const target = rowWithDrillDown.drilldown?.table?.rows?.find(
        r => r.displayName === drillDownRow
      );
      if (target) {
        target.comment = comment;
      }
    } else if (mainRow) {
      const target = rows?.find(r => r.displayName === mainRow);
      if (target) {
        target.comment = comment;
      }
    }
  });

  rows
    ?.filter(r => r.drilldown?.table?.rows)
    ?.forEach(row => {
      const drilldownRowsWithComments =
        row.drilldown.table.rows.filter(r => r.comment) ?? [];

      const aggregateStates = [
        ...new Set(
          drilldownRowsWithComments
            .map(r => r.comment?.statusDisplay?.state)
            .filter(r => r)
        )
      ];
      const aggregateStatus = [
        ...new Set(
          drilldownRowsWithComments.map(r => r.comment?.status).filter(r => r)
        )
      ];

      const aggregateRequestKeys = [
        ...new Set(
          drilldownRowsWithComments
            .map(r => r.comment?.queryType)
            .filter(r => r)
        )
      ];
      row.drilldown.drilldownStats = {
        states: aggregateStates,
        statuses: aggregateStatus,
        requestKeys: aggregateRequestKeys
      };
    });
}

const assignRowIds = (row, id) => {
  row.id = id;
};

export function buildLookupValues(values) {
  const lowerCaseValues = values?.map(value =>
    typeof value === "string" ? value.toLocaleLowerCase() : value
  );
  const lowerUniqueValues = [...new Set(lowerCaseValues)].sort(
    new Intl.Collator(undefined, { sensitivity: "base" }).compare
  );
  return lowerUniqueValues.map(value => values[lowerCaseValues.indexOf(value)]);
}

const InteractiveReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [downloadParams, setDownloadParams] = useState(null);
  const [drilldown, setDrilldown] = useState(null);
  const [errorMsg, setErrorMsg] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [source, setSource] = useState();
  const [table, setTable] = useState(null);
  const [title, setTitle] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [currentDrilldownFilter, setCurrentDrilldownFilter] = useState(null);
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");

  const { setExpanded } = useSecondaryNav();
  const { user } = useAuthUser();

  const { projectId, pageId } = useParams();
  const { client } = useGetClientFromParams();
  const { currentProject: project, onChangeCurrentProject } =
    useCurrentProject();
  const { isProjectMember } = useGetProjectMembers(project);
  const [getInteractiveReportPage, interactiveReportPageData] =
    useLazyGetPageQuery();
  const [getInteractiveReportMenus, interactiveReportMenusData] =
    useLazyGetMenusQuery();
  const [getCommentsFilterOptions, commentsFilterOptions] =
    useLazyGetCommentsFiltersQuery();
  const [getComments, { data: comments }] = useLazyGetCommentsQuery();
  const [displayCommentModal, setDisplayCommentModal] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const { PDFIsLoading, generatePDFForAnalyticsDashboard } = useHandlePDF();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || project?.id === projectId }
    );

  const {
    setActiveFilterKey,
    activeFilter,
    activeReview,
    activeColumns,
    resetFilter,
    setFilter,
    toggleReview,
    activeDrilldownFilters,
    setActiveDrilldownFilterKey,
    setDrilldownFilter,
    toggleColumns
  } = useInteractiveReportFilters();
  const refreshQueriesState = usePubSub();
  const { t } = useTranslation();
  const didMount = useRef(false);

  useEffect(() => {
    if (projectToSelect && project?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, project, onChangeCurrentProject]);

  useEffect(() => {
    if (!didMount.current) {
      refreshQueriesState.subscribe(
        systemConstants.project.events.projects.refreshProjectQueries
      );
      didMount.current = true;
    }
  }, [refreshQueriesState]);

  useEffect(() => {
    if (isInvalidProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [isInvalidProject, navigate]);

  useEffect(() => {
    if (
      project &&
      pageId &&
      refreshQueriesState.value?.projectId === project.id &&
      refreshQueriesState.value?.query &&
      `${refreshQueriesState.value?.query?.source?.pageId}` === pageId
    ) {
      getComments({ projectId, pageId });
    }
  }, [
    project,
    refreshQueriesState.value,
    getComments,
    projectId,
    pageId,
    interactiveReportPageData
  ]);

  useEffect(() => {
    if (projectId && pageId) {
      setDrilldown(null);
      setSelectedRowId(null);
    }
  }, [pageId, projectId]);

  useEffect(() => {
    if (projectId && pageId) {
      getInteractiveReportMenus({ projectId });
      getInteractiveReportPage({ projectId, pageId });
      getCommentsFilterOptions({ projectId });
    }
  }, [
    projectId,
    pageId,
    getInteractiveReportPage,
    getComments,
    getInteractiveReportMenus,
    getCommentsFilterOptions
  ]);

  useEffect(() => {
    if (projectId && pageId) {
      setActiveFilterKey({ projectId, pageId });
    }
  }, [pageId, projectId, setActiveFilterKey]);

  useEffect(() => {
    if (projectId && pageId && selectedRowId) {
      setActiveDrilldownFilterKey({ projectId, pageId, selectedRowId });
    }
  }, [
    pageId,
    projectId,
    drilldown,
    setActiveDrilldownFilterKey,
    selectedRowId
  ]);

  useEffect(() => {
    if (projectId && pageId && interactiveReportPageData?.data) {
      getComments({ projectId, pageId });
    }
  }, [projectId, pageId, getComments, interactiveReportPageData]);

  useEffect(() => {
    if (interactiveReportPageData?.isFetching) {
      setTable(null);
    }
  }, [interactiveReportPageData?.isFetching]);

  useEffect(() => {
    if (interactiveReportPageData?.data) {
      setTitle(interactiveReportPageData.data.pageName);
      const tableWithRowId = structuredClone(
        interactiveReportPageData.data.table
      );
      if (tableWithRowId) {
        tableWithRowId.charts = structuredClone(
          interactiveReportPageData.data.charts
        )?.map((chart, index) => ({
          ...chart,
          id: `${interactiveReportPageData.data.id}-${index}`
        }));
        if (
          !tableWithRowId.displayLevels &&
          interactiveReportPageData?.data.displayLevels
        ) {
          tableWithRowId.displayLevels =
            interactiveReportPageData?.data.displayLevels;
        }
        tableWithRowId.rows?.forEach(assignRowIds);
        attachComments(tableWithRowId.rows, comments);
      }
      const visibleColumns = tableWithRowId?.columns;
      if (activeColumns?.length === visibleColumns?.length) {
        tableWithRowId.columns = structuredClone(activeColumns);
        setSelectedColumns(structuredClone(activeColumns));
      } else {
        const defaulColumns = structuredClone(visibleColumns);
        setSelectedColumns(defaulColumns);
      }
      setTable(tableWithRowId);
    }
  }, [
    pageId,
    comments,
    interactiveReportPageData,
    interactiveReportMenusData,
    selectedRowId,
    toggleColumns,
    projectId,
    activeColumns
  ]);

  useEffect(() => {
    if (selectedRowId !== null && table?.rows) {
      const row = table.rows?.find(r => r.id === selectedRowId);
      row.drilldown.table.rows?.forEach(assignRowIds);
      setDrilldown({
        parentDisplayName: row.displayName,
        ...row.drilldown
      });
    }
  }, [comments, selectedRowId, table?.rows]);

  const getDrilldownFilterItems = useCallback(() => {
    const filterIndex = drilldown?.table.columns.findIndex(
      column => column.name === drilldown?.filterColumn
    );

    if (drilldown) {
      const filterItems = Object.values(
        drilldown?.table?.rows?.reduce((acc, current) => {
          const value = current.cells[filterIndex]?.value;
          acc[value] = {
            name: value,
            label: value,
            value: {
              label: value,
              filterColumn: drilldown?.filterColumn
            },
            state: [
              ...(acc[current?.cells[filterIndex]?.value]?.state ?? []),
              current.comment?.statusDisplay?.state
            ],
            comments: current.comment
              ? [...(acc[value]?.comments ?? []), current.comment.status]
              : null
          };
          return acc;
        }, [])
      ).filter(
        (item, index, self) =>
          index === self.findIndex(t => t.name === item.name)
      );

      return filterItems;
    }
  }, [drilldown]);

  const drilldownFilterItems = getDrilldownFilterItems();

  const isSmallScreen = window.innerWidth < 2000;

  const onDrilldownTableSelected = useCallback(
    item => {
      setSelectedRowId(prevState => {
        if (item.row?.id !== undefined && prevState === item.row.id) {
          setDrilldown(null);
          return null;
        }
        const activeDrilldown = {
          parentDisplayName: item.row.displayName,
          ...item.drilldown
        };
        item.drilldown.table.rows?.forEach(assignRowIds);
        setDrilldown(activeDrilldown);
        if (!drilldown && window.innerWidth < 2000) {
          // Side nav toggles
          setExpanded({ isExpanded: false });
        }
        return item.row.id;
      });
    },
    [drilldown, setExpanded]
  );

  const getDrillDownTable = () => {
    if (!drilldown) {
      return null;
    }
    const getFilter = () => {
      if (!drilldown?.filterColumn) {
        return null;
      }
      const checkFilterOptions = drilldownFilterItems?.some(
        item => item.label === currentDrilldownFilter?.drilldownFilter.label
      );

      if (!checkFilterOptions) {
        return {
          drilldownFilter: drilldownFilterItems[0].value
        };
      }
      if (currentDrilldownFilter) {
        return currentDrilldownFilter;
      }
      return activeDrilldownFilters;
    };

    const currentFilter = getFilter();

    return (
      <>
        {drilldown.charts &&
          getChart(
            drilldown.charts,
            drilldown?.table,
            true,
            drilldown.id,
            currentFilter?.drilldownFilter,
            drilldown.pageName
          )}
        <div className={"side-panel-template__subtitle"}>
          {getDrilldownSubtitle()}
        </div>

        <InteractiveReportTable
          filter={currentFilter}
          table={drilldown?.table}
          onClickCommentToCreate={onClickCommentToCreate}
          onClickCommentToView={onClickCommentToView}
          downloadTable={{
            name: "drilldown",
            onClick: onDownloadTable({ isDrilldown: true })
          }}
          isDrilldown={true}
          displayChart={drilldown.charts}
        />
      </>
    );
  };

  const onDrillDownPageSelected = useCallback(
    item => {
      if (item.drilldown.pageId === undefined) {
        return;
      }

      const pageLink = `/projects/${projectId}/pages/${item.drilldown.pageId}?newtab=1`;
      window.open(pageLink);
    },
    [projectId]
  );

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setShowProjectAccessModal(true);
      });
    },
    [isProjectMember, user]
  );

  const onClickCommentToCreate = useCallback(
    (rowId, isDrilldown) => {
      checkMembership(
        t("interactiveReport:interactiveReport.ui.projectAccess.message")
      )(() => {
        let path = [];
        if (isDrilldown) {
          const parentDisplayName = drilldown.parentDisplayName;
          const displayName = drilldown.table.rows[rowId].displayName;
          path = [parentDisplayName, displayName];
        } else {
          const displayName = table.rows[rowId].displayName;
          path = [displayName];
        }
        const newSource = {
          pageId: interactiveReportPageData.data.id,
          revision: interactiveReportPageData.data.revision,
          path
        };
        setSource(newSource);
        setDisplayCommentModal(true);
      });
    },
    [
      checkMembership,
      drilldown?.parentDisplayName,
      drilldown?.table.rows,
      interactiveReportPageData.data?.id,
      interactiveReportPageData.data?.revision,
      table?.rows,
      t
    ]
  );

  const onClickCancelComment = () => {
    setDisplayCommentModal(false);
  };

  const onClickSubmitComment = () => {
    setDisplayCommentModal(false);
    dispatch(util.invalidateTags(["Comments"]));
  };

  const onClickCommentToView = useCallback(
    (rowId, isDrilldown) => {
      let relevantRow = null;
      if (isDrilldown) {
        relevantRow = drilldown.table.rows[rowId];
      } else {
        relevantRow = table.rows[rowId];
      }
      const queryId = relevantRow?.comment?.id;
      if (queryId) {
        setModalOpen(true);
        setModalContent(
          <CommentThreadModal
            displayModal={true}
            queryId={queryId}
            project={project}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        );
      }
    },
    [drilldown?.table?.rows, project, table?.rows]
  );

  const onClickReviewRow = useCallback(
    (e, rowId) => {
      e.stopPropagation();
      toggleReview({ projectId, pageId, rowId });
    },
    [toggleReview, projectId, pageId]
  );

  const showFilterModal = () => {
    setIsFilterModalVisible(true);
  };

  const hideFilterModal = () => {
    setIsFilterModalVisible(false);
  };

  const onChangeColumnFilter = useCallback(
    columns => {
      toggleColumns({ projectId, pageId, columns });
    },
    [toggleColumns, pageId, projectId]
  );

  const handleResetFilter = useCallback(() => {
    resetFilter({ projectId, pageId });
    const defaultTableColumns = structuredClone(
      interactiveReportPageData.data.table.columns
    );
    onChangeColumnFilter(defaultTableColumns);
  }, [
    pageId,
    projectId,
    resetFilter,
    interactiveReportPageData.data?.table?.columns,
    onChangeColumnFilter
  ]);

  const applyFilters = useCallback(
    filter => {
      hideFilterModal();
      setDrilldown(null);
      setSelectedRowId(null);
      setFilter({ projectId, pageId, filter });
    },
    [pageId, projectId, setFilter]
  );

  const applyCommentsFilter = useCallback(
    commentsFilter => {
      const updatedFilter = {
        ...activeFilter,
        comments: commentsFilter
      };
      setFilter({ projectId, pageId, filter: updatedFilter });
    },
    [activeFilter, pageId, projectId, setFilter]
  );

  const applyDrilldownFilter = useCallback(
    drilldownFilter => {
      const updatedFilter = {
        ...activeDrilldownFilters,
        drilldownFilter: drilldownFilter
      };
      setCurrentDrilldownFilter(updatedFilter);
      setDrilldownFilter({
        projectId,
        pageId,
        selectedRowId,
        filter: updatedFilter
      });
    },
    [
      activeDrilldownFilters,
      pageId,
      projectId,
      setDrilldownFilter,
      selectedRowId
    ]
  );

  const onDownloadTable = useCallback(
    ({ isDrilldown }) =>
      () => {
        if (isDownloading) {
          return;
        }
        const revision = interactiveReportPageData.data.revision;
        const revisionPath = revision ? `/revision/${revision}` : "";
        const drilldownPath =
          isDrilldown && drilldown ? `/drilldown/${drilldown.id}` : "";

        setDownloadParams({
          apiUrl: `projects/${projectId}/interactiveReport/downloadTable/${pageId}${drilldownPath}${revisionPath}`,
          sequenceId: Date.now()
        });
      },
    [interactiveReportPageData, isDownloading, projectId, pageId, drilldown]
  );

  const downloadingHandler = () => {
    setErrorMsg(null);
    setIsDownloading(false);
  };
  const errorHandler = message => {
    setDownloadParams(null);
    setErrorMsg(message);
    setIsDownloading(false);
  };

  const filterableTableColumns = useMemo(() => {
    const safeColumns = table?.columns ?? [];
    const safeRows = table?.rows ?? [];
    const colToFilter = safeColumns
      .map(({ type }, i) => ({ type, i }))
      .filter(({ type }) => type !== "displayName");
    const lookupValues = colToFilter
      .filter(({ type }) => type === "string")
      .map(({ i }) => ({
        i,
        values: buildLookupValues(safeRows.map(({ cells }) => cells[i]?.value))
      }))
      .reduce((a, { i, values }) => ({ ...a, [i]: values }), {});

    return colToFilter.map(({ i }) => ({
      ...safeColumns[i],
      lookup: lookupValues[i]
    }));
  }, [table]);

  const getFileName = useCallback(
    ({ pageName, drilldownFilter } = {}) => {
      const clientName = client?.name;
      const projectId = project?.id;
      const reportRevision = interactiveReportPageData?.data?.revision;
      const chartPageName =
        pageName ?? interactiveReportPageData?.data?.pageName;
      const filterValue = drilldownFilter?.label;

      return [
        clientName,
        `${projectId} ${project?.name}`,
        `Report v${reportRevision}`,
        chartPageName,
        filterValue
      ]
        .filter(f => f)
        .join(" - ");
    },
    [
      client?.name,
      interactiveReportPageData?.data?.pageName,
      interactiveReportPageData?.data?.revision,
      project?.id,
      project?.name
    ]
  );

  const fetchChartsValues = useCallback(
    (
      chart,
      tableData,
      index,
      isDrilldown,
      id,
      drilldownFilter,
      drilldownPageName
    ) => {
      const chartWithValues = getChartValues(
        chart,
        tableData,
        id,
        drilldownFilter
      );
      return (
        <InteractiveReportChart
          key={index}
          chart={chartWithValues}
          tickMaxLines={2}
          isDrilldown={isDrilldown}
          pieRadius={isSmallScreen ? 90 : 100}
          chartMargin={{ bottom: 28 }}
          chartFileName={getFileName({
            pageName: drilldownPageName,
            drilldownFilter
          })}
        />
      );
    },
    [getFileName]
  );

  const getChart = useCallback(
    (charts, table, isDrilldown, id, drilldownFilter, drilldownPageName) => {
      return charts?.length ? (
        <>
          {charts?.map((c, i) => {
            if (c.axis?.tableColumn) {
              return (
                <React.Fragment key={i}>
                  {fetchChartsValues(
                    c,
                    table,
                    i,
                    isDrilldown,
                    id,
                    drilldownFilter,
                    drilldownPageName
                  )}
                </React.Fragment>
              );
            } else if (c.axis?.values) {
              return (
                <InteractiveReportChart
                  key={i}
                  chart={c}
                  isDrilldown={isDrilldown}
                  tickMaxLines={2}
                  pieRadius={100}
                  chartMargin={{ bottom: 28 }}
                  chartFileName={getFileName({
                    pageName: drilldownPageName,
                    drilldownFilter
                  })}
                />
              );
            } else {
              return null;
            }
          })}
        </>
      ) : null;
    },
    [fetchChartsValues, getFileName]
  );

  const getDataTable = useCallback(
    () => (
      <InteractiveReportTable
        filter={activeFilter}
        reviewRows={activeReview}
        table={table}
        onDrilldownTableSelected={onDrilldownTableSelected}
        onDrillDownPageSelected={onDrillDownPageSelected}
        activeRowId={selectedRowId}
        onClickCommentToCreate={onClickCommentToCreate}
        onClickCommentToView={onClickCommentToView}
        onClickReviewRow={onClickReviewRow}
        downloadTable={{
          name: "table",
          onClick: onDownloadTable({ isDrilldown: false })
        }}
        displayChart={table?.charts}
      />
    ),
    [
      activeFilter,
      activeReview,
      onClickCommentToCreate,
      onClickCommentToView,
      onClickReviewRow,
      onDownloadTable,
      onDrillDownPageSelected,
      onDrilldownTableSelected,
      selectedRowId,
      table
    ]
  );

  const getDrilldownSubtitle = () => {
    if (!drilldown?.subtitle && !drilldown?.subtitleLabel) {
      return null;
    }
    return (
      <>
        <span className="side-panel-template__subtitle--left">
          {drilldown.subtitle}
        </span>
        <span className="side-panel-template__subtitle--right">
          {drilldown.subtitleLabel}
        </span>
      </>
    );
  };

  const data = useMemo(
    () => structuredClone(interactiveReportPageData.data),
    [interactiveReportPageData.data]
  );

  const getGrids = useCallback(() => {
    if (data) {
      return (
        <AnalyticsDashboard
          data={data}
          component={data => <AnalyticsPanel data={data} />}
        />
      );
    }
  }, [data]);

  const getHelpMessage = () => {
    if (table?.columns?.length === 0 && data?.pageType !== "dashboard") {
      return "No data included for current report.";
    }
    return null;
  };

  const getLoadingMessage = () => {
    if (interactiveReportPageData?.isFetching) {
      return "Loading Report...";
    } else {
      return null;
    }
  };

  const getFilterColumns = () => {
    if (!drilldown?.filterColumn) {
      return null;
    }
    return (
      <DrilldownFilter
        filterLabel={drilldown.filterColumn}
        data={drilldown?.table}
        onChange={applyDrilldownFilter}
        selectedFilterOption={currentDrilldownFilter?.drilldownFilter}
        projectId={projectId}
        pageId={pageId}
        selectedRowId={selectedRowId}
        setDrilldownFilter={setDrilldownFilter}
        activeDrilldownFilters={activeDrilldownFilters}
        currentDrilldownFilter={currentDrilldownFilter}
        filterItems={drilldownFilterItems}
      />
    );
  };

  const renderTable = () => {
    if (table?.columns?.length > 0) {
      return (
        <>
          <InteractiveReportDataTemplate
            filterControls={
              <InteractiveReportFilterControls
                showCommentsFilter={comments?.length > 0}
                onCommentsFilterSelected={applyCommentsFilter}
                selectedCommentsOption={activeFilter?.comments}
                commentsFilterOptions={commentsFilterOptions?.data}
                filterConditions={activeFilter?.conditions ?? []}
                onResetFilters={handleResetFilter}
                onTriggerOpenFilterModal={showFilterModal}
                onChangeColumnFilter={onChangeColumnFilter}
                columnDropdownItems={selectedColumns}
              />
            }
            chart={getChart(table?.charts, table, table.id)}
            dataTable={getDataTable()}
          />
        </>
      );
    } else if (data?.pageType === "dashboard") {
      return <InteractiveReportDataTemplate grids={getGrids()} />;
    }

    return <></>;
  };

  const drilldownSidePanel = () => {
    return (
      <SidePanelContentTemplate
        title={drilldown?.pageName}
        content={getDrillDownTable()}
        filters={getFilterColumns()}
        onClose={() => {
          setDrilldown(null);
          setSelectedRowId(null);
        }}
      />
    );
  };

  return (
    <>
      <PageOverlay
        isVisible={PDFIsLoading}
        text={t("common:ui.pageOverlay.text.downloadingPDF")}
      />
      <PageTemplate
        header={{
          title: title ?? "",
          actions:
            data?.pageType === "dashboard" ? (
              // hide the element when export as PDF
              <div>
                <Button
                  variant={ButtonVariant.TEXT}
                  iconName="download"
                  onClick={() => {
                    generatePDFForAnalyticsDashboard(
                      `${client?.name} - ${project?.name} - ${title}.pdf`
                    );
                  }}
                  label={t("interactiveReport:ui.button.downloadPDF")}
                />
              </div>
            ) : null
        }}
        body={{
          primary: renderTable()
        }}
        other={{
          loading: getLoadingMessage(),
          help: getHelpMessage(),
          error: errorMsg,
          project
        }}
        sidePanel={{
          open: drilldown !== null,
          content: drilldownSidePanel(),
          hasSlider: true
        }}
        modal={{
          open: modalOpen,
          content: modalOpen ? modalContent : null
        }}
      />
      {downloadParams && (
        <FileDownloader
          downloadParams={downloadParams}
          onError={errorHandler}
          onDownloading={downloadingHandler}
        ></FileDownloader>
      )}
      <CommentModal
        source={source}
        displayCommentModal={displayCommentModal}
        project={project}
        onClickCancelComment={onClickCancelComment}
        onClickSubmitComment={onClickSubmitComment}
      />
      <PopupOverlay
        isVisible={isFilterModalVisible}
        isModal={true}
        width="70vw"
      >
        <InteractiveReportFilterModal
          isVisible={isFilterModalVisible}
          onCancelModal={hideFilterModal}
          onApplyFilters={applyFilters}
          tableColumns={filterableTableColumns}
          filterConditions={activeFilter?.conditions ?? []}
        ></InteractiveReportFilterModal>
      </PopupOverlay>
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={membershipErrorMessage}
        handleClose={() => {
          setShowProjectAccessModal(false);
          setMembershipErrorMessage("");
        }}
      />
    </>
  );
};

export default InteractiveReport;
