import React, { useMemo, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useCurrentProject } from "@shared/hooks";

import DraftProjectBanner from "@components/organisms/DraftProjectBanner/DraftProjectBanner";

interface PageBannerProps {
  registerModal: (key: string, content: () => React.JSX.Element) => void;
  handleOpenModal: (key: string) => void;
  handleCloseModal: () => void;
}

const PageBanner = ({
  registerModal,
  handleOpenModal,
  handleCloseModal
}: PageBannerProps) => {
  const { currentProject } = useCurrentProject();
  const navigate = useNavigate();

  const currentProjectRef = useRef({
    id: undefined,
    isDraft: false
  });

  const banner = useMemo(() => {
    if (window.location.pathname.includes("/projects")) {
      // Prevents flickering when currentProject is reloading (is undefined)
      if (
        currentProject?.id &&
        (currentProject?.id !== currentProjectRef.current.id ||
          currentProjectRef.current?.isDraft !==
            (currentProject?.status === systemConstants.project.status.draft))
      ) {
        currentProjectRef.current = {
          id: currentProject?.id,
          isDraft:
            currentProject?.status === systemConstants.project.status.draft
        };
      }
    } else {
      currentProjectRef.current = {
        id: undefined,
        isDraft: false
      };
    }

    if (currentProjectRef.current?.id && currentProjectRef.current?.isDraft) {
      return (
        <DraftProjectBanner
          projectId={currentProjectRef.current?.id}
          refreshAfterPublish={() => {
            navigate(
              `${routeConstants.projects}/${currentProjectRef.current?.id}`,
              { replace: true }
            );
            currentProjectRef.current = {
              id: undefined,
              isDraft: false
            };
          }}
          registerModal={registerModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
        />
      );
    }

    return <></>;
  }, [
    currentProject?.id,
    currentProject?.status,
    handleCloseModal,
    handleOpenModal,
    navigate,
    registerModal
  ]);

  return banner;
};

export default PageBanner;
