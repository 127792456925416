import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageDocumentDownloadsActions } from "@shared/actions";

export function useDocumentDownloads() {
  const [downloadedDocuments, setDownloadedDocuments] = useState([]);
  const [isDocumentDownloadsLoading, setIsDocumentDownloadsLoading] =
    useState(false);
  const [documentDownloadsError, isDocumentDownloadsError] = useState(null);
  const manageDocumentDownloads = useSelector(
    state => state.manageDocumentDownloads
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setDownloadedDocuments(manageDocumentDownloads.downloadedDocuments);
  }, [manageDocumentDownloads.downloadedDocuments]);

  useEffect(() => {
    setIsDocumentDownloadsLoading(manageDocumentDownloads.loading);
  }, [manageDocumentDownloads.loading]);

  useEffect(() => {
    isDocumentDownloadsError(manageDocumentDownloads.error);
  }, [manageDocumentDownloads.error]);

  const fetchProjectDocument = useCallback(
    item => {
      dispatch(manageDocumentDownloadsActions.getProjectDocument(item));
    },
    [dispatch]
  );

  const resetDocumentDownloadsError = () => {
    dispatch(manageDocumentDownloadsActions.resetError());
  };

  return {
    documentDownloadsError,
    isDocumentDownloadsLoading,
    downloadedDocuments,
    fetchProjectDocument,
    resetDocumentDownloadsError
  };
}
