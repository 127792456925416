import React from "react";

import { useTranslation } from "react-i18next";

import { useTooltip } from "@shared/hooks/useTooltip";

import OTTooltip from "@components/atoms/OTTooltip";

import "./StatusBar.scss";

type StatusBarType =
  | "upcoming"
  | "overdue"
  | "responded"
  | "closed"
  | "notstarted"
  | "inprogress"
  | "completed"
  | "error"
  | "warning"
  | "success";

interface StatusBarProps {
  type: StatusBarType;
}

export const StatusBar = ({ type }: StatusBarProps) => {
  const { t } = useTranslation();
  const { showTooltip, elementProps } = useTooltip();

  return (
    <>
      <div
        className={`status-bar status-bar--${type}`}
        data-testid={`testStatusBar-${type}`}
        {...elementProps}
      />
      {showTooltip && (
        <OTTooltip
          visible={showTooltip}
          content={t(`requests:requests.indicators.label_${type}`)}
        />
      )}
    </>
  );
};
