import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  useAddEngagementMutation,
  useAuthUser,
  useClients
} from "@shared/hooks";
import { useGetEngagementManagersQuery } from "@shared/services";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalForm from "@components/molecules/ModalForm";

function AddEngagementForm(props) {
  const { t } = useTranslation();
  const {
    yupSchema,
    isEngagementManagerFieldEnabled,
    onCancel,
    onSubmitted,
    showForm
  } = props;
  const { user } = useAuthUser();
  const { clients } = useClients(user);
  const { data: engagementManagers = [] } = useGetEngagementManagersQuery(null);

  const [
    addEngagement,
    {
      isError: isAddEngagementError,
      isSuccess: addedEngagement,
      error: addEngagementError
    }
  ] = useAddEngagementMutation();

  const clientsForDropdown = useMemo(
    () =>
      clients.map(c => ({
        name: c.name,
        value: c
      })),
    [clients]
  );

  const engagementManagersForDropdown = useMemo(
    () =>
      engagementManagers.map(e => ({
        name: e.name,
        value: e
      })),
    [engagementManagers]
  );

  useEffect(() => {
    if (addedEngagement) {
      onSubmitted();
    }
  }, [addedEngagement, onSubmitted]);

  const handleCancel = () => {
    handleFreezeScrollPosition(false);
    onCancel();
  };

  const handleSubmit = useCallback(
    data => {
      addEngagement({
        name: data.name,
        client: data.client.value,
        manager: data.engagementManager?.value
      });
      handleFreezeScrollPosition(false);
    },
    [addEngagement]
  );

  return (
    <div>
      <ModalForm
        title={t("common:ui.engagement.navigation.title", {
          context: "add"
        })}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        slidingForm={true}
        show={showForm}
        yupSchema={yupSchema}
      >
        {isAddEngagementError && (
          <InlineAlert
            type="error"
            message={t(addEngagementError?.data?.message)}
          />
        )}
        <Form.TextField
          name={"name"}
          label={t("common:ui.engagement.name.label")}
          required={true}
        />
        <Form.Dropdown
          name={"client"}
          label={t("common:ui.engagement.client.label")}
          required={true}
          items={clientsForDropdown}
        />
        {isEngagementManagerFieldEnabled && (
          <Form.Dropdown
            name={"engagementManager"}
            label={t("common:ui.engagement.engagementManager.label")}
            required={true}
            items={engagementManagersForDropdown}
          />
        )}
      </ModalForm>
    </div>
  );
}

AddEngagementForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  isEngagementManagerFieldEnabled: PropTypes.bool.isRequired,
  yupSchema: PropTypes.object.isRequired,
  showForm: PropTypes.bool.isRequired
};

export default AddEngagementForm;
