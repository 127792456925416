import { useCallback, useMemo } from "react";

import { useSelector } from "react-redux";

export function useStaticAssets() {
  const authentication = useSelector(state => state.authentication);

  const isUrlAvailable = useMemo(
    () => authentication?.host?.host !== undefined,
    [authentication?.host?.host]
  );

  const getUrl = useCallback(
    path => {
      const host = authentication?.host?.host;
      const hostName = host?.name;
      const staticAssetsLocation =
        host?.properties?.staticAssetsLocation ??
        "https://static-assets.oneteam.services";
      const slash = staticAssetsLocation.endsWith("/") ? "" : "/";
      return `${staticAssetsLocation}${slash}${hostName}/${path}`;
    },
    [authentication?.host?.host]
  );

  const setFavIcon = useCallback(() => {
    function setHref(id, image) {
      const element = document.querySelector(`#${id}`);
      if (element) {
        element.href = getUrl(image);
      }
    }
    setHref("favicon", "favicon.png");
    setHref("appleTouchIcon", "180x180.png");
  }, [getUrl]);

  return { isUrlAvailable, getUrl, setFavIcon };
}
