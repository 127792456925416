import React, { useMemo } from "react";

import { useTooltip } from "@shared/hooks/useTooltip";

import { classNames } from "@app/helpers/componentHelpers";

import { Inline, Stack } from "@fermions";

import OTTooltip from "../OTTooltip";
import "./NavHeader.scss";
import { Pill, PillSize } from "@atoms/Pill/Pill.tsx";
import { returnStringIfTrue } from "@app/helpers/componentHelpers.js";

const getTextDirection = (isExpanded: boolean) =>
  isExpanded ? "nav-header__text" : "nav-header__text--vertical";

interface Props {
  text: string;
  isExpanded: boolean;
  image?: string;
  tooltipContent?: string;
  pills?: string[];
}

function NavHeader(props: Props) {
  const { text, isExpanded, image, tooltipContent, pills } = props;
  const { elementProps, showTooltip } = useTooltip();

  const Element = text?.length > 16 ? Stack : Inline;

  const validPills = useMemo(() => pills?.filter(p => p), [pills]);

  return (
    <>
      <Element
        className={classNames([
          "nav-header",
          returnStringIfTrue(pills && isExpanded, "nav-header__with-pills"),
          returnStringIfTrue(!isExpanded, "nav-header__vertical")
        ])}
        {...elementProps}
        alignment={isExpanded && image ? "left" : "center"}
      >
        {isExpanded && image && (
          <img
            className="nav-header__image"
            src={image}
            alt="nav header image"
          />
        )}
        <h3 className={classNames([getTextDirection(isExpanded)])}>{text}</h3>
        {!!tooltipContent && (
          <OTTooltip visible={showTooltip} content={tooltipContent} />
        )}
      </Element>
      {validPills?.length > 0 && isExpanded && (
        <Inline className="nav-header__pills" gap="050">
          {validPills.map(pill => (
            <Pill label={pill} key={pill} size={PillSize.SMALL} />
          ))}
        </Inline>
      )}
    </>
  );
}

export default NavHeader;
