import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";

import "./Avatar.scss";

const Avatar = props => {
  const { user, size, showHover, className } = props;
  const getAvatarColorCode = name => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash % 5) + 1;
  };

  const renderDefaultAvatar = useCallback(() => {
    return (
      <div
        className={`default-avatar default-avatar--${size} default-avatar--variant-${getAvatarColorCode(user.name)}`}
        title={`Avatar of ${user.firstname} ${user.lastname}`}
      >
        {`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}
      </div>
    );
  }, [size, user.firstname, user.lastname, user.name]);

  const renderImageAvatar = useCallback(() => {
    return (
      <img
        src={user.avatar.image}
        alt={`Avatar of ${user.firstname} ${user.lastname}`}
        className={`ot-avatar__image ot-avatar__image--${size}`}
      />
    );
  }, [size, user.avatar?.image, user.firstname, user.lastname]);

  return (
    <ContainerWithTooltip
      className={`ot-avatar ${className}`}
      tooltipContent={`${user.firstname} ${user.lastname}`}
      hideTooltip={!showHover}
    >
      {user.avatar?.image ? renderImageAvatar() : renderDefaultAvatar()}
    </ContainerWithTooltip>
  );
};

Avatar.defaultProps = {
  size: "small",
  showHover: false
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["x-small", "small", "medium", "large"]),
  user: PropTypes.shape({
    name: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  className: PropTypes.string,
  showHover: PropTypes.bool
};

export default Avatar;
