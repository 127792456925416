import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useModalContent } from "@shared/hooks/useModalContent";

import ions from "@ions";

import Title from "@components/atoms/Title/Title";

import PageBanner from "../PageTemplate/PageBanner";
import PageModal from "../PageTemplate/PageModal";
import "./PageBodyTemplate.scss";

const PageBodyTemplate = props => {
  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();

  return (
    <div className="page-body-template">
      <PageBanner
        registerModal={registerModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="page-body-template__header">
        <div
          className={`page-body-template__header-container ${
            props.extraSpace
              ? "page-body-template__header-container--extra"
              : ""
          }`}
        >
          <div className="page-body-template__header-heading">
            {props.breadcrumbs && (
              <div className="page-body-template__header-breadcrumbs">
                {props.breadcrumbs.map((crumb, index) => (
                  <>
                    {index > 0 && <span>{">"}</span>}
                    <span key={index}>
                      <Link
                        className={
                          "page-body-template__header-breadcrumbs-item"
                        }
                        to={crumb.linkTo}
                        state={crumb.linkTo?.state}
                      >
                        {crumb.label}
                      </Link>
                    </span>
                  </>
                ))}
              </div>
            )}
            {props.tagline && (
              <div
                className="page-body-template__header-tagline"
                role="tagline"
              >
                {props.tagline}
              </div>
            )}
            <Title
              title={props.title}
              subtitle={props.subheading}
              context={ions.context?.default?.pageTitle}
            />
            {props.shortDescription && (
              <div className="page-body-template__header-short-description">
                {props.shortDescription}
              </div>
            )}
          </div>
          <div className="page-body-template__header-actions">
            {props.actions}
          </div>
        </div>
      </div>

      <div className="page-body-template__main">{props.children}</div>

      {modalOpen && <PageModal content={getModalContent()} open={modalOpen} />}
    </div>
  );
};

PageBodyTemplate.defaultProps = {};

PageBodyTemplate.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  tagline: PropTypes.any,
  title: PropTypes.string,
  subheading: PropTypes.string,
  shortDescription: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node
};

export default PageBodyTemplate;
