import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;
export const finalPackageService = {
  getFinalPackageTips,
  getFinalPackageShareStatus,
  updateFinalPackageTip,
  addFinalPackageTip,
  deleteFinalPackageTip,
  getFinalPackageDocuments,
  getFinalPackageProcessEntries,
  createFinalPackageProcessEntry,
  updateFinalPackageProcessEntry,
  deleteFinalPackageProcessEntry,
  setFinalPackageShareStatus,
  getSignedDocuments
};

function getSignedDocuments(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/signoff`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createFinalPackageProcessEntry(finalPackageProcess) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(finalPackageProcess)
  };
  const projectId = finalPackageProcess.projectId;
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/finalisationProcess`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateFinalPackageProcessEntry(finalPackageProcess) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(finalPackageProcess)
  };
  const projectId = finalPackageProcess.projectId;
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/finalisationProcess`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getFinalPackageProcessEntries(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/finalisationProcess`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteFinalPackageProcessEntry({ projectId, id }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/finalisationProcess/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getFinalPackageTips(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackageTips`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateFinalPackageTip(projectId, finalPackageTip) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(finalPackageTip)
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackageTips/${finalPackageTip.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addFinalPackageTip(projectId, finalPackageTip) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(finalPackageTip)
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackageTips`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteFinalPackageTip(projectId, finalPackageTip) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(finalPackageTip)
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackageTips/${finalPackageTip.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getFinalPackageDocuments(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/document`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getFinalPackageShareStatus(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/share`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function setFinalPackageShareStatus({ projectId, shareStatus }) {
  const requestOptions = {
    method: shareStatus ? "POST" : "DELETE",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/share`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
