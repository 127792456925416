import React, { useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { BorderStyle, MultiSelectInput } from "@molecules/inputs";

const TodoListFilter = props => {
  const { filterItems, onFiltersSelected, t } = props;
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const onChangeSelectedFilter = items => {
    setSelectedFilterItems(items);
    onFiltersSelected(items);
  };
  return filterItems.length > 0 ? (
    <MultiSelectInput
      label={t("requests:requests.ui.clientTodoList.filterLabel")}
      placeholder={t("requests:requests.ui.clientTodoList.filterPlaceholder")}
      items={filterItems}
      onChange={onChangeSelectedFilter}
      disabled={false}
      selectedValues={selectedFilterItems}
      borderStyle={BorderStyle.NONE}
    />
  ) : (
    <></>
  );
};

TodoListFilter.defaultProps = {
  filterItems: []
};

TodoListFilter.propTypes = {
  filterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  onFiltersSelected: PropTypes.func
};
export default withTranslation()(TodoListFilter);
