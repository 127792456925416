import React, { useCallback, useMemo, useState } from "react";

import { t } from "i18next";
import { omit, pickBy } from "lodash";

import { systemConstants } from "@shared/constants/systemConstants";

import { CrudFormMode, ItemList, StepStatusList } from "@app/types/index.ts";

import { Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button/index.ts";
import TextInput from "@atoms/TextInput/index.js";

import { DropdownInput } from "@molecules/inputs";

import { useWorkflowCanvas } from "@pages/ViewActionItemType/WorkflowCanvas/WorkflowCanvasContext.tsx";

import { WorkflowSidebarProps } from "../WorkflowSidebar.tsx";

const MAX_LENGTH = 250;
export const StepEditor = ({ crudFormMode, onClose }: WorkflowSidebarProps) => {
  const context = useWorkflowCanvas();
  const { selectedLaneId, workflowConfig, setWorkflowConfig } = context;
  const [errorName, setErrorName] = useState<string | null>(null);

  const hasUpdateAccess = useMemo(() => {
    return crudFormMode === CrudFormMode.UPDATE;
  }, [crudFormMode]);

  const workflowStep = useMemo(() => {
    if (selectedLaneId) {
      return workflowConfig?.steps[selectedLaneId];
    }
  }, [workflowConfig, selectedLaneId]);

  const stepStatusList = useMemo(() => {
    return StepStatusList.map((status: string) => {
      return { name: status, value: status };
    });
  }, []);

  const status = useMemo(() => {
    return selectedLaneId
      ? workflowConfig?.steps[selectedLaneId].queryStatus
      : StepStatusList.find(
          status => status === systemConstants.project.queries.status.open
        );
  }, [workflowConfig, selectedLaneId]);

  const name = useMemo(() => {
    return selectedLaneId ? workflowConfig?.steps[selectedLaneId].name : "";
  }, [selectedLaneId, workflowConfig]);

  const handleChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nameVal = event.target.value;
      if (nameVal?.length > MAX_LENGTH) {
        const errorText = t(
          "admin:ui.requestTypes.workflowAction.error.maxCharacter"
        );
        setErrorName(errorText);
        return;
      }
      setErrorName(null);
      if (workflowStep && workflowConfig && selectedLaneId) {
        const selectedStepVal = workflowConfig.steps[selectedLaneId];
        const stepObj = nameVal
          ? { ...selectedStepVal, name: nameVal }
          : omit(selectedStepVal, "name");

        setWorkflowConfig({
          ...workflowConfig,
          steps: {
            ...workflowConfig.steps,
            [selectedLaneId]: stepObj
          }
        });
      }
    },
    [workflowConfig, selectedLaneId, workflowStep, setWorkflowConfig]
  );
  const handleStepStatus = useCallback(
    (status: ItemList) => {
      if (workflowConfig && selectedLaneId) {
        setWorkflowConfig({
          ...workflowConfig,
          steps: {
            ...workflowConfig.steps,
            [selectedLaneId]: {
              ...workflowConfig.steps[selectedLaneId],
              queryStatus: status.value
            }
          }
        });
      }
    },
    [workflowConfig, selectedLaneId, setWorkflowConfig]
  );
  const handleRemoveStep = () => {
    if (!workflowConfig || !workflowStep) {
      return;
    }

    const remainingStepsOrder = workflowConfig.stepsOrder.filter(
      step => step !== selectedLaneId
    );

    const remainingSteps = pickBy(
      workflowConfig.steps,
      (_, key) => key !== selectedLaneId
    );
    setWorkflowConfig({
      ...workflowConfig,
      stepsOrder: remainingStepsOrder,
      steps: remainingSteps
    });
    onClose();
  };

  //hide remove button for now
  const showRemoveButton = false;

  return (
    <Stack height="100" className="workflow_action__container__content">
      <Stack gap="100">
        <Stack gap="075">
          <DropdownInput
            label={t("admin:ui.requestTypes.workflowStep.Status")}
            items={stepStatusList}
            value={{ name: status }}
            onChange={handleStepStatus}
            disabled={!hasUpdateAccess}
            allowUndefined={false}
          />
        </Stack>
        <Stack gap="075">
          <TextInput
            variant={ButtonVariant.TEXT}
            label={t("admin:ui.requestTypes.workflowAction.displayName")}
            value={name}
            onChange={handleChangeName}
            maxLength={MAX_LENGTH}
            disabled={!hasUpdateAccess}
            error={errorName}
          />
        </Stack>
        <Stack gap="075">
          {/* <DropdownInput
            label={t("admin:ui.requestTypes.workflowAction.transitionTo")}
            items={dropDownItems}
            value={{ name: transitionToVal }}
            onChange={handleChangeTransitionTo}
            disabled={!hasUpdateAccess}
            allowUndefined={false}
          /> */}
        </Stack>
      </Stack>
      {hasUpdateAccess && showRemoveButton && (
        <Stack alignment="bottom-right">
          <Button
            className="btn__action--remove"
            iconName="delete"
            variant={ButtonVariant.TEXT_DANGER}
            label={t("admin:ui.requestTypes.workflowStep.btn.remove")}
            onClick={handleRemoveStep}
          />
        </Stack>
      )}
    </Stack>
  );
};
