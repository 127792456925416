import { useMemo, useState } from "react";

export function useTooltip() {
  const [showTooltip, setShowTooltip] = useState(false);

  const elementProps = useMemo(
    () => ({
      onMouseEnter: () => setShowTooltip(true),
      onMouseLeave: () => setShowTooltip(false),
      position: "relative"
    }),
    []
  );

  return { showTooltip, elementProps };
}
