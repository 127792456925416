import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
  useAuthUser,
  useGetProjectMilestoneCommentQuery,
  useUpsertProjectMilestoneCommentMutation
} from "@shared/hooks";

import MilestoneCommentTemplate from "@components/templates/MilestoneCommentTemplate";

const MilestoneComment = ({ projectId, milestone, onClose }) => {
  const mode = {
    edit: "EDIT",
    view: "VIEW",
    new: "NEW"
  };
  const [comment, setComment] = useState({ comment: "" });
  const [viewMode, setViewMode] = useState(mode.view);
  const [upsertProjectMilestoneComment, { isError }] =
    useUpsertProjectMilestoneCommentMutation();
  const { data: commentData } = useGetProjectMilestoneCommentQuery({
    projectId,
    milestoneId: milestone.id
  });
  const { user } = useAuthUser();

  useEffect(() => {
    if (commentData) {
      setComment(commentData);
    }
    setViewMode(!commentData?.comment ? mode.new : mode.view);
  }, [commentData, mode.new, mode.view]);

  const updateComment = () => {
    setViewMode(mode.edit);
  };

  const saveComment = () => {
    upsertProjectMilestoneComment({
      projectId,
      milestoneId: milestone.id,
      comment: comment.comment
    });
  };

  const handleChange = event => {
    setComment({
      ...comment,
      comment: event.target.value
    });
  };

  return (
    <MilestoneCommentTemplate
      viewMode={viewMode}
      mode={mode}
      comment={comment}
      updateComment={updateComment}
      saveComment={saveComment}
      handleChange={handleChange}
      onClose={onClose}
      hasError={isError}
      isEditable={user.isHostUser}
    />
  );
};

MilestoneComment.propTypes = {
  projectId: PropTypes.number,
  milestone: PropTypes.shape({
    id: PropTypes.number
  }),
  onClose: PropTypes.func
};

export default MilestoneComment;
