import React from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import Popup from "@shared/components/popup/Popup";
import websheetUtilities from "@shared/helpers/websheetUtilities";

import Form from "@components/atoms/Form/Form";
import ModalForm from "@components/molecules/ModalForm";

import SplitColumnModalTables from "./SplitColumnModalTables";

const SplitColumnModal = props => {
  const {
    splitColumnIndex,
    handleCancel,
    afterData,
    beforeData,
    onInputChange,
    handleSubmit,
    errorMessage
  } = props;
  const { t } = useTranslation();

  if (splitColumnIndex === null) {
    return <></>;
  }

  const modalTitle = t(
    "common:ui.websheet.actions.cleaningWizard.step.splitColumn.title",
    { columnName: websheetUtilities.convertNumToColumn(splitColumnIndex + 1) }
  );

  return (
    <Popup visibility={true} width="50rem">
      <ModalForm
        title={modalTitle}
        submitLabel={t("common:ui.forms.apply.label")}
        cancelLabel={t("common:ui.forms.cancel.label")}
        handleCancel={handleCancel}
        yupSchema={yup.object({
          delimiter: yup
            .string()
            .required(
              t(
                "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiter.required"
              )
            )
        })}
        handleSubmit={handleSubmit}
        boxClassName="split-column-modal"
      >
        {errorMessage && <ErrorBox message={errorMessage} />}
        <Form.TextField
          label={t(
            "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiter.label"
          )}
          name={"delimiter"}
          placeholder={t(
            "common:ui.websheet.actions.cleaningWizard.step.splitColumn.delimiter.placeholder"
          )}
          required={true}
          onChange={onInputChange}
        />
        <SplitColumnModalTables afterData={afterData} beforeData={beforeData} />
      </ModalForm>
    </Popup>
  );
};

export default SplitColumnModal;
