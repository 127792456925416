import React from "react";

import { Box } from "@fermions";

import "./MatrixTemplate.scss";

type GetRows = ({
  className,
  cellWidth
}: {
  className: string;
  cellWidth: string;
}) => React.JSX.Element[];

type Props = {
  getRows: GetRows;
  rowHeaders: string[];
  columnHeaders: string[];
  header?: string;
};

const MatrixTemplate = (props: Props) => {
  const { getRows, rowHeaders, columnHeaders, header } = props;

  if (rowHeaders.length === 0) {
    return <></>;
  }

  return (
    <Box className="matrix-template">
      <table className="matrix-template__table">
        <thead>
          <tr className="matrix-template__column-header-row">
            <th className="matrix-template__column-header">{header}</th>
            {columnHeaders.map((header, headerIndex) => (
              <th
                className="matrix-template__column-header--levels"
                key={`${header}-${headerIndex}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getRows({
            className: "matrix-template__row--data",
            cellWidth: `${50 / columnHeaders.length}%`
          }).map((row, rowIndex) => {
            return (
              <tr
                key={`${rowHeaders[rowIndex]}-${rowIndex}}`}
                className="matrix-template__row"
              >
                <td className="matrix-template__row--header">
                  {rowHeaders[rowIndex]}
                </td>
                {row}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default MatrixTemplate;
