import React from "react";

import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";

import FormTemplate from "@components/atoms/Form/FormTemplate";

function FormWithProvider(props) {
  const { formOptions, actionButtons } = props;
  const methods = useForm(formOptions);
  return (
    <FormProvider {...methods}>
      <FormTemplate
        autoComplete={props.autoComplete}
        onSubmit={methods.handleSubmit(props.handleSubmit)}
        actionButtons={actionButtons(methods.formState)}
        customFormClassName={props.customFormClassName}
      >
        {props.children}
      </FormTemplate>
    </FormProvider>
  );
}

FormWithProvider.propTypes = {
  formOptions: PropTypes.object
};

export default FormWithProvider;
