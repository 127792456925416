import { cmsConstants } from "../constants";

const initialState = {
  isContentLoading: false,
  contentLoaded: false,
  content: null,
  contentLoadingError: "",
  isUIConfigLoading: false,
  uiConfigLoaded: false,
  uiConfig: null,
  uiConfigLoadingError: ""
};

export function cms(state = initialState, action = {}) {
  switch (action.type) {
    case cmsConstants.GET_CONTENT_REQUEST:
      return { ...state, isContentLoading: true };
    case cmsConstants.GET_CONTENT_SUCCESS:
      return {
        ...state,
        isContentLoading: false,
        contentLoaded: true,
        content: action.content
      };
    case cmsConstants.GET_CONTENT_FAILURE:
      return {
        ...state,
        isContentLoading: false,
        contentLoaded: false,
        uiConfigLoaded: false,
        contentLoadingError: action.error
      };
    case cmsConstants.GET_UI_CONFIG_REQUEST:
      return { ...state, isUIConfigLoading: true };
    case cmsConstants.GET_UI_CONFIG_SUCCESS:
      return {
        ...state,
        isUIConfigLoading: false,
        uiConfigLoaded: true,
        uiConfig: action.uiConfig
      };
    case cmsConstants.GET_UI_CONFIG_FAILURE:
      return {
        ...state,
        isUIConfigLoading: false,
        uiConfigLoaded: false,
        uiConfigLoadingError: action.error
      };
    default:
      return state;
  }
}
